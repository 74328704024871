import React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import GlobalStyles from '../../styles/GlobalStyles';
import Text from '../CommonCustomTxt';
import CommonImage from '../CommonImage';
import Icon from 'react-native-vector-icons/Feather';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useTranslation } from 'react-i18next';
import CommonStyle from './CommonStyle';
import { ProgramAction } from '../../types';
import CommonProgressBar from '../CommonProgress/CommonProgressBar';
import CommonStatusLabel from '../CommonProgress/CommonStatusLabel';
import CommonProgressIcon from '../CommonProgress/CommonProgressIcon';
import { APP_SHORTCUT_NAME } from '../../env.json';
interface OwnProps {
    programAction: ProgramAction;
    handleClick: () => void;
}

const CommonProgramActionsList = ({programAction, handleClick}: OwnProps) => {
    const {t, i18n} = useTranslation();
    const {language: userLanguage} = i18n;
    const title = programAction?.title || {en:t('translation:general.empty_title')}

    const calculateProgress = () => {
        const daysRemaining = programAction.repetition - programAction.events.length;
        const total = programAction.events.length;
        return ({total: total, remaining: daysRemaining});
    }

    const showProgramActionStatus = () => {
        const actionsDoneCount = countActionEventsDone()
        if (programAction.repetition !== undefined) {
            if (isActionClosed()) {
                const count = countActionEventsDone()
                if (count === programAction.repetition)
                    return (
                        <View style={{flexDirection: 'row', alignItems:'center'}}>
                            <CommonStatusLabel
                                status='completed'
                                color={GlobalStyles[APP_SHORTCUT_NAME].color}
                            />
                            <Text numberOfLines={1} style={styles.actionProgressTxt}>{t('translation:web_dashboard.studies.status.completed')} </Text>
                        </View>
                    )
                else
                    return (
                        <View style={{flex: 1, flexDirection: 'column'}}>
                            <View style={{flexDirection: 'row', alignItems:'center'}}>
                                <CommonStatusLabel
                                    status='completed'
                                    color={GlobalStyles[APP_SHORTCUT_NAME].color}
                                />
                                <Text numberOfLines={1} style={styles.actionProgressTxt}>{t('translation:programs.action.finished')}</Text>
                            </View>
                            <Text>{t('translation:programs.action.habit.completion', {value: Math.round(count / programAction.repetition * 100)})}</Text>
                        </View>
                    )
            }
            else {
                const {total, remaining} = calculateProgress()
                const timeGap = programAction.frequency === 'weekly' ? 'weeks' : 'days'
                return (
                    <View style={{flex: 1, flexDirection: 'column'}}>
                        <View style={{width: "90%", flex: 1, flexDirection: 'row', paddingRight: PR(10), alignItems: 'center'}}>
                            <View style={{marginRight: PR(10)}}>
                                <CommonProgressIcon
                                    status='in_progress'
                                    color={GlobalStyles[APP_SHORTCUT_NAME].color}
                                />
                            </View>
                            <CommonProgressBar current={actionsDoneCount} total={total} fillColor={GlobalStyles.helpi.color} backgroundColor={GlobalStyles.helpi.progressBarBackgroundColor}/>
                            <Text style={{marginLeft: PR(5)}}>{actionsDoneCount}/{total}</Text>
                        </View>
                        <Text>{t(`translation:programs.action.habit.remaining_${timeGap}`, {value: remaining})}</Text>
                    </View>
                )
            }
        }
    }

    const countActionEventsDone = () => {
        let count = 0;
        programAction.events.forEach((event) => {
            if ((event.type === 'program_action' && event.done) || ((event.type === 'mood' || event.type === 'sleep') && event.value !== null))
                count++
        })
        return count;
    }

    const isActionClosed = () => {
        if (!programAction.events || programAction.events.length === 0)
            return false
        if (programAction.type === 'one_time' && programAction.events[0].done)
            return true
        if (programAction.type === 'habit')
            return programAction.events.length >= programAction.repetition;
    }

    return(
        <TouchableOpacity style={CommonStyle.container} onPress={handleClick}>
            <View style={CommonStyle.wrapper}>
                {programAction.imageUri ?
                    <View style={{ width:PR(50), height:PR(50),borderRadius: PR(15), overflow: "hidden"}}>
                        <CommonImage name="" uri={programAction.imageUri} width={PR(50)} height={PR(50)}/>
                    </View>
                :
                    <View style={{ width:PR(50), height:PR(50),borderRadius: PR(15), overflow: "hidden"}}>
                        <CommonImage name="noImage" width={PR(50)} height={PR(50)} />
                    </View>
                }
                <View style={CommonStyle.textContainer}>
                    <Text numberOfLines={2} style={CommonStyle.title}>{title[userLanguage] || title.en}</Text>
                    {(programAction.events.length === 0 || !programAction.events[0].date) &&
                        <CommonStatusLabel
                            status='not_started'
                            color={GlobalStyles[APP_SHORTCUT_NAME].color}
                        />
                    }
                    {programAction.events.length > 0 && programAction.type === 'habit' && showProgramActionStatus()}
                    {programAction.events.length === 1 && programAction.type === 'one_time' &&
                        <CommonStatusLabel
                            status={!programAction.events ? 'not_started' : programAction.events[0].done ? 'completed' : 'in_progress'}
                            color={GlobalStyles[APP_SHORTCUT_NAME].color}
                        />
                    }
                </View>
                <Icon style={CommonStyle.icon} name="chevron-right" size={PR(25)} color={GlobalStyles.global.grey}/>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    actionProgressTxt: {
        fontSize: PR(12),
        color: GlobalStyles.global.black,
        marginRight: PR(5)
    }
});

export default CommonProgramActionsList;