import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CommonTimelineGraphs from './CommonTimelineGraphs';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Text from 'common/src/components/CommonCustomTxt';
import cmsFile from 'common/src/services/localization/en/en.json'
import { APP_SHORTCUT_NAME } from '../env.json';
import { TIMELINE_DISPLAY_TYPES, WEEK, MONTH, YEAR, DATE_FORMAT_MONTH, DATE_FORMAT } from '../types/index';
import { convertLangCode } from 'common/src/services/translationList';
import moment from 'moment';
import { widthPercentageToDP as PR } from '../styles/PixelRatio';
import { useNavigation } from "@react-navigation/native";
import {
    defineStartIndex,
    isConnectedToHCP,
    defineSeizureTypeColor,
    defineSeizureTypeOtherColor,
    calculateTotalDaySeizures,
    displaySeizureTypeName
} from '../services/utils';
import Loading from '../../../helpilepsy/src/modules/Loading';
import { getTimelineEventsAction } from '../../../helpilepsy/src/store/actions/authenticationActions';

const colors = cmsFile["list.seizureListColors"]
const otherColors = cmsFile["list.otherSeizureColors"]
let max = 1;
const monthFormat = DATE_FORMAT_MONTH;
const isoFormat = DATE_FORMAT

const CommonTimeline = ({ resetEventsOnDiary }) => {
    const navigation = useNavigation();
    const { i18n, t } = useTranslation();
    const lang = convertLangCode(i18n.language);
    const dispatch = useDispatch();
    const GetEventsResponse = useSelector((state: any) => state.getEvents.timeline_events);
    const Me = useSelector((state: any) => state.getMe.response);
    const [Wseizures, setSeizures] = useState([] as any)
    const [seizureTypes, setSeizureTypes] = useState([] as any);
    const [otherColorsMapping, setOtherColorsMapping] = useState([] as any);
    const [selectedDisplayType, setSelectedDisplayType] = useState('WEEK')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let now = moment();
        setIsLoading(true)
        dispatch(getTimelineEventsAction({
            type: "seizure,reminder", to: now.format(isoFormat), from: now.subtract(1, 'weeks').add(1, 'days').format(isoFormat), disablePopulate: true,
            callback: () => {
                setIsLoading(false)
            }
        }));
    }, [])

    useEffect(() => {
        max = 0;
        sortBySeizure();
    }, [GetEventsResponse])

    const updateSelected = (displayType) => {
        let obj;
        let now = moment();

        if (displayType === WEEK)
            obj = { type: "seizure,reminder", to: now.format(isoFormat), from: now.subtract(1, 'weeks').add(1, 'days').format(isoFormat), disablePopulate: true }
        else if (displayType === MONTH)
            obj = { type: "seizure,reminder", to: now.format(isoFormat), from: now.subtract(1, 'months').add(1, 'days').format(isoFormat), disablePopulate: true }
        else if (displayType === YEAR)
            obj = { type: "seizure,reminder", to: now.format(monthFormat), from: now.subtract(1, 'years').add(1, 'months').format(monthFormat), groupBy: 'month', disablePopulate: true }
        setIsLoading(true)
        setSelectedDisplayType(displayType)
        dispatch(getTimelineEventsAction({
            ...obj,
            callback: () => {
                setIsLoading(false)
            }
        }));
    }


    const sortBySeizure = () => {
        const _seizures: any = [];
        const _seizureTypes: any = [];
        const _otherColors: any = isConnectedToHCP(Me) ? null : otherColorsMapping;

        let colorIndex = defineStartIndex(otherColorsMapping, otherColors);
        GetEventsResponse.forEach((day: any, seizureIndex) => {
            _seizures.push({
                date: day.date,
                seizures: []
            })
            if (day.seizures.length > max)
                max = day.seizures.length;

            day.seizures.forEach((seizure: any) => {
                let existsForDate = _seizures[seizureIndex].seizures.find((item: any) => item.name === seizure.seizure_type)
                const existsInGlobal = _seizureTypes.find((item: any) => item.name === seizure.seizure_type)
                if (!existsInGlobal) {
                    let seizureTypeColor = defineSeizureTypeColor(seizure.seizure_type, seizure.seizure_type_id, Me.patientData, colors)
                    if (!seizureTypeColor) {
                        if (!_otherColors)
                            seizureTypeColor = GlobalStyles.global.customTypeGrey;
                        else {
                            let otherTypeColor = defineSeizureTypeOtherColor(seizure.seizure_type, otherColorsMapping);
                            if (otherTypeColor === -1) {
                                seizureTypeColor = otherColors[colorIndex]['other_' + (colorIndex + 1)]
                                _otherColors.push({
                                    color: seizureTypeColor,
                                    type: seizure.seizure_type
                                })
                                colorIndex = colorIndex >= otherColors.length - 1 ? 0 : colorIndex + 1;
                            }
                            else
                                seizureTypeColor = otherColorsMapping[otherTypeColor].color;
                        }
                    }
                    _seizureTypes.push({ name: seizure.seizure_type, color: seizureTypeColor })
                    _seizures[seizureIndex].seizures.push({ date: day.date, name: seizure.seizure_type, color: seizureTypeColor, count: 1 })
                }
                else if (!existsForDate) {
                    _seizures[seizureIndex].seizures.push({ date: day.date, name: seizure.seizure_type, color: existsInGlobal.color, count: 1 })
                }
                else
                    existsForDate.count++
            })
        })
        setSeizures(_seizures)
        setSeizureTypes(_seizureTypes);
        if (_otherColors && otherColorsMapping.length !== _otherColors.length) {
            setOtherColorsMapping(_otherColors)
        }
    }

    let format = 'dddd' // day full name
    if (selectedDisplayType === MONTH)
        format = 'D' // day month number
    else if (selectedDisplayType === YEAR)
        format = 'MMMM' // month full name

    // go to diary in a specific day
    const goToDiary = (day) => {
        resetEventsOnDiary();
        navigation.navigate('Diary', { day, format })
    }

    if (isLoading)
        return <Loading netConnected={true} />
    else
        return (
            <View style={styles.container}>
                <View style={styles.selectContainer}>
                    {
                        TIMELINE_DISPLAY_TYPES.map((displayType, index) => (
                            <TouchableOpacity key={index} style={[styles.selectBox, displayType === selectedDisplayType.toLowerCase() ? {} : { backgroundColor: 'transparent' }]} onPress={() => { updateSelected(displayType) }}>
                                <Text style={[styles.selectBoxText, displayType === selectedDisplayType.toLowerCase() ? { color: GlobalStyles[APP_SHORTCUT_NAME].color } : { color: GlobalStyles[APP_SHORTCUT_NAME].inactiveColor }]}>{t('translation:dashboard.filters.' + displayType)}</Text>
                            </TouchableOpacity>
                        ))
                    }
                </View>
                {
                    !isLoading && selectedDisplayType !== MONTH &&
                    <View style={styles.timelineContainer}>
                        {
                            Wseizures.map((day, index) => {
                                let seizureCount = calculateTotalDaySeizures(day)
                                const timelineLabel = moment(day.date).locale(lang || 'en').format(format);
                                return (
                                    <TouchableOpacity onPress={() => goToDiary(day)} key={index} style={styles.timelineBar}>
                                        <CommonTimelineGraphs
                                            day={day}
                                            max={max}
                                            seizureCount={seizureCount}
                                            timelineLabels={lang === 'ko' ? timelineLabel : timelineLabel[0].toUpperCase()}
                                        />
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </View>
                }
                {
                    !isLoading && selectedDisplayType === MONTH &&
                    <View>
                        <View style={styles.timelineContainer}>
                            {
                                Wseizures.map((day, dayIndex) => {
                                    let seizureCount = calculateTotalDaySeizures(day)
                                    if (dayIndex > 15)
                                        return
                                    return (
                                        <TouchableOpacity onPress={() => goToDiary(day)} key={dayIndex} style={styles.timelineBar}>
                                            <CommonTimelineGraphs
                                                day={day}
                                                max={max}
                                                seizureCount={seizureCount}
                                                timelineLabels={moment(day.date).locale(lang || 'en').format(format)}
                                            />
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </View>
                        <View style={styles.timelineContainer}>
                            {
                                Wseizures.map((day, dayIndex) => {
                                    let seizureCount = calculateTotalDaySeizures(day)
                                    if (dayIndex <= 15)
                                        return
                                    return (
                                        <TouchableOpacity onPress={() => goToDiary(day)} key={dayIndex} style={styles.timelineBar}>
                                            <CommonTimelineGraphs
                                                day={day}
                                                max={max}
                                                seizureCount={seizureCount}
                                                timelineLabels={moment(day.date).locale(lang || 'en').format(format)}
                                            />
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </View>
                    </View>
                }
                <View style={styles.legendContainer}>
                    {
                        seizureTypes.map((seizure: any, index: number) => (
                            <View key={index} style={styles.legendItem}>
                                <View style={[styles.legendDot, { backgroundColor: seizure.color }]}></View>
                                <Text style={[styles.legendText, { color: seizure.color }]}>{displaySeizureTypeName(seizure.name, cmsFile["list.seizureList"], t)}</Text>
                            </View>
                        ))
                    }
                </View>
            </View>
        )
}

const styles = StyleSheet.create({
    container: {

    },
    selectContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: PR(20),
        backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor,
        borderRadius: PR(25),
        alignItems: 'center',
    },
    timelineContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: PR(10)
    },
    selectBox: {
        padding: PR(12),
        backgroundColor: GlobalStyles.global.white,
        borderRadius: PR(25),
        margin: PR(3),
        alignItems: 'center',
        flex: 1,
        flexGrow: 0.33
    },
    selectBoxText: {
        fontFamily: GlobalStyles.global.fontFamily.Bold
    },
    timelineBar: {
        flex: 1,
        flexDirection: 'column'
    },
    timelineLabels: {
        alignSelf: 'center',
        marginTop: PR(10)
    },
    legendContainer: {
        flexDirection: 'column',
        marginTop: PR(10)
    },
    legendItem: {
        flex: 1,
        flexDirection: 'row',
        padding: PR(10)
    },
    legendDot: {
        borderRadius: PR(10),
        width: PR(15),
        height: PR(15)
    },
    legendText: {
        marginLeft: PR(15)
    }
})

export default CommonTimeline