import React, { useState, useEffect, useRef } from 'react';
import {Modal, View, StyleSheet, TouchableOpacity, TouchableWithoutFeedback,Platform, ScrollView} from 'react-native';
import CommonBtn from '../components/CommonBtn';
import GlobalStyle from '../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import CommonSelect from 'common/src/components/CommonSelect';
import InputField from './InputField';
import {useTranslation} from 'react-i18next';
import CommonTimeInput from './CommonTimeInput';
import CommonDateInput from './CommonDateInput';
import {DateTime} from 'luxon';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import {compareDatesForEvents, compareDates, areDatesEqual} from '../services/compare';
import {isCloseToBottom} from '../services/utils';
import { useSelector } from 'react-redux';
import { getLocalTime } from '../helpers/event';

interface ActionModalTypes {
  modalType: string,
  title: string,
  buttons: any,
  onClose: any,
  data?: any,
  other?: boolean,
  testID: string
}

const getInitialDateTime = (userTimezone: string, data?: { data?: any; taken_date?: any; reminder?: any, date?: any }): DateTime => {
  let initialDateTime = DateTime.now();
  if (data?.taken_date) {
    initialDateTime = DateTime.fromISO(data.taken_date);
  } else if (data?.data) {
    initialDateTime = DateTime.fromISO(data.data.time);
  } else if (data?.reminder?.time) {
    let tempDate;
    const timeArray = data?.reminder.time.split(':');
    tempDate = DateTime.fromISO(data?.date).set({hour: timeArray[0], minute: timeArray[1]})
    initialDateTime = DateTime.fromISO(tempDate, {zone : userTimezone})
  }

  return initialDateTime;
}

const CommonActionModal = ({modalType, title, buttons, onClose, data, other, testID}:ActionModalTypes) => {
  const {t} = useTranslation();
  const scrollRef:any = useRef();
  const userTimezone = useSelector((state: any) => state.getMe?.settings?.timezone);
  const [currentData, setCurrentData] = useState(data);
  const [otherValue, setOtherValue] = useState<string>()
  const initialDateTime =  getInitialDateTime(userTimezone, data);
  const [medicationIntakeTime, setMedicationIntakeTime] = useState(initialDateTime.toFormat('HH:mm'));
  const [medicationIntakeDate, setMedicationIntakeDate] = useState(initialDateTime.toFormat('dd-MM-yyyy'));
  const [programActionScheduleTime, setProgramActionScheduleTime] = useState(data?.date ? DateTime.fromISO(data?.date).toFormat('HH:mm') : DateTime.now().toFormat('HH:mm'));
  const [programActionScheduleDate, setProgramActionScheduleDate] = useState(data?.date ? DateTime.fromISO(data?.date).toFormat('dd-MM-yyyy') : DateTime.now().toFormat('dd-MM-yyyy'));
  const [isDayWrong, setIsDayWrong] = useState<boolean>(false)
  const [isTimeWrong, setIsTimeWrong] = useState<boolean>(false)
  const [atBottom, setAtBottom] = useState<boolean>(true)
  const [contentHeight, setContentHeight] = useState<number>(0)
  let currentDate = DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy')
  let currentTime = DateTime.fromJSDate(new Date()).toFormat('HH:mm')

  const updateData = (updatedData) => {
      setCurrentData(updatedData)
  }

  const onButtonClick = (button) => {
    if (modalType === 'intakes')
      button.click(currentData, (medicationIntakeTime && medicationIntakeDate ) ? `${DateTime.fromFormat(medicationIntakeDate, 'dd-MM-yyyy').toFormat('yyyy-MM-dd')}T${DateTime.fromISO(medicationIntakeTime).toFormat('HH:mm')}` : null)
    else if(modalType === 'programActionSchedule')
      button.click(programActionScheduleTime, programActionScheduleDate)
    else if (button.action && otherValue === undefined)
      button.click(currentData)
    else if (button.action && otherValue !== undefined)
      button.click([{label:otherValue, value:otherValue, other:true}])
    else
      button.click()
  }

  useEffect(() => {
      scrollRef.current?.scrollTo({y: 1});
  },[])

  useEffect(() => {
    if(modalType === 'programActionSchedule'){
      if(programActionScheduleDate && compareDatesForEvents(currentDate, programActionScheduleDate, 'dd-MM-yyyy'))
        setIsDayWrong(true)
      else
        setIsDayWrong(false)
    }
  }, [programActionScheduleDate])

  useEffect(() => {
    if(modalType === 'programActionSchedule'){
      if(programActionScheduleTime && !compareDates(currentDate, programActionScheduleDate, 'dd-MM-yyyy')){
        setIsTimeWrong(false)
      }
      else if(programActionScheduleTime && areDatesEqual(currentDate, programActionScheduleDate, 'dd-MM-yyyy')){
        currentTime > programActionScheduleTime ? setIsTimeWrong(true) : setIsTimeWrong(false)
      }
      else{
        setIsTimeWrong(true)
      }
    }
  }, [programActionScheduleTime, programActionScheduleDate])

  const changeDate = (inputDate) => {
    if(DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      inputDate = DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setMedicationIntakeDate(inputDate)
    }
    setIsDayWrong(compareDatesForEvents(inputDate, currentDate, 'dd-MM-yyyy'))
  }

  const checkDisable = () => {
    return (modalType === 'programActionSchedule' && (isDayWrong || isTimeWrong)) || (modalType === 'intakes' && isDayWrong)
  }

  useEffect(() => {
    if(checkDisable())
      scrollRef.current?.scrollTo({y: contentHeight-1});
  },[checkDisable()])

  const modalHeader = (
    <View style={styles.modalHeader}>
      <Text style={[styles.title]}>{title}</Text>
      <TouchableOpacity style={styles.closeIcon} onPress={onClose}>
        <Icon name={"x"} size={PR(24)} color={GlobalStyle[APP_SHORTCUT_NAME].btnColor}/>
      </TouchableOpacity>
    </View>
  )

  const modalBody = (
    <View style={styles.modalBody} onStartShouldSetResponder={() => true}>
      <ScrollView nestedScrollEnabled={true}
        ref={scrollRef}
        style={{width: "100%"}}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={16}
        onLayout={e => setContentHeight(e.nativeEvent.layout.height)}
        onScroll={({nativeEvent}) => {
          if(isCloseToBottom(nativeEvent) && !atBottom){
            setAtBottom(true)
          }
          else if(!isCloseToBottom(nativeEvent) && atBottom)
            setAtBottom(false)
        }}
      >
        <TouchableOpacity activeOpacity={1}>
          {
              modalType === 'select-check' &&
                <CommonSelect type={'check'} data={data} onSelect={updateData} disable={false} style={{}}/>
          }
          {
              modalType === 'select-radio' &&
                <CommonSelect type={'radio'} data={data} onSelect={updateData} disable={false} style={{}}/>
          }
          {
              modalType === 'intakes' &&
                <View style={{paddingHorizontal: PR(4)}}>
                  <Text renderAsHtml={true} style={{marginBottom: PR(15), fontSize: PR(16)}}>{t('translation:modal.medication.taken')}</Text>
                  <View style={styles.medicationContainer}>
                    <View style={{flex: 1, flexGrow: 0.8, flexDirection: 'column'}}>
                      <Text>{data.data ? data.data.name : data.reminder.name}</Text>
                      <Text>{data.data ? data.data.dose : data.reminder.dose} {data.data ? data.data.unit : data.reminder.unit}</Text>
                    </View>
                    <View style={{flex: 1, flexGrow: 0.2, alignItems: 'flex-end'}}>
                      <Text>{data.data ? getLocalTime(data.data.time, userTimezone).toFormat("HH:mm") : getLocalTime(data.reminder.time, userTimezone).toFormat("HH:mm")}</Text>
                    </View>
                  </View>
                  <View style={{marginTop: PR(25)}}>
                    <Text  renderAsHtml={true} style={{marginBottom: PR(15), marginLeft: PR(15), fontSize: PR(12)}}>{t('translation:modal.medication.hour')}</Text>
                    <View>
                      <CommonTimeInput 
                        onChange={setMedicationIntakeTime}
                        value={medicationIntakeTime} 
                        fieldStyle={styles.dateInputField}
                        textStyle={styles.dateInputFieldText}
                        webInputStyle={{
                          backgroundColor: "#F2F4F5", 
                          border: "none", 
                          width: "100%", 
                          color: GlobalStyle.global.black,
                          fontSize: PR(16),
                          fontFamily: GlobalStyle.global.fontFamily.Medium
                        }}
                      />
                    </View>
                  </View>
                  <View style={{marginTop: PR(25)}}>
                    <Text  renderAsHtml={true} style={{marginBottom: PR(15), marginLeft: PR(15), fontSize: PR(12)}}>{t('translation:modal.medication.day')}</Text>
                    <View>
                    <CommonDateInput
                      onChange={d => changeDate(d)}
                      value={medicationIntakeDate}
                      fieldStyle={styles.dateInputField}
                      textStyle={styles.dateInputFieldText}
                      maxDate={new Date()}
                      webInputStyle={{
                        backgroundColor: "#F2F4F5", 
                        border: "none", 
                        width: "100%", 
                        color: GlobalStyle.global.black,
                        fontSize: PR(16),
                        fontFamily: GlobalStyle.global.fontFamily.Medium
                      }}
                    />  
                    </View>
                  </View>
                </View>
          }
          {
            modalType === 'programActionSchedule' &&
              <View style={{paddingHorizontal: PR(4)}}>
                <Text renderAsHtml={true} style={{marginBottom: PR(15), fontSize: PR(16)}}>{t('translation:modal.programActionSchedule.description')}</Text>
                <View style={{marginTop: PR(10)}}>
                  <Text style={{marginBottom: PR(15), marginLeft: PR(15), fontSize: PR(12)}}>{t('translation:modal.text.date')}</Text>
                </View>
                <CommonDateInput
                  onChange={d => setProgramActionScheduleDate(DateTime.fromFormat(d, 'yyyy-MM-dd').toLocal().toFormat('dd-MM-yyyy'))}
                  value={programActionScheduleDate}
                  fieldStyle={styles.dateInputField}
                  textStyle={styles.dateInputFieldText}
                  minDate={new Date()}
                  webInputStyle={{
                    backgroundColor: "#F2F4F5", 
                    border: "none", 
                    width: "100%", 
                    color: GlobalStyle.global.black,
                    fontSize: PR(16),
                    fontFamily: GlobalStyle.global.fontFamily.Medium
                  }}
                />
                <View style={{marginTop: PR(25), marginBottom: PR(27)}}>
                  <Text  renderAsHtml={true} style={{marginBottom: PR(15), marginLeft: PR(15), fontSize: PR(12)}}>{t('translation:modal.text.time')}</Text>
                  <View>
                    <CommonTimeInput 
                      value={programActionScheduleTime} 
                      onChange={setProgramActionScheduleTime} 
                      fieldStyle={styles.dateInputField}
                      textStyle={styles.dateInputFieldText}
                      minTime={new Date()}
                      inputDate={programActionScheduleDate}
                      webInputStyle={{
                        backgroundColor: "#F2F4F5", 
                        border: "none", 
                        width: "100%", 
                        color: GlobalStyle.global.black,
                        fontSize: PR(16),
                        fontFamily: GlobalStyle.global.fontFamily.Medium,
                      }}
                    />
                  </View>
                </View>
                {checkDisable() &&
                  <Text style={styles.errorTxt}>{t('translation:errors.schedule.dateTime')}</Text>
                }
              </View>
          }
          {
            other &&
              <InputField
                  labelText={t('translation:modal.select.other')}
                  inputType="string"
                  customStyle={{marginBottom:Platform.OS !== 'web' ? PR(80): 0}}
                  changeText={(e) => setOtherValue(e.trimStart())}
                  placeholderTxt={t('translation:modal.select.other_placeholder')}
                  testID="otherID"
                  value={otherValue}
                />
          }
        </TouchableOpacity>
      </ScrollView>
      {!atBottom &&
      <View style={{position:'absolute', bottom:10, right:10}}>
        <Icon name={"arrow-down"} size={PR(20)} color={GlobalStyle[APP_SHORTCUT_NAME].btnColor}/>
      </View>
      }
    </View>
  )

  const modalFooter = (
    <View>
      {checkDisable() && modalType === 'intakes' &&
        <View style={{width:'90%', alignSelf:'center'}}>
          <Text style={styles.errorTxt}>{t('translation:modal.date_time.error')}</Text>
        </View>  
      }
      {
        buttons && buttons.map((btn, index) => {
          return (
            <View key={index} style={{marginBottom:PR(5)}}>
              <CommonBtn 
                testID={"action_modal_btn"} 
                key={index} type={btn.type} 
                title={btn.title} 
                click={() => {onButtonClick(btn)}}
                disable={btn.type === 'primary' ? checkDisable() : false}
              />
            </View>
          )
        }
      )}
    </View>
  )

  const modalContainer = (
    <TouchableWithoutFeedback>
      <View testID={testID} style={styles.modalContainer}>
        {modalHeader}
        {modalBody}
        {modalFooter}
      </View>
    </TouchableWithoutFeedback>
  )
  
  return (
    <Modal
      animationType={'fade'}
      transparent={true}>
      <TouchableOpacity onPress={onClose} style={styles.modal}>
          {modalContainer}
      </TouchableOpacity>
    </Modal>
  )
}

const styles = StyleSheet.create({
  closeIcon: {
    backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].closeBtn,
    borderRadius: PR(25),
    justifyContent: 'center',
    alignItems: 'center',
    height: PR(30),
    width: PR(30)
  },
  modal: {
    backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].backgroundColorModal,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    width: "80%",
    paddingBottom: PR(20),
    borderRadius: PR(15),
    backgroundColor: GlobalStyle.global.white,
    maxHeight: "80%",
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    width: "90%",
    paddingTop: PR(15),
    paddingBottom: PR(5),
    paddingHorizontal: Platform.OS === 'web' ? PR(4) : 0,
  },
  title: {
    fontFamily: GlobalStyle.global.fontFamily.Bold,
    fontSize: PR(18),
    color: GlobalStyle.global.black,
    width: '75%'
  },
  modalBody:{
    minHeight: PR(75),
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: PR(10),
    paddingBottom: PR(10),
    width: "90%",
    flexShrink: 1,
  },
  bodyText: {
    color: GlobalStyle.global.grey,
    fontSize: PR(15),
    textAlign: 'center',
    lineHeight: PR(25)
  },
  medicationContainer: {
    width: "100%",
    backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].closeBtn,
    borderRadius: PR(20),
    flexDirection: "row",
    padding: PR(20)
  },
  dateInputField: {
    backgroundColor: '#F2F4F5',
    borderRadius: PR(20),
    borderColor: '#F2F4F5',
    height: PR(62),
    paddingHorizontal: PR(20),
    paddingVertical: PR(20),
  },
  dateInputFieldText: {
    fontSize: PR(16),
    fontFamily: GlobalStyle.global.fontFamily.Medium
  },
  errorTxt: {
    color: GlobalStyle.global.redError,
    textAlign: 'center'
  },
});


export default CommonActionModal;
