import React, {useState, useEffect} from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';
import Text from 'common/src/components/CommonCustomTxt';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../../env.json';
import { DateTime } from "luxon";
import {useTranslation} from 'react-i18next';
import {convertLangCode} from 'common/src/services/translationList';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';

const CommonWeekView = ({ data, focus, startEndDay, elScroll}) => {
  const today = DateTime.now();
  const {i18n} = useTranslation();
  const lang = convertLangCode(i18n.language)
  const [selectedDay, setSelectedDay] = useState<string>(DateTime.fromFormat(new Date().toLocaleString(),'yyyy-MM-dd').toFormat('yyyy-MM-dd'))
  const [clickedDay, setClickedDay] = useState<string>('')
  useEffect(() => {
    if(data.length > 0 ){
      if(focus === -1) {
        setSelectedDay(DateTime.fromFormat(new Date().toLocaleString(),'yyyy-MM-dd').toFormat('yyyy-MM-dd'))
      }
      else if (clickedDay !== '') {
        setClickedDay('')
      }
      else if(focus >= 0 && clickedDay === '') {
        setSelectedDay(DateTime.fromFormat((data[focus]?.date || today.toString()),'yyyy-MM-dd').toFormat('yyyy-MM-dd'))
      }
    }
  },[data,focus]);
  // this func send back to the parent component(Diary) the position we want to scroll to & also update the selected day
  const scrollFunc = (day) => {
    elScroll(DateTime.fromFormat(day,'yyyy-MM-dd').toFormat('yyyy-MM-dd'))
    setSelectedDay(DateTime.fromFormat(day,'yyyy-MM-dd').toFormat('yyyy-MM-dd'))
    setClickedDay(day)
  }
  return (
    // creating the visual part of the week view
    <>
      <View  style={[styles.item, {flexDirection:'row'}]}>
        {data.slice(startEndDay[0], startEndDay[1]).map((day:any,index:number) =>{
          return (
            <TouchableOpacity 
              testID={'weekview_'} 
              onPress={()=>{scrollFunc(day.date)}} 
              key={index} 
              style={
                DateTime.fromFormat(day.date,'yyyy-MM-dd').toFormat('yyyy-MM-dd')=== selectedDay
                ?
                [styles.weekDay,{flexDirection:'column', width: '14.5%',alignItems:'center'}]
                :
                {flexDirection:'column', width: '14.5%',alignItems:'center', paddingVertical: PR(15)}}
            >
              <Text style={{textTransform: 'uppercase', fontSize: PR(10),color: GlobalStyles.global.black}}>{DateTime.fromISO(day.date, { locale: lang }).toFormat('EEE')}</Text>
              <Text style={{
                fontSize: PR(18), 
                color: GlobalStyles.global.black, 
                fontFamily: GlobalStyles.global.fontFamily.Bold}}
              >
                {DateTime.fromISO(day.date).toFormat('dd')}
              </Text>
                <View style={{flexDirection:'row',justifyContent: 'space-around'}}>
                  {day.side_effects.length > 0 && 
                    <>
                      <View style={[styles.eventDots,{backgroundColor:'#ffd039'}]} />
                      <View style={{padding:PR(1)}}></View>
                    </>
                  }
                  {day.seizures.length > 0 && 
                    <>
                      <View style={[styles.eventDots,{backgroundColor:'#fb667a'}]} />
                      <View style={{padding:PR(1)}}></View>
                    </>
                  }
                  {day.appointments.length > 0 && 
                    <>
                      <View style={[styles.eventDots,{backgroundColor:'#1de9b6'}]} />
                      <View style={{padding:PR(1)}}></View>
                    </>
                  }
                  {day.reminders.length > 0 && 
                    <>
                      <View style={[styles.eventDots,{backgroundColor:GlobalStyles.helpi.color}]} />
                      <View style={{padding:PR(1)}}></View>
                    </>
                  }
                  {day.others.length > 0 && 
                    <>
                      <View style={[styles.eventDots,{backgroundColor:GlobalStyles.global.black}]} />
                      <View style={{padding:PR(1)}}></View>
                    </>
                  }
                  {day.headaches.length > 0 && 
                    <>
                      <View style={[styles.eventDots,{backgroundColor:GlobalStyles.migraine.color}]} />
                      <View style={{padding:PR(1)}}></View>
                    </>
                  }
                  {day.nightwatch_reports.length > 0 && 
                    <>
                      <View style={[styles.eventDots,{backgroundColor:'#8c7fe5'}]} />
                      <View style={{padding:PR(1)}}></View>
                    </>
                  }
                  {day.forms.length > 0 && 
                    <>
                      <View style={[styles.eventDots,{backgroundColor:'#3964AE'}]} />
                      <View style={{padding:PR(1)}}></View>
                    </>
                  }
                  {day.program_actions.length > 0 && 
                    <>
                      <View style={[styles.eventDots,{backgroundColor:GlobalStyles.global.actionGreen}]} />
                      <View style={{padding:PR(1)}}></View>
                    </>
                  }
                </View>
            </TouchableOpacity>
          )
        })}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  item: {
    width: '100%',
    alignSelf: 'center',
  },
  weekDay: {
    borderRadius: PR(20),
    backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor,
    paddingVertical: PR(15),
  },
  eventDots: {
    height: PR(5),
    width: PR(5),
    borderRadius: PR(5 / 2),
  },
})

export default CommonWeekView;