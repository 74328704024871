export enum SettingsTypes{
    Profile = "Profile",
    My_neurologists = "My_neurologists",
    Language = "Language",
    Timezone = "Timezone",
    Change_my_password = "ChangeMyPdw",
    My_reports = "Reports",
    About = "About",
    Nightwatch = "Nightwatch",
    Tutorial = "Tutorial",
    Mini_tutorial = "Mini_tutorial",
    SeizureVideos = "SeizureVideos",
    InformedConsent = "InformedConsent",
    Our_privacy_notice = "Our privacy notice",
    Our_terms_of_use = "Our terms of use",
    Treatment_reminders = "AddEvent",
    Mood_and_sleep = "MoodSleepNotifications",
    Erfahren_Sie = "Erfahren Sie",
    Epilepsie_gut = "Epilepsie gut",
    Help = "Help",
    My_seizures_description = "MySeizuresDescription",
    My_appointments = "MyAppointments",
    My_triggers_model = "MyTriggersModel",
    My_seizure_antidote = "MySeizureAntidote",
    Enable_Disable_biometrics = "EnableDisableBiometric"
}

export const defaultLang = (lang) => {
    return [
    {label: 'Français', value: 'french', lngCode: 'fr', selected: 'fr' === lang},
    {label: 'Français Canadien', value: 'canadian_french', lngCode: 'can-fr', selected: 'can-fr' === lang},
    {label: 'English', value: 'english', lngCode: 'en', selected: 'en' === lang},
    {label: 'Canadian English', value: 'canadian_english', lngCode: 'can-en', selected: 'can-en' === lang},
    {label: 'Nederlands', value: 'dutch', lngCode: 'nl', selected: 'nl' === lang},
    {label: 'Deutsch', value: 'german', lngCode: 'de', selected: 'de' === lang},
    {label: 'Italiano', value: 'italian', lngCode: 'it', selected: 'it' === lang},
    {label: 'Español', value: 'spanish', lngCode: 'es', selected: 'es' === lang},
    {label: 'Magyar', value: 'hungarian', lngCode: 'hu', selected: 'hu' === lang},
    {label: 'Dansk', value: 'danish', lngCode: 'da', selected: 'da' === lang},
    {label: 'Ελληνικά', value: 'greek', lngCode: 'el', selected: 'el' === lang},
    {label: 'Български', value: 'bulgarian', lngCode: 'bg', selected: 'bg' === lang},
    {label: 'Polskie', value: 'polish', lngCode: 'pl', selected: 'pl' === lang},
    {label: 'Rumuński', value: 'romanian', lngCode: 'ro', selected: 'ro' === lang},
    {label: 'Čeština', value: 'czech', lngCode: 'cs', selected: 'cs' === lang},
    {label: '한국어', value:'korean', lngCode: 'ko', selected: 'ko' === lang },
  ]
}
