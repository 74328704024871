import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import GlobalStyles from "../../../styles/GlobalStyles";
import Icon from "react-native-vector-icons/Feather";
import { widthPercentageToDP as PR } from "common/src/styles/PixelRatio";
import CommonCustomTxt from "../../CommonCustomTxt";
import { useTheme } from "@react-navigation/native";

interface OwnProps {
  clickNext: () => void;
  clickPrevious: () => void;
  clickComplete: () => void;
  currentPage: number;
  totalPages: number;
}

const CommonChapterNavigation = (props: OwnProps) => {
  const theme = useTheme();
  const Style = getStyle(theme.colors.primary);
  
  return (
  <View style={Style.container}>
    <View style={Style.innerContainer}>
      <View style={Style.leftContainer}>
        <CommonCustomTxt>{props.currentPage}/{props.totalPages}</CommonCustomTxt>
      </View>
      <View style={Style.rightContainer}>
        { props.currentPage > 1 && 
          <TouchableOpacity testID="chapter-previous-button" onPress={props.clickPrevious}>
            <View style={Style.previousButtonContainer}>
              <View style={Style.centered}>
                <Icon
                  name="arrow-left"
                  size={PR(28)}
                  color={GlobalStyles.helpi.btnColor}
                />
              </View>
            </View>
          </TouchableOpacity>
        }
        { props.currentPage < props.totalPages &&
          <TouchableOpacity testID="chapter-next-button" onPress={props.clickNext}>
            <View style={Style.nextButtonContainer}>
              <View style={Style.centered}>
                <Icon name="arrow-right" size={PR(28)} color="white" />
              </View>
            </View>
          </TouchableOpacity>
        }
        { props.currentPage === props.totalPages &&
          <TouchableOpacity testID="chapter-complete-button" onPress={props.clickComplete}>
            <View style={Style.nextButtonContainer}>
              <View style={Style.centered}>
                <Icon name="check-circle" size={PR(28)} color="white" />
              </View>
            </View>
          </TouchableOpacity>
        }
      </View>
    </View>
  </View>
)};

const getStyle = (buttonColor: string) => StyleSheet.create({
  centered: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    height: PR(82),
    width: "100%",
  },
  innerContainer: {
    borderTopWidth: PR(1),
    borderTopColor: "#F1EFFF",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignSelf: "center",
    width: '100%',
  },
  leftContainer: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: PR(15),
  },
  nextButtonContainer: {
    borderRadius: PR(25),
    backgroundColor: buttonColor,
    height: PR(52),
    width: PR(92),
  },
  previousButtonContainer: {
    backgroundColor: "white",
    borderWidth: PR(1),
    borderRadius: PR(25),
    borderColor: buttonColor,
    height: PR(52),
    padding: PR(10),
    width: PR(92),
    marginRight: PR(6),
  },
  rightContainer: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

export default CommonChapterNavigation;
