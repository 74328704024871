import { put, takeLatest } from "redux-saga/effects";
import { TimezoneBannerActions, UpdateTimezoneBannerTypes } from './timezoneBannerActions';
import { AnyAction } from "redux";

export function* updateTimezoneBannerSaga(payload: AnyAction & UpdateTimezoneBannerTypes) {
    yield put({ type: TimezoneBannerActions.UPDATE_TIMEZONE_BANNER_SUCCESS, response: payload.bannerExist });
}

export default function* watch() {
    yield takeLatest(TimezoneBannerActions.UPDATE_TIMEZONE_BANNER, updateTimezoneBannerSaga);

}