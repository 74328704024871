import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { View, AppState, StyleSheet, Platform } from 'react-native'
import { useTranslation } from 'react-i18next';
import Text from './CommonCustomTxt';
import CommonBtn from './CommonBtn'
import GlobalStyles from '../styles/GlobalStyles';
import { APP_SHORTCUT_NAME } from '../env.json';
import { getNotificationsAction, deleteNotificationsAction } from '../../../helpilepsy/src/store/actions/authenticationActions';
import { getUniqueEventAction } from '../../../helpilepsy/src/store/treatments/treatmentsActions';
import { getPinStatusAction } from 'helpilepsy/src/store/biometrics/biometricsActions';
import CommonImage from './CommonImage';
import { NOTIFICATIONS_TYPES } from '../services/notifications/index';
import cmsFile from 'common/src/services/localization/en/en.json';
import { widthPercentageToDP as PR } from '../styles/PixelRatio';
import { DateTime } from "luxon";
import Loading from '../../../helpilepsy/src/modules/Loading';
import ReactNativeBiometrics from 'react-native-biometrics';

const rnBiometrics = new ReactNativeBiometrics({allowDeviceCredentials:true})

const excludedNotifType = [
    'notif_medication'
]

const CommonNotifications = ({ action, navigation }) => {
    const { t, i18n } = useTranslation();
    const { language: userLanguage } = i18n;
    const dispatch = useDispatch();
    const today = DateTime.now();
    const Me = useSelector((state:any) => state.getMe.response)
    const GetNotifications = useSelector((state: any) => state.getNotifications.notifications);
    const [isLoading, setIsLoading] = useState(false);
    let goInactiveTimestampt:Date = new Date();
    let goActiveTimestampt:Date = new Date();
    let isUnlockPageActive = false;

    // when that app coming back from background to foreground trigger the get notification request
    useEffect(() => {
        const subscription = AppState.addEventListener("change", handleNextAppState);
        return () => {
            subscription.remove();
        };
    }, []);

    const handleNextAppState = (nextAppState) => {
        if(nextAppState.match(/inactive|background/)){
            goInactiveTimestampt = new Date();
        } 
        else if(nextAppState === "active"){
            goActiveTimestampt = new Date();
        }

        if(Platform.OS !== 'web' ) {
            dispatch(getPinStatusAction(
                {
                    userID: Me?.result?.id,
                    callback: (res) => {
                        isUnlockPageActive = res.message
                    }
                }));
            rnBiometrics.biometricKeysExist()
                .then((resultObject) => {
                    const { keysExist } = resultObject
                    const inactiveTime = Math.floor((goActiveTimestampt.getTime() - goInactiveTimestampt.getTime())/1000); // inactive time to seconds
                    if ((keysExist || isUnlockPageActive) && nextAppState === "active" && inactiveTime >= 60) {
                        navigation.navigate('LoginWithBiometrics')
                    } 
                })
        }
        let dateObj = {from:today.toFormat('yyyy-MM-dd')+'T00:00:01', to:today.toFormat('yyyy-MM-dd')+'T23:59:59'}
        if(nextAppState === "active"){
            dispatch(getNotificationsAction());
            dispatch(getUniqueEventAction(dateObj));
        }
    }

    useEffect(() => {
        navigation.addListener('focus', () => {
            dispatch(getNotificationsAction({
                callback: () => {
                }
            }));
        })
    }, [navigation])

    const onNotifClick = (notification) => {
        action(notification);
        removeNotification(notification.id)
    }

    const removeNotification = (notificationId) => {
        dispatch(deleteNotificationsAction({ notificationId }))
    }

    const defineNotificationText = (notification) => {
        const notifData = notification.data;
        let text = notification.text
        if (notification.data && notification.data.text && excludedNotifType.indexOf(notification.type) === -1)
            text = notification.data.text[userLanguage] || notification.data.text.en;
        else if (cmsFile[`server.notifications.${notification.type}`])
            text = t(`translation:server.notifications.${notification.type}`, { time: notifData.time, name: notifData.name, dose: notifData.dose, unit: notifData.unit, neuro_name: notifData.neuro_name, name_form: notifData.name_form });
        return text
    }

    const renderNotification = (notification) => {
        let notifType: any = NOTIFICATIONS_TYPES.find(item => item.type === notification.type) || NOTIFICATIONS_TYPES.find(item => item.type === 'default');
        let notificationText;

        if (notification.data && notification.data.button_text && notification.data.button_text[userLanguage])
            notificationText = notification.data.button_text[userLanguage];

        else if (notification.button_text && notification.button_text.length > 0)
            notificationText = notification.button_text;

        else
            notificationText = t(`translation:${notifType.btnTitle}`)

        return (
            <View style={styles.container}>
                <View style={styles.iconContainer}>
                    <View style={styles.iconBackground}>
                        <CommonImage name={notifType.icon} width={PR(20)} height={PR(20)} />
                    </View>
                </View>
                <View style={styles.textContainer}>
                    <Text style={{ color: GlobalStyles.global.black }}>{defineNotificationText(notification)}</Text>
                </View>
                <View style={styles.buttonContainer}>
                    <CommonBtn type="secondary" title={notificationText} click={() => { onNotifClick(notification) }} size="small" />
                </View>
            </View>
        )
    }

    return (
        <>
            {
                isLoading &&
                <Loading netConnected={true} />
            }
            {!isLoading && GetNotifications && GetNotifications.map((notif, index) => {
                return (
                    <View key={index}>{renderNotification(notif)}</View>
                )
            })}
            {
                !isLoading && GetNotifications.length === 0 &&
                <Text>{t('translation:dashboard.notifications.no_result')}</Text>
            }
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        justifyContent: 'space-between',
    },
    textContainer: {
        flex: 1,
        flexGrow: 0.7,
        justifyContent: 'center',
        paddingVertical: PR(5),
        marginLeft: PR(7)
    },
    buttonContainer: {
        flex: 1,
        flexGrow: 0.3,
        justifyContent: 'center',
    },
    iconContainer: {
        alignItems: 'center',
        padding: PR(3),
        width: PR(30),
    },
    iconBackground: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor,
        width: PR(30),
        height: PR(30),
        borderRadius: PR(15)
    }
})

export default CommonNotifications