import React, {useState, useEffect} from 'react';
import {View, StyleSheet, SafeAreaView, Platform, ScrollView} from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {useTranslation} from 'react-i18next';
import CommonHeader from "common/src/components/CommonHeader";
import CommonBtn from 'common/src/components/CommonBtn';
import CommonModal from 'common/src/components/CommonModal';
import Text from 'common/src/components/CommonCustomTxt';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonReport from 'common/src/components/CommonReport';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsAction } from '../store/reports/reportsActions';
import Icon from 'react-native-vector-icons/Feather';
import moment from 'moment';

const Reports = ({navigation}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const ReportsResponse = useSelector((state:any) => state.getReports.reports)
  const [scrollState, setScrollState] = useState();
  const [reportInfo, setReportInfo] = useState<boolean>(false);

  const isEmpty = inputObject => {
    return inputObject ? Object.keys(inputObject).length === 0 : true;
  };

  useEffect(() => {
    if(isEmpty(ReportsResponse))
      dispatch(getReportsAction());
  },[])

  const goToCreate = () => {
    navigation.navigate('GenerateReport')
  }

  const updateReportInfoModal = () => {
    setReportInfo(!reportInfo)
  }

  return(
    <SafeAreaView style={styles.wrapper}>
      {
        reportInfo &&
          <CommonModal
            type="info"
            title={t('translation:modal.title.report_Info')}
            text={t('translation:modal.body.report_info')}
            onClose={updateReportInfoModal}
            buttons={[{title: t('translation:modal.btn.ok'), 
            click: updateReportInfoModal }]}
          />
      }
      <CommonHeader
        title={t('translation:general.page_title.reports')} 
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
        rightClickFirst={()=>{setReportInfo(true)}}
        rightIconNameFirst="info"
        rightIconColorFirst={GlobalStyles.helpi.color}
        testIDTitle="report_title"
        scroll={scrollState}
      />
      <ScrollView 
        testID="reports_scrollView"
        scrollEventThrottle={16}
        onScroll={(event: any) => {
          setScrollState(event.nativeEvent.contentOffset.y);
        }}
      >
        {!isEmpty(ReportsResponse) ?
          <CommonReport 
            start={moment(ReportsResponse.startDate).format('DD/MM/yyyy')}
            end={moment(ReportsResponse.endDate).format('DD/MM/yyyy')}
            created={moment(ReportsResponse.createdAt).format('DD-MM-yyyy')}
            downloadLink={ReportsResponse.url}
            testID='report_container'
          />
        :
          <View style={styles.noReportContainer}>
            <Icon name='file' size={PR(35)} color={GlobalStyles.helpi.color} />
            <View style={{margin:PR(5)}}></View>
            <Text testID="no_reports_ID" style={styles.noReportTxt}>{t('translation:reports.no_reports')}</Text>
          </View>
        }
      </ScrollView>
      <CommonBtn type="primary" click={goToCreate} title={t('translation:report.btn.create_new')} testID="report_redirect"/>
      <View style={{marginBottom: PR(15)}}></View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: GlobalStyles.global.white,
    flex: 1,
  },
  noReportContainer: {
    backgroundColor: GlobalStyles.global.footerGrey,
    borderRadius: PR(20),
    width:'90%',
    justifyContent:'center',
    alignSelf: 'center',
    alignItems:'center',
    padding: PR(10)
  },
  noReportTxt: {
    fontFamily: GlobalStyles.global.fontFamily.Bold,
    fontSize: PR(14)
  }
});

export default Reports; 