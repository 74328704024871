import React, {useState, useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import CommonRadioForm from '../CommonAddEvents/CommonRadioForm';
import InputField from '../InputField';
import GlobalStyles from '../../styles/GlobalStyles';
import {useTranslation} from 'react-i18next';
import { DateTime } from "luxon";
import CommonTimeInput from "../CommonTimeInput";
import Text from 'common/src/components/CommonCustomTxt';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';

interface OwnProps {
  NoteData: any;
  isSubmitButtonActive: boolean;
  initialData: any;
  disabled: (data: boolean) => void;
}

const CommonAddNote = ({NoteData, isSubmitButtonActive, initialData, disabled}: OwnProps) => {
  const {t} = useTranslation();
  const [noteType, setNoteType] = useState<string>(initialData?.other_type);
  const [remarks, setRemarks] = useState<string>(initialData?.remark);
  const [answer, setAnswer] = useState<string|undefined>(initialData?.all_day === undefined? undefined: initialData?.all_day? 'yes': 'no');
  const [time, setTime] = useState((initialData?.date? DateTime.fromISO(initialData?.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }) : DateTime.now()).toFormat('HH:mm'));
  
  useEffect(() => {
    if(isSubmitButtonActive)
      sendDataBack()
  },[isSubmitButtonActive])

useEffect(() => {
    if(!checkDisable())
        disabled(false)
    else    
        disabled(true)
}, [answer, noteType])

  const sendDataBack = () => {
    let obj
    switch(answer) {
      case 'yes': 
        obj = {
          type:'other',
          noteType,
          answer:true,
          time: null,
          remarks,
          msg: t('translation:events.other.success')
        }
        break;
      case 'no':
        obj = {
          type:'other',
          noteType,
          answer:false,
          time,
          remarks,
          msg: t('translation:events.other.success')
        }
        break;
      default:
        break;
    }
    
    NoteData(obj)
  }
  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => {return (answer === undefined || !noteType)}
  return (
    <>
      <InputField
        labelText={t('translation:events.other.all_day_label')}
        inputType="dropdown"
        customStyle={{marginBottom:PR(30)}}
        changeText={setAnswer}
        placeholderTxt={t('translation:modal.select.title_one')}
        testID="all_day_option"
        value={answer === undefined ? null :t('translation:modal.btn.'+answer)}
        data={[
          { label: t('translation:modal.btn.yes'), value: 'yes' },
          { label: t('translation:modal.btn.no'), value: 'no' },
        ]}
        isOptional={false}
      />
      {answer === 'no' &&
        <View style={styles.dateInput}>
          <View style={styles.dateInputLabel}>
            <Text style={styles.dateInputLabelText}>{t('translation:events.other.hour')}</Text>
          </View>
          <CommonTimeInput
              onChange={time => setTime(time)}
              value={time}
              fieldStyle={styles.dateInputField}
              textStyle={styles.dateInputFieldText}
              webInputStyle={{backgroundColor: "#F2F4F5", border: "none", width: "100%"}}
          />
        </View>
      }
      <CommonRadioForm
        value={noteType}
        setValue={setNoteType}
        cmsListLink="list.other_event_categories"
        patientList="other_type_inputs"
        label={t('translation:general.events.type')}
        isOptional={false}
      />
      <InputField 
        labelText={t('translation:general.events.remark')}
        inputType="string" 
        customStyle={{marginBottom:PR(30)}}
        changeText={setRemarks}
        placeholderTxt={t('translation:general.events.remark_placeholder')} 
        testID="remarks"
        multiline={true}
        value={remarks}
        isOptional={true}
      />
    </>
  )
}

const styles = StyleSheet.create({
  label: { 
    marginBottom: PR(10),
    marginLeft: PR(20),
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  container: {
    backgroundColor: GlobalStyles.global.lightGrey,
    borderRadius: PR(20),
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: PR(62),
    padding: PR(10),
    fontSize: PR(16),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputValue: {
    flex: 1,
    flexGrow: 1,
    color: GlobalStyles.global.black,
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  switchWrapper:{
    flexDirection:'row',
    alignItems:'center',
    alignSelf:'center',
    width:'90%',
    justifyContent:'space-between',
    flex:1,
    paddingBottom: PR(10),
  },
  txt: {
    fontSize: PR(16),
    marginLeft: PR(15),
    color: GlobalStyles.global.greyToBlack
  },
  dateInput: {
    width:'90%',
    alignSelf:'center',
    marginBottom: PR(30)
  },
  dateInputLabel: {
    marginBottom: PR(10),
    marginLeft: PR(20),
  },
  dateInputLabelText: {
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  dateInputField: {
    backgroundColor: '#F2F4F5',
    borderRadius: PR(20),
    borderColor: '#F2F4F5',
    height: PR(62),
    paddingHorizontal: PR(20),
    paddingVertical: PR(20),
  },
  dateInputFieldText: {
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  }
})

export default CommonAddNote;