import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SafeAreaView, View, ScrollView } from 'react-native';
import Text from '../CommonCustomTxt';
import CommonHeader from '../CommonHeader';
import CommonStyle from './CommonStyle';
import CommonBtn from '../CommonBtn';
import CommonActionModal from '../CommonActionModal';
import CommonModal from '../CommonModal';
import GlobalStyles from '../../styles/GlobalStyles';
import { APP_SHORTCUT_NAME } from '../../env.json';
import { StackNavigationProp } from "@react-navigation/stack";
import { ProgramActionProps, RootStackParams } from "../../types";
import { useTranslation } from "react-i18next";
import { DateTime } from 'luxon';
import { getListCMSData } from '../../helpers/translations';
import moment from 'moment';
import CommonProgramActionHabit from './CommonProgramActionHabit';
import CommonProgramActionOneTime from './CommonProgramActionOneTime';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import { startProgramAction } from '../../../../helpilepsy/src/store/programs/programsActions';


type OwnProps = {
    route: {params: ProgramActionProps},
    navigation: StackNavigationProp<RootStackParams>,
};

const CommonProgramAction = ({ route, navigation }: OwnProps) => {
    const {
        params: { programAction, handleActionSchedule, removeAction },
      } = route;
    const { t, i18n } = useTranslation();
    const { language: userLanguage } = i18n;
    const dispatch = useDispatch();
    const [scrollState, setScrollState] = useState();
    const [actionScheduleModal, setActionScheduleModal] = useState<boolean>(false);
    const [scheduleDateTime, setScheduleDateTime] = useState<string | undefined>(programAction.events[0]?.date ? DateTime.fromISO(programAction.events[0].date).toFormat('dd-MM-yyyy - HH:mm') : undefined)
    const [habitScheduleTime, setHabitScheduleTime] = useState<string | undefined>();
    const [habitScheduleWeekDay, setHabitScheduleWeekDay] = useState<string |undefined>();
    const title:Record<string, string> = programAction?.title || {en:t('translation:general.empty_title')}
    const description:Record<string, string> = programAction?.description || {en:t('translation:general.empty_description')}
    const weekDays = getListCMSData(t, 'general.date.days');
    const actionSchedule = useSelector((state: any) => state.patientProgramsReducers.currentProgramActionSchedule)
    const [isAlredySchedule, setIsAlreadySchedule] = useState(false);
    const [disableSaveBtn, setDisableSaveBtn] = useState(true)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    useEffect(() => {
        if (actionSchedule && actionSchedule.next_action_at && actionSchedule.data.id === programAction.id) {
            setHabitScheduleTime(moment(actionSchedule.next_action_at).format('HH:mm'));
            if (programAction.frequency === 'weekly')
                setHabitScheduleWeekDay(moment(actionSchedule.next_action_at).format('dddd').toLowerCase());
            setIsAlreadySchedule(true)
        }
        else if (programAction.type === 'one_time' && programAction.events[0]) {
            setScheduleDateTime(moment(programAction.events[0].date).format('DD-MM-YYYY - HH:mm'));
            setIsAlreadySchedule(true)
        }
    }, [actionSchedule])

    const updateActionScheduleModal = () => {
        setActionScheduleModal(!actionScheduleModal)
    }

    const actionScheduleModalBtn = [
        {
          type: 'primary',
          title: t('translation:general.header.btn_save'),
          action: 'save',
          click: (time:string, date:string) => {
            updateActionScheduleModal();
            handleActionSchedule(programAction, programAction.id, date, time, programAction.events[0]?.date || undefined)
            navigation.goBack();
          }
        }
    ]

    const saveAction = () => {
        let date = DateTime.now().toFormat('dd-MM-yyyy');
        if (programAction.frequency === 'weekly') {
            let weekDayIndex = weekDays.findIndex(item => item.value === habitScheduleWeekDay);
            weekDayIndex = weekDayIndex === 6 ? 0 : weekDayIndex + 1;
            const todayWeekDay = moment().isoWeekday();
            date = todayWeekDay <= weekDayIndex ? moment().isoWeekday(weekDayIndex).format('DD-MM-yyyy') : moment().add(1, 'week').isoWeekday(weekDayIndex).format('DD-MM-yyyy');
        }
        const oldDate = programAction.frequency === 'weekly' && programAction.events && programAction.events[programAction.events.length -1] ? programAction.events[programAction.events.length -1]?.date : undefined
        handleActionSchedule(programAction, programAction.id, date, habitScheduleTime, oldDate);
        return navigation.goBack()
    }

    const markActionAsDone = () => {
        let eventId: any = null;
        if (programAction.events && programAction.events.length > 0)
            eventId = programAction.events[programAction.events.length - 1].id;
        dispatch(startProgramAction({
            actionId: programAction.id,
            done: false,
            actionEventId: eventId,
          }))
        return navigation.goBack()
    }

    const onHabitTimeChange = (value) => {
        setHabitScheduleTime(value)
        setDisableSaveBtn(false)
    }

    const onHabitWeekDayChange = (value) => {
        setHabitScheduleWeekDay(value)
        setDisableSaveBtn(false)
    }

    const defineStartButtonHabit = () => {
        const status = checkActionCompletion();
        if (programAction.actionBtnNavigation && (programAction.actionBtnNavigation.value === 'mood' || programAction.actionBtnNavigation.value === 'sleep') && (status === 'in_progress' || status === 'completed'))
            return t('translation:general.started')
        if (isAlredySchedule)
            return t('translation:general.header.btn_save')

        if (programAction.frequency === 'daily')
            return t('translation:programs.button.start_today')

        return t('translation:programs.button.start')
    }

    const onDeleteModalConfirmation = () => {
        setDeleteModalVisible(false)
        removeAction(programAction)
        return navigation.goBack();
    }

    const isDisabled = () => {
        const status = checkActionCompletion();
        if (programAction.actionBtnNavigation && (programAction.actionBtnNavigation.value === 'mood' || programAction.actionBtnNavigation.value === 'sleep'))
            return (status === 'in_progress' || status === 'completed')
        return false || disableSaveBtn
    }

    const checkActionCompletion = () => {
        if (!programAction)
            return 'not_selected'
        else {
            if (!programAction.events || programAction.events.length === 0)
                return 'not_started'
            else if (programAction.type === 'one_time')
                return programAction.events[0].done ? 'completed' : 'in_progress';
            else if (programAction.type === 'habit')
                return programAction.events.length === programAction.repetition ? 'completed' : 'in_progress'
        }
    }

    const isActionClosed = () => {
        if (!programAction.events || programAction.events.length === 0)
            return false
        if (programAction.type === 'one_time' && programAction.events[0].done)
            return true
        if (programAction.type === 'habit')
            return programAction.events.length >= programAction.repetition;
    }

    const isActionDoable = () => {
        const status = checkActionCompletion()
        if (programAction.type === 'habit' && programAction.actionBtnNavigation && (programAction.actionBtnNavigation.value === 'mood' || programAction.actionBtnNavigation.value === 'sleep'))
            return false
        else if (status === 'not_selected')
            return false
        else if (status === 'not_started')
            return programAction.type === 'one_time'
        else if (status === 'completed' && programAction.type === 'one_time')
            return false
        else if (status === 'completed' && programAction.type === 'habit' && moment(programAction.events[programAction.events.length - 1].date).isBefore(moment().endOf('day')))
            return false
        else
            return programAction.events && !programAction.events[programAction.events.length - 1].done
    }

    return(
        <SafeAreaView style={CommonStyle.screenContainer}>
            {
            actionScheduleModal &&
                <CommonActionModal
                    testID='medicationIntakeModal'
                    modalType='programActionSchedule'
                    title={'Schedule'}
                    onClose={updateActionScheduleModal}
                    buttons={actionScheduleModalBtn}
                    data={programAction?.events[0]}
                />
            }
            {
                deleteModalVisible &&
                    <CommonModal
                        testID="warningModalID"
                        type={'info'}
                        title={t('translation:modal.titles.warning')}
                        text={t('translation:programs.action.delete.warning_text')}
                        buttons={[
                            {
                                type: 'tertiary',
                                title: t('translation:modal.btn.cancel'),
                                click: () => {setDeleteModalVisible(false)}
                              },
                              {
                                type: 'primary',
                                title: t('translation:modal.btn.confirm'),
                                click: onDeleteModalConfirmation
                              }
                        ]}
                        onClose={() => {setDeleteModalVisible(false)}}
                    />
            }
            <CommonHeader
                title={title[userLanguage] || title.en}
                testIDTitle="Program_action_title"
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles[APP_SHORTCUT_NAME].color}
                leftClick={() => {navigation.goBack()}}
                scroll={scrollState}
            />
            <ScrollView
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                setScrollState(event.nativeEvent.contentOffset.y);
                }}
            >
                <View style={CommonStyle.actionDescriptionContainer}>
                    <Text renderAsHtml={true} testID='description' style={CommonStyle.actionDescriptionTxt}>
                        {description[userLanguage] || description.en}
                    </Text>
                </View>
                {
                    !isActionClosed() && programAction.type === 'one_time' &&
                        <CommonProgramActionOneTime programAction={programAction} scheduledDateTime={scheduleDateTime} updateModalVisibility={setActionScheduleModal} navigation={navigation}/>
                }
                {
                    !isActionClosed() && programAction.type === 'habit' &&
                        <CommonProgramActionHabit programAction={programAction} scheduledTime={habitScheduleTime} scheduledWeekDay={habitScheduleWeekDay} onTimeChange={onHabitTimeChange} onWeekDayChange={onHabitWeekDayChange} weekDaysList={weekDays} navigation={navigation}/>
                }

                <View style={CommonStyle.primaryBtn}>
                    {
                            <CommonBtn
                                type='tertiary'
                                title={t('translation:programs.action.delete.btn_text')}
                                color={GlobalStyles.global.redError}
                                click={() => {setDeleteModalVisible(true)}}
                                testID='delete_action'
                            />
                    }
                    {
                        !isActionClosed() && programAction.type === 'habit' &&
                            <View style={{marginBottom: PR(10)}}>
                                <CommonBtn
                                    type='secondary'
                                    title={defineStartButtonHabit()}
                                    click={saveAction}
                                    testID='done_habit_action'
                                    disable={isDisabled()}
                                />
                            </View>

                    }
                    {
                        isActionDoable() && 
                            <CommonBtn
                                type='primary'
                                title={programAction.type === 'one_time' ? t('translation:general.programs.done_btn') : t('translation:programs.action.done_today_btn')}
                                color={GlobalStyles[APP_SHORTCUT_NAME].btnColor}
                                click={markActionAsDone}
                                testID='done_action'
                            />
                    }
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

export default CommonProgramAction;