import React, {useState} from 'react';
import {StyleSheet, ScrollView, View, TouchableOpacity, Platform} from 'react-native';
import CommonBtn from 'common/src/components/CommonBtn';
import InputField from 'common/src/components/InputField';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonAboutFooter from 'common/src/components/CommonAboutFooter';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { useDispatch } from 'react-redux';
import { loginUserAction } from '../store/actions/authenticationActions';
import { biometricKeyExistsAction } from '../store/biometrics/biometricsActions';
import Text from 'common/src/components/CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { removeBiometricKeysOnFirstLaunch } from 'common/src/services/biometricServices';
import Loading from './Loading';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';


const Login = ({navigation}:any) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const [emailValidated, setEmailValidated] = useState(false);
  const [pwd, setPwd] = useState<string>('');
  const [scrollState, setScrollState] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // keep tracking of the changes of the email input
  const callbackEmail = (text:string) => {
    setEmail(text);
  }

  // keep tracking of the changes of the password input
  const callbackPassword = (text:string) => {
    setPwd(text);
  }

  const checkEmailValidation = (validated: boolean) => {
    setEmailValidated(validated);
  }

  // post request in order to login to the app
  const signIn = async () => {
    if(Platform.OS !== 'web')
      removeBiometricKeysOnFirstLaunch({callback: dispatch(biometricKeyExistsAction({key:false}))}); // remove biometrics keys form keychain before login
    let obj = {email:email, password:pwd, navigation, language: await AsyncStorage.getItem('APP_LANG') || 'en'}
    const setLogin = () => {
      setIsLoading(true)
      return loginUserAction(
        {...obj, 
          errorCallback: () => {
            setIsLoading(false)
          }
        })
    };
    dispatch(setLogin());
  }

  // function that send you to register page
  const signUp = () => {
    navigation.navigate('Register');
  }

  // function that send you to password forgotten page
  const passForgot = () => {
    navigation.navigate('PasswordForgotten');
  }
  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => {return (email.length === 0 || pwd.length === 0 || !emailValidated )}

  if (isLoading)
    return <Loading netConnected={true}/>

  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader
        title={t('translation:login.title_login')}
        rightIconNameSecond="settings"
        rightIconColorSecond={GlobalStyles.helpi.color}
        rightClickSecond={() => navigation.navigate("Settings")}
        scroll={scrollState}
        testIDTitle="login"
        disableFormsBanner={true}
      />
      <View style={styles.scrollViewWrapper}>
        <ScrollView 
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            setScrollState(event.nativeEvent.contentOffset.y);
          }}
        >
          <InputField 
            labelText={t('translation:login.form.email')}
            inputType="email" 
            customStyle={{marginBottom:PR(30)}}
            changeText={callbackEmail}
            placeholderTxt={t('translation:login.form.email_placeholder')}
            testID="Email"
            isValidationRequired={true}
            changeValidation={checkEmailValidation}
            value={email}
          />
          <InputField 
            labelText={t('translation:login.form.password')}
            inputType="password"  
            customStyle={{marginBottom:PR(30)}}
            changeText={callbackPassword} 
            placeholderTxt={t('translation:login.form.email_placeholder')}
            testID="Password"
            value={pwd}
          />
          <CommonBtn testID="Log In" type="primary" click={signIn} title={t('translation:login.form.btn_login')} disable={checkDisable()} />
          <TouchableOpacity onPress={passForgot} testID="pass_forgot">
            <Text style={styles.nfo}>{t('translation:login.form.password_forgot')}</Text>
          </TouchableOpacity>
          <Text style={styles.nfo}>{t('translation:login.no_account_yet')}</Text>
          <CommonBtn type="secondary" click={signUp} title={t('translation:login.form.btn_register')} testID="register"/>
          <CommonAboutFooter />
          </ScrollView>
      </View>
    </CommonKeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
   },
  nfo: {
    fontSize: PR(14),
    lineHeight: PR(20),
    color: GlobalStyles.global.grey,
    alignSelf: 'center',
    marginTop: PR(10),
    marginBottom: PR(10)
  }
});

export default Login;