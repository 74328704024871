import { call, takeLatest, put } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";
import { 
    NightwatchActions,
    GetNightwatchAccountInterface,
    LoginToNightwatchInterface,
    LogoutFromNightwatchInterface
} from "./nightwatchActions";
import * as types from '../actions'

export function* getNightwatchAccountSaga(payload: AnyAction & GetNightwatchAccountInterface) {
    yield call(makeRequest, {
        path: `/nightwatch/account/${payload.patientId}`,
        method: 'GET',
        responseAction: NightwatchActions.GET_NIGHTWATCH_ACCOUNT_SUCCESS,
        ...payload
    });
}

export function* loginToNightWatchSaga(payload: AnyAction & LoginToNightwatchInterface) {
    const response = yield call(makeRequest, {
        path: '/nightwatch/link/',
        method: 'POST',
        data: payload.credentials,
        ...payload
    });
    if (response) {
        yield put ({ type: types.SUCCESS,
            message: payload?.t?.(
                'translation:settings.nightwatch.link_success_message',
                {recordingsCount: response.recordingsCount, seizuresCount:response.seizuresCount}
            )
        });
    }
}

export function* logoutFromNightWatchSaga(payload: AnyAction & LogoutFromNightwatchInterface) {
      const response = yield call(makeRequest, {
        path: '/nightwatch/unlink/',
        method: 'POST',
        ...payload
      });
      if (response.success) {
        yield put ({ type: NightwatchActions.LOGOUT_FROM_NIGHTWATCH_SUCCESS, response: undefined});
        yield put ({ type: types.SUCCESS, message: payload.msg});
      } else {
          yield put({ type: types.ERROR, message: 'Bad Gateway' })
      }
  }

export default function* watch() {
    yield takeLatest(NightwatchActions.GET_NIGHTWATCH_ACCOUNT, getNightwatchAccountSaga);
    yield takeLatest(NightwatchActions.LOGIN_TO_NIGHTWATCH, loginToNightWatchSaga);
    yield takeLatest(NightwatchActions.LOGOUT_FROM_NIGHTWATCH, logoutFromNightWatchSaga);
}