import React, { ReactNode } from "react";
import { KeyboardAvoidingView, Platform, SafeAreaView, StyleSheet } from "react-native";
import GlobalStyles from "../styles/GlobalStyles";

type OwnProps = {
    children: ReactNode;
}

const CommonKeyboardAvoidingView = ({ children }: OwnProps) => {
    return (
        <SafeAreaView style={ styles.safeAreaContainer }>
            <KeyboardAvoidingView style={styles.keyboardAvoidingViewContainer} behavior={ Platform.OS==='ios'?"padding":"height" }>
                {children}
            </KeyboardAvoidingView>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    safeAreaContainer: {
      flex: 1,
      backgroundColor: GlobalStyles.global.white,
    },
    keyboardAvoidingViewContainer: {
        display: "flex",
        flex:1,
    }
});

export default CommonKeyboardAvoidingView;