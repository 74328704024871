import { call, takeLatest, select } from "redux-saga/effects";
import { ChapterAction, GetPatientProgramActionEvents, PatientProgramActions, UpdateChapterAction, UpdateQuestionnaireAction, GetPatientProgramActionSchedule } from './patientProgramsActions';
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";

export function* updateChapterSaga(payload: AnyAction & UpdateChapterAction) {
    yield call(makeRequest, {
        path: `/patient_programs/${payload.patientProgramId}/education/${payload.educationModuleId}/chapter/${payload.chapterId}`,
        method: 'PATCH',
        responseAction: PatientProgramActions.UPDATE_CHAPTER_LAST_PAGE_SUCCESS,
        data: {
            lastPage: payload.lastPage,
        },
    });
}

export function* completeChapter(payload: AnyAction & ChapterAction) {
    yield call(makeRequest, {
        path: `/patient_programs/${payload.patientProgramId}/education/${payload.educationModuleId}/chapter/${payload.chapterId}/complete`,
        method: 'POST',
        responseAction: PatientProgramActions.COMPLETE_CHAPTER_SUCCESS,
    });
}

export function* updateQuestionnaire(payload: AnyAction & UpdateQuestionnaireAction) {
    yield call(makeRequest, {
        path: `/patient_programs/${payload.patientProgramId}/questionnaires`,
        method: 'PATCH',
        responseAction: PatientProgramActions.UPDATE_QUESTIONNAIRE_SUCCESS,
        data: payload.answers
    })
}

export function* getPatientProgramActionEvents(payload: AnyAction & GetPatientProgramActionEvents) {
    yield call(makeRequest, {
        path: `/patient_programs/${payload.patientProgramId}/actions_events`,
        method: 'GET',
        responseAction: PatientProgramActions.GET_PATIENT_PROGRAM_ACTION_EVENTS_SUCCESS,
        ...payload
    })
}

export function* getPatientProgramActionSchedule(payload: AnyAction & GetPatientProgramActionSchedule) {
    const state = yield select()
    const patientId = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        path: `/patient_programs/${patientId}/actions/${payload.programActionId}/getSchedule`,
        method: 'GET',
        responseAction: PatientProgramActions.GET_PATIENT_PROGRAM_ACTION_SCHEDULE_SUCCESS,
        ...payload
    })
}

export default function* watch() {
    yield takeLatest(PatientProgramActions.UPDATE_CHAPTER_LAST_PAGE, updateChapterSaga);
    yield takeLatest(PatientProgramActions.COMPLETE_CHAPTER, completeChapter);
    yield takeLatest(PatientProgramActions.UPDATE_QUESTIONNAIRE, updateQuestionnaire);
    yield takeLatest(PatientProgramActions.GET_PATIENT_PROGRAM_ACTION_EVENTS, getPatientProgramActionEvents)
    yield takeLatest(PatientProgramActions.GET_PATIENT_PROGRAM_ACTION_SCHEDULE, getPatientProgramActionSchedule)

}
