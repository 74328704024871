import React, {useState, useEffect, useRef} from 'react';
import {SafeAreaView, StyleSheet, Linking, View, ScrollView, Platform} from 'react-native'; 
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonModal from 'common/src/components/CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import { updateConsentAction, logOutAction, getMeAction } from '../store/actions/authenticationActions';
import Text from 'common/src/components/CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import CommonAcceptanceCheckbox from 'common/src/components/CommonAcceptanceCheckbox';
import CommonFooter from 'common/src/components/CommonFooter';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import Icon from 'react-native-vector-icons/Feather';
import { TouchableOpacity } from 'react-native';
import { APP_SHORTCUT_NAME } from '../env.json';

const PrivacyConsent = ({navigation, route}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const GetMeConsent = useSelector((state:any) => state.getMe.consent);
  const [scrollState, setScrollState] = useState();
  const [contentHeight, setContentHeight] = useState<number>();
  const [warningModalVisible, setWarningModalVisible] = useState<boolean>(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const [checkBoxAnswer, setCheckBoxAnswer] = useState<boolean>();
  const [disableUpdate, setDisableUpdate] = useState<boolean>(true);
  const [disableArrows, setDisableArrows] = useState<any>({display: 'flex', disabled: false});
  const scrollViewRef = useRef<any>(null);
  let hrefPrivacy = t('translation:settings.support.privacy_url');
  let hrefTerms = t('translation:settings.support.terms_url');
  Icon.loadFont();
  const [isLogoutTriggered, setLogoutTriggerValue] = useState<boolean>(false);

  // checking if the redux state is empty 
  useEffect(() => {
    if(GetMeConsent === undefined && !isLogoutTriggered) {
      dispatch(getMeAction());
    } else {
      setCheckBoxAnswer(GetMeConsent)
    }
  },[GetMeConsent]);
  // logout function
  const setLogout = () => {
    setLogoutTriggerValue(true)
    dispatch(logOutAction());
    navigation.navigate('Welcome');
    navigation.reset({
      index: 0,
      routes: [{ name: 'Welcome' }],
    });
  }
  // function when you click update
  const next = () => {
    const obj = {consent:checkBoxAnswer, navigation}
    if(route.params.param && route.params.param !== 'false') {
      if(checkBoxAnswer) {
        navigation.navigate('Settings')
      } else {
        callbackVisibleConfirmation()
      }
    } else {
      if(checkBoxAnswer) {
        dispatch(updateConsentAction(obj))
      } else {
        callbackVisibleWarning()
      }
    }
  }
  const callbackVisibleWarning = () => {
    setWarningModalVisible(!warningModalVisible);
  }
  const callbackVisibleConfirmation = () => {
    setConfirmModalVisible(!confirmModalVisible);
  }
  const notConsent = () => {
    let obj = {consent:false, navigation}
    dispatch(updateConsentAction(obj))
  }
  const warningModalBtn = [{
    type: 'primary',
    title: t('translation:modal.btn.close'),
    click: callbackVisibleWarning
  }]
  const confirmModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackVisibleConfirmation
    },
    {
    type: 'primary',
    title: t('translation:modal.btn.confirm'),
    click: notConsent
    }
  ]
  
  const scrollToBottom = () => {
    scrollViewRef.current?.scrollToEnd({animated: true});
  };
  
  const allowClick = (nativeEvent) => {
    const bottom = nativeEvent.layoutMeasurement.height + nativeEvent.contentOffset.y >= nativeEvent.contentSize.height - 5
    if(bottom) 
      setDisableUpdate(false);
  };
  
  const highResolution = (event) => {
    const {height, width} = event.nativeEvent.layout;
    if(height >= 975) {
      setDisableUpdate(false);
      setDisableArrows({disabled: true, display: 'none'});
    }
  }

  const removeArrows = (nativeEvent) => {
    const scrolledDown = nativeEvent.layoutMeasurement.height + nativeEvent.contentOffset.y >= 20
    if(scrolledDown)
      setDisableArrows({disabled: true, display: 'none'});
  }

  return (
      <SafeAreaView style={styles.container}>
        {route.params.param ?
          <CommonHeader 
            leftIconName="arrow-left"
            leftIconColor={GlobalStyles.helpi.color}
            leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
            title={t('translation:consent.title')}
            scroll={scrollState}
          />
          :
          <CommonHeader 
            rightIconNameFirst="log-out"
            rightIconColorFirst={GlobalStyles.helpi.btnColor}
            rightClickFirst={setLogout}
            title={t('translation:consent.title')}
            scroll={scrollState}
            disableFormsBanner={true}
          />
        }
        <View style={styles.scrollViewWrapper} onLayout={highResolution}>
          <ScrollView
            ref={scrollViewRef}
            style={styles.scrollview}
            scrollEventThrottle={16}
            onScroll={(event: any) => {
              setScrollState(event.nativeEvent.contentOffset.y);
              allowClick(event.nativeEvent);
              removeArrows(event.nativeEvent);
            }}
            
          >
            <View
              onLayout={(event: any) => {
                setContentHeight(event.nativeEvent.layout.height)
              }}
            >
              
              <View style={styles.txtContainer}>
                <Text renderAsHtml={true} style={styles.txtStyle}>{t('translation:consent.text')}</Text>
                <Text style={[styles.txtStyle,{color:'blue', alignSelf:'flex-end'}]} 
                  onPress={() => Platform.OS === 'web' ? window.open(hrefPrivacy, '_blank') : Linking.openURL(hrefPrivacy)}>
                  {t('translation:consent.privacy_policy')}
                </Text>
                <Text style={[styles.txtStyle,{color:'blue', alignSelf:'flex-end'}]} 
                  onPress={() => Platform.OS === 'web' ? window.open(hrefTerms, '_blank') : Linking.openURL(hrefTerms)}>
                  {t('translation:settings.support.terms')}
                </Text>
              </View>
            </View>
          </ScrollView>
          <View>
            <TouchableOpacity disabled={disableArrows.disabled} style={[styles.iconContainer, {display: disableArrows.display}]} onPress={scrollToBottom} testID='scrolldown_btn'>
              <Icon name="chevrons-down" size={PR(40)} color={GlobalStyles.global.white}/>  
            </TouchableOpacity>
          </View>
          <CommonFooter style={styles.footer} atBottom={contentHeight}>
              <View style={styles.checkboxContainer}>
                {checkBoxAnswer !== undefined && 
                  <CommonAcceptanceCheckbox disabled={disableUpdate} testID="acceptanceCheckbox" text={t('translation:consent.consent_text')} updateValidation={setCheckBoxAnswer} checked={checkBoxAnswer}/>
                }
              </View>
              <View style={styles.updateButton}>
                <CommonBtn type='primary' click={next} title={t('translation:consent.btn_update')} disable={disableUpdate}  testID="consent_btn"/>
              </View>
          </CommonFooter>
        </View>
        { warningModalVisible &&
          <CommonModal 
            testID="warningModal" 
            type={'warning'} 
            title={'Warning'} 
            text={t('translation:consent.must_accept')}
            buttons={warningModalBtn} onClose={callbackVisibleWarning}
          />
        }
        { confirmModalVisible &&
          <CommonModal 
            testID="warningModal" 
            type={'warning'} 
            title={t('translation:consent.confirm_disagree.title')}
            text={t('translation:consent.confirm_disagree.text')}
            buttons={confirmModalBtn} onClose={callbackVisibleConfirmation}
          />
        }
      </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: GlobalStyles.global.white,
    flex: 1,
  },
  scrollViewWrapper: {
    position: 'relative',
    marginTop: PR(5),
    flex: 1,
    paddingBottom: Platform.OS === 'ios'? 0:PR(30)
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
  },
  imgContainer: {
    marginTop:PR(20),
    justifyContent:'center',
    alignItems:'center'
  },
  txtContainer:{
    width:'90%',
    alignSelf:'center',
    paddingBottom: PR(25)
  },
  txtStyle: {
    marginTop: PR(10),
    textAlign:'left',
    fontSize: PR(14)
  },
  checkboxContainer: {
    width:'90%',
    alignSelf:'center', 
    paddingVertical: PR(25),
  },
  updateButton: {
    width: '90%',
    alignSelf:'center'
  },
  footer: {
    width: '100%',
    alignSelf: 'center'
  },
  iconContainer: {
    position: 'absolute',
    bottom: PR(20),
    right: PR(40),
    backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].color,
    borderRadius: PR(50),
  }
});

export default PrivacyConsent;