import React from "react";
import { View } from "react-native";
import {
  EducationModule,
  PatientEducationTrack,
  ProgramEducationModule,
  ProgressBarStyleProps,
} from "../../types";
import CommonEducationModuleListItem from "./CommonEducationModuleListItem";
import { getEducationModulesByFeatured } from "./util";

interface OwnProps {
  educationModules: EducationModule[];
  programEducationModules: ProgramEducationModule[];
  patientStartDate: string;
  progressBarStyle: ProgressBarStyleProps;
  educationTracks?: PatientEducationTrack;
  onClick: (id:string) => void;
}

const CommonEducationListByFeatured = (props: OwnProps) => {
  const {
    educationModules,
    progressBarStyle,
    educationTracks,
    programEducationModules,
    patientStartDate,
    onClick
  } = props;

  const { featured, notFeatured } = getEducationModulesByFeatured(
    programEducationModules,
    patientStartDate,
    educationModules
  );

  return (
    <>
      <View testID="featured-education-list">
        {featured.map((education: EducationModule, index: number) => (
          <View
            testID={"education_module_" + index}
            key={index}
            style={{ width: "90%", alignSelf: "center" }}
          >
            <CommonEducationModuleListItem
              educationModule={education}
              handleClick={() => { onClick(education.id); }}
              style={progressBarStyle}
              patientEducationTrack={educationTracks}
              isFeatured={true}
            />
          </View>
        ))}
      </View>
      {notFeatured
        .map((education: EducationModule, index: number) => (
          <View
            testID={"education_module_" + index}
            key={index}
            style={{ width: "90%", alignSelf: "center" }}
          >
            <CommonEducationModuleListItem
              educationModule={education}
              handleClick={() => { onClick(education.id); }}
              style={progressBarStyle}
              patientEducationTrack={educationTracks}
              isFeatured={false}
            />
          </View>
        ))}
    </>
  );
};

export default CommonEducationListByFeatured;
