import { call, takeLatest } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";
import { DeviceDetailsActions, DeviceOsVersionInterface } from "./deviceDetailsActions";

export function* deviceOsVersionSaga(payload: AnyAction & DeviceOsVersionInterface) {
    yield call(makeRequest, {
        data: payload,
        path: `/me`,
        method: 'PUT',
        ...payload
    });
}

export default function* watch() {
    yield takeLatest(DeviceDetailsActions.DEVICE_OS_VERSION, deviceOsVersionSaga);
}