import { fork } from 'redux-saga/effects';
import watchUserAuthentication from './watchers';
import userSagas from '../user/userSagas';
import treatmentsSagas from '../treatments/treatmentsSagas';
import editTreatmentsSagas from '../treatments/editTreatmentSagas';
import editDoseSagas from '../treatments/editDoseSagas';
import deleteTreatmentsSagas from '../treatments/deleteTreatmentSagas';
import deleteDoseSagas from '../treatments/deleteDoseSagas';
import getDrugsSagas from '../drugs/drugsSagas';
import eventSagas from '../events/eventSagas';
import fb_tokenSaga from '../FB-token/fb-tokenSagas';
import getListOfLang from '../list_of_languages/listOfLangSagas';
import updateTimezone from '../timezones/changeTimezoneSagas';
import programsSagas from '../programs/programsSagas';
import reports from '../reports/reportsSagas';
import patientProgramsSaga from '../programs/patientProgramsSaga';
import epilepsyDetailsSagas from '../epilepsyDetails/epilepsyDetailsSagas';
import updateTimezoneBannerSaga  from '../timezoneBanner/timezoneBannerSaga';
import biometricSaga from '../biometrics/biometricSaga';
import deviceDetailsSaga from '../deviceDetails/deviceDetailsSaga';
import nightwatchSaga from '../nightwatch/nightwatchSagas';
import profileDetailsSaga from '../profileDetails/profileDetailsSagas';
import emailExistanceSaga from "../emailExistanceOnRegistration/emailExistanceSaga";

// allows us to run some tasks in a parallel fashion
// Forking tasks will make them non-blocking so they will run smoothly in the background
export default function* startForman() {
	yield fork(watchUserAuthentication);
	yield fork(userSagas);
	yield fork(treatmentsSagas);
	yield fork(editTreatmentsSagas);
	yield fork(editDoseSagas);
	yield fork(deleteTreatmentsSagas);
	yield fork(deleteDoseSagas);
	yield fork(getDrugsSagas);
	yield fork(eventSagas);
	yield fork(getListOfLang);
	yield fork(fb_tokenSaga);
	yield fork(updateTimezone);
	yield fork(programsSagas);
	yield fork(reports);
	yield fork(patientProgramsSaga);
	yield fork(epilepsyDetailsSagas);
	yield fork(updateTimezoneBannerSaga);
	yield fork(biometricSaga);
	yield fork(deviceDetailsSaga);
	yield fork(nightwatchSaga);
	yield fork(profileDetailsSaga);
	yield fork(emailExistanceSaga);
}