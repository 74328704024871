import React, {useEffect} from 'react';
import CommonImpactContainer from 'common/src/components/CommonImpact/CommonImpactContainer';
import { useSelector, useDispatch } from 'react-redux';
import { getImpactEvents, getTriggersEvents, getAurasEvents } from '../../store/events/eventActions';
import { useTranslation } from 'react-i18next';
import { useIsFocused } from '@react-navigation/native';
import moment from 'moment';

const ImpactTab = () => {
    const isFocused = useIsFocused();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const Me = useSelector((state:any) => state.getMe.response);
    const impactSeizures = useSelector((state:any) => state.getEvents.impact_seizure_events);
    const impactTriggers = useSelector((state:any) => state.getEvents.impact_triggers_events);
    const impactAuras = useSelector((state:any) => state.getEvents.impact_auras_events);
    const impactMood = useSelector((state:any) => state.getEvents.impact_mood_events);
    const impactSleep = useSelector((state:any) => state.getEvents.impact_sleep_events);
    const impactAdherence = useSelector((state:any) => state.getEvents.impact_adherence_events);

    useEffect(() => {
        let obj = {
            date: {
                from: moment().subtract(5, 'week').startOf('isoWeek').format(),
                to: moment().endOf('isoWeek').format(),
            } 
        }
        // the first time length is zero for all of them
        if(impactSeizures.length === 0 || impactMood.length === 0 || impactSleep.length === 0 || impactAdherence.length === 0){
            Object.assign(obj, {event_type: 'seizure'})
            dispatch(getImpactEvents({
                obj,
                callback: () => {
                    Object.assign(obj, {event_type: 'mood'})
                    dispatch(getImpactEvents({
                        obj,
                        callback: () => {
                            Object.assign(obj, {event_type: 'sleep'})
                            dispatch(getImpactEvents({
                                obj,
                                callback: () => {
                                    Object.assign(obj, {event_type: 'reminder'})
                                    dispatch(getImpactEvents({obj}))
                                }
                            }));
                        }
                    }));
                }
            }));
        }
        if(impactTriggers.length === 0){
            dispatch(getTriggersEvents({
                field: ["triggers"],
                event_type: 'seizure',
                from: moment().subtract(6, 'months').startOf('day').format('YYYY-MM-DDTHH:mm'),
                to: moment().endOf('day').format('YYYY-MM-DDTHH:mm')
            }))
            
        }

        if(impactAuras.length === 0) {
            dispatch(getAurasEvents({
                field: 'auras',
                from: moment().subtract(6, 'months').startOf('day').format('YYYY-MM-DDTHH:mm'),
                to: moment().endOf('day').format('YYYY-MM-DDTHH:mm')
            }));
        }
        // TODO: add extra conditions for the mood and sleep
    },[isFocused])

    const loadPrevEvents = (from:string, to:string, event_type: string) => {
        let obj = {
            date: {
                from,
                to
            },
            event_type
        }
        dispatch(getImpactEvents({
            obj,
        }));
    }

    const triggerAuraArray = (triggerAuraType: string) => {
        if(impactTriggers.length > 0)
            return impactTriggers.filter(item => {
                if(item[triggerAuraType] !== undefined)
                    return item
            })
        else 
            return []
    }
    
    return(
        <CommonImpactContainer
            data={[
                {itemType:'seizures', itemTitle: t('translation:list.events_types.seizure'), icon: 'zap', Me, Events: impactSeizures},
                {itemType:'mood', itemTitle: t('translation:list.events_types.mood'), icon: 'sun', Events: impactMood},
                {itemType:'sleep', itemTitle: t('translation:list.events_types.sleep'), icon: 'moon', Events: impactSleep},
                {itemType:'triggers', itemTitle: t('translation:events.seizure.triggers'), icon: 'zap', Events: triggerAuraArray('triggers')},
                {itemType:'auras', itemTitle: t('translation:events.seizure.auras'), icon: 'zap', Events: impactAuras},
                {itemType:'reminder', itemTitle: t('translation:reports.medication_adherence.general.title'), icon: 'plus-square', Events: impactAdherence},
            ]}
            callbackRequest={loadPrevEvents}
        />
    )
}

export default ImpactTab;