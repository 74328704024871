import React, {useEffect, useState} from 'react';
import { View, ScrollView, Platform, StyleSheet, TouchableOpacity, SafeAreaView  } from 'react-native';
import {useTranslation} from 'react-i18next';
import Text from './CommonCustomTxt';
import CommonHeader from './CommonHeader';
import CommonImage from './CommonImage';
import GlobalStyles from '../styles/GlobalStyles';
import CommonSubTitle from './CommonSubtitle';
import CommonModal from './CommonModal';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../env.json'
import { useSelector } from 'react-redux';
import {DateTime as luxonDT} from 'luxon';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonStudyOverview = (props) => {
    const {t, i18n} = useTranslation();
    const {language: userLanguage} = i18n;
    const [scrollState, setScrollState] = useState();
    const [seizureModal, setSeizureModal] = useState(false);
    const study = props.study ? props.study : props.route.params.study;
    const patientId = props.patientId ? props.patientId : props.route.params.patientId;
    const siteId = props.siteId ? props.siteId : props.route.params.siteId;
    const patientSiteData = props.patientSiteData ? props.patientSiteData : props.route.params.patientSiteData;
    const contactEmail = props.contactEmail ? props.contactEmail : props.route?.params.contactEmail;
    const saveForm = props.saveForm || props.route.params.saveForm
    const patientForms = useSelector((state:any) => state.getMe.patientForms);
    const contactBlock = props.contactBlock ? props.contactBlock : props.route?.params.contactBlock;
    const [seizureCount, setSeizureCount] = useState(patientSiteData.seizureCount || 0)
    const [formsToAnswer, setFormsToAnswer] = useState([])

    const sectionOnClick = (section) => {
        const type = section.type;
        if (type === 'information' || type === 'consent' || type === 'forms' || type === 'contact')
            props.navigation.navigate('StudySection', {section: section, study: study, patientId: patientId, siteId: siteId, contactEmail: contactEmail, contactBlock: contactBlock, saveForm})
        else if (type === 'seizure') {
            if (seizureCount < 2)
                updateSeizureModalVisibily();
            else
                props.navigation.navigate('AddEvent', {itemType: 'Seizure'});
        }
    }

    const updateSeizureModalVisibily = () => {
        setSeizureModal(!seizureModal)
    }

    const addSeizureOnClick = () => {
        if (seizureCount < 2) {
            props.updatePatientSite ? props.updatePatientSite({siteId, patientId, data: {seizureCount: seizureCount + 1 }}) : props.route?.params.updatePatientSite({siteId, patientId, data: {seizureCount: seizureCount + 1 }})
            setSeizureCount(seizureCount + 1)
        }
        updateSeizureModalVisibily();
        props.navigation.navigate('AddEvent', {itemType: 'Seizure'});
    }

    const now = luxonDT.now();

    useEffect(() => {
        const formsFound = patientForms.
            filter(({send_date}) => send_date && luxonDT.fromISO(send_date) < now)
            .filter(({expiration}) => !expiration || luxonDT.fromISO(expiration) > now)
            .filter(({site}) => site && site === siteId)

        setFormsToAnswer(formsFound)
    }, [patientForms])
    

    return (
        <SafeAreaView style={styles.container}>
            {
                seizureModal && 
                    <CommonModal 
                        type="info"
                        title={t('translation:studies.reportSeizure.title')}
                        text={study.seizurePopup ? study.seizurePopup[userLanguage] || study.seizurePopup.en : t('translation:studies.reportSeizure.text').replace('$name', study.shortName[userLanguage] ||  study.shortName.en)}
                        onClose={updateSeizureModalVisibily}
                        buttons={[{title: t('translation:studies.reportSeizure.action'), click: addSeizureOnClick}]}
                    />
            }
            {
                props.route ?
                    <CommonHeader
                        testIDTitle="study_overview_title"
                        title={study.shortName[userLanguage] || study.shortName.en}
                        leftIconName="arrow-left"
                        leftIconColor={GlobalStyles.helpi.color}
                        leftClick={() => {Platform.OS === 'web' ? window.history.back() : props.navigation.goBack()}}
                        scroll={scrollState}
                    />
                :
                    <CommonHeader
                        testIDTitle="study_overview_title"
                        title={study.shortName[userLanguage] || study.shortName.en}
                        scroll={scrollState}
                    />
            }
            
            <ScrollView 
                testID="study_overview_scrollView"
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
            >
                <CommonImage name="" uri={study.logo} height={PR(180)} width={PR(360)} style={{alignSelf: 'center'}}/>
                <View style={{margin: PR(5), justifyContent: 'center', alignItems: 'center'}}>
                   <CommonSubTitle txtAlign="center" text={study.description[userLanguage] || study.description.en}/>
                </View>
                {
                    study.blocks.map((section, index) => (
                        <TouchableOpacity key={index} style={[styles.sectionContainer, {backgroundColor: section.color}, section.type === 'contact' ? styles.shadowBox : {}]} onPress={() => sectionOnClick(section)}>
                            <View style={styles.sectionIcon}>
                                <CommonImage name={`${section.type}StudyIcon`}/>
                            </View>
                                <View style={styles.sectionTitleBox}>
                                    <Text style={[styles.sectionTitle, {color : section.type === 'contact' ? GlobalStyles[APP_SHORTCUT_NAME].color : GlobalStyles.global.white}]}>{section.title[userLanguage] || section.title.en}</Text>
                                    {
                                        section.type === 'seizure' &&
                                            <Icon style={{marginLeft: PR(10)}} name='info' color={"white"} size={PR(25)} />
                                    }
                                </View>
                                {
                                        formsToAnswer && formsToAnswer.length > 0 &&
                                            section.type === 'forms' && <View style={styles.formsCountBadge}><Text style={styles.formsCountText}>{ formsToAnswer.length }</Text></View>
                                }
                        </TouchableOpacity>
                    ))
                }
            </ScrollView>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: GlobalStyles.global.white,
    },
    scrollViewWrapper: {
        marginTop: PR(5),
        flex: 1,
    },
    sectionContainer: {
        width: '90%',
        height: PR(120),
        borderRadius: PR(5),
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: PR(25),
    },
    sectionTitle: {
        fontFamily: GlobalStyles.global.fontFamily.Bold,
        fontSize: PR(16)
    },
    sectionTitleBox: {
        flex: 1,
        flexGrow: 0.6,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sectionIcon: {
        flex: 1,
        flexGrow: 0.3
    },
    shadowBox: {
        shadowColor: "#000",
        // shadowOffset: {
        //     width: 0,
        //     height: 3,
        // },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 6
    },
    formsCountBadge: {
        backgroundColor: '#fb667a',
        position: 'absolute',
        width: PR(30),
        height: PR(30),
        top: PR(-10),
        right: PR(-10),
        borderRadius: PR(100),
        textAlign: 'center',
        textAlignVertical: 'center'
    },
    formsCountText: {
        color: 'white',
        padding: PR(8),
        left: PR(3),
        fontWeight: '500'
    }
})

export default CommonStudyOverview;