import React from 'react';
import {View, StyleSheet} from 'react-native';
import Globalstyles from '../styles/GlobalStyles';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

type IProps = {
  text: string,
  color: string,
  txtAlign?: "center" | "auto" | "left" | "right" | "justify",
  size?: number,
  testID?: string
}

const CommonTitle = ({text, color, txtAlign, size, testID}:IProps) => {
  txtAlign = txtAlign || "center";
  size = size || 16;
  return (
    <>
      <View>
         <Text testID={testID} style={styles(txtAlign, color, size).title}>{text}</Text>
      </View>
    </>
  );
}

const styles = (txtAlign: any, color?: any, size?: number) => StyleSheet.create({
  title: {
    color: color,
    marginBottom: PR(20),
    fontFamily: Globalstyles.global.fontFamily.Bold,
    fontSize: PR(size),
    textAlign: txtAlign,
  }
});

export default CommonTitle;