/**
 * @format
 */
 import React, {Suspense} from 'react';
 import i18next from 'i18next';
 import { I18nextProvider } from 'react-i18next';
 import Loading from './modules/Loading';
 import {AppRegistry} from 'react-native';
 import App from './App';
 import {name as appName} from './app.json';
 import { Provider } from 'react-redux';
 import './services/WebFonts';
 import './WebStyles/index.css';
 import Text from 'common/src/components/CommonCustomTxt';
 
 import configureStore from './store/configureStore/configureStore';
 
 const store = configureStore();
 
 const RNRedux = () => (
     <Provider store = { store }>
       <Suspense fallback={<Loading />}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </Suspense>
     </Provider>
   )
 
 
 AppRegistry.registerComponent(appName, () => RNRedux);
 AppRegistry.runApplication(appName, {
     rootTag: document.getElementById('root'),
 });
 