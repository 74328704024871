import React, {useState, useRef, useEffect} from 'react';
import {
    View,
    StyleSheet,
    PanResponder,
    TouchableOpacity,
    Platform
  } from 'react-native';
import {APP_SHORTCUT_NAME} from '../env.json';
import GlobalStyles from '../styles/GlobalStyles';
import Text from './CommonCustomTxt';
import CommonBtn from './CommonBtn';
import Svg,{Path,Circle,G} from 'react-native-svg';
import Icon from 'react-native-vector-icons/Feather';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const MoodSleep = ({value}) => {
  return (
    <>
      {value === null ? <Text></Text>
      : value >= 7 ?
        <Icon 
          name={"smile"} 
          size={PR(120)} 
          color={"#1de9b6"} 
        />
      : value >= 4 && value <= 6 ?
        <Icon 
          name={"meh"} 
          size={PR(120)} 
          color={"#ffd039"} 
        />
      : 
        <Icon 
          name={"frown"} 
          size={PR(120)} 
          color={"#fb667a"} 
        />
      }
    </>
  )
}

const CommonMoodAndSleep = (props) => {
  const {t} = useTranslation();
  const [value, setValue] = useState(180)
  const [valueDisplayed, setValueDisplayed] = useState(props.receivedValue !== null ? props.receivedValue :5)
  const smallestSide = (Math.min(300,300))
  const [cx,] = useState(PR(300/2))
  const [cy,] = useState(PR(200))
  const [r,] = useState(PR((smallestSide/2)*0.85))

  useEffect(() => {
    if(value <= 50 ) {
      setValueDisplayed(0)
    } 
    else if(value > 50 && value <= 72) {
      setValueDisplayed(1)
    } 
    else if(value > 72 && value <= 99) {
      setValueDisplayed(2)
    }
    else if(value > 99 && value <= 126) {
      setValueDisplayed(3)
    }
    else if(value > 126 && value <= 153) {
      setValueDisplayed(4)
    }
    else if(value > 153 && value <= 180) {
      setValueDisplayed(5)
    }
    else if(value > 180 && value <= 207) {
      setValueDisplayed(6)
    }
    else if(value > 207 && value <= 234) {
      setValueDisplayed(7)
    }
    else if(value > 234 && value <= 261) {
      setValueDisplayed(8)
    }
    else if(value > 261 && value <= 288) {
      setValueDisplayed(9)
    }
    else {
      setValueDisplayed(10)
    }
    
  },[value]);

  useEffect(() => {
    props.onValueChange(valueDisplayed)
  },[valueDisplayed])

  useEffect(() => {
    if(valueDisplayed >= 0 && valueDisplayed < 1)
      setValue(43)
    else if(valueDisplayed >= 1 && valueDisplayed < 2)
      setValue(72)
    else if(valueDisplayed >= 2 && valueDisplayed < 3)
      setValue(99)
    else if(valueDisplayed >= 3 && valueDisplayed < 4)
      setValue(126)
    else if(valueDisplayed >= 4 && valueDisplayed < 5)
      setValue(153)
    else if(valueDisplayed >= 5 && valueDisplayed < 6)
      setValue(180)
    else if(valueDisplayed >= 6 && valueDisplayed < 7)
      setValue(207)
    else if(valueDisplayed >= 7 && valueDisplayed < 8)
      setValue(234)
    else if(valueDisplayed >= 8 && valueDisplayed < 9)
      setValue(261)
    else if(valueDisplayed >= 9 && valueDisplayed < 10)
      setValue(288)
    else if(valueDisplayed === 10)
      setValue(315)
  },[])

  const polarToCartesian = (angle) =>{
    let a = (angle-270) * Math.PI / 180.0
    let x = cx + (r * Math.cos(a))
    let y = cy + (r * Math.sin(a))
    return {x,y}
  }

  const startCoord = polarToCartesian(0)
  const endCoord = polarToCartesian(value)

  const cartesianToPolar = (x,y) =>{
    return Math.round((Math.atan((y-cy)/(x-cx)))/(Math.PI/180)+((x>cx) ? 270 : 90))
  }

  const handlePanResponderMove = ({nativeEvent:{locationX,locationY}}) =>{
    if(cartesianToPolar(locationX,locationY) < 45) {
      setValue(45)
    } 
    else if(cartesianToPolar(locationX,locationY) > 315) {
      setValue(315)
    } else {
      setValue(cartesianToPolar(locationX,locationY))
    }
  }

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => true,
      onPanResponderMove: handlePanResponderMove
    })
  ).current;

  const increaseDecrease = (operator) => {
    if(operator === 'minus') {
      if(value <= 50) {
        setValue(43)
      } else {
        setValue(value-27)
      }
    } else {
      if(value >= 310) {
        setValue(315)
      } else {
        setValue(value+27)
      }
    }
  }

  const sendEditBack = () => {
    props.onClose();
    props.route.navigate('MoodSleepNotifications');
  }
  return (
    <>
    <Svg style={{alignSelf:'center', marginTop:PR(-50), zIndex:998}} width={PR(300)} height={PR(300)}>
      <Circle cx={cx} cy={cy} r={r} stroke={GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor} strokeWidth={PR(5)} fill='none'/>
      <Path stroke={GlobalStyles[APP_SHORTCUT_NAME].btnColor} strokeWidth={PR(5)} fill='none'
        d={`M${startCoord.x} ${startCoord.y} A ${r} ${r} 0 ${value>180?1:0} 1 ${endCoord.x} ${endCoord.y}`}/>
      <G x={endCoord.x} y={endCoord.y} {...panResponder.panHandlers}>
        <Circle cx={0} cy={0} r={PR(20)} strokeWidth={PR(20)} stroke={"#ffffff00"} fill={"#ffffff00"}/>
        <Circle cx={0} cy={0} r={PR(10)} strokeWidth={PR(2)} stroke={GlobalStyles[APP_SHORTCUT_NAME].btnColor} fill={GlobalStyles.global.white} />
        <Circle  cx={0} cy={0} r={PR(7)} fill={GlobalStyles[APP_SHORTCUT_NAME].btnColor} />
      </G>
    </Svg>
    
    <View style={styles.innerContainer}>
      <MoodSleep value={valueDisplayed} />
      <View style={styles.valueContainer}>
        {Platform.OS === 'web' ?
        <TouchableOpacity onPress={() => increaseDecrease('minus')}>
          <Icon 
            name={"minus"} 
            size={PR(25)} 
            color={GlobalStyles[APP_SHORTCUT_NAME].btnColor} 
          />
        </TouchableOpacity>
        : <View></View>
        }
        <View style={{width:'50%'}}>
        <Text style={styles.valueTxt}>{valueDisplayed}</Text>
        </View>
        
        {Platform.OS === 'web' ?
        <TouchableOpacity onPress={() => increaseDecrease('plus')}>
          <Icon 
            name={"plus"} 
            size={PR(25)} 
            color={GlobalStyles[APP_SHORTCUT_NAME].btnColor} 
          />
        </TouchableOpacity>
        : <View></View>
        }
      </View>
    </View>
    <View style={styles.wrapper}>
      <Text style={styles.thresholdValues}>0</Text>
      <Text style={styles.thresholdValues}>10</Text>
    </View>
    <CommonBtn type="tertiary" click={sendEditBack} title={t('translation:general.notifications.edit')} testID="edit_notifications_btn"/>
    </>
  )
}

const styles = StyleSheet.create({
  wrapper:{
    width: PR(220),
    alignSelf:'center',
    flexDirection: 'row',
    justifyContent:'space-between',
    marginTop:PR(-15),
    marginBottom: PR(30)
  },
  thresholdValues: {
    color:GlobalStyles[APP_SHORTCUT_NAME].lightColor,
    fontSize: PR(16)
  },
  innerContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: PR(-165),
  },
  valueContainer: {
    flexDirection:'row', 
    alignItems:'center', 
    alignSelf:'center', 
    justifyContent: 'space-around',
    marginTop:PR(20),
    width:'30%'
  },
  valueTxt: {
    fontFamily: GlobalStyles.global.fontFamily.Bold,
    alignSelf: 'center',
    color:GlobalStyles[APP_SHORTCUT_NAME].darkColor,
    fontSize: PR(32),
  }
});

export default CommonMoodAndSleep;