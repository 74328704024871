import React, {useState, memo} from 'react';
import { View, StyleSheet, TouchableOpacity} from 'react-native';
import Text from '../CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import GlobalStyles from '../../styles/GlobalStyles';
import CommonModal from 'common/src/components/CommonModal';
import { DateTime } from "luxon";
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {convertLangCode} from 'common/src/services/translationList';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import {compareMoodAndSleepDates} from '../../services/compare/index';
import ActionEvents from './EventSubComponents/ActionEvents';
import FormEvents from './EventSubComponents/FormEvents';
import SideEffectEvents from './EventSubComponents/SideEffectEvents';
import SeizureEvents from './EventSubComponents/SeizureEvents';
import AppointmentEvents from './EventSubComponents/AppointmentEvents';
import MedicationEvents from './EventSubComponents/MedicationEvents';
import NoteEvents from './EventSubComponents/NoteEvents';
import HeadacheEvents from './EventSubComponents/HeadacheEvents';
import NightWatchEvents from './EventSubComponents/NightWatchEvents';

// this child component return the correct icon according to the mood and sleep
const MoodSleep = ({value}) => {
  return (
    <>
      {value === null ? <Text></Text>
      : value >= 0.7 ?
        <Icon 
          name={"smile"} 
          size={PR(15)} 
          color={"#1de9b6"} 
        />
      : value >= 0.4 && value <=0.6 ?
        <Icon 
          name={"meh"} 
          size={PR(15)} 
          color={"#ffd039"} 
        />
      : 
        <Icon 
          name={"frown"} 
          size={PR(15)} 
          color={"#fb667a"} 
        />
      }
    </>
  )
}

const isEmpty = inputObject => {
  return Object.keys(inputObject).length === 0;
};

const CommonDiaryItem = (props:any) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [actionWarningModal, setActionWarningModal] = useState<boolean>(false);
  const [tempActionData, setTempActionData] = useState<Record<string, any>>()
  const {i18n, t} = useTranslation();
  const lang = convertLangCode(i18n.language) 
  const styles: any = generateStyleSheet();
  const patientData = useSelector((state:any) => state.getMe.patient);

  const moodAndSleep = (id,value,eventType,date,isDataEmpty) => {
    props.callbackMnS(id,value,eventType,date,isDataEmpty)
  }
  // keep tracking of the changes of the modalVisible
  const callbackVisible = () => {
    setModalVisible(false);
  }
  const errorModalBtn = [{
    type: 'primary',
    title: t('translation:modal.btn.close'),
    click: callbackVisible
  }]

  // keep tracking of the changes of the action warning modal
  const callbackActionVisible = () => {
    setActionWarningModal(false);
  }

  const confirmActionUnDone = () => {
    props.callbackActions(tempActionData);
    callbackActionVisible();
  }

  const actionModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackActionVisible
    },
    {
      type: 'primary',
      title: t('translation:modal.btn.confirm'),
      click: confirmActionUnDone
    }
  ]
  
  return (
    <>
    {/* creating the list calendar */}
      { modalVisible &&
        <CommonModal testID="errorModal" type={'error'} title={t('translation:modal.titles.error')} text={t('translation:errors.mood_sleep_in_future')} buttons={errorModalBtn} onClose={callbackVisible}/>
      }
      { actionWarningModal &&
        <CommonModal testID="actionModal" type={'info'} title={t('translation:modal.titles.warning')} text={t('translation:modal.actionWarning.text')} buttons={actionModalBtn} onClose={callbackActionVisible}/>
      }
      <View
        testID={'diary_'}
        style={[styles.item, {flexDirection:'row'}]}
      >
        <View style={{flexDirection:'column', width: '20%'}}>
          <Text style={styles.dayNum}>{DateTime.fromISO(props.data.date, { zone: patientData?.timezone }).toFormat('dd')}</Text>
          <Text style={styles.day}>{DateTime.fromISO(props.data.date, { locale: lang, zone: patientData?.timezone }).toFormat('EEE')}</Text>
        </View>
        <View style={{flexDirection:'column', width: '80%'}}>
          {/* Mood */}
          { props.filterData.length === 0 && compareMoodAndSleepDates(DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy'),DateTime.fromISO(props.data.date).toFormat('dd-MM-yyyy'), 'dd-MM-yyyy') &&
            <TouchableOpacity testID={'mood_id'} onPress={()=> setModalVisible(true)} style={styles.moodAndSleepContainer}>
              <View style={{width: "93%", flexDirection:'row', alignItems: 'center'}}>
                <Icon 
                  name={"sun"} 
                  size={15} 
                  color={"#0079ff"} 
                />
                <Text renderAsHtml={true} style={styles.title}>{t('translation:journal.events.mood')}</Text>
              </View>
            </TouchableOpacity>
          }
          {props.filterData.length === 0 && !compareMoodAndSleepDates(DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy'),DateTime.fromISO(props.data.date).toFormat('dd-MM-yyyy'), 'dd-MM-yyyy') &&
            <TouchableOpacity testID={'mood_id'} onPress={()=>{props.data!== undefined && moodAndSleep(props.data.mood.id,props.data.mood.value,'mood', props.data.date, isEmpty(props.data.mood))}} style={styles.moodAndSleepContainer}>
              <View style={{width: "93%", flexDirection:'row', alignItems: 'center'}}>
                <Icon 
                  name={"sun"} 
                  size={PR(15)} 
                  color={"#0079ff"} 
                />
                <Text renderAsHtml={true} style={styles.title}>{t('translation:journal.events.mood')}</Text>
              </View>
              <View style={{width: "10%"}}>
                {props.data !== undefined  && 
                  <MoodSleep  value={!isEmpty(props.data.mood) ? props.data.mood.value : null} />
                }
              </View>
            </TouchableOpacity>
          }
          {props.filterData.length > 0 && props.filterData.find(item => item.value === 'mood') &&
            <TouchableOpacity testID={'mood_id'} onPress={()=>{
              !compareMoodAndSleepDates(DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy'),DateTime.fromISO(props.data.date).toFormat('dd-MM-yyyy'), 'dd-MM-yyyy') ? 
              moodAndSleep(props.data.mood.id,props.data.mood.value,'mood', props.data.date, isEmpty(props.data.mood))
              : setModalVisible(true)
              }} style={styles.moodAndSleepContainer
            }>
            <View style={{width: "93%", flexDirection:'row', alignItems: 'center'}}>
              <Icon 
                name={"sun"} 
                size={PR(15)} 
                color={"#0079ff"} 
              />
              <Text renderAsHtml={true} style={styles.title}>{t('translation:journal.events.mood')}</Text>
            </View>
            <View style={{width: "10%"}}>
              {props.data !== undefined && 
                <MoodSleep  value={!isEmpty(props.data.mood) ? props.data.mood.value : null} />
              }
            </View>
          </TouchableOpacity>
          }
          {/* Sleep */}
          { props.filterData.length === 0 && compareMoodAndSleepDates(DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy'),DateTime.fromISO(props.data.date).toFormat('dd-MM-yyyy'), 'dd-MM-yyyy')&&
            <TouchableOpacity testID={'sleep_id'} onPress={()=> setModalVisible(true)} style={styles.moodAndSleepContainer}>
              <View style={{width: "93%", flexDirection:'row', alignItems: 'center'}}>
                <Icon 
                  name={"moon"} 
                  size={15} 
                  color={"#0079ff"} 
                />
                <Text renderAsHtml={true} style={styles.title}>{t('translation:journal.events.sleep')}</Text>
              </View>
            </TouchableOpacity>
          }
          {props.filterData.length === 0 && !compareMoodAndSleepDates(DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy'),DateTime.fromISO(props.data.date).toFormat('dd-MM-yyyy'), 'dd-MM-yyyy') &&
            <TouchableOpacity testID={'sleep_id'} onPress={()=>{props.data!== undefined && moodAndSleep(props.data.sleep.id,props.data.sleep.value,'sleep', props.data.date, isEmpty(props.data.sleep))}} style={styles.moodAndSleepContainer}>
              <View style={{width: "93%", flexDirection:'row', alignItems: 'center'}}>
                <Icon 
                  name={"moon"} 
                  size={PR(15)} 
                  color={"#0079ff"} 
                />
                <Text renderAsHtml={true} style={styles.title}>{t('translation:journal.events.sleep')}</Text>
              </View>
              <View style={{width: "10%"}}>
                {props.data !== undefined  && 
                  <MoodSleep  value={!isEmpty(props.data.sleep) ? props.data.sleep.value : null} />
                }
              </View>
            </TouchableOpacity>
          }
          {props.filterData.length > 0 && props.filterData.find(item => item.value === 'sleep') &&
            <TouchableOpacity testID={'sleep_id'} onPress={()=>{
              !compareMoodAndSleepDates(DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy'),DateTime.fromISO(props.data.date).toFormat('dd-MM-yyyy'), 'dd-MM-yyyy') ? 
              moodAndSleep(props.data.sleep.id, props.data.sleep.value,'sleep', props.data.date, isEmpty(props.data.sleep))
              : setModalVisible(true)
              }} style={styles.moodAndSleepContainer
            }>
              <View style={{width: "93%", flexDirection:'row', alignItems: 'center'}}>
                <Icon 
                  name={"moon"} 
                  size={PR(15)} 
                  color={"#0079ff"} 
                />
                <Text renderAsHtml={true} style={styles.title}>{t('translation:journal.events.sleep')}</Text>
              </View>
              <View style={{width: "10%"}}>
                {props.data !== undefined && 
                  <MoodSleep  value={!isEmpty(props.data.sleep)  ? props.data.sleep.value : null} />
                }
              </View>
            </TouchableOpacity>
          }
            {props.data !== undefined &&
              <View >
                {props.data.side_effects.length > 0 &&
                  <SideEffectEvents 
                    filterData={props.filterData}
                    title={t('translation:journal.side_effect_title')}
                    item={props.data.side_effects}
                    type='sideEffect'
                    itemNames={['effect_type','intensity']}
                  />
                }
                {props.data.seizures.length > 0 && 
                  <SeizureEvents 
                    filterData={props.filterData}
                    title={t('translation:journal.seizure_title')}
                    item={props.data.seizures}
                    type='seizure'
                    itemNames={['date','seizure_type']}
                  />
                }
                {props.data.appointments.length > 0 &&
                  <AppointmentEvents 
                    filterData={props.filterData}
                    title={t('translation:journal.appointment_title')}
                    item={props.data.appointments}
                    type='appointment'
                    itemNames={['date','doctor_info']}
                  />
                }
                {props.data.reminders.length > 0 &&
                  <MedicationEvents 
                    filterData={props.filterData}
                    title={t('translation:journal.medication_title')}
                    item={props.data.reminders}
                    type='medication'
                    itemNames={['time','reminder','taken_date']}
                    callback={props.callbackMedication}
                  />
                }
                {props.data.others.length > 0 &&
                  <NoteEvents 
                    filterData={props.filterData}
                    title={t('translation:journal.other_title')}
                    item={props.data.others}
                    type='notes'
                    itemNames={['other_type','remark']}
                  />
                }
                {props.data.headaches.length > 0 &&
                  <HeadacheEvents 
                    filterData={props.filterData}
                    title={t('translation:journal.add_headache')}
                    item={props.data.headaches}
                    type='headaches'
                    itemNames={['date','name']}
                  />
                }
                {props.data.nightwatch_reports.length > 0 &&
                  <NightWatchEvents 
                    filterData={props.filterData}
                    title={t('translation:journal.events.nightwatch_recording_title')}
                    item={props.data.nightwatch_reports}
                    type='nightwatch'
                    itemNames={['date']}
                  />
                }
                { props.data.forms.length > 0 &&
                  <FormEvents 
                    filterData={props.filterData}
                    title={t('translation:journal.events.formsLabel')}
                    item={props.data.forms}
                    type="forms"
                    itemNames={['date', 'updatedAt', 'form', 'studyParameters']}
                    saveForm={props.saveForm}
                  />
                }
                {props.data.program_actions.length > 0 &&
                  <ActionEvents 
                    filterData={props.filterData}
                    title={t('translation:journal.events.actions_title')}
                    item={props.data.program_actions}
                    type='program_actions'
                    itemNames={['date', 'done', 'title']}
                    callback={props.callbackActions}
                    modal={(e:any) => {setActionWarningModal(true); setTempActionData(e)}}
                    moreCallback={props.actionsMoreCallback}
                  />
                }
                {props.filterData.length > 0 && 
                  props.filterData.find(fItem => isEmpty(props.data[fItem.value])) &&
                  <Text>{t('translation:events.no_results')}</Text>
                }
              </View>
            }
        </View>
      </View>
    </>
  )
  
}

const generateStyleSheet = (type?) => {
  return StyleSheet.create({
  item: {
    width: '90%',
    alignSelf: 'center',
    marginVertical: PR(8),
  },
  moodAndSleepContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: PR(1),
    borderColor: '#0079ff',
    borderRadius: PR(18),
    padding: PR(10),
    marginBottom: PR(10)
  },
  title: {
    fontFamily: GlobalStyles.global.fontFamily.Medium,
    fontSize: PR(16),
    color:"#0079ff",
    paddingLeft: PR(10)
  },
  dayNum:{
    fontSize: PR(32),
    color: GlobalStyles.global.black,
    fontFamily: GlobalStyles.global.fontFamily.Bold
  },
  day: {
    fontSize: PR(14),
    color: GlobalStyles.global.grey,
    paddingLeft: PR(4),
    textTransform: "uppercase"
  }
})
}

function arePropsEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  if(prevProps.filterData !== nextProps.filterData)
    return false
  else if(prevProps.data !== nextProps.data)
    return false
  else
    return true
}

export default memo(CommonDiaryItem, arePropsEqual);