import React, { useState, useRef } from 'react';
import {View, StyleSheet, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import Text from "../CommonCustomTxt";
import GlobalStyles from '../../styles/GlobalStyles';
import {useTranslation} from "react-i18next";
import CommonSimpleModal from "../CommonSimpleModal";
import {updatePatient} from "helpilepsy/src/store/user/userActions";
import CommonOtherInput from "../Inputs/CommonOtherInput";
import {useDispatch, useSelector} from "react-redux";
import CommonSelectInput from "../Inputs/CommonSelectInput";
import {getListCMSData} from "../../helpers/translations";
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import {useKeyboard} from '../../services/useKeyboard';
import CommonLabelForm from './CommonLabelForm';

interface CommonSelectFormProps {
    values: Array<string>,
    setValues: any,
    cmsListLink: string,
    patientList: string,
    label: string,
    isOptional?: boolean
}

let screenHeight = Dimensions.get('window').height;

const CommonSelectForm = ({values, setValues, cmsListLink, patientList, label, isOptional }: CommonSelectFormProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isKeyBoardOpen = useKeyboard();
    const scrollRef:any = useRef();
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    const [selectedValues, setSelectedValues] = useState<Array<string>>([]);

    const patientData = useSelector((state:any) => state.getMe.patient);
    const cmsValues = getListCMSData(t, cmsListLink);
    const selectedValueName = values.map(value => cmsValues.find(i => i.value === value)?.label || value)

    const defineModalMaxHeight = () => {
        if (isKeyBoardOpen)
            return PR(screenHeight * 0.5)
        else
            return PR(screenHeight * 0.75)
    }

    return (
        <>
            {
                displayModal &&
                <CommonSimpleModal
                    title={t('translation:modal.select.title_multiple')}
                    onClose={()=>setDisplayModal(false)}
                >
                    <View style={[styles.modalContainer, {maxHeight: defineModalMaxHeight()}]}>
                        <ScrollView style={styles.selectWrapper}
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                            ref={scrollRef}
                        >
                            <CommonSelectInput
                                data={cmsValues}
                                otherData={patientData?.lists?.[patientList]}
                                onSelect={(item) => {
                                    if (selectedValues.indexOf(item) >= 0) {
                                        setSelectedValues(selectedValues.filter(i => i !== item))
                                    } else {
                                        selectedValues.push(item)
                                        setSelectedValues([...selectedValues])
                                    }
                                }}
                                selectedValues={selectedValues}
                                onDelete={(item) => {
                                    if (selectedValues.indexOf(item) >= 0) {
                                        setSelectedValues(selectedValues.filter(i => i !== item))
                                    }
                                    if (values.indexOf(item) >= 0) {
                                        setValues(values.filter(i => i !== item))
                                    }
                                    dispatch(updatePatient({
                                        data: {
                                            lists: {
                                                ...patientData?.lists,
                                                [patientList]: patientData?.lists?.[patientList]?.filter(i => i !== item)
                                            }
                                        },
                                        patientId: patientData.id,
                                    }))
                                }}
                            />
                        </ScrollView>
                        <View style={styles.otherWrapper}>
                            <View style={styles.txtCustomContainer}>
                                <Text style={styles.txtCustomLabel}>{t('translation:modal.select.other')}</Text>
                            </View>
                            <CommonOtherInput
                                onCreateOther={item => {
                                    const newList = patientData?.lists?.[patientList] || []
                                    const findItem = newList.find(fItem => fItem === item)
                                    if(!findItem) {
                                        newList.push(item)
                                        dispatch(updatePatient({
                                            data: {
                                                lists: {
                                                    ...patientData?.lists,
                                                    [patientList]: newList
                                                }
                                            },
                                            patientId: patientData.id,
                                            callback: () => {
                                                setTimeout(() => {
                                                    scrollRef?.current?.scrollToEnd({animated: true})
                                                }, 100);
                                            }
                                        },
                                        ))
                                        selectedValues.push(item)
                                        setSelectedValues([...selectedValues])
                                    }
                                }}
                                onSubmit={() => {
                                    setValues([...selectedValues])
                                    setDisplayModal(false)
                                    setSelectedValues([])
                                }}
                            />
                        </View>
                    </View>
                </CommonSimpleModal>
            }
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
                <CommonLabelForm label={label} isOptional={isOptional}/>
                <TouchableOpacity testID="seizureTypeBtn" style={styles.container} onPress={() => {
                    setSelectedValues([...values])
                    setDisplayModal(true)
                }}>
                    <View style={styles.inputContainer}>
                        <Text style={styles.inputValue}>{values.length === 0 ? t('translation:modal.select.title_multiple') : selectedValueName.join('\n')}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    label: {
        marginBottom: PR(10),
        marginLeft: PR(20),
        color: GlobalStyles.global.black,
        fontSize: PR(12)
    },
    container: {
        backgroundColor: GlobalStyles.global.footerGrey,
        borderRadius: PR(20),
    },
    inputContainer: {
        flex: 1,
        flexDirection: 'row',
        minHeight: PR(62),
        padding: PR(10),
        fontSize: PR(16),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inputValue: {
        flexGrow: 1,
        color: GlobalStyles.global.black,
        marginLeft: PR(10),
        fontSize: PR(16),
        fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    selectWrapper: {
        maxHeight: '75%',
    },
    otherWrapper: {
    },
    modalContainer: {
        minHeight: PR(75)
    },
    txtCustomContainer: {
        width: '90%', 
        paddingLeft:PR(26), 
        marginBottom:PR(-10)
    },
    txtCustomLabel: {
        fontSize:PR(16),
        color: GlobalStyles.global.greyToBlack
    }
});


export default CommonSelectForm;