import React from 'react';
import { View, StyleSheet, Platform} from 'react-native';
import CommonImage from './CommonImage';
import Text from './CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonAboutFooter = () => {
  const {t} = useTranslation();
  return (
    <>
      <View style={styles.footerRow}>
        <View style={styles.innerFooterRow}>
          <CommonImage name={'ceIcon'} height={PR(35)} width={PR(49)}/>
          <CommonImage name={'mdIcon'} height={PR(35)} width={PR(49)}/>
        </View>
        <View style={styles.innerFooterManufacturerRow}>
          <CommonImage name={'manufacturerIcon'} height={PR(35)} width={PR(49)}/>
          <View style={styles.footerColumn}>
            <Text renderAsHtml={true} style={styles.footerTxt}>{t('translation:settings.about.cAddressV')}</Text>
          </View>
        </View>
      </View>
    </>
  )
};

const styles = StyleSheet.create({
  footerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    marginTop: PR(20),
    marginBottom: PR(30)
  },
  innerFooterRow: {
    width: Platform.OS === 'web' ? '20%' : '28%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  innerFooterManufacturerRow: {
    width: Platform.OS === 'web' ? '20%' : '40%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerColumn: {
    flexDirection: 'column',
  },
  footerTxt: {
    fontSize: PR(11),
    lineHeight: PR(16)
  }
});

export default CommonAboutFooter;