import React, {useEffect, useState} from 'react';
import {StyleSheet, Platform, SafeAreaView, View} from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonSwitchBtn from 'common/src/components/CommonSwitchBtn';
import CommonImage from 'common/src/components/CommonImage';
import CommonSimpleModal from 'common/src/components/CommonSimpleModal';
import CommonNumPad from 'common/src/components/Inputs/CommonNumPad';
import CommonPinField from 'common/src/components/Inputs/CommonPinField';
import Text from 'common/src/components/CommonCustomTxt';
import ReactNativeBiometrics from 'react-native-biometrics';
import DeviceInfo from 'react-native-device-info';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {useTranslation} from 'react-i18next';
import { 
    enableBiometricAction, 
    disableBiometricAction, 
    biometricKeyExistsAction,
    enablePinCodeAction, 
    disablePinCodeAction
} from '../../store/biometrics/biometricsActions';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'helpilepsy/src/store/reducers';

const rnBiometrics = new ReactNativeBiometrics()

const EnableBiometric = ({navigation}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const Me = useSelector((state:any) => state.getMe.response)
    const isBiometricKeyExists = useSelector((state: RootState) => state.biometricKeyExistsReducer.isBiometricKeyExists);
    const [isBiometricAvailable, setBiometricAvailable] = useState<boolean>(false);
    const [enableBiometric, setBiometric] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorPin, setErrorPin] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [deviceID, setDeviceID] = useState<string>("");
    const [isPinModalOpen, setPinModal] = useState<boolean>(false);
    const [isPinEnabled, setPinEnable] = useState<boolean>(false);

    useEffect(() => {
        DeviceInfo.getUniqueId().then(id => {
            setDeviceID(id)
        })
        setPinEnable(isBiometricKeyExists)
        rnBiometrics.isSensorAvailable()
            .then((resultObject) => {
                const { available } = resultObject
                
                if (available) {
                    setBiometricAvailable(true);
                    biometricKey();
                } else {
                    setBiometricAvailable(false);
                }
            })
    },[])

    const [pinInput, setPinInput] = useState<Array<string>>([]);
    const pinLength = 4;

    const changePinInput = (input: string) => {
        if(pinInput.length < pinLength)
            setPinInput([...pinInput, input])
        
    }

    const deletePinInput = () => {
        if(pinInput.length > 0)
            setPinInput(pinInput.slice(0, -1))
        
    }

    const biometricKey = () => {
        rnBiometrics.biometricKeysExist()
            .then((resultObject) => {
                const { keysExist } = resultObject

                if (keysExist) {
                    setBiometric(true);
                } else {
                    setBiometric(false);
                }
            })
    }

    const switchToggle = () => {
        setBiometric(!enableBiometric);
    }

    const deleteKeys = (skipRequest:boolean) => {
        rnBiometrics.deleteKeys()
            .then((resultObject) => {
            const { keysDeleted } = resultObject
        
            if (keysDeleted) {
                if(!skipRequest){
                    dispatch(disableBiometricAction({
                        deviceID: deviceID,
                        userID: Me.result.id,
                        callback: () => {
                            setError(false)
                            switchToggle()
                        },
                        errorCallback: () => {
                            createKeys(true)
                        }
                    }))
                }
            } else {
                setError(true)
                setErrorMsg(t('translation:modal.biometric.delete.publicKey.failed'))
            }
            })
    }

    const createKeys = (skipRequest) => {
        rnBiometrics.createKeys()
            .then((resultObject) => {
            const { publicKey } = resultObject
            if(!skipRequest){
                dispatch(enableBiometricAction({
                    publicKey,
                    deviceID: deviceID,
                    userID: Me.result.id,
                    callback: () => {
                        setError(false)
                        switchToggle()
                        dispatch(biometricKeyExistsAction({key: true}));
                    },
                    errorCallback: () => {
                        deleteKeys(true)
                    }
                }))
            }
        })
    }

    const validateBiometric = () => {
        rnBiometrics.simplePrompt({promptMessage: t('translation:modal.biometric.validate.title')})
            .then((resultObject) => {
            const { success } = resultObject

            if (success) {
                setError(false)
                enableDisableAuth()
            } else {
                setError(true)
                setErrorMsg(t('translation:modal.biometric.validate.cancel'))
            }
        })
        .catch(() => {
            setError(true)
            setErrorMsg(t('translation:modal.biometric.validate.failed'))
        })
    }

    const openPinModal = () => {
        if(enableBiometric) {
            setErrorPin(true);
            setErrorMsg(t('translation:MFA.disable.pin.error'));
            setError(false);
        } else {
            setPinModal(!isPinModalOpen)
            setErrorPin(false);
            setErrorMsg("");
        }
    }

    const enableDisableAuth = () => {
        if(!enableBiometric){
            createKeys(false)
        } else {
            deleteKeys(false)
            if(!isPinEnabled)
                dispatch(biometricKeyExistsAction({key: false}));
        }
    }

    const enableDisablePin = () => {
        setPinModal(false);
        setPinInput([]);
        if(!isBiometricKeyExists) {
            dispatch(enablePinCodeAction({
                userID: Me.result.id,
                biometric_pin: pinInput.join(""),
                callback: () => {
                    setPinEnable(true)
                }
            }))
        } else {
            dispatch(disablePinCodeAction({
                userID: Me.result.id,
                biometric_pin: pinInput.join(""),
                callback: () => {
                    setPinEnable(false)
                }
            }))
        }
        
    }

    return(
        <SafeAreaView style={styles.container}>
            {isPinModalOpen &&
                <CommonSimpleModal
                        title={isPinEnabled ? t('translation:MFA.deactivate_pin_code.title') : t('translation:MFA.set_pin_code.title')}
                        onClose={()=>setPinModal(false)}
                    >
                    <View style = {{height: PR(380)}}>
                        <CommonPinField 
                            input={pinInput}
                            pinLength={pinLength}
                            inputAreaStyle={{marginBottom: PR(24)}}
                            showInputText={isPinEnabled ? false : true}
                        />
                        <CommonNumPad 
                            setInput={changePinInput}
                            deleteInput={deletePinInput}
                            isConfirmActive={pinInput.length === pinLength ? true : false}
                            sendConfirmation={()=> enableDisablePin()}
                        />
                    </View>
                </CommonSimpleModal>
            }
            <CommonHeader
                testIDTitle="enable_disable_biometric_title"
                title={t('translation:settings.btn.enable_disable_biometrics')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
            />
            <View style={styles.IconRow}>
              <CommonImage name="biometric" width={PR(80)} height={PR(80)}/>
            </View>
            <View testID='pinContainerId' style={[styles.biometricContainer, {borderColor: errorPin ? GlobalStyles.global.redError :GlobalStyles.helpi.color}]}>
                <View style={styles.wrapper}>
                    <Text testID="pin_text" style={styles.txt}>{t('translation:MFA.pin.unlock')}</Text>
                    <CommonSwitchBtn 
                        isOn={isPinEnabled}
                        size="small"
                        onToggle={()=>openPinModal()}
                        testID="enableDisablePinSwitch"
                    />
                </View>
                <View style={styles.horizontalDivider}></View>
                <View style={{alignSelf:'center', paddingTop:PR(10)}}>
                    <Text testID="enableDisablePinDescription" style={styles.subTxt}>{t('translation:MFA.pin.unlock.description')}</Text>
                </View>
                {errorPin && 
                    <View style={styles.biometricBottomContainer}>
                        <View style={{alignSelf:'center', paddingTop:PR(10)}}>
                            <Text style={[styles.subTxt, {color: GlobalStyles.global.redError}]}>{errorMsg}</Text>
                        </View>
                    </View>
                }
            </View>
            {isBiometricAvailable && isPinEnabled && 
                <View testID='biometricContainerId' style={[styles.biometricContainer, {marginTop: PR(10), borderColor: error ? GlobalStyles.global.redError :GlobalStyles.helpi.color}]}>
                    <View style={styles.wrapper}>
                        <Text testID="unlock_text" style={styles.txt}>{t('translation:general.biometric.unlock')}</Text>
                        <CommonSwitchBtn 
                            isOn={enableBiometric}
                            size="small"
                            onToggle={()=>validateBiometric()}
                            testID="enableDisableBiometricSwitch"
                        />
                    </View>
                    <View style={styles.horizontalDivider}></View>
                    <View style={{alignSelf:'center', paddingTop:PR(10)}}>
                        <Text testID="enableDisableBiometricDescription" style={styles.subTxt}>{t('translation:general.biometric.unlock.description')}</Text>
                    </View>
                    {error && 
                        <View style={styles.biometricBottomContainer}>
                            <View style={{alignSelf:'center', paddingTop:PR(10)}}>
                                <Text style={[styles.subTxt, {color: GlobalStyles.global.redError}]}>{errorMsg}</Text>
                            </View>
                        </View>
                    }
                </View>
            }  
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        display: "flex",
        backgroundColor: GlobalStyles.global.white,
        flex: 1,
    },
    wrapper:{
        flexDirection:'row',
        alignItems:'center',
        alignSelf:'center',
        width:'90%',
        justifyContent:'space-between',
        paddingBottom: PR(10),
    },
    biometricContainer: {
        minHeight: PR(62),
        padding: PR(10),
        width: '90%',
        borderRadius: PR(20),
        borderWidth: 1,
        borderColor: GlobalStyles.helpi.color ,
        alignSelf:'center'
    },
    biometricBottomContainer: {
        backgroundColor:GlobalStyles.global.white, 
        borderWidth: PR(1), 
        borderBottomLeftRadius: PR(20), 
        borderBottomRightRadius: PR(20), 
        padding:PR(20),
        borderColor: GlobalStyles.global.white,
        flexDirection: 'column',
    },
    horizontalDivider: {
        borderBottomColor: GlobalStyles.helpi.lightColor,
        borderBottomWidth: PR(1),
        width: '50%',
        alignSelf: 'center'
    },
    IconRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: PR(50),
        marginBottom: PR(20)
    },
    txt: {
        fontSize: PR(16),
        marginLeft: PR(15),
        color: GlobalStyles.global.greyToBlack
    },
    subTxt: {
        fontSize: PR(12),
        color: GlobalStyles.global.grey
    }
})

export default EnableBiometric;