import React, { useEffect, useState, useRef, useCallback } from 'react';
import { StyleSheet, View, SafeAreaView, FlatList, Platform } from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonDiaryItem from 'common/src/components/CommonDiary/CommonDiaryItem';
import CommonWeekView from 'common/src/components/CommonDiary/CommonWeekView';
import CommonModal from 'common/src/components/CommonModal';
import CommonActionModal from 'common/src/components/CommonActionModal';
import AddModal from '../components/AddModal';
import Text from 'common/src/components/CommonCustomTxt';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsAction, updateMedicationIntake } from '../store/actions/authenticationActions';
import { startProgramAction, getPatientPrograms, getPrograms } from '../store/programs/programsActions';
import { DiaryViewableItemTypes } from '../components/interfaces';
import { DateTime } from "luxon";
import {
  moveToElement,
  isCloseToTop,
  isCloseToBottom,
  filtersData,
  weekCreation,
  saveForm,
  saveMoodAndSleep,
  onWeekViewClick
} from '../components/DiaryFunctions';
import { useTranslation } from 'react-i18next';
import { useIsFocused } from '@react-navigation/native';
import { convertLangCode, translate } from 'common/src/services/translationList';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import { dateTimeEvents } from 'common/src/services/utils';
import { getPatientProgramActionSchedule, getPatientProgramActionEvents } from '../store/programs/patientProgramsActions';
import { addProgramAction } from '../store/programs/programsActions';
import { ProgramAction } from 'common/src/types';
import Loading from './Loading';

const Diary = ({ navigation, route }) => {
  const { t, i18n } = useTranslation();
  const isFocused = useIsFocused();
  const lang = convertLangCode(i18n.language)
  const scrollRef: any = useRef();
  const dispatch = useDispatch();
  const GetEventsResponse = useSelector((state: any) => state.getEvents.events);
  const [displayFilterModal, setDisplayFilterModal] = useState<boolean>(false);
  const [filterModalData, setFilterModalData] = useState<any>(filtersData)
  const [filter, setFilter] = useState<Array<string>>([]);
  const today = DateTime.now();
  const [sendWeekDays, setSendWeekDays] = useState<Array<number>>([])
  const [focusItem, setFocusItem] = useState<DiaryViewableItemTypes>()
  const [AddModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [moodAndSleepModalVisible, setMoodAndSleepModalVisible] = useState<boolean>(false);
  const [moodAndSleepModalTitle, setMoodAndSleepModalTitle] = useState<string>('');
  const [moodAndSleepModalId, setMoodAndSleepModalId] = useState<string>('');
  const [moodAndSleepModalType, setMoodAndSleepModalType] = useState<string>('');
  const [moodAndSleepModalValue, setMoodAndSleepModalValue] = useState<any>(0);
  const [moodAndSleepModalDate, setMoodAndSleepModalDate] = useState<string>('');
  const [moodAndSleepModalEmptyData, setMoodAndSleepModalEmptyData] = useState<boolean>(false);
  const [medicationIntakeModal, setMedicationIntakeModal] = useState(false);
  const [medicationIntakeData, setMedicationIntakeData] = useState(null);
  const [weekNextPrev, setWeekNextPrev] = useState<string>();
  const [updating, setUpdating] = useState(false);
  const [weekViewClicked, setWeekViewClicked] = useState(false)
  const [tempFocusDay, setTempFocusDay] = useState<string>('');
  let month = DateTime.fromISO(focusItem?.item.date, { locale: lang }).toFormat('MMMM');
  let year = DateTime.fromISO(focusItem?.item.date, { locale: lang }).toFormat('yyyy');
  const timelineDay = route.params && route.params.format === 'MMMM' ? route.params?.day?.date + '01' : route.params && route.params.format !== 'MMMM' ? route.params?.day?.date : null;
  const patientPrograms = useSelector((state: any) => state.patientProgramsReducers.patientPrograms);
  const [isLoading, setIsLoading] = useState(true);
  const [isPastLoading, setIsPastLoading] = useState(false);

  // checking if the event reducer is empty and make a request in the first render
  useEffect(() => {
    if (isFocused) {
      if (timelineDay && isFocused && GetEventsResponse.length === 0) {
        setWeekNextPrev('timeline')
        setSendWeekDays(weekCreation('timeline', isFocused, GetEventsResponse, dispatch) as number[])
        let obj = {
          from: DateTime.fromISO(timelineDay).startOf('week').minus({ days: 21 }).toFormat('yyyy-MM-dd'),
          to: DateTime.fromISO(timelineDay).endOf('week').plus({ days: 7 }).toFormat('yyyy-MM-dd'),
          callback: () => {
            setIsLoading(false)
          },
          errorCallback: () => {
            setIsLoading(false)
          }
        }
        dispatch(getEventsAction(obj));
        setFocusItem(undefined)
      }
      else if (GetEventsResponse.length === 0 && isFocused) {
        setWeekNextPrev('current')
        setSendWeekDays(weekCreation('current', isFocused, GetEventsResponse, dispatch) as number[])
        let obj = {
          from: today.startOf('week').minus({ days: 21 }).toFormat('yyyy-MM-dd'),
          to: today.endOf('week').plus({ days: 7 }).toFormat('yyyy-MM-dd'),
          callback: () => {
            setIsLoading(false)
          },
          errorCallback: () => {
            setIsLoading(false)
          }
        }
        dispatch(getEventsAction(obj));
      }
      else if (GetEventsResponse.length > 0 && isFocused && sendWeekDays.length === 0) {
        setSendWeekDays(weekCreation('current', isFocused, GetEventsResponse, dispatch) as number[])
        moveToElement(today.toFormat('yyyy-MM-dd'), GetEventsResponse, scrollRef.current, true)
      }
      else if (weekNextPrev === 'timeline') {
        moveToElement(DateTime.fromISO(timelineDay).toFormat('yyyy-MM-dd'), GetEventsResponse, scrollRef.current, true)
      }
      else if (weekNextPrev === 'current') {
        moveToElement(today.toFormat('yyyy-MM-dd'), GetEventsResponse, scrollRef.current, true)
      }
      else if (updating && weekNextPrev === 'prev') {
        moveToElement(DateTime.fromISO(GetEventsResponse[27].date).toFormat('yyyy-MM-dd'), GetEventsResponse, scrollRef.current, false)
        setUpdating(false)
        setWeekNextPrev('')
      }
    }
  }, [GetEventsResponse, isFocused]);

  useEffect(() => {
    setTimeout(() => {
      moveToElement(tempFocusDay, GetEventsResponse, scrollRef.current, false)
    }, 1000);
  }, [filter])

  useEffect(() => {
    if (isPastLoading)
      setIsPastLoading(false);
  }, [GetEventsResponse])

  // return the visible Item
  const onViewRef = useRef((viewableItems) => {
    if (viewableItems.changed[0].isViewable) {
      setFocusItem(viewableItems.viewableItems[0])
    }
  })

  // threshold of the visible item
  const viewConfigRef = useRef({ viewAreaCoveragePercentThreshold: 50 })
  // update the week view according to selected day
  if (focusItem !== undefined) {
    if (focusItem.index >= sendWeekDays[1]) {
      setSendWeekDays([sendWeekDays[0] + 7, sendWeekDays[1] + 7])
    }
    else if (focusItem.index < sendWeekDays[0]) {
      setSendWeekDays([sendWeekDays[0] - 7, sendWeekDays[1] - 7])
    }
  }
  // rendering the calendar list 
  const renderItem = useCallback(({item}: any) => (
    <CommonDiaryItem data={item} filterData={filter} callbackMnS={moodAndSleepFunc} callbackMedication={updateMedicationIntakeData} callbackActions={updateAction} actionsMoreCallback={{ getEvents: getActionEvents, getSchedule: getActionSchedule, handleAction: handleActionSchedule, removeAction: removeAction }} saveForm={(type, data) => saveForm(type, data, dispatch)} />
  ), []);
  const keyExtractor = (_: any, index:number) => index.toString() // Defined outside the render method

  // when click on mood or sleep btn update the state with the right values
  const moodAndSleepFunc = (id: string, value: number, eventType: string, dateMnS: string, emptyData: boolean) => {
    switch (eventType) {
      case 'mood':
        setMoodAndSleepModalTitle(t('translation:widget.daily_reminder.mood'));
        if (value !== null) {
          setMoodAndSleepModalValue(value * 10)
        } else {
          setMoodAndSleepModalValue(null)
        }
        setMoodAndSleepModalType(eventType);
        setMoodAndSleepModalId(id);
        setMoodAndSleepModalDate(dateMnS);
        setMoodAndSleepModalEmptyData(emptyData)
        break;
      case 'sleep':
        setMoodAndSleepModalTitle(t('translation:widget.daily_reminder.sleep_quality'));
        if (value !== null) {
          setMoodAndSleepModalValue(value * 10)
        } else {
          setMoodAndSleepModalValue(null)
        }
        setMoodAndSleepModalType(eventType);
        setMoodAndSleepModalId(id);
        setMoodAndSleepModalDate(dateMnS);
        setMoodAndSleepModalEmptyData(emptyData)
        break;
      default:
        break;
    }
    setMoodAndSleepModalVisible(true);
  }

  // close add modal
  const callbackAddModalVisible = () => {
    setAddModalVisible(false);
  }
  // close modal
  const callbackVisible = () => {
    setMoodAndSleepModalVisible(false);
  }

  const updateMedicationIntakeModal = () => {
    setMedicationIntakeModal(!medicationIntakeModal)
  }

  const updateMedicationIntakeData = (data) => {
    setMedicationIntakeData(data)
    updateMedicationIntakeModal();
  }

  const updateAction = (data: Record<string, any>) => {
    dispatch(startProgramAction({
      actionId: data.actionId,
      done: data.done,
      actionEventId: data.actionEventId,
    }))
  }

  const moodAndSleepModalBtn = [{
    type: 'primary',
    title: t('translation:general.header.btn_save'),
    click: () => {
      saveMoodAndSleep(moodAndSleepModalId, moodAndSleepModalType, moodAndSleepModalValue, moodAndSleepModalDate, moodAndSleepModalEmptyData, dispatch);
      callbackVisible();
    }
  }]
  const updateFilter = (values) => {
    // @ts-ignore
    setTempFocusDay(focusItem?.item.date)
    let filterArr: any[] = []
    values.map((item: any) => {
      // replace label text with the default key, in order to be translated
      let findRow = filtersData.findIndex(lItem => lItem.value === item.value)
      if (findRow > -1)
        values[findRow].label = filtersData[findRow].label

      if (item.selected !== undefined && item.selected && item.value !== 'all_events') {
        filterArr.push(item)
      }
      else if (item.value === 'all_events') {
        filterArr.push()
      } else {
        filterArr.push()
      }
    })
    setFilter(filterArr)
    setFilterModalData(values)
    setDisplayFilterModal(false)
  }

  const changeModalVisibility = () => {
    setDisplayFilterModal(!displayFilterModal)
  }

  const medicationIntakeModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.notifications.edit'),
      click: () => {
        updateMedicationIntakeModal();
        navigation.navigate('AddEvent', { itemType: 'Reminder' })
      }
    },
    {
      type: 'primary',
      title: t('translation:general.header.btn_save'),
      action: 'save',
      click: (event, taken_date) => {
        const obj = {
          eventId: event.id,
          taken: true,
          ...(taken_date && { taken_date })
        }
        dispatch(updateMedicationIntake(obj));
        updateMedicationIntakeModal();
      }
    }
  ]

  useEffect(() => { // clear params when user change tab
    if (!isFocused) {
      route.params = undefined;
    }
  }, [isFocused])

  const handleActionSchedule = (action: ProgramAction, actionId: string, date?: string, time?: string, old_date?: string) => {
    let dateTime: string | undefined;
    if (date && time)
      dateTime = dateTimeEvents(date, time)

    dispatch(startProgramAction({
      actionId,
      scheduledDate: dateTime,
      old_date
    }))
  }

  const getActionSchedule = (actionId, actionCallback) => {
    dispatch(getPatientProgramActionSchedule({
      programActionId: actionId, callback: () => {
        actionCallback()
      }
    }))
  }

  const getActionEvents = (actionId, actionCallback) => {
    if (!patientPrograms || patientPrograms.length === 0) {
      dispatch(getPatientPrograms({
        callback: (res) => {
          const clickedPatientProgram = res.find(item => item.actions && item.actions.find(_action => _action.id === actionId))
          if (!clickedPatientProgram)
            return;
          dispatch(getPatientProgramActionEvents({
            patientProgramId: clickedPatientProgram.id, callback: (result) => {
              actionCallback(result)
            }
          }))
        }
      }))
    }
    else {
      const clickedPatientProgram = patientPrograms.find(item => item.actions && item.actions.find(_action => _action.id === actionId))
      if (!clickedPatientProgram)
        return;
      dispatch(getPatientProgramActionEvents({
        patientProgramId: clickedPatientProgram.id, callback: (result) => {
          actionCallback(result)
        }
      }))
    }
  }

  const removeAction = (action) => {
    const patientProgram = patientPrograms.find(program => program.actions && !!program.actions.find(item => item.id === action.id))

    if (patientProgram)
      dispatch(addProgramAction({ actionId: action.id, action: action, patient_program_id: patientProgram.id }))
  }

  if (isLoading)
    return <Loading netConnected={true} />
  else
    return (
      <>
        {moodAndSleepModalVisible &&
          <CommonModal
            testID="moodAndSleepModal"
            type={'modal'}
            title={moodAndSleepModalTitle}
            moodAndSleep={true}
            buttons={moodAndSleepModalBtn}
            moodAndSleepValue={setMoodAndSleepModalValue}
            onClose={callbackVisible}
            setValue={moodAndSleepModalValue}
            route={navigation}
          />
        }
        {
          displayFilterModal &&
          <CommonActionModal
            testID={"filterModalID"}
            title={t('translation:modal.titles.filter')}
            modalType={'select-radio'}
            buttons={[{ title: t('translation:modal.btn.apply_filter'), type: 'primary', click: updateFilter, action: 'save' }]}
            onClose={changeModalVisibility}
            data={JSON.parse(JSON.stringify(filterModalData)).map(filterItem => {
              return { label: translate(t, filterItem.label, 'list.journal_filters'), value: filterItem.value, selected: filterItem?.selected }
            })}
          />
        }
        {
          AddModalVisible &&
          <AddModal title={t('translation:modal.add_item_title')} testID="addModalId" onClose={callbackAddModalVisible} navigation={navigation} />
        }
        {
          medicationIntakeModal &&
          <CommonActionModal
            testID='medicationIntakeModal'
            modalType='intakes'
            title={t('translation:general.info_popup.medication_types.drug.title')}
            onClose={updateMedicationIntakeModal}
            buttons={medicationIntakeModalBtn}
            data={medicationIntakeData}
          />
        }
        <SafeAreaView style={styles.container}>
          {filter.length > 0 ?
            <CommonHeader
              testIDTitle="diary_title"
              testIDRightFirst="filterBtnID"
              testIDRightSecond="addBtnID"
              title={t('translation:general.page_title.journal')}
              imageName="applied_filter_Helpi"
              secondImageName="helpiAddIconFilled"
              rightClickFirst={() => { setDisplayFilterModal(true) }}
              rightIconNameSecond="plus-circle"
              rightIconColorSecond={GlobalStyles.helpi.btnColor}
              rightClickSecond={() => { setAddModalVisible(true) }}
              mainPage={true}
            />
            :
            <CommonHeader
              testIDTitle="diary_title"
              testIDRightFirst="filterBtnID"
              testIDRightSecond="addBtnID"
              title={t('translation:general.page_title.journal')}
              rightIconNameFirst="filter"
              rightIconColorFirst={GlobalStyles.helpi.btnColor}
              rightClickFirst={() => { setDisplayFilterModal(true) }}
              rightIconNameSecond="plus-circle"
              secondImageName="helpiAddIconFilled"
              rightIconColorSecond={GlobalStyles.helpi.btnColor}
              rightClickSecond={() => { setAddModalVisible(true) }}
              mainPage={true}
            />
          }

          <View style={{ alignSelf: 'center', width: '90%' }}>
            <CommonWeekView
              data={GetEventsResponse !== undefined ? GetEventsResponse : []}
              elScroll={(e) => { setWeekViewClicked(true); onWeekViewClick(e, GetEventsResponse, scrollRef.current); }}
              focus={focusItem !== undefined ? focusItem.index : -1}
              startEndDay={sendWeekDays}
            />
          </View>
          {
            isPastLoading &&
              <View style={{height: PR(50)}}>
                <Loading netConnected={true}/>
              </View>
          }
          <View style={styles.scrollViewWrapper}>
            {GetEventsResponse.length !== 0 &&
              <>
                <View style={styles.monthContainer}>
                  {focusItem !== undefined ?
                    <Text style={styles.monthTxt}>{month} {year}</Text>
                    :
                    <Text></Text>
                  }
                </View>
                <FlatList
                  testID={'diaryList'}
                  ref={scrollRef}
                  data={GetEventsResponse !== undefined ? JSON.parse(JSON.stringify(GetEventsResponse)) : []}
                  renderItem={renderItem}
                  keyExtractor={keyExtractor}
                  removeClippedSubviews={true}
                  initialNumToRender={GetEventsResponse.length}
                  onViewableItemsChanged={onViewRef.current}
                  viewabilityConfig={viewConfigRef.current}
                  maxToRenderPerBatch={7}
                  windowSize={10}
                  onScrollToIndexFailed={info => {
                    const wait = new Promise(resolve => setTimeout(resolve, 500));
                    wait.then(() => {
                      scrollRef.current?.scrollToIndex({ index: info.index, animated: false });
                    });
                  }}
                  onMomentumScrollEnd={() => { setWeekViewClicked(false) }}
                  onScroll={({ nativeEvent }) => {
                    setWeekNextPrev('')
                    if (weekViewClicked && Platform.OS === 'web') {
                      setTimeout(() => {
                        setWeekViewClicked(false)
                      }, 1000)
                    }
                    else if (isCloseToTop(nativeEvent) && !weekViewClicked) {
                      setIsPastLoading(true)
                      setWeekNextPrev('prev')
                      setUpdating(weekCreation('prev', isFocused, GetEventsResponse, dispatch) as boolean)
                    }
                    else if (isCloseToBottom(nativeEvent) && !weekViewClicked) {
                      setWeekNextPrev('next')
                      setUpdating(weekCreation('next', isFocused, GetEventsResponse, dispatch) as boolean)
                    }
                  }}
                  onEndReachedThreshold={0.5}
                />
              </>
            }
          </View>
        </SafeAreaView>
      </>
    )

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
  },
  monthContainer: {
    width: '90%',
    alignSelf: 'center'
  },
  monthTxt: {
    fontFamily: GlobalStyles.global.fontFamily.Bold,
    fontSize: PR(18),
    color: GlobalStyles.global.black,
    textTransform: 'capitalize'
  }
})

export default Diary