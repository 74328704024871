import React from "react";
import { useTranslation } from "react-i18next";
import CommonBtn from "../CommonBtn";
import CommonSimpleModal from "../CommonSimpleModal";
import Text from "../CommonCustomTxt";
import { View } from "react-native";
import { GenericRecord } from "../../types";
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
interface OwnProps {
  event?: Partial<GenericRecord>;
  onClose: () => void;
  onConfirm: () => void;
  confirmationText?: string;
}

const CommonDeleteEventModal = ({ event, onClose, onConfirm, confirmationText }: OwnProps) => {
  const { t } = useTranslation();
  const confirmLiteral = confirmationText || `events.${event?.type}.delete_confirm`;

  return (
    <CommonSimpleModal
      title={t("translation:modal.titles.warning")}
      alignTitle="center"
      titleWidth="95%"
      onClose={onClose}
    >
      <View
        style={{
          width: "90%",
          alignSelf: "center",
          marginTop: PR(10),
          marginBottom: PR(10),
        }}
      >
        <Text style={{ fontSize: PR(16), textAlign: "center" }}>
          {t(`translation:${confirmLiteral}`)}
        </Text>
      </View>
      <View style={{marginBottom: PR(5)}}>
        <CommonBtn
          click={onClose}
          title={t("translation:modal.btn.cancel")}
          type="tertiary"
        />
      </View>
      <CommonBtn
        click={onConfirm}
        title={t("translation:modal.btn.confirm")}
        type="primary"
      />
    </CommonSimpleModal>
  );
};

export default CommonDeleteEventModal;
