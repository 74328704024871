import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, View, ScrollView, SafeAreaView } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonNotifications from 'common/src/components/CommonNotifications';
import CommonTimeline from 'common/src/components/CommonTimeline';
import CommonMedicationAdherence from 'common/src/components/CommonMedicationAdherence';
import CommonBtn from 'common/src/components/CommonBtn';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Text from 'common/src/components/CommonCustomTxt';
import { updateMoodAndSleepAction, updateMedicationIntake, updatePatientSiteAction, getPatientSitesAction } from '../store/actions/authenticationActions';
import { getPatientPrograms } from '../store/programs/programsActions';
import { resetEvents } from '../store/events/eventActions';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';
import CommonModal from 'common/src/components/CommonModal';
import { WEEK } from 'common/src/types/index';
import { checkNotificationType } from 'common/src/services/notifications';
import CommonActionModal from 'common/src/components/CommonActionModal';
import { addEventAction } from '../store/actions/authenticationActions';
import { updateEvent } from '../store/events/eventActions'
import { userLanguage } from '../store/reducers/getMeReducer';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import { RootState } from '../store/reducers';
import { useUpdateTimezoneAndVersion } from "common/src/hooks/timezone_version/useUpdateTimezoneAndVersion";
import { useUpdateTimezoneBanner } from "common/src/hooks/timezone_version/useUpdateTimezoneBanner";
import { version } from "../../package.json";
import { saveMoodAndSleep as _saveMoodAndSleep } from '../components/DiaryFunctions';
import moment from 'moment';

const FEATURED_EDUCATION = 'featured_education';
const GO_PROGRAM_OVERVIEW = 'goto_programOverview';
const NEW_PROGRAM_ACTION = 'new_program';

const Home = ({ navigation }) => {
  const { t, i18n } = useTranslation();
  const Me = useSelector((state: any) => state.getMe.response?.result)
  const Patient = useSelector((state: any) => state.getMe.patient)
  const DataSharing = useSelector((state: any) => state.getMe.dataSharing)
  const PatientPrograms = useSelector((state: RootState) => state.programsReducers.patientPrograms)
  const LibraryPrograms = useSelector((state: RootState) => state.programsReducers.programs)
  const language = useSelector(userLanguage);
  const [selectedTimeline, setSelectedTimeline] = useState(WEEK)
  const [medAdherenceModal, setMedAdherenceModal] = useState(false);
  const [moodAndSleepModal, setMoodAndSleepModal]: any = useState(null);
  const [moodAndSleepValue, setMoodAndSleepValue] = useState(5);
  const [moodAndSleepData, setMoodAndSleepData]: any = useState(null);
  const [medicationIntakeModal, setMedicationIntakeModal] = useState(false);
  const [medicationIntakeData, setMedicationIntakeData] = useState(null);
  const dispatch = useDispatch();
  const updateTimezoneVersion = useUpdateTimezoneAndVersion({version});
  const updateTimezoneBanner = useUpdateTimezoneBanner();

  useEffect(() => {
    updateTimezoneVersion;
    updateTimezoneBanner;
  }, []);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);


  useEffect(() => {
    if (DataSharing?.length > 0) {
      const activeDataSharing = DataSharing.find(ds => ds.isActive);
      if (activeDataSharing) {
        return navigation.navigate('DataSharingSignedIn', { callback: updatePatientStudyDisclaimer, nextAction: 'Home' })
      }
    }
  }, [DataSharing])

  useEffect(() => {
    if (DataSharing?.length === 0 && Me.hasStudy && Me.showStudyDisclaimer)
      getPatientsSites();
  }, [Me])

  const getPatientsSites = () => {
    const sites = dispatch(getPatientSitesAction({
        patientId: Patient.id,callback: (sites) => {
            const site = sites[0]
            return navigation.navigate('StudyDisclaimer', { siteId: site._id, study: site.study[0], callback: updatePatientStudyDisclaimer })
        }
      }))
    }
  const updatePatientStudyDisclaimer = (siteId) => {
    dispatch(updatePatientSiteAction({ siteId, patientId: Patient.id, data: { hideDisclaimer: true } }))
    navigation.navigate('Home')
  }

  const updateMedAdherenceModal = () => {
    setMedAdherenceModal(!medAdherenceModal)
  }

  const updateMedicationIntakeModal = () => {
    setMedicationIntakeModal(!medicationIntakeModal)
  }

  const resetEventsDiary = () => {
    dispatch(resetEvents());
  }

  const goToProgram = (patientProgramId, patientPrograms, notification) => {
    let programFound = patientPrograms.find(item => ((item.id === patientProgramId) || (item.program.id === patientProgramId)))
    if(programFound){
        const programToGo = programFound.subProgram ? programFound.subProgram : programFound.program
        Object.assign(programToGo, {patientProgramId: patientProgramId})
        return navigation.navigate('Programs', {selectedTab: notification?.data?.programTab})
      }
  }

  const notificatioNService = async (notification) => {
    const typeFound: any = await checkNotificationType(notification, navigation, saveForm, Patient, PatientPrograms, LibraryPrograms)
    if (typeFound.exists)
      typeFound.action();
    else {
      switch (notification.type) {
        case 'notif_medication':
          setMedicationIntakeData(notification);
          setMedicationIntakeModal(true);
          break;
        case 'mood':
          setMoodAndSleepData({
            eventID: notification.event_id,
            data: {
              type: 'mood'
            }
          })
          setMoodAndSleepModal('mood');
          break;
        case 'sleep':
          setMoodAndSleepData({
            eventID: notification.event_id,
            data: {
              type: 'sleep'
            }
          })
          setMoodAndSleepModal('sleep');
          break;
        case FEATURED_EDUCATION:
        case GO_PROGRAM_OVERVIEW:
          let patientProgramId = notification?.data?.patientProgramId ? notification?.data?.patientProgramId : notification?.data?.patientProgram
          if (!PatientPrograms)
            dispatch(getPatientPrograms({callback: (res) => {
                return goToProgram(patientProgramId, res, notification)
            }}))
          else
            return goToProgram(patientProgramId, PatientPrograms, notification)
          break;
        case NEW_PROGRAM_ACTION:
          return navigation.navigate('Programs')
        default:
          break;
      }
    }
  }

  const saveMoodAndSleep = () => {
    let obj = { ...moodAndSleepData }
    _saveMoodAndSleep(obj.eventID, obj.data.type, moodAndSleepValue, moment().format(), true, dispatch);

    setMoodAndSleepValue(5)
    setMoodAndSleepData(null)
    setMoodAndSleepModal(null)
  }

  const moodAndSleepModalBtn = [{
    type: 'primary',
    title: t('translation:general.header.btn_save'),
    click: saveMoodAndSleep
  }]

  const medicationIntakeModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.notifications.edit'),
      click: () => {
        updateMedicationIntakeModal();
        navigation.navigate('AddEvent', { itemType: 'Reminder' })
      }
    },
    {
      type: 'primary',
      title: t('translation:general.header.btn_save'),
      action: 'save',
      click: (notification, taken_date) => {
        const obj = {
          eventId: notification.event_id,
          taken: true,
          ...(taken_date && { taken_date })
        }
        dispatch(updateMedicationIntake(obj));
        updateMedicationIntakeModal();
      }
    }
  ]

  const saveForm = (type, event) => {
    type === 'add' ? dispatch(addEventAction({ data: event.data })) : dispatch(updateEvent(event))
  }
  const onAddSeizureClick = () => {
    navigation.navigate('AddEvent', { itemType: 'Seizure' })
  }

  return (
    <>
      <SafeAreaView style={styles.container}>
        {
          medAdherenceModal &&
          <CommonModal
            type="info"
            title={t('translation:modal.titles.help')}
            text={t('translation:dashboard.medication_adherence.popup.text')}
            onClose={updateMedAdherenceModal}
            buttons={[{ title: t('translation:modal.btn.ok'), click: updateMedAdherenceModal }]} />
        }
        {
          moodAndSleepModal &&
          <CommonModal
            testID="moodAndSleepModal"
            type={'modal'}
            title={moodAndSleepModal === 'mood' ? t('translation:widget.daily_reminder.mood') : t('translation:widget.daily_reminder.sleep_quality')}
            moodAndSleep={true}
            buttons={moodAndSleepModalBtn}
            moodAndSleepValue={setMoodAndSleepValue}
            onClose={() => { setMoodAndSleepModal(null); setMoodAndSleepValue(5) }}
            setValue={moodAndSleepValue}
            route={navigation}
          />
        }
        {
          medicationIntakeModal &&
          <CommonActionModal
            testID='medicationIntakeModal'
            modalType='intakes'
            title={t('translation:general.info_popup.medication_types.drug.title')}
            onClose={updateMedicationIntakeModal}
            buttons={medicationIntakeModalBtn}
            data={medicationIntakeData}
          />
        }
        <CommonHeader
          title={t('translation:general.page_title.dashboard')}
          testIDTitle="Home"
          mainPage={true}
          addActionBtnChildren={
            <View style={{ width: '100%' }}>
              <CommonBtn
                type="primary"
                testID="add_seizure_header"
                title={t('translation:general.page_title.add_seizure')}
                click={onAddSeizureClick}
                size="small"
              />
            </View>
          }
        />
        <ScrollView style={{ flex: 1 }}>
          <View style={{ marginBottom: PR(25) }}>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionHeaderText}>
                {t('translation:dashboard.notifications.title')}
              </Text>
              <CommonNotifications action={notificatioNService} navigation={navigation} />
            </View>
          </View>
          <View style={{ marginTop: PR(25), marginBottom: PR(25) }}>
            <View style={styles.sectionHeader}>
              <View style={{ flexDirection: 'row', width: '101%', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                <View style={{ width: '70%' }}>
                  <Text style={styles.sectionHeaderText}>
                    {t('translation:dashboard.seizures.graph_title')}
                  </Text>
                </View>
                <View style={{ flex: 1, alignItems: 'center' }}>
                  <CommonBtn
                    type="secondary"
                    testID="see_more_graphs"
                    title={t('translation:dashboard.more_graphs_btn')}
                    click={() => navigation.navigate('MoreGraphs')}
                    size='small'
                  />
                </View>
              </View>
              <CommonTimeline
                resetEventsOnDiary={resetEventsDiary}
              />
            </View>
          </View>
          <View style={{ marginTop: PR(25), marginBottom: PR(25) }}>
            <View style={styles.sectionHeader}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={styles.sectionHeaderText}>
                  {t('translation:dashboard.medication_adherence.title')}
                </Text>
                <TouchableOpacity style={{ marginLeft: PR(10) }} onPress={updateMedAdherenceModal} >
                  <Icon name="info" size={PR(20)} />
                </TouchableOpacity>
              </View>
              <CommonMedicationAdherence selectedDisplayType={selectedTimeline} />
            </View>
          </View>
          <View style={{ marginBottom: PR(75) }}></View>
        </ScrollView>
      </SafeAreaView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles.global.white,
  },
  sectionHeader: {
    width: '90%',
    alignSelf: 'center',
    paddingVertical: PR(6),
  },
  sectionHeaderText: {
    color: GlobalStyles.global.black,
    fontSize: PR(20),
    fontFamily: GlobalStyles.global.fontFamily.Bold,
    letterSpacing: 0,
    marginBottom: PR(12)
  },
});

export default Home;
