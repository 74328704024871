import React, {useReducer, useEffect, useState} from 'react';
import {StyleSheet, Platform, ScrollView, View, TouchableOpacity} from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonTitle from 'common/src/components/CommonTitle';
import InputField from 'common/src/components/InputField';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Text from 'common/src/components/CommonCustomTxt';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonDateInput from 'common/src/components/CommonDateInput';
import CommonFooter from 'common/src/components/CommonFooter';
import { useDispatch, useSelector } from 'react-redux';
import { getMeAction, removeAccountAction, logOutAction, updateAddressAction } from '../store/actions/authenticationActions';
import { getProfileDetailsAction, updateProfileDetailsAction } from '../store/profileDetails/profileDetailsActions';
import CommonModal from 'common/src/components/CommonModal';
import {useTranslation} from 'react-i18next';
import {compareDatesForEvents} from 'common/src/services/compare';
import { DateTime } from "luxon";
import { generateArrayOfYears } from 'common/src/services/utils';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {countries} from 'common/src/services/countries.json';
import { RootState } from '../store/reducers';
import CommonKeyboardAvoidingView from "common/src/components/CommonKeyboardAvoidingView";

interface AddressFields {
  city?: string,
  country?: string,
  postcode?: string,
  street?: string
}

interface ProfileTypes {
  firstname?: string,
  lastname?: string,
  email?: string,
  phone?: string,
  birthday?: string,
  gender?: string,
  neuro_name?: string,
  hospital?: string,
  epilepsy_begin_year?: string,
  frequency?: string,
  address?: AddressFields
}

const Profile = ({navigation}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const ProfileResponse = useSelector((state:any) => state.getMe.response)
  const ProfileData = useSelector((state:RootState) => state.profileDetailsReducer.profileData)
  const [scrollState, setScrollState] = useState();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [checkDate, setCheckDate] = useState<boolean>(true)
  const [addressUpdating, setAddressUpdating] = useState(false)
  const [isAccountRemoved, setIsAccountRemoved] = useState(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [mandatoryFieldError, setMandatoryFieldError] = useState<boolean>(false);
  const [profileDetails, setProfileDetails] = useReducer(
    (state:ProfileTypes, newState:ProfileTypes) => ({...state, ...newState}),
    {
    firstname: '',
    lastname : '',
    email: '',
    phone: '',
    birthday: '',
    gender: '',
    neuro_name: '',
    hospital: '',
    epilepsy_begin_year: '',
    frequency: '',
    address: {
      city: '-',
      country: '-',
      postcode: '-',
      street: '-'
    }
  })

  let currentDate = DateTime.fromJSDate(new Date(Date.now() - 86400000)).toFormat('dd-MM-yyyy')

  const changeDate = (inputDate) => {
    if(DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      inputDate = DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setBirthday(inputDate)
    }
    if(compareDatesForEvents(inputDate, currentDate, 'dd-MM-yyyy'))
      setCheckDate(false)
    else
      setCheckDate(true)
  }

  const fetchIpLocation = async () => {
    fetch('https://ipapi.co/json/')
    .then(response => response.json())
    .then(async data => {
      countries.sort((a,b) => (a.country === data.country_name) ? -1 : ((b.country !== data.country_name) ? 1 : 0))
    })
  }
  
  // check if the response of the reducer is undefined 
  useEffect(() => {
    if (addressUpdating) {
      setAddressUpdating(false)
      return;
    }
    if(ProfileResponse === undefined) {
      if(!isAccountRemoved)
        dispatch(getMeAction());
    }
    else if(!ProfileData){
      dispatch(getProfileDetailsAction({patientId: ProfileResponse.result.patients.id}))
    } else {
      if(ProfileData && (!ProfileData.address || !ProfileData.address.country))
        fetchIpLocation();

      setProfileDetails({
        firstname: ProfileData.first_name,
        lastname : ProfileData.last_name,
        email: ProfileData.email,
        phone: ProfileData.phone,
        birthday: ProfileData.birthday,
        gender: ProfileData.gender,
        neuro_name: ProfileData.neurologist_name,
        hospital: ProfileData.hospital,
        epilepsy_begin_year: ProfileData.epilepsy_begin_year,
        frequency: ProfileData.frequency,
        address: ProfileData.address && {
          city: ProfileData.address !== null ? ProfileData.address.city : '-',
          country: ProfileData.address !== null ? ProfileData.address.country : '-',
          postcode: ProfileData.address !== null ? ProfileData.address.postcode : '-', 
          street: ProfileData.address !== null ? ProfileData.address.street : '-'
        }
      })
    }
  },[ProfileResponse, ProfileData]);
  /**
 * update the local state according to text input change
 */
  const setFirstname = (text:string) => {
    setProfileDetails({
      firstname: text,
    })
  }
  const setLastname = (text:string) => {
    setProfileDetails({
      lastname: text,
    })
  }
  const setPhone = (text:string) => {
    setProfileDetails({
      phone: text,
    })
  }
  const setBirthday = (text:string) => {
    setProfileDetails({
      birthday: text,
    })
  }
  // keep tracking of the changes of the modalVisible
  const callbackVisible = () => {
    setModalVisible(false);
  }
  const setGender = (answer:string) => {
    setProfileDetails({
      gender: answer
    })
  }
  const setNeuro = (text:string) => {
    setProfileDetails({
      neuro_name: text,
    })
  }
  const setHospital = (text:string) => {
    setProfileDetails({
      hospital: text,
    })
  }
  const setBeginOfEpilepsy = (text: string) => {
    setProfileDetails({
      epilepsy_begin_year: text
    })
  }
  const setFrequency = (text: string) => {
    setProfileDetails({
      frequency: text
    })
  }
  const setCountry = (text:string) => (
    setProfileDetails({
      address: {
        country: text,
        city: profileDetails.address?.city,
        postcode: profileDetails.address?.postcode,
        street: profileDetails.address?.street,
      }
    }),
    setMandatoryFieldError(false)
  )

  const closeErrorModal = () => {
    setErrorModal(false)
  }

  const errorModalBtn = [{
    type: 'primary',
    title: t('translation:modal.btn.close'),
    click: closeErrorModal
  }]


  const isMandatoryFieldsEmpty = () => {
    if(profileDetails.address?.country === '' || !profileDetails.address?.country)
      return true
    else 
      return false
  }
  // sent the request in order to update the profile
  const update = () => {
    if(isMandatoryFieldsEmpty()){
      setErrorModal(true)
      setMandatoryFieldError(true)
    } else {
      dispatch(updateProfileDetailsAction({
        updateMe:{
          first_name: profileDetails.firstname,
          last_name : profileDetails.lastname,
          email: profileDetails.email,
          phone: profileDetails.phone,
        },
        patients:{
          first_name: profileDetails.firstname,
          last_name : profileDetails.lastname,
          email: profileDetails.email,
          phone: profileDetails.phone,
          birthday: profileDetails.birthday,
          gender: profileDetails.gender,
          neurologist_name: profileDetails.neuro_name,
          hospital: profileDetails.hospital,
          epilepsy_begin_year: profileDetails.epilepsy_begin_year,
          frequency: profileDetails.frequency,
          address: {
            country: profileDetails.address?.country,
            city: profileDetails.address?.city,
            postcode: profileDetails.address?.postcode,
            street: profileDetails.address?.street,
          }
        },
        id:ProfileResponse.result.patients.id,
        callback: () => {
          if(profileDetails.address?.country &&  profileDetails.address?.country !== ''){
            dispatch(updateAddressAction({
              address: {
                country: profileDetails.address?.country,
                city: profileDetails.address?.city,
                postcode: profileDetails.address?.postcode,
                street: profileDetails.address?.street,
              }
            }));
          }

          navigation?.navigate?.('Settings')
        }
      }));
    }
  }

  const openModal = () => {
    setModalVisible(true);
  }

  const removeAccount = () => {
    dispatch(removeAccountAction({
      callback: () => {
        setIsAccountRemoved(true);
        dispatch(logOutAction());
      }
    }));
    setModalVisible(false);
  }

  const confirmModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackVisible
    },
    {
    type: 'primary',
    title: t('translation:modal.btn.confirm'),
    click: removeAccount
    }
  ]
  // unmount component
  useEffect(() => {
    return () => {
      setProfileDetails({
        firstname: '',
        lastname : '',
        email: '',
        phone: '',
        birthday: '',
        gender: '',
        neuro_name: '',
        hospital: '',
        epilepsy_begin_year: '',
        frequency: '',
        address: {
          city: '',
          country: '',
          postcode: '',
          street: ''
        }
      })
    };
  }, []);

  const onAddressGoBack = (profile) => {
    setAddressUpdating(true);
    setProfileDetails(profile)
  }

  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => {return (!checkDate )}

  const confirmModalTxt = t('translation:profile.remove_modal')
  return (
    <>
      { errorModal &&
        <CommonModal
          testID="errorModal"
          type={'error'}
          title={t('translation:modal.titles.error')}
          text={t('translation:errors.required')}
          buttons={errorModalBtn}
          onClose={closeErrorModal}
        />
      }
      <CommonKeyboardAvoidingView>
        <CommonHeader
          testIDTitle="profile_title"
          title={t('translation:general.page_title.profile')}
          leftIconName="arrow-left"
          leftIconColor={GlobalStyles.helpi.color}
          leftClick={() => {navigation.goBack()}}
          scroll={scrollState}
        />
        <View style={styles.scrollViewWrapper}>
          <ScrollView 
            testID="profile_scrollView"
            style={styles.scrollview}
            scrollEventThrottle={16}
            onScroll={(event: any) => {
              setScrollState(event.nativeEvent.contentOffset.y);
            }}
          >
            <View style={styles.seperationTitleContainer}>
              <CommonTitle text={t('translation:profile.essential_information')} size={PR(20)} color={GlobalStyles.global.black} txtAlign="left" testID="essentialID"/>
            </View>
            <InputField 
              labelText={t('translation:profile.form.firstname')}
              inputType="string" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setFirstname}
              placeholderTxt="-" 
              testID="Firstname"
              value={profileDetails.firstname}
            />
            <InputField 
              labelText={t('translation:profile.form.lastname')}
              inputType="string" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setLastname}
              placeholderTxt="-" 
              testID="Lastname"
              value={profileDetails.lastname}
            />
              <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                  <Text style={styles.label}>{t('translation:profile.form.email')}</Text>
                  <Text style={[styles.label, {fontSize:PR(10)}]}>{t('translation:general.form.not_editable')}</Text>
                </View>
                <View testID="Email" style={styles.summaryContainer}>
                  <View style={styles.inputSummaryContainer}>
                    <Text style={styles.inputSummaryValue}>{!profileDetails.email ? '-' : profileDetails.email}</Text>
                  </View>
                </View>
              </View>
            <InputField
              labelText={t('translation:treatment.form.epilepsy_begin_date')}
              inputType="dropdown" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setBeginOfEpilepsy}
              placeholderTxt="" 
              testID="epilepsy_begin_year"
              value={profileDetails.epilepsy_begin_year}
              data={generateArrayOfYears()}
            />
            <InputField
              labelText={t('translation:treatment.form.frequency')}
              inputType="dropdown" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setFrequency}
              placeholderTxt="" 
              testID="Frequency"
              value={t('translation:'+profileDetails.frequency)}
              data={[
                { label: t('translation:list.seizure_frequencies.0.less_1_year'), value: 'list.seizure_frequencies.0.less_1_year' },
                { label: t('translation:list.seizure_frequencies.1.2_to_5_year'), value: 'list.seizure_frequencies.1.2_to_5_year' },
                { label: t('translation:list.seizure_frequencies.2.1_per_month'), value: 'list.seizure_frequencies.2.1_per_month' },
                { label: t('translation:list.seizure_frequencies.3.more_1_month'), value: 'list.seizure_frequencies.3.more_1_month' },
                { label: t('translation:list.seizure_frequencies.4.not_know'), value: 'list.seizure_frequencies.4.not_know' }
              ]}
            />
            <InputField
                labelText={t('translation:modal.address_picker.country')+' (*)'}
                inputType="dropdown" 
                customStyle={{marginBottom:PR(30)}}
                changeText={setCountry}
                error={mandatoryFieldError}
                errorText={t('translation:server.errors.required').replace('%s', '"'+ t('translation:modal.address_picker.country') +'"')}
                highlightFirstItem={!ProfileResponse?.result?.address || !ProfileResponse?.result?.address?.country ? true: false}
                data={countries.map(c => ({label: c.country, value: c.country}))}
                testID="country"
                value={profileDetails.address?.country === '' ? "Select a Country" : profileDetails.address?.country}
                isSearchEnabled={true}
              />
            <View style={[styles.seperationTitleContainer, {paddingTop:PR(30)}]}>
              <CommonTitle text={t('translation:profile.optional_information')} size={PR(20)} color={GlobalStyles.global.black} txtAlign="left" testID="optionalID"/>
            </View>
            <InputField 
              labelText={t('translation:profile.form.phone')}
              inputType="cel-phone" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setPhone}
              placeholderTxt="-" 
              testID="Phone"
              value={profileDetails.phone}
            />
            <View style={styles.dateInput}>
              <View style={styles.dateInputLabel}>
                <Text style={styles.dateInputLabelText}>{t('translation:profile.form.birthday')}</Text>
              </View>
              <CommonDateInput
                onChange={d => changeDate(d)}
                value={profileDetails.birthday}
                fieldStyle={styles.dateInputField}
                textStyle={styles.dateInputFieldText}
                maxDate={new Date(Date.now() - 86400000)}
                webInputStyle={{
                  backgroundColor: "#F2F4F5", 
                  border: "none", 
                  width: "100%", 
                  color: GlobalStyles.global.black,
                  fontSize: PR(16),
                  fontFamily: GlobalStyles.global.fontFamily.Medium
                }}
              />
              {!checkDate &&
                <Text style={styles.errorTxt}>{t('translation:events.future_date_error')}</Text>
              } 
            </View>
            <InputField 
              labelText={t('translation:profile.form.gender')}
              inputType="dropdown"
              customStyle={{marginBottom: PR(30)}}
              changeText={setGender}
              placeholderTxt={t('translation:profile.form.gender_placeholder')}
              testID="Gender"
              value={profileDetails.gender}
              data={[
                {label: t('translation:profile.form.gender.male'), value: 'male'},
                {label: t('translation:profile.form.gender.female'), value: 'female'}
              ]}
            />
            <InputField 
              labelText={t('translation:profile.form.neurologist')}
              inputType="string" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setNeuro}
              placeholderTxt={t('translation:profile.form.neurologist_placeholder')}
              testID="Neurologist_name"
              value={profileDetails.neuro_name}
            />
            <InputField 
              labelText={t('translation:profile.form.hospital')}
              inputType="string" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setHospital}
              placeholderTxt={t('translation:profile.form.hospital_placeholder')} 
              testID="Your_hospital"
              value={profileDetails.hospital}
            />
            <View style={styles.item}>
              <Text style={styles.itemText}>{t('translation:profile.form.address')}</Text>
              <View style={styles.addressWrapper}>
                <TouchableOpacity testID="addressID" style={styles.addressContainer} onPress={() => {navigation.navigate('Address', { address: profileDetails.address, profile: profileDetails, onGoBack: onAddressGoBack })}}>
                  <Text numberOfLines={1} style={styles.addressField}>{profileDetails.address?.street}</Text>
                  <Text numberOfLines={1} style={styles.addressField}>{profileDetails.address?.postcode}</Text>
                  <Text numberOfLines={1} style={styles.addressField}>{profileDetails.address?.city}</Text>
                </TouchableOpacity>
              </View>
            </View>
            <CommonFooter>
              <CommonBtn type="primary" click={update} title={t('translation:profile.form.btn_update')} testID="update_profile" disable={checkDisable()}/>
            </CommonFooter>
            <TouchableOpacity onPress={openModal} style={styles.removeContainer} testID="remove_account">
              <Text style={styles.removeTxt}>{t('translation:profile.remove')} </Text>
            </TouchableOpacity>
            { modalVisible &&
              <CommonModal testID="removeAccountModal" testIDBtn="BtnID" type={'warning'} title={t('translation:profile.remove')} text={confirmModalTxt} buttons={confirmModalBtn} onClose={callbackVisible}/>
            }
          </ScrollView>
        </View>
      </CommonKeyboardAvoidingView>
    </>
  )
}

const styles = StyleSheet.create({
  safeAreaContainer: {
    flex: 1,
    backgroundColor: GlobalStyles.global.white,
  },
  container: {
    display: "flex",
    flex:1,
    // backgroundColor: GlobalStyles.global.white,
    // paddingTop: isIphoneX() || DeviceInfo.hasDynamicIsland() ? PR(40) : Platform.OS === 'ios'? PR(20):0
  },
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
  },
  item: {
    width: '90%',
    alignSelf: 'center',
    marginBottom: PR(25)
  },
  itemText: {
    marginBottom: PR(10),
    marginLeft: PR(20),
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  addressWrapper: {
    backgroundColor: '#F2F4F5',
    borderRadius: PR(20),
    height: PR(100)
  },
  addressContainer: {
    flex: 1,
    flexDirection: 'column',
    height: PR(62),
    padding: PR(10),
    fontSize: PR(16),
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  addressField: {
    flex: 1,
    flexGrow: 1,
    color: GlobalStyles.global.black,
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  seperationTitleContainer: {
    width:'90%',
    alignSelf:'center'
  },
  removeContainer: {
    alignSelf: 'center',
    marginBottom: Platform.OS === 'web' ? PR(10) : PR(40)
  },
  removeTxt: {
    color: '#fb667a',
    fontFamily: GlobalStyles.global.fontFamily.Bold
  },
  dateInput: {
    width:'90%',
    alignSelf:'center',
    marginBottom: PR(30)
  },
  dateInputLabel: {
    marginBottom: PR(10),
    marginLeft: PR(20),
  },
  dateInputLabelText: {
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  dateInputField: {
    backgroundColor: '#F2F4F5',
    borderRadius: PR(20),
    borderColor: '#F2F4F5',
    height: PR(62),
    paddingHorizontal: PR(20),
    paddingVertical: PR(20),
  },
  dateInputFieldText: {
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  errorTxt: {
    color: GlobalStyles.global.redError,
    textAlign: 'center'
  },
  label: { 
    marginBottom: PR(10),
    marginLeft: PR(20),
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  summaryContainer: {
    backgroundColor: GlobalStyles.global.footerGrey,
    borderRadius: PR(20),
  },
  inputSummaryContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: PR(62),
    padding: PR(10),
    fontSize: PR(16),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputSummaryValue: {
    flex: 1,
    flexGrow: 1,
    color: GlobalStyles.global.black,
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
})

export default Profile;