import { AnyAction } from 'redux';
import { RootState } from '../reducers';
import { EpilepsyDetailsActions } from './epilepsyDetailsActions';
import {SeizureDescriptionCardTypes, SeizureAntidoteCardTypes, TriggersModelCardTypes} from './types'

interface EpilepsyDetailsReducer {
    seizureDescriptionCard: SeizureDescriptionCardTypes | null
    seizureAntidoteCard: SeizureAntidoteCardTypes | null
    getPatientAllEvents: Array<any> | null
    triggersModelCard: TriggersModelCardTypes | null
}

const initialState:EpilepsyDetailsReducer = {
    seizureDescriptionCard: null,
    seizureAntidoteCard: null,
    getPatientAllEvents: null,
    triggersModelCard: null
};

export default function (state = initialState, action: AnyAction): EpilepsyDetailsReducer {
    if (action.type === EpilepsyDetailsActions.GET_SEIZURES_DESCRIPTION_CARD_SUCCESS){
        return {
            ...state,
            seizureDescriptionCard: action.response?.result === null ? null : action.response
        }
    }

    if(action.type === EpilepsyDetailsActions.ADD_UPDATE_SEIZURES_DESCRIPTION_CARD_SUCCESS){
        const { result } = action.response;
        return {
            ...state,
            seizureDescriptionCard: result.seizureDescription
        }
    }

    if (action.type === EpilepsyDetailsActions.GET_SEIZURES_ANTIDOTE_CARD_SUCCESS){
        return {
            ...state,
            seizureAntidoteCard: action.response?.result === null ? null : action.response
        }
    }

    if(action.type === EpilepsyDetailsActions.ADD_UPDATE_SEIZURES_ANTIDOTE_CARD_SUCCESS){
        const { result } = action.response;
        return {
            ...state,
            seizureAntidoteCard: result.seizureAntidote
        }
    }

    if (action.type === EpilepsyDetailsActions.GET_TRIGGERS_MODEL_CARD_SUCCESS){
        return {
            ...state,
            triggersModelCard: action.response?.result === null ? null : action.response
        }
    }

    if(action.type === EpilepsyDetailsActions.ADD_UPDATE_TRIGGERS_MODEL_CARD_SUCCESS){
        const { result } = action.response;
        return {
            ...state,
            triggersModelCard: result.triggersModel
        }
    }
    
    if(action.type === EpilepsyDetailsActions.GET_PATIENT_EVENT_SUCCESS){
        const { result } = action.response;
        return {
            ...state,
            getPatientAllEvents: result
        }
    }
    return state;
}

export const seizureDescriptionCard = (state: RootState) => state.epilepsyDetailsReducers.seizureDescriptionCard;

export const seizureAntidoteCard = (state: RootState) => state.epilepsyDetailsReducers.seizureAntidoteCard;

export const getPatientAllEvents = (state: RootState) => state.epilepsyDetailsReducers.getPatientAllEvents;

export const triggersModelCard = (state: RootState) => state.epilepsyDetailsReducers.triggersModelCard;
