import * as types from '../list_of_languages/listOfLangActions';

export default function(state:any = {
    listOfLang: [],
  }, action:any) {
  const response = action.response;
  switch(action.type) {
    case types.GET_LIST_OF_LANGUAGES_SUCCESS:
      return { 
        ...state, 
        listOfLang: response
      }; 
    default:
      return state;
  }
};