import React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import GlobalStyle from '../../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../../env.json';
import Text from '../CommonCustomTxt';
import CommonImage from "../CommonImage";
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';

// type = rounded || rounded-checkbox || squared
const CommonSelectItem = ({selected, onClick, onDelete, showDelete, type, text}:any) => {

    const styles: any = generateStyleSheet(type || 'rounded', selected);

    return (
        <View>
            <TouchableOpacity onPress={onClick}>
                <View style={styles.wrapper}>
                    <View style={styles.txtWrapper}>
                        <Text style={styles.checkBoxTxt}>{text}</Text>
                    </View>
                    <View style={styles.iconsWrapper}>
                        {showDelete && (
                            <TouchableOpacity onPress={() => onDelete()}>
                                <View style={styles.deleteIconContainer}>
                                    <Icon name="trash-2" size={PR(24)} color={GlobalStyle.global.redError}/>
                                </View>
                            </TouchableOpacity>
                        )}
                        <View style={styles.checkBoxContainer}>
                            {
                                selected && (
                                        type === 'rounded-checkbox' ?
                                            <View style={styles.checkMark}>
                                                <CommonImage name={'checkmarkIcon'} height={PR(11)} width={PR(11)}/>
                                            </View> :
                                            <View style={styles.checkedRadio}>
                                            </View>
                                )
                            }
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}

const generateStyleSheet = (type, selected) => {
    return StyleSheet.create({
        wrapper: {
            flexDirection: 'row',
            padding: PR(5),
            paddingHorizontal: PR(12),
            justifyContent: 'space-between'
        },
        txtWrapper: {
            flexShrink: 1
        },
        checkBoxContainer: {
            height: PR(24),
            width: PR(24),
            borderWidth: PR(2),
            backgroundColor: GlobalStyle.global.white,
            borderColor: selected ? GlobalStyle[APP_SHORTCUT_NAME].darkColor : GlobalStyle.global.lightGrey,
            borderRadius: type === 'rounded' || type === 'rounded-checkbox' ? PR(15) : 0,
            justifyContent: 'center',
            alignItems: 'center',
            padding: PR(2)
        },
        checkedRadio: {
            height: PR(16),
            width: PR(16),
            backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].darkColor,
            borderRadius: type === 'rounded' || type === 'rounded-checkbox' ? PR(15) : 0,
        },
        checkMark: {

        },
        checkBoxTxt: {
            color: selected ? GlobalStyle[APP_SHORTCUT_NAME].darkColor : GlobalStyle.global.black,
            fontSize: PR(16),
            lineHeight:PR(25),
            opacity: selected ? 1 : 0.8,
            fontFamily: selected ? GlobalStyle.global.fontFamily.Medium : GlobalStyle.global.fontFamily.Regular
        },
        deleteIconContainer: {
            paddingHorizontal: PR(10),
        },
        iconsWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }
    })
}

export default CommonSelectItem;