import { call, takeLatest } from "redux-saga/effects";
import { makeRequest } from "../../services/requestService";
import { AnyAction } from "redux";
import {
	CheckEmailInterface,
	EmailExistanceActions,
} from "./emailExistanceAction";

export function* checkEmailExistanceSaga(
	payload: AnyAction & CheckEmailInterface
) {
	yield call(makeRequest, {
		path: `/auth/checkemail?language=${payload.language}`,
		method: "POST",
		data: {
			email: payload.email,
		},
		...payload,
	});
}

export default function* watch() {
	yield takeLatest(
		EmailExistanceActions.CHECK_EMAIL_EXISTANCE,
		checkEmailExistanceSaga
	);
}
