export const ADD_TREATMENT_ACTION = 'ADD_TREATMENT_ACTION';
export const ADD_TREATMENT_SUCCESS = 'ADD_TREATMENT_SUCCESS';

export const ADD_INTAKE_ACTION = 'ADD_INTAKE_ACTION';
export const ADD_INTAKE_SUCCESS = 'ADD_INTAKE_SUCCESS';

export const UPDATE_TREATMENT_ACTION = 'UPDATE_TREATMENT_ACTION';
export const UPDATE_TREATMENT_SUCCESS = 'UPDATE_TREATMENT_SUCCESS';

export const UPDATE_DOSE_ACTION = 'UPDATE_DOSE_ACTION';
export const UPDATE_DOSE_SUCCESS = 'UPDATE_DOSE_SUCCESS';

export const DELETE_TREATMENT_ACTION = 'DELETE_TREATMENT_ACTION';
export const DELETE_TREATMENT_SUCCESS = 'DELETE_TREATMENT_SUCCESS';

export const DELETE_DOSE_ACTION = 'DELETE_DOSE_ACTION';
export const DELETE_DOSE_SUCCESS = 'DELETE_DOSE_SUCCESS';

export const GET_UNIQUE_EVENT = 'GET_UNIQUE_EVENT';

export const addTreatmentAction = (payload:any) => ({
        type: ADD_TREATMENT_ACTION,
        ...payload
    }
);

export const addIntakeAction = (payload:any) => ({
        type: ADD_INTAKE_ACTION,
        ...payload
    }
);

export const updateTreatmentAction = (payload:any) => ({
        type: UPDATE_TREATMENT_ACTION,
        ...payload
    }
);

export const updateDoseAction = (payload:any) => ({
        type: UPDATE_DOSE_ACTION,
        ...payload
    }
);

export const deleteTreatmentAction = (payload:any) => ({
        type: DELETE_TREATMENT_ACTION,
        ...payload
    }
);

export const deleteDoseAction = (payload:any) => ({
        type: DELETE_DOSE_ACTION,
        ...payload
    }
);

export const getUniqueEventAction = (obj:any) => ({
        type: GET_UNIQUE_EVENT,
        obj
    }
);