import { call, takeLatest, put } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { AnyAction } from "redux";
import { 
    ProfileDetailsActions,
    GetProfileInterface,
    UpdateProfileInterface
} from "./profileDetailsActions";

export function* getProfileSaga(payload: AnyAction & GetProfileInterface) {
    yield call(makeRequest, {
        path: `/patients/${payload.patientId}/profile`,
        method: 'GET',
        responseAction: ProfileDetailsActions.GET_PROFILE_SUCCESS,
        ...payload
    });
}

export function* updateProfileSaga(payload: AnyAction & UpdateProfileInterface) {
    yield call(makeRequest, {
      path: '/patients/'+payload.id,
      method: 'PUT',
      data: payload.patients,
      responseAction: ProfileDetailsActions.UPDATE_PROFILE_SUCCESS,
      ...payload
    });
    yield call(makeRequest, {
      path: '/meApp',
      method: 'PUT',
      data: payload.updateMe,
      ...payload
    });
  }

export default function* watch() {
    yield takeLatest(ProfileDetailsActions.GET_PROFILE, getProfileSaga);
    yield takeLatest(ProfileDetailsActions.UPDATE_PROFILE, updateProfileSaga);
}