import React, { useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView, Platform, TouchableOpacity } from 'react-native';
import CommonHeader from './CommonHeader';
import GlobalStyles from '../styles/GlobalStyles';
import { useTranslation } from 'react-i18next';
import Text from './CommonCustomTxt';
import CommonSubTitle from './CommonSubtitle';
import CommonQuestionnairesDropdownList from './CommonQuestionnairesDropdownList';
import { getRequest, postRequest } from 'common/src/services/callService';
import {API_URL, APP_SHORTCUT_NAME} from 'common/src/env.json';
import CommonStudyContacts from './CommonStudyContacts';
import CommonModal from './CommonModal';
import {checkBottomScroll} from '../services/utils';
import Icon from 'react-native-vector-icons/Feather';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import { useSelector } from 'react-redux';
import CommonKeyboardAvoidingView from './CommonKeyboardAvoidingView';

const CommonStudySection =  (props) => {
    const {t, i18n} = useTranslation();
    const { language: userLanguage } = i18n;
    const [scrollState, setScrollState] = useState();
    const [expandedSection, setExpandedSection] = useState(-1)
    const [data, setData] = useState(null as any);
    const [scrollAtBottom, setScrollAtBottom] = useState(false);
    const [contactData, setContactData] = useState({})
    const [contactInfoModal, setContactInfoModal] = useState(false);
    const lastAddedForm = useSelector((state:any) => state.getEvents.lastAddedForm)
    const section = props.route.params.section;
    const study = props.route.params.study;
    const patientId = props.route.params.patientId;
    const siteId = props.route.params.siteId;
    const contactEmail = props.route.params.contactEmail;
    const saveForm = props.route.params.saveForm;
    const contactBlock = props.route.params.contactBlock;

    const sortFormsByDate = (steps) => {
        steps.map((step) => {
            step.forms.sort((a,b) => {
                if (a.sentDate > b.sentDate)
                    return 1
                else if (a.sentDate < b.sentDate)
                    return -1
                else
                    return 0
            })
        })
    }

    useEffect(() => {
        async function getData() {
            if (section.type === 'information')
                setData(study.informations);
            else if (section.type === 'consent')
                setData(study.studyConsent);
            else if (section.type === 'forms') {
                const response = await getRequest(`${API_URL}/studies/${study._id}/patients/${patientId}/forms`);
                if (response.success) {
                    const stepsFound = response.result;
                    for (let i = 0; stepsFound[i]; i++) {
                        const step = stepsFound[i];
                        if (step.status === 'done' || step.status === 'over' || step.status === 'withdrawn')
                            continue
                        else {
                            setExpandedSection(i);
                            break;
                        }
                    }
                    sortFormsByDate(stepsFound)
                    setData(stepsFound);
                }
            }
            else
                setData([])
        }
        // if (!data)
            getData()
    },[lastAddedForm])

    const updateDisplayList = (expandedIndex) => {
        setExpandedSection(expandedSection === expandedIndex ? -1 : expandedIndex)
    }

    const dropdownContentType = () => {
        if (section.type === 'forms')
            return 'list';
        return 'text'
    }

    const updateDataValidation = (data) => {
        setContactData(data)
    }

    const sendContactQuestion = async () => {
        let dataToSend: any = {...contactData}
        dataToSend.contactEmail = contactEmail ? contactEmail : dataToSend.subjectContactEmail;
        const response = await postRequest(`${API_URL}/sites/${siteId}/contact`, dataToSend);
        props.navigation.goBack();
    }

    const updateContactInfoModal = () => {
        setContactInfoModal(!contactInfoModal)
    }

    return (
        <CommonKeyboardAvoidingView>
            {
                contactInfoModal && 
                    <CommonModal
                        type="info"
                        title={study.contactInfoTitle ? study.contactInfoTitle[userLanguage] || study.contactInfoTitle?.en : ""}
                        text={study.contactInfoText ? study.contactInfoText[userLanguage] || study.contactInfoText?.en : ""}
                        onClose={updateContactInfoModal}
                        buttons={[{title: t('translation:modal.btn.ok'), click: updateContactInfoModal }]}
                    />
            }
            <CommonHeader
                testIDTitle="study_overview_title"
                title={study.shortName[userLanguage] ||  study.shortName.en}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {Platform.OS === 'web' ? window.history.back() : props.navigation.goBack()}}
                scroll={scrollState}
            />
            <ScrollView 
                testID="study_overview_scrollView"
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                    if (section.type === 'contact') {
                        let calc = checkBottomScroll(event.nativeEvent, 0);
                        if (!scrollAtBottom && calc)
                            setScrollAtBottom(true)
                        else if (scrollAtBottom && !calc)
                            setScrollAtBottom(false)
                    }
                    setScrollState(event.nativeEvent.contentOffset.y);
            }}>
                <View style={{margin: PR(10)}}>
                    {
                        section.title &&
                            <View style={styles.sectionHeader}>
                                {
                                    section.type === 'contact' ?
                                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                            <Text style={[styles.sectionHeaderText, {color: GlobalStyles[APP_SHORTCUT_NAME].color}]}>
                                                {section.title[userLanguage] || section.title.en}
                                            </Text>
                                            <TouchableOpacity style={{marginLeft: PR(10), marginBottom: PR(15)}} onPress={updateContactInfoModal} >
                                                <Icon name="info" size={PR(20)}/>
                                            </TouchableOpacity>
                                        </View>
                                    :
                                        <Text style={[styles.sectionHeaderText, {color: section.color}]}>
                                            {section.title[userLanguage] || section.title.en}
                                        </Text>
                                }
                            </View>
                    }
                </View>
                <View style={{marginBottom: PR(50)}}>
                    {
                        section.subtitle &&
                            <CommonSubTitle text={section.subtitle[userLanguage] || section.subtitle.en}/>
                    }
                </View>
                {
                    section.type === 'contact' ?
                            <CommonStudyContacts study={study} updateValidation={updateDataValidation} contactBlock={contactBlock} sendContactQuestion={sendContactQuestion} contactEmail={contactEmail}/>
                        :
                        data && data.length > 0 && data.map((item, i) => (
                            <View style={styles.dropdownContainer}>
                                <CommonQuestionnairesDropdownList isExpanded={expandedSection === i ? true : false} updateDisplayList={updateDisplayList} index={i} type={section.type} displayType={dropdownContentType()} data={item} color={section.color} navigation={props.navigation} navigationParams={props.route.params} siteId={siteId} saveForm={saveForm}/>
                            </View>
                        ))
                }
            </ScrollView>
        </CommonKeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    sectionHeader: {
        width: '90%',
        alignSelf: 'center',
        paddingVertical: PR(6),
        justifyContent: 'center',
        alignItems: 'center'
    },
    sectionHeaderText: {
        fontSize: PR(20),
        fontFamily: GlobalStyles.global.fontFamily.Bold,
        letterSpacing: 0,
        marginBottom: PR(15),
        textAlign: 'center'
    },
    dropdownContainer: {
        marginBottom: PR(20),
    }
})

export default CommonStudySection;