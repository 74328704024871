import React, {useState, useEffect, useReducer} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import Text from '../CommonCustomTxt';
import GlobalStyles from '../../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../../env.json';
import Icon from 'react-native-vector-icons/Feather';
import {useTranslation} from 'react-i18next';
import { translate } from '../../services/translationList';
import { checkFrequency} from '../../services/utils';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';

// return only the unique values
const uniqueValue = (array) => {
  return array.filter((item,index) => {
    return array.indexOf(item) === index
  })
}
// return the days of the reminder 
const checkDays = (t, array, time) => {
  let week = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday']
  let count = 0
  let i:any = 0
  for( i in array) {
    if(array[i] === array[i-1])
      count++
  }
  if(count >= 6)
    return t('translation:treatment.intakes.every_day')
  else {
    let index:Array<number> = []
    array.map((itemPos,itemIndex) => {
      if(itemPos === time)
        index.push(itemIndex)
    })
    return index.map((item, itemPos) => {
      if(itemPos < index.length -1) 
        return t('translation:general.date.days.'+item+'.'+week[item])+', '
      else
        return t('translation:general.date.days.'+item+'.'+week[item])
    })
  }
}

// find the translation value of the key in an array of object in the CMS
const getListCMSData = (t, key, list) => {
  // @ts-ignore
  let index = t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true}).findIndex((itemType)=>
    Object.keys(itemType).toString() === key)
  if (index > -1) 
    return t('translation:'+list+'.'+index+'.'+key)
}

// sort moments in order to be displayed chronological 
const sortMoments = (t, array, list) => {
  let index 
  let newArr:any = []
  let sortedArr:any = []
  array.forEach(item => {
    // @ts-ignore
    index = t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true}).findIndex((itemType)=>
      Object.keys(itemType).toString() === item)
    if (index > -1) 
      newArr.push({moment:item, pos: index})
  })
  newArr.sort((a,b) => (a.pos > b.pos) ? 1 : ((b.pos > a.pos) ? -1 : 0))
  newArr.forEach(item => {
    sortedArr.push(item.moment)
  })
  return sortedArr
}

const Medications = ({medications, reminder_settings, navigation}) => {
  const {t} = useTranslation();
  const [time, setTime] = useState([])
  const [moment, setMoment] = useState([])
  const [showAllArr, setShowAllArr] = useReducer(
    (state:any, newState:any) => ({...state, ...newState}),
    {
      early_morning: 1, 
      morning: 1, 
      noon: 1, 
      afternoon: 1, 
      evening:1
    })
    let countArr:any = {
    early_morning: 0, 
    morning: 0, 
    noon: 0, 
    afternoon: 0, 
    evening:0
  }
let maxCount = 0

  useEffect(() => {
    const momentArray:any = []
    const timeArray:any = []
    // find the moments of the intake and push the to moment array
    medications.map((med) => {
      med?.intake?.dosage?.map((dos) =>{
        momentArray.push(dos.moment)
      })
    })
    let uniqueMoment = uniqueValue(momentArray)
    uniqueMoment = sortMoments(t, uniqueMoment, 'list.moments')
    setMoment(uniqueMoment)
    // find the time of the reminder and push it to time array
    uniqueMoment.forEach(mItem => {
      if(reminder_settings.hasOwnProperty(mItem) )
        timeArray.push({[mItem]: reminder_settings[mItem]})
    })
    setTime(timeArray)
  },[medications, reminder_settings])

  const goToReminderView = (momentItem) => {
    navigation.navigate('ReminderView',{
      moment: momentItem,
      time: time,
      medications: medications,
      reminder_settings: reminder_settings
    })
  }
  return(
    <>
      {moment.map((item,index) =>{
        maxCount = 0
        return(
          <>
          <View key={index} style={styles.container}>
            <View style={styles.medContainer}>
              <View style={styles.horizontalDivider}>
                {/* display the moment(morning, evening, noon etc...) */}
                <Text style={styles.medMomentTxt}>{getListCMSData(t, item, 'list.moments')}</Text>
              </View>
              <View 
                style={{paddingTop:PR(10)}}
              >
                {medications.map((med) => {
                  return (
                    <>
                    {med?.intake?.dosage?.map((dos,i) =>{
                      if(dos.moment === item)
                        maxCount = maxCount +1
                      if(dos.moment === item && countArr[item] <= showAllArr[item]){
                        countArr[item] = countArr[item] + 1
                        return(
                          // display the drug name, dose, unit
                          <Text numberOflines={2} key={i} style={styles.medTxt}>{med.name+' '+dos.dose+' '+translate(t, dos.unit, 'list.units')+' '+'('+checkFrequency(t, med.intake.days)+')'}</Text>
                        )
                      }
                    })}
                    </>
                  )
                })}
              </View>
              {countArr[item] === 2 && maxCount > 2 &&
                <TouchableOpacity onPress={() => setShowAllArr({[item]:Infinity})}>
                  <Text style={[styles.medTxt,{alignSelf:'flex-end'}]}>{t('translation:treatment.general.see_all')}</Text>
                </TouchableOpacity>
              }
              {countArr[item] > 2 &&
                <TouchableOpacity onPress={() => setShowAllArr({[item]:1})}>
                  <Text style={[styles.medTxt,{alignSelf:'flex-end'}]}>{t('translation:treatment.general.see_less')}</Text>
                </TouchableOpacity>
              }
            </View>
            <View style={styles.medBottomContainer}>
              <View style={{flexDirection: 'column',width:'90%'}}>
                {time.map(tItem => {
                  if(Object.keys(tItem).toString() === item){
                    let newUniqueArr = uniqueValue(tItem[item])
                    return (
                      <>
                      {newUniqueArr.map(uData => {
                        return (
                          <View style={{flexDirection: 'column'}}>
                            <View style={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',paddingHorizontal:PR(10)}}>
                              {/* display the time of the reminder */}
                              <View style={{width: '20%'}}>
                                <Text>{uData}</Text>
                              </View>
                              {/* display the days of the reminder */}
                              <View style={{width: '70%', }}>
                                <Text>{checkDays(t,tItem[item],uData)}</Text>
                              </View>
                            </View>
                          </View>
                        )
                      })}
                      </>
                    )
                  }
                })}
              </View>
              {/* display the edit icon */}
              <TouchableOpacity onPress={()=>goToReminderView(item,)} style={{width: '10%',alignItems:'flex-end',alignSelf:'center',paddingRight:PR(10)}}>
                <Icon name={'edit-2'} size={PR(20)} color={GlobalStyles[APP_SHORTCUT_NAME].color}/>
              </TouchableOpacity>          
            </View>
          </View>
          </>
        )
      })}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].lightColor,
    borderRadius: PR(20),
    width:'90%',
    alignSelf:'center', 
    marginBottom:PR(30)
  },
  medContainer: {
    flex: 1,
    minHeight: PR(62),
    padding: PR(10),
    fontSize: PR(16),
  },
  medBottomContainer: {
    backgroundColor:GlobalStyles.global.white, 
    borderWidth: 1, 
    borderColor: GlobalStyles[APP_SHORTCUT_NAME].lightColor , 
    borderBottomLeftRadius: PR(20), 
    borderBottomRightRadius: PR(20), 
    padding:PR(10),
    flexDirection: 'row',
  },
  medMomentTxt: {
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium,
    color: GlobalStyles.global.white,
    paddingBottom:PR(10)
  },
  medTxt: {
    flex: 1,
    flexGrow: 1,
    color: GlobalStyles.global.white,
    marginLeft: PR(10),
    fontSize: PR(12),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  horizontalDivider: {
    borderBottomColor: GlobalStyles.global.white,
    borderBottomWidth: 1,
  }
})

export default Medications;