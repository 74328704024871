import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Text from './CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from 'common/src/env.json';
import GlobalStyles from '../styles/GlobalStyles';
import { DateTime } from "luxon";
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonMedicationsHistoryPast = ({med,dos,to, id, navigation}) => {
  const {t} = useTranslation();
  const translate = (key, list) => {
    // @ts-ignore
    let index = t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true}).findIndex((itemType)=>
    Object.keys(itemType).toString() === key)
    if (index > -1) 
      return t('translation:'+list+'.'+index+'.'+key)
    else 
      return key
  }

  const calculateDose = () => {
    let count = 0
    let unit = ''
    if(dos.length > 0){
      dos.forEach(element => {
        count = count + element.dose
        unit = element.unit
      });
      return count + ' ' + unit
    } else return ''
    
  }
  return (
    <>
      <View 
        style={[styles.container,
        {backgroundColor: med.reason === 'epilepsy' ? GlobalStyles[APP_SHORTCUT_NAME].color : GlobalStyles.global.yellow}
        ]}
      >
        <TouchableOpacity onPress={()=> navigation.navigate('MedicationHistory', {id:id})} style={styles.wrapper}>
          <View style={styles.medContainer}>
            <View >
              <Text renderAsHtml={true} style={styles.medMomentTxt}>{med.name+' '+calculateDose()}</Text>
            </View>
            <View style={{paddingBottom:PR(5)}}>
              <Text renderAsHtml={true} style={styles.medTxt}>{translate(med.reason, 'list.medication_reasons')}</Text>
            </View>
          </View>
          <View style={styles.rightContainer}>
            <View style={styles.iconContainer}>
              {to !== undefined &&
                <Text style={styles.medTxt}>{t('translation:treatment.general.till')+' '+DateTime.fromISO(to).toFormat('MMM dd yyyy')}</Text>
              }
              <Icon 
                name={"chevron-right"} 
                size={PR(20)} 
                color={"#fff"} 
              />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </>  
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: PR(20),
    width:'90%',
    alignSelf:'center', 
    marginBottom:PR(10),
    shadowColor: '#333333',
    shadowOffset: {
      width: PR(2),
      height: PR(10),
    },
    shadowOpacity: 0.10,
    shadowRadius: 13.97,
    elevation: 10,
  },
  wrapper: {
    flexDirection: 'row',
    alignItems:'center'
  },
  medContainer: {
    flex:1,
    minHeight: PR(62),
    padding: PR(10),
    fontSize: PR(16),
    width: '60%'
  },
  medMomentTxt: {
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium,
    color: GlobalStyles.global.white,
    paddingBottom:PR(10)
  },
  medTxt: {
    color: GlobalStyles.global.white,
    marginLeft: PR(10),
    fontSize: PR(14),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  rightContainer: {
    width:'40%',
    paddingRight:PR(10),
    alignItems:'flex-end',
  },
  iconContainer: {
    alignItems:'center',
    flexDirection: 'row'
  },
  horizontalDivider: {
    borderBottomColor: GlobalStyles.global.white,
    borderBottomWidth: PR(1),
  }
})

export default CommonMedicationsHistoryPast;