import React from "react";
import { StyleSheet, View } from "react-native";
import { widthPercentageToDP as PR } from "../../styles/PixelRatio";

interface OwnProps {
  current: number;
  total: number;
  fillColor: string;
  backgroundColor: string;
}

const CommonProgressBar = ({
  current,
  total,
  fillColor,
  backgroundColor,
}: OwnProps): JSX.Element => {
  const Style = createStyle(backgroundColor, fillColor);

  return (
    <View style={Style.progressBarContainer}>
      <View style={Style.progressBar}>
        <View
          style={[
            Style.progressBarFill,
            { width: `${(current / total) * 100}%` },
          ]}
        ></View>
      </View>
    </View>
  );
};

const createStyle = (backgroundColor: string, fillColor: string) =>
  StyleSheet.create({
    progressBarContainer: {
      paddingTop: PR(3),
      width: "90%"
    },
    progressBar: {
      height: PR(10),
      width: "100%",
      backgroundColor,
      borderColor: backgroundColor,
      borderWidth: PR(1),
      borderRadius: PR(5),
    },
    progressBarFill: {
      height: PR(10),
      backgroundColor: fillColor,
      borderColor: fillColor,
      borderRadius: PR(5),
      flex: 1,
    },
  });

export default CommonProgressBar;
