import React, {useEffect, useState} from 'react';
import { SafeAreaView, View, StyleSheet, ScrollView } from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import Text from 'common/src/components/CommonCustomTxt';
import CommonQoLForm from 'common/src/components/CommonQoLForm';
import CommonBtn from 'common/src/components/CommonBtn';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updatePatientPrograms, getSubprograms } from '../store/programs/programsActions';
import { RootState } from '../store/reducers';

const QoLForm = ({navigation, route}) => {
    const {t, i18n} = useTranslation();
    const {language: userLanguage} = i18n;
    const dispatch = useDispatch();
    const currentSubprograms = useSelector((state: RootState) => state.programsReducers.currentSubprograms)
    const [subPrograms, setSubPrograms] = useState<Array<any>>([])
    const [answer, setAnswer] = useState<any>()
    const currentProgram = route.params.program
    currentProgram.title = currentProgram?.title || {en:t('translation:general.empty_title')}
    currentProgram.formSubtitle = currentProgram?.formSubtitle || {en:t('translation:general.empty_subtitle')}
    const ProgramsLibrary = useSelector((state: RootState) => state.programsReducers.programs)
    const PatientPrograms = useSelector((state: RootState) => state.programsReducers.patientPrograms)

    useEffect(() => {
        if(!currentSubprograms)
            dispatch(getSubprograms({mainProgramId: currentProgram.id}))
        else
            setSubPrograms(currentSubprograms);
    }, [currentSubprograms])


    const updateList = (selected, index) => {
        let _updatedList: any = [...subPrograms]
        let tempSelected
        _updatedList.map((item, i) => {
            item.selected = false
            if (i === index && selected !== false){
                item.selected = true;
                tempSelected = item
            }
        })
        setSubPrograms(_updatedList)
        setAnswer(tempSelected)
    }

    const saveForm = () => {
        let obj = {
            id:currentProgram.patientProgramId, 
            subProgram: answer.id,
            introductionDone: true,
            subProgramData: answer
        }
        dispatch(updatePatientPrograms({
            obj,
            callback: () => {
                navigation.pop();
                const program = { ...answer, patientProgramId: currentProgram.patientProgramId, id: currentProgram.id };
                if (PatientPrograms?.length === 1 && ProgramsLibrary?.length === 0)
                    navigation.navigate('Programs', { unqiueProgram: program });
                else
                    navigation.navigate('ProgramOverview', { program });
            }
        }))
    }

    const checkDisable = () => {return (!answer || !answer.selected)}

    return(
        <SafeAreaView style={styles.container}>
            <CommonHeader
                testIDTitle="qol_form_title"
                title={currentProgram?.title[userLanguage] || currentProgram?.title.en}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {
                    navigation.navigate('Main', {screen: 'Programs'});
                    navigation.reset({
                    index: 0,
                    routes: [{ name: 'Programs' }],
                    });
                }}
            />
            <View style={{width:'90%', alignSelf:'center', marginBottom:PR(36)}}>
                <Text testID='form_subtitle' style={styles.subtitleTxt}>{currentProgram.formSubtitle[userLanguage] || currentProgram.formSubtitle.en}</Text>
            </View>
            <ScrollView>
                {subPrograms.length > 0 &&
                    subPrograms.map((sp, index) => (
                        <View key={index} testID={'qol_form_element_'+index}>
                            <CommonQoLForm 
                                index={index}
                                subProgram={sp}
                                updateCheckBox={updateList}
                            />
                        </View>
                    ))
                }
                <View style={{marginTop: PR(32), marginBottom: PR(20)}}>
                    <CommonBtn 
                        type="primary" 
                        title={t('translation:general.header.btn_save')} 
                        click={saveForm}
                        testID='save_QoL_btn'
                        disable={checkDisable()}
                    />
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: GlobalStyles.global.white
    },
    subtitleTxt: {
        color: GlobalStyles.global.black,
        fontSize: PR(16),
        fontFamily: GlobalStyles.global.fontFamily.Bold
    },
});

export default QoLForm;