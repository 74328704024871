import { AnyAction } from 'redux';
import { RootState } from '../reducers';
import { NightwatchActions } from './nightwatchActions';

interface NightwatchReducerInterface {
    nightwatchAccount: string | null
}

const initialState:NightwatchReducerInterface = {
    nightwatchAccount: null
};

export default function (state = initialState, action: AnyAction): NightwatchReducerInterface {
    if (action.type === NightwatchActions.GET_NIGHTWATCH_ACCOUNT_SUCCESS){
        return {
            ...state,
            nightwatchAccount: action.response.result.message.account
        }
    } 
    else if(action.type === NightwatchActions.LOGOUT_FROM_NIGHTWATCH_SUCCESS) {
        return {
            ...state,
            nightwatchAccount: null
        }
    }
    return state;
}

export const nightwatchAccount = (state: RootState) => state.nightwatchReducer.nightwatchAccount;