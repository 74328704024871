import React, { useEffect, useState, useRef } from 'react'
import { View, ScrollView, StyleSheet, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CommonHeader from './CommonHeader';
import GlobalStyles from '../styles/GlobalStyles';
import { getRequest } from '../services/callService';
import {API_URL} from 'common/src/env.json';
import CommonBtn from './CommonBtn';
import CommonStudyFormQuestions from './CommonStudyFormQuestions';
import Text from './CommonCustomTxt';
import {APP_SHORTCUT_NAME} from '../env.json';
import CommonFooter from './CommonFooter';
import moment from 'moment';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import { convertLangCode } from '../services/translationList';
import CommonKeyboardAvoidingView from './CommonKeyboardAvoidingView';

const CommonStudyForm = (props) => {
    const {t, i18n} = useTranslation();
    const { language: userLanguage } = i18n;
    const patient = useSelector((state: any) => state.getMe.patient)
    const [stepIndex, setStepIndex] = useState(0);
    const [form, setForm]: any = useState();
    const questionnaire = props.route.params.questionnaire;
    const siteId = props.route.params.siteId ? props.route.params.siteId : questionnaire.site
    const expirationDate = questionnaire && questionnaire.expirationDate ? questionnaire.expirationDate : props.route.params.expirationDate;
    const [questions, setQuestions] = useState<Array<any>>([]);
    const [sections, setSections] = useState<Array<any>>([]);
    const saveForm = props.route.params.saveForm;
    const scrollRef = useRef<ScrollView>();
    const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        async function getForm() {
            let response;
            if (questionnaire.eventId)
                response = await getRequest(`${API_URL}/patients/${patient.id}/events/${questionnaire.eventId}`);
            else
                response = await getRequest(`${API_URL}/forms/${questionnaire.formId}`);
            if (response.success)
                setForm(response.result);
        }
        if (!form)
            getForm()
    })

    const checkFormExpiration = (formToCheck, form) => {
        if (!formToCheck.expirationDate) {
            if (form && form.expiration)
                return moment().isSameOrAfter(moment(form.expiration));
            return false
        }
        else
            return moment().isSameOrAfter(moment(formToCheck.expirationDate));
    }

    const removeConditionalValue = (data, isSection) => {
        if (!isSection)
            data.forEach((question) => {
                if (question.conditional && !question.isDisplay)
                    delete question.answer;
                
            })
        else {
            data.forEach((section) => {
                section.questions.forEach((question) => {
                    if (question.conditional && !question.isDisplay)
                        delete question.answer;
                })
            })
        }
        return data
    }

    const saveAnsweredForm = async () => {
        const correctedQuestions = removeConditionalValue(questions, false);
        const correctedSections = removeConditionalValue(sections, true);

        let event: any = {
            type: 'form',
            form: questionnaire.formId,
            formInfo: form,
            questions: correctedQuestions,
            sections: correctedSections
        };

        if (!questionnaire.isFromProgram && !questionnaire.eventId) {
            let patientForm: any = null
            if (typeof(questionnaire.dailyIndex) !== "undefined") {
                patientForm = patient.forms.find((f) => f.form === questionnaire.formId && f.dailyIndex === questionnaire.dailyIndex)
            }
            else
                patientForm = patient.forms.find((f) => f.form === questionnaire.formId && f.send_date === questionnaire.sentDate)

            if (patientForm) {
                event.expiration = patientForm.expiration;
                if (siteId) {
                    event.studyParameters = {
                        dailyIndex: patientForm.dailyIndex,
                        site: patientForm.site,
                        step: patientForm.step,
                        send_date: patientForm.send_date,
                        targetDate: patientForm.targetDate,
                        expiration: patientForm.expiration
                    }
                }
                if (patientForm.targetDate)
                    event.date = moment(patientForm.targetDate).format();
            }
        }

        const type = questionnaire.eventId ? 'edit' : 'add';
        const data = {data: event, eventId: questionnaire.eventId, patientId: patient.id}
        saveForm(type, data);
        props.navigation.goBack()
    }

    const checkConditionalQuestion = (question) => {
        if (!question.conditional) {
            question.isDisplay = true;
            return true
        }
        else {
            let shouldShow = !question?.conditions?.length;
            for (const condition of question.conditions) {
                if (!condition.depency || !condition.depency.answer || !condition.depency.isDisplay) {
                    shouldShow = false;
                    break;
                } 
                else {
                    let answerToCheck = condition.depency.answer_type === 'number' ? parseInt(condition.answer) : condition.answer;
                    let depencyAnswer = condition.depency.answer_type === 'number' ? parseInt(condition.depency.answer) : condition.depency.answer;
                    if (condition.depency.answer_type !== 'number' && condition.type === 'contains' && depencyAnswer.includes(answerToCheck)) {
                        shouldShow = true;
                    } else if (condition.type === 'equals' && depencyAnswer === answerToCheck) {
                        shouldShow = true;
                    } else if (condition.type === '>' && depencyAnswer > answerToCheck) {
                        shouldShow = true;
                    } else if (condition.type === '>=' && depencyAnswer >= answerToCheck) {
                        shouldShow = true;
                    } else if (condition.type === '<' && depencyAnswer < answerToCheck) {
                        shouldShow = true;
                    } else if (condition.type === '<=' && depencyAnswer <= answerToCheck) {
                        shouldShow = true;
                    } else {
                        shouldShow = false;
                        break;
                    }
                }
            }
            question.isDisplay = shouldShow
            return shouldShow
        }
    }

    const goNext = () => {
        setStepIndex(stepIndex + 1)
        scrollRef.current?.scrollTo({y: 0, animated: false})
    }

    const goPrevious = () => {
        setStepIndex(stepIndex - 1)
        scrollRef.current?.scrollTo({y: 0, animated: false})
    }

    useEffect(() => {
        if (form && Array.isArray(form.sections) && form.sections.length > 0) {
            form.sections.forEach((section, index) => {
                section.questions.forEach((question) => {
                    if (question.conditional) {
                        question.conditions.forEach((condition) => {
                            const questionFromSameSection = section.questions.find((item) => item.index === condition.index);
                            if (questionFromSameSection)
                                condition.depency = questionFromSameSection
                            else {
                                for (let i = 0; i <= index; i++) {
                                    const questionFromOtherSection = form.sections[i].questions.find((item) => item.index === condition.index);
                                    if (questionFromOtherSection)
                                        condition.depency = questionFromOtherSection;
                                }
                            }
                        })
                    }
                })
            })
            setSections([...form.sections])
        }
        
        else if (form && Array.isArray(form.questions)) {
            form.questions.forEach((question) => {
                if (question.conditional) {
                    question.conditions.forEach((condition) => {
                        const questionToCheck = form.questions.find((item) => item.index === condition.index);
                        if (questionToCheck)
                            condition.depency = questionToCheck;
                    })
                }
            })
            setQuestions([...form.questions]);
        }
    }, [form]);
    
    let disableButton = !questions?.length && !sections?.length;

    const checkButtonDisable = (question, disableButton) => {
        if (question.answer_type === 'section') return;
        else if (question.answer_type === 'list') {
            question.questions.map((listq) => {
                if (checkConditionalQuestion(listq) && !listq.answer && listq.question) { // check if question is not answered
                    disableButton = true;
                }
            })
        }
        else if (question.answer_type === 'rank') {
            disableButton = !question.answer
            for (const key in question.answer){
                if (!question.answer[key]) {
                    disableButton = true
                    break;
                }
            }
        }
        else if (checkConditionalQuestion(question) && !question.answer) { // check if question is not answered
            disableButton = true;
        }
        return disableButton;
    }

    if (questions?.length) {
        for (let i = 0; questions[i]; i++) {
            const question = questions[i]
            disableButton = checkButtonDisable(question, disableButton)
            if (disableButton) break;
        }
    }

    if (sections?.length) {
        for (let j = 0; sections[stepIndex].questions[j]; j++) {
            const question = sections[stepIndex].questions[j]
            disableButton = checkButtonDisable(question, disableButton)
            if (disableButton) break;
        }
    }


    const isExpired = checkFormExpiration(questionnaire, form);

    return (
        <CommonKeyboardAvoidingView>
            <CommonHeader
                testIDTitle="study_overview_title"
                title={t('translation:general.page_title.answerForm')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {Platform.OS === 'web' ? window.history.back() : props.navigation.goBack()}}
                disableFormsBanner={true}
            />
            <ScrollView
                ref={scrollRef}
                testID="study_overview_scrollView"
                scrollEventThrottle={16}
                contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', flexDirection: 'column' }}
            >
                {
                    form &&
                        <View>
                            <View style={{paddingHorizontal: PR(20)}}>
                                {
                                    isExpired &&
                                    <View style={styles.expiredContainer}>
                                        <Text renderAsHtml={true} style={styles.expiredText}>{t('translation:forms.general.disabled')}</Text>
                                    </View>
                                }
                                <View style={styles.sectionHeaderTextWrapper}>
                                    {
                                        (form.title || (form.form && form.form.title)) &&
                                            <Text renderAsHtml={true} style={styles.sectionHeaderText}>{form.title ? (form.title[userLanguage] || form.title.en) : (form.form.title[userLanguage] || form.form.title.en)}</Text>
                                    }
                                    {
                                        (form.form?.showFormDate || form.showFormDate) &&
                                            <Text style={{padding: PR(5)}}>{t('translation:forms.general.formDate').replace('$date', moment(questionnaire.targetDate || questionnaire.sentDate).format('DD/MM/YYYY'))}</Text>
                                    }
                                </View>
                            </View>
                            {
                                !!questions?.length &&
                                    questions.map((question, index) => {
                                        if (checkConditionalQuestion(question)) {
                                            return (<CommonStudyFormQuestions
                                                disable={isExpired}
                                                key={index}
                                                question={question}
                                                updateQuestion={(answer) => {
                                                    if (question.answer_type === 'list') return;
                                                    question.answer = answer
                                                    setRefresh(!refresh)
                                                }}
                                            />)
                                        }

                                    })
                            }
                            {
                                !!sections?.length && sections[stepIndex]?.name && 
                                    <View>
                                        <Text renderAsHtml={true} style={{fontSize: PR(20), paddingHorizontal: PR(20)}}>{sections[stepIndex].name[userLanguage] || sections[stepIndex].name.en}</Text>
                                    </View>
                            }
                            {
                                !!sections?.length && stepIndex < sections.length &&
                                    sections[stepIndex].questions.map((question) => {
                                        if (checkConditionalQuestion(question))    {
                                            return (<CommonStudyFormQuestions
                                                disable={isExpired}
                                                question={question}
                                                updateQuestion={(answer) => {
                                                    if (question.answer_type === 'list') return;
                                                    question.answer = answer;
                                                    setRefresh(!refresh)
                                            }} />)
                                        }
                                    })
                            }
                        </View>
                }
                <View style={{marginTop: PR(20)}}>
                    {
                        form && form.sections && stepIndex > 0 &&
                            <View style={styles.btnContainer}>
                                <CommonBtn title={t('translation:general.header.btn_back')} click={goPrevious} type='secondary'/>
                            </View>
                    }
                    {
                        form && !isExpired && (!sections.length || stepIndex === sections.length - 1) &&
                            <View style={styles.btnContainer}>
                                <CommonBtn
                                    title={t('translation:general.header.btn_save')}
                                    click={saveAnsweredForm}
                                    type='primary'
                                    disable={disableButton}
                                />
                            </View>
                    }
                    {
                        form && form.sections && stepIndex >= 0 && stepIndex < form.sections.length - 1 &&
                            <View style={styles.btnContainer}>
                                <CommonBtn
                                    title={t('translation:general.header.btn_next')}
                                    click={goNext}
                                    type='primary'
                                    disable={disableButton}
                                />
                            </View>
                    }
                </View>
            </ScrollView>
            <CommonFooter>
                <View style={{marginBottom: Platform.OS === 'ios' ? PR(20) : PR(5)}}>
                    {
                        (expirationDate || form?.expiration) &&
                            <Text style={{color: GlobalStyles[APP_SHORTCUT_NAME].color, alignSelf: 'center'}}>{t('translation:forms.general.validity').replace('$expirationDate', moment(expirationDate ? expirationDate : form?.expiration).locale(convertLangCode(userLanguage) || 'en').format('LL'))}</Text>
                    }
                    {
                        form && (form.copyright || (form.form && form.form.copyright)) &&
                            <Text renderAsHtml={true} style={{color: GlobalStyles.global.grey, alignSelf: 'center'}}>{form.copyright ? form.copyright[userLanguage] || form.copyright.en : form.form.copyright[userLanguage] || form.form.copyright.en}</Text>
                    }
                </View>
            </CommonFooter>
        </CommonKeyboardAvoidingView>
    )
};

const styles = StyleSheet.create({
    sectionHeaderText: {
        color: GlobalStyles.global.black,
        fontSize: PR(20),
        fontFamily: GlobalStyles.global.fontFamily.Bold,
        letterSpacing: 0,
        paddingVertical: PR(15),

    },
    sectionHeaderTextWrapper: {
        borderBottomColor: GlobalStyles.global.lightGrey,
        borderBottomWidth: PR(1),
        borderStyle: "solid",
    },
    btnWrapper: {
        width: '100%',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: PR(10)
    },
    btnContainer: {
        width: '100%',
        marginBottom: PR(10)
    },
    expiredContainer: {
        borderWidth: PR(1),
        borderColor: GlobalStyles.global.redError,
        borderRadius: PR(5),
        padding: PR(5),
        backgroundColor: GlobalStyles.global.redErrorBackground,
        alignItems: 'center',
        justifyContent: 'center'
    },
    expiredText: {
        color: GlobalStyles.global.redError
    }
})

export default CommonStudyForm