import React, {useState, useEffect} from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '../../CommonCustomTxt';
import {widthPercentageToDP as PR} from '../../../styles/PixelRatio';
import GlobalStyles from '../../../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../../../env.json';
import calculateMedicationAdherence from '../../CommonMedicationAdherence/calculate-medication-adherence';

type OwnProps = {
    day: DayTypes,
    eventType: string,
    switchWeek: number
}

interface DayTypes {
    moodSleep: Array<number>,
    reminders: Array<Record<string, any>>
}

const CommonImpactMoodSleepAdhBars = ({day, eventType, switchWeek}:OwnProps) => {
    const [averageWeekValue, setAverageWeekValue] = useState<number>(-1);
    
    useEffect(() => {
        day.moodSleep.length === 0 && 
            setAverageWeekValue(-1)
            
        day.moodSleep.length > 0 && 
            setAverageWeekValue(day.moodSleep.reduce((a,b) => a + b, 0) / day.moodSleep.length)

        day.reminders.length > 0 &&
            setAverageWeekValue(calculateMedicationAdherence([day]))
            
    }, [switchWeek,day])

    const displayMoodSleepAdhPerPeriod = (averageValue:number) => {
        const extraStyles = {
            height: averageValue > 0 ? PR(averageValue+2) : averageValue == 0 ? PR(2) : PR(100),
            backgroundColor: averageValue > -1 ? GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor : GlobalStyles.global.white,
            borderTopRightRadius: PR(5), 
            borderTopLeftRadius: PR(5), 
            borderBottomLeftRadius: PR(5), 
            borderBottomRightRadius: PR(5)
        }
        return (
            <View style={[styles.moodSleepAdhBarFilled, extraStyles]}>
                { eventType === 'reminder' && averageValue > 0 &&
                    <Text style={styles.moodSleepAdhBarCount}>{averageValue+'%'}</Text>
                }
                { eventType === 'reminder' && averageValue == 0 &&
                    <Text style={styles.moodSleepAdhBarCount}>{averageValue.toFixed(0)+'%'}</Text>
                }
                { eventType !== 'reminder' && averageValue/10 > 0 &&
                    <Text style={styles.moodSleepAdhBarCount}>{(averageValue/10).toFixed(1)}</Text>
                }
                { eventType !== 'reminder' && averageValue/10 == 0 &&
                    <Text style={styles.moodSleepAdhBarCount}>{(averageValue/10).toFixed(0)}</Text>
                }
            </View>
        )
    }

    return(
        <View>
            {
                eventType !== 'reminder' && averageWeekValue*10 < 10 && averageWeekValue*10 > -1 &&
                <View style={[styles.moodSleepAdhBarEmpty, {height: PR(100 - (averageWeekValue * 100)), borderBottomLeftRadius: 0, borderBottomRightRadius: 0}]}></View>
            }
            {
                eventType === 'reminder' && averageWeekValue < 100 && averageWeekValue > -1 && 
                <View style={[styles.moodSleepAdhBarEmpty, {height: PR(100 - (averageWeekValue)), borderBottomLeftRadius: 0, borderBottomRightRadius: 0}]}></View>
            }
            { 
                displayMoodSleepAdhPerPeriod(eventType === 'reminder' ? averageWeekValue : averageWeekValue * 100)
            }
        </View>
    )
}

const styles = StyleSheet.create({
    moodSleepAdhBarEmpty: { 
        width: '80%', 
        maxWidth: PR(50),
        backgroundColor: GlobalStyles.global.white, 
        alignSelf: 'center', 
        borderRadius: PR(5)
    },
    moodSleepAdhBarFilled: {
        width: '80%',
        maxWidth: PR(50),
        alignSelf: 'center',
        alignItems: 'center', 
        justifyContent: 'flex-start',
    },
    moodSleepAdhBarCount: {
        zIndex: 99,
        fontSize: PR(8),
        color: GlobalStyles[APP_SHORTCUT_NAME].color,
        marginTop: PR(-10)
    },
});

export default CommonImpactMoodSleepAdhBars;