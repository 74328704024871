import React from 'react';
import { View , StyleSheet, ScrollView, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import CommonBtn from './CommonBtn';
import Text from './CommonCustomTxt';
import CommonImage from './CommonImage';
import { isIphoneX } from 'react-native-iphone-x-helper';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonStudyDisclaimer = (props) => {
    const {t, i18n} = useTranslation();
    const {language: userLanguage} = i18n;
    const study = props.route.params.study
    const callback = props.route.params.callback
    const siteId = props.route.params.siteId

    const onClick = () => {
        callback(siteId)
    }

    return (
        <View style={styles.container}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{marginBottom: PR(85)}}
            >
                <CommonImage name="" uri={study.logo} height={PR(225)} width={PR(225)} style={{alignSelf: 'center'}}/>
                { study.disclaimerSubtitle && <Text renderAsHtml={true} style={styles.disclaimerSubtitle}>{study.disclaimerSubtitle[userLanguage] || study.disclaimerSubtitle.en}</Text> }
                <Text renderAsHtml={true}>{study.disclaimer[userLanguage] || study.disclaimer.en}</Text>
            </ScrollView>
            <View style={styles.btnContainer}>
                <CommonBtn type="primary" title={t('translation:general.next')} click={onClick}/>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "90%",
        height: "100%",
        alignSelf: 'center',
        paddingTop: isIphoneX() ? 40 : Platform.OS === 'ios' ? PR(20):0
    },
    btnContainer: {
        flex: 1,
        position: 'absolute',
        width: '100%',
        alignSelf: 'center',
        bottom: PR(35)
    },
    disclaimerSubtitle: {
        fontSize: PR(16),
        textAlign: "center"
    }
})

export default CommonStudyDisclaimer;