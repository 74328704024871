import React, {useState, useEffect} from 'react';
import { View, Platform } from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonAddTreatmentTitleSection from 'common/src/components/CommonAddTreatments/CommonAddTreatmentTitleSection';
import CommonAddTreatmentInfo from 'common/src/components/CommonAddTreatments/CommonAddTreatmentInfo';
import CommonAddTreatmentFrequency from 'common/src/components/CommonAddTreatments/CommonAddTreatmentFrequency';
import CommonAddTreatmentPeriod from 'common/src/components/CommonAddTreatments/CommonAddTreatmentPeriod';
import CommonTreatmentReview from 'common/src/components/CommonAddTreatments/CommonTreatmentReview';
import CommonModal from 'common/src/components/CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import { addTreatmentAction, addIntakeAction, getUniqueEventAction } from '../../store/treatments/treatmentsActions';
import { getDrugsAction } from '../../store/drugs/drugsActions'
import {useTranslation} from 'react-i18next';
import { DateTime } from "luxon";
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const AddTreatments = ({navigation}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const GetMedicationsHistory = useSelector((state:any) => state.getMedications.medicationsHistory)
  const GetDrugs = useSelector((state:any) => state.getDrugs.drugs);
  const [warningModal, setWarningModal] = useState<boolean>(false)
  const [infoAnswers, setInfoAnswers] = useState<any>()
  const [frequencyAnswers, setFrequencyAnswers] = useState({})
  const [periodAnswers, setPeriodAnswers] = useState()
  const [step, setStep] = useState<number>(0)
  const [enableBtn, setEnableBtn] = useState<boolean>(true)
  const today = DateTime.now();

  useEffect(() => {
    if(GetDrugs.length === 0)
      dispatch(getDrugsAction())
  },[])

  const getAnswers = (answers) => {
    if(answers?.nameOfTreat)
      setInfoAnswers(answers)
    else if(['daily', 'weekly', 'monthly', 'quarterly', 'other'].indexOf(answers?.frequency) >= 0){
      let obj = frequencyAnswers
      Object.assign(obj, answers);
      setFrequencyAnswers(obj)
    }
    else if(answers?.startDate)
      setPeriodAnswers(answers)
    else 
      setFrequencyAnswers(answers)
    setEnableBtn(!enableBtn)
    if(answers?.step)
      setStep(answers?.step)
    else  
      setStep(step+1)
  }

  const allData = () => {
    return {infoAnswers: infoAnswers, frequencyAnswers: frequencyAnswers, periodAnswers: periodAnswers}
  }

  const checkWeekDays = (weekArr) => {
    if(weekArr.length >= 7)
      return t('translation:treatment.add_medication.step_frequency.summary.every_day')
    else if(weekArr.length > 0) {
      return weekArr.map((item, i) => {
        if(i < weekArr.length-1) return t('translation:general.date.days.'+item.pos+'.'+item.day)+', '
        else return t('translation:general.date.days.'+item.pos+'.'+item.day)
      })
    } else return null
  }

  const booleanDaysArr = (weekArr) => {
    let daysBooleanArr: Array<boolean> = []
    let arr = [0,1,2,3,4,5,6]
    arr.forEach((item) => {
      if(weekArr.find(day => day.pos === item)) daysBooleanArr.push(true)
      else daysBooleanArr.push(false)
    })
    return daysBooleanArr
  }
  const sendRequest = (data) => {
    let start = DateTime.fromFormat(data?.periodAnswers?.startDate, 'dd-MM-yyyy').toLocal().toFormat('yyyy-MM-dd')
    let end = data?.periodAnswers?.endDate ? DateTime.fromFormat(data?.periodAnswers?.endDate, 'dd-MM-yyyy').toLocal().toFormat('yyyy-MM-dd') : null
    const obj = {data:{
      intake: {
        dosage: data?.frequencyAnswers?.dosage || null,
        daily_frequencies: data?.frequencyAnswers?.daily_frequencies,
        end_known: data?.periodAnswers?.endDateBool,
        frequency: data?.frequencyAnswers?.frequency,
        from: start,
        to: end,
        remarks: data?.frequencyAnswers?.remarks,
        specific_dosage: data?.frequencyAnswers?.MQDose ? {
          dose: parseInt(data?.frequencyAnswers?.MQDose),
          every: parseInt(data?.frequencyAnswers?.every),
          period: data?.frequencyAnswers?.otherPeriod || 'month',
          unit: data?.frequencyAnswers?.MQUnit,
          times: data?.frequencyAnswers?.times
        } : null,
        totalDailyDosage: data?.frequencyAnswers?.totalDose,
        week_frequency: checkWeekDays(data?.frequencyAnswers?.week_frequency || []),
        days: booleanDaysArr(data?.frequencyAnswers?.week_frequency || [])
      },
      treatment_type: data?.infoAnswers?.typeTreat,
      name: data?.infoAnswers?.nameOfTreat,
      reason: data?.infoAnswers?.reasonTreat,
      intake_type: data?.infoAnswers?.typeIntake || 'pill',
      review: true
    }}
    let msg = 'treatment.add_medication.success.added'
    dispatch(addTreatmentAction({
      obj, 
      callback: d => dispatch(addIntakeAction({
        t, d, obj, msg,
        callback: () => {
          if(today.toFormat('yyyy-MM-dd') >= start){
            let dateObj = {from:today.toFormat('yyyy-MM-dd')+'T00:00:01', to:today.toFormat('yyyy-MM-dd')+'T23:59:59'}
            dispatch(getUniqueEventAction(dateObj));
          }
          navigation.navigate('Main', { screen: 'Treatments' })
        }
      }))
    }));
  }

  const callbackVisible = () => {
    setWarningModal(false);
  }

  const goBackToTreatment = () =>{
    if(Platform.OS === 'web')
      window.history.back() 
    else
      navigation.goBack()
    setWarningModal(false)
  }

  const confirmModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackVisible
    },
    {
    type: 'primary',
    title: t('translation:modal.btn.confirm'),
    click: goBackToTreatment
    }
  ]

  let range = [
    {name:'add_medication.steps.step_1', stepNum: '1', stepName: t('translation:treatment.add_medication.steps.step_1')},
    {name:'add_medication.steps.step_2', stepNum: '2', stepName: t('translation:treatment.add_medication.steps.step_2')},
    {name:'add_medication.steps.step_3', stepNum: '3', stepName: t('translation:treatment.add_medication.steps.step_3')}
  ]

  return (
    <CommonKeyboardAvoidingView>
      { warningModal &&
        <CommonModal testID="warningModalID" type={'info'} title={t('translation:modal.titles.warning')} text={t('translation:treatment.add_medication.confirm.quit_action')} buttons={confirmModalBtn} onClose={callbackVisible}/>
      }
      <CommonHeader 
        testIDTitle="AddMedicationInfoID"
        title={t('translation:general.page_title.medication')}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={() => {setWarningModal(true)}}
        // scroll={scrollState}
      />
      {step < 3 ?
        <>
        <CommonAddTreatmentTitleSection step={step} imageName='treatmentsIcon' range={range}/>
        <View style={{flex:1}}>
        {step === 0 && 
          <CommonAddTreatmentInfo getAnswers={getAnswers} data={infoAnswers} navigation={navigation} existingTreatments={GetMedicationsHistory ? GetMedicationsHistory : []}/>
        }
        {step === 1 && 
          <CommonAddTreatmentFrequency getAnswers={getAnswers} freqStep={infoAnswers?.freqStep} freqData={frequencyAnswers} back={()=>setStep(step-1)}/>
        }
        {step === 2 && 
          <CommonAddTreatmentPeriod getAnswers={getAnswers} back={()=>setStep(step-1)} periodData={periodAnswers}/>
        }
        </View>
        </>
        :
        <CommonTreatmentReview data={allData()} back={setStep} doRequest={sendRequest}/>
      }
    </CommonKeyboardAvoidingView>
  )
}

export default AddTreatments;