import { useDispatch, useSelector } from 'react-redux';
import { useNetInfo } from "@react-native-community/netinfo";
import { updateTimezoneBannerAction } from 'helpilepsy/src/store/timezoneBanner/timezoneBannerActions';
import moment from 'moment';
import { timezones } from 'common/src/services/timezones.json';
import { useEffect } from 'react';

export const useUpdateTimezoneBanner = () => {
    const dispatch = useDispatch();
    const netInfo = useNetInfo();
    const GetMeAccountTimezone = useSelector((state:any) => state.getMe.accountTimezone);
    const deviceTz = timezones.find(tz => tz === moment.tz.guess());

    useEffect(() => {
		if (netInfo.isConnected) {
            if (deviceTz && GetMeAccountTimezone && GetMeAccountTimezone !== deviceTz) {
              const dateTime = moment().utc();
              // @ts-ignore
              const deviceOffset = moment.tz.zone(deviceTz)?.utcOffset(dateTime);
              // @ts-ignore
              const accountOffset = moment.tz.zone(GetMeAccountTimezone)?.utcOffset(dateTime)
              if (deviceOffset !== accountOffset) {
                dispatch(updateTimezoneBannerAction({ bannerExist: true }))
              } else {
                dispatch(updateTimezoneBannerAction({ bannerExist: false }))
              }
            } else {
              dispatch(updateTimezoneBannerAction({ bannerExist: false }))
            }
          }
	}, [GetMeAccountTimezone, netInfo.isConnected])
}