import { DateTime } from "luxon";

export const getDurationString = durationInMinutes => {
    if (!durationInMinutes) return '';
    const hours = Math.floor(durationInMinutes/60);
    const minutes = durationInMinutes - hours*60;
    const hoursString = `${hours}`.padStart(2, '0');
    const minutesString = `${minutes}`.padStart(2, '0')
    return `${hoursString}:${minutesString}`;
};

export const getLocalTime = (time: string, userTimezone: string): DateTime => {
    const [hour, minutes] = time.split(":");
  
    const userDateTime = DateTime.now()
      .setZone(userTimezone)
      .set({ hour: parseInt(hour), minute: parseInt(minutes) });
  
    return userDateTime.setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  };
  