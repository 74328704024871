export enum ProfileDetailsActions {
    GET_PROFILE = "GET_PROFILE",
    GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS",
    UPDATE_PROFILE = "UPDATE_PROFILE",
    UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
}

export interface GetProfileInterface {
    patientId: string
}

export interface UpdateProfileInterface {
    updateMe:{
        first_name: string | undefined,
        last_name : string | undefined,
        email: string | undefined,
        phone: string | undefined,
      },
      patients:{
        first_name: string | undefined,
        last_name : string | undefined,
        email: string | undefined,
        phone: string | undefined,
        birthday: string | undefined,
        gender: string | undefined,
        neurologist_name: string | undefined,
        hospital: string | undefined,
        epilepsy_begin_year: string | undefined,
        frequency: string | undefined,
        address: {
            country: string | undefined,
            city: string | undefined,
            postcode: string | undefined,
            street: string | undefined,
        }
      },
      id:string | undefined,
      callback: () => void;
}

export const getProfileDetailsAction = (payload: GetProfileInterface) => {
    return {
        type: ProfileDetailsActions.GET_PROFILE,
        ...payload
    };
};

export const updateProfileDetailsAction = (payload: UpdateProfileInterface) => {
    return {
        type: ProfileDetailsActions.UPDATE_PROFILE,
        ...payload
    };
};