import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import CommonAcceptanceCheckbox from './CommonAcceptanceCheckbox';
import InputField from './InputField';
import CommonFooter from './CommonFooter';
import CommonBtn from './CommonBtn';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonStudyContacts = ({study, updateValidation, contactBlock, sendContactQuestion, contactEmail}) => {
    const {t, i18n} = useTranslation();
    const {language: userLanguage} = i18n;
    const Patient = useSelector((state: any) => state.getMe.patient);
    const email = Patient.email;
    const [subjectList, setSubjectList] = useState(null);
    const [subject, setSubject] = useState('');
    const [subjectContactEmail, setSubjectContactEmail] = useState(null)
    const [question, setQuestion] = useState('');
    const [checked, setChecked] = useState(false);
    const [saveEnable, setSaveEnable] = useState(false);
    const confirmationText = study.contactConfirmationText ? study.contactConfirmationText[userLanguage] || study.contactConfirmationText.en : "";

    useEffect(() => {
        if (!subjectList) {
            let data: any = [];
            const subjects = study.contactSubjects && study.contactSubjects.length > 0 ? study.contactSubjects : contactBlock || [];
            subjects.forEach((item) => {
                data.push({
                    value: item.subject.en,
                    label: item.subject[userLanguage] || item.subject.en,
                    selected: false
                })
            })
            setSubjectList(data);
        }
    })

    useEffect(() => {
        updateDataValidation();
    }, [checked, subject, question])

    const onSubjectChange = (value) => {
        if (!contactEmail && contactBlock) {
            const subjectToFind = contactBlock.find(item => item.subject.en === value);

            if (subjectToFind){
                value = subjectToFind.subject[userLanguage] || subjectToFind.subject.en;
                setSubjectContactEmail(subjectToFind.email);
            }
        }
        setSubject(value)
    }

    const onQuestionChange = (value) => {
        setQuestion(value);
    }

    const onCheckBoxChange = () => {
        setChecked(!checked)
    }

    const updateDataValidation = () => {
        let validated = false;
        if (checked && question && subject)
            validated = true;
        if (validated !== saveEnable)
            setSaveEnable(validated)
        return updateValidation({email, subject, question, subjectContactEmail})
    }

    return (
        <>
            <View>
                <View style={{padding: PR(10)}}>
                    <InputField inputType="email" labelText={t('translation:studies.contact.email')} value={email} disable={true}/>
                </View>
                <View style={{padding: PR(10)}}>
                    <InputField inputType="dropdown" placeholderTxt={t('translation:studies.contact.select')} labelText={t('translation:studies.contact.subject')} value={subject} changeText={onSubjectChange} data={subjectList}/>
                </View>
                <View style={{padding: PR(10)}}>
                    <InputField inputType="string" placeholderTxt={t('translation:studies.contact.messagePlaceholder')} labelText={t('translation:studies.contact.question')} value={question} changeText={onQuestionChange} multiline={true}/>
                </View>
            </View>
            <View style={{width: '90%', alignSelf: 'center', padding: PR(20)}}>
                <CommonAcceptanceCheckbox testID="acceptanceCheckbox" text={confirmationText || t('translation:studies.contact.confirm')} updateValidation={onCheckBoxChange} checked={checked}/>
            </View>
            <CommonFooter atBottom={true}>
                <CommonBtn type='primary' title={t('translation:general.header.btn_save')} click={sendContactQuestion} disable={!saveEnable}/>
            </CommonFooter>
        </>
    )
}

export default CommonStudyContacts;