import React, {useState, useEffect} from 'react';
import { ScrollView, View, StyleSheet, Platform} from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonTitle from 'common/src/components/CommonTitle';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Text from 'common/src/components/CommonCustomTxt';
import InputField from 'common/src/components/InputField';
import CommonBtn from 'common/src/components/CommonBtn';
import Loading from '../Loading';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getNightwatchAccountAction, 
  loginToNightWatchAction, 
  logoutFromNightWatchAction 
} from '../../store/nightwatch/nightwatchActions';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

import { RootState } from 'helpilepsy/src/store/reducers';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const Nightwatch = ({navigation}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const GetMeResponse = useSelector((state:any) => state.getMe.response)
  const nightwatchAccountRes = useSelector((state: RootState) => state.nightwatchReducer.nightwatchAccount);
  const [scrollState, setScrollState] = useState();
  const [nightWatchLogin, setNightWatchLogin] = useState<string>(nightwatchAccountRes || '');
  const [nightWatchPwd, setNightWatchPwd] = useState<string>('');
  const [tempBtn, setTempBtn] = useState<boolean>(false)
  const [isLinked, setIsLinked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const getAccountFunc = () => {
    dispatch(getNightwatchAccountAction({
      patientId: GetMeResponse.result.patients.id,
      callback: () => {
        setIsLoading(false)
      },
      errorCallback: () => {
        setIsLoading(false)
      }
    }))
  }
  useEffect(() => {
    getAccountFunc();
  },[])
  // change the button according to the state
  useEffect(() => {
    if(nightwatchAccountRes) {
      setNightWatchLogin(nightwatchAccountRes)
      setTempBtn(true)
      setIsLinked(true)
      setTimeout(() => {
        setTempBtn(false)
      }, 1000);
    } 
    else {
      setIsLinked(false)
      setNightWatchLogin('')
    }
  },[nightwatchAccountRes]);

  const linkNightWatch = () => {
    setIsLoading(true)
    dispatch(loginToNightWatchAction({
      credentials: {
        login:nightWatchLogin, 
        password:nightWatchPwd
      },
      t,
      callback: () => {
        getAccountFunc();
        setIsLoading(false)
      },
      errorCallback: () => {
        setIsLoading(false)
      }
    }))
  }
  
  const unlinkNightWatch = () => {
    setIsLoading(true)
    dispatch(logoutFromNightWatchAction({
      msg: t('translation:settings.nightwatch.unlink_success_message'),
      callback: () => {
        setIsLoading(false)
        setNightWatchLogin('')
        setNightWatchPwd('')
      },
      errorCallback: () => {
        setIsLoading(false)
      }
    }))
  }
  if (isLoading)
    return <Loading netConnected={true} />
  else
    return (
      <CommonKeyboardAvoidingView>
        <CommonHeader 
          testIDTitle="nigthwatch_title"
          title={t('translation:settings.nightwatch.title')}
          leftIconName="arrow-left"
          leftIconColor={GlobalStyles.helpi.color}
          leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
          scroll={scrollState}
        />
        <View style={styles.scrollViewWrapper}>
          <ScrollView 
            testID="nightwatch_scrollView"
            style={styles.scrollview}
            scrollEventThrottle={16}
            onScroll={(event: any) => {
              setScrollState(event.nativeEvent.contentOffset.y);
            }}
          >
            <View style={styles.titleContainer}>
              <CommonTitle text={t('translation:settings.nightwatch.info_title')} color={GlobalStyles.global.black} txtAlign="left" testID="nightwatchTitle" />
            </View>
            <View style={styles.txtContainer}>
              <Text renderAsHtml={true} style={styles.txt}>{t('translation:settings.nightwatch.info_description')}</Text>
            </View>
            <View style={{paddingTop:PR(28)}}>
              <InputField 
                labelText={t('translation:settings.nightwatch.login_title')}
                inputType="string" 
                customStyle={{marginBottom:PR(30)}}
                changeText={setNightWatchLogin}
                placeholderTxt={t('translation:settings.nightwatch.login_placeholder')}
                testID="nightWatchLogin"
                value={nightWatchLogin}
              />
              {!isLinked?
                <InputField 
                  labelText={t('translation:settings.nightwatch.password_title')}
                  inputType="password" 
                  customStyle={{marginBottom:PR(30)}}
                  changeText={setNightWatchPwd}
                  placeholderTxt={t('translation:settings.nightwatch.password_placeholder')}
                  testID="nightWatchPassword"
                  value={nightWatchPwd}
                />
                :
                <View></View>
              }
            </View>
            {nightwatchAccountRes && tempBtn ?
              <CommonBtn type="success" click={linkNightWatch} title="NightWatch linked!" testID="NightwatchLinkedBtn" txtColor="#0ACB1C" disable={true}/>
              : (nightwatchAccountRes && !tempBtn) || isLinked?
              <CommonBtn type="tertiary" click={unlinkNightWatch} title={t('translation:settings.nightwatch.unlink_button')} testID="unlinkNightwatchBtn"/>
              :
              <CommonBtn type="primary" click={linkNightWatch} title={t('translation:settings.nightwatch.link_button')} testID="linkNightwatchBtn"/>
            }
            <View style={{paddingTop:PR(80)}}></View>
            <View style={[styles.txtContainer, {marginBottom: PR(50)}]}>
              <Text renderAsHtml={true} style={styles.txt} imageWidth={PR(222.5)}>
                {t('translation:settings.nightwatch.more_info_text')}
              </Text>
            </View>
          </ScrollView>
        </View>
      </CommonKeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1,
  },
  titleContainer:{
    width: '90%',
    alignSelf: 'center'
  },
  txtContainer: {
    width: '90%',
    alignSelf: 'center',
    marginBottom: PR(20)
  },
  txt: {
    fontSize: PR(16)
  }
})

export default Nightwatch;