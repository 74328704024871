import * as types from '../actions';

export default function(state:any = {
  success: [],
  errors: []
}
, action:any = '' ) {
  if (action.type === types.ERROR && action?.message) {
    return {
      ...state, 
      errors: [...state.errors, action.message]
    };
  }
  else if (action.type === types.ERROR_RESET && action?.message) {
    return {
      ...state,
      errors: state.errors.filter(message => message !== action.message)
    };
  }
  if (action.type === types.SUCCESS && action?.message) {
    return {
      ...state,
      success: [...state.success, action.message]
    };
  }
  else if (action.type === types.SUCCESS_RESET && action?.message) {
    return {
      ...state,
      success: state.success.filter(message => message !== action.message)
    };
  }
  else {
    return state;
  }
}