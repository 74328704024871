import React, { useState } from "react";
import { RootStackParams } from "common/src/types";
import { StackScreenProps } from "@react-navigation/stack";
import CommonHeader from "common/src/components/CommonHeader";
import {
  Platform,
  View,
  StyleSheet,
  Linking,
  ScrollView
} from "react-native";
import GlobalStyles from "common/src/styles/GlobalStyles";
import Icon from "react-native-vector-icons/Feather";
import CommonTitle from "common/src/components/CommonTitle";
import CommonModal from "common/src/components/CommonModal";
import Text from "common/src/components/CommonCustomTxt";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import InputField from "common/src/components/InputField";
import CommonBtn from "common/src/components/CommonBtn";
import CommonDeleteEventModal from "common/src/components/CommonDeleteEvents/CommonDeleteEventModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvent, updateNightwatchEvent } from "../../store/events/eventActions";
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonKeyboardAvoidingView from "common/src/components/CommonKeyboardAvoidingView";

const NightwatchEvent = (
  props: StackScreenProps<RootStackParams, "NightwatchEvent">
) => {
  const {
    navigation,
    route: {
      params: { initialData },
    },
  } = props;
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();
  const patientData = useSelector((state:any) => state.getMe.patient);
  const [showDelete, setShowDelete] = useState(false);
  const [remark, setRemark] = useState(initialData.remark || "");
  const [scrollState, setScrollState] = useState();
  const [isWarningModalOpen, setWarningModalOpen] = useState<boolean>(false);
  const createdAt = initialData?.date
    ? DateTime.fromISO(initialData.date).setLocale(language)
    : DateTime.now().setLocale(language);
  const reportLink = t(
    "translation:settings.nightwatchReport.nightwatchReportLink",
    { recordingID: initialData.nightwatch_report_id }
  );
  const deleteEventAction = () => {
    setShowDelete(false);
    dispatch(
      deleteEvent(
        { 
          eventId: initialData.id,
          patientId: patientData.id,
          callback: () => {
            navigation.goBack();
          }
        },
      )
    );
  };

  const updateEventAction = () => {
    dispatch(updateNightwatchEvent({
      data: { report: { ...initialData, remark }},
      eventId: initialData.id,
      patientId: patientData.id,
      callback: () => {
        navigation.goBack();
      }
    }));
  }

  const externalLink = () => {
    Platform.OS === 'web' ? window.open(reportLink, '_blank') : Linking.openURL(reportLink)
    setWarningModalOpen(false)
  }

  return (
    <CommonKeyboardAvoidingView>
      {isWarningModalOpen && (
          <CommonModal
              type={'error'}
              title={t('translation:modal.external_link.title')}
              text={t('translation:modal.external_link.body', {link:reportLink})}
              onClose={() => setWarningModalOpen(false)}
              buttons={[
                {
                  type: 'tertiray',
                  title: t('translation:modal.external_link.back_btn'),
                  click: () => setWarningModalOpen(false)
                },
                {
                  type: 'primary',
                  title: t('translation:modal.external_link.follow_btn'),
                  click: () => externalLink()
                }
              ]}
          />
      )}
      {showDelete && (
        <CommonDeleteEventModal
          event={initialData}
          onClose={() => {
            setShowDelete(false);
          }}
          onConfirm={deleteEventAction}
          confirmationText={t(
            "translation:settings.nightwatchReport.deleteModalText"
          )}
        />
      )}
      <CommonHeader
        title={t("translation:settings.nightwatchReport.headerTitle")}
        leftClick={() => {
          Platform.OS === "web" ? window.history.back() : navigation.goBack();
        }}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        scroll={scrollState}
      />
      <ScrollView 
        testID="profile_scrollView"
        style={styles.scrollview}
        scrollEventThrottle={16}
        onScroll={(event: any) => {
          setScrollState(event.nativeEvent.contentOffset.y);
        }}
      >
      <View style={{height: Platform.OS === 'web' ? '80%' : '78%' }}>
        <View style={styles.IconRow}>
          <View style={styles.Icon}>
            <View style={styles.titleIcon}>
              <Icon name={"moon"} size={PR(40)} color={GlobalStyles.helpi.color} />
            </View>
          </View>
        </View>
        <View style={styles.titleWrapper}>
          <CommonTitle
            text={t("translation:settings.nightwatchReport.infoTitle")}
            color={GlobalStyles.helpi.color}
          />
          <View style={styles.reportContainer}>
            <View style={styles.descriptionContainer}>
              <Text style={styles.text}>
                {t("translation:settings.nightwatchReport.reportDescription", {
                  date: createdAt.toFormat("d MMMM"),
                  time: createdAt.toFormat("HH:mm"),
                })}
              </Text>
            </View>
            <View style={{ alignSelf: "flex-end" }}>
              <Text
                style={{
                  ...styles.text,
                  textDecorationLine: "underline",
                  color: GlobalStyles.helpi.color,
                }}
                onPress={() =>setWarningModalOpen(true)}
              >
                {t("translation:settings.nightwatchReport.openReportButton") +
                  " "}
                <Icon name="external-link" style={styles.text} />
              </Text>
            </View>
          </View>
            <InputField
              labelText={t(
                "translation:settings.nightwatchReport.remarksFieldLabel"
              )}
              inputType="string"
              customStyle={styles.remarksInput}
              changeText={(newValue) => {
                setRemark(newValue);
              }}
              placeholderTxt={t(
                "translation:settings.nightwatchReport.remarksFieldPlaceholder"
              )}
              testID="remarks"
              multiline={true}
              value={remark}
            />  
        </View>
      </View>
      <View style={styles.footer}>
              <CommonBtn
                type="tertiary"
                color="red"
                click={() => {
                  setShowDelete(true);
                }}
                title={t("translation:settings.nightwatchReport.deleteButton")}
              />
              <View style={{ height: PR(5) }}></View>
              <CommonBtn
                type="primary"
                click={updateEventAction}
                title={t("translation:settings.nightwatchReport.saveButton")}
              />
            </View>
      </ScrollView>
    </CommonKeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  descriptionContainer: {
    marginTop: PR(20),
    marginBottom: PR(10),
    padding: PR(20),
    backgroundColor: "#F2F4F5",
    borderRadius: PR(20),
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
  },
  footer: { justifyContent: "flex-end", width: '100%', alignSelf: 'center' },
  IconRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  Icon: {
    position: "relative",
  },
  remarksInput: { marginTop: PR(30), marginBottom: PR(30), width: "100%" },
  reportContainer: { width: "90%", alignSelf: 'center' },
  text: {
    textAlign:'justify',
    fontSize: PR(16),
    lineHeight: PR(26),
    fontFamily: GlobalStyles.global.fontFamily.Medium,
  },
  titleIcon: {
    alignSelf: "center",
    borderWidth: PR(2),
    borderRadius: PR(30),
    borderColor: GlobalStyles.global.lightGrey,
    backgroundColor: GlobalStyles.global.lightGrey,
    padding: PR(7),
  },
  titleWrapper: {
    width: '100%',
    alignSelf:'center',
    margin: PR(30),
  }
});

export default NightwatchEvent;
