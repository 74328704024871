import * as types from '../actions';

export default function(state:any = {
    all_set: false,
  }, action:any) {
  const response = action.response;
  switch(action.type) {
    case types.ALL_SET_SUCCESS:
      return { 
        ...state, 
        all_set: response
      }; 
    default:
      return state;
  }
};