import React, {useState, useEffect} from 'react';
import {StyleSheet, SafeAreaView, Platform, View, ScrollView} from 'react-native';
import NoVideos from '../components/EmptyVideos';
import Videos from '../components/ListOfVideos';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {useDispatch, useSelector} from 'react-redux';
import { getSeizureVideosActions } from '../store/actions/authenticationActions';
import {useTranslation} from "react-i18next";
import {loadEvent} from "../store/events/eventActions";
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const SeizureVideos = ({navigation}) => {
  const {i18n, t} = useTranslation();
  const lang = i18n.language
  const dispatch = useDispatch();
  const ResSeizureVideos = useSelector((state:any) => state.getSeizureVideos.response)
  const [scrollState, setScrollState] = useState();
  const patientData = useSelector((state:any) => state.getMe.patient);

  useEffect(() => {
    if(!ResSeizureVideos)
      dispatch(getSeizureVideosActions())
  },[ResSeizureVideos]);
  const setUpdateClick = (item:any) => {
    dispatch(loadEvent({
      patientId: patientData.id,
      eventId: item.id,
      callback: event => {
        navigation.navigate('AddEvent', {itemType: 'Seizure', initialData: event})
      }
    }));
  }
  const goBackBtn = () => {
    Platform.OS === 'web' ? window.history.back() : navigation.goBack()
  }
  return (
    <>
      <SafeAreaView style={styles.container}>
        <CommonHeader 
          leftIconName="arrow-left"
          leftIconColor={GlobalStyles.helpi.color}
          leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
          title={t('translation:settings.seizure_videos.title')}
          scroll={scrollState}
        />
        <View style={styles.scrollViewWrapper}>
          <ScrollView 
            style={styles.scrollview}
            scrollEventThrottle={16}
            onScroll={(event: any) => {
              setScrollState(event.nativeEvent.contentOffset.y);
            }}
          >
            {ResSeizureVideos !== undefined && ResSeizureVideos.result.length ? ResSeizureVideos.result.map((item, index) => {
                let dateType = new Date(item.event.date)
                let correctDateFormat = dateType.toLocaleString(lang, {month: 'long',day: 'numeric',year: 'numeric', hour: 'numeric', minute: '2-digit'});
                return <Videos key={index} listNames={[{id:item.event.id, title: item.event.seizure_type, date:correctDateFormat, data:item.event}]} clicked={setUpdateClick}/>
              })
              :
              <NoVideos />
            }
            <View style={{marginBottom:15}}></View>
          </ScrollView>
        </View>
        {ResSeizureVideos !== undefined && ResSeizureVideos.result.length === 0 &&
          <View style={styles.btnContainer}>
            <CommonBtn type="primary" title={t('translation:general.header.btn_back')} click={goBackBtn} testID="seizureBackID"/>
          </View>
        }
      </SafeAreaView>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: GlobalStyles.global.white,
    flex: 1,
  },
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
    paddingBottom: PR(30)
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
  },
  btnContainer: {
    alignSelf:'center', 
    position:'absolute', 
    bottom: PR(35), 
    width:'100%'
  }
})

export default SeizureVideos;