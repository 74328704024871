import React, { useEffect, useState } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParams, EducationModuleChapter, ProgramAction, ChapterTrack } from 'common/src/types';
import CommonEducationChapter from 'common/src/components/CommonEducationModules/CommonEducationChapter';
import { completeChapter, updateChapter, updateQuestionnaires } from '../../store/programs/patientProgramsActions';
import { useDispatch, useSelector } from 'react-redux';
import { activePatientProgramId, patientProgramQuestionnaires, patientProgramEducationTrack } from '../../store/programs/patientProgramsReducers';
import { addProgramAction } from '../../store/programs/programsActions';
import { isEmpty } from 'lodash';

type OwnProps = {
    navigation: StackNavigationProp<RootStackParams, "EducationChapter">,
    route: {
        params: {
            chapter: EducationModuleChapter,
            educationModuleId: string
        }
    }
}

const EducationChapter = (props: OwnProps) => {
    const dispatch = useDispatch();
    const { chapter, educationModuleId } = props.route.params;
    const patientProgramId = useSelector(activePatientProgramId);
    const previousQuestionnaireAnswers = useSelector(patientProgramQuestionnaires);
    const patientEducationTracks = useSelector(patientProgramEducationTrack);
    const [chapterTrack, setChapterTrack] = useState<ChapterTrack | undefined>();

    let chapterQuestionnaires = {};

    useEffect(()=>{
        let trackExists =  false;
        if (patientEducationTracks && educationModuleId in patientEducationTracks) {
            const educationTrack = patientEducationTracks[educationModuleId];
            if (educationTrack && educationTrack.chapters && chapter.id in educationTrack.chapters) {
                trackExists = true;
                setChapterTrack(educationTrack.chapters[chapter.id]);
            }
        }

        if (!trackExists) {
            dispatch(
                updateChapter({
                    lastPage: 0,
                    patientProgramId,
                    educationModuleId,
                    chapterId: chapter.id,
                })
            );
        }
        
    }, [patientEducationTracks]);

    const callUpdateQuestionnaires = () =>  {
        if (!isEmpty(chapterQuestionnaires)) {
            dispatch(updateQuestionnaires({ patientProgramId,  answers: chapterQuestionnaires }));
        }
    }

    const addChapterActionToTabActions = (chapterAction: any) => {
        dispatch(addProgramAction({
            patient_program_id: patientProgramId,
            actionId: chapterAction.id,
            action: chapterAction
        }))
    }

    return (<CommonEducationChapter
        chapter={chapter}
        exitChapterAction={() => {
            callUpdateQuestionnaires();
            props.navigation.goBack();
        }}
        updateChapterAction={(lastPage: number) => { 
            callUpdateQuestionnaires();
            if (!chapterTrack || (chapterTrack && chapterTrack.status !== 'completed')) {
                dispatch(
                    updateChapter(
                        {
                            lastPage,
                            patientProgramId,
                            educationModuleId,
                            chapterId: chapter.id,
                        }
                    )
                ); 
            }
            
        }}
        completeChapterAction={() => {
            callUpdateQuestionnaires();
            if (!chapterTrack || (chapterTrack && chapterTrack.status !== 'completed')) {
                dispatch(
                    completeChapter(
                        {
                            patientProgramId,
                            educationModuleId,
                            chapterId: chapter.id
                        }
                    )
                );
            }            
            props.navigation.goBack();
        }}
        updateQuestionnaires={(questionnaires) => {
            chapterQuestionnaires = {...questionnaires};
        }}
        previousQuestionnaireAnswers={previousQuestionnaireAnswers}
        handleChapterAction={addChapterActionToTabActions}
        startPageIndex={ chapterTrack ? chapterTrack.lastPage || 0 : 0 }
        navigation={props.navigation}
    />
    );
}

export default EducationChapter;
