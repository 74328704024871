import React, {useState, useEffect, useRef} from 'react';
import { StyleSheet, View } from 'react-native';
import InputField from '../InputField';
import {useTranslation} from 'react-i18next';
import CommonRadioForm from "./CommonRadioForm";
import CommonSelectForm from "./CommonSelectForm";
import CommonAppointmentChecklistBtn from '../CommonMyAppointments/CommonAppointmentChecklistBtn';
import Text from '../CommonCustomTxt';
import GlobalStyles from '../../styles/GlobalStyles';
import {useDispatch, useSelector} from "react-redux";
import {updatePatient} from "helpilepsy/src/store/user/userActions";
import { AppointmentChecklistTypes } from '../../types';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import CommonLabelForm from './CommonLabelForm';
interface OwnProps {
    AppointmentData: any;
    isSubmitButtonActive: boolean;
    initialData: any;
    checklistData: {checklist:AppointmentChecklistTypes}
    onChecklistBtnClick: () => void;
    onEditChecklistClick: (data:AppointmentChecklistTypes) => void;
    onDeleteChecklistClick: () => void;
    disabled: (data: boolean) => void;
    isFormUpdated: (data: boolean) => void;
}


const CommonAppointment = ({AppointmentData, isSubmitButtonActive, initialData, checklistData, onChecklistBtnClick, onEditChecklistClick, onDeleteChecklistClick, disabled, isFormUpdated}: OwnProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const patientData = useSelector((state:any) => state.getMe.patient);
    const [physician, setPhysician] = useState<string>(initialData?.doctor_info?.name ? initialData?.doctor_info?.name : patientData.neurologist_name ? patientData.neurologist_name : "");
    const [doctorType, setDoctorType] = useState<string>(initialData?.doctor_info?.type || 'neurologist');
    const [appointmentType, setAppointmentType] = useState<Array<string>>(initialData?.consultation || []);
    const [location, setLocation] = useState<string>(initialData?.place);
    const [remarks, setRemarks] = useState<string>(initialData?.remark);
    const [customDoctorInputs, setCustomDoctorInputs] = useState<Array<string>>(patientData?.lists?.doctor_info_inputs?.map(i => i.type) || []);
    const checklist = checklistData?.checklist ? checklistData.checklist : initialData?.checklist ? initialData.checklist : undefined
    const prevAmount = useRef({ checklist }).current;

    useEffect(() => {
        if (prevAmount.checklist !== checklist)
            isFormUpdated(true)
    }, [checklist])

    useEffect(() => {
        if(isSubmitButtonActive)
          sendDataBack()
      },[isSubmitButtonActive])

    useEffect(() => {
        if(!checkDisable())
            disabled(false)
        else    
            disabled(true)
    }, [physician])

    const sendDataBack = () => {
        if (customDoctorInputs?.length) {
            const newDoctorInputs: Array<any> = [];
            const oldDoctorInputs = patientData?.lists?.doctor_info_inputs || [];
            for (const doctor of customDoctorInputs) {
                if (!oldDoctorInputs.find(i => i.type === doctor)) { newDoctorInputs.push({ type: doctor, name: physician }) }
            }
            if (newDoctorInputs.length) {
                dispatch(updatePatient({
                    data: {
                        lists: {
                            ...patientData?.lists,
                            doctor_info_inputs: [...oldDoctorInputs, ...newDoctorInputs]
                        }
                    },
                    patientId: patientData.id,
                }))
            }
        }

        let obj = {
            type:'appointment',
            physician,
            doctorType,
            appointmentType,
            location,
            remarks,
            checklist: checklist,
            msg: t('translation:events.appointment.success')
        }
        AppointmentData(obj)
    }

    const checklistEdit = () => {
        onEditChecklistClick(checklist)
    }

    // return a boolean value in order to turn the button to disable or enable
    const checkDisable = () => {return (physician === "" )}
    return (
        <>
            <CommonRadioForm
                value={doctorType}
                setValue={(dt) => {setDoctorType(dt); isFormUpdated(true)}}
                cmsListLink="list.doctor_types"
                label={t('translation:events.appointment.doctor_type')}
                patientListData={customDoctorInputs}
                handleCreateOther={(item) => {setCustomDoctorInputs([...customDoctorInputs, item])}}
                handleDeleteOther={(item) => {
                    const newDoctors = customDoctorInputs.filter(i => i.type !== item)
                    setCustomDoctorInputs([...newDoctors])
                    dispatch(updatePatient({
                        data: {
                            lists: {
                                ...patientData?.lists,
                                doctor_info_inputs: newDoctors
                            }
                        },
                        patientId: patientData.id,
                    }))
                }}
                isOptional={false}
            />
            <InputField
                labelText={t('translation:events.appointment.doctor')}
                inputType="string"
                customStyle={{marginBottom:PR(30)}}
                changeText={(d) => {setPhysician(d); isFormUpdated(true)}}
                placeholderTxt={t('translation:events.appointment.doctor_placeholder')}
                testID="physician"
                value={physician}
                isOptional={false}
            />
            <CommonSelectForm
                values={appointmentType}
                setValues={(d) => {setAppointmentType(d); isFormUpdated(true)}}
                cmsListLink="list.appointment_types"
                patientList="consultation_inputs"
                label={t('translation:events.appointment.appointment_type')}
                isOptional={true}
            />
            <InputField
                labelText={t('translation:events.appointment.location')}
                inputType="string"
                customStyle={{marginBottom:PR(30)}}
                changeText={(d) => {setLocation(d); isFormUpdated(true)}}
                placeholderTxt={t('translation:events.appointment.location_placeholder')}
                testID="remarks"
                value={location}
                isOptional={true}
            />
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
                <CommonLabelForm label={t('translation:general.page_title.epilepsy_appointment_checklist')} isOptional={true}/>
                <CommonAppointmentChecklistBtn 
                    date={checklist?.checklistDate ? checklist.checklistDate : undefined}
                    btnText={t('translation:events.add.appointment.checklist_text')}
                    onChecklistBtnClick={onChecklistBtnClick}
                    onEditChecklistClick={checklistEdit}
                    onDeleteChecklistClick={onDeleteChecklistClick}
                    checklistText={t('translation:events.last_updated_text')}
                />
            </View>
            <InputField
                labelText={t('translation:general.events.remark')}
                inputType="string"
                customStyle={{marginBottom:PR(30)}}
                changeText={(d) => {setRemarks(d); isFormUpdated(true)}}
                placeholderTxt={t('translation:general.events.remark_placeholder')}
                testID="remarks"
                multiline={true}
                value={remarks}
                isOptional={true}
            />
        </>
    )

}

const styles = StyleSheet.create({
    label: {
        marginBottom: PR(10),
        marginLeft: PR(20),
        color: GlobalStyles.global.black,
        fontSize: PR(12)
    },
})

export default CommonAppointment;