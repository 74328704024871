import React from 'react';
import {TouchableOpacity, StyleSheet, View, Platform} from 'react-native';
import GlobalStyle from '../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import { CommonButtonType } from '../types';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

type IProps = {
  type: CommonButtonType,
  title: string,
  click: any,
  size?: string,
  txtColor?: string,
  color?: string,
  disable?: boolean,
  testID?: string
}

const CommonBtn = ({type, title, click, size, txtColor, color, disable, testID}:IProps) => {

  type = type || 'primary'
  size = size || 'medium'
  txtColor = txtColor || undefined
  const styles: any = generateStyleSheet(type, disable || false, txtColor, size, color);

  return (
    <>
      <TouchableOpacity testID={testID} onPress={click} style={[styles.btn, styles[type]]} disabled={disable}>
        {type === 'success' ?
        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <Icon name="check-circle" size={PR(22)} color="#0ACB1C"/>
          <View>
            <Text renderAsHtml={true} style={styles.txt}>{title}</Text>
          </View>
          <View style={{width:PR(22)}}>
          </View>
        </View>
        :
        <Text renderAsHtml={true} style={styles.txt}>{title}</Text>
        }
        
      </TouchableOpacity>
    </>
  );
}

const generateStyleSheet = (type: string, disable: boolean, txtColor: string | undefined, size: string, color?: string) => {
  return StyleSheet.create({
    btn: {
      width: '80%',
      alignSelf: 'center',
      alignItems: 'center',
      borderRadius: PR(26),
      padding: type === 'tertiary' ? PR(5) : size === 'small' ? PR(4) : type === 'primary' ? PR(14) : PR(12),
      opacity: disable && Platform.OS === 'android' ? 0.4 : disable && Platform.OS !== 'android' ? 0.2 : 1
    },
    primary: {
      backgroundColor: color || GlobalStyle[APP_SHORTCUT_NAME].btnColor,
      borderColor: color || GlobalStyle[APP_SHORTCUT_NAME].btnColor,
      borderWidth: PR(2),
    },
    secondary: {
      backgroundColor: GlobalStyle.global.white,
      borderColor: color || GlobalStyle[APP_SHORTCUT_NAME].btnColor,
      borderWidth: PR(2),
    },
    tertiary:{
      backgroundColor: "#FFFFFF00",
      borderWidth: 0
    },
    success: {
      backgroundColor: GlobalStyle.global.white,
      borderColor: "#0ACB1C",
      borderWidth: PR(2),
    },
    txt: {
      color: txtColor !== undefined ? txtColor : type === 'primary' ? GlobalStyle.global.white : color || GlobalStyle[APP_SHORTCUT_NAME].btnColor,
      fontSize: size === 'small' ? PR(12) : type === 'tertiary' ? PR(16) : PR(18),
      textAlign: 'center',
      letterSpacing: 0,
      fontFamily: GlobalStyle.global.fontFamily.Medium,
      textDecorationLine: type === 'tertiary' ? 'underline': undefined
    }
  });
}


export default CommonBtn;