import React, {useState, useEffect} from 'react';
import {StyleSheet, ScrollView, Platform, View} from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonSubTitle from 'common/src/components/CommonSubtitle';
import CommonModal from 'common/src/components/CommonModal';
import InputField from 'common/src/components/InputField';
import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonFooter from 'common/src/components/CommonFooter';
import CommonBtn from 'common/src/components/CommonBtn';
import Text from 'common/src/components/CommonCustomTxt';
import { seizureAntidoteCard } from '../../../store/epilepsyDetails/epilepsyDetailsReducers';
import { getSeizureAntidoteCard, addUpdateSeizureAntidoteCard, deleteDescriptionCards } from '../../../store/epilepsyDetails/epilepsyDetailsActions';
import { isEmpty } from 'lodash';
import { RootStackParams } from 'common/src/types';
import { StackNavigationProp } from '@react-navigation/stack';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

type OwnProps = {
    navigation: StackNavigationProp<RootStackParams, "Settings">
}

const MySeizureAntidote = ({navigation}:OwnProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const seizureAntidoteCardReducer = useSelector(seizureAntidoteCard)
    const [scrollState, setScrollState] = useState();
    const [seizureAntidote, setSeizureAntidote] = useState<string>('');
    const [warningModal, setWarningModal] = useState<boolean>(false);

    useEffect(() => {
        if(isEmpty(seizureAntidoteCardReducer))
            dispatch(getSeizureAntidoteCard());
        else{
            setSeizureAntidote(seizureAntidoteCardReducer?.description || '')
        }
    },[seizureAntidoteCardReducer])

    const saveSeizureAntidote = () => {

        dispatch(addUpdateSeizureAntidoteCard({
            description: seizureAntidote,
            successMsg: t('translation:settings.seizuresDescription.success.message'),
            callback: () => {
                navigation.navigate('Settings')
            }
        }))
    }

    const deleteSeizureAntidote = () => {
        setWarningModal(false);
        dispatch(deleteDescriptionCards({
            cardAction: 'ADD_UPDATE_SEIZURES_ANTIDOTE_CARD_SUCCESS',
            cardName: 'seizureAntidote',
            cardUrl: 'addupdateseizureantidote',
            successMsg: t('translation:settings.epilepsy_details.deleted_successfully.message'),
            callback: () => {
                navigation.navigate('Settings');
            }
        }))
    }

    // keep tracking of the changes of the warningVisible
    const callbackWarningVisible = () => {
        setWarningModal(false);
    }

    const warningModalBtn = [
        {
          type: 'tertiary',
          title: t('translation:modal.btn.cancel'),
          click: callbackWarningVisible
        },
        {
          type: 'primary',
          title: t('translation:modal.btn.confirm'),
          click: deleteSeizureAntidote
        }
      ]

    const isDisabled = () => {return ( seizureAntidote.length === 0)}

    return (
        <CommonKeyboardAvoidingView>
            { warningModal &&
                <CommonModal 
                    testID="warningModalID" 
                    type={'info'} 
                    title={t('translation:modal.titles.warning')} 
                    text={t('translation:modal.epilepsy_details_delete_text')} 
                    buttons={warningModalBtn} 
                    onClose={callbackWarningVisible}
                />
            }
            <CommonHeader
                title={t('translation:general.page_title.epilepsy_seizures_antidote')} 
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {navigation.goBack()}}
                testIDTitle="epilepsy_seizures_antidote_title"
                scroll={scrollState}
            />
            <ScrollView 
                testID="antidote_scrollView"
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                    setScrollState(event.nativeEvent.contentOffset.y);
                }}
            >
                <View style={{width:'90%', alignSelf:'center'}}>
                    <CommonSubTitle 
                        text={t('translation:general.page_subtitle.epilepsy_seizures_antidote')}
                        txtAlign='center'
                    />
                </View>
                <View testID='indroduction-section' style={{marginTop: PR(32), width: '90%', alignSelf:'center'}}>
                    <Text style={styles.label}>{t('translation:general.seizure_antidote_introduction_title')}</Text>
                    <Text style={styles.introductionTxt}>{t('translation:general.seizure_antidote_introduction_text')}</Text>
                </View>
                <InputField 
                    labelText={t('translation:general.antidote_of_my_seizures_label')}
                    inputType="string" 
                    customStyle={{marginTop:PR(37), marginBottom:PR(30)}}
                    changeText={setSeizureAntidote}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="antidote_of_my_seizures"
                    multiline={true}
                    value={seizureAntidote}
                />
            </ScrollView>
            <CommonFooter>
                {seizureAntidoteCardReducer !== null &&
                    <>
                        <CommonBtn type="tertiary" txtColor="red" title={t("translation:settings.epilepsy_details.delete_description.btn")} testID="delete_seizure_antidote" click={()=> setWarningModal(true)} /> 
                        <View style={{marginBottom: PR(5)}}></View>
                    </>
                }
                <CommonBtn type="primary" click={() => saveSeizureAntidote()} title={t('translation:general.header.btn_save')} testID="save_seizure_antidote" disable={isDisabled()}/>
            </CommonFooter>
        </CommonKeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    label: { 
        marginBottom: PR(10),
        marginLeft: PR(20),
        color: GlobalStyles.global.black,
        fontSize: PR(12)
    },
    introductionTxt: {
        color: GlobalStyles.global.black,
        fontSize: PR(12),
        lineHeight: PR(18),
        fontFamily: GlobalStyles.global.fontFamily.RegularItalic
    }
});

export default MySeizureAntidote;