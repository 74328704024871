export enum NightwatchActions {
    GET_NIGHTWATCH_ACCOUNT = "GET_NIGHWATCH_ACCOUNT",
    GET_NIGHTWATCH_ACCOUNT_SUCCESS = "GET_NIGHTWATCH_ACCOUNT_SUCCESS",
    LOGIN_TO_NIGHTWATCH = 'LOGIN_TO_NIGHTWATCH',
    LOGOUT_FROM_NIGHTWATCH = 'LOGOUT_FROM_NIGHTWATCH',
    LOGOUT_FROM_NIGHTWATCH_SUCCESS = 'LOGOUT_FROM_NIGHTWATCH_SUCCESS',
}

export interface GetNightwatchAccountInterface {
    patientId: string;
    callback: () => void;
    errorCallback: () => void;
}

export interface LoginToNightwatchInterface {
    credentials: {
        login: string,
        password: string
    },
    t: any,
    callback: () => void;
    errorCallback: () => void;
}

export interface LogoutFromNightwatchInterface {
    msg: string,
    callback: () => void;
    errorCallback: () => void;
}

export const getNightwatchAccountAction = (payload: GetNightwatchAccountInterface) => {
    return {
        type: NightwatchActions.GET_NIGHTWATCH_ACCOUNT,
        ...payload
    };
};

export const loginToNightWatchAction = (payload: LoginToNightwatchInterface) => {
    return {
        type: NightwatchActions.LOGIN_TO_NIGHTWATCH,
        ...payload
    }
}

  
  export const logoutFromNightWatchAction = (payload: LogoutFromNightwatchInterface) => ({
        type: NightwatchActions.LOGOUT_FROM_NIGHTWATCH,
        ...payload
    }
  );