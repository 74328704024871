import { StyleSheet } from "react-native";
import GlobalStyles from "../../../styles/GlobalStyles";
import { APP_SHORTCUT_NAME } from '../../../env.json';

const style = StyleSheet.create({
  pinView: {
    alignItems: "center",
  },
  inputContainer: {
    flexDirection: "row",
  },
  inputView: {
    margin: 6,
    backgroundColor: "#a3a7b9",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: GlobalStyles[APP_SHORTCUT_NAME].btnColor
  },
  inputText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
})
export default style