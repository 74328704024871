import React from 'react';
import { View, StyleSheet, Dimensions, SafeAreaView } from 'react-native';
import Text from 'common/src/components/CommonCustomTxt';
import CommonImage from 'common/src/components/CommonImage';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { widthPercentageToDP as PR } from 'common/src//styles/PixelRatio';
import { useTranslation } from 'react-i18next';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

type OwnProps = {
  netConnected: boolean | null
}

const Loading = ({ netConnected }: OwnProps) => {
  const { t } = useTranslation()
  return (
    <SafeAreaView style={styles.container}>
      {netConnected === false &&
        <View style={styles.formsErrorBanner}>
          <Text renderAsHtml={true} style={{ color: GlobalStyles.global.white }}>{t('translation:widget.no_internet.message')}</Text>
        </View>
      }
      <View style={styles.modal}>
        <CommonImage name={'loadingSpinnerHelpi'} height={height * 0.17} width={width * 0.17} />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99,
    elevation: 5,
    flex: 1
  },
  modal: {
    width: '100%',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  formsErrorBanner: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: GlobalStyles.global.redError,
    paddingVertical: PR(6),
    width: '100%'
  }
});

export default Loading;

