import React, { useEffect, useRef, useState } from "react";
import {
  EducationModulePage,
  EducationModuleChapter,
} from "../../types";
import { ScrollView, View, Platform } from "react-native";
import CommonStyle from "./CommonStyle";
import { useTranslation } from "react-i18next";
import CommonHeader from "../CommonHeader";
import GlobalStyles from "../../styles/GlobalStyles";
import CommonChapterNavigation from "./components/CommonChapterNavigation";
import CommonChapterPage from "./components/CommonChapterPage";
import { widthPercentageToDP as PR } from "common/src/styles/PixelRatio";
import {useKeyboard} from '../../services/useKeyboard';
import CommonKeyboardAvoidingView from "../CommonKeyboardAvoidingView";

interface OwnProps {
  chapter: EducationModuleChapter;
  exitChapterAction: () => void;
  updateChapterAction: (lastPage: number) => void;
  completeChapterAction: () => void;
  updateQuestionnaires: (questionnaires: Record<string, object>) => void;
  previousQuestionnaireAnswers: Record<string, object> | null;
  handleChapterAction: (chapterActionID:string) => void;
  startPageIndex: number;
  navigation: any;
}


const CommonEducationChapter = ({ chapter, exitChapterAction, updateChapterAction, completeChapterAction, updateQuestionnaires, previousQuestionnaireAnswers, handleChapterAction, startPageIndex, navigation }: OwnProps) => {
  const { pages } = chapter;
  const sortedPages =
    pages && pages.length > 0
      ? [...pages].sort((current, next) => current.index - next.index)
      : [];
  const { i18n } = useTranslation();
  const { language: userLanguage } = i18n;
  const [currentPage, setCurrentPage] = useState<EducationModulePage|undefined>(undefined);
  const [currentPageIndex, setCurrentPageIndex] = useState(startPageIndex);
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState(previousQuestionnaireAnswers);
  const scrollRef = useRef<ScrollView>(null);
  const isKeyBoardOpen = useKeyboard();

  useEffect(() => {
    if (sortedPages.length > currentPageIndex) {
      setCurrentPage(sortedPages[currentPageIndex]);
    }
  }, [sortedPages, currentPageIndex]);

  useEffect(() => {
    setCurrentPageIndex(startPageIndex);
  }, [startPageIndex]);

  const updateQuestionnaire = (questionnaireId: string, questionIndex: string, answer: any) => {
    const currentQuestionnaireAnswers = questionnaireAnswers && questionnaireId in questionnaireAnswers ? questionnaireAnswers[questionnaireId] : {};
    
    const updatedAnswers = {
      ...questionnaireAnswers,
      [questionnaireId]: {
        ...currentQuestionnaireAnswers,
        [questionIndex]: answer,
      }
    };
    setQuestionnaireAnswers(updatedAnswers);
    updateQuestionnaires(updatedAnswers);
  };

  const updateSingleQuestion = (id: string, answer: any) => {
    updateQuestionnaire(chapter.id, id, answer)
  }

  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader
        title={chapter.title[userLanguage] || chapter.title.en}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={exitChapterAction}
      />
      {currentPage && (
        <ScrollView contentContainerStyle={CommonStyle.pageContainer} ref={scrollRef} showsVerticalScrollIndicator={false}>
          <View
            testID={`currentpage-index-${currentPage.index}`}
          >
            <CommonChapterPage
              page={currentPage}
              language={userLanguage}
              updateQuestionnaire={updateQuestionnaire}
              questionnaireAnswers={questionnaireAnswers}
              handleChapterAction={handleChapterAction}
              updateSingleQuestion={updateSingleQuestion}
              chapterId={chapter.id}
              navigation={navigation}
              updateChapterAction={updateChapterAction}
              currentPageIndex={currentPageIndex}
            />
          </View>
          {pages && pages.length > 0 && (
            
              <CommonChapterNavigation
                clickNext={() => {
                  if (currentPageIndex + 1 < sortedPages.length) {
                    const index = currentPageIndex;
                    setCurrentPageIndex(index + 1);
                    updateChapterAction(index + 1);
                    scrollRef.current?.scrollTo({ y:0, animated: false });
                  }
                }}
                clickPrevious={() => {
                  if (currentPageIndex > 0) {
                    const index = currentPageIndex;
                    setCurrentPageIndex(index - 1);
                    updateChapterAction(index - 1);
                    scrollRef.current?.scrollTo({ y:0, animated: false });
                  }
                }}
                clickComplete={completeChapterAction}
                currentPage={currentPageIndex + 1}
                totalPages={sortedPages.length}
              />
            
          )}
          {Platform.OS=== 'android' && isKeyBoardOpen &&
            <View style={{marginBottom:PR(28)}}></View>
          }
        </ScrollView>
      )}
    </CommonKeyboardAvoidingView>
  );
};

export default CommonEducationChapter;
