export const getListCMSData = (t, list) => {
    if (!list) return [];
    try {
        // @ts-ignore
        return t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true})?.map((name,i) => {
            return {
                label:t('translation:'+list+'.'+i+'.'+Object.keys(name)),
                value: Object.keys(name)[0],
                position: i
            }

        })
    } catch (e) {
        return [];
    }
};


export const getCaptionInLanguage = (
    language: string,
    captions?: Record<string, string>
  ) => {
    let caption = "";
    if (captions) {
      caption = language in captions ? captions[language] : captions["en"];
    }
    return caption;
};
