import React from 'react';
import { StyleSheet, View, Platform, ScrollView, SafeAreaView } from 'react-native';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import Text from 'common/src/components/CommonCustomTxt';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonImage from 'common/src/components/CommonImage';
import CommonHeader from './CommonHeader';
import CommonFooter from './CommonFooter';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from "@react-navigation/stack";


const CommonProgramIntroduction = (props) => {
    const {t, i18n} = useTranslation();
    const {language: userLanguage} = i18n;
    const {program, updateMyPatientProgram} = props.route.params;
    const navigation = useNavigation<StackNavigationProp<any>>();
    const ProgramsLibrary = useSelector((state) => state.programsReducers.programs)
    const PatientPrograms = useSelector((state) => state.programsReducers.patientPrograms)

    const goToProgram = async () => {
        let introductionDone = true
        updateMyPatientProgram({id: program.patientProgramId, introductionDone})
        if (program.hasSubprograms && program.subprograms && program.subprograms.length > 0){
            navigation.pop()
            navigation.navigate('QoLForm', {program})
        }
        else if (PatientPrograms?.length === 1 && ProgramsLibrary?.length === 0) {
            navigation.pop()
            navigation.navigate('Programs', { unqiueProgram: program });
        }
        else{
            navigation.pop()
            navigation.navigate('ProgramOverview',{program}) //To main program page
        }
        
    }

    return (
        <SafeAreaView style={styles.container}>
            <CommonHeader
                testIDTitle="program_introduction_title"
                title={program?.title[userLanguage] || program?.title.en}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
            />
                <ScrollView>
                    <View testID='icon_id' style={styles.imageContainer}>
                        <CommonImage name="programsIconFilled" width={PR(60)} height={PR(60)} />
                    </View>
                    <View testID='description_id' style={styles.textContainer}>
                        <Text style={styles.subtitleTxt}>{program?.subtitle[userLanguage] || program?.subtitle.en}</Text>
                    </View>
                    <View style={styles.textContainer}>
                        <Text style={styles.introductionTxt} renderAsHtml={true} testID='itroduction_id'>{program?.introduction[userLanguage] || program?.introduction.en}</Text>
                    </View>
                </ScrollView>
                <CommonFooter>
                    <CommonBtn 
                        type="primary" 
                        title={t('translation:register.tutorial.last_title')} 
                        click={goToProgram}
                        testID="start_btn_id"
                    />
                    <View style={{marginBottom:PR(10)}}></View>
                </CommonFooter>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: GlobalStyles.global.white,
    },
    imageContainer: {
        borderWidth: 1,
        borderRadius: 80,
        borderColor: GlobalStyles.global.white,
        backgroundColor: GlobalStyles.global.lightGrey,
        width: PR(125),
        height: PR(125),
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: PR(22)
    },
    textContainer: {
        padding: PR(10),
        width: '90%',
        alignSelf:'center'
    },
    subtitleTxt: {
        color:GlobalStyles.global.black,
        fontSize: PR(16),
        fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    introductionTxt: {
        color:GlobalStyles.global.black,
        fontSize: PR(16),
        fontFamily: GlobalStyles.global.fontFamily.Regular
    }
})

export default CommonProgramIntroduction;