import React from "react";
import { View, TouchableOpacity } from "react-native";
import Text from "../../CommonCustomTxt";
import { DateTime, Duration } from "luxon";
import { widthPercentageToDP as PR } from "../../../styles/PixelRatio";
import { getLocalTime } from '../../../helpers/event';
interface OwnProps {
  handlePress: (sItem: any) => void;
  sItem: any;
  styles: any;
  itemNames: string[];
  userTimezone: string;
}

const formatDiff = (diff: Duration): string => {
  const { hours, minutes } = diff;

  if (hours === 0 && minutes === 0) {
    return "";
  }

  const sign = hours < 0 || minutes < 0 ? "-" : "+";
  const paddedHours = Math.abs(hours).toString().padStart(2, "0");
  const paddedMinutes = Math.abs(minutes).toString().padStart(2, "0");
  return `(${sign}${paddedHours}:${paddedMinutes})`;
};

const isDateISOUTC = (dateString: string): boolean => {
  const date = new Date(dateString);
  return (
    date.toUTCString() !== "Invalid Date" && date.toISOString() === dateString
  );
};

const getIntakeTimeInLocalTime = (
  takenDate: string,
  userTimezone: string
): DateTime => {
  const takenConverted = DateTime.fromISO(takenDate);
  if (isDateISOUTC(takenDate)) {
    return takenConverted;
  }
  const { year, month, day, hour, minute } = takenConverted.toObject();
  const takenInUserTz = DateTime.now()
    .setZone(userTimezone)
    .set({ year, month, day, hour, minute });
  const takenInLocal = DateTime.fromISO(takenInUserTz.toISO()).setZone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  return takenInLocal;
};

const MedicationEvent = ({
  handlePress,
  itemNames,
  sItem,
  styles,
  userTimezone,
}: OwnProps) => {
  const hourDisplay = getLocalTime(
    sItem[itemNames[1]].time,
    userTimezone
  ).toFormat("HH:mm");
  const [hour, minutes] = sItem[itemNames[1]].time.split(":");
  const date = DateTime.fromISO(sItem.date)
    .setZone(userTimezone)
    .set({ hour: parseInt(hour), minute: parseInt(minutes) });
  const takenDate =
    sItem[itemNames[2]] !== null
      ? getIntakeTimeInLocalTime(sItem[itemNames[2]], userTimezone)
      : null;

  return (
    <TouchableOpacity
      style={{ width: "100%", marginVertical: PR(4) }}
      onPress={() => {
        handlePress(sItem);
      }}
    >
      <View style={{ flexDirection: "row", width: "100%" }}>
        <Text style={styles.eventSubtitle}>{hourDisplay}</Text>
        {takenDate !== null && (
          <>
            <Text style={styles.eventSubtitle}> - </Text>
            <Text style={styles.eventSubtitle}>
              {takenDate.toFormat("HH:mm")}
            </Text>
            <Text style={styles.eventSubtitle}>
              {formatDiff(
                takenDate.diff(date, ["hours", "minutes", "milliseconds"])
              )}
            </Text>
          </>
        )}
        <Text style={styles.eventSubtitle}> - </Text>
        <Text numberOfLines={1} style={[styles.eventSubtitle, { flex: 1 }]}>
          {sItem[itemNames[1]].name} - {sItem[itemNames[1]].dose}{" "}
          {sItem[itemNames[1]].unit}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default MedicationEvent;
