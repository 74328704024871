import React, {useState} from 'react';
import {View, StyleSheet, ImageBackground, Dimensions, Platform} from 'react-native';
import CommonImage from './CommonImage';
import GlobalStyle from '../styles/GlobalStyles'
import Images from '../services/imageServices';
import Text from './CommonCustomTxt';
import { useTranslation } from 'react-i18next';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import NetInfo from "@react-native-community/netinfo";

const fullWidth = Dimensions.get('window').width;

type IProps = {
  title: string,
  subtitle: string,
  logo: string,
  testID: string
}

const WelcomeHeader = ({title, subtitle, logo, testID}:IProps) => {
  const {t} = useTranslation()
  const [connected, setConnected] = useState<boolean | null | string>(true)

  NetInfo.addEventListener(state => {
    if(connected !== state.isConnected)
      setConnected(state.isConnected)
  });
  
  return (
    <>
      {
        !connected &&
          <View style={styles.formsErrorBanner}>
            <Text style={{color: GlobalStyle.global.white}}>{t('translation:widget.no_internet.message')}</Text>
          </View>
      }
      <View style={styles.imageContainer} testID={testID}>
        <ImageBackground source={Images.loginBackgroundImage.mobile} style={styles.image}>
          <View style={styles.imageTxt}>
            <View style={styles.titleElementsRow}>
              {
                logo === 'migraine' ? 
                  <CommonImage name={'migraineIcon'} height={PR(80)} width={PR(80)}/>
                    :
                  <CommonImage name={'helpilepsyIcon'} height={PR(80)} width={PR(80)}/>
              }
              <View>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.subtitle}>{subtitle}</Text>
              </View>
            </View>
          </View>
        </ImageBackground>
    </View>
    </>
  );
}

const styles = StyleSheet.create({
  imageContainer: {
    width: fullWidth,
    height: '30%'
  },
  image: {
    flex: 1,
    width: '100%',
  },
  imageTxt: {
    flex: 1,
    alignSelf:'center',
    justifyContent: 'center'
  },
  titleElementsRow: {
    flexDirection: 'row'
  },
  title: {
    flexDirection: 'column',
    fontSize: Platform.OS === 'web' ? PR(30): fullWidth * 0.08,
    fontFamily: GlobalStyle.global.fontFamily.Bold,
    marginTop: Platform.OS === 'web' ? PR(25) : PR(10),
    marginBottom: PR(10),
    color: Platform.OS === 'web' ? GlobalStyle.global.black : GlobalStyle.global.white
  },
  subtitle: {
    flexDirection: 'column',
    fontSize: Platform.OS === 'web' ? PR(12): fullWidth * 0.03,
    fontFamily: GlobalStyle.global.fontFamily.Bold,
    color: Platform.OS === 'web' ? GlobalStyle.global.black : GlobalStyle.global.white
  },
  formsErrorBanner: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: GlobalStyle.global.redError,
    paddingVertical: PR(6),
    width: '100%'
  }
});

export default WelcomeHeader;