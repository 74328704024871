import React, {useState, useEffect} from 'react';
import {Platform, Pressable, View} from "react-native";
import Text from "./CommonCustomTxt";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { DateTime } from "luxon";
import {useTranslation} from 'react-i18next';
import {convertLangCode} from 'common/src/services/translationList';
import { compareDates } from '../services/compare';

const CommonTimeInput = ({ value, onChange, fieldStyle, textStyle, webInputStyle, maxTime, minTime, inputDate }:any) => {
    const {i18n} = useTranslation();
    const lang = convertLangCode(i18n.language)
    const [showTimeInputModal, setShowTimeInputModal] = useState(false);
    const [updateMaxTime, setUpdateMaxTime] = useState(maxTime)
    const [updateMinTime, setUpdateMinTime] = useState(minTime)
    let maxTimeValue = DateTime.fromJSDate(maxTime).toFormat('HH:mm')
    let currentDate = DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy')

    useEffect(() => {
        if(inputDate){
            if(compareDates(inputDate, currentDate, 'dd-MM-yyyy')){
                setUpdateMaxTime(maxTime)
            }
            if(compareDates(currentDate, inputDate, 'dd-MM-yyyy')){
                setUpdateMinTime(minTime)
            }
            else{
                setUpdateMaxTime(undefined)
                setUpdateMinTime(undefined)
            }
                
        }
    },[inputDate])
    
    return (
        <>
            <DateTimePickerModal
                date={value ? DateTime.fromFormat(value, 'HH:mm').toJSDate(): new Date()}
                isVisible={showTimeInputModal}
                mode="time"
                display="spinner"
                // @ts-ignore
                maximumDate={updateMaxTime ? maxTime : undefined}
                minimumDate={updateMinTime ? minTime : undefined}
                onConfirm={(d) => {
                    let confirmDate = DateTime.fromISO(d.toISOString()).toFormat('HH:mm')
                    setShowTimeInputModal(false);
                    onChange(updateMaxTime && (confirmDate > maxTimeValue) ? maxTimeValue : confirmDate);
                }}
                onCancel={() => {
                    setShowTimeInputModal(false);
                }}
                is24Hour={true}
                locale={lang}
            />
            {Platform.OS === 'web' ? (
                <View style={fieldStyle} >
                    <Text style={textStyle}>
                        <input
                            style={webInputStyle}
                            type="time" 
                            defaultValue={value && DateTime.fromFormat(value, 'HH:mm').toFormat('HH:mm')}
                            value={value}
                            max={maxTimeValue}
                            onChange={e => {
                                if(e.target.value <= maxTimeValue) {
                                    onChange(e.target.value);
                                }
                            }}
                        />
                    </Text>
                </View>
            ) : (
                <Pressable onPress={() => setShowTimeInputModal(true)}>
                    <View style={fieldStyle} >
                        <Text style={textStyle}>{value}</Text>
                    </View>
                </Pressable>
            )}
        </>
    );
}


export default CommonTimeInput;