import React, {useState} from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import GlobalStyles from '../../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import Text from '../CommonCustomTxt';
import CommonModal from '../CommonModal';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import moment from 'moment';
import { APP_SHORTCUT_NAME } from '../../env.json';
import {useTranslation} from 'react-i18next';

interface OwnProps {
    btnText: string,
    date?: string,
    checklistText?: string,
    onChecklistBtnClick: () => void;
    onEditChecklistClick: () => void;
    onDeleteChecklistClick: () => void;
}

const CommonAppointmentChecklistBtn = ({btnText, date, checklistText, onChecklistBtnClick, onEditChecklistClick, onDeleteChecklistClick}:OwnProps) => {
    const {t} = useTranslation();
    const [deleteModal, setDeleteModal] = useState<boolean>(false)

    // keep tracking of the changes of the action warning modal
    const callbackDeleteModalVisible = () => {
        setDeleteModal(false);
    }

    const deleteModalBtn = [
        {
          type: 'tertiary',
          title: t('translation:modal.btn.cancel'),
          click: callbackDeleteModalVisible
        },
        {
          type: 'primary',
          title: t('translation:modal.btn.confirm'),
          click: () => {onDeleteChecklistClick(); setDeleteModal(false)}
        }
      ]
    return (
        <>
        { deleteModal &&
                <CommonModal 
                    testID="deleteModal" 
                    type={'info'} 
                    title={t('translation:modal.titles.warning')} 
                    text={t('translation:modal.delete.appointment.checklist')} 
                    buttons={deleteModalBtn} 
                    onClose={callbackDeleteModalVisible}
                />
            }
        {date ? 
            <View  testID={`checklistItem`} style={styles.summaryContainer}>
                <Icon name="check-circle" size={PR(25)} color={GlobalStyles.global.black} />
                <Text numberOfLines={1} style={styles.inputSummaryValue}>{checklistText} {moment(date).format('DD/MM/yyyy - HH:mm')}</Text>
                <TouchableOpacity onPress={() => {setDeleteModal(true)}} style={styles.trashContainer}>
                    <Icon name="trash-2" size={PR(25)} color={GlobalStyles.global.redError} />
                </TouchableOpacity>
                <TouchableOpacity onPress={onEditChecklistClick} style={styles.editContainer}>
                    <Icon name="edit-2" size={PR(25)} color={GlobalStyles[APP_SHORTCUT_NAME].btnColor} />
                </TouchableOpacity>
            </View>
            :
            <TouchableOpacity onPress={onChecklistBtnClick}>
                <View style={[styles.inputContainer, {alignSelf:'center'}]}>
                    <Icon style={styles.icon} name="edit" size={PR(25)} color={GlobalStyles.global.grey} />
                    <Text style={{color: GlobalStyles.global.grey}}>{btnText}</Text>
                </View>
            </TouchableOpacity>
        }
        </>
    )
}

const styles = StyleSheet.create({
    icon: {
      marginVertical: PR(18),
    },
    inputContainer: {
      flex: 1,
      flexDirection: 'column',
      minHeight: PR(62),
      padding: PR(10),
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: PR(17),
      backgroundColor: GlobalStyles.global.footerGrey,
      borderRadius: PR(20),
      width: '100%',
      height: PR(114),
      paddingHorizontal: PR(20),
    },
    summaryContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: GlobalStyles.global.footerGrey,
        borderRadius: PR(20),
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: PR(14),
        padding:PR(20)
    },
    inputSummaryValue: {
        flex: 1,
        flexGrow: 1,
        color: GlobalStyles.global.black,
        marginLeft: PR(11),
        fontSize: PR(12),
        fontFamily: GlobalStyles.global.fontFamily.Regular,
    },
    trashContainer: {
        alignItems: 'center',
        paddingRight: PR(10)
    },
    editContainer: {
        alignItems:'center'
    }
})

export default CommonAppointmentChecklistBtn;