import React, {useState} from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import GlobalStyles from '../styles/GlobalStyles';
import InputField from './InputField';
import CommonBtn from './CommonBtn';
import Text from './CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import CommonAcceptanceCheckbox from 'common/src/components/CommonAcceptanceCheckbox';
import CommonFooter from 'common/src/components/CommonFooter';
import {checkBottomScroll} from 'common/src/services/utils';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonYesNeuro = (props:any) => {
  const {t} = useTranslation();
  const [neuroName, setNeuroName] = useState<string>('');
  const [neuroEmail, setNeuroEmail] = useState<string>('');
  const [emailValidated, setEmailValidated] = useState(false);
  const [checkBoxAnswer, setCheckBoxAnswer] = useState<boolean>(false);
  const [scrollAtBottom, setScrollAtBottom] = useState(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  // send the obj to parent component
  const next = () => {
    let obj = {patient:{neurologist_name:neuroName, onboarding:{neurologistDone:true}}, callPermissions:true, permission:{email:neuroEmail, optin:true, permissions:{"*":true}, prescription:'yes',type:"doctor"}}
    props.nextAction(obj)
  }

  const checkEmailValidation = (validated: boolean) => {
    setEmailValidated(validated);
  }

  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => (!neuroEmail.length || !checkBoxAnswer || !emailValidated)
  return (
    <>
      <View style={styles.scrollViewWrapper} testID="app_suggested_by_neuro">
        <ScrollView style={styles.scrollview}
        onScroll={(event: any) => {
            let calc = checkBottomScroll(event.nativeEvent, 0);
            if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
        }}>
           <View
              onLayout={(event: any) => {
                setContentHeight(event.nativeEvent.layout.height)
              }}
            >
            <View style={styles.container}>
              <Text renderAsHtml={true} style={styles.txt}>{t('translation:onboarding.neurologist.prescription_headache_yes')}</Text>
            </View>
            <InputField 
                labelText={t('translation:login.neurologist_name')}
                inputType="string" 
                customStyle={{marginBottom:PR(30)}}
                changeText={setNeuroName}
                placeholderTxt={t('translation:login.neurologist_name_placeholder')}
                testID="name"
                value={neuroName}
              />
              <InputField 
                labelText={t('translation:login.neurologist_email')}
                inputType="email" 
                customStyle={{marginBottom:PR(30)}}
                changeText={setNeuroEmail}
                placeholderTxt={t('translation:login.neurologist_name_placeholder')}
                testID="email"
                isValidationRequired={true}
                changeValidation={checkEmailValidation}
                value={neuroEmail}
              />
              <View style={{width:'90%', alignSelf:'center', justifyContent: 'center'}}>
                <CommonAcceptanceCheckbox testID="acceptanceCheckbox" text={t('translation:neurologist.optin')} updateValidation={setCheckBoxAnswer} checked={checkBoxAnswer}/>
              </View>
            </View>
          </ScrollView>
          <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
            <CommonBtn testID="next_btn" type='primary' click={next} title={t('translation:register.form.btn_register')} disable={checkDisable()}/>
          </CommonFooter>
        </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '90%',
    alignSelf: 'center',
    marginBottom: PR(15)
  },
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1,
    paddingBottom:PR(20)
  },
  txt: {
    textAlign: 'justify',
    fontSize: PR(14),
    color: GlobalStyles.global.greyToBlack,
    marginBottom: PR(15),
    lineHeight:PR(25)
  },
})

export default CommonYesNeuro;