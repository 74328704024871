import React, { useState, useEffect } from 'react';
import { StyleSheet, View, ScrollView, SafeAreaView, TouchableOpacity, Platform } from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonTitle from 'common/src/components/CommonTitle';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Text from 'common/src/components/CommonCustomTxt';
import CommonMedications from 'common/src/components/CommonMedications';
import CommonMedicationsHistoryCurrentFuture from 'common/src/components/CommonMedicationsHistoryCurrentFuture';
import CommonMedicationHistoryPast from 'common/src/components/CommonMedicationsHistoryPast';
import CommonFooter from 'common/src/components/CommonFooter';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMedicationsActions, getMedicationsHistoryActions } from '../store/actions/authenticationActions';
import { DateTime } from "luxon";
import { checkBottomScroll } from 'common/src/services/utils';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import Loading from './Loading';

const Timeline = ['current.label', 'past_and_future.label']

const Treatments = ({ navigation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const today = DateTime.now();
  const medications = useSelector((state: any) => state.getMedications.medications)
  const medicationsHistory = useSelector((state: any) => state.getMedications.medicationsHistory)
  const [selectedDisplayType, setSelected] = useState<string>('current.label');
  const [past, setPast] = useState<Array<any>>([]);
  const [currentFuture, setCurrentFuture] = useState<Array<any>>([]);
  const [contentHeight, setContentHeight] = useState<number>();
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    dispatch(getMedicationsActions({
      callback: () => {
        dispatch(getMedicationsHistoryActions({
          callback: () => {
            setIsLoading(false)
          }
        }));
      }
    }));
  }, [])

  useEffect(() => {
    if (medicationsHistory.length > 0)
      splitMedications();
    else {
      setPast([])
      setCurrentFuture([])
    }
  }, [medicationsHistory])

  // split Medication History data to past and currentFuture
  const splitMedications = () => {
    let pastArr: any = []
    let curFutArr: any = []
    medicationsHistory.forEach(item => {
      if (!item.intake)
        pastArr.push(item)
      else if (item?.intake?.to && today.toFormat('yyyy-MM-dd') > item?.intake?.to) {
        pastArr.push(item)
      } else {
        curFutArr.push(item)
      }
    })
    pastArr.sort((a) => (a.reason !== 'epilepsy') ? 1 : -1)
    setPast(pastArr)
    curFutArr.sort((a) => (a.reason !== 'epilepsy') ? 1 : -1)
    setCurrentFuture(curFutArr)
  }

  const addNewTreatmentBtn = () => {
    if (medications.length > 0)
      navigation.navigate('AddMedication', { medications })
    else
      navigation.navigate('AddTreatments')
  }

  const editIntakeHours = () => {
    navigation.navigate('AddEvent', { itemType: 'Reminder' })
  }

  const changeDisplayList = () => {
    const newDisplayType = selectedDisplayType === 'current.label' ? 'past_and_future.label' : 'current.label';
    setSelected(newDisplayType)
  }

  if (isLoading)
    return <Loading netConnected={true} />
  else
    return (
      <SafeAreaView style={styles.container}>
        <CommonHeader
          testIDTitle="treatmentsTitle"
          title={t('translation:general.page_title.treatment')}
          secondImageName="helpiAddIconFilled"
          rightIconNameSecond="plus-circle"
          rightIconColorSecond={GlobalStyles.helpi.btnColor}
          rightClickSecond={() => { addNewTreatmentBtn() }}
        />
        <View style={styles.selectWrapper}>
          <View style={styles.selectContainer}>
            {
              Timeline.map((displayType, index) => (
                <TouchableOpacity key={index} testID={displayType === 'current.label' ? 'currentID' : 'pastFutureID'} style={[styles.selectBox, displayType === selectedDisplayType ? {} : { backgroundColor: 'transparent' }]} onPress={changeDisplayList}>
                  <Text style={[styles.selectBoxText, displayType === selectedDisplayType ? { color: GlobalStyles.helpi.color } : { color: GlobalStyles.helpi.inactiveColor }]}>{t('translation:treatment.detail.' + displayType)}</Text>
                </TouchableOpacity>
              ))
            }
          </View>
        </View>
        <ScrollView
          testID="treatment_scrollView"
          scrollEventThrottle={16}
          onScroll={(event) => {
            let calc = checkBottomScroll(event.nativeEvent, 0);
            if (!scrollAtBottom && calc)
              setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
              setScrollAtBottom(false)
          }}
        >
          <View
            onLayout={(event: any) => {
              setContentHeight(event.nativeEvent.layout.height)
            }}
          >
            {/* Display current medications */}
            {
              selectedDisplayType === 'current.label' ?
                <>
                  {medications.length > 0 ? medications.map((item, index) => {
                    if (item) {
                      let med = { name: item?.name, reason: item?.reason }
                      let dos = !item?.intake?.dosage && !item?.intake?.specific_dosage ? [] : !item?.intake?.dosage ? [item?.intake?.specific_dosage] : item?.intake?.dosage
                      let from = item?.intake?.from
                      let to = item?.intake?.to === undefined ? null : item?.intake?.to
                      return (
                        <View key={index}>
                          <CommonMedications
                            navigation={navigation}
                            med={med}
                            dos={dos}
                            from={from}
                            to={to}
                            id={item?.id}
                          />
                        </View>
                      )
                    }
                  })
                    :
                    <CommonTitle color={GlobalStyles.global.black} text={t('translation:treatment.general.no_medication')} txtAlign='center' />
                  }
                </>
                :
                <>
                  {/* display current & future medications */}
                  {currentFuture.length === 0 && past.length === 0 ?
                    <CommonTitle color={GlobalStyles.global.black} text={t('translation:treatment.general.no_past_medication')} txtAlign='center' />
                    :
                    <>
                      {currentFuture.length > 0 &&
                        <>
                          <CommonTitle color={GlobalStyles.global.black} text={t('translation:treatment.general.future_use')} txtAlign='center' />
                          {currentFuture.map((item, index) => {
                            let med = { name: item.name, reason: item.reason }
                            let dos = !item?.intake?.dosage && !item?.intake?.specific_dosage ? [] : !item?.intake?.dosage ? [item?.intake?.specific_dosage] : item?.intake?.dosage
                            let from = item?.intake?.from
                            return (
                              <View key={index}>
                                <CommonMedicationsHistoryCurrentFuture
                                  navigation={navigation}
                                  med={med}
                                  dos={dos}
                                  from={from}
                                  id={item.id}
                                />
                              </View>
                            )
                          })}
                        </>
                      }
                      {/* display past medications */}
                      {past.length > 0 &&
                        <>
                          <CommonTitle color={GlobalStyles.global.black} text={t('translation:treatment.general.past_medication')} txtAlign='center' />
                          {past.map((item, index) => {
                            let med = { name: item.name, reason: item.reason }
                            let dos = !item?.intake?.dosage && !item?.intake?.specific_dosage ? [] : !item?.intake?.dosage ? [item?.intake?.specific_dosage] : !item?.intake?.dosage ? [] : item?.intake?.dosage
                            let to = item?.intake?.to
                            return (
                              <View key={index}>
                                <CommonMedicationHistoryPast
                                  navigation={navigation}
                                  med={med}
                                  dos={dos}
                                  to={to}
                                  id={item.id}
                                />
                              </View>
                            )
                          })}
                        </>
                      }
                    </>
                  }
                </>
            }
          </View>
        </ScrollView>
        <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
          <CommonBtn testID={"intakesHoursBtnID"} type={'tertiary'} title={t('translation:treatment.general.edit_intake')} click={() => { editIntakeHours() }} />
        </CommonFooter>
        <View style={{ marginBottom: Platform.OS === 'web' ? PR(45) : 0 }}></View>
      </SafeAreaView>
    )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: GlobalStyles.global.white,
    flex: 1,
  },
  selectWrapper: {
    width: '90%',
    alignSelf: 'center',
    height: PR(45),
    marginBottom: PR(10),
  },
  selectContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: GlobalStyles.helpi.ultraLightColor,
    borderRadius: PR(50),
    alignItems: 'center',
  },
  selectBox: {
    padding: PR(9),
    backgroundColor: GlobalStyles.global.white,
    borderRadius: PR(50),
    margin: PR(5),
    alignItems: 'center',
    flex: 1,
    flexGrow: 0.5
  },
  selectBoxText: {
    fontFamily: GlobalStyles.global.fontFamily.Bold
  },

  btnContainer: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
    marginBottom: Platform.OS === 'web' ? PR(50) : PR(20),
    marginTop: PR(15)
  }
})

export default Treatments