import {call, put, takeLatest, select} from "redux-saga/effects";
import {SEND_TOKEN_ACTION, SEND_TOKEN_SUCCESS, REVOKE_TOKEN_ACTION, REVOKE_TOKEN_SUCCESS} from "./fb_tokenAction";
import {makeRequest} from "../../services/requestService";

export function* fb_tokenSaga(payload:any) {
    yield call(makeRequest, {
        data: payload.data,
        path: `/meApp`,
        method: 'PUT',
        responseAction: SEND_TOKEN_SUCCESS,
        ...payload
    })
}

export function* revoke_fb_tokenSaga(payload:any) {
    yield call(makeRequest, {
        data: payload.data,
        path: `/auth/revoke`,
        method: 'POST',
        responseAction: REVOKE_TOKEN_SUCCESS,
        ...payload
    })
}

export default function* watchUserAuthentication() {
    yield takeLatest(SEND_TOKEN_ACTION, fb_tokenSaga);
    yield takeLatest(REVOKE_TOKEN_ACTION, revoke_fb_tokenSaga);
}