import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { useDispatch, useSelector } from 'react-redux';
import { deviceOsVersionAction } from 'helpilepsy/src/store/deviceDetails/deviceDetailsActions'

export const useDeviceHandler = () => { 
    const dispatch = useDispatch();
    const GetMe = useSelector((state:any) => state.getMe.response)

    return () => {
        if(Platform.OS === 'web'){
            if(GetMe?.result?.settings?.os_version !== 'web')
                dispatch(deviceOsVersionAction({
                    settings:{
                        os_version: 'web'
                    }
                }))
        } else {
            if(GetMe?.result?.settings?.os_version !== DeviceInfo.getSystemName() + " " + DeviceInfo.getSystemVersion())
                dispatch(deviceOsVersionAction({
                    settings:{
                        os_version: DeviceInfo.getSystemName() + " " + DeviceInfo.getSystemVersion()
                    }
                }))
        }
    }
}