import {
    SeizureDescriptionCardTypes, 
    SeizureAntidoteCardTypes, 
    TriggersModelCardTypes, 
    GetPatientEvent, 
    DeleteAppointmentChecklistTypes,
    DeleteDescriptionTypes
} from './types';

export enum EpilepsyDetailsActions {
    GET_SEIZURES_DESCRIPTION_CARD = 'GET_SEIZURES_DESCRIPTION_CARD',
    GET_SEIZURES_DESCRIPTION_CARD_SUCCESS = "GET_SEIZURES_DESCRIPTION_CARD_SUCCESS",
    ADD_UPDATE_SEIZURES_DESCRIPTION_CARD = 'ADD_UPDATE_SEIZURES_DESCRIPTION_CARD',
    ADD_UPDATE_SEIZURES_DESCRIPTION_CARD_SUCCESS = 'ADD_UPDATE_SEIZURES_DESCRIPTION_CARD_SUCCESS',
    GET_SEIZURES_ANTIDOTE_CARD = 'GET_SEIZURES_ANTIDOTE_CARD',
    GET_SEIZURES_ANTIDOTE_CARD_SUCCESS = 'GET_SEIZURES_ANTIDOTE_CARD_SUCCESS',
    ADD_UPDATE_SEIZURES_ANTIDOTE_CARD = 'ADD_UPDATE_SEIZURES_ANTIDOTE_CARD',
    ADD_UPDATE_SEIZURES_ANTIDOTE_CARD_SUCCESS = 'ADD_UPDATE_SEIZURES_ANTIDOTE_CARD_SUCCESS',
    GET_PATIENT_EVENT = 'GET_PATIENT_EVENT',
    GET_PATIENT_EVENT_SUCCESS = 'GET_PATIENT_EVENT_SUCCESS',
    GET_TRIGGERS_MODEL_CARD = 'GET_TRIGGERS_MODEL_CARD',
    GET_TRIGGERS_MODEL_CARD_SUCCESS = 'GET_TRIGGERS_MODEL_CARD_SUCCESS',
    ADD_UPDATE_TRIGGERS_MODEL_CARD = 'ADD_UPDATE_TRIGGERS_MODEL_CARD',
    ADD_UPDATE_TRIGGERS_MODEL_CARD_SUCCESS = 'ADD_UPDATE_TRIGGERS_MODEL_CARD_SUCCESS',
    DELETE_APPOINTMENT_CHECKLIST = 'DELETE_APPOINTMENT_CHECKLIST',
    DELETE_DESCRIPTION = 'DELETE_DESCRIPTION',
}

export const getSeizureDescriptionCard = () => {
    return {
        type: EpilepsyDetailsActions.GET_SEIZURES_DESCRIPTION_CARD,
    };
};

export const getSeizureAntidoteCard = () => {
    return {
        type: EpilepsyDetailsActions.GET_SEIZURES_ANTIDOTE_CARD,
    };
};

export const getTriggersModelCard = () => {
    return {
        type: EpilepsyDetailsActions.GET_TRIGGERS_MODEL_CARD
    }
}

export const getPatientEvent = (payload:GetPatientEvent) => {
    return {
        type: EpilepsyDetailsActions.GET_PATIENT_EVENT,
        ...payload
    };
};

export const addUpdateSeizureDescriptionCard = (payload:SeizureDescriptionCardTypes) => {
    return {
        type: EpilepsyDetailsActions.ADD_UPDATE_SEIZURES_DESCRIPTION_CARD,
        ...payload
    };
};

export const addUpdateSeizureAntidoteCard = (payload:SeizureAntidoteCardTypes) => {
    return {
        type: EpilepsyDetailsActions.ADD_UPDATE_SEIZURES_ANTIDOTE_CARD,
        ...payload
    };
};

export const addUpdateTriggersModelCard = (payload:TriggersModelCardTypes) => {
    return {
        type: EpilepsyDetailsActions.ADD_UPDATE_TRIGGERS_MODEL_CARD,
        ...payload
    };
};

export const deleteAppointmentChecklist = (payload:DeleteAppointmentChecklistTypes) => {
    return {
        type: EpilepsyDetailsActions.DELETE_APPOINTMENT_CHECKLIST,
        ...payload
    }
};

export const deleteDescriptionCards = (payload:DeleteDescriptionTypes) => {
    return {
        type: EpilepsyDetailsActions.DELETE_DESCRIPTION,
        ...payload
    }
};
