import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useTranslation } from 'react-i18next';
import Text from './CommonCustomTxt';
import CommonSelect from './CommonSelect';
import InputField from './InputField';
import CommonStudyRankDropdown from './CommonStudyRankDropdown';
import {getListCMSData} from "../helpers/translations";
import { LocalisationListItem } from '../types';
import CommonSlider from './CommonSlider';
import CommonBtn from './CommonBtn';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import GlobalStyles from '../styles/GlobalStyles';

const getLabelInList = (list: LocalisationListItem[], value: string) => {
    const item = list.find(term => term.value === value);
    if (item) {
        return item.label;
    }
    return undefined;
}

interface FormQuestions {
    question: any,
    updateQuestion: Function,
    disable: boolean,
    isList?: boolean,
    listIndex?: number,
    answersIndex?: number
}

const CommonStudyFormQuestions = ({question, updateQuestion, disable, isList, listIndex, answersIndex}: FormQuestions) => {
    const {t, i18n} = useTranslation();
    const { language: userLanguage } = i18n;
    const type = question.answer_type;
    const [answer, setAnswer] = useState(question.answer);
    const [currentRankTypeOpened, setCurrentRankTypeOpened] = useState(-1)
    const [questionList, setQuestionList] = useState([] as any)
    const [rawQuestions, setRawQuestions] = useState([] as any)

    const getText = () => {
        let text = "";
        if (question?.questionText)
            text = question?.questionText[userLanguage] || question?.questionText?.en
        if (!text && question?.question)
            text = t('translation:forms.' + question.question);
        return text
    }

    const propositions = question.propositions && getListCMSData(t, `list.${question.propositions}`)
        .map(({label, value}, index) => {
            if (type === 'rank') {
                if (!question.answer)
                    question.answer = {}
                if (!question.answer[index] && !disable)
                    question.answer[index] = '';
            }
            return {
                value,
                label,
                selected: value === question.answer ? true : false,
                disable: type === 'rank' && question.answer && Object.keys(question.answer).find(key =>
                    question.answer[key] === value)
            };
        });

    const [data, setData] = useState([])

    useEffect(() => {
        if (propositions) {
            setData(propositions);
        }
        if (type === 'multiCheck' && question.answer)
            setData(question.answer)
        if (type === 'list' && questionList.length === 0) {
            let questionCpy = {...question}
            questionCpy.answers = question.answers ? question.answers : [{answers: question.questions}]
            setQuestionList(questionCpy)
        }
        if (question.questions && rawQuestions.length === 0) {
            let questionCopy = JSON.parse(JSON.stringify(question.questions))
            questionCopy.map((qst) => {
                if (qst.answer_type === 'rank') {
                    for (let key in qst.answer)
                        qst.answer[key] = ""
                }
                else
                    qst.answer = "";
            });
            setRawQuestions(questionCopy)
        }
    }, [question])

    const updateAnswer = (qList) => {
        let answer: any = null
        qList.map((q) => {
            if (q.selected){
                answer = q.value
            }
        })
        question.answer = answer
        setAnswer(answer);
        if (isList)
            updateQuestion(answer, question, answersIndex, listIndex)
        else
            updateQuestion(answer);
        return answer
    }

    const onStringTypeUpdate = (text) => {
        question.answer = text
        if (isList)
            updateQuestion(text, question, answersIndex, listIndex)
        else
            updateQuestion(text)
        setAnswer(text)
    }

    const onRankTypeUpdate = (value, label, rank) => {
        let dataToUpdate: any = [...data]
        let matchIndex = 0;
        if (!question.answer)
            question.answer = {}

        dataToUpdate.map((item, i) => {
            if (item.value === value) {
                question.answer[rank] = value;
                matchIndex = i;
            }
        })
        dataToUpdate[matchIndex].disable = true;
        setAnswer(question.answer);
        if (isList)
            updateQuestion(question.answer, question, answersIndex, listIndex)
        else
            updateQuestion(question.answer);
        setData(dataToUpdate)
    }

    const onRankTypeRemove = (value, rank) => {
        let dataToUpdate: any = [...data]
        if (question.answer[rank])
            question.answer[rank] = ''
        dataToUpdate.map((item, i) => {
            if (item.value === value) {
                item.disable = false;
            }
        })
        setAnswer(question.answer);
        if (isList)
            updateQuestion(question.answer, question, answersIndex, listIndex)
        else
            updateQuestion(question.answer);
        setData(dataToUpdate)
    }

    const onSelectTypeUpdate = (value: string) => {
        setAnswer(value);
        question.answer = value;
        if (isList)
            updateQuestion(question.answer, question, answersIndex, listIndex)
        else
            updateQuestion(question.answer);
    };

    const onScaleTypeUpdate = (value: number) => {
        question.answer = value.toString();
        setAnswer(value.toString())
        if (isList)
            updateQuestion(value.toString(), question, answersIndex, listIndex)
        else
            updateQuestion(value.toString())
    }

    const updateRankTypeOpened = (index) => {
        setCurrentRankTypeOpened(index === currentRankTypeOpened ? -1 : index)
    }

    const onDateTypeUpdate = (value: string) => {
        question.answer = value;
        setAnswer(value)
        if (isList)
            updateQuestion(value, question, answersIndex, listIndex)
        else
            updateQuestion(value)
    }

    const onListTypeUpdate = (value, currentQ, index, index_2) => {
        let questionListCpy: any = {...questionList};
        questionListCpy.answers[index].answers[index_2] = currentQ
        setQuestionList(questionListCpy);
        updateQuestion(questionListCpy)
    }

    const addEmptyList = () => {
        let questionListCpy: any = {...questionList};
        questionListCpy.answers.push({answers: JSON.parse(JSON.stringify(rawQuestions))})
        setQuestionList(questionListCpy);
    }

    const removeList = (indexToRemove) => {
        let questionListCpy: any = {...questionList};
        questionListCpy.answers.splice(indexToRemove, 1)
        setQuestionList(questionListCpy)
    }

    const onMultiCheck = (value) => {
        question.answer = value
        updateQuestion(question.answer)
    }

    return (
        <View>
            <View style={{paddingHorizontal: PR(20)}}>
                {
                    getText() !== "" &&
                        <Text renderAsHtml={true} style={[styles.text, type === 'section' ? {fontSize: PR(18)} : {fontSize: PR(16)}]} shouldParseHtml={true}>{getText()}</Text>
                }
                {
                    type === 'section' && question.text &&
                        <Text renderAsHtml={true} style={{fontSize: PR(16)}} shouldParseHtml={true}>{question.text[userLanguage] || question.text.en}</Text>
                }
            </View>
            <View style={{marginBottom: PR(20)}}>
                {
                    (type === 'select') &&
                        <InputField
                            inputType="dropdown"
                            changeText={onSelectTypeUpdate}
                            placeholderTxt={t('translation:forms.general.chooseAnswer')}
                            testID="form_question_select"
                            value={(data.length > 0 && question.answer) ? getLabelInList(data, question.answer) : null }
                            data={data}
                            disable={disable}
                        />
                }
                {
                    (type === 'radio') && propositions &&
                        <CommonSelect style={{paddingHorizontal: PR(20)}} type='radio' data={data} onSelect={updateAnswer} disable={disable}/>
                }
                {
                    type === 'multiCheck' && propositions &&
                        <CommonSelect style={{paddingHorizontal: PR(20)}} type='check' data={data} onSelect={onMultiCheck} disable={disable}/>
                }
                {

                    (type === 'string' || type === 'textarea') &&
                        <InputField inputType='string' value={question.answer} changeText={onStringTypeUpdate} multiline={type === 'textarea'} disable={disable}/>
                }
                {
                    type === 'rank' && question.answer &&
                        <View style={{marginLeft: PR(22), marginRight: PR(22)}}>
                            {
                                Object.keys(question.answer).map((item: any, index) => (
                                    <CommonStudyRankDropdown placeholderTxt={t('translation:forms.general.chooseAnswer')} value={question.answer[item]} data={data} changeText={onRankTypeUpdate} rank={index} removeInput={onRankTypeRemove} index={index} indexOpened={currentRankTypeOpened} updateDropdownIndex={updateRankTypeOpened} disable={disable}/>
                                ))
                            }
                        </View>
                }
                {
                    type === 'scale' &&
                        <CommonSlider min={parseInt(question.scaleFrom)} max={parseInt(question.scaleTo)} updateValue={onScaleTypeUpdate} initialValue={question.answer} disable={disable}/>
                }
                {
                    type === 'number' &&
                        <InputField inputType='number' value={question.answer} changeText={onStringTypeUpdate} disable={disable}/>
                }
                {
                    type === 'date' &&
                        <InputField inputType='datetime' value={question.answer} changeText={onDateTypeUpdate} disable={disable}/>
                }
                {
                    type === 'list' &&
                        <View>
                            {
                                questionList && questionList.answers && questionList.answers.map((item, answersIndex) => (
                                    <View style={{backgroundColor: "#fafafa", padding: PR(20), borderRadius: PR(5), marginBottom: PR(20)}}>
                                        {
                                            answersIndex > 0 &&
                                                <TouchableOpacity style={{alignSelf: 'flex-end'}} onPress={() => {removeList(answersIndex)}}>
                                                    <Icon name="x"/>
                                                </TouchableOpacity>
                                        }
                                        {
                                            item.answers && item.answers.map((listq, index) => (
                                                <CommonStudyFormQuestions question={listq} updateQuestion={onListTypeUpdate} disable={disable} isList={true} listIndex={index} answersIndex={answersIndex}/>
                                            ))
                                        }
                                    </View>
                                ))
                            }
                            {
                                !disable && 
                                    <CommonBtn type="tertiary" title={t('translation:modal.btn.add')} click={addEmptyList}/>
                            }
                        </View>
                }
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    text: {
        marginTop: PR(20),
        marginBottom: PR(10),
        fontFamily: GlobalStyles.global.fontFamily.Bold
    }
});

export default CommonStudyFormQuestions;
