import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import Text from 'common/src/components/CommonCustomTxt';
import calculateMedicationAdherence from './calculate-medication-adherence';

const CommonMedicationAdherence = ({selectedDisplayType}) => {
    const {t} = useTranslation()
    const [medicationAdherence, setMedicationAdherence] = useState(0)
    const [hasMedication, setHasMedication] = useState(false);
    const GetEventsResponse = useSelector(state => state.getEvents.timeline_events);

    useEffect(()=> {
        if (GetEventsResponse) {
            const patientMedication = checkMedication(GetEventsResponse)
            if (patientMedication) {
                setMedicationAdherence(
                    calculateMedicationAdherence(GetEventsResponse)
                );
                setHasMedication(patientMedication);
            }
        }
    }, [GetEventsResponse])

    const checkMedication = (eventDates: any[]) => {
        let medicationFound = false
        eventDates
            .filter(date => Array.isArray(date.reminders) && date.reminders.length > 0)
            .forEach(function (day) {
                for (let index = 0; day.reminders[index]; index++) {
                    let medication = day.reminders[index];
                    if (medication.intake) {
                        medicationFound = true;
                        break;
                    }
                }
            })
        return medicationFound;
    }

    return (
        <View>
            {
                hasMedication ?
                    <Text>{t('translation:dashboard.medication_adherence.text.' + selectedDisplayType) + ' ' + medicationAdherence}%</Text>
                    :
                    <Text>{t('translation:treatment.general.no_medication')}</Text>
            }
        </View>
    )
}

export default CommonMedicationAdherence