import React, {useState, useEffect} from 'react';
import {View, ScrollView, StyleSheet, TouchableOpacity, TouchableWithoutFeedback} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import GlobalStyle from '../styles/GlobalStyles';
import Text from './CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonStudyRankDropdown = (props:any) => {
  const {
    rank,
    labelText,
    customStyle,
    changeText,
    removeInput,
    placeholderTxt,
    value,
    data,
    index,
    indexOpened,
    updateDropdownIndex,
    testID,
    disable
  } = props;
  const {t} = useTranslation();
  const [displayDropdown, setDisplayDropdown] = useState(false);
  
  useEffect(() => {
    if (index === indexOpened)
      setDisplayDropdown(true)
    else
      setDisplayDropdown(false);
  }, [indexOpened])

  const updateInput = (value, label) => {
    changeText(value, label, rank);
    if (displayDropdown)
      setDisplayDropdown(false)
  }

  const removeData = () => {
    removeInput(value, rank)
  }

  const onLabelClick = () => {
    updateDropdownIndex(index)
  }

  const getValueToDisplay = () => {
    if (!value)
      return "";
    const match = data.find(item => item.value === value)
    if (!match)
      return value
    return match.label
  }

  const renderInput = () => {
      return (
        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <TouchableOpacity testID={"dropdownID"+testID} style={{flexDirection: 'row', alignItems: 'center', flex: 1}} onPress={disable ? () => {} : onLabelClick}>
                <Text>{rank + 1}.</Text>
                <Text renderAsHtml={true} style={styles.dropdownValue}>{getValueToDisplay() || placeholderTxt}</Text>
                {
                    value !== '' &&
                    <TouchableWithoutFeedback>
                        <TouchableOpacity onPress={disable ? () => {} : removeData}>
                            <Icon name="x" style={styles.iconBox} size={PR(26)} color={GlobalStyle.global.grey}/>
                        </TouchableOpacity>
                    </TouchableWithoutFeedback>
                }
            </TouchableOpacity>
        </View>
      )
  }

  const renderDropdown = () => (
    <View style={styles.dropdown}>
      <ScrollView nestedScrollEnabled={true}>
        {
          data && data.map((item, index) => (
            item.disable ?
              <View style={styles.dropdownTextContainer}>
                <Text style={[styles.dropdownText, {opacity: 0.5}]}>{item.label}</Text>
              </View>
            :
              <TouchableOpacity key={index} style={styles.dropdownTextContainer} onPress={() => updateInput(item.value, item.label)} testID={item.value}>
                <Text style={styles.dropdownText}>{item.label}</Text>
              </TouchableOpacity>
          ))
        }
      </ScrollView>
    </View>
  )

  return (
    <View style={[customStyle, styles.wrapper]}>
        {
            labelText &&
                <Text style={styles.label}>{labelText}</Text>
        }
        <View style={styles.inputContainer}>
            {renderInput()}
        </View>
        {
            displayDropdown &&
                renderDropdown()
        }
    </View>
  );
}

const styles = StyleSheet.create({
    wrapper: {
      width: '100%',
      alignSelf: 'center',
      padding: PR(10)
    },
    label: {
      marginBottom: PR(10),
      marginLeft: PR(20),
      color: GlobalStyle.global.black,
      fontSize: PR(12)
    },
    inputContainer: {
      flex: 1,
      flexDirection: 'row',
      fontSize: PR(16),
      alignItems: 'center',
    },
    dropdown: {
      backgroundColor: GlobalStyle.global.lightGrey,
      borderRadius: PR(20),
      marginTop: PR(10),
      paddingBottom: PR(20),
      justifyContent: 'space-around',
      flexDirection: 'column'
    },
    dropdownTextContainer: {
      marginTop: PR(20),
      marginLeft: PR(20)
    },
    dropdownText: {
      color: GlobalStyle.global.black,
      fontSize: PR(16),
      fontFamily: GlobalStyle.global.fontFamily.Medium
    },
    dropdownValue: {
      padding: PR(10),
      color: GlobalStyle.global.black,
      fontSize: PR(16),
      fontFamily: GlobalStyle.global.fontFamily.Medium,
      textTransform: 'capitalize',
      flexGrow: 1,
      alignSelf: 'flex-start',
      flex: 1
    },
    iconsContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: PR(10)
    },
    iconBox: {
      justifyContent: 'center',
      alignItems: 'center',
    }
});

export default CommonStudyRankDropdown;