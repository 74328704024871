import React, { useState, useEffect } from 'react';
import {StyleSheet, View} from 'react-native';
import Text from 'common/src/components/CommonCustomTxt';
import GlobalStyles from '../../styles/GlobalStyles';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import { useTranslation } from 'react-i18next';
import {getListCMSData} from "../CommonDiary/utils";
import CommonProgressBar from '../CommonProgress/CommonProgressBar';
import {APP_SHORTCUT_NAME} from '../../env.json';

type OwnProps = {
    Events: Array<EventType>,
    eventType: string
};

interface EventType {
    totalSeizures: number,
    yes: FeltType

};

interface FeltType {
    count: number,
    auras: Array<string>
};

interface AuraType {
    count: number,
    name: string,
}



const CommonImpactAurasGraph = ({Events, eventType}: OwnProps) => {
	const {t} = useTranslation();
    const [auraArray, setAuraArray] = useState<Array<AuraType>>([]);
    const [auraCount, setAuraCount] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    
    const getTopValues = (array, n) => {
        let tempObj = {};
        for(let i = 0; i < array.length; i++) {
            tempObj.hasOwnProperty(array[i]) ? tempObj[array[i]]++ : tempObj[array[i]] = 1;
        }

        const sorted: Array<any> = Object.entries(tempObj)
            .sort((a: any, b: any) => b[1] - a[1])
            .slice(0,n);
        
        let arr: any[] = [];
        for(let i = 0; i < sorted.length; i++) {
            const obj: AuraType = { name: sorted[i][0], count: sorted[i][1] };
            arr.push(obj);
        }
        
       return arr;
    };
    
    
    useEffect(() => {
        Events.map((item) => {
            if(item?.yes) {
                setAuraArray(getTopValues(item?.yes?.auras, 3));
                setAuraCount(item?.yes?.count);            
            }
            setTotalCount(item?.totalSeizures);
        });
    }, []);
    
	return (
		<View style={{flexDirection: 'column',  width: '100%'}}>
            <Text testID="auras_title" style={{textAlign: 'center'}}>{t('translation:programs.impact.triggers_auras.title',{fieldType: eventType})} </Text>
            <View>
                {
                    auraArray.map((item, index) => {
                     
                        return (
                            <View key={index} style={styles.auraContainer}>
                                <Text testID={`auras_name_${index}`} style={{textAlign: 'left'}}>
                                    { item.name !== 'Not Specified' ? 
                                        getListCMSData(t, item.name, `list.seizure_${eventType}`) : t('translation:events.title.not_specified')
                                    }
                                </Text>
                                <View style={{flexGrow: 1}}>
                                    {
                                        item.name !== 'Not Specified' ?
                                        <CommonProgressBar current={item.count} total={auraCount} fillColor={GlobalStyles[APP_SHORTCUT_NAME].color} backgroundColor={GlobalStyles[APP_SHORTCUT_NAME].progressBarBackgroundColor}/>
                                        :
                                        <CommonProgressBar current={item.count} total={auraCount} fillColor={GlobalStyles.global.grey} backgroundColor={GlobalStyles.global.lightGrey}/>
                                    }
                                </View>
                                <Text testID={`auras_percentage_${index}`}>{auraCount === 0 ? 0 : ((item.count/auraCount)*100).toFixed(1)}%</Text>
                            </View>    
                        )
                    })
                }
            </View>
            <Text testID='auras_bottom_text' style={styles.aurasBottomTxt}>{t('translation:programs.impact.triggers_auras.bottom_text',{TAtype: eventType, percentage: ( totalCount === 0 ? 0 : (auraCount/totalCount)*100).toFixed(1)})}</Text>
        </View>
        
	)
};

const styles = StyleSheet.create({
    auraContainer: {
        flex:1,
        marginTop: PR(20)
    },
    aurasBottomTxt: {
        marginTop: PR(20),
        textAlign: 'left',
        fontSize: PR(12),
        fontFamily: GlobalStyles.global.fontFamily.RegularItalic,
        opacity: 0.8
    }
});

export default CommonImpactAurasGraph;