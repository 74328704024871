import { getHeaderRequest, getRequest } from '../../callService';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {APP_SHORTCUT_NAME, TRANSLATION_ENV} from '../../../env.json';

let API:string 
if(APP_SHORTCUT_NAME === 'helpi') {
  API = 'https://i18n.neuroventis.care/helpilepsy'
} else {
  API = 'https://i18n.neuroventis.care/migrainemanager'
}

export const languages = async (lang) => {
  const date = await getHeaderRequest(API+TRANSLATION_ENV+lang+'.json');
  let lastModifiedDate = await AsyncStorage.getItem('last-modified-'+lang)
  if (lastModifiedDate !== null && JSON.parse(lastModifiedDate) === date.get('last-modified')) {
    let res = (await data(lang)).res;
    return {res,}
  } else {
    await AsyncStorage.setItem('last-modified-'+lang, JSON.stringify(date.get('last-modified')));
    let langRes = await getLang(lang)
    let res = langRes.language
    return {res,}
  }
}

const data = async (lang) => {
  let value = await AsyncStorage.getItem(lang)
  if (value !== null && value !== "") {
    let res = await JSON.parse(value)
    return {res}
  } else {
    let langRes = await getLang(lang)
    let res = langRes.language
    return {res,}
  }
}

const getLang = async (lang) => {
  try {
    const language = await getRequest(API+TRANSLATION_ENV+lang+'.json');
    await AsyncStorage.setItem(lang, JSON.stringify(language));
    return {
      language,
    };
  } catch (e) {
      throw e;
  }
};