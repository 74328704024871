import {call, put, takeLatest} from "redux-saga/effects";
import * as types from "../actions";
import {getRequest} from "common/src/services/callService";
import {GET_LIST_OF_LANGUAGES, GET_LIST_OF_LANGUAGES_SUCCESS} from "./listOfLangActions";

function* makeRequest ({path, callback, errorCallback, responseAction}) {
    try {
        yield put ({ type: types.LOADING, response:true});
        const response = yield call(getRequest, path)
            yield put ({ type: types.LOADING, response:false});
            if (callback) yield call(callback, response.result);
            if (responseAction) yield put({ type: responseAction, response });
            return response
    } catch(error:any) {
        if (errorCallback) yield call(callback);
        yield put({ type: types.LOADING, response:false});
        if (error.message === 'Network request failed' || error.message === 'Failed to fetch') {
            yield put({ type: types.ERROR, message: 'translation:errors.network_error' });
        }
    }
}


export function* getListOfLangSaga(payload:any) {
    yield call(makeRequest, {
        path: `https://localisation-api.neuroventis.care/languages`,
        responseAction: GET_LIST_OF_LANGUAGES_SUCCESS,
        ...payload
    })
}

export default function* watchUserAuthentication() {
    yield takeLatest(GET_LIST_OF_LANGUAGES, getListOfLangSaga);
}