import React from 'react';
import { useTranslation } from "react-i18next";
import { View, StyleSheet, Platform } from 'react-native';
import Text from '../CommonCustomTxt';
import CommonTimeInput from '../CommonTimeInput';
import InputField from '../InputField';
import GlobalStyles from '../../styles/GlobalStyles';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import { ProgramAction } from "../../types";
import CommonBtn from "../CommonBtn";
import CommonStyle from './CommonStyle';
import { APP_SHORTCUT_NAME } from '../../env.json';
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParams } from "../../types";
import CommonTitle from '../CommonTitle';
import CommonSubTitle from '../CommonSubtitle';
import moment from 'moment';

type OwnProps = {
    programAction: ProgramAction,
    scheduledTime: string | undefined,
    scheduledWeekDay: string | undefined,
    onTimeChange: (value: string) => void,
    onWeekDayChange: (value: string) => void,
    weekDaysList: Array<object>,
    navigation: StackNavigationProp<RootStackParams>
}

const CommonProgramActionHabit = ({programAction, scheduledTime, scheduledWeekDay, onTimeChange, onWeekDayChange, weekDaysList, navigation}: OwnProps) => {
    const { t } = useTranslation();

    if (!scheduledTime && Platform.OS === 'android')
        scheduledTime = moment().format('HH:mm');

    if (programAction.actionBtnNavigation && programAction.actionBtnNavigation.Nav === 'MoodSleepNotifications')
        return (
            <View style={CommonStyle.scheduleBtn}>
                <CommonBtn
                    type='tertiary'
                    title={t('translation:modal.notifications.edit')}
                    color={GlobalStyles[APP_SHORTCUT_NAME].btnColor}
                    click={() => {navigation.navigate('MoodSleepNotifications')}}
                    testID='scheduleMoodSleep'
                />
            </View>
        )
    else
        return (
            <View>
                <View style={{width: "90%", alignSelf: 'center', marginVertical: PR(20)}}>
                    <CommonTitle text={t("translation:programs.action.reminder.title")} color={GlobalStyles.global.black} txtAlign={"left"} size={PR(18)}/>
                    <CommonSubTitle text={t(`translation:programs.action.reminder.${programAction.frequency}.subtitle`)} txtAlign={"left"}/>
                </View>
                {
                    programAction.frequency === 'weekly' &&
                        <View style={{marginBottom: PR(20)}}>
                            <InputField inputType="dropdown" placeholderTxt={t('translation:programs.action.reminder.select_weekday.placeholder')} labelText={t('translation:programs.action.reminder.select_weekday')} value={scheduledWeekDay} changeText={onWeekDayChange} data={weekDaysList}/>
                        </View>
                }
                <View style={{width: "90%", alignSelf: 'center'}}>
                    <Text style={{marginBottom: PR(10), marginLeft: PR(20), fontSize: PR(12), color: GlobalStyles.global.black}}>{t('translation:programs.action.reminder.select_time')}</Text>
                    <CommonTimeInput
                        value={scheduledTime}
                        onChange={onTimeChange}
                        fieldStyle={styles.dateInputField}
                        textStyle={styles.dateInputFieldText}
                        webInputStyle={{
                            backgroundColor: "#F2F4F5",
                            border: "none",
                            width: "100%",
                            color: GlobalStyles.global.black,
                            fontSize: PR(16),
                            fontFamily: GlobalStyles.global.fontFamily.Medium
                        }}
                    />
                </View>
            </View>
        )
}

const styles = StyleSheet.create({
    dateInputField: {
        backgroundColor: '#F2F4F5',
        borderRadius: PR(20),
        borderColor: '#F2F4F5',
        height: PR(62),
        paddingHorizontal: PR(20),
        paddingVertical: PR(20),
        width: '100%'
    },
    dateInputFieldText: {
        fontSize: PR(16),
        fontFamily: GlobalStyles.global.fontFamily.Medium
    }
})

export default CommonProgramActionHabit;