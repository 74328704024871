import React, { useEffect, useState } from 'react';
import CommonDataSharing from 'common/src/components/CommonDataSharing/CommonDataSharing';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParams, User } from 'common/src/types';
import { useDispatch, useSelector } from 'react-redux';
import { getMeAction, logOutAction, updateDataSharingAction, updatePatientSiteAction } from '../store/actions/authenticationActions';
import { RootState } from '../store/reducers';

interface DataSharingRequest {
    isActive: boolean;
    doctor: User;
};

const DataSharing = ({
  navigation,
  route: { params },
}: StackScreenProps<RootStackParams, "DataSharingRequest">) => {
  const patient = useSelector((state: any) => state.getMe.patient);
  const dataSharingRequests = useSelector<
    RootState,
    DataSharingRequest[] | undefined
  >((state: any) => state.getMe.dataSharing);
  const dispatch = useDispatch();
  const [doctor, setDoctor] = useState<User | undefined>();
  const { nextAction, reloadMe, siteId, study } = params;

  useEffect(() => {
    if (reloadMe) {
      dispatch(getMeAction());
    }
  }, [reloadMe]);

  const updateDataSharing = (consent, doctorId) => {
    dispatch(
      updateDataSharingAction({
        data: {
          accepted: consent,
          patientId: patient.id,
          doctorId: doctorId,
        },
        callback: () => { 
          if(nextAction === 'StudyDisclaimer')
            navigation.navigate(nextAction, {siteId, study, callback: updatePatientStudyDisclaimer})
          else
            navigation.navigate(nextAction)
        },
      })
    );
  };

  const updatePatientStudyDisclaimer = (site_id:string) => {
    dispatch(updatePatientSiteAction({site_id, patientId: patient.id, data: {hideDisclaimer: true}}))
    navigation.navigate('Home')
  }

  const logout = () => {
    dispatch(logOutAction());
    navigation.navigate('Welcome');
    navigation.reset({
      index: 0,
      routes: [{ name: 'Welcome' }],
    });
  };

  useEffect(() => {
    if (dataSharingRequests && dataSharingRequests.length > 0) {
      const request = dataSharingRequests.find((req) => req.isActive);
      if (request) {
        setDoctor(request.doctor);
      } 
      else {
        navigation.navigate('Home');
      }
    }
  }, [dataSharingRequests]);

  return (
    <>
      {doctor && 
        <CommonDataSharing doctor={doctor} logout={logout} updateDataSharing={updateDataSharing} />
      }
    </>
  );
};

export default DataSharing;
