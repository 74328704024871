import React, {useState, useEffect} from 'react';
import {View, ScrollView, TextInput, StyleSheet, TouchableOpacity, Platform} from 'react-native';
import validator from 'validator';
import Icon from 'react-native-vector-icons/Feather';
import GlobalStyle from '../styles/GlobalStyles';
import Text from './CommonCustomTxt';
import CommonMaskInput from './CommonMaskInput';
import {APP_SHORTCUT_NAME} from '../env.json';
import CommonImage from './CommonImage';
import {useTranslation} from 'react-i18next';
import CommonDateInput from "./CommonDateInput";
import {DateTime} from 'luxon';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import CommonLabelForm from './CommonAddEvents/CommonLabelForm';

const InputField = (props:any) => {
  const { 
    isAutoFocus,
    isSearchEnabled,
    labelText,
    labelDescription,
    inputType,
    customStyle,
    changeText,
    placeholderTxt,
    value,
    data,
    multiline,
    isValidationRequired,
    changeValidation,
    testID,
    disable,
    isOptional
  } = props;
  const {t} = useTranslation();
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(false);
  const [errorList, setErrorList]: any = useState(props.errorText ? [props.errorText] : []);
  const [inputVisibility, setInputVisibility] = useState(props.inputType !== 'password');
  const [height, setHeight] = useState(PR(62));
  const [searchDropdown, setSearchDropdown] = useState<string>('');

  useEffect(()=> {
    if(props.error !== null && props.error !== undefined)
      setError(props.error)
    if(props.errorText !== null && props.errorText !== undefined)
      setErrorList([props.errorText])
    if(props.validated !== null && props.validated !== undefined)
      setValidated(props.validated)
  }, [props.error, props.errorText, props.validated])

  const updateInput = (text:string) => {
    if (text && inputType === 'number' && validator.isNumeric(text) === false)
      return;
    if (inputType === 'email')
      text = text.trim();
    changeText(text);
    if (isValidationRequired)
      validateInput(text);
    if (displayDropdown)
      setDisplayDropdown(false)
  }

  const validateInput = (text: string) => {
    let _validated = false

    switch (inputType) {
      case 'string' :
        _validated = validator.isAscii(text);
        break;
      case 'number' :
        _validated = validator.isNumeric(text);
        break;
      case 'email' :
        _validated = validator.isEmail(text);
        break;
      case 'cel-phone' :
        _validated = validator.isMobilePhone(text.replace(/ /g, ''));
        break;
      case 'datetime' :
        _validated = validator.isDate(text, {format: 'dd-mm-yyyy'});
        break;
      default :
        _validated = validator.isAscii(text);
    }
  
    const newError = defineErrorMessage()
    const index = errorList.indexOf(newError);
    
    if (!_validated) {
      if (newError && index === -1) {
        let copyErrorList = [...errorList]
        copyErrorList.push(newError);
        setErrorList(copyErrorList)
      }
    }
    else {
      if (newError && index !== -1 ) {
        let copyErrorList = [...errorList]
        copyErrorList.splice(index, 1);
        setErrorList(copyErrorList)
      }
    }
    setValidated(_validated)
    setError(props.error ? props.error : !_validated)
    changeValidation(_validated)
  }

  const defineErrorMessage = () => {
    switch (inputType) {
      case 'email':
        return t('translation:errors.email')
      default:
        return ''
    }
  }

  const checkIconsContainerSize = () => {
    let size = 0;
    if (validated)
      size++;
    if (error)
      size++;
    if (inputType === 'password')
      size++;
    return size;
  }

  const onVisibilityChange = () => {
    setInputVisibility(!inputVisibility);
  }

  const onDropDownLabelClick = () => {
    if (disable)
      return
    setDisplayDropdown(!displayDropdown)
  }
  const renderInput = () => {
    if (inputType === 'string' || inputType === 'password' || inputType === 'email' || inputType === 'number')
      if (disable && Platform.OS === 'android')
        return (
          <ScrollView style={[styles.inputField]} nestedScrollEnabled={true}
            onContentSizeChange={(w, h) => {
                setHeight(h < PR(62) ? PR(62) : h > PR(128) ? PR(128) : PR(h + 20))
              }
            }
          >
            <Text style={{marginVertical: PR(10)}}>{value || placeholderTxt}</Text>
          </ScrollView>
        )
      else {
        let textHeight = height - PR(20) // for padding top and bot
        let numberOfLines = textHeight / PR(14) >= 5 ? 5 : textHeight / PR(14) // 14px is the default line height
        return (
			<TextInput
				onChangeText={updateInput}
				placeholder={placeholderTxt}
				placeholderTextColor={GlobalStyle.global.grey}
				autoCorrect={false}
				style={styles.inputField}
				secureTextEntry={inputType === "password" && !inputVisibility}
				value={value || ""}
				testID={testID}
				editable={!disable}
				selectTextOnFocus={!disable}
				multiline={multiline || false}
				numberOfLines={numberOfLines}
				keyboardType={
					inputType === "number"
						? "number-pad"
						: inputType === "email"
						? "email-address"
						: "default"
				}
				autoFocus={isAutoFocus || false}
				onContentSizeChange={(e) => {
					let realHeight = e.nativeEvent.contentSize.height + PR(20); // for padding as well
					if (
						(realHeight < PR(62) && height !== PR(62)) ||
						(realHeight > PR(128) && height !== PR(128)) ||
						(realHeight > PR(62) &&
							realHeight < PR(128) &&
							realHeight !== height)
					) {
						setHeight(
							realHeight < PR(62)
								? PR(62)
								: realHeight > PR(128)
								? PR(128)
								: PR(realHeight)
						);
					}
				}}
			/>
		);
          }
    if (inputType === 'cel-phone')
      return (
        <CommonMaskInput 
          placeholder={placeholderTxt}
          type={inputType}
          maskType="INTERNATIONAL"
          value={value}
          changeText={updateInput}
        />
      )
    if (inputType === 'datetime')
      return (
        <CommonDateInput
          onChange={updateInput}
          value={value && DateTime.fromISO(value).toFormat('dd-MM-yyyy')}
          fieldStyle={styles.dateInputField}
          textStyle={styles.dateInputFieldText}
          webInputStyle={{
            backgroundColor: "#F2F4F5",
            border: "none",
            width: "100%",
            color: GlobalStyle.global.black,
            fontSize: PR(16),
            fontFamily: GlobalStyle.global.fontFamily.Medium
          }}
        />
      )
    if (inputType === 'time')
      return (
          <CommonMaskInput
              placeholder={placeholderTxt}
              type="datetime"
              maskFormat="HH:mm"
              value={value}
              changeText={updateInput}
              testID={testID}
          />
      )
    if (inputType === 'minutes-and-seconds')
      return (
      <>
        <CommonMaskInput
            placeholder="00"
            type="only-numbers"
            maskFormat="HH"
            value={value?.split(':')?.[0]}
            changeText={(t) => {
              updateInput(`${t}:${value?.split(':')?.[1]}`)
            }}
            testID={testID}
            style={{fontSize: PR(14) ,paddingLeft: PR(10), paddingRight: PR(5), width: Platform.OS === 'web'? PR(45) : undefined}}
        />
        <Text>:</Text>
        <CommonMaskInput
            placeholder="00"
            type="only-numbers"
            maskFormat="mm"
            value={value?.split(':')?.[1]}
            changeText={(t) => {
              updateInput(`${value?.split(':')?.[0]}:${t >= 60 ? 59 : t}`)
            }}
            testID={testID}
            style={{fontSize: PR(14) ,paddingLeft: PR(5)}}
        />
      </>
      )
    if (inputType === 'dropdown')
      return (
        <View style={{flex: 1, flexDirection: 'column', justifyContent: 'space-between'}}>
          <TouchableOpacity testID={"dropdownID"+testID} style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}} onPress={onDropDownLabelClick}>
            {isSearchEnabled && displayDropdown ?
              <InputField 
                inputType="string" 
                changeText={setSearchDropdown}
                placeholderTxt={t('translation:modal.select.filter')} 
                testID="searchDropdown"
                value={searchDropdown}
                isAutoFocus={true}
              />
              :
              <Text style={styles.dropdownValue}>{value || placeholderTxt}</Text>
            }
            {
              displayDropdown ? 
                <Icon name="chevron-up" style={styles.iconBox} size={PR(22)} color={GlobalStyle.global.grey}/>
              :
                <Icon name="chevron-down" style={styles.iconBox} size={PR(22)} color={GlobalStyle.global.grey}/>
            }
          </TouchableOpacity>
        </View>
      )
    if (inputType === 'actionModal')
      return (<></>)
  }

  const renderDropdown = () => (
    <>
    <View style={styles.dropdown}>
      <ScrollView nestedScrollEnabled={true}>
        {
          isSearchEnabled && searchDropdown.length > 0 ?
            data && data.map((item, index) => {
              if(item.label.toLowerCase().indexOf(searchDropdown.toLowerCase()) !== -1)
                return (
                  <TouchableOpacity key={index} style={styles.dropdownTextContainer} onPress={() => updateInput(item.value)} testID={item.value}>
                    <Text style={styles.dropdownText}>{item.label}</Text>
                  </TouchableOpacity>
                )
            })
          :
          data && data.map((item, index) => (
            item.disable ? 
              <View key={index} style={styles.dropdownTextContainer}>
                <Text style={[styles.dropdownText, {opacity: 0.5}]}>{item.label}</Text>
              </View>
            :
              <TouchableOpacity key={index} style={styles.dropdownTextContainer} onPress={() => updateInput(item.value)} testID={item.value}>
                {
                  props.highlightFirstItem && index === 0 ?
                    <Text style={[styles.dropdownText, {fontWeight:'900'}]}>{item.label}</Text>
                  :
                    <Text style={styles.dropdownText}>{item.label}</Text>
                }
                
              </TouchableOpacity>
          ))
        }
      </ScrollView>
    </View>
    </>
  )

  const styles: any = generateStyleSheet(error, errorList, checkIconsContainerSize(), multiline, height);

  return (
    <View style={[customStyle, styles.wrapper]}>
      {labelText && (
        <CommonLabelForm label={labelText} isOptional={isOptional}/>
      )}
      {labelDescription && (
        <Text style={styles.labelDescription}>{labelDescription}</Text>
      )}
      <View style={styles.container}>
        <View style={styles.inputContainer}>
          {
            disable && !multiline ?
                <Text style={styles.inputField}>{value || placeholderTxt}</Text>
            :
              renderInput()
          }
          <View style={styles.iconsContainer}>
            {
              inputType === 'password' && 
                <TouchableOpacity style={styles.iconBox} onPress={onVisibilityChange}>
                  <Icon name={inputVisibility ? 'eye' : 'eye-off'} size={15} color={GlobalStyle[APP_SHORTCUT_NAME].color}/>
                </TouchableOpacity>
            }
            {
              validated &&
                <View style={[styles.iconBox, styles.successIcon]}>
                  <CommonImage name="checkmark_green" height={PR(15)} width={PR(15)}/>
                </View>
            }
            {
              error &&
                <View style={[styles.iconBox, styles.errorIcon]}>
                  <CommonImage name="warning_red" height={PR(15)} width={PR(15)}/>
                </View>
            }
          </View>
        </View>
        {
          error && errorList.length > 0 &&
            <View style={styles.errorBox}>
              {
                errorList.map((errorText, i) => (
                  <Text key={i} numberOfLines={6} style={styles.errorText}>{errorText}</Text>
                ))
              }
            </View>
        }
      </View>
      {
        inputType === 'dropdown' && displayDropdown &&
          renderDropdown()
      }
    </View>
  );
}

const generateStyleSheet = (error, errorList, iconsContainerSize, multiline, height) => {
  return (StyleSheet.create({
    wrapper: {
      width: '90%',
      alignSelf: 'center',
    },
    label: { 
      marginBottom: PR(10),
      marginLeft: PR(20),
      color: GlobalStyle.global.black,
      fontSize: PR(12)
    },
    labelDescription: {
      marginBottom: PR(10),
      color: GlobalStyle.global.black,
      fontSize: PR(12),
      fontFamily: GlobalStyle.global.fontFamily.Light
    },
    container: {
      backgroundColor: '#F2F4F5',
      borderRadius: PR(20),
      borderColor: error ? '#F43535' : '#F2F4F5',
      borderWidth: error ? PR(2) : 0,
      minHeight: error && errorList.length > 0 ? PR(104) : multiline ? height : PR(62)
    },
    inputContainer: {
      flex: 1,
      flexDirection: 'row',
      paddingLeft: PR(10),
      fontSize: PR(16),
      // justifyContent: 'space-between',
      alignItems: 'center',
      height: PR(62)
    },
    inputField: {
      flex: 1,
      flexGrow: 1,
      color: GlobalStyle.global.black,
      marginLeft: PR(10),
      padding: PR(10),
      fontSize: PR(16),
      fontFamily: GlobalStyle.global.fontFamily.Medium
    },
    dropdown: {
      backgroundColor: GlobalStyle.global.lightGrey,
      borderRadius: PR(20),
      marginTop: PR(10),
      paddingBottom: PR(20),
      maxHeight: PR(188),
      justifyContent: 'space-around',
      flexDirection: 'column'
    },
    dropdownTextContainer: {
      marginTop: PR(20),
      marginLeft: PR(20)
    },
    dropdownText: {
      color: GlobalStyle.global.black,
      fontSize: PR(16),
      fontFamily: GlobalStyle.global.fontFamily.Medium
    },
    dropdownValue: {
      padding: PR(10),
      color: GlobalStyle.global.black,
      fontSize: PR(16),
      fontFamily: GlobalStyle.global.fontFamily.Medium,
      textTransform: 'capitalize'
    },
    iconsContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flexGrow: 0.1 * iconsContainerSize,
      marginRight: PR(10)
    },
    iconBox: {
      width: PR(22),
      height: PR(22),
      justifyContent: 'center',
      alignItems: 'center',
      margin: PR(2)
    },
    successIcon: {
    },
    errorIcon: {
    },
    errorBox: {
      borderBottomRightRadius: PR(20),
      borderBottomLeftRadius: PR(20),
      backgroundColor: GlobalStyle.global.lightGrey,
      padding: PR(18),
      minHeight: PR(50)
    },
    errorText: {
      color: '#F43535'
    },
    dateInputField: {
      backgroundColor: '#F2F4F5',
      borderRadius: PR(20),
      borderColor: '#F2F4F5',
      height: PR(62),
      paddingHorizontal: PR(20),
      paddingVertical: PR(20),
      width: '100%',
    },
    dateInputFieldText: {
      fontSize: PR(16),
      fontFamily: GlobalStyle.global.fontFamily.Medium
    }
  })
)};

export default InputField;