import React, {useState, useEffect} from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import GlobalStyles from '../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

type OwnProps = {
    tabBarData: Array<TabBarItems>,
    callback: (selectedValue:string) => void;
}

interface TabBarItems {
    label: string,
    value: string
}

const CommonTabBar = ({tabBarData, callback}: OwnProps) => {
    const [selectedDisplayType, setSelectedDisplayType] = useState<string>(tabBarData[0].value);
    const styles: any = generateStyleSheet(tabBarData.length);

    useEffect(() => {
        if(callback)
            callback(selectedDisplayType)
    },[])

    const updateSelected = (selectedValue:string) => {
        setSelectedDisplayType(selectedValue)
        callback(selectedValue)
    }

    return(
        <View testID='common_tabBar' style={styles.selectContainer}>
            {tabBarData.map((tabBarItem, index) => {
                return(
                    <TouchableOpacity key={index} testID={`${tabBarItem.value}_btn`} style={[styles.selectBox, `${tabBarItem.value}` === selectedDisplayType.toLowerCase() ? {} : {backgroundColor: 'transparent'}]} onPress={() => {updateSelected(tabBarItem.value)}}>
                        <Text style={[styles.selectBoxText, `${tabBarItem.value}` === selectedDisplayType.toLowerCase() ? {color: GlobalStyles.helpi.color} : {color: GlobalStyles.helpi.inactiveColor}]}>{tabBarItem.label}</Text>
                    </TouchableOpacity> 
                )
            })}
        </View>
    )
}

const generateStyleSheet = (arrayLength:number) => {
    return StyleSheet.create({
        selectContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: PR(16),
            backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor,
            borderRadius: PR(25),
            alignItems: 'center',
            alignSelf: 'center',
            width: '90%'
        },
        selectBox: {
            padding: PR(12),
            backgroundColor: GlobalStyles.global.white,
            borderRadius: PR(25),
            margin: PR(3),
            alignItems: 'center',
            flex: 1,
            flexGrow: 1/arrayLength
        },
        selectBoxText: {
            fontFamily: GlobalStyles.global.fontFamily.Bold,
        },
    })
}

export default CommonTabBar;