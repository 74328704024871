import React, {useReducer, useState} from 'react';
import { ScrollView, StyleSheet, View} from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import InputField from 'common/src/components/InputField';
import CommonFooter from 'common/src/components/CommonFooter';
import {useTranslation} from 'react-i18next';
import {checkBottomScroll} from 'common/src/services/utils';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParams } from 'common/src/types';
import { ContactDetailsTypes } from '../../../store/epilepsyDetails/types';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

interface OwnProps {
    route:{
        params:{
            name?: string,
            relationship?:string,
            phone?: string,
            callback: (contact:ContactDetailsTypes) => void;
        }
    }
}

const AddContact = ({route}: OwnProps) => {
    const {
        params: { name, relationship, phone, callback },
      } = route;
    const {t} = useTranslation();
    const navigation = useNavigation<StackNavigationProp<RootStackParams, "Settings">>();
    const [scrollState, setScrollState] = useState();
    const [contentHeight, setContentHeight] = useState<number>();
    const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
    const [contactDetails, setContactDetails] = useReducer(
        (state:ContactDetailsTypes, newState:ContactDetailsTypes) => ({...state, ...newState}),
        {
        name: name || '',
        relationship: relationship || '',
        phone: phone || ''
    })

    /**
     * update the local state according to text input change
     */
    const setName = (text:string) => (
        setContactDetails({
            name: text,
        })
    )
    const setRelationship = (text:string) => (
        setContactDetails({
            relationship: text,
        })
    )
    const setPhone = (text:string) => (
        setContactDetails({
            phone: text,
        })
    )
  
    const saveContact = () => {
        // TODO: save contact person
        const obj = {
            name: contactDetails.name || '',
            relationship: contactDetails.relationship || '',
            phone: contactDetails.phone || ''
        }
        callback(obj)
        navigation.goBack()
    }

    const isDisabled = () => {
      return ( 
            (!contactDetails.name || contactDetails.name.length === 0) || 
            (!contactDetails.phone || contactDetails.phone.length === 0) || 
            (!contactDetails.relationship || contactDetails.relationship.length === 0)
        )
    }
  
    return (
        <CommonKeyboardAvoidingView>
            <CommonHeader
                title={t('translation:general.page_title.add_contact')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {navigation.goBack()}}
                scroll={scrollState}
                testIDTitle="add_contact_title"
            />
            <View style={styles.scrollViewWrapper}>
                <ScrollView 
                    style={styles.scrollview}
                    scrollEventThrottle={16}
                    onScroll={(event: any) => {
                    let calc = checkBottomScroll(event.nativeEvent, 0);
                    if (!scrollAtBottom && calc)
                        setScrollAtBottom(true)
                    else if (scrollAtBottom && !calc)
                        setScrollAtBottom(false)
                    setScrollState(event.nativeEvent.contentOffset.y);
                    }}
                >
                    <View
                    onLayout={(event: any) => {
                        setContentHeight(event.nativeEvent.layout.height)
                    }}
                    >
                    <InputField 
                        labelText={t('translation:general.add_contact.name_label')}
                        inputType="string" 
                        customStyle={{marginBottom:PR(30)}}
                        changeText={setName}
                        placeholderTxt={t('translation:general.add_contact.name_placeholder')} 
                        testID="name"
                        value={contactDetails.name}
                    />
                    <InputField 
                        labelText={t('translation:general.add_contact.relationship_label')}
                        inputType="string" 
                        customStyle={{marginBottom:PR(30)}}
                        changeText={setRelationship}
                        placeholderTxt={t('translation:general.add_contact.relationship_placeholder')} 
                        testID="relationship"
                        value={contactDetails.relationship}
                    />
                    <InputField 
                        labelText={t('translation:general.add_contact.phone_label')}
                        inputType="number" 
                        customStyle={{marginBottom:PR(30)}}
                        changeText={setPhone}
                        placeholderTxt={t('translation:general.add_contact.phone_placeholder')} 
                        testID="phone"
                        value={contactDetails.phone}
                    />
                    </View>
                </ScrollView>
            </View>
            <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
                <CommonBtn 
                    type="primary" 
                    click={saveContact} 
                    title={t('translation:modal.btn.add')} 
                    testID="add_contact_person" 
                    disable={isDisabled()}
                />
            </CommonFooter>
        </CommonKeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    scrollViewWrapper: {
        marginTop: PR(5),
        flex: 1,
    },
    scrollview: {
        paddingTop: PR(20),
        flex:1
    },
})

export default AddContact