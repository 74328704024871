import {call, put, takeLatest, select} from "redux-saga/effects";
import * as types from "../actions";
import {request} from "common/src/services/callService";
import {DELETE_TREATMENT_ACTION, DELETE_TREATMENT_SUCCESS,} from "./treatmentsActions";

export function* makeRequest ({method, path, data, callback, errorCallback, responseAction, successMessage}) {
    try {
        yield put ({ type: types.LOADING, response:true});
        const response = yield call(request, {data, method, path})
        if (response.success) {
            yield put ({ type: types.LOADING, response:false});
            yield put({ type: responseAction, response: data });
            if (successMessage) yield put({ type: types.SUCCESS, message: successMessage });
            if (callback) yield call(callback, response.result);
            return response.result
        } else {
            if (errorCallback) yield call(callback);
            yield put({ type: types.ERROR, response });
            yield put ({ type: types.LOADING, response:false});
        }
    } catch(error:any) {
        if (errorCallback) yield call(callback);
        yield put({ type: types.LOADING, response:false});
        if (error.message === 'Network request failed' || error.message === 'Failed to fetch') {
            yield put({ type: types.ERROR, message: 'translation:errors.network_error' });
        }
    }
}


export function* deleteTreatmentSaga(payload:any) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        data: payload.dataToDelete,
        path: `/patients/${id}/medications/${payload.dataToDelete.id}`,
        method: 'DELETE',
        responseAction: DELETE_TREATMENT_SUCCESS,
        successMessage: 'translation:treatment.add_medication.success.delete_medication',
        ...payload
    })
}

export default function* watchUserAuthentication() {
    yield takeLatest(DELETE_TREATMENT_ACTION, deleteTreatmentSaga);
}