import GlobalStyles from '../../styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { StyleSheet } from 'react-native';
import { APP_SHORTCUT_NAME } from '../../env.json';

const CommonStyle = StyleSheet.create({
    container: {
        borderRadius: PR(20),
        width:'100%',
        alignSelf:'center', 
        marginBottom:PR(8),
        backgroundColor: GlobalStyles.global.white,
        shadowColor: '#333333',
        shadowOffset: {
        width: PR(2),
        height: PR(10),
        },
        shadowOpacity: 0.10,
        shadowRadius: 13.97,
        elevation: 10
    },
    wrapper: {
        flexDirection: 'row',
        minHeight: PR(62),
        padding: PR(10),
        paddingLeft:PR(20),
        width: '100%',
        alignItems:'center'
    },
    textContainer: {
        flex: 1,
        flexGrow: 1,
        marginLeft: PR(15)
    },
    title: {
        fontSize: PR(16),
        color: GlobalStyles.global.black,
        fontFamily: GlobalStyles.global.fontFamily.Medium,
        marginBottom: PR(8)
    },
    icon: {
        alignSelf: "center"
    },
    screenContainer: {
        flex: 1,
        backgroundColor: GlobalStyles.global.white
    },
    actionDescriptionContainer: {
        marginTop: PR(15),
        width: '90%',
        alignSelf: 'center'
    },
    actionDescriptionTxt: {
        fontSize: PR(16),
        color: GlobalStyles.global.black,
        fontFamily: GlobalStyles.global.fontFamily.Regular,
        lineHeight: PR(26)
    },
    scheduleBtn: {
        marginTop: PR(7),
    },
    primaryBtn: {
        marginTop: PR(20),
        marginBottom: PR(35)
    },
    label: { 
        marginBottom: PR(10),
        marginLeft: PR(20),
        color: GlobalStyles.global.black,
        fontSize: PR(12),
        textTransform: 'capitalize'
    },
    summaryContainer: {
        backgroundColor: GlobalStyles.global.footerGrey,
        borderRadius: PR(20),
    },
    inputSummaryContainer: {
        flex: 1,
        flexDirection: 'row',
        minHeight: PR(62),
        padding: PR(10),
        fontSize: PR(16),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inputSummaryValue: {
        flex: 1,
        flexGrow: 1,
        color: GlobalStyles.global.black,
        marginLeft: PR(10),
        fontSize: PR(16),
        fontFamily: GlobalStyles.global.fontFamily.Medium
    }
});

export default CommonStyle;