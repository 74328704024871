import React, { useState, useRef } from 'react';
import {View, StyleSheet, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import Text from "../CommonCustomTxt";
import GlobalStyles from '../../styles/GlobalStyles';
import {useTranslation} from "react-i18next";
import CommonSimpleModal from "../CommonSimpleModal";
import CommonRadioInput from "../Inputs/CommonRadioInput";
import {updatePatient} from "helpilepsy/src/store/user/userActions";
import CommonOtherInput from "../Inputs/CommonOtherInput";
import {useDispatch, useSelector} from "react-redux";
import {getListCMSData} from "../../helpers/translations";
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import {useKeyboard} from '../../services/useKeyboard';
import CommonLabelForm from './CommonLabelForm';

interface CommonRadioFormProps {
    value: string,
    setValue: any,
    cmsListLink?: string,
    patientList: string,
    label?: string,
    patientListData?: Array<string>,
    handleCreateOther?: any,
    handleDeleteOther?: any,
    openModal?: boolean,
    dataList?: Array<any>,
    dataFieldToDisplay?: string,
    displayCmsListLink?: string,
    removeStyle?: boolean,
    customWidth?: string,
    isOptional?: boolean
}

let screenHeight = Dimensions.get('window').height;

const CommonRadioForm = ({value, setValue, cmsListLink, patientList, label, patientListData, handleCreateOther, handleDeleteOther, openModal, dataList, dataFieldToDisplay, displayCmsListLink, removeStyle, customWidth, isOptional}: CommonRadioFormProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isKeyBoardOpen = useKeyboard();
    const scrollRef:any = useRef();
    const [displayModal, setDisplayModal] = useState<boolean>(openModal || false);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const patientData = useSelector((state:any) => state.getMe.patient);
    const cmsValues = getListCMSData(t, cmsListLink);
    const displayCmsValues = displayCmsListLink && getListCMSData(t, displayCmsListLink);
    let selectedValueName = displayCmsValues?.find(i => i.value === value)?.label;
    if (!selectedValueName) selectedValueName = cmsValues?.find(i => i.value === value)?.label || value;
    
    const defineModalMaxHeight = () => {
        if (isKeyBoardOpen)
            return PR(screenHeight * 0.5)
        else
            return PR(screenHeight * 0.75)
    }

    return (
        <>
            {
                displayModal &&
                <CommonSimpleModal
                    title={t('translation:modal.select.title_one')}
                    onClose={()=>setDisplayModal(false)}
                >
                    <View style={[styles.modalContainer, {maxHeight: defineModalMaxHeight()}]}>
                        <ScrollView style={styles.selectWrapper}
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                            ref={scrollRef}
                        >
                            <CommonRadioInput
                                data={dataList ? [] : cmsValues} // if there is data list - don't display cms values
                                objectsData={dataList ? dataList : []}
                                dataFieldToDisplay={dataFieldToDisplay ? dataFieldToDisplay : ''}
                                otherData={patientListData ? patientListData : patientData?.lists?.[patientList]}
                                onSelect={setSelectedValue}
                                selectedValue={selectedValue}
                                onDelete={(item) => {
                                    if (item === value) setValue('')
                                    if (item === selectedValue) setSelectedValue('')
                                    if (handleDeleteOther) {
                                        handleDeleteOther()
                                    } else {
                                        dispatch(updatePatient({
                                            data: {
                                                lists: {
                                                    ...patientData?.lists,
                                                    [patientList]: patientData?.lists?.[patientList]?.filter(i => i !== item)
                                                }
                                            },
                                            patientId: patientData.id,
                                        }))
                                    }
                                }}
                            />
                        </ScrollView>
                        <View style={styles.otherWrapper}>
                            <View style={styles.txtCustomContainer}>
                                <Text style={styles.txtCustomLabel}>{t('translation:modal.select.other')}</Text>
                            </View>
                            <CommonOtherInput
                                onCreateOther={item => {
                                    if (handleCreateOther) {
                                        handleCreateOther(item)
                                        setValue(item)
                                        setSelectedValue(item)
                                    } else {
                                        const newList = patientData?.lists?.[patientList] || []
                                        const findItem = newList.find(fItem => fItem === item)
                                        if(!findItem) {
                                            newList.push(item)
                                            dispatch(updatePatient({
                                                data: {
                                                    lists: {
                                                        ...patientData?.lists,
                                                        [patientList]: newList
                                                    }
                                                },
                                                patientId: patientData.id,
                                                callback: () => {
                                                    setTimeout(() => {
                                                        scrollRef?.current?.scrollToEnd({animated: true})
                                                    }, 100);
                                                }
                                            }))
                                            setValue(item)
                                            setSelectedValue(item)
                                        }
                                    }
                                    
                                }}
                                onSubmit={() => {
                                    setValue(selectedValue)
                                    setDisplayModal(false)
                                    setSelectedValue("")
                                }}
                            />
                        </View>
                    </View>
                </CommonSimpleModal>
            }
            <View style={{width:customWidth ? customWidth : '90%',alignSelf:'center', marginBottom: customWidth ? 0 : PR(30)}}>
                {label &&
                    <CommonLabelForm label={label} isOptional={isOptional}/>
                }   
                <TouchableOpacity testID="seizureTypeBtn" style={removeStyle ? {} : styles.container} onPress={() => {
                    setSelectedValue(value)
                    setDisplayModal(true)
                }}>
                    <View style={removeStyle ? {} : styles.inputContainer}>
                        <Text style={styles.inputValue}>{!value ? t('translation:modal.select.title_one'): selectedValueName}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    label: {
        marginBottom: PR(10),
        marginLeft: PR(20),
        color: GlobalStyles.global.black,
        fontSize: PR(12)
    },
    container: {
        backgroundColor: GlobalStyles.global.footerGrey,
        borderRadius: PR(20),
    },
    inputContainer: {
        flex: 1,
        flexDirection: 'row',
        minHeight: PR(62),
        padding: PR(10),
        fontSize: PR(16),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inputValue: {
        flexGrow: 1,
        color: GlobalStyles.global.black,
        marginLeft: PR(10),
        fontSize: PR(16),
        fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    selectWrapper: {
        maxHeight: '75%',
    },
    otherWrapper: {
    },
    modalContainer: {
        minHeight: PR(75),
    },
    txtCustomContainer: {
        width: '90%', 
        paddingLeft:PR(26), 
        marginBottom:PR(-10)
    },
    txtCustomLabel: {
        fontSize:PR(16),
        color: GlobalStyles.global.greyToBlack
    }
});


export default CommonRadioForm;