import * as types from '../actions';

export default function(state:any = {
    success:[]
  }, action:any = '') {
  const response = action.response;
  switch(action.type) {
    case types.ADD_NEUROLOGIST_SUCCESS:
      return { 
          ...state, 
          success: [...state.success, {id:response.user.id, email:response.user.email}] 
        };
    case types.DELETE_NEUROLOGIST_SUCCESS:
      let restItems = state.success.filter(neuro => neuro.id !== response)
      return {
        ...state,
        success: restItems
      }
    default:
      return state;
  }
}