import {call, takeLatest, select} from "redux-saga/effects";
import {UPDATE_TIMEZONE, UPDATE_TIMEZONE_SUCCESS} from "./changeTimezoneActions";
import {makeRequest} from "../../services/requestService";

export function* updateTimeZoneSaga(payload:any) {
    
    yield call(makeRequest, {
        data: payload.dataToUpdate,
        path: `/meApp`,
        method: 'PUT',
        responseAction: UPDATE_TIMEZONE_SUCCESS,
        ...payload
    })
}

export default function* watchUserAuthentication() {
    yield takeLatest(UPDATE_TIMEZONE, updateTimeZoneSaga);
}