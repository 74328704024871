import React from 'react';
import {View, StyleSheet, TouchableOpacity, Platform, Linking} from 'react-native';
import GlobalStyles from '../styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import Text from './CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../env.json';
import {countReportCreated} from '../services/compare';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';

interface ReportTypes {
  start: string,
  end: string,
  created: string,
  downloadLink: string,
  testID?: string
}

const CommonReport = ({start, end, created, downloadLink,testID}: ReportTypes) => {
  const {t} = useTranslation();

  const download = (href) => {
    Platform.OS === 'web' ? window.open(href, '_blank') : Linking.openURL(href)
  }
  
  return(
    <View testID={testID} style={styles.reportContainer}>
      <View style={styles.reportContentContainer}>
        <View style={{flexDirection: 'row', flex:1, width:'90%'}}>
          <View style={{marginRight:PR(20)}}>
            <Icon name='file' size={PR(35)} color={GlobalStyles[APP_SHORTCUT_NAME].color} />
          </View>
          <View style={{flexDirection:'column'}}>
            <Text style={styles.dateTxt}>{start} - {end}</Text>
            <Text style={styles.createdTxt}>{t('translation:report.global.created')} {countReportCreated(created,DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy'), 'dd-MM-yyyy',t)}</Text>
          </View>
        </View>
        <View style={{alignSelf:'center'}}>
          <TouchableOpacity 
            onPress={() => download(downloadLink)} 
            testID={'downloadLinkID'} 
            disabled={!downloadLink}
            style={
              {
                flexDirection: 'row', 
                alignItems:'center', 
                marginTop: PR(20),
                opacity: !downloadLink && Platform.OS === 'android' ? 0.4 : !downloadLink && Platform.OS !== 'android' ? 0.2 : 1
              }
            }
          >
            <Icon name='download' size={PR(25)} color={GlobalStyles[APP_SHORTCUT_NAME].btnColor}/>
            <Text style={styles.linkTxt}>{t('translation:report.global.download_pdf')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  reportContainer: {
    backgroundColor: GlobalStyles.global.footerGrey,
    borderRadius: PR(20),
    width:'90%',
    justifyContent:'center',
    alignSelf: 'center'
  },
  reportContentContainer: {
    flex: 1,
    flexDirection: 'column',
    minHeight: PR(62),
    padding: PR(10),
    fontSize: PR(16),
    // justifyContent: 'space-between',
    alignSelf: 'center',
  },
  dateTxt: {
    fontSize:PR(18),
    fontFamily: GlobalStyles.global.fontFamily.Bold,
    color: GlobalStyles.global.black
  },
  createdTxt: {
    fontSize:PR(16),
    color: GlobalStyles.global.black
  },
  linkTxt: {
    fontSize: PR(16),
    color: GlobalStyles[APP_SHORTCUT_NAME].btnColor,
    fontFamily: GlobalStyles.global.fontFamily.Bold,
    marginLeft: PR(10)
  }
})

export default CommonReport;