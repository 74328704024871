import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { EducationTrackStatus } from "../../types";
import CommonCustomTxt from "../CommonCustomTxt";
import { widthPercentageToDP as PR } from "common/src/styles/PixelRatio";
import CommonProgressIcon from './CommonProgressIcon';

type Status = EducationTrackStatus | string;

interface OwnProps {
  status: Status;
  color: string;
  testID: string;
}

const CommonStatusLabel = ({color, status, testID}: OwnProps) => {
  const { t } = useTranslation();
  let statusText = '';

  if (status === 'in_progress') {
    statusText = t("translation:general.programs.education.in_progress");
  } else if (status === "completed") {
    statusText= t("translation:general.programs.education.completed");
  } else {
    statusText = t("translation:general.programs.education.not_started");
  }

  return (
  <View style={Style.container} testID={testID}>
    <View>
      <CommonProgressIcon color={color} status={status}/>
    </View>
    <CommonCustomTxt style={Style.text}>
      {statusText}
    </CommonCustomTxt>
  </View>);
};

const Style = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  text: {
    fontSize: PR(12),
    paddingHorizontal: PR(10),
  },
});

export default CommonStatusLabel;
