import moment from "moment"

export const seperateFuturePast = (appointments) => {
    const past = appointments.filter(appointment => moment(appointment.date).isBefore(moment()))
    const future = appointments.filter(appointment => moment(appointment.date).isAfter(moment()))

    future.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
    past.sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))

    return {past, future}
}