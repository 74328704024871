export const SEND_TOKEN_ACTION = 'SEND_TOKEN_ACTION';
export const SEND_TOKEN_SUCCESS = 'SEND_TOKEN_SUCCESS';

export const REVOKE_TOKEN_ACTION = 'REVOKE_TOKEN_ACTION';
export const REVOKE_TOKEN_SUCCESS = 'REVOKE_TOKEN_SUCCESS';

export const sendFbTokenAction = (payload:any) => ({
        type: SEND_TOKEN_ACTION,
        ...payload
    }
);

export const revokeFbTokeAction = (payload:any) => ({
    type: REVOKE_TOKEN_ACTION,
    ...payload
});