import React, {useState, useEffect} from 'react';
import {StyleSheet, View, TouchableOpacity, TextInput} from 'react-native';
import Text from '../../CommonCustomTxt';
import CommonActionModal from '../../CommonActionModal';
import GlobalStyles from '../../../styles/GlobalStyles';
import { useTranslation } from 'react-i18next';
import {translate} from '../../../services/translationList';
import {widthPercentageToDP as PR} from '../../../styles/PixelRatio';

const Other = ({activateBtn, getData, savedOtherData}) => {
  const {t} = useTranslation();
  const [every, setEvery] = useState<string>(savedOtherData?.every || '');
  const [timeline, setTimeline] = useState<string>(savedOtherData?.otherPeriod || 'month');
  const [displayTimelineModal, setTimelineModal] = useState<boolean>(false);

  useEffect(() => {
    let frequency = ''
    if(parseInt(every) > 0){
      activateBtn(false)
      frequency = checkFrequency()
      let obj = {every: every, timeline: timeline, frequency, otherPeriod: timeline }
      getData(obj)
    } else {
      activateBtn(true)
    }
  },[every,timeline])

  const checkFrequency = () => {
    if(parseInt(every) === 1 && timeline === 'month')
      return 'monthly'
    else if(parseInt(every) === 3 && timeline === 'month')
      return 'quarterly'
    else  
      return 'other'
  }

  // update timeline selection
  const updateTimeline = (values) =>{
    values.map((item:any) => {
      if(item.selected !== undefined && item.selected){
        setTimeline(JSON.parse(item.value.replace(/[\[\]']+/g,'')))
      }
    })
    setTimelineModal(!displayTimelineModal)
  }

  // close modal
  const changeModalVisibility = () => {
    setTimelineModal(!displayTimelineModal)
  }
  return(
    <>
      { displayTimelineModal &&
        <CommonActionModal 
          testID={"unitModalID"}
          title={t('translation:modal.select.title_one')} 
          modalType={'select-radio'} 
          buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: updateTimeline, action: 'save'}]} 
          onClose={changeModalVisibility} 
          // @ts-ignore
          data={t<string | TemplateStringsArray>('translation:list.periodFrequencies', {returnObjects: true}).map((name,i) => {
            return {label:t('translation:list.periodFrequencies.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)), position: i}
          })}
        />
      }
      <View style={styles.questContainer}>
        <Text style={styles.questTxt}>{t('translation:treatment.add_medication.step_frequency.custom.question')}</Text>
        <View style={{marginBottom:PR(15)}}></View>
        <View  style={styles.momentsContainer}>
          <View style={{width: '40%', alignItems:'center'}}>
            <Text style={styles.momentsTxt}>{t('translation:treatment.add_medication.step_frequency.custom.timesPer')}</Text>
          </View>
          <View style={{width:'30%', alignItems:'center'}}>
            <TextInput
                onChangeText={(e) => setEvery(e.trim().replace(/[^0-9]/g, ''))}
                placeholder={t('translation:treatment.add_medication.step_frequency.custom.everyPlaceholder')}
                placeholderTextColor={GlobalStyles.global.grey}
                autoCorrect={false}
                style={styles.inputField}
                value={every}
                keyboardType="numeric"
                testID={"everyID"}
            />
          </View>
          <TouchableOpacity onPress={() => setTimelineModal(!displayTimelineModal)} style={{width:'30%', alignItems:'center'}}>
            <Text style={styles.momentsTxt}>{translate(t,timeline,'list.periodFrequencies')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  questContainer: {
    paddingTop: PR(20),
    width: '90%',
    alignItems: 'center',
    alignSelf:'center'
  },
  questTxt: {
    fontSize: PR(16),
    color: GlobalStyles.global.black
  },
  momentsContainer: {
    flexDirection:'row', 
    width:'90%', 
    alignItems:'center', 
    alignSelf:'center', 
    justifyContent:'space-between', 
    paddingVertical:PR(10),
    borderBottomWidth: PR(1),
    borderBottomColor: GlobalStyles.global.lightGrey
  },
  momentsTxt: {
    fontSize: PR(14),
    color: GlobalStyles.global.black
  },
  inputField: {
    flex: 1,
    flexGrow: 1,
    color: GlobalStyles.global.black,
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
})

export default Other;