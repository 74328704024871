import React from "react";
import { TouchableOpacity, View } from "react-native";
import { EducationModuleChapter } from "../../../types";
import CommonCustomTxt from "../../CommonCustomTxt";
import CommonStyle from "../CommonStyle";
import Icon from "react-native-vector-icons/Feather";
import GlobalStyles from "../../../styles/GlobalStyles";
import { widthPercentageToDP as PR } from "common/src/styles/PixelRatio";

interface OwnProps {
    chapter: EducationModuleChapter;
    language: string;
    handleClick: () => void;
    StatusElement: JSX.Element;
}

const CommonEducationChapterItem = ({
    chapter,
    language,
    handleClick,
    StatusElement,
  }: OwnProps) => (
    <TouchableOpacity
      style={CommonStyle.container}
      onPress={handleClick}
      testID={`education-chapter-container-${chapter.index}`}
    >
      <View style={CommonStyle.wrapper}>
        <View style={CommonStyle.textContainer}>
          <CommonCustomTxt
            numberOfLines={1}
            style={CommonStyle.title}
            testID="education-chapter-title"
          >
            {chapter.title[language] ?? chapter.title["en"]}
          </CommonCustomTxt>
        </View>
        <Icon
          style={CommonStyle.icon}
          name="chevron-right"
          size={PR(25)}
          color={GlobalStyles.global.grey}
        />
      </View>
      <View style={{marginLeft: PR(35), marginBottom: PR(10), width: '100%'}}>
          {StatusElement}
      </View>
    </TouchableOpacity>
  );

export default CommonEducationChapterItem;
