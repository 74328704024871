export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';

export const POST_REPORTS = 'POST_REPORTS';
export const POST_REPORTS_SUCCESS = 'POST_REPORTS_SUCCESS';

interface PostReportsTypes{
    startDate: string,
    endDate: string,
    navigation: any
}

export const getReportsAction = () => ({
        type: GET_REPORTS,
    }
);

export const postReportsAction = (payload:PostReportsTypes) => ({
    type: POST_REPORTS,
    ...payload
}
);