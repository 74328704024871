import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';
import CommonRadioBtn from './CommonRadioBtn';
import CommonNoNeuro from './CommonNoNeuro';
import CommonYesNeuro from './CommonYesNeuro';
import GlobalStyle from '../styles/GlobalStyles';
import Text from './CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';


const CommonNeuroStep = (props:any) => {
  const {t} = useTranslation();
  const [answer, setAnswer] = useState<string>('')
  // send the obj to parent component
  const sendBackAnswer = (obj:any) => {
    props.nextTriggered(obj)
  }
  return (
    <>
      <View style={styles.container}>
        <Text renderAsHtml={true} style={styles.txt}>{t('translation:login.prescription')}</Text>
        <View style={styles.radioContainer}>
          <CommonRadioBtn 
            answers={[
              {name:'Yes',value:t('translation:modal.btn.yes')},
              {name:'No',value:t('translation:modal.btn.no')}
            ]} 
            updateAnswer={setAnswer}
          />
        </View>
      </View>
      <View style={styles.childrenContainer}>
      {answer === '' ?
        <Text></Text>: answer === 'No'? 
        <CommonNoNeuro nextAction={sendBackAnswer} />:
        <CommonYesNeuro nextAction={sendBackAnswer} />
      }
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '90%',
    alignItems:'flex-start',
    alignSelf: 'center',
    borderBottomWidth: PR(1),
    borderColor:'#EBEBEB',
    marginBottom: PR(15),
  },
  radioContainer: {
    paddingVertical: PR(25),
  },
  txt: {
    color: GlobalStyle.global.greyToBlack
  },
  childrenContainer: {
    marginBottom: PR(30)
  }
})

export default CommonNeuroStep;