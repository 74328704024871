import React, {useState} from 'react';
import {View, StyleSheet, SafeAreaView, ScrollView, Platform} from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonDateInput from 'common/src/components/CommonDateInput';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonSubTitle from 'common/src/components/CommonSubtitle';
import Icon from 'react-native-vector-icons/Feather';
import Text from 'common/src/components/CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {DateTime} from 'luxon';
import { compareDates, isDatesLessThanNinety, isMoreThanOneYear } from 'common/src/services/compare';
import { useDispatch } from 'react-redux';
import { postReportsAction } from '../store/reports/reportsActions';

const GenerateReport = ({navigation}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [scrollState, setScrollState] = useState();
  const [startDate, setStartDate] = useState<string>(DateTime.now().minus({days:90}).toFormat('dd-MM-yyyy'))
  const [endDate, setEndDate] = useState<string>(DateTime.now().toFormat('dd-MM-yyyy'))

  const generateReport = () => {
    // TODO send request to generate new report
    let obj = {
      startDate: DateTime.fromFormat(startDate, 'dd-MM-yyyy').toFormat('yyyy-MM-dd'),
      endDate: DateTime.fromFormat(endDate, 'dd-MM-yyyy').toFormat('yyyy-MM-dd'),
      navigation
    }
    dispatch(postReportsAction(obj))
  }

  const changeStartDate = (inputDate) => {
    if(DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      inputDate = DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setStartDate(inputDate)
    }
  }

  const changeEndDate = (inputDate) => {
    if(DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      inputDate = DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setEndDate(inputDate)
    }
  }

  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => {
    if(startDate === undefined) return true
    else if (endDate !== undefined && compareDates(startDate, endDate, 'dd-MM-yyyy')) return true
    else if (endDate !== undefined && isDatesLessThanNinety(startDate, endDate, 'dd-MM-yyyy')) return true
    else if (endDate !== undefined && isMoreThanOneYear(startDate, endDate, 'dd-MM-yyyy')) return true
    else return false
  }

  return(
    <SafeAreaView style={styles.wrapper}>
      <CommonHeader
        title={t('translation:report.globals.title.generate_report')} 
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
        testIDTitle="genarate_reports_title"
        scroll={scrollState}
      />
      <ScrollView 
        testID="generate_reports_scrollView"
        scrollEventThrottle={16}
        onScroll={(event: any) => {
          setScrollState(event.nativeEvent.contentOffset.y);
        }}
      >
        <View style={{width:'90%', alignSelf:'center', marginBottom:PR(20)}}>
          <View style={styles.IconRow}>
            <View style={styles.Icon}>
              <View style={styles.titleIcon}>
                <Icon name="file" size={PR(40)} color={GlobalStyles.helpi.color}/>
              </View>
            </View>
          </View>
          <CommonSubTitle 
            text={t('translation:report.generate.subtitle')}
          />
        </View>
        <View testID='startDateID' style={styles.dateInput}>
          <View style={styles.dateInputLabel}>
            <Text style={styles.dateInputLabelText}>{t('translation:treatment.add_medication.step_period.step_1.answer')}</Text>
          </View>
          <CommonDateInput
            onChange={d => changeStartDate(d)}
            value={startDate}
            fieldStyle={styles.dateInputField}
            textStyle={styles.dateInputFieldText}
            maxDate={new Date(Date.now() - 7776000000)}
            webInputStyle={{backgroundColor: "#F2F4F5", fontFamily: GlobalStyles.global.fontFamily.Bold, color: GlobalStyles.global.black, border: "none", width: "100%"}}
            openPicker={false}
          />
        </View>
        <View testID='endDateID' style={styles.dateInput}>
          <View style={styles.dateInputLabel}>
            <Text style={styles.dateInputLabelText}>{t('translation:treatment.add_medication.step_period.step_2.answer')}</Text>
          </View>
          <CommonDateInput
            onChange={d => changeEndDate(d)}
            value={endDate}
            fieldStyle={styles.dateInputField}
            textStyle={styles.dateInputFieldText}
            maxDate={new Date()}
            webInputStyle={{backgroundColor: "#F2F4F5", fontFamily: GlobalStyles.global.fontFamily.Bold, color: GlobalStyles.global.black, border: "none", width: "100%"}}
            openPicker={false}
          />
        </View>
        {startDate !== undefined && endDate !== undefined && compareDates(startDate, endDate,'dd-MM-yyyy') &&
          <Text style={styles.errorTxt}>{t('translation:treatment.general.bad_end_date')}</Text>
        }
        {startDate !== undefined && endDate !== undefined && isDatesLessThanNinety(startDate, endDate,'dd-MM-yyyy') &&
          <Text style={styles.errorTxt}>{t('translation:errors.minimum_report_duration')}</Text>
        }
        {startDate !== undefined && endDate !== undefined && isMoreThanOneYear(startDate, endDate,'dd-MM-yyyy') &&
          <Text style={styles.errorTxt}>{t('translation:errors.maximum_report_duration')}</Text>
        }
      </ScrollView>
      <CommonBtn 
        type="primary" 
        click={generateReport} 
        title={t('translation:report.btn.generate_new_report')} 
        testID="generate_report"
        disable={checkDisable()}
      />
      <View style={{marginBottom: PR(15)}}></View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: GlobalStyles.global.white,
    flex: 1,
  },
  dateInput: {
    width:'90%',
    alignSelf:'center',
    marginBottom: PR(30)
  },
  dateInputLabel: {
    marginBottom: PR(10),
    marginLeft: PR(20),
  },
  dateInputLabelText: {
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  dateInputField: {
    backgroundColor: '#F2F4F5',
    borderRadius: PR(20),
    borderColor: '#F2F4F5',
    height: PR(62),
    paddingHorizontal: PR(20),
    paddingVertical: PR(20),
  },
  dateInputFieldText: {
    color: GlobalStyles.global.black,
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  errorTxt: {
    color: GlobalStyles.global.redError,
    textAlign: 'center'
  },
  IconRow: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:PR(20)
  },
  titleIcon: {
    alignSelf:'center', 
    borderWidth:PR(2), 
    borderRadius:PR(30), 
    borderColor:GlobalStyles.global.lightGrey, 
    backgroundColor: GlobalStyles.global.lightGrey,
    padding: PR(7)
  },
  Icon: {
    position: 'relative',
  },
});

export default GenerateReport;