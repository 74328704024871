import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import Text from './CommonCustomTxt';
import CommonBtn from './CommonBtn';
import GlobalStyles from '../styles/GlobalStyles';
import { useTranslation } from 'react-i18next';
import { FORM_STATUS_DONE, FORM_STATUS_NOT_YET, FORM_STATUS_OVER, FORM_STATUS_SENT, FORM_STATUS_WITHDRAWN} from '../types'
import moment from 'moment';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import { convertLangCode } from '../services/translationList';

const CommonQuestionnairesDropdownList = ({navigation, navigationParams, isExpanded, updateDisplayList, index, type, displayType, data, color, siteId, saveForm}) => {
    const {t, i18n} = useTranslation();
    const { language: userLanguage } = i18n;
    const forms = data.forms;

    const updateDisplay = () => {
        updateDisplayList(index)
    }

    const openForm = (questionnaire) => {
        navigation.navigate('StudyForm', {questionnaire: questionnaire, patientId: navigationParams.patientId, siteId: siteId, saveForm});
    }

    const renderListItem = (item) => {
        const questionnaire = item;
        const disable = questionnaire.status === FORM_STATUS_NOT_YET || questionnaire.status === FORM_STATUS_OVER || questionnaire.status === FORM_STATUS_WITHDRAWN ? true : false;
        const btnType = questionnaire.status === FORM_STATUS_NOT_YET ? 'primary' : 'secondary';
        let btnColor = color
        let btnTxtColor = ''
        
        if (questionnaire.status === FORM_STATUS_NOT_YET) {
            btnColor = GlobalStyles.global.lightGrey
            btnTxtColor = GlobalStyles.global.greyToBlack
        }
        else if (questionnaire.status === FORM_STATUS_OVER || questionnaire.status === FORM_STATUS_WITHDRAWN)
            btnColor = GlobalStyles.global.redError;
        const questionnaireTitle = questionnaire.title[userLanguage] || questionnaire.title.en;
        return (
            <View style={styles.itemContainer}>
                <Text renderAsHtml={true} style={{flex: 1, flexGrow: 0.5, padding: PR(2)}}>{questionnaireTitle}</Text>
                <View style={{flex: 1, flexGrow: 0.2, padding: PR(2), alignItems: 'center'}}>
                    <Text>{moment(questionnaire.sentDate).locale(convertLangCode(userLanguage) || 'en').format('LL')}</Text>
                </View>
                <View style={{flex: 1, flexGrow: 0.3, padding: PR(2)}}>
                    <CommonBtn size='small' txtColor={btnTxtColor} type={btnType} title={t(`translation:${displayFormStatus(questionnaire.status)}`)} click={() => openForm(questionnaire)} disable={disable} color={btnColor}/>
                </View>
            </View>
        )
    }

    const displayFormStatus = (status) => {
        if (status === FORM_STATUS_DONE)
            return  "studies.formStatuses.done";
        else if (status === FORM_STATUS_SENT)
            return "studies.formStatuses.sent";
        else if (status === FORM_STATUS_NOT_YET)
            return "studies.formStatuses.not_yet"
        else if (status === FORM_STATUS_WITHDRAWN)
            return "studies.formStatuses.withdrawn"
        else
            return "studies.formStatuses.over"
    }

    return (
        <View style={{width: '90%', alignSelf: 'center'}}>
            <TouchableOpacity onPress={updateDisplay} style={[styles.titleContainer, {backgroundColor: color, borderRadius: isExpanded ? 0 : PR(5)}]}>
                <Text renderAsHtml={true} style={styles.title}>{displayType === 'text' ? ( data.title[userLanguage] || data.title.en ): (data.patientStepName?.[userLanguage] || data.patientStepName?.en)}</Text>
                { 
                    type === 'forms' &&
                        <View style={{marginRight: PR(5)}}>
                            <Text renderAsHtml={true} style={styles.titleDate}>{t('translation:studies.questionnaires.from')}</Text>
                            <Text style={styles.titleDate}>{moment(data.stepDate).locale(convertLangCode(userLanguage) || 'en').format('ll')}</Text>
                        </View>
                }
                {
                    isExpanded ? 
                        <Icon name={'chevron-down'} size={PR(20)} color={GlobalStyles.global.white}/>
                        :
                        <Icon name={'chevron-right'} size={PR(20)} color={GlobalStyles.global.white}/>
                }
            </TouchableOpacity>
            {
                isExpanded &&
                    <View style={{borderWidth: PR(1), borderColor: GlobalStyles.global.lightGrey, borderRadius: isExpanded ? 0 : PR(5), borderBottomRightRadius: PR(5), borderBottomLeftRadius: PR(5), paddingHorizontal: PR(5), paddingVertical: PR(5)}}>
                        {
                            displayType === 'text' ? 
                                <Text renderAsHtml={true} style={{fontSize:PR(14)}}>{data.text[userLanguage] || data.text.en}</Text>
                            :
                                forms.map(f => {
                                    return renderListItem(f)
                                })
                        }
                    </View>
            }
        </View>
    )
}

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderTopRightRadius: PR(5),
        borderTopLeftRadius: PR(5),
        padding: PR(15),
        minHeight: PR(60)
    },
    title: {
        flex: 1,
        color: GlobalStyles.global.white,
        fontFamily: GlobalStyles.global.fontFamily.Bold,
        fontSize: PR(16),
        marginRight: PR(15)
    },
    titleDate: {
        flex: 1,
        alignSelf: 'flex-end',
        color: GlobalStyles.global.white
    },
    itemContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: GlobalStyles.global.white,
        borderColor: GlobalStyles.global.lightGrey,
        paddingVertical: PR(5)
    }
})

export default CommonQuestionnairesDropdownList;