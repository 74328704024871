import {call, put, takeLatest} from "redux-saga/effects";
import {GET_DRUGS, GET_DRUGS_SUCCESS} from "./drugsActions";
import {request} from "common/src/services/callService";
import * as types from "../../store/actions";

function* makeRequest ({method, path, data, callback, errorCallback, responseAction}) {
    try {
        yield put ({ type: types.LOADING, response:true});
        const response = yield call(request, {data, method, path})
        yield put ({ type: types.LOADING, response:false});
        if (callback) yield call(callback, response);
        if (responseAction) yield put({ type: responseAction, response });
        return response
    } catch(error:any) {
        if (errorCallback) yield call(callback);
        yield put({ type: types.LOADING, response:false});
        if (error.message === 'Network request failed' || error.message === 'Failed to fetch') {
            yield put({ type: types.ERROR, message: 'translation:errors.network_error' });
        }
    }
}
export function* getDrugsSaga(payload:any) {
    yield call(makeRequest, {
        path: `/drugs`,
        method: 'GET',
        responseAction: GET_DRUGS_SUCCESS,
        ...payload
    })
}

export default function* watchUserAuthentication() {
    yield takeLatest(GET_DRUGS, getDrugsSaga);
}