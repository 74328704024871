import React from 'react';
import {StyleSheet, View} from 'react-native';
import CommonImage from 'common/src/components/CommonImage';
import Text from 'common/src/components/CommonCustomTxt';
import CommonTitle from 'common/src/components/CommonTitle';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const EmptyVideos = () => {
  const {t} = useTranslation();
  return (
  <>
    <View style={styles.container}>
      <View style={styles.imgContainer}> 
        <CommonImage name={'novideos'} height={PR(310)} width={PR(250)}/>
      </View>
      <View style={styles.txtContainer}>
        <CommonTitle text={t('translation:settings.empty_videos.title')} color={GlobalStyles.global.black} size={PR(18)}/>
        <Text style={styles.bodyTxt}>{t('translation:settings.empty_videos.text_1')}</Text>
        <Text style={styles.bodyTxt}>{t('translation:settings.empty_videos.text_2')}</Text>
      </View>
    </View>
  </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: PR(20),
  },
  imgContainer: {
    alignSelf: 'center',
    paddingRight:PR(20)
  },
  txtContainer: {
    paddingTop: PR(40),
    alignItems:'center'
  },
  bodyTxt: {
    fontSize: PR(16)
  },
})

export default EmptyVideos;