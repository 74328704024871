import { call, takeLatest, select, put } from "redux-saga/effects";
import { makeRequest } from '../../services/requestService';
import { EpilepsyDetailsActions } from "./epilepsyDetailsActions";
import { UPDATE_EVENT_SUCCESS,} from "../events/eventActions";
import { AnyAction } from "redux";
import { 
    SeizureDescriptionCardTypes, 
    SeizureAntidoteCardTypes, 
    TriggersModelCardTypes, 
    GetPatientEvent,
    DeleteAppointmentChecklistTypes,
    DeleteDescriptionTypes
} from './types';

export function* getSeizureDescriptionCardSaga() {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    const response = yield call(makeRequest, {
        path: `/patients/${id}/getseizuredescription`,
        method: 'GET',
    })
    if(response){
        yield put({ type: EpilepsyDetailsActions.GET_SEIZURES_DESCRIPTION_CARD_SUCCESS, response: response });
    }
}

export function* addUpdateSeizureDescriptionCardSaga(payload: AnyAction & SeizureDescriptionCardTypes) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        path: `/patients/${id}/addseizuredescription`,
        method: 'PUT',
        responseAction: EpilepsyDetailsActions.ADD_UPDATE_SEIZURES_DESCRIPTION_CARD_SUCCESS,
        successMessage: payload.successMsg,
        data: {
            seizureDescription: {
                description: payload.description,
                myExperience: payload.myExperience,
                yourExperience: payload.yourExperience,
                yourHelp: payload.yourHelp,
                contactDetails: payload.contactDetails
            }
        },
        ...payload
    });
}

export function* getSeizureAntidoteCardSaga() {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    const response = yield call(makeRequest, {
        path: `/patients/${id}/getSeizureAntidote`,
        method: 'GET',
    })
    if(response){
        yield put({ type: EpilepsyDetailsActions.GET_SEIZURES_ANTIDOTE_CARD_SUCCESS, response: response });
    }
}

export function* addUpdateSeizureAntidoteCardSaga(payload: AnyAction & SeizureAntidoteCardTypes) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        path: `/patients/${id}/addupdateseizureantidote`,
        method: 'PUT',
        responseAction: EpilepsyDetailsActions.ADD_UPDATE_SEIZURES_ANTIDOTE_CARD_SUCCESS,
        successMessage: payload.successMsg,
        data: {
            seizureAntidote: {
                description: payload.description,
            }
        },
        ...payload
    });
}

export function* getPatientEventSaga(payload: AnyAction & GetPatientEvent) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        path: `/patients/${id}/events/${payload.eventType}`,
        method: 'POST',
        data: {},
        responseAction: EpilepsyDetailsActions.GET_PATIENT_EVENT_SUCCESS,
    })
}

export function* getTriggersModelCardSaga() {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    const response = yield call(makeRequest, {
        path: `/patients/${id}/gettriggersmodel`,
        method: 'GET',
    })
    if(response){
        yield put({ type: EpilepsyDetailsActions.GET_TRIGGERS_MODEL_CARD_SUCCESS, response: response });
    }
}

export function* addUpdateTriggersModelCardSaga(payload: AnyAction & TriggersModelCardTypes) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        path: `/patients/${id}/addupdatetriggersmodel`,
        method: 'PUT',
        responseAction: EpilepsyDetailsActions.ADD_UPDATE_TRIGGERS_MODEL_CARD_SUCCESS,
        successMessage: payload.successMsg,
        data: {
            triggersModel: {
                longTermExternal: payload.longTermExternal,
                longTermInternal: payload.longTermInternal,
                shortTermExternal: payload.shortTermExternal,
                shortTermInternal: payload.shortTermInternal,
                combinationOfStressfulConditions: payload.combinationOfStressfulConditions,
                seizureInducingReactions: payload.seizureInducingReactions
            }
        },
        ...payload
    });
}

export function* deleteAppointmentChecklistSaga(payload: AnyAction & DeleteAppointmentChecklistTypes) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        path: `/patients/${id}/events/${payload.eventId}/appointment_checklist`,
        method: 'PUT',
        data: {old_date: payload.old_date},
        responseAction: UPDATE_EVENT_SUCCESS,
        ...payload
    })
}

export function* deleteDescriptionCardsSaga(payload: AnyAction & DeleteDescriptionTypes) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        path: `/patients/${id}/${payload.cardUrl}`,
        method: 'PUT',
        responseAction: EpilepsyDetailsActions[payload.cardAction],
        successMessage: payload.successMsg,
        data: {
            [payload.cardName]: null
        },
        ...payload
    });
}

export default function* watch() {
    yield takeLatest(EpilepsyDetailsActions.GET_SEIZURES_DESCRIPTION_CARD, getSeizureDescriptionCardSaga);
    yield takeLatest(EpilepsyDetailsActions.ADD_UPDATE_SEIZURES_DESCRIPTION_CARD, addUpdateSeizureDescriptionCardSaga);
    yield takeLatest(EpilepsyDetailsActions.GET_SEIZURES_ANTIDOTE_CARD, getSeizureAntidoteCardSaga);
    yield takeLatest(EpilepsyDetailsActions.ADD_UPDATE_SEIZURES_ANTIDOTE_CARD, addUpdateSeizureAntidoteCardSaga);
    yield takeLatest(EpilepsyDetailsActions.GET_TRIGGERS_MODEL_CARD, getTriggersModelCardSaga);
    yield takeLatest(EpilepsyDetailsActions.ADD_UPDATE_TRIGGERS_MODEL_CARD, addUpdateTriggersModelCardSaga);
    yield takeLatest(EpilepsyDetailsActions.GET_PATIENT_EVENT, getPatientEventSaga);
    yield takeLatest(EpilepsyDetailsActions.DELETE_APPOINTMENT_CHECKLIST, deleteAppointmentChecklistSaga);
    yield takeLatest(EpilepsyDetailsActions.DELETE_DESCRIPTION, deleteDescriptionCardsSaga);
}