import React from "react";
import { View, TouchableOpacity } from "react-native";
import Text from 'common/src/components/CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import { styles } from "./styles";
import {DateTime} from 'luxon';
import GlobalStyles from "../../../styles/GlobalStyles";
import {widthPercentageToDP as PR} from '../../../styles/PixelRatio';

const VideoItem = ({video, deleteVideo, setVideoUrl, isDisabled}) => {
    return (
        <View style={[styles.videoItem, isDisabled ? {backgroundColor: GlobalStyles.global.white, opacity: 0.3} : {}]}>
            <Icon style={styles.itemIcon} name="film" size={PR(25)} color={GlobalStyles.global.black}/>
            <Text>{DateTime.fromISO(video.createdAt, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('dd/MM/yyyy - HH:mm')}</Text>
            <TouchableOpacity style={{marginLeft: "auto", marginRight: PR(10)}} onPress={() => deleteVideo(video)} disabled={isDisabled}>
            <Icon name="trash-2" size={PR(25)} color={GlobalStyles.global.redError}/>
            </TouchableOpacity>
            <TouchableOpacity disabled={isDisabled} onPress={() => {
                setVideoUrl('')

                setVideoUrl(video.url)
            }}>
                <Icon name="play" size={PR(25)} color={GlobalStyles.helpi.color}/>
            </TouchableOpacity>
      </View>
    )
}

export default VideoItem;