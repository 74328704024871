import React, {useState, useEffect} from 'react';
import {StyleSheet, ScrollView, View, TouchableOpacity} from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonSubTitle from 'common/src/components/CommonSubtitle';
import InputField from 'common/src/components/InputField';
import {useTranslation} from 'react-i18next';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonFooter from 'common/src/components/CommonFooter';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonModal from 'common/src/components/CommonModal';
import Icon from 'react-native-vector-icons/Feather';
import Text from 'common/src/components/CommonCustomTxt';
import { getSeizureDescriptionCard, addUpdateSeizureDescriptionCard, deleteDescriptionCards } from '../../../store/epilepsyDetails/epilepsyDetailsActions';
import { useDispatch, useSelector } from 'react-redux';
import { seizureDescriptionCard } from '../../../store/epilepsyDetails/epilepsyDetailsReducers';
import { ContactDetailsTypes } from '../../../store/epilepsyDetails/types';
import { isEmpty } from 'lodash';
import { RootStackParams } from 'common/src/types';
import { StackNavigationProp } from '@react-navigation/stack';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

type OwnProps = {
    navigation: StackNavigationProp<RootStackParams, "AddContact">
}

const MySeizuresDescription = ({navigation}:OwnProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const seizureDescriptionCardReducer = useSelector(seizureDescriptionCard)
    const [scrollState, setScrollState] = useState();
    const [seizureDescription, setSeizureDescription] = useState<string>('');
    const [myExperience, setMyExperience] = useState<string>('');
    const [yourExperience, setYourExperience] = useState<string>('');
    const [yourHelp, setYourHelp] = useState<string>('');
    const [contactDetails, setContactDetails] = useState<ContactDetailsTypes[]>([])
    const [temporaryDeletedElement, setTemporaryDeletedElement] = useState<number | undefined>()
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [warningModal, setWarningModal] = useState<boolean>(false);

    useEffect(() => {
        if(isEmpty(seizureDescriptionCardReducer))
            dispatch(getSeizureDescriptionCard());
        else{
            setSeizureDescription(seizureDescriptionCardReducer?.description || '')
            setMyExperience(seizureDescriptionCardReducer?.myExperience || '')
            setYourExperience(seizureDescriptionCardReducer?.yourExperience || '')
            setYourHelp(seizureDescriptionCardReducer?.yourHelp || '')
            setContactDetails(seizureDescriptionCardReducer?.contactDetails || [])
        }
    },[seizureDescriptionCardReducer])

    const addContact = () => {
        navigation.navigate('AddContact', {callback: contactCallback} )
    }

    // update the contact details with the new values from the Add contact
    const contactCallback = (items:ContactDetailsTypes) => {
        let updatedContact:ContactDetailsTypes[] = [...contactDetails]
        updatedContact.push(items)
        setContactDetails(updatedContact)
    }

    const deleteContact = () => {
        const updatedContactDetails = contactDetails.filter((_, i) => i !== temporaryDeletedElement)
        setContactDetails(updatedContactDetails)
        setDeleteModal(false)
    }

    const saveSeizureDescription = () => {

        dispatch(addUpdateSeizureDescriptionCard({
            description: seizureDescription,
            myExperience,
            yourExperience,
            yourHelp,
            contactDetails,
            successMsg: t('translation:settings.seizuresDescription.success.message'),
            callback: () => {
                navigation.navigate('Settings')
            }
        }))
    }

    const isDisabled = () => {return ( seizureDescription.length === 0 && myExperience.length === 0 && yourExperience.length === 0 && yourHelp.length === 0)}

    // keep tracking of the changes of the action warning modal
    const callbackDeleteModalVisible = () => {
        setDeleteModal(false);
    }

    const deleteModalBtn = [
        {
          type: 'tertiary',
          title: t('translation:modal.btn.cancel'),
          click: callbackDeleteModalVisible
        },
        {
          type: 'primary',
          title: t('translation:modal.btn.confirm'),
          click: deleteContact
        }
      ]

    const deleteSeizureAntidote = () => {
        setWarningModal(false);
        dispatch(deleteDescriptionCards({
            cardAction: 'ADD_UPDATE_SEIZURES_DESCRIPTION_CARD_SUCCESS',
            cardName: 'seizureDescription',
            cardUrl: 'addseizuredescription',
            successMsg: t('translation:settings.epilepsy_details.deleted_successfully.message'),
            callback: () => {
                navigation.navigate('Settings');
            }
        }))
    }

    // keep tracking of the changes of the warningVisible
    const callbackWarningVisible = () => {
        setWarningModal(false);
    }

    const warningModalBtn = [
        {
          type: 'tertiary',
          title: t('translation:modal.btn.cancel'),
          click: callbackWarningVisible
        },
        {
          type: 'primary',
          title: t('translation:modal.btn.confirm'),
          click: deleteSeizureAntidote
        }
      ]

    // unmount component
    useEffect(() => {
        return () => {
            setSeizureDescription('')
            setMyExperience('')
            setYourExperience('')
            setYourHelp('')
            setContactDetails([])
        };
    }, []);
    
    return(
        <CommonKeyboardAvoidingView>
            { deleteModal &&
                <CommonModal 
                    testID="deleteModal" 
                    type={'info'} 
                    title={t('translation:modal.titles.warning')} 
                    text={t('translation:modal.contact.delete.warning.text')} 
                    buttons={deleteModalBtn} 
                    onClose={callbackDeleteModalVisible}
                />
            }
            { warningModal &&
                <CommonModal 
                    testID="warningModalID" 
                    type={'info'} 
                    title={t('translation:modal.titles.warning')} 
                    text={t('translation:modal.epilepsy_details_delete_text')} 
                    buttons={warningModalBtn} 
                    onClose={callbackWarningVisible}
                />
            }
            <CommonHeader
                title={t('translation:general.page_title.epilepsy_seizures_description')} 
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {navigation.goBack()}}
                testIDTitle="epilepsy_seizures_description_title"
                scroll={scrollState}
            />
            <ScrollView 
                testID="reports_scrollView"
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                setScrollState(event.nativeEvent.contentOffset.y);
                }}
            >
                <View style={{width:'90%', alignSelf:'center'}}>
                    <CommonSubTitle 
                        text={t('translation:general.page_subtitle.epilepsy_seizures_description')}
                        txtAlign='center'
                    />
                </View>
                <InputField 
                    labelText={t('translation:general.description_of_my_seizures_label')}
                    inputType="string" 
                    customStyle={{marginTop:PR(37), marginBottom:PR(30)}}
                    changeText={setSeizureDescription}
                    placeholderTxt={t('translation:general.description_of_my_seizures_placeholder')} 
                    testID="description_of_my_seizures"
                    multiline={true}
                    value={seizureDescription}
                />
                <InputField 
                    labelText={t('translation:general.my_seizures_experience_label')}
                    inputType="string" 
                    customStyle={{marginBottom:PR(30)}}
                    changeText={setMyExperience}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="my_experience"
                    multiline={true}
                    value={myExperience}
                />
                <InputField 
                    labelText={t('translation:general.your_experience_of_my_seizures_label')}
                    inputType="string" 
                    customStyle={{marginBottom:PR(30)}}
                    changeText={setYourExperience}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="your_experience"
                    multiline={true}
                    value={yourExperience}
                />
                <InputField 
                    labelText={t('translation:general.seizure_description_help_me_label')}
                    inputType="string" 
                    customStyle={{marginBottom:PR(30)}}
                    changeText={setYourHelp}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="your_help"
                    multiline={true}
                    value={yourHelp}
                />
                {contactDetails.length > 0 && 
                    <View testID="contact_details" style={{width:'90%',alignSelf:'center'}}>
                        <View style={{flexDirection:'row', alignItems:'center'}}>
                            <Text style={styles.label}>{'Contact details'}</Text>
                        </View>
                        {contactDetails.map((cd:ContactDetailsTypes, index:number) => {
                            return (
                                <View key={index} testID={`contact_elements_${index}`} style={styles.summaryContainer}>
                                    <View style={styles.inputSummaryContainer}>
                                        <Text numberOfLines={1} style={styles.inputSummaryValue}>{cd.name} - {cd.relationship}</Text>
                                        <Text numberOfLines={1} style={styles.inputSummaryValue}>{cd.phone}</Text>
                                    </View>
                                    <TouchableOpacity onPress={() => {setTemporaryDeletedElement(index); setDeleteModal(true)}} style={styles.trashContainer}>
                                        <Icon name="trash-2" size={PR(25)} color={GlobalStyles.global.redError} />
                                    </TouchableOpacity>
                                </View>
                            )
                        })}
                    </View>
                }
                <TouchableOpacity testID='add_contact_btn' onPress={() => addContact()} style={styles.addContainer}>
                    <View style={styles.wrapper}>
                        <View style={styles.checkBoxContainer}>
                            <Icon name="plus-circle" size={PR(25)} color={GlobalStyles.helpi.btnColor} />
                        </View>
                        <Text style={styles.txt}>{t('translation:general.btn.add_contact')}</Text>
                    </View>
                </TouchableOpacity>
                <CommonFooter>
                    {seizureDescriptionCardReducer !== null &&
                        <>
                            <CommonBtn type="tertiary" txtColor="red" title={t("translation:settings.epilepsy_details.delete_description.btn")} testID="delete_seizure_description" click={()=> setWarningModal(true)} /> 
                            <View style={{marginBottom: PR(5)}}></View>
                        </>
                    }
                    <CommonBtn type="primary" click={() => saveSeizureDescription()} title={t('translation:general.header.btn_save')} testID="save_seizure_description" disable={isDisabled()}/>
                </CommonFooter>
            </ScrollView>
        </CommonKeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    addContainer: {
        borderRadius: PR(20),
        borderWidth: PR(2),
        borderColor: GlobalStyles.helpi.ultraLightColor,
        width:'90%',
        alignSelf:'center', 
        marginBottom:PR(36),
        backgroundColor: GlobalStyles.global.white,
        shadowColor: '#333333',
        shadowOffset: {
        width: PR(2),
        height: PR(10),
        },
        shadowOpacity: 0.10,
        shadowRadius: 13.97,
        elevation: 10,
    },
    wrapper: {
        flexDirection: 'row',
        minHeight: PR(62),
        padding: PR(10),
        paddingLeft:PR(20),
        width: '90%',
        justifyContent: 'center',
        alignItems:'center'
    },
    checkBoxContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: PR(2),
        marginRight: PR(23)
    },
    txt: {
        fontSize: PR(16),
        color: GlobalStyles.helpi.btnColor,
    },
    label: { 
        marginBottom: PR(10),
        marginLeft: PR(20),
        color: GlobalStyles.global.black,
        fontSize: PR(12)
      },
      summaryContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: GlobalStyles.global.footerGrey,
        borderRadius: PR(20),
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: PR(14)
      },
      inputSummaryContainer: {
        flex: 1,
        flexDirection: 'column',
        minHeight: PR(62),
        padding: PR(10),
        fontSize: PR(16),
      },
      inputSummaryValue: {
        flex: 1,
        flexGrow: 1,
        color: GlobalStyles.global.black,
        marginLeft: PR(10),
        fontSize: PR(16),
        fontFamily: GlobalStyles.global.fontFamily.Medium
      },
      trashContainer: {
          alignItems: 'center',
          padding: PR(20)
      }
});

export default MySeizuresDescription;