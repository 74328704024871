import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useTranslation } from 'react-i18next';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import Text from 'common/src/components/CommonCustomTxt';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonImage from 'common/src/components/CommonImage';


const CommonProgramListItem = ({navigation, program, startProgram, updateMyPatientProgram}) => {
    const {t, i18n} = useTranslation();
    const {language: userLanguage} = i18n;
    const status = program.status ? program.status : "not_started"
    const styles = generateStyleSheet(status);
    program.title = program?.title || {en:t('translation:general.empty_title')}
    program.subtitle = program?.subtitle || {en:t('translation:general.empty_subtitle')}
    program.introduction = program?.introduction || {en:t('translation:general.empty_introduction')}

    const renderItem = () => {
        return <View style={{flexDirection:'row', width: '100%',alignItems: 'center'}}>
            {program.imageUri ?
                <View style={{ width:PR(50), height:PR(50),borderRadius: PR(15), overflow: "hidden"}}>
                    <CommonImage name="" uri={program.imageUri} width={PR(50)} height={PR(50)}/>
                </View>
            :
                <View style={{ width:PR(50), height:PR(50),borderRadius: PR(15), overflow: "hidden"}}>
                    <CommonImage name="noImage" width={PR(50)} height={PR(50)} />
                </View>
            }
            <View style={styles.textContainer}>
                <Text numberOfLines={1} style={styles.title}>{program.title[userLanguage] || program.title.en}</Text>
                <Text numberOfLines={2} style={styles.subtitle}>{program.subtitle[userLanguage] || program.subtitle.en}</Text>
            </View>
            {
                status === "active" && 
                    <Icon style={styles.icon} name="chevron-right" size={PR(25)} color={GlobalStyles.global.grey}/>
            }
            {
                status === "not_started" && 
                    <View style={styles.buttonContainer}>
                        <CommonBtn type="primary" title={t('translation:general.start')} click={() => {startProgram(program.id)}} size="small"/>
                    </View>
            }
        </View>
    }

    const goToProgram = () => {
        if (program.subProgram)
            return navigation.navigate('ProgramOverview', {program: {...program.subProgram, patientProgramId: program.patientProgramId}})
        if(!program.introductionDone)
            navigation.navigate('ProgramIntroduction', {program: program, updateMyPatientProgram})
        else if (!program.subProgram && (program.hasSubprograms && program.subprograms && program.subprograms.length > 0) && program.introductionDone)
            navigation.navigate('QoLForm', {program})
        else
            navigation.navigate('ProgramOverview', {program}) // needs improvement
    }

    return (
        <>
            { 
                !status || status === 'not_started' ? 
                    <View style={styles.container}>{renderItem()}</View>
                    :
                    <TouchableOpacity style={styles.container} onPress={goToProgram}>{renderItem()}</TouchableOpacity>
            }
        </>
    )
}

const generateStyleSheet = (status) => {
    return StyleSheet.create({
        container: {
            flexDirection: "row",
            marginVertical: PR(5)
        },
        textContainer: {
            flex: 1,
            flexGrow: status === 'active' ? 1 : 0.7,
            marginLeft: PR(15)
        },
        title: {
            fontSize: PR(16),
            color: GlobalStyles.global.black,
            fontFamily: GlobalStyles.global.fontFamily.Medium
        },
        subtitle: {
            fontSize: PR(12),
            color: GlobalStyles.global.greyToBlack,
            marginTop: PR(5)
        },
        icon: {
            alignSelf: "center"
        },
        buttonContainer: {
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 0.3
        },
    })
}

export default CommonProgramListItem;