import React, { useState } from 'react';
import InputField from '../InputField';
import {useTranslation} from 'react-i18next';
import CommonBtn from "../CommonBtn";
import {StyleSheet, View} from 'react-native';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';


interface Types {
    onCreateOther: any,
    onSubmit: any
}

const CommonOtherInput = ({onCreateOther, onSubmit}:Types) => {
    const {t} = useTranslation();
    const [value, setValue] = useState<string>()

    const setText = (val:string) => {
        setValue(val.trimStart())
    }

    return (
        <View style={styles.wrapper}>
            <InputField
                inputType="string"
                customStyle={{
                    marginTop: PR(15),
                    marginBottom: PR(10),
                }}
                changeText={setText}
                placeholderTxt={t('translation:modal.select.other_placeholder')}
                value={value}
            />
            <CommonBtn
                title={
                    value? t('translation:general.header.btn_add') : t('translation:general.header.btn_validate')
                }
                type='primary'
                testID={"action_modal_btn"}
                click={() => {
                    if (value) {
                        onCreateOther(value)
                        setValue("")
                    } else onSubmit();
                }}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        height: PR(140),
    },
});

export default CommonOtherInput;