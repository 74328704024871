import React from "react";
import Events from "../Events";
import { DiaryFilterTypes } from '../../../types/index';

interface OwnTypes {
    filterData:Array<DiaryFilterTypes>, 
    title:string, 
    item:Array<any>, 
    type:string, 
    itemNames:Array<string>, 
    callback:() => void, 
    moreCallback: Record<string, () => void>,
    modal:(data:Record<string, any>)=> void
}

const ActionEvents = ({filterData, title, item, type, itemNames, callback, moreCallback, modal}: OwnTypes) => {
    return(
        <>
        {filterData.length === 0  &&
            <Events title={title} item={item} type={type} itemNames={itemNames} callback={callback} moreActionCallback={moreCallback} modal={modal}/>
        }
        {filterData.length > 0 && item.length > 0 && filterData.find(fd => fd.value === type) &&
            <Events title={title} item={item} type={type} itemNames={itemNames} callback={callback} moreActionCallback={moreCallback} modal={modal}/>
        }
        </>
    )
}

export default ActionEvents;