import { TFunction } from "i18next"
import GlobalStyles from "../../styles/GlobalStyles";
import {isArray} from 'lodash';

export const getColor = (type: string) => {
    return type === "notes" ? 
        GlobalStyles.global.black : 
        type === 'medication' ? 
        GlobalStyles.helpi.color : 
        type === 'program_actions' ? 
        GlobalStyles.global.actionGreen :  
        GlobalStyles.global.white
}

export const getContainerBackgroundColor = (type: string) => {
    return type === 'nightwatch' ? 
        '#8c7fe5' : 
        type === 'headaches' ? 
        GlobalStyles.migraine.color : 
        type === 'sideEffect' ? 
        '#ffd039': 
        type === 'seizure' ? 
        '#fb667a': 
        type === 'appointment'? 
        '#1de9b6': 
        type === 'notes' || type === 'medication' || type === 'program_actions' ? 
        '#ffffff00': 
        type === 'forms' ? 
        '#3964AE' : 
        "#000"
}

export const getListCMSData = (t, key, list) => {
    // @ts-ignore
    let index = isArray(t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true})) && t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true}).findIndex((itemType)=>
      Object.keys(itemType).toString() === key)
    if (index > -1) 
      return t('translation:'+list+'.'+index+'.'+key)
    else 
      return key
}

export const defineFormTitle = (form, lang:string, t:TFunction) => {
    if (!form)
      return
    if (form.title)
      return form.title[lang] || form.title.en
    else if (form.label)
      return t(`translation:forms.${form.label}.title`)
    else
      return t(`translation:forms.${form.name}.title`)
}

export const defineActionTitle = (actionTitle:Record<string, string>, lang: string, t:TFunction) => {
    if(actionTitle)
      return actionTitle[lang] || actionTitle.en
    else 
      return t('translation:general.empty_title')
}

export const getIcon = (type: string): string => {
    switch (type) {
      case "seizure":
        return "zap";
      case "appointment":
        return "calendar";
      case "notes":
        return "edit";
      case "medication":
        return "plus-square";
      case "nightwatch":
        return "moon";
      case "headaches":
        return "meh";
      case "forms":
        return "clipboard";
      case "program_actions":
        return "target"
      default:
        return "thermometer";
    }
};