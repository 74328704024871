import { AnyAction } from 'redux';
import { RootState } from '../reducers';
import { ProfileDetailsActions } from './profileDetailsActions';

interface ProfileDetailsReducer {
    profileData: {
        first_name: string,
        last_name : string,
        email: string,
        phone: string,
        birthday: string,
        gender: string,
        neurologist_name: string,
        hospital: string,
        epilepsy_begin_year: string,
        frequency: string,
        address: {
          city: string,
          country: string,
          postcode: string, 
          street: string
        },
        reminder_settings: {
            afternoon: Array<string>,
            early_morning: Array<string>,
            evening: Array<string>,
            morning: Array<string>,
            noon: Array<string>,
            notification: boolean,
            time: number,
            when: string
        }
    } | null
}

const initialState:ProfileDetailsReducer = {
    profileData: null
};

export default function (state = initialState, action: AnyAction): ProfileDetailsReducer {
    if (action.type === ProfileDetailsActions.GET_PROFILE_SUCCESS){
        return {
            ...state,
            profileData: action.response.result
        }
    }
    else if (action.type === ProfileDetailsActions.UPDATE_PROFILE_SUCCESS){
        return {
            ...state,
            profileData: action.response.result
        }
    }
    return state;
}

export const profileData = (state: RootState) => state.profileDetailsReducer.profileData;