export default {
    globalFontFamily: {
        fontFamily: 'arial',
    },
    global: {
        fontFamily: {
            Black: 'FilsonPro-Black',
            BlackItalic: 'FilsonPro-BlackItalic',
            Bold: 'FilsonPro-Bold',
            BoldItalic: 'FilsonPro-BoldItalic',
            Heavy: 'FilsonPro-Heavy',
            HeavyItalic: 'FilsonPro-HeavyItalic',
            Light: 'FilsonPro-Light',
            LightItalic: 'FilsonPro-Light',
            Medium: 'FilsonPro-Medium',
            MediumItalic: 'FilsonPro-MediumItalic',
            Regular: 'FilsonPro-Regular',
            RegularItalic: 'FilsonPro-RegularItalic',
            Thin: 'FilsonPro-Thin',
            ThinItalic: 'FilsonPro-ThinItalic'
        },
        black: '#333333',
        white: '#fff',
        grey: '#aaaeb3',
        lightGrey: '#EBEBEB',
        greyToBlack: '#202224',
        footerGrey: '#F2F4F5',
        yellow: '#FFB714',
        redError: '#FD2F2F',
        redErrorBackground: '#FECDCD',
        ligthGreen: '#1EEAB7',
        customTypeGrey: '#C1C7D1',
        actionGreen: '#3B95A7',
        greenSuccess: '#5AC25E',
        blueInProgress: '#49ACF5'

    },
    helpi: {
        color: '#4C0EA7',
        btnColor: '#6D14EE',
        lightColor: '#C5A1F8',
        ultraLightColor: '#6D14EE1A',
        closeBtn: '#8C7FE51A',
        darkColor: '#570FBE',
        backgroundColorOpacity: "#FFFFFFBE",
        backgroundColorModal: "#000038d9",
        inactiveColor: '#A772F5',
        lightBackgroundColor: '#8C7FE5',
        primary: '#6D14EE',
        progressBarBackgroundColor: '#DBD6FF'
    },
    migraine: {
        color: '#00aca3',
        lightColor: '#e6fffd',
        ultraLightColor: '#e6fffd',
        darkColor: '#00aca3',
        backgroundColorOpacity: "#00aca3d9"
    }
}