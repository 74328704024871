import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Text from 'common/src/components/CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import {translate} from 'common/src/services/translationList';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const MedicationHistoryName = ({med, navigation}) => {
  const {t} = useTranslation();
  return(
    <>
      <View style={[styles.container,{backgroundColor: GlobalStyles.helpi.color}]}>
        <View style={styles.wrapper}>
          <View style={styles.medContainer}>
            <View >
              <Text style={styles.medMomentTxt}>{med.name}</Text>
            </View>
            <View style={{paddingBottom:PR(5)}}>
              <Text style={styles.medTxt}>{translate(t, med.reason, 'list.medication_reasons')}</Text>
            </View>
          </View>
          <TouchableOpacity onPress={() => navigation.navigate('EditMedication', {data:med, type:'name'})} style={styles.rightContainer}>
            <View style={styles.iconContainer}>
              <Icon 
                name={"edit-2"} 
                size={PR(20)} 
                color={"#fff"} 
              />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: PR(20),
    width:'90%',
    alignSelf:'center', 
    marginBottom:PR(10),
    shadowColor: '#333333',
    shadowOffset: {
      width: PR(2),
      height: PR(10),
    },
    shadowOpacity: 0.10,
    shadowRadius: 13.97,
    elevation: 10,
  },
  wrapper: {
    flexDirection: 'row',
    alignItems:'center'
  },
  medContainer: {
    flex:1,
    minHeight: PR(62),
    padding: PR(10),
    fontSize: PR(16),
    width: '60%'
  },
  medMomentTxt: {
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium,
    color: GlobalStyles.global.white,
    paddingBottom:PR(10)
  },
  medTxt: {
    color: GlobalStyles.global.white,
    marginLeft: PR(10),
    fontSize: PR(14),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  rightContainer: {
    width:'40%',
    paddingRight:PR(20),
    alignItems:'flex-end',
  },
  iconContainer: {
    alignItems:'center',
    flexDirection: 'row'
  }
})

export default MedicationHistoryName;