import React from 'react';
import {View, StyleSheet} from 'react-native';
import GlobalStyle from '../styles/GlobalStyles';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

type IProps = {
  text: string,
  txtAlign?: "center" | "auto" | "left" | "right" | "justify",
}

const CommonSubTitle = ({text, txtAlign}:IProps) => {
  txtAlign = txtAlign || "center";
  return (
    <>
      <View>
         <Text renderAsHtml={true} style={styles(txtAlign).subtitle}>{text}</Text>
      </View>
    </>
  );
}

const styles = (txtAlign: any) => StyleSheet.create({
  subtitle: {
    color: GlobalStyle.global.grey,
    fontSize: PR(16),
    textAlign: txtAlign,
    lineHeight: PR(25),
  }
});

export default CommonSubTitle;