import {call, put, takeLatest, select} from "redux-saga/effects";
import * as types from "../actions";
import {request} from "common/src/services/callService";
import {DELETE_DOSE_ACTION, DELETE_DOSE_SUCCESS,} from "./treatmentsActions";

export function* makeRequest ({method, path, data, callback, errorCallback, responseAction, successMessage}) {
    try {
        yield put ({ type: types.LOADING, response:true});
        const response = yield call(request, {data, method, path})
        if (response.success) {
            yield put ({ type: types.LOADING, response:false});
            if (successMessage) yield put({ type: types.SUCCESS, message: successMessage });
            yield put({ type: responseAction, response: {deleteDose:data} });
            if (callback) yield call(callback, response.result);
            return response.result
        } else {
            if (errorCallback) yield call(callback);
            yield put({ type: types.ERROR, response });
            yield put ({ type: types.LOADING, response:false});
        }
    } catch(error) {
        if (errorCallback) yield call(callback);
        yield put({ type: types.ERROR, error });
        yield put ({ type: types.LOADING, response:false});
    }
}


export function* deleteDoseSaga(payload:any) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        data: payload.doseToDelete,
        path: `/patients/${id}/intakes/${payload.doseToDelete.id}`,
        method: 'DELETE',
        responseAction: DELETE_DOSE_SUCCESS,
        translation: payload.t,
        successMessage: 'translation:treatment.intakes.success.delete_intake',
        ...payload
    })
}

export default function* watchUserAuthentication() {
    yield takeLatest(DELETE_DOSE_ACTION, deleteDoseSaga);
}