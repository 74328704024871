import React, {useEffect, useState} from 'react';
import { View, Platform, Dimensions, StyleSheet } from 'react-native';
import Text from './CommonCustomTxt';
import MultiSlider from '@ptomasroos/react-native-multi-slider';
import GlobalStyles from '../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../env.json';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

interface SliderAttribute {
    min: number,
    max: number,
    initialValue: string,
    labels?: Array<number|string>,
    updateValue: Function,
    disable: boolean,
}

const CommonSlider = ({min, max, initialValue, labels, updateValue, disable} : SliderAttribute) => {
    const [value, setValue] = useState(initialValue ? parseInt(initialValue) : Math.floor(max / 2));
    let { width } = Dimensions.get('window');
    width =  Platform.OS === 'android' ? width - 80 : width;
    width = width > 650 ? 650 : width;
    const sliderLength = Platform.OS === 'android' ? width * 0.85 : width * 0.70

    const  valueChange = (values) => {
        setValue(values[0]);
    }

    const submitChange = () => {
        updateValue(value)
    }

    useEffect(() => {
        setValue(initialValue ? parseInt(initialValue) : Math.floor(max / 2));
    }, [initialValue])

    return (
        <View style={styles.container}>
          <View style={{alignItems: 'center'}}>
            <MultiSlider
                values={[value]}
                onValuesChange={valueChange}
                onValuesChangeFinish={submitChange}
                sliderLength={PR(sliderLength)}
                min={min}
                max={max}
                step={1}
                allowOverlap
                snapped
                selectedStyle={{
                    backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].lightBackgroundColor
                }}
                trackStyle={{
                    backgroundColor: GlobalStyles.global.lightGrey,
                    height: PR(4)
                }}
                customMarker={() => {
                    return (
                        <View style={{flexDirection: 'column', justifyContent: 'space-between'}}>
                            <Text style={{alignSelf: 'center'}}>{value}</Text>
                            <View style={styles.marker}></View>
                        </View>
                    )
                }}
                enabledOne={!disable}
            />
          </View>
          <View style={[styles.labelContainer, {width: PR(sliderLength + 30)}]}>
            {
                labels && labels.map((label) => (
                    <Text>{label.toString()}</Text>
                ))
            }
            {
                (!labels || labels.length === 0) &&
                <>
                    <View style={styles.minMaxContainer}>
                        <Text>{min.toString()}</Text>

                    </View>
                    <View style={styles.minMaxContainer}>
                        <Text>{max.toString()}</Text>
                    </View>
                </>
            }
          </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignSelf: 'center',
        marginVertical: PR(10)
    },
    labelContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    marker: {
        height: PR(35),
        width: PR(35),
        backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].lightBackgroundColor,
        borderRadius: PR(20),
        marginBottom: PR(12)
    },
    minMaxContainer: {
        width: PR(30),
        alignItems: 'center'
    }
})

export default CommonSlider;