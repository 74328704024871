import React from 'react';
import {Modal, View, StyleSheet, TouchableOpacity, Platform} from 'react-native';
import GlobalStyle from '../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';


type IModal = {
    onClose?: any,
    children?: any,
    title?: string,
    alignTitle?: string,
    titleWidth?: string,
    containerHeight?: number,
}

const CommonImageZoomModal = ({children, onClose, title, alignTitle, titleWidth}:IModal) => {
    return (
        <Modal
            animationType={'fade'}
            transparent={true}>
            <View style={styles.modal} >
                <View style={styles.modalHeader}>
                    <Text numberOfLines={1} style={[styles.title, {textAlign: alignTitle || 'left', width: titleWidth || '75%' } ]}>{title}</Text>
                    <TouchableOpacity style={styles.closeIcon} onPress={onClose}>
                        <Icon name={"x"} size={PR(25)} color={GlobalStyle[APP_SHORTCUT_NAME].btnColor}/>
                    </TouchableOpacity>
                </View>
                <View style={styles.modalBody} onStartShouldSetResponder={() => true}>
                    <TouchableOpacity activeOpacity={1} style={{flex: Platform.OS === 'web' ? 1: 0}}>
                        {children}
                    </TouchableOpacity>
                    </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    closeIcon: {
        backgroundColor: GlobalStyle.global.white,
        borderRadius: PR(25),
        padding: PR(2),
        zIndex:999
    },
    modal: {
        backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].backgroundColorModal,
        flex: 1,
        justifyContent: 'center',
    },
    modalHeader: {
        alignItems: "flex-end",
        flexDirection: "row",
        height: 70,
        justifyContent: "space-between",
        left: 0,
        paddingBottom: 8,
        paddingHorizontal: 20,
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 1,
    },
    title: {
        fontFamily: GlobalStyle.global.fontFamily.Bold,
        fontSize: PR(18),
        color: GlobalStyle.global.white,
        textAlign: 'center',
    },
    modalBody:{
        left: 0,
        paddingBottom: 8,
        position: "absolute",
        right: 0,
        top: PR(100),
        zIndex: 1,
    },

});

export default CommonImageZoomModal;