import React from 'react';
import {Modal, View, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, Platform, KeyboardAvoidingView} from 'react-native';
import GlobalStyle from '../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';


type IModal = {
    onClose?: any,
    children?: any,
    title?: string,
    alignTitle?: string,
    titleWidth?: string,
    containerHeight?: number,
}

const CommonSimpleModal = ({children, onClose, title, alignTitle, titleWidth}:IModal) => {
    return (
        <Modal
            animationType={'fade'}
            transparent={true}>
            <TouchableOpacity onPress={onClose} style={styles.modal} >
            <KeyboardAvoidingView style={{alignItems:'center',justifyContent:'center', flex: 1}} {...(Platform.OS === 'ios' && { behavior: 'padding' })}>
            
            <TouchableWithoutFeedback>
                <View style={styles.modalContainer}>
                    <View style={styles.modalHeader}>
                        <Text numberOfLines={2} style={[styles.title, {textAlign: alignTitle || 'left', width: titleWidth || '75%' } ]}>{title}</Text>
                        {onClose &&
                            <TouchableOpacity style={styles.closeIcon} onPress={onClose}>
                                <Icon name={"x"} size={PR(25)} color={GlobalStyle[APP_SHORTCUT_NAME].btnColor}/>
                            </TouchableOpacity>
                        }
                    </View>
                    <View style={styles.modalBody} onStartShouldSetResponder={() => true}>
                        <TouchableOpacity activeOpacity={1} style={{flex: Platform.OS === 'web' ? 1: 0}}>
                            {children}
                        </TouchableOpacity>
                    </View>
                </View>
                </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
            </TouchableOpacity>
        </Modal>
    )
}

const styles = StyleSheet.create({
    closeIcon: {
        backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].closeBtn,
        borderRadius: PR(25),
        padding: PR(2),
        zIndex:999
    },
    modal: {
        backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].backgroundColorModal,
        flex: 1,
        // alignItems: 'center',
        justifyContent: 'center',
    },
    modalContainer: {
        width: '80%',
        paddingVertical: PR(15),
        borderRadius: PR(15),
        backgroundColor: GlobalStyle.global.white,
        maxHeight: '94%',
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: PR(25)
    },
    title: {
        fontFamily: GlobalStyle.global.fontFamily.Bold,
        fontSize: PR(18),
        color: GlobalStyle.global.black,
        textAlign: 'center',
    },
    modalBody:{
        minHeight: PR(75),
        paddingTop: PR(10),
        paddingBottom: PR(10),
        width: "100%",
        flex:Platform.OS === 'web' ? 1: 0
    },

});

export default CommonSimpleModal;