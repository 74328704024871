import * as types from '../actions';

export default function(state:any = [], action:any = '') {
  const response = action.response;
  if (action.type === types.GET_SEIZUREVIDEOS_SUCCESS) {
    return { ...state, response };
  } 
  else if(action.type === types.RESET_SEIZUREVIDEOS_SUCCESS) {
    return {
      ...state,
      response: undefined
    }
  }
  else {
    return state;
  }
}