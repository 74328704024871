import React from 'react';


const CommonVideo = ({ srcUri }) => {

    return (
        <video width="100%" height="400" autoPlay={false} controls>
            <source src={srcUri} type="video/mp4" />
        </video>
    );
};


export default CommonVideo;