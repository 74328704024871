import React, {useState, useEffect} from 'react';
import { StyleSheet, View, ScrollView} from 'react-native'; 
import GlobalStyle from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonNeuroStep from 'common/src/components/CommonNeuroStep';
import { useDispatch, useSelector } from 'react-redux';
import { updatePatientAction, logOutAction } from '../store/actions/authenticationActions';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const NeurologistStep = ({navigation}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const GetMeResponse = useSelector((state:any) => state.getMe.response)
  const DataSharing = useSelector((state: any) => state.getMe.dataSharing)
  const [scrollState, setScrollState] = useState();

  useEffect(() => {
    if (DataSharing?.length > 0){
      for (let index = 0; DataSharing[index]; index++){
        if (!DataSharing[index].isActive)
          continue
        else {
          return navigation.navigate('DataSharing', { nextAction: 'PrivacyConsent' })
        }
      }
    }
  }, [DataSharing])

  const next = (obj:any) => {
    const updatePatient = () => (updatePatientAction({obj,id:GetMeResponse.result.patients.id, navigation}));
    dispatch(updatePatient())
  }

  const setLogout = () => {
    dispatch(logOutAction());
    navigation.navigate('Welcome');
    navigation.reset({
      index: 0,
      routes: [{ name: 'Welcome' }],
    });
  }

  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader 
        rightIconNameFirst="log-out"
        rightIconColorFirst={GlobalStyle.helpi.btnColor}
        rightClickFirst={setLogout}
        title={t('translation:register.form.neurologist')}
        scroll={scrollState}
        disableFormsBanner={true}
      />
      <View style={styles.scrollViewWrapper}>
        <ScrollView 
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            setScrollState(event.nativeEvent.contentOffset.y);
          }}
        >
          <CommonNeuroStep nextTriggered={next}/>
        </ScrollView>
      </View>
    </CommonKeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
  },
})

export default NeurologistStep;