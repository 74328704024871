export enum EmailExistanceActions {
	CHECK_EMAIL_EXISTANCE = "CHECK_EMAIL_EXISTANCE",
}

export interface CheckEmailInterface {
	email: string;
	language: string;
	callback?: () => void;
	errorCallback?: () => void;
}

export const checkEmailExistanceAction = (payload: CheckEmailInterface) => {
	return {
		type: EmailExistanceActions.CHECK_EMAIL_EXISTANCE,
		...payload,
	};
};
