import React, {useState, useEffect} from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import GlobalStyles from '../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import { useTranslation } from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const CommonQoLForm = ({subProgram, updateCheckBox, index}) => {
    const {i18n} = useTranslation();
    const {language: userLanguage} = i18n;
    const [checkBoxSelected, setCheckBoxSelected] = useState<boolean>(false);
    const styles: any = generateStyleSheet(checkBoxSelected);

    useEffect(() => {
        if (subProgram.selected !== undefined && checkBoxSelected !== subProgram.selected)
            setCheckBoxSelected(subProgram.selected)
      },[subProgram.selected])

    const selected = () => {
        setCheckBoxSelected(true)
        updateCheckBox(!checkBoxSelected, index)
    }

    return(
        <TouchableOpacity onPress={selected} style={styles.container}>
            <View style={styles.wrapper}>
                <View style={styles.checkBoxContainer}>
                    {checkBoxSelected && 
                        <View style={styles.selectedcheckBox}></View>
                    }
                </View>
                <Text style={styles.txt}>{subProgram?.title[userLanguage] || subProgram?.title.en}</Text>
            </View>
        </TouchableOpacity>
    )
}

const generateStyleSheet = (selected) => {
    return StyleSheet.create({
        container: {
            borderRadius: PR(20),
            borderWidth: PR(2),
            borderColor: GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor,
            width:'90%',
            alignSelf:'center', 
            marginBottom:PR(8),
            backgroundColor: GlobalStyles.global.white,
            shadowColor: '#333333',
            shadowOffset: {
            width: PR(2),
            height: PR(10),
            },
            shadowOpacity: 0.10,
            shadowRadius: 13.97,
            elevation: 10,
        },
        wrapper: {
            flexDirection: 'row',
            minHeight: PR(62),
            padding: PR(10),
            paddingLeft:PR(20),
            width: '80%',
            alignItems:'center'
        },
        checkBoxContainer: {
            height: PR(24),
            width: PR(24),
            borderWidth: PR(2),
            backgroundColor: GlobalStyles.global.white,
            borderColor: GlobalStyles[APP_SHORTCUT_NAME].btnColor,
            borderRadius: PR(15),
            justifyContent: 'center',
            alignItems: 'center',
            padding: PR(2),
            marginRight: PR(10)
        },
        selectedcheckBox: {
            height: PR(24),
            width: PR(24),
            backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].btnColor,
            borderRadius: PR(15)
        },
        txt: {
            fontSize: PR(16),
            color: GlobalStyles.global.black
        }
    })
}

export default CommonQoLForm;