import { AnyAction } from 'redux';
import { RootState } from '../reducers';
import { TimezoneBannerActions } from './timezoneBannerActions';

interface TimezoneBannerReducer {
    timezoneBannerExist: boolean;
}

const initialState: TimezoneBannerReducer = {
    timezoneBannerExist: false
};

export default function (state = initialState, action: AnyAction): TimezoneBannerReducer {
    if (action.type === TimezoneBannerActions.UPDATE_TIMEZONE_BANNER_SUCCESS) {
        return { ...state, timezoneBannerExist: action.response};
    }

    return state;
}

export const timezoneBannerExist = (state: RootState) => state.timezoneBannerReducer.timezoneBannerExist;