import React, {useState} from 'react'
import { View, StyleSheet, Dimensions } from 'react-native';
import GlobalStyles from '../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../env.json';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const { height } = Dimensions.get('window');

const CommonFooter = (props) => {
    const [viewHeight, setViewHeight] = useState<number>(0);
    let contentHeight = props.atBottom ? props.atBottom : 0
    return (
        <View
            onLayout={(event: any) => {
                setViewHeight(event.nativeEvent.layout.height)
            }}
        >
            <View style={((height-viewHeight-PR(30)) > contentHeight) ||  props.reachBottom ? {} :  styles.border}></View>
            <View style={[styles.container,{flexDirection:props.direction}]}>
                {props.children}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: PR(10)
    },
    border: {
        alignSelf:'center',
        borderTopColor: GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor,
        borderTopWidth: PR(1),
        width:'90%'
    },
   
})

export default CommonFooter;