import * as types from '../drugs/drugsActions';

export default function(state:any = {
    drugs: [],
  }, action:any) {
  const response = action.response;
  switch(action.type) {
    case types.GET_DRUGS_SUCCESS:
      return { 
        ...state, 
        drugs: response
      }; 
    default:
      return state;
  }
};