import * as types from '../actions';
import moment from 'moment';
import {DELETE_EVENT_SUCCESS, RESET_EVENTS_SUCCESS, UPDATE_EVENT_SUCCESS, GET_IMPACT_EVENTS_SUCCESS, GET_TRIGGERS_EVENTS_SUCCESS, GET_AURAS_EVENTS_SUCCESS} from "../events/eventActions";
import { EventSet, GenericRecord } from 'common/src/types';
import { ADD_PROGRAM_ACTION_SUCCESS, START_PROGRAM_ACTION_SUCCESS } from '../programs/programsActions';

const getIndexesInEventSet = (event: GenericRecord, eventsSet: EventSet[]): [number, number] => {
  let indexInSet = -1;
  if (validateDateFormat(eventsSet[0].date, 'yyyy-MM-DD'))
    indexInSet = eventsSet.findIndex(events => events.date === moment(event.date).format('yyyy-MM-DD'));
  else
    indexInSet = eventsSet.findIndex(events => events.date === moment(event.date).format('yyyy-MM'));
  if (indexInSet === -1) {
    return [-1, -1];
  }

  const eventsOfSameType: GenericRecord[] = eventsSet[indexInSet][`${event.type}s`];
  if (eventsOfSameType.length === 0) {
    return [-1, -1];
  }

  const indexToReplace = eventsOfSameType.findIndex(e => (event.id || event._id) === (e.id || e._id));
  if (indexToReplace === -1) {
    return [-1, -1];
  }

  return [indexInSet, indexToReplace];
}

const replaceInEventsSet = (event: GenericRecord, eventsSet: EventSet[]) => {
  const [indexInSet, indexToReplace] = getIndexesInEventSet(event, eventsSet);
  if (indexInSet === -1 || indexToReplace === -1) {
    return eventsSet;
  }
  
  eventsSet[indexInSet][`${event.type}s`][indexToReplace] = event;
  return eventsSet;
}

const deleteInEventsSet = (event: GenericRecord, eventsSet: EventSet[]) => {
  const [indexInSet, indexToReplace] = getIndexesInEventSet(event, eventsSet);
  
  if (indexInSet === -1 || indexToReplace === -1) {
    return eventsSet;
  }

  const eventsOfSameType: GenericRecord[] = eventsSet[indexInSet][`${event.type}s`];
  eventsSet[indexInSet][`${event.type}s`] = eventsOfSameType.filter(e => (event.id || event._id) !== (e.id || e._id));
  return eventsSet;
};

const validateDateFormat = (date, format) => {
  return moment(date, format, true).isValid()
}

const updateImpactEvent = (arrayToUpdate, eventWithNewValues) => {
  return arrayToUpdate.map(event => {
    if(event.id === eventWithNewValues.id)
      event = eventWithNewValues
    return event
  })
}

export default function(state:any = {
  events: [],
  timeline_events: [],
  impact_seizure_events: [],
  impact_triggers_events: [],
  impact_auras_events: [],
  impact_mood_events: [],
  impact_sleep_events: [],
  impact_adherence_events: []
} , action:any = '') {
  

  const response = action.response;
  switch(action.type) {
    case types.GET_EVENTS_SUCCESS:
      if(state.events.length === 0) {
        if(response.result){
          let sortedEvents = response.result
          sortedEvents.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
          return { 
            ...state, 
            events: sortedEvents
          };
        } else {
          return {
            ...state
          }
        }
      }
      else {
        if(response.result){
          let newEvents = [...state.events, ...response.result]
          newEvents.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
          if(response.prevNext === 'prev' && newEvents.length >= 112)
            newEvents.splice(84, newEvents.length)
          return { 
            ...state, 
            events: newEvents
          };
        } else {
          return {
            ...state
          }
        }
      }
    case types.GET_NIGHTWATCH_EVENTS_SUCCESS:
      let nightWatchDate = state.events.findIndex(item => item.date === moment(response.result[0].date).format('yyyy-MM-DD'))
      if(nightWatchDate > -1) {
        state.events[nightWatchDate] = response.result[0]
      }
      let UpdatedWithNW = [...state.events]
      return {
        ...state, 
        events: UpdatedWithNW
      }
    case GET_IMPACT_EVENTS_SUCCESS:
      const {result: impactEvent } = response
      if(impactEvent && impactEvent.length > 0 && impactEvent[0]?.type && impactEvent[0]?.type === 'seizure'){
        if(state.impact_seizure_events.length === 0) {
          return { 
            ...state, 
            impact_seizure_events: impactEvent
          };
        } else {
          let newImpactSeizureEvents = [...state.impact_seizure_events]
          impactEvent.forEach(seizure => {
            if(newImpactSeizureEvents.filter(item => item.id === seizure.id).length === 0)
              newImpactSeizureEvents.push(seizure)
          })
          return { 
            ...state, 
            impact_seizure_events: newImpactSeizureEvents
          };
        }
      }
      else if(impactEvent && impactEvent.length > 0 && impactEvent[0]?.type && impactEvent[0]?.type === 'mood'){
        if(state.impact_mood_events.length === 0) {
          return { 
            ...state, 
            impact_mood_events: impactEvent
          };
        } else {
          let newImpactMoodEvents = [...state.impact_mood_events]
          impactEvent.forEach(mood => {
            if(newImpactMoodEvents.filter(item => item.id === mood.id).length === 0)
              newImpactMoodEvents.push(mood)
          })
          return { 
            ...state, 
            impact_mood_events: newImpactMoodEvents
          };
        } 
      }
      else if(impactEvent && impactEvent.length > 0 && impactEvent[0]?.type && impactEvent[0]?.type === 'sleep'){
        if(state.impact_sleep_events.length === 0) {
          return { 
            ...state, 
            impact_sleep_events: impactEvent
          };
        } else {
          let newImpactSleepEvents = [...state.impact_sleep_events]
          impactEvent.forEach(sleep => {
            if(newImpactSleepEvents.filter(item => item.id === sleep.id).length === 0)
              newImpactSleepEvents.push(sleep)
          })
          return { 
            ...state, 
            impact_sleep_events: newImpactSleepEvents
          };
        } 
      }
      else if(impactEvent && impactEvent.length > 0 && impactEvent[0]?.type && impactEvent[0]?.type === 'reminder'){
        if(state.impact_adherence_events.length === 0) {
          return { 
            ...state, 
            impact_adherence_events: impactEvent
          };
        } else {
          let newImpactAdherenceEvents = [...state.impact_adherence_events]
          impactEvent.forEach(adh => {
            if(newImpactAdherenceEvents.filter(item => item.id === adh.id).length === 0)
              newImpactAdherenceEvents.push(adh)
          })
          return { 
            ...state, 
            impact_adherence_events: newImpactAdherenceEvents
          };
        } 
      } else return; // TODO: extend the condition for the mood and sleep for the impact
    case GET_TRIGGERS_EVENTS_SUCCESS:
      const {result: triggersEvents } = response;
      if(triggersEvents && triggersEvents.length > 0){
        if(state.impact_triggers_events.length === 0) {
          return { 
            ...state, 
            impact_triggers_events: triggersEvents
          };
        } else {
          let newImpactTriggersEvents = [...state.impact_triggers_events]
          triggersEvents.forEach(seizure => {
            if(newImpactTriggersEvents.filter(item => item.id === seizure.id).length === 0)
            newImpactTriggersEvents.push(seizure)
          })
          return { 
            ...state, 
            impact_triggers_events: newImpactTriggersEvents
          };
        }
      } else return;
    
    case GET_AURAS_EVENTS_SUCCESS:
      const {result: aurasEvents } = response;
      if(aurasEvents && Object.keys(aurasEvents).length > 0) {
        if(state.impact_auras_events.length === 0) {
          const aurasEventsArr = [aurasEvents];
          
          return {
            ...state,
            impact_auras_events: aurasEventsArr
          };

        } else {
          let newImpactAurasevents = [...state.impact_auras_events]
          newImpactAurasevents.push(aurasEvents);

          return {
            ...state,
            impact_auras_events: newImpactAurasevents
          };
        }
      } else return;
      
    case types.ADD_EVENT_SUCCESS: 
      const result = response.result
      const dataSent = response.dataSent
      if(result.type === "seizure")
        Object.assign(result, {video_events: dataSent.videoList[0]})
      let findDate = state.events.findIndex(item => item.date === moment(result.date).format('yyyy-MM-DD'))
      let resultType = result.type+'s'
      let eventsCpy = [...state.events];
      let timelineEventsCpy = [...state.timeline_events];
      let impact_seizure_eventsCpy:any = [...state.impact_seizure_events];
      if(result.type === "seizure")
        impact_seizure_eventsCpy.push(result)
      
      if(findDate > -1) {
          if (resultType === 'forms' && result.form === dataSent.form) {
            result.form = dataSent.formInfo
          }
          eventsCpy[findDate][resultType].push(result)
      }
      if (resultType === 'seizures' || resultType === 'reminders') {
        let findTimelineDate = -1
        if (validateDateFormat(state.timeline_events[0].date, 'yyyy-MM-DD'))
          findTimelineDate = state.timeline_events.findIndex(item => item.date === moment(result.date).format('yyyy-MM-DD'))
        else
          findTimelineDate = state.timeline_events.findIndex(item => item.date === moment(result.date).format('yyyy-MM'))
        if (findTimelineDate > -1) {
          timelineEventsCpy[findTimelineDate][resultType].push(result);
        }
      }
      let lastAddedFormCpy = {...state.lastAddedForm}
      if (resultType === 'forms')
        lastAddedFormCpy = response.result
      return {...state, events: eventsCpy, timeline_events: timelineEventsCpy, impact_seizure_events: impact_seizure_eventsCpy, lastAddedForm: lastAddedFormCpy, impact_triggers_events: [], impact_auras_events: []}
    case types.GET_TIMELINE_EVENTS_SUCCESS:
      return { 
        ...state, 
        timeline_events: response.result.reverse()
      };
    case types.ADD_EVENT_MOOD_AND_SLEEP_SUCCESS:
      let findMoodAndSleepEvent = state.events.findIndex(item => item.date === moment(response.result.date).format('yyyy-MM-DD'))
      state.events[findMoodAndSleepEvent][response.result.type] = response.result
      let MoodAndSleepCpy = [...state.events]
      let impactAddMood = [...state.impact_mood_events]
      impactAddMood.push(response.result)
      let impactAddSleep = [...state.impact_sleep_events]
      impactAddSleep.push(response.result)
      return {...state, events: MoodAndSleepCpy, impact_mood_events: [], impact_sleep_events: []}
    case types.UPDATE_MOOD_AND_SLEEP_SUCCESS:
      let editEvent = state.events.findIndex(item => item.date === moment(response.result.date).format('yyyy-MM-DD'))
      let editImpactMood = state.impact_mood_events.findIndex(item => moment(item.date).format('yyyy-MM-DD') === moment(response.result.date).format('yyyy-MM-DD'));
      let editImpactSleep = state.impact_sleep_events.findIndex(item => moment(item.date).format('yyyy-MM-DD') === moment(response.result.date).format('yyyy-MM-DD'));
      if (editEvent === -1)
        return state
      if(response.result.type === 'mood') {
        state.events[editEvent].mood = response.result
        if (editImpactMood > -1)
          state.impact_mood_events[editImpactMood] = response.result
        let newMood = [...state.events]
        return {...state, events: newMood, impact_mood_events: []}
      } else {
        state.events[editEvent].sleep = response.result
        if (editImpactSleep > -1)
          state.impact_sleep_events[editImpactSleep] = response.result
        let newSleep = [...state.events]
        return {...state, events: newSleep, impact_sleep_events: []}
      }
    // Triggered when add treatment
    case types.UPDATE_DIARY_MEDICATION_SUCCESS: 
      if(response.updateMedData){
        let updateNameMed = [...state.events]
        let findAllDays = state.events.map((item,i) => {
          if(moment(item.date) >= moment(response.medData.intake.from) && moment(item.date).format('yyyy-MM-DD') <= moment().format('yyyy-MM-DD') )
            return i
          else 
            return null
        }).filter(i => i !== null)
        if(findAllDays.length > -1 ){
          findAllDays.forEach(day => {
            const findReminders = updateNameMed[day].reminders.map((item, i) => {
              if(item.medication.id === response.medData.id)
                return i
              else 
                return null
            }).filter(i => i !== null)
            if(findReminders.length > -1){
              findReminders.forEach(reminder =>{
                updateNameMed[day].reminders[reminder].medication.name = response.medData.name
                updateNameMed[day].reminders[reminder].reminder.name = response.medData.name
              })
            }
          })
        }
        return {...state, events: updateNameMed}
      } else {
        let findFromDay = state.events.findIndex(item => item.date === moment(response.result[0].date).format('yyyy-MM-DD'))
        if(findFromDay > -1 ){
          state.events[findFromDay] = response.result[0]
        }
        let updateMed = [...state.events]
        return {...state, events: updateMed}
      }
    case types.UPDATE_MEDICATION_INTAKE_SUCCESS:
      let eventIndex = state.events.findIndex(item => item.date === moment(response.result.date).format('yyyy-MM-DD'))
      let _timelineEventsCpy = [...state.timeline_events];
      let _eventsCpy = [...state.events];
      let timelineEventIndex = -1;
      if (validateDateFormat(state.timeline_events[0]?.date, 'yyyy-MM-DD'))
        timelineEventIndex = state.timeline_events.findIndex(item => item.date === moment(response.result.date).format('yyyy-MM-DD'))
      else
        timelineEventIndex = state.timeline_events.findIndex(item => item.date === moment(response.result.date).format('yyyy-MM'))

        if (timelineEventIndex > -1){
        let reminderIndex = _timelineEventsCpy[timelineEventIndex].reminders.findIndex(item => item.id === response.result.id);
        if (reminderIndex > -1)
          _timelineEventsCpy[timelineEventIndex].reminders[reminderIndex] = response.result;
      }
      if (eventIndex > -1){
        let reminderIndex = _eventsCpy[eventIndex].reminders.findIndex(item => item.id === response.result.id);
        if (reminderIndex > -1) {
          _eventsCpy[eventIndex].reminders[reminderIndex].taken = response.result.taken;
          _eventsCpy[eventIndex].reminders[reminderIndex].taken_date = response.result.taken_date;
        }
      }
    
      return {...state, events: _eventsCpy, timeline_events: _timelineEventsCpy, impact_adherence_events: []}
    case RESET_EVENTS_SUCCESS:
      return {...state, events: []}
    case UPDATE_EVENT_SUCCESS:
      const { result: event } = response;
      // if the update event is a form replace the updatedAt with the new value
      if (event.type === 'form'){
        let findFormDate = state.events.findIndex(item => item.date === moment(event.date).format('yyyy-MM-DD'))
        let findForm: number = -1
        let eventsCpyForm = [...state.events];
        if (findFormDate > -1)
          findForm = eventsCpyForm[findFormDate][event.type+'s'].findIndex(item => item.id === event.id)
        if(findForm > -1) 
          eventsCpyForm[findFormDate][event.type+'s'][findForm].updatedAt = event.updatedAt
        return { ...state, events: eventsCpyForm}
      }
      if(event.type === "seizure")
        Object.assign(event, {video_events: response.dataSent.videoList[0]})
      // if by editing the event change the date do the following
      if(moment(event.date).format('yyyy-MM-DD') !== moment(response.dataSent.old_date).format('yyyy-MM-DD')){
        let findDateUpdate = state.events.findIndex(item => item.date === moment(event.date).format('yyyy-MM-DD'))
        let resultTypeUpdate = event.type+'s'
        let eventsCpyUpdate = [...state.events];
        let findUpdateTimelineDate
        let CopyEvent = {...event}
        CopyEvent.date = response.dataSent.old_date
        deleteInEventsSet(CopyEvent, state.events as EventSet[]);
        if(findDateUpdate > -1) {
          eventsCpyUpdate[findDateUpdate][resultTypeUpdate].push(event)
        }
        // if it's a seizure or reminder update the timeline byt delting the previous date and adding event to the new date
        let eventsUpdateInTimeline = state.timeline_events;
        if (event.type === 'seizure' || event.type === 'reminder') {
          deleteInEventsSet(CopyEvent, state.timeline_events as EventSet[]);
          if (validateDateFormat(state.timeline_events[0].date, 'yyyy-MM-DD'))
            findUpdateTimelineDate = state.timeline_events.findIndex(item => item.date === moment(event.date).format('yyyy-MM-DD'))
          else
            findUpdateTimelineDate = state.timeline_events.findIndex(item => item.date === moment(event.date).format('yyyy-MM'))
          if (findUpdateTimelineDate > -1) {
            eventsUpdateInTimeline[findUpdateTimelineDate][resultTypeUpdate].push(event);
          }
        }
        return { ...state, events: eventsCpyUpdate, timeline_events: [...eventsUpdateInTimeline], impact_seizure_events: [], impact_triggers_events: []};
      } 
      // else just find the event and modify the values
      else{
        let updatedEvents = replaceInEventsSet(event, state.events as EventSet[]);
        let updatedTimelineEvents = state.timeline_events;

        if (event.type === 'seizure' || event.type === 'reminder') {
          updatedTimelineEvents = replaceInEventsSet(event, updatedTimelineEvents);
        }
        return { ...state, events: [...updatedEvents], timeline_events: [...updatedTimelineEvents], impact_seizure_events: [], impact_triggers_events: []};
      }
    case START_PROGRAM_ACTION_SUCCESS:
      const { result: actionEvent } = response;
      if (actionEvent.type === "mood" || actionEvent.type === "sleep")
        return {...state}
      const findActionDate = state.events.findIndex(item => item.date === moment(actionEvent.date).format('yyyy-MM-DD'))
      if (findActionDate === -1)
        return {...state}

      const resultActionType = actionEvent.type+'s'
      // update the schedule date
      if( response.dataSent.old_date && (moment(actionEvent.date).format('yyyy-MM-DD') !== moment(response.dataSent.old_date).format('yyyy-MM-DD'))){
        let eventsCpyUpdate = [...state.events];
        let CopyEvent = {...actionEvent}
        CopyEvent.date = response.dataSent.old_date

        deleteInEventsSet(CopyEvent, state.events as EventSet[]);

        if(findActionDate > -1) {
          eventsCpyUpdate[findActionDate][resultActionType].push(actionEvent)
        }
        return { ...state, events: eventsCpyUpdate}
      } else { // update the action event for the day that already exist
        let findAction: number = -1
        let eventsCpyAction = [...state.events];
        if (findActionDate > -1)
          findAction = eventsCpyAction[findActionDate][resultActionType].findIndex(item => (item._id || item.id) === (actionEvent._id || actionEvent.id))

        if (actionEvent.actionData && actionEvent.actionData.type === 'habit') {
          let shouldNotUpdate = false
          for (let i = 0; eventsCpyAction[i]; i++) {
            const date = eventsCpyAction[i];
            if (date.program_actions.length === 0)
              break;
            for (let j = 0; date.program_actions[j]; j++) {
              const event = date.program_actions[j];
              
              if (event.action === actionEvent.action) {
                if (event.isActionCompleted === actionEvent.isActionCompleted) {
                  shouldNotUpdate = true
                  break;
                }
                else
                  event.isActionCompleted = actionEvent.isActionCompleted
              }
            }
            if (shouldNotUpdate)
              break;
          }
        }

        if(findAction > -1) 
          eventsCpyAction[findActionDate][resultActionType][findAction] = actionEvent
        else
          eventsCpyAction[findActionDate][resultActionType].push(actionEvent)

        return { ...state, events: eventsCpyAction}
      }
    case DELETE_EVENT_SUCCESS: 
      const { result: eventToDelete } = response;
      let removedEvents = deleteInEventsSet(eventToDelete, state.events as EventSet[]);
      let eventsInTimeline = state.timeline_events;

      if (eventToDelete.type === 'seizure' || eventToDelete.type === 'reminder') {
        eventsInTimeline = deleteInEventsSet(eventToDelete, eventsInTimeline);
      }
      return { ...state, events: [...removedEvents], timeline_events: [...eventsInTimeline], impact_seizure_events: [], impact_triggers_events: [], impact_auras_events: []};
    case types.UPDATE_NIGHTWATCH_EVENT_SUCCESS:
      // Nightwatch update event endpoint does not return the updated event
      const updatedNightwatchEvent = response.dataSent.report;
      return { 
        ...state,
        events: [...replaceInEventsSet(updatedNightwatchEvent, state.events as EventSet[])]
      };
    case ADD_PROGRAM_ACTION_SUCCESS:
      const { result: patientProgram, dataSent: data } = response;
      const action = data.action

      if (!action || !action.id) {
        return state
      }

      const actionFound = patientProgram.actions.find(item => item.id === action.id)
      if (!actionFound) {
        const eventsCopy = [...state.events]
        eventsCopy.forEach((day) => {
          day.program_actions.forEach((event) => {
            if (event.action === action.id && !event.isFromDeletedAction)
              event.isFromDeletedAction = true
          })
        })
        return {...state, events: eventsCopy}
      }
      return state
    case types.LOGIN_TO_NIGHTWATCH_SUCCESS: 
      return {...state, events: []}
    default:
      return state;
  }
}