import React, {useState, useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import GlobalStyle from '../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonCheckBox = (props:any) => {
  const [checkBoxSelect, setCheckBoxSelect] = useState<boolean>(props.selected || false)
  
  useEffect(() => {
    if (props.selected !== undefined && checkBoxSelect !== props.selected)
      setCheckBoxSelect(props.selected)
  },[props.selected])

  const sendBackAnswer = () => {
    setCheckBoxSelect(!checkBoxSelect)
    props.updateCheckBox(!checkBoxSelect, props.index)
  }

  const type = props.type ? props.type : 'squared';
  const checkedIcon = !!props.checkedIcon;
  const styles: any = generateStyleSheet(type, checkBoxSelect);

  return (
    <View>
      <TouchableOpacity disabled={type === 'rounded' && props.selected} testID={props.testID} onPress={props.disable ? () => {} : sendBackAnswer}>
        <View style={styles.wrapper}>
          <View style={styles.txtWrapper}>
          <Text renderAsHtml={true} style={styles.checkBoxTxt}>{props.text}</Text>
          </View>
          <View style={styles.checkBoxContainer}>
            {
              checkBoxSelect &&
                <View style={styles.selectedcheckBox}>
                  {
                    checkedIcon &&
                      <Icon name="check" size={PR(17)} color={type === 'rounded-checkbox'? GlobalStyle[APP_SHORTCUT_NAME].darkColor : GlobalStyle.global.white}/>
                  }
                </View>
            }
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}

const generateStyleSheet = (type, selected) => {
  return StyleSheet.create({
    wrapper: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems:'center',
      padding: 4
    },
    txtWrapper: {
      width: '90%',
      marginRight: PR(10)
    },
    checkBoxContainer: {
      height: PR(24),
      width: PR(24),
      borderWidth: PR(2),
      backgroundColor: GlobalStyle.global.white,
      borderColor: selected ? GlobalStyle[APP_SHORTCUT_NAME].darkColor : GlobalStyle.global.lightGrey,
      borderRadius: type === 'rounded' || type === 'rounded-checkbox' ? PR(15) : 0,
      justifyContent: 'center',
      alignItems: 'center',
      padding: PR(2)
    },
    selectedcheckBox: {
      height: PR(16),
      width: PR(16),
      backgroundColor: type === 'rounded-checkbox'? GlobalStyle.global.white : GlobalStyle[APP_SHORTCUT_NAME].darkColor,
      borderRadius: type === 'rounded' || type === 'rounded-checkbox' ? PR(15) : 0
    },
    checkBoxTxt: {
      color: selected ? GlobalStyle[APP_SHORTCUT_NAME].darkColor : GlobalStyle.global.black,
      fontSize: PR(16),
      lineHeight:PR(25),
      opacity: selected ? 1 : 0.8
    }
  })
}
  
export default CommonCheckBox;