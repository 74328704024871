import React, {useState, useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity, TextInput} from 'react-native';
import Text from '../../CommonCustomTxt';
import CommonSelect from '../../CommonSelect';
import GlobalStyles from '../../../styles/GlobalStyles';
import CommonBtn from '../../CommonBtn';
import CommonActionModal from '../../CommonActionModal';
import CommonRadioForm from '../../CommonAddEvents/CommonRadioForm';
import Icon from 'react-native-vector-icons/Feather';
import { useTranslation } from 'react-i18next';
import {translate} from 'common/src/services/translationList';
import {widthPercentageToDP as PR} from '../../../styles/PixelRatio';
import CommonModal from '../../CommonModal';

const Daily = ({activateBtn, step, getData, weeklyDays, savedData, treatmentType, checkDF, boolDF}) => {
  const {t} = useTranslation();
  const [weekModal, setWeekModal] = useState<boolean>(false);
  const [momentModal, setMomentModal] = useState<boolean>(false);
  const [unit, setUnit] = useState<string>(savedData?.dosage ? savedData?.dosage[0]?.unit : '');
  const [daily_frequencies, setDaily_frequencies] = useState<string>(savedData?.daily_frequencies? savedData?.daily_frequencies : '')
  const [momentsOfDay, setMomentsOfDay] = useState<Array<any>>([]);
  const [momentsToDisplay, setMomentsToDisplay] = useState<Array<any>>([]);
  const [morning, setMorning] = useState<string>()
  const [early_morning, setEarlyMorning] = useState<string>()
  const [noon, setNoon] = useState<string>()
  const [afternoon, setAfternoon] = useState<string>()
  const [evening, setEvening] = useState<string>()
  const [someDays, setSomeDays] = useState<Array<any>>(weeklyDays);
  const [stopRenderOldData, setStopRenderOldData] = useState<boolean>(false)
  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(true)
  const [momentToChange, setMomentToChange] = useState('')
  const [incorrectDataModal, setIncorrectDataModal] = useState(false)
  const [totalDosage, setTotalDosage] = useState(0)
  let momentsToDisplayLength = momentsToDisplay.length
  const isEmpty = inputObject => {
    return Object.keys(inputObject).length === 0;
  };

  useEffect(() => {
    let count = 0
    let arr:any = []
    let obj = {week_frequency: someDays, daily_frequencies, totalDose: totalDosage.toString() + ' ' + translate(t, unit, 'list.units')}
    momentsToDisplay.forEach((item) => {
      if(item?.value?.length && parseFloat(item?.value) !== 0){
        count = count+1
        arr.push({moment: JSON.parse(JSON.stringify(item.name).replace(/[\[\]']+/g,'')), dose: parseFloat(item.value), unit: unit})
      }
    })
    Object.assign(obj, {dosage:arr});
    if(count === momentsToDisplay.length && unit.length > 0 && checkDF) {
      activateBtn(false)
      setShowErrorMsg(false)
      getData(obj)
    }
    else if(!boolDF && daily_frequencies.length > 0){
      activateBtn(false)
      setShowErrorMsg(false)
      checkDF(obj)
    }
    else {
      activateBtn(true)
      setShowErrorMsg(true)
    }
  },[early_morning, morning, noon, afternoon, evening, unit, momentsToDisplay, someDays])

  const move = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0])
    return arr
  }

  useEffect(()=>{
    let arr:any = []
    // @ts-ignore 
    t<string | TemplateStringsArray>('translation:list.moments', {returnObjects: true}).map((name,i) => {
      arr.push({name:Object.keys(name), pos: i})
    })
    move(arr, 0, 3)
    move(arr, 2, 4)
    move(arr, 3, 1)
    setMomentsOfDay(arr)
    if(step === "daily"){
      someDays.push(
        {day:'monday', pos:0}, 
        {day:'tuesday', pos:1}, 
        {day:'wednesday', pos:2}, 
        {day:'thursday', pos:3}, 
        {day:'friday', pos:4}, 
        {day:'saturday', pos:5}, 
        {day:'sunday', pos:6})
    }
  },[])

  // checking if there are saved data and fill out the fields with the proper values
  useEffect(() => {
    if(savedData?.dosage?.length > 0 && momentsOfDay.length > 0 && momentsToDisplay.length === 0) {
      if(savedData?.dosage?.length === 1){
        setDaily_frequencies('once')
        moments('once')
      }
      else if(savedData?.dosage?.length === 2){
        setDaily_frequencies('twice')
        moments('twice')
      }
      else if(savedData?.dosage?.length === 3){
        setDaily_frequencies('three times')
        moments('three times')
      } else {
        setDaily_frequencies('more')
        moments('more')
      }
    }
    if(!isEmpty(savedData) && momentsToDisplay.length > 0 && momentsOfDay.length > 0 && !stopRenderOldData){
      savedData?.dosage?.forEach((data,i) => {
        if(data.dose)
          onChangeValue(data.dose.toString(), data.moment, i)
      })
    }
  },[momentsOfDay, momentsToDisplay])

  // sort the moments to display when moments length is bigger or equal to 4
  useEffect(() => {
    let tempMomToDisplay = [...momentsToDisplay]
    if(momentsToDisplay.length >= 3){
      tempMomToDisplay.sort((a,b) => (a.pos > b.pos) ? 1 : ((b.pos > a.pos) ? -1 : 0))
      setMomentsToDisplay(tempMomToDisplay)
    }
    calculateTotal()
  },[momentsToDisplayLength])

  useEffect(() => {
    if (totalDosage > 5 && unit === 'g')
      setIncorrectDataModal(true)
  }, [totalDosage])

  const onChangeValue = (e, name, i) => {
    // if(parseFloat(e) === 0){
      e = e.replace(/,/g, '.')
    // }

   
    if(name === 'early_morning') {
      setEarlyMorning(e === '' ? undefined : e)
      Object.assign(momentsToDisplay[i], {value: e});
    }
    if(name === 'morning') {
      setMorning(e === '' ? undefined : e)
      Object.assign(momentsToDisplay[i], {value: e});
    }
    if(name === 'noon') {
      setNoon(e === '' ? undefined : e)
      Object.assign(momentsToDisplay[i], {value: e});
    }
    if(name === 'afternoon') {
      setAfternoon(e === '' ? undefined : e)
      Object.assign(momentsToDisplay[i], {value: e});
    }
    if(name === 'evening') {
      setEvening(e === '' ? undefined : e)
      Object.assign(momentsToDisplay[i], {value: e});
    }
  }

  const onChangeMoment = (name) => {
    if(name === 'early_morning') {
      setEarlyMorning(undefined)
    }
    if(name === 'morning') {
      setMorning(undefined)
    }
    if(name === 'noon') {
      setNoon(undefined)
    }
    if(name === 'afternoon') {
      setAfternoon(undefined)
    }
    if(name === 'evening') {
      setEvening(undefined)
    }
  }

  const displayValue = (dosName) => {

    if(dosName === 'early_morning') return early_morning
    if(dosName === 'morning') return morning
    if(dosName === 'noon') return noon
    if(dosName === 'afternoon') return afternoon
    if(dosName === 'evening') return evening
  }

  const calculateTotal = () => {
    setTotalDosage(momentsToDisplay.reduce((a, b) => +a + +(b.value? parseFloat(b.value) : 0), 0));
  }

  const frequencyOfWeek = () => {
    if(someDays.length >= 7)
      return t('translation:treatment.add_medication.step_frequency.summary.every_day')
    else {
      return someDays.map((item, i) => {
        if(i < someDays.length-1) return t('translation:general.date.days.'+item.pos+'.'+item.day)+', '
        else return t('translation:general.date.days.'+item.pos+'.'+item.day)
      })
    }
  }
  // update select list of(once, twice, etc...)
  const updateData = (updatedData) => {
    updatedData.map((item:any) => {
      if(item.selected !== undefined && item.selected){
        moments(JSON.parse(item.value.replace(/[\[\]']+/g,'')))
        setDaily_frequencies(JSON.parse(item.value.replace(/[\[\]']+/g,'')))
      }
    })
  }

  // check how many moments will display in the beginning
  const moments = (data) => {
    let arr:any = []
    let tempIndexArr:any = []
    // find the position of the save data moments
    let findPos = savedData?.dosage?.map((item) => {
      return momentsOfDay.findIndex(mom => item.moment === mom.name[0])
    })
    // store the position in a temp array 
    findPos?.forEach(item => {
      if(item > -1)
        tempIndexArr.push(item)
    })
    // check if temp array is empty and initialize it
    if(tempIndexArr.length === 0)
      tempIndexArr = [0,1,2,3,4]

    if(data === 'once')
      for(let i = 0; i < 1; i++) {
        arr.push(momentsOfDay[tempIndexArr[i]])
      }
    else if(data === 'twice'){
      for(let i = 0; i < 2; i++) {
        arr.push(momentsOfDay[tempIndexArr[i]])
      }
    }
    else if(data === 'three times')
      for(let i = 0; i < 3; i++) {
        arr.push(momentsOfDay[tempIndexArr[i]])
      }
    else 
      for(let i = 0; i <= 4; i++) {
        if(tempIndexArr[i] !== undefined)
          arr.push(momentsOfDay[tempIndexArr[i]])
      }
    setMomentsToDisplay(JSON.parse(JSON.stringify(arr)))
  }
  // close modal
  const changeWeekModalVisibility = () => {
    setWeekModal(!weekModal)
  }

  const changeMomentModalVisibility = () => {
    setMomentModal(!momentModal)
  }

  // update week day selection
  const updateWeekDays = (values) =>{
    let arr:any = []
    values.map((item:any) => {
      if(item.selected !== undefined && item.selected){
        arr.push({day:JSON.parse(item.value.replace(/[\[\]']+/g,'')), pos:item.pos})
      }
    })
    setSomeDays(arr)
    setWeekModal(!weekModal)
  }
  // update moment according to modal selection
  const updateMoment = (values) =>{
    let tempMomArr:any = JSON.parse(JSON.stringify(momentsToDisplay))
    values.forEach((item:any) => {
      if(item.selected !== undefined && item.selected){
        tempMomArr.forEach((mItem, i) => {
          let localName = JSON.stringify(Object.values(mItem.name))
          if(JSON.parse(localName.replace(/[\[\]']+/g,'')) === momentToChange){
            tempMomArr[i].name = JSON.parse(item.value)
            tempMomArr[i].pos = item.pos
            tempMomArr[i].value = undefined
            onChangeMoment(momentToChange)
          }
        })
      }
    })
    setMomentsToDisplay(tempMomArr)
    setMomentModal(!momentModal)
  }

  // checking which moments are selected inside the modal
  const selectedMoments = (propsMoment) => {
    if(propsMoment !== undefined) {
      let findMoments = momentsToDisplay.find(selectedMoment => {
        let localName = JSON.stringify(Object.values(selectedMoment.name))
        let parseName = JSON.parse(localName.replace(/[\[\]']+/g,''))
        if(parseName === JSON.parse(propsMoment.replace(/[\[\]']+/g,'')))
          return parseName
      }) 
      if(findMoments !== undefined) 
        return true
      else 
        return false
    }
  }

  // checking which days are selected inside the modal
  const selectedDay = (propsDay) => {
    if(propsDay !== undefined) {
      let findDay = someDays.find(days => days.day === JSON.parse(propsDay.replace(/[\[\]']+/g,''))) 
      if(findDay !== undefined) return true
    }
  }
  // add one more moment
  const addIntakeMoment = () => {
    let findItem:any = momentsOfDay.find(itemMom => {
      let momentsOfDayLocalName = JSON.stringify(Object.values(itemMom.name))
      let momentsOfDayParseName = JSON.parse(momentsOfDayLocalName.replace(/[\[\]']+/g,''))
      return !momentsToDisplay.some(e => {
        let momentsToDisplayLocalName = JSON.stringify(Object.values(e.name))
        let momentsToDisplayParseName = JSON.parse(momentsToDisplayLocalName.replace(/[\[\]']+/g,''))
        return momentsOfDayParseName === momentsToDisplayParseName

      })
    })
    setMomentsToDisplay(oldArray => [...oldArray, JSON.parse(JSON.stringify(findItem))])
  }
  
  // remove a moment
  const removeIntakeMoment = (item) => {
    let removeLocalName = JSON.stringify(Object.values(item.name))
    let removeParseName = JSON.parse(removeLocalName.replace(/[\[\]']+/g,''))
    let findItems:any = momentsToDisplay.filter(moment => moment !== item)
    setMomentsToDisplay(findItems)
    setStopRenderOldData(true)
    onChangeMoment(removeParseName)
  }

  const checkMomModal = (name) => {
    if(momentsToDisplayLength < 5){
      setMomentModal(!momentModal); 
      setMomentToChange(name)
    }
  }

  const closeIncorrectDataModal = () => {
    setIncorrectDataModal(false)
  }

  const updateUnit = (e) => {
    if (e === 'g') {
      for (let i = 0; momentsToDisplay[i]; i++) {
        const _value = momentsToDisplay[i].value
        if (_value && parseInt(_value) >= 5) {
            setIncorrectDataModal(true)
            break;
        }
      }
    }
    setUnit(e)
    calculateTotal();
  } 

const updateDosage = (e, parseName, i) => {
  onChangeValue(e.trim().replace(/[^0-9 .|,]/g, ''), parseName, i)
  calculateTotal();
}

  return (
    <>
      { 
        incorrectDataModal &&
          <CommonModal 
              testID="warningModalID" 
              type={'info'} 
              title={t('translation:modal.titles.warning')} 
              text={t('translation:modal.incorrect_data.treatment_dosage')} 
              buttons={[
                  {
                    type: 'primary',
                    title: t('translation:modal.btn.ok'),
                    click: closeIncorrectDataModal
                  }
                ]} 
              onClose={closeIncorrectDataModal}
          />
      }
      { weekModal &&
        <CommonActionModal 
          testID={"weekModalID"}
          title={t('translation:modal.select.title_multiple')} 
          modalType={'select-check'} 
          buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: updateWeekDays, action: 'save'}]} 
          onClose={changeWeekModalVisibility} 
          // @ts-ignore
          data={t<string | TemplateStringsArray>('translation:general.date.days', {returnObjects: true}).map((name,i) => {
            return {label:t('translation:general.date.days.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)), selected:selectedDay(JSON.stringify(Object.keys(name))), pos:i}
          })}
        />
      }
      { momentModal &&
        <CommonActionModal 
          testID={"momentModalID"}
          title={t('translation:modal.select.title_one')} 
          modalType={'select-radio'} 
          buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: updateMoment, action: 'save'}]} 
          onClose={changeMomentModalVisibility} 
          // @ts-ignore
          data={t<string | TemplateStringsArray>('translation:list.moments', {returnObjects: true}).map((name,i) => {
            if(selectedMoments(JSON.stringify(Object.keys(name))) === false){
              return {label:t('translation:list.moments.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)), pos:i}
            }
          }).filter(item => item !== undefined)}
        />
      }
      {!boolDF && 
        <View style={styles.questContainer}>
          <Text style={styles.questTxt}>{t('translation:treatment.add_medication.step_frequency.step_2.label')}</Text>
          <View style={{marginBottom:PR(15)}}></View>
          {/* @ts-ignore */}
          <CommonSelect type={'radio'} data={t<string | TemplateStringsArray>('translation:list.daily_frequencies', {returnObjects: true}).map((name,i) => {
            return {label:t('translation:list.daily_frequencies.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name)), position: i}
          })} onSelect={updateData}/>
          <View style={{marginBottom:PR(15)}}></View>
        </View>
      }
      {momentsToDisplay.length > 0 && boolDF &&
        <View style={styles.questContainer}>
          <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
            <Text style={styles.label}>{t('translation:treatment.add_medication.step_frequency.summary.week_frequency')}</Text>
            <TouchableOpacity testID="weekFrequencyID" style={styles.summaryContainer} onPress={()=>setWeekModal(!weekModal)}>
              <View style={styles.inputSummaryContainer}>
                <Text style={styles.inputSummaryValue}>{frequencyOfWeek()}</Text>
              </View>
            </TouchableOpacity>
          </View>
          <Text style={styles.questTxt}>{t('translation:treatment.add_medication.step_frequency.step_3.label')}</Text>
          <View style={{marginBottom:PR(15)}}></View>
          {momentsToDisplay?.map((mom,i) => {
            let localName = JSON.stringify(Object.values(mom.name))
            let parseName = JSON.parse(localName.replace(/[\[\]']+/g,''))
            return (
              <View key={i} style={styles.momentsContainer}>
                <TouchableOpacity style={{width: '40%'}} onPress={()=>{checkMomModal(parseName)}}>
                  <Text style={styles.momentsTxt}>{t('translation:list.moments.'+mom.pos+'.'+mom.name)}</Text>
                </TouchableOpacity>
                <CommonRadioForm
                  value={unit.length === 0 ? t('translation:treatment.drug.unit') : treatmentType === 'drug' ? unit : translate(t, unit, 'list.other_units')}
                  setValue={updateUnit}
                  cmsListLink={treatmentType === 'drug' ? 'list.units': 'list.other_units'}
                  patientList='unit_inputs'
                  removeStyle={true}
                  customWidth={'30%'}
                />
                <View style={{width:'20%'}}>
                <TextInput
                    onChangeText={(e) => {updateDosage(e, parseName, i)}}
                    placeholder={t('translation:treatment.drug.dose')}
                    placeholderTextColor={GlobalStyles.global.grey}
                    autoCorrect={false}
                    style={styles.inputField}
                    value={displayValue(parseName) || ''}
                    keyboardType="numeric"
                    testID={"doseID"+mom.name}
                />
                </View>
                {momentsToDisplay.length > 1 &&
                  <TouchableOpacity onPress={()=>removeIntakeMoment(mom)} style={{width:'10%'}}>
                    <Icon name={"trash-2"} size={PR(18)} color={'red'} />
                  </TouchableOpacity>
                }
              </View>
            )
          })}
          {showErrorMsg &&
            <View style={{alignSelf:'center'}}>
              <Text style={styles.errorTxt}>{t('translation:treatment.add_medication.errors.invalid_intake')}</Text>
            </View>
          }
          {momentsToDisplay.length !== momentsOfDay.length &&
            <View style={{padding: PR(5)}}>
              <CommonBtn type="tertiary" click={() => {addIntakeMoment()}} title={t('translation:treatment.add_medication.step_frequency.step_3.add_intake_moment')} testID="addIntakeID"/>
            </View>
          }
          <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:'90%', paddingTop:PR(10), paddingBottom:PR(10)}}>
            <Text style={styles.TotalTxt}>{t('translation:treatment.add_medication.step_frequency.step_3.total')}</Text>
            <Text style={styles.TotalTxt}>
              {unit.length > 0 && totalDosage > 0 &&
                totalDosage+' '+translate(t, unit, 'list.units')
              }
            </Text>
          </View>
        </View>
      }
    </>
  )
}

const styles = StyleSheet.create({
  questContainer: {
    paddingTop: PR(20),
    width: '90%',
    alignItems: 'center',
    alignSelf:'center'
  },
  questTxt: {
    fontSize: PR(16),
    color: GlobalStyles.global.black
  },
  momentsContainer: {
    flexDirection:'row', 
    width:'90%', 
    alignItems:'center', 
    alignSelf:'center', 
    justifyContent:'space-between', 
    paddingVertical:PR(10),
    borderBottomWidth: PR(1),
    borderBottomColor: GlobalStyles.global.lightGrey
  },
  momentsTxt: {
    fontSize: PR(14),
    color: GlobalStyles.global.black
  },
  TotalTxt: {
    fontSize: PR(16),
    color: GlobalStyles.global.black,
    fontFamily: GlobalStyles.global.fontFamily.Bold
  },
  inputField: {
    flex: 1,
    flexGrow: 1,
    color: GlobalStyles.global.black,
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  label: { 
    marginBottom: PR(10),
    marginLeft: PR(20),
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  summaryContainer: {
    backgroundColor: GlobalStyles.global.footerGrey,
    borderRadius: PR(20),
  },
  inputSummaryContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: PR(62),
    padding: PR(10),
    fontSize: PR(16),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  errorTxt: {
    color: GlobalStyles.global.redError,
    fontSize: PR(14)
  },
  inputSummaryValue: {
    flex: 1,
    flexGrow: 1,
    color: GlobalStyles.global.black,
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
})

export default Daily;