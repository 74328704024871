export enum DeviceDetailsActions {
    DEVICE_OS_VERSION = "DEVICE_OS_VERSION",
}

export interface DeviceOsVersionInterface {
    settings: {
        os_version: string
    },
}

export const deviceOsVersionAction = (payload: DeviceOsVersionInterface) => {
    return {
        type: DeviceDetailsActions.DEVICE_OS_VERSION,
        ...payload
    };
};