import React, {useState, useEffect} from 'react';
import GlobalStyles from '../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../env.json';
import ToggleSwitch from 'toggle-switch-react-native';

/**
 * for more parameters of the toggle-switch-react-native
 * please visit the https://github.com/aminebenkeroum/toggle-switch-react-native#api
 */

interface SwitchTypes {
  isOn: boolean | undefined,
  size: 'large' | 'medium' | 'small',
  onToggle: any,
  testID: string
}

const CommonSwitchBtn = ({isOn, size, onToggle, testID}:SwitchTypes) => {
  const [isEnabled, setIsEnabled] = useState(isOn)
  useEffect(() => {
    setIsEnabled(isOn)
  },[isOn])
  const sendBackStatus = (status) => {
    onToggle(status)
  }
  return (
    <>
      <ToggleSwitch
        testID={testID}
        isOn={isEnabled}
        onColor={GlobalStyles[APP_SHORTCUT_NAME].color}
        offColor={GlobalStyles.global.lightGrey}
        size={size}
        onToggle={status => sendBackStatus(status)}
      />
    </>
  )
}

export default CommonSwitchBtn;