import {call, put, takeLatest} from "redux-saga/effects";
import {UPDATE_PATIENT_ACTION} from "./userActions";
import {UPDATE_PATIENT_SUCCESS} from "../actions";
import {makeRequest} from "../../services/requestService";


export function* updatePatientSaga(payload:any) {
    const {data, patientId} = payload
    yield call(makeRequest, {
        data,
        path: `/patients/${patientId}`,
        method: 'PUT',
        responseAction: UPDATE_PATIENT_SUCCESS,
        ...payload
    })
}

export default function* watchUserAuthentication() {
    yield takeLatest(UPDATE_PATIENT_ACTION, updatePatientSaga);
}