import GlobalStyles from '../../styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { StyleSheet } from 'react-native';

const CommonStyle = StyleSheet.create({
    container: {
        borderRadius: PR(20),
        width:'100%',
        alignSelf:'center', 
        marginBottom:PR(8),
        backgroundColor: GlobalStyles.global.white,
        shadowColor: '#333333',
        shadowOffset: {
        width: PR(2),
        height: PR(10),
        },
        shadowOpacity: 0.10,
        shadowRadius: 13.97,
        elevation: 10
    },
    contentContainer: {
        flex: 1,
        backgroundColor: GlobalStyles.global.white,
    },
    wrapper: {
        flexDirection: 'row',
        minHeight: PR(62),
        padding: PR(10),
        paddingLeft:PR(20),
        width: '100%',
        alignItems:'center'
    },
    textContainer: {
        flex: 1,
        flexGrow: 1,
        marginLeft: PR(15)
    },
    title: {
        fontSize: PR(16),
        color: GlobalStyles.global.black,
        fontFamily: GlobalStyles.global.fontFamily.Medium
    },
    icon: {
        alignSelf: "center"
    },
    screenContainer: {
        flex: 1,
        backgroundColor: GlobalStyles.global.white
    },
    pageContainer: {
        flexGrow: 1,
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignSelf:'center',
        width: '90%',
    },
    completedContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
    },
    completedText: {
        fontSize: PR(12),
        paddingHorizontal: PR(10),
    },
});

export default CommonStyle;