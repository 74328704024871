import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';
import GlobalStyles from '../styles/GlobalStyles';
import InputField from './InputField';
import CommonBtn from './CommonBtn';
import Text from './CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonNoNeuro = (props:any) => {
  const {t} = useTranslation();
  const [neuroName, setNeuroName] = useState<any>('');
  // send the obj to parent component
  const next = () => {
    let neurologistName:any = ''
    if (neuroName.length === 0) {
      neurologistName = null
    } else {
      neurologistName = neuroName
    }
    let obj = {patient:{neurologist_name:neurologistName, onboarding:{neurologistDone:true},callPermissions:false}}
    props.nextAction(obj)
  }
  return (
    <>
      <View style={styles.container} testID="app_not_suggested_by_neuro">
        <Text renderAsHtml={true} style={styles.txt}>{t('translation:login.prescription_no_text')}</Text>
      </View>
      <InputField 
          labelText={t('translation:login.neurologist_name')}
          inputType="string" 
          customStyle={{marginBottom:PR(30)}}
          changeText={setNeuroName}
          placeholderTxt={t('translation:login.neurologist_name_placeholder')}
          value={neuroName}
        />
        <CommonBtn testID="next_btn" type='primary' click={next} title={t('translation:register.form.btn_register')}/>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '90%',
    alignSelf: 'center',
    marginBottom: PR(15)
  },
  txt: {
    textAlign: 'justify',
    fontSize: PR(14),
    color: GlobalStyles.global.greyToBlack,
    lineHeight:PR(25)
  },
})

export default CommonNoNeuro;