import React from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import GlobalStyles from '../../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../../env.json';
import Text from '../CommonCustomTxt';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import moment from "moment";

type OwnProps = {
    appointment: Record<string, Record<string, object>>,
    callback: (appItem:Record<string, Record<string, object>>) => void;
}

const CommonFuturePast = ({appointment, callback}:OwnProps) => {

    const sendBackSelected = () => {
        callback(appointment)
    }

    return(
        <TouchableOpacity onPress={sendBackSelected} style={styles.container}>
            <View style={styles.wrapper}>
                <Text style={styles.txt}>{moment(appointment.date).format('DD/MM/yyyy - HH:mm')}</Text>
                <Text style={styles.txt}>{appointment.doctor_info.name}</Text>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        borderRadius: PR(20),
        width:'90%',
        alignSelf:'center', 
        marginBottom:PR(16),
        backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].btnColor,
    },
    wrapper: {
        flexDirection: 'column',
        minHeight: PR(62),
        padding: PR(20),
        width: '80%',
    },
    txt: {
        fontSize: PR(16),
        color: GlobalStyles.global.white,
        fontFamily: GlobalStyles.global.fontFamily.Medium
    }

})

export default CommonFuturePast;