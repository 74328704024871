import React, {useState} from 'react';
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import CommonRadioForm from '../CommonAddEvents/CommonRadioForm';
import CommonBtn from '../CommonBtn';
import Text from '../CommonCustomTxt';
import InputField from '../InputField';
import CommonActionModal from '../CommonActionModal';
import GlobalStyles from '../../styles/GlobalStyles';
import { useTranslation } from 'react-i18next';
import CommonFooter from '../CommonFooter';
import CommonModal from '../CommonModal';
import { Treatment } from '../../types';
import {checkBottomScroll} from '../../services/utils';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';

// TODO: narrow this interface correctly
interface Props {
  getAnswers: any;
  data: any;
  navigation: any;
  existingTreatments: Treatment[];
} 

const CommonAddTreatmentInfo = ({getAnswers, data, navigation, existingTreatments}: Props) => {
  const {t} = useTranslation();
  const [displayMedicineModal, setMedicineModal] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [reasonTreat, setReasonTreat] = useState<string>(data?.reasonTreat || '')
  const [typeTreat, setTypeTreat] = useState<string>(data?.typeTreat || '')
  const [freqStep, setFreqStep] = useState<string>(data?.freqStep || '')
  const [typeIntake, setTypeIntake] = useState<string>(data?.typeIntake || '')
  const [nameOfTreat, setNameOfTreatment] = useState<string>(data?.nameOfTreat || '')

  const [showReasonQuestion, setShowReasonQuestion] = useState<boolean>(data?.reasonTreat ? false :true)
  const [showTypeTreatQuestion, setShowTypeTreatQuestion] = useState<boolean>(false)
  const [showTypeIntakeQuestion, setShowTypeIntakeQuestion] = useState<boolean>(false)

  const [showTypeTreatForm, setShowTypeTreatForm] = useState<boolean>(data?.typeTreat ? true : false)
  const [showTypeIntakeForm, setShowTypeIntakeForm] = useState<boolean>(data?.typeIntake ? true : false)
  const [existingTreatment, setExistingTreatment] = useState<undefined | Treatment>();
  const [showTreatmentExistsModal, setShowTreatmentExistsModal] = useState(false);
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);

  const goToDrugsScreen = () => {
    navigation.navigate('Drugs', {onReturn: (item) => {
      setNameOfTreatment(item)
    }})
  }

  const changeMedicineModalVisibility = () => {
    setMedicineModal(!displayMedicineModal)
  }

  const typeOfTreatments = (e) => {
    if (['device', 'diet', 'exercices', 'drug'].indexOf(e) >= 0){
      setTypeTreat(e)
      setFreqStep(e)
    }
    else{
      setTypeTreat(e)
      setFreqStep('custom')
    }
  }

  const updateMedicine = (values) =>{
    values.map((item:any) => {
      if(item.selected !== undefined && item.selected){
        setNameOfTreatment(item.value)
      }
    })
    setMedicineModal(!displayMedicineModal)
  }  
  const sendBackAnswers = () =>{
    let obj = {
      reasonTreat: reasonTreat,
      typeTreat: typeTreat,
      typeIntake: typeIntake,
      nameOfTreat: nameOfTreat,
      freqStep: freqStep
    }
    getAnswers(obj)
  }

  let buttonDisabled = !(reasonTreat && typeTreat && typeIntake && nameOfTreat);
  if (typeTreat !== 'drug' && !!typeTreat) buttonDisabled = !(reasonTreat && typeTreat && nameOfTreat)

  return(
    <>
      <View style={styles.horizontalDiv}></View>
      <ScrollView
        testID="AddMedicationInfo_scrollView"
        scrollEventThrottle={16}
        onScroll={(event) => {
          let calc = checkBottomScroll(event.nativeEvent, PR(15));
            if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
        }}
      >
        <View
          onLayout={(event: any) => {
            setContentHeight(event.nativeEvent.layout.height)
          }}
        >
          { displayMedicineModal &&
            <CommonActionModal 
              testID={"MedicineModalID"}
              title={t('translation:modal.select.title_one')} 
              modalType={'select-radio'} 
              buttons={[{title: t('translation:general.header.btn_validate'), type: 'primary', click: updateMedicine, action: 'save'}]} 
              onClose={changeMedicineModalVisibility} 
              // @ts-ignore
              data={[
                
                {label:'Panadol', value: 'Panadol'},
                {label:'Aspirin', value: 'Aspirin'},
                {label:'Cold Sore Treatment', value: 'Cold Sore Treatment'}
              ]}
            />
          }
          { !showReasonQuestion &&
            <CommonRadioForm
              value={reasonTreat}
              setValue={v => {
                setReasonTreat(v)
                setShowTypeTreatQuestion(true)
              }}
              cmsListLink='list.medication_reasons'
              patientList='reason_inputs'
              label={t('translation:treatment.add_medication.step_info.summary.reason')}
              openModal={!reasonTreat}
            />
          }
          { showTypeTreatForm &&
            <CommonRadioForm
              value={typeTreat}
              setValue={v => {
                typeOfTreatments(v)
                setShowTypeIntakeQuestion(true)
              }}
              cmsListLink='list.medication_types'
              patientList='treatment_type_inputs'
              label={t('translation:treatment.add_medication.step_info.summary.type')}
              openModal={!typeTreat}
            />
          }
          { showTypeIntakeForm && typeTreat === 'drug' &&
            <CommonRadioForm
              value={typeIntake}
              setValue={setTypeIntake}
              cmsListLink='list.intake_types'
              patientList='intake_type_inputs'
              label={t('translation:treatment.add_medication.step_info.summary.intake')}
              openModal={!typeIntake}
            />
          }
          { typeIntake.length > 0 && typeTreat === 'drug' && 
            <View style={{width:'90%',alignSelf:'center', marginBottom:30}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_info.summary.name')}</Text>
              <TouchableOpacity testID="intakeID" style={styles.summaryContainer} onPress={() => goToDrugsScreen()}>
                <View style={styles.inputSummaryContainer}>
                  <Text style={styles.inputSummaryValue}>{nameOfTreat.length === 0 ? t('translation:treatment.add_medication.step_info.summary.name_placeholder') : nameOfTreat}</Text>
                </View>
              </TouchableOpacity>
            </View>
          }
          {(typeTreat !== 'drug' && !!typeTreat) &&
            <InputField 
              labelText={t('translation:treatment.add_medication.step_info.summary.name')}
              inputType="string" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setNameOfTreatment}
              placeholderTxt={t('translation:treatment.add_medication.step_info.summary.name_placeholder')} 
              testID="remarksID"
              value={nameOfTreat}
            />
          }
          {showTreatmentExistsModal && 
            <CommonModal 
              testID="modal__treatment-exists"
              type="info"
              onClose={() => { setShowTreatmentExistsModal(false); }}
              title={t('translation:modal.titles.warning')}
              text={t('translation:treatment.add_medication.step_info.modal')}
              buttons={[
                {
                  type: 'tertiary',
                  title: t('translation:modal.btn.cancel'),
                  click: () => { setShowTreatmentExistsModal(false); }
                },
                {
                  type: 'secondary',
                  title: t('translation:treatment.add_medication.step_info.modal_history'),
                  click: () => { setShowTreatmentExistsModal(false); return navigation.navigate('MedicationHistory', {id: existingTreatment?.id}); }
                },
                {
                  type: 'primary',
                  title: t('translation:treatment.add_medication.step_info.modal_add'),
                  click: () => { setShowTreatmentExistsModal(false); return navigation.navigate('EditMedication', {data: existingTreatment, type:'dose', newDose: existingTreatment}) }
                }
              ]}
              />
          }
          { !reasonTreat && showReasonQuestion && 
            <View style={styles.questContainer}>
              <Text style={styles.questTxt}>{t('translation:treatment.add_medication.step_info.step_1.label')}</Text>
              <View style={{marginBottom:PR(15)}}></View>
              <CommonBtn testID={"yesID"} type={'secondary'} title={t('translation:treatment.add_medication.step_info.step_1.one')} click={() => {
                setShowReasonQuestion(false)
                setReasonTreat('epilepsy')
                setShowTypeTreatQuestion(true)
              }}/>
              <View style={{marginBottom:PR(15)}}></View>
              <CommonBtn testID={"anotherReasonID"} type={'secondary'} title={t('translation:treatment.add_medication.step_info.step_1.two')} click={() => {
                setShowReasonQuestion(false)
                }}/>
              <View style={{marginBottom:PR(15)}}></View>
            </View>
          }
          { !typeTreat && showTypeTreatQuestion && 
            <View style={styles.questContainer}>
              <Text style={styles.questTxt}>{t('translation:treatment.add_medication.step_info.step_2.label')}</Text>
              <View style={{marginBottom:PR(15)}}></View>
              <CommonBtn testID={"yesID"} type={'secondary'} title={t('translation:treatment.add_medication.step_info.step_2.one')} click={() => {
                setShowTypeTreatQuestion(false)
                typeOfTreatments('drug')
                setShowTypeTreatForm(true)
                setShowTypeIntakeQuestion(true)
              }}/>
              <View style={{marginBottom:PR(15)}}></View>
              <CommonBtn testID={"anotherReasonID"} type={'secondary'} title={t('translation:treatment.add_medication.step_info.step_2.two')} click={() => {
                setShowTypeTreatQuestion(false)
                setShowTypeTreatForm(true)
                }}/>
              <View style={{marginBottom:PR(15)}}></View>
            </View>
          }
          { !typeIntake && showTypeIntakeQuestion && typeTreat === 'drug' &&
            <View style={styles.questContainer}>
              <Text style={styles.questTxt}>{t('translation:treatment.add_medication.step_info.step_3.label')}</Text>
              <View style={{marginBottom:PR(15)}}></View>
              <CommonBtn testID={"yesID"} type={'secondary'} title={t('translation:treatment.add_medication.step_info.step_3.one')} click={() => {
                setShowTypeIntakeQuestion(false)
                setTypeIntake('pill')
                setShowTypeIntakeForm(true)
              }}/>
              <View style={{marginBottom:PR(15)}}></View>
              <CommonBtn
                testID={"anotherReasonID"}
                type={'secondary'}
                title={t('translation:treatment.add_medication.step_info.step_3.two')}
                click={() => {
                  setShowTypeIntakeQuestion(false)
                  setShowTypeIntakeForm(true)
                }}
              />
            </View>
          }
        </View>
      </ScrollView>
      <CommonFooter atBottom={contentHeight+PR(300)} reachBottom={scrollAtBottom}>
        <CommonBtn
          testID={"nextBtnID"} 
          type={'primary'} 
          title={t('translation:general.next')}
          disable={buttonDisabled}
          click={() => {
            const currentTreatment = existingTreatments.find(treatment => treatment.name === nameOfTreat);
            if (currentTreatment) {
              setExistingTreatment(currentTreatment);
              setShowTreatmentExistsModal(true);
            } else {
              sendBackAnswers();
            }
          }}
        />
      </CommonFooter>
    </>
  )
}

const styles = StyleSheet.create({
  horizontalDiv: {
    borderTopWidth: 1, 
    borderTopColor: GlobalStyles.global.lightGrey,
    width: '90%',
    paddingBottom:PR(20),
    alignSelf:'center'
  },
  questContainer: {
    paddingTop: PR(20),
    width: '90%',
    alignItems: 'center',
    alignSelf:'center'
  },
  questTxt: {
    fontSize: PR(16),
    color: GlobalStyles.global.black
  },
  label: { 
    marginBottom: PR(10),
    marginLeft: PR(20),
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  summaryContainer: {
    backgroundColor: GlobalStyles.global.footerGrey,
    borderRadius: PR(20),
  },
  inputSummaryContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: PR(62),
    padding: PR(10),
    fontSize: PR(16),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputSummaryValue: {
    flex: 1,
    flexGrow: 1,
    color: GlobalStyles.global.black,
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  errorTxt: {
    color: GlobalStyles.global.redError,
    fontSize: PR(14)
  },
})

export default CommonAddTreatmentInfo;