import React from 'react';
import {View, StyleSheet} from 'react-native';
import CommonImage from './CommonImage';
import GlobalStyles from '../styles/GlobalStyles';
import CommonTitle from './CommonTitle';
import CommonSubTitle from './CommonSubtitle';
import {APP_SHORTCUT_NAME} from '../env.json';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';


interface TitleTypes {
  imageName: string,
  title: string,
  subtitle: string
}

const CommonAddMedicationTitleSection = ({imageName, title, subtitle}:TitleTypes) => {
  return(
    <>
      <View style={styles.titleIcon}>
        <CommonImage name={imageName} width={PR(40)} height={PR(40)}/>
      </View>
      <View style={styles.txtTitleWrapper}>
        <CommonTitle color={GlobalStyles[APP_SHORTCUT_NAME].btnColor} text={title} txtAlign='center' />
        <CommonSubTitle text={subtitle} txtAlign='center' />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  titleIcon: {
    alignSelf:'center', 
    borderWidth:PR(2), 
    borderRadius:PR(30), 
    borderColor:GlobalStyles.global.lightGrey, 
    backgroundColor: GlobalStyles.global.lightGrey,
    padding: PR(10),
    marginTop: PR(20)
  },
  txtTitleWrapper: {
    width: '90%',
    alignSelf: 'center',
    paddingTop: PR(20)
  }
})

export default CommonAddMedicationTitleSection;