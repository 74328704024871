import React from 'react';
import {View, StyleSheet, SafeAreaView, ScrollView} from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonTitle from 'common/src/components/CommonTitle';
import CommonBtn from 'common/src/components/CommonBtn';
import Icon from 'react-native-vector-icons/Feather';
import Text from 'common/src/components/CommonCustomTxt';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {useTranslation} from 'react-i18next';

const ReportGenerated = ({navigation}) => {
  const {t} = useTranslation();

  const goBack = () => {
    navigation.navigate('Reports')
  }
  return(
    <SafeAreaView style={styles.wrapper}>
      <ScrollView 
        testID="generate_reports_scrollView"
      >
        <View style={{marginBottom: PR(50)}}></View>
        <View style={styles.IconRow}>
          <View style={styles.Icon}>
            <View style={styles.titleIcon}>
              <Icon name="check" size={PR(120)} color={GlobalStyles.global.white}/>
            </View>
          </View>
        </View>
        <View style={{width:'80%', alignSelf:'center', marginTop:PR(20)}}>
          <CommonTitle 
            text={t('translation:report.title.request')}
            color={GlobalStyles.global.black}
            txtAlign='center'
            size={PR(22)}
            testID='report_requested_id'
          />
          <Text style={styles.txt}>{t('translation:report.body.text.request')}</Text>
        </View>
      </ScrollView>
      <CommonBtn 
        type="primary" 
        click={goBack} 
        title={t('translation:report.btn.back.request')} 
        testID="back_to_reports_id"
      />
      <View style={{marginBottom: PR(15)}}></View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: GlobalStyles.global.white,
    flex: 1,
  },
  txt:{
    fontSize:PR(16),
    color:GlobalStyles.global.black,
    textAlign:'center'
  },
  IconRow: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:PR(20)
  },
  titleIcon: {
    alignSelf:'center', 
    borderWidth:PR(2), 
    borderRadius:PR(90), 
    borderColor:GlobalStyles.helpi.color, 
    backgroundColor: GlobalStyles.helpi.color,
    padding: PR(7),
    width: PR(180),
    height: PR(180),
    alignItems: 'center',
    justifyContent: 'center'
  },
  Icon: {
    position: 'relative',
  },
})

export default ReportGenerated;