import React, { useEffect, useState, useRef } from "react";
import { getRequest } from "../../../services/callService";
import { API_URL } from "common/src/env.json";
import {
  Form,
  FormQuestion,
  QuestionnaireContentChapter,
} from "../../../types";
import { View } from "react-native";
import CommonCustomTxt from "../../CommonCustomTxt";
import CommonStudyFormQuestions from "../../CommonStudyFormQuestions";
import { getCaptionInLanguage } from "../../../helpers/translations";
import Style from "./CommonStyle";

const CommonQuestionnaireContentComponent = ({
  content,
  language,
  handleAnsweringQuestion,
  previousQuestionnaireAnswers,
}: {
  content: QuestionnaireContentChapter;
  language: string;
  handleAnsweringQuestion: (question: FormQuestion, answer: string) => void;
  previousQuestionnaireAnswers: Record<string, object> | null;
}) => {
  const [form, setForm] = useState<undefined | Form>();
  const [questions, setQuestions] = useState<undefined | FormQuestion[]>();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => { isMounted.current = false }
  }, []);

  useEffect(() => {
    const loadForm = () => {
      getRequest(
        `${API_URL}/forms/${content.questionnaireForm}`
      ).then(response => {
        if (response.success) {
          setForm(response.result);
        }
      });
    }

    if (isMounted.current) {
      loadForm();
    }

  }, [content, previousQuestionnaireAnswers]);

  useEffect(() => {
    if (form) {
      const { questions: formQuestions } = form;
      if (
        previousQuestionnaireAnswers !== null &&
        form.id in previousQuestionnaireAnswers
      ) {
        const answers = previousQuestionnaireAnswers[form.id];

        const updatedQuestions: FormQuestion[] = [];
        for (const q of formQuestions) {
          const updatedQuestion =
            q.index in answers ? { ...q, answer: answers[q.index] } : { ...q };
          updatedQuestions.push(updatedQuestion);
        }
        setQuestions(updatedQuestions);
      } else {
        setQuestions(form.questions);
      }
    }
  }, [form, previousQuestionnaireAnswers]);

  return (
    <View
      testID={`content-questionnaire-${content.index}`}
      style={Style.content}
    >
      {form && (
        <CommonCustomTxt style={Style.caption}>
          {getCaptionInLanguage(language, form.title)}
        </CommonCustomTxt>
      )}
      {questions &&
        questions.map((question) => (
          <CommonStudyFormQuestions
            question={question}
            updateQuestion={(answer: any) => {
              const updatedQuestions = [...questions];
              for (const i in updatedQuestions) {
                if (question.index === questions[i].index) {
                  updatedQuestions[i].answer = question.answer;
                }
              }
              setQuestions(updatedQuestions);
              handleAnsweringQuestion(question, answer);
            }}
            disable={false}
          />
        ))}
      {!form && <></>}
    </View>
  );
};

export default CommonQuestionnaireContentComponent;
