import {call, put, takeLatest, select} from "redux-saga/effects";
import {UPDATE_TREATMENT_ACTION, UPDATE_TREATMENT_SUCCESS,} from "./treatmentsActions";
import { UPDATE_DIARY_MEDICATION_SUCCESS } from "../actions";
import {makeRequest} from "../../services/requestService";


export function* editTreatmentSaga(payload:any) {
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    const response = yield call(makeRequest, {
        data: payload.dataToSubmit,
        path: `/patients/${id}/medications/${payload.dataToSubmit.id}`,
        method: 'PUT',
        responseAction: UPDATE_TREATMENT_SUCCESS,
        successMessage: 'translation:treatment.add_medication.success.updated',
        ...payload
    })
    if(response) {
        yield put({ type: UPDATE_DIARY_MEDICATION_SUCCESS, response: {medData:payload.dataToSubmit, updateMedData: true} });
    }
}

export default function* watchUserAuthentication() {
    yield takeLatest(UPDATE_TREATMENT_ACTION, editTreatmentSaga);
}