import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import {API_URL, REACT_NATIVE_ERROR_API_KEY} from "common/src/env.json";

export const request = async ({ data, method, path }) => {
  const token = await getToken();
  try {
    let response = await fetch(API_URL + path, {
      method: method || 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return await response.json();
    
  } catch (e) {
    throw e;
  }
};

export const postRequest = async (endpoint: any, model?: any) => {
  const url = `${endpoint}`;
  const token = await getToken();
  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(model),
    });
    return await response.json();
 
  } catch (e) {
    throw e;
  }
};

export const putRequest = async (endpoint: any, model: any) => {
  const url = `${endpoint}`;
  const token = await getToken();
  try {
    let response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(model),
    });

    return await response.json();
  } catch (e) {
    throw e;
  }
};

export const getRequest = async (endpoint: string) => {
  const url = `${endpoint}`;
  const token = await getToken();
  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
    
  } catch (e) {
    throw e;
  }
};

export const getHeaderRequest = async (endpoint: string) => {
  const url = `${endpoint}`;
  const token = await getToken();
  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response.headers
  } catch (e) {
    throw e;
  }
};

export const deleteRequest = async (endpoint: any) => {
  const url = `${endpoint}`;
  const token = await getToken();
  try {
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    });

    return await response.json();
    
  } catch (e) {
    throw e;
  }
};

export const errorRequest = async (endpoint: any, model?: any) => {
  const url = `${endpoint}`;
  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': REACT_NATIVE_ERROR_API_KEY
      },
      body: JSON.stringify(model),
    });

    return await response.json();
 
  } catch (e) {
    throw e;
  }
};

export const formatDate = (date: any) => {
  return moment(date).format('DD/MM/YYYY');
};

export const formatDateGetTime = (date: any) => {
  return moment(date).format('HH:mm');
};

const getToken = async () => {
  try {
    let token = await AsyncStorage.getItem('token');
    return token;
  } catch (e) {
    return null;
  }
};

export const logout = async () => {
  try {
    await AsyncStorage.setItem('token', '');
  } catch (e) {
    console.log(`Exception: ${e.message}`);
  }
};
