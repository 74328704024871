import React, {useState, useReducer, useEffect} from 'react';
import {View, StyleSheet} from 'react-native';
import GlobalStyles from '../../../styles/GlobalStyles';
import CommonTitle from '../../CommonTitle';
import CommonSwitchBtn from '../../CommonSwitchBtn';
import Text from '../../CommonCustomTxt';
import { useTranslation } from 'react-i18next';
import {widthPercentageToDP as PR} from '../../../styles/PixelRatio';

interface DaysTypes {
  monday?: boolean,
  tuesday?: boolean,
  wednesday?: boolean,
  thursday?: boolean,
  friday?: boolean,
  saturday?: boolean,
  sunday?: boolean
}

const SeveralTimes = ({activateBtn, getData, savedWeekDays}) => {
  const {t} = useTranslation();
  const [week, setWeek] = useState<Array<any>>(savedWeekDays || [])
  const [days, setDays] = useReducer(
    (state:DaysTypes, newState:DaysTypes) => ({...state, ...newState}),
    {})
  useEffect(() => {
    if(savedWeekDays){
      savedWeekDays.forEach(dayItem => {
        setDays({[dayItem.day]: true})
      })
    }
  },[])

  useEffect(() => {
    let obj = week
    if(week.length > 0){
      activateBtn(false)
      getData(obj)
    } else {
      activateBtn(true)
    }

  },[week])

  const addDays = (day, pos, e) => {
    let arr:any = []
    setDays({[day]:e})
    if(e){
      let find = week.find(weekDay => weekDay.day === day)
      if(!find) {
        arr.push(...week, {day:day, pos:pos})
      } 
    } else {
      let remove = week.filter(weekDay => weekDay.day !== day)
      remove.forEach(removeItem => arr.push(removeItem))
    }
    setWeek(arr)
  }
  return(
    <View style={{width:'90%', alignSelf:'center'}}>
      <View style={{alignSelf:'center'}}>
        <CommonTitle text={t('translation:treatment.add_medication.step_frequency.step_5.question')} color={GlobalStyles.global.black}/>
      </View>
        {/* @ts-ignore */}
      {t<string | TemplateStringsArray>('translation:general.date.days', {returnObjects: true}).map((name,i) => {
        return (
          <View key={i} style={styles.wrapper}>
            <Text style={styles.txt}>{t('translation:general.date.days.'+i+'.'+Object.keys(name))}</Text>
            <CommonSwitchBtn 
              isOn={days[JSON.parse(JSON.stringify(Object.keys(name)).replace(/[\[\]']+/g,''))]}
              size={PR(20) > 17 ? "medium" : "small"}
              onToggle={(e) => addDays(JSON.parse(JSON.stringify(Object.keys(name)).replace(/[\[\]']+/g,'')), i, e)}
              testID="moodSwitch"
            />
          </View>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper:{
    flexDirection:'row',
    alignItems:'center',
    alignSelf:'center',
    width:'90%',
    justifyContent:'space-between',
    flex:1,
    paddingBottom: PR(10),
  },
  txt: {
    fontSize: PR(16),
    marginLeft: PR(15),
    color: GlobalStyles.global.greyToBlack
  },

})

export default SeveralTimes;