import React, {useState} from 'react';
import { SafeAreaView, View, Platform, StyleSheet, ScrollView } from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonAddMedicationTitleSection from 'common/src/components/CommonAddMedicationTitleSection';
import { useTranslation } from 'react-i18next';
import ExistingMedicationsList from './ExistingMedicationList';
import CommonFooter from 'common/src/components/CommonFooter';
import {checkBottomScroll} from 'common/src/services/utils';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const AddMedication = ({navigation, route}) => {
  const [scrollState, setScrollState] = useState();
  const [contentHeight, setContentHeight] = useState<number>();
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
  const {t} = useTranslation();

  const addNewTreatmentBtn = () => {
    navigation.navigate('AddTreatments')
  }
  const cancelBtn = () => {
    Platform.OS === 'web' ? window.history.back() : navigation.goBack()
  }
  return(
    <SafeAreaView style={styles.container}>
      <CommonHeader 
        testIDTitle="AddMedicationID"
        title={t('translation:treatment.drug.save')}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
        scroll={scrollState}
      />
      <ScrollView
        testID="AddMedication_scrollView"
        scrollEventThrottle={16}
          onScroll={(event: any) => {
            let calc = checkBottomScroll(event.nativeEvent, 0);
            if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
            setScrollState(event.nativeEvent.contentOffset.y);
          }}
      >
        <View
          onLayout={(event: any) => {
            setContentHeight(event.nativeEvent.layout.height)
          }}
        >
          <CommonAddMedicationTitleSection 
            imageName='treatmentsIcon' 
            title={t('translation:treatment.add_medication.title_creation')}
            subtitle={t('translation:treatment.add_medication.subtitle_creation')}
          />
          <View style={{marginBottom:PR(15)}}></View>
          {route.params.medications.map((med,i) =>{
            let name = med.name;
            let id = med.id
            return <ExistingMedicationsList navigation={navigation} key={i} name={name} id={id} />
          })}
        </View>
      </ScrollView>
      <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
        <CommonBtn testID={"AddNewTreatmentBtnID"} type={'primary'} title={t('translation:treatment.add_medication.button')} click={() => {addNewTreatmentBtn()}}/>
        <View style={{marginBottom:PR(15)}}></View>
        <CommonBtn testID={"cancelBtnID"} type={'secondary'} title={t('translation:modal.btn.cancel')} click={() => {cancelBtn()}}/>
      </CommonFooter>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: GlobalStyles.global.white,
    flex: 1,
  }
})

export default AddMedication;