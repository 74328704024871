import * as types from '../actions';
import {ADD_INTAKE_SUCCESS, UPDATE_TREATMENT_SUCCESS, UPDATE_DOSE_SUCCESS, DELETE_TREATMENT_SUCCESS, DELETE_DOSE_SUCCESS} from '../treatments/treatmentsActions';
import {compareDates} from 'common/src/services/compare';
import moment from 'moment';

export default function(state:any = {
  medications: [],
  medicationsHistory: [],
  editMedicationHistory: []
} , action:any = '') {
  const response = action.response;
  switch(action.type) {
    case types.GET_MEDICATION_SUCCESS:
      return { 
        ...state, 
        medications: response.result
      }; 
    case types.GET_MEDICATION_HISTORY_SUCCESS:
      return { 
        ...state, 
        medicationsHistory: response.result
      };
    case types.GET_EDIT_MEDICATION_HISTORY_SUCCESS:
      return { 
        ...state, 
        editMedicationHistory: response.result
      };
    case types.UPDATE_MEDICATION_INTAKE_SUCCESS:
      return {
        ...state
      }
    case ADD_INTAKE_SUCCESS:
      let today = moment();
      let formatToday = moment(today).format('YYYY-MM-DD')
      if (!response || !response.intake)
        return state
      if(!compareDates(formatToday,response.intake.from, 'yyyy-MM-dd') || (response.intake.to && compareDates(formatToday,response.intake.to, 'yyyy-MM-dd')))
        return {
          ...state,
          medicationsHistory: [...state.medicationsHistory, response],
          editMedicationHistory: [...state.editMedicationHistory, response.intake]
        }
      else {
        return {
          ...state,
          medications: [...state.medications, response],
          medicationsHistory: [...state.medicationsHistory, response],
          editMedicationHistory: [...state.editMedicationHistory, response.intake]
        }
      }
    case UPDATE_TREATMENT_SUCCESS:
      let medHistoryCopy = [...state.medicationsHistory]
      let medCopy = [...state.medications]
      let findHistory = state.medicationsHistory.findIndex(item => item.id === response.result.id)
      let findMed = state.medications.findIndex(item => item.id === response.result.id)
      if(findMed > -1)
        medCopy[findMed] = response.result
      if(findHistory > -1)
        medHistoryCopy[findHistory] = response.result

      medCopy[findMed].intake = state.medications[findMed].intake
      medHistoryCopy[findHistory].intake = state.medicationsHistory[findHistory].intake
      return {
        ...state,
        medications: medCopy,
        medicationsHistory: medHistoryCopy

      }
    case UPDATE_DOSE_SUCCESS:
      let findDoseHistory = state.medicationsHistory.findIndex(item => item.id === response.data.medication)
      let findDoseMed = state.medications.findIndex(item => item.id === response.data.medication)
      let medDoseHistoryCopy = [...state.medicationsHistory]
      let medDoseCopy = [...state.medications]
      if(findDoseMed > -1){
        medDoseCopy[findDoseMed].intake = JSON.parse(JSON.stringify(response.data))
      }
      if(findDoseHistory > -1){
        medDoseHistoryCopy[findDoseHistory].intake = JSON.parse(JSON.stringify(response.data))
      }
      return {
        ...state,
        medications: medDoseCopy,
        medicationsHistory: medDoseHistoryCopy,
        editMedicationHistory: [response.data]
      }
    case DELETE_TREATMENT_SUCCESS:
      let filterHistory = state.medicationsHistory.filter(item => item.id !== response.id)
      let filterMed = state.medications.filter(item => item.id !== response.id)

      let medHistoryFilter = filterHistory
      let medFilter= filterMed
      return {
        ...state,
        medications: medFilter,
        medicationsHistory: medHistoryFilter

      }
    case DELETE_DOSE_SUCCESS:
      let filterDoseHistory = state.medicationsHistory.findIndex(item => item.id === response.deleteDose.medication)
      let filterDoseMed = state.medications.findIndex(item => item.id === response.deleteDose.medication)
      let filterDoseEditMed = state.editMedicationHistory.findIndex(item => item.id === response.deleteDose.id)

      let medDoseHistoryFilter = [...state.medicationsHistory]
      let medDoseFilter= [...state.medications]
      let editMedDoseFilter= [...state.editMedicationHistory]

      if(editMedDoseFilter.length < 2){
        if(filterDoseMed > -1){
          delete medDoseFilter[filterDoseMed]
        }
        if(filterDoseHistory > -1){
          delete medDoseHistoryFilter[filterDoseHistory].intake
        }
      } else {
          delete editMedDoseFilter[filterDoseEditMed]
          delete medDoseHistoryFilter[filterDoseHistory].intake
      }
      
      return {
        ...state,
        medications: medDoseFilter,
        medicationsHistory: medDoseHistoryFilter,
        editMedicationHistory: editMedDoseFilter
      }
    default:
      return state;
  }
}