import React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import GlobalStyles from "../../styles/GlobalStyles";
import Text from "../CommonCustomTxt";
import CommonImage from "../CommonImage";
import Icon from "react-native-vector-icons/Feather";
import { widthPercentageToDP as PR } from "common/src/styles/PixelRatio";
import { useTranslation } from "react-i18next";
import CommonStyle from "./CommonStyle";
import {
  EducationModule,
  PatientEducationTrack,
  PatientProgram,
  ProgressBarStyleProps,
} from "../../types";
import { DateTime } from "luxon";
import { TFunction } from "i18next";
import CommonStatusLabel from "../CommonProgress/CommonStatusLabel";
import CommonFeaturedRibbon from "./components/CommonFeaturedRibbon";
import CommonProgressIcon from '../CommonProgress/CommonProgressIcon';

interface OwnProps {
  educationModule: EducationModule;
  handleClick: () => void;
  style: ProgressBarStyleProps;
  patientEducationTrack?: PatientEducationTrack;
  patientProgram?: PatientProgram;
  isFeatured: boolean;
}

const getCompletesDaysText = (date: string, t: TFunction) => {
  const now = DateTime.now();
  const completedDate = DateTime.fromISO(date);

  if (completedDate.hasSame(now, "day")) {
    return `${t("translation:general.programs.education.completed")} ${t(
      "translation:general.dates.today"
    )}`;
  }

  const diff = Math.max(1, Math.round(now.diff(completedDate).as("days")));

  return t("translation:general.programs.education.completed.days", {
    d: diff,
  });
};

const CommonEducationModuleListItem = ({
  educationModule,
  handleClick,
  style,
  patientEducationTrack,
  isFeatured,
}: OwnProps) => {
  const { t, i18n } = useTranslation();
  const { language: userLanguage } = i18n;
  const Style = getStyle(isFeatured);

  const getStatusElement = (id: string): JSX.Element => {
    if (!patientEducationTrack || !(id in patientEducationTrack)) {
      return (
        <CommonStatusLabel
          status="not_started"
          color={style.progressBarColor}
          testID={`status-notstarted-${educationModule.id}`}
        />
      );
    }

    const track = patientEducationTrack[id];

    if (track.status === "completed") {
      const diffInDaysText = track.completedDate
        ? getCompletesDaysText(track.completedDate, t)
        : t("translation:general.programs.education.completed");
      return (
        <View
          style={CommonStyle.completedContainer}
          testID={`education-completed-${id}`}
        >
          <CommonProgressIcon
            status="completed"
            color={style.progressBarColor}
          />
          <Text
            testID={`education-completed-date-${id}`}
            style={CommonStyle.completedText}
          >
            {diffInDaysText}
          </Text>
        </View>
      );
    }
    return (
      <CommonStatusLabel
        status="in_progress"
        color={style.progressBarColor}
        testID={`status-inprogress-${educationModule.id}`}
      />
    );
  };

  return (
    <TouchableOpacity
      style={Style.container}
      onPress={handleClick}
      testID={`education-item-${educationModule.id}`}
    >
      {isFeatured && <CommonFeaturedRibbon />}
      <View style={Style.wrapper}>        
        {educationModule.imageUri ? (
          <View
            style={{
              width: PR(50),
              height: PR(50),
              borderRadius: PR(15),
              overflow: "hidden",
            }}
          >
            <CommonImage
              name=""
              uri={educationModule.imageUri}
              width={PR(50)}
              height={PR(50)}
            />
          </View>
        ) : (
          <View
            style={{
              width: PR(50),
              height: PR(50),
              borderRadius: PR(15),
              overflow: "hidden",
            }}
          >
            <CommonImage name="noImage" width={PR(50)} height={PR(50)} />
          </View>
        )}
        <View style={CommonStyle.textContainer}>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View style={{ maxWidth: "90%" }}>
              <Text numberOfLines={2} style={CommonStyle.title}>
                {educationModule.title[userLanguage] ||
                  educationModule.title.en}
              </Text>
            </View>
          </View>
          {getStatusElement(educationModule.id)}
        </View>
        <Icon
          style={CommonStyle.icon}
          name="chevron-right"
          size={PR(25)}
          color={GlobalStyles.global.grey}
        />
      </View>
    </TouchableOpacity>
  );
};

const getStyle = (isFeatured: boolean) =>
  StyleSheet.create({
    container: {
      borderRadius: PR(20),
      width: "100%",
      alignSelf: "center",
      marginBottom: PR(8),
      backgroundColor: isFeatured ? "#F1EFFF" : GlobalStyles.global.white,
      shadowColor: "#333333",
      shadowOffset: {
        width: PR(2),
        height: PR(10),
      },
      shadowOpacity: 0.1,
      shadowRadius: 13.97,
      elevation: 10,
    },
    wrapper: {
      flexDirection: "row",
      minHeight: PR(100),
      padding: PR(10),
      paddingLeft: PR(20),
      width: "100%",
      alignItems: "center",
    },
});

export default CommonEducationModuleListItem;
