import { StyleSheet } from "react-native";
import { widthPercentageToDP as PR } from "common/src/styles/PixelRatio";
import GlobalStyles from "../../../styles/GlobalStyles";

const Style = StyleSheet.create({
    caption: {
        fontWeight: "bold",
        lineHeight: PR(20),
        fontSize: PR(16),
    },
    actionDesc: {
        fontSize: PR(16),
        fontFamily: GlobalStyles.global.fontFamily.Bold,
        color: GlobalStyles.global.black
    },
    actionDescContainer: {
        width: '90%', 
        alignSelf:'center', 
        marginBottom:PR(16)
    }
});

export default Style;