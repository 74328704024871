import React, {useState, useEffect} from 'react';
import { StyleSheet } from 'react-native';
import InputField from '../InputField';
import {useTranslation} from 'react-i18next';
import CommonRadioForm from "./CommonRadioForm";
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';

interface OwnProps {
    SideEffectData: any;
    isSubmitButtonActive: boolean;
    initialData: any;
    checkDate: boolean;
    disabled: (data: boolean) => void;
}

const CommonAddSizeEffect = ({SideEffectData, isSubmitButtonActive, initialData, checkDate, disabled}: OwnProps) => {
    const {t} = useTranslation();
    const [sideEffectType, setSideEffectType] = useState<string>(initialData?.effect_type);
    const [intesityType, setIntesityType] = useState<string>(initialData?.intensity);
    const [remarks, setRemarks] = useState<string>(initialData?.remark);

    useEffect(() => {
        if(isSubmitButtonActive)
          sendDataBack()
      },[isSubmitButtonActive])

    useEffect(() => {
        if(!checkDisable())
            disabled(false)
        else    
            disabled(true)
    }, [sideEffectType, intesityType, checkDate])

    const sendDataBack = () => {
        let obj = {
            type:'side_effect',
            sideEffectType,
            intesityType,
            remarks,
            msg: t('translation:events.side_effect.success')
        }
        SideEffectData(obj)
    }

    const displayValues = (key, list) => {
        // @ts-ignore
        let index = t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true}).findIndex((itemType)=> {
            return Object.keys(itemType).toString() === key
        })
        if (index > -1)
            return t('translation:'+list+'.'+index+'.'+key)
        else
            return key
    }
    // return a boolean value in order to turn the button to disable or enable
    const checkDisable = () => {return (sideEffectType === undefined|| intesityType === undefined || !checkDate )}
    return (
        <>
            <CommonRadioForm
                value={sideEffectType}
                setValue={setSideEffectType}
                cmsListLink="list.side_effect_types"
                patientList="effect_type_inputs"
                label={t('translation:general.events.type')}
                isOptional={false}
            />
            <InputField
                labelText={t('translation:events.side_effect.intensity')}
                inputType="dropdown"
                customStyle={{marginBottom:30}}
                changeText={setIntesityType}
                placeholderTxt={t('translation:modal.select.title_one')}
                testID="appointment_report"
                value={displayValues(intesityType, 'list.side_effect_intensities')}
                // @ts-ignore
                data={t<string | TemplateStringsArray>('translation:list.side_effect_intensities', {returnObjects: true}).map((name,i) => {
                    let value = JSON.stringify(Object.keys(name)).replace(/[\[\]']+/g,'')
                    return {label:t('translation:list.side_effect_intensities.'+i+'.'+Object.keys(name)), value:JSON.parse(value)}
                })}
                isOptional={false}
            />
            <InputField
                labelText={t('translation:general.events.remark')}
                inputType="string"
                customStyle={{marginBottom:PR(30)}}
                changeText={setRemarks}
                placeholderTxt={t('translation:general.events.remark_placeholder')}
                testID="remarks"
                multiline={true}
                value={remarks}
                isOptional={true}
            />
        </>
    )
}

const styles = StyleSheet.create({
})

export default CommonAddSizeEffect;