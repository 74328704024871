import React, {useState, useReducer} from 'react';
import {SafeAreaView, StyleSheet, View, ScrollView} from 'react-native'; 
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonTitle from 'common/src/components/CommonTitle';
import CommonSubtitle from 'common/src/components/CommonSubtitle';
import InputField from 'common/src/components/InputField';
import CommonFooter from 'common/src/components/CommonFooter';
import { useDispatch } from 'react-redux';
import { updateOnboardingQuestionsAction, logOutAction } from '../store/actions/authenticationActions';
import {useTranslation} from 'react-i18next';
import {checkBottomScroll, generateArrayOfYears} from 'common/src/services/utils';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useNavigation } from '@react-navigation/native';

interface Questions {
  epilepsy_begin_year?: string,
  frequency?: string
}

const OnboardingQuestions = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [scrollState, setScrollState] = useState();
  const [contentHeight, setContentHeight] = useState<number>();
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
  const [questionsDetails, setQuestionsDetails] = useReducer(
    (state:Questions, newState:Questions) => ({...state, ...newState}),
    {
    epilepsy_begin_year: '',
    frequency: ''
  })
  const setEpilepsyYear = (value:string) => {
    setQuestionsDetails({epilepsy_begin_year:value})
  }
  const setFrequency = (value:string) => {
    setQuestionsDetails({frequency:value})
  }
  const setLogout = () => {
    dispatch(logOutAction());
    navigation.navigate('Welcome');
    navigation.reset({
      index: 0,
      routes: [{ name: 'Welcome' }],
    });
  }
  const next = () => {
    const obj = {
      patients:{
        epilepsy_begin_year: questionsDetails.epilepsy_begin_year,
        frequency: questionsDetails.frequency,
        onboarding: {
          endOnboarding: true,
          questionsDone: true,
          neurologistDone: true
        }
      },
      navigation:navigation
    }
    dispatch(updateOnboardingQuestionsAction(obj))
  }
  return (
    <>
      <SafeAreaView style={styles.container}>
        <CommonHeader 
          title={t('translation:general.page_title.onboarding_questions_epilepsy')}
          rightIconNameFirst="log-out"
          rightIconColorFirst={GlobalStyles.helpi.btnColor}
          rightClickFirst={setLogout}
          scroll={scrollState}
          disableFormsBanner={true}
        />
         <View style={styles.scrollViewWrapper}>
          <ScrollView 
            style={styles.scrollview}
            scrollEventThrottle={16}
            onScroll={(event: any) => {
              let calc = checkBottomScroll(event.nativeEvent, 0);
              if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
              else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
              setScrollState(event.nativeEvent.contentOffset.y);
            }}
          >
            <View
              onLayout={(event: any) => {
                setContentHeight(event.nativeEvent.layout.height)
              }}
            >
              <View style={{width:'90%', justifyContent:'center', alignSelf:'center' }}>
                <CommonTitle text={t('translation:onboarding.questions.helpilepsy.title')} color={GlobalStyles.global.black} txtAlign="left"/>
                <View style={{paddingBottom:PR(20)}}>
                  <CommonSubtitle text={t('translation:onboarding.questions.helpilepsy.text')} txtAlign="left" />
                </View>
                <CommonTitle text={t('translation:onboarding.questions.helpilepsy.subtitle')} color={GlobalStyles.global.black} txtAlign="left"/>
              </View>
              <InputField 
                labelText={t('translation:register.form.epilepsy_begin_date')}
                inputType="dropdown" 
                customStyle={{marginBottom:PR(30)}}
                changeText={setEpilepsyYear}
                data={generateArrayOfYears()}
                testID="begin_year_of_your_epilepsy"
                value={questionsDetails.epilepsy_begin_year === '' ? t('translation:general.select_option') : questionsDetails.epilepsy_begin_year}
              />
              <InputField 
                labelText={t('translation:register.form.frequency')}
                inputType="dropdown" 
                customStyle={{marginBottom:PR(30)}}
                changeText={setFrequency}
                data={[
                  { label: t('translation:list.seizure_frequencies.0.less_1_year'), value: 'list.seizure_frequencies.0.less_1_year' },
                  { label: t('translation:list.seizure_frequencies.1.2_to_5_year'), value: 'list.seizure_frequencies.1.2_to_5_year' },
                  { label: t('translation:list.seizure_frequencies.2.1_per_month'), value: 'list.seizure_frequencies.2.1_per_month' },
                  { label: t('translation:list.seizure_frequencies.3.more_1_month'), value: 'list.seizure_frequencies.3.more_1_month' },
                  { label: t('translation:list.seizure_frequencies.4.not_know'), value: 'list.seizure_frequencies.4.not_know' }
                ]}
                testID="frequensy_of_epilepsy"
                value={questionsDetails.frequency === '' ? t('translation:general.select_option') : t('translation:'+questionsDetails.frequency)}
              />
            </View>
          </ScrollView>
          <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
            <CommonBtn type='primary' click={next} title={t('translation:register.tutorial.start_using')}  testID="start_using_helpi_btn"/>
          </CommonFooter>
        </View>
      </SafeAreaView>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: GlobalStyles.global.white,
    flex: 1,
  },
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
  },
});

export default OnboardingQuestions;