import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { EducationQuestion, QuestionContentChapter } from "../../../types";
import CommonStudyFormQuestions from "../../CommonStudyFormQuestions";
import Style from "./CommonStyle";

interface OwnProps {
  content: QuestionContentChapter;
  handleAnsweringQuestion: (question: string, answer: any) => void;
  previousAnswer: any;
}

const CommonQuestionContentComponent = ({
  content,
  handleAnsweringQuestion,
  previousAnswer,
}: OwnProps) => {
  const [question, setQuestion] = useState<undefined | EducationQuestion>();

  useEffect(() => {
    if (previousAnswer !== null) {
      setQuestion({ ...content.question, answer: previousAnswer });
    } else {
      setQuestion(content.question);
    }
  }, [previousAnswer]);

  return (
    <View testID={`content-question-${content.index}`}>
      {question && (
        <CommonStudyFormQuestions
          question={question}
          updateQuestion={(answer: any) => {
            setQuestion({ ...question, answer });
            handleAnsweringQuestion(question.id, answer);
          }}
          disable={false}
        />
      )}
    </View>
  );
};

export default CommonQuestionContentComponent;
