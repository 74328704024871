import {call, takeLatest, put, select} from "redux-saga/effects";
import { DELETE_EVENT, DELETE_EVENT_SUCCESS, DELETE_VIDEO_EVENT, LOAD_EVENT, UPDATE_EVENT, UPDATE_EVENT_SUCCESS, UPDATE_NIGHTWATCH_EVENT, UPDATE_VIDEO_EVENTS, RESET_EVENTS, RESET_EVENTS_SUCCESS, GET_IMPACT_EVENTS, GET_IMPACT_EVENTS_SUCCESS, GET_TRIGGERS_EVENTS, GET_TRIGGERS_EVENTS_SUCCESS, GET_AURAS_EVENTS, GET_AURAS_EVENTS_SUCCESS } from "./eventActions";
import {makeRequest} from "../../services/requestService";
import { UPDATE_NIGHTWATCH_EVENT_SUCCESS } from "../actions";


export function* updateEventSaga(payload:any) {
    const {data, patientId, eventId, old_date} = payload
    Object.assign(data, {old_date})
    yield call(makeRequest, {
        data,
        path: `/patients/${patientId}/events/${eventId}`,
        method: 'PUT',
        responseAction: UPDATE_EVENT_SUCCESS,
        ...payload
    })
}

export function* updateVideoEventsSaga(payload:any) {
    yield call(makeRequest, {
        path: '/video-event/update',
        method: 'POST',
        ...payload
    })
}

export function* deleteVideoEventSaga(payload:any) {
    yield call(makeRequest, {
        path: `/video-event/${payload.videoId}`,
        method: 'DELETE',
        ...payload
    })
}

export function* loadEventSaga(payload:any) {
    yield call(makeRequest, {
        path: `/patients/${payload.patientId}/events/${payload.eventId}`,
        method: 'GET',
        ...payload
    })
}

export function* deleteEventSaga(payload: any) {
    yield call(makeRequest, {
        path: `/patients/${payload.patientId}/events/${payload.eventId}`,
        method: 'DELETE',
        responseAction: DELETE_EVENT_SUCCESS,
        ...payload
    });
}

export function* updateNightwatchEvent(payload: any) {
    yield call(makeRequest, {
        path: `/nightwatch/report/${payload.eventId}`,
        method: 'PUT',
        responseAction: UPDATE_NIGHTWATCH_EVENT_SUCCESS,
        ...payload,
    })
}

export function* resetEvent() {
    try {
        yield put({ type: RESET_EVENTS_SUCCESS });
    } catch(e) {
        console.log(e)
    }
}

export function* getImpactEventsSaga(payload: any) {
    const dateQuery = {date: payload?.obj?.date};
    const triggersAurasQuery = {triggers: payload?.triggers}
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        path: `/patients/${id}/events/${payload.obj.event_type}`,
        method: 'POST',
        data: payload?.triggers ? triggersAurasQuery : dateQuery,
        responseAction: GET_IMPACT_EVENTS_SUCCESS,
        ...payload
    })
}

export function* getTriggersEvents(payload: any) {
    const triggersQuery = `field=${payload?.field.join('&' + 'field=')}&from=${payload?.from}&to=${payload?.to}`;
    const state = yield select()
    const id = state.getMe.response.result.patients.id
    yield call(makeRequest, {
        path: `/patients/${id}/events/${payload.event_type}/fields?${triggersQuery}`,
        method: 'GET',
        responseAction: GET_TRIGGERS_EVENTS_SUCCESS,
        ...payload
    })
}

export function* getAurasEventsSaga(payload: any) {
    const aurasQuery = `field=${payload?.field}&from=${payload?.from}&to=${payload?.to}`;
    const state = yield select();
    const id = state.getMe.response.result.patients.id;
    yield call(makeRequest, {
        path: `/patients/${id}/events/seizures/auras?${aurasQuery}`,
        method: 'GET',
        responseAction: GET_AURAS_EVENTS_SUCCESS,
        ...payload
    });
};

export default function* watch() {
    yield takeLatest(UPDATE_EVENT, updateEventSaga);
    yield takeLatest(UPDATE_VIDEO_EVENTS, updateVideoEventsSaga);
    yield takeLatest(DELETE_VIDEO_EVENT, deleteVideoEventSaga);
    yield takeLatest(LOAD_EVENT, loadEventSaga);
    yield takeLatest(DELETE_EVENT, deleteEventSaga);
    yield takeLatest(UPDATE_NIGHTWATCH_EVENT, updateNightwatchEvent);
    yield takeLatest(RESET_EVENTS, resetEvent);
    yield takeLatest(GET_IMPACT_EVENTS, getImpactEventsSaga);
    yield takeLatest(GET_TRIGGERS_EVENTS, getTriggersEvents);
    yield takeLatest(GET_AURAS_EVENTS, getAurasEventsSaga)
}
