import {AddProgramActionTypes, StartProgramActionTypes} from './types'
import { EducationModule } from 'common/src/types';

export const GET_PROGRAMS = 'GET_PROGRAMS'
export const GET_PROGRAMS_SUCCESS = 'GET_PROGRAMS_SUCCESS';

export const GET_PATIENT_PROGRAMS = 'GET_PATIENT_PROGRAMS';
export const GET_PATIENT_PROGRAMS_SUCCESS = 'GET_PATIENT_PROGRAMS_SUCCESS';

export const GET_EDUCATION_MODULES = 'GET_EDUCATION_MODULES';
export const GET_EDUCATION_MODULES_SUCCESS = 'GET_EDUCATION_MODULES_SUCCESS';

export const GET_EDUCATION_MODULE_WITH_ALL_DATA = 'GET_EDUCATION_MODULE_WITH_ALL_DATA';
export const GET_EDUCATION_MODULES_WITH_ALL_DATA_SUCCESS = 'GET_EDUCATION_MODULES_WITH_ALL_DATA_SUCCESS';

export const GET_PROGRAM_ACTIONS = 'GET_PROGRAM_ACTIONS';
export const GET_PROGRAM_ACTIONS_SUCCESS = 'GET_PROGRAM_ACTIONS_SUCCESS';

export const ADD_PATIENT_PROGRAMS = 'ADD_PATIENT_PROGRAMS';
export const ADD_PATIENT_PROGRAMS_SUCCESS = 'ADD_PATIENT_PROGRAMS_SUCCESS';

export const UPDATE_PATIENT_PROGRAMS = 'UPDATE_PATIENT_PROGRAMS';
export const UPDATE_PATIENT_PROGRAMS_SUCCESS = 'UPDATE_PATIENT_PROGRAMS_SUCCESS';

export const START_PROGRAM_ACTION = 'START_PROGRAM_ACTION';
export const START_PROGRAM_ACTION_SUCCESS = 'START_PROGRAM_ACTION_SUCCESS';

export const ADD_PROGRAM_ACTION = 'ADD_PROGRAM_ACTION';
export const ADD_PROGRAM_ACTION_SUCCESS = 'ADD_PROGRAM_ACTION_SUCCESS';

export const GET_SUBPROGRAMS = 'GET_SUBPROGRAMS';
export const GET_SUBPROGRAMS_SUCCESS = 'GET_SUBPROGRAMS_SUCCESS';

export const getPrograms = (payload) => {
    return ({
        type: GET_PROGRAMS,
        ...payload
})};

export const getPatientPrograms = (payload) => {
    return ({
        type: GET_PATIENT_PROGRAMS,
        ...payload
})};

export const getEducationModules = (payload?: { ids?: string[] }) => {
    return ({
        type: GET_EDUCATION_MODULES,
        ...payload,
    });
};

export const getEducationModuleWithAllData = (payload?: { ids?: string[], callback: (data:EducationModule) => void }) => {
    return ({
        type: GET_EDUCATION_MODULE_WITH_ALL_DATA,
        ...payload,
    });
};

export const getProgramActions = (payload) => {
    return ({
        type: GET_PROGRAM_ACTIONS,
        ...payload
})};

export const addPatientPrograms = (payload) => {
    return ({
        type: ADD_PATIENT_PROGRAMS,
        ...payload
})};

export const updatePatientPrograms = (payload) => {
    return ({
        type: UPDATE_PATIENT_PROGRAMS,
        ...payload
})};

export const startProgramAction = (payload: StartProgramActionTypes) => {
    return ({
        type: START_PROGRAM_ACTION,
        ...payload
})};

export const addProgramAction = (payload: AddProgramActionTypes) => {
    return ({
        type: ADD_PROGRAM_ACTION,
        ...payload
})};

export const getSubprograms = (payload) => {
    return ({
        type: GET_SUBPROGRAMS,
        ...payload
    })
}