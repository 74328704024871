import React from 'react';
import CommonAbout from 'common/src/components/CommonAbout';
import { version } from '../../package.json';
import { SafeAreaView, StyleSheet } from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';

const About = ({navigation}) => {
  return (
    <SafeAreaView style={styles.safeAreaContainer}>
      <CommonAbout 
        AppName="Helpilepsy" 
        navigation={navigation} 
        footerTitle="Helpilepsy" 
        version={version} 
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  safeAreaContainer: {
    flex: 1,
    backgroundColor: GlobalStyles.global.white,
  },
})

export default About;