import * as types from '../reports/reportsActions';

export default function(state:any = {
    reports: {},
  }, action:any) {
  const response = action.response;
  switch(action.type) {
    case types.GET_REPORTS_SUCCESS:
        let getReport = response.result[response.result.length-1]
      return { 
        ...state, 
        reports: getReport
      }; 
    case types.POST_REPORTS_SUCCESS:
        let postReport = response.result
      return { 
        ...state, 
        reports: postReport
      }; 
    default:
      return state;
  }
};