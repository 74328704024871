import React from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, Platform} from 'react-native';
import {useTranslation} from 'react-i18next';
import CommonBtn from 'common/src/components/CommonBtn';
import WelcomeHeader from 'common/src/components/WelcomeHeader';
import CommonTitle from 'common/src/components/CommonTitle';
import CommonSubtitle from 'common/src/components/CommonSubtitle';
import GlobalStyle from 'common/src/styles/GlobalStyles';
// @ts-ignore
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const Welcome = ({navigation}:any) => {
  const {t} = useTranslation();
  const goToLogin = () => {
    navigation.navigate('Login');
  }

  const goToRegistration = () => {
    navigation.navigate('Register');
  }

  return (
    <>
      <SafeAreaView style={Platform.OS === 'web'? styles.containerWeb: styles.container}>
        <View style={styles.scrollViewWrapper}>
          {/* WelcomeHeader takes 3 props title, subtitle and Logo. Logo takes two values migraine or helpilepsy */}
          <WelcomeHeader title={t('translation:login.helpilepsy.title')} subtitle={t('translation:login.helpilepsy.intro')} logo='helpilepsy' testID="Helpilepsy"/>
          <ScrollView
            style={styles.scrollView}>
            {/* WelcomeTxt takes 2 props mainTxt and color */}
            <View style={styles.textContainer}>
              <CommonTitle text={t('translation:login.title')} color={GlobalStyle.helpi.color}/>
              <CommonSubtitle text={t('translation:login.subtitle')}/>
            </View>
            {/* CommonBtn takes 4 props click, title, color and txtColor. click return a function to parent component */}
            <CommonBtn type='primary' click={goToLogin} title={t('translation:login.form.buttonLogin')} testID="i_have_account"/>
            <View style={{padding:PR(10)}}></View>
            <CommonBtn type='secondary' click={goToRegistration} title={t('translation:login.form.buttonRegister')} testID="create_new_account"/>
            <View style={{ marginBottom: PR(75) }}></View>
          </ScrollView>
        </View>
      </SafeAreaView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems:'center',
    backgroundColor: GlobalStyle.global.white
  },
  containerWeb: {
    flex: 1,
    backgroundColor: GlobalStyle.global.white
  },
  scrollViewWrapper: {
    alignItems: 'center',
    flex: 1,
  },
  textContainer: {
    width: '75%',
    margin: PR(50)
  },
  scrollView: {
    flex:1,
    // alignItems: 'center'
  },
  choiceTxt: {
    margin: PR(25),
    fontSize: PR(24),
    color: GlobalStyle.global.grey,
  }
});

export default Welcome;