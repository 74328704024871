import React, {useEffect, useState} from 'react';
import CommonAddEventForm from 'common/src/components/CommonAddEvents/CommonAddEventForm';
import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  addEventAction,
  updateRemindersAction,
  getMedicationsActions,
  getMeAction,
  getEventsAction
} from '../store/actions/authenticationActions';
import { deleteAppointmentChecklist } from '../store/epilepsyDetails/epilepsyDetailsActions';
import { deleteEvent, updateEvent } from "../store/events/eventActions";
import CommonDeleteEventModal from 'common/src/components/CommonDeleteEvents/CommonDeleteEventModal';
import { GenericRecord, AppointmentChecklistTypes } from 'common/src/types';
import { isEmpty } from 'lodash';
import Loading from './Loading';

const AddEvent = ({navigation, route}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {initialData, itemType, checklistData} = route.params;
  const GetMeResponse = useSelector((state:any) => state.getMe.response);
  const GetMedicationRes = useSelector((state:any) => state.getMedications.medications)
  const [type, setType] = useState<string>('')
  const [icon, setIcon] = useState<string>('')
  const [modalTitle, setModalTilte] = useState<string>('')
  const [infoTxt, setInfoTxt] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [addTitle, setAddTitle] = useState<string>('')
  const [subtitle, setSubtitle] = useState<string>('')
  const [timeTitle, setTimeTitle] = useState<string>('')
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [checklistInitial, setChecklistInitial] = useState<AppointmentChecklistTypes | null>(null)
  const [savedData, setSavedData] = useState<any>(initialData)
  const eventRecord: Partial<GenericRecord> | undefined = initialData ? { type: initialData.type  } : undefined;
  const onDeleteAction = initialData && initialData.id ? () => { setDeleteModalVisible(true); } : undefined;
  const patientData = useSelector((state:any) => state.getMe.patient);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if(itemType === 'Seizure') {
      setType(itemType)
      setIcon('zap')
      setModalTilte(t('translation:events.seizureInfo.title'))
      setInfoTxt(t('translation:events.seizureInfo.text'))
      setTitle(t('translation:journal.seizure_title'))
      setSubtitle(t('translation:events.seizure.info_subtitle'))
      setTimeTitle(t('translation:events.seizure.time'))
      setAddTitle(initialData ? t('translation:general.page_title.edit_seizure') : t('translation:general.page_title.add_seizure'))
    }
    if(itemType === 'Appointment') {
      setType(itemType)
      setIcon('calendar')
      setModalTilte(t('translation:events.appointment.info_title'))
      setInfoTxt(t('translation:events.appointment.info_help'))
      setTitle(t('translation:journal.events.appointment_title'))
      setSubtitle(t('translation:events.appointment.info_subtitle'))
      setTimeTitle(t('translation:events.appointment.time'))
      setAddTitle(initialData ? t('translation:general.page_title.edit_appointment') : t('translation:general.page_title.add_appointment'))
      setChecklistInitial(checklistData)
    }
    if(itemType === 'Side Effect') {
      setType(itemType)
      setIcon('thermometer')
      setModalTilte(t('translation:events.side_effect.info_title'))
      setInfoTxt(t('translation:events.side_effect.info_help'))
      setTitle(t('translation:journal.side_effect_title'))
      setSubtitle(t('translation:events.side_effect.info_subtitle'))
      setAddTitle(initialData ? t('translation:general.page_title.edit_side_effect') : t('translation:general.page_title.add_side_effect'))
    }
    if(itemType === 'Note / Event') {
      setType(itemType)
      setIcon('edit')
      setModalTilte(t('translation:events.other.info_title'))
      setInfoTxt(t('translation:events.other.info_help'))
      setTitle(t('translation:journal.other_title'))
      setSubtitle(t('translation:events.other.info_subtitle'))
      setAddTitle(initialData ? t('translation:general.page_title.edit_other') : t('translation:general.page_title.add_other'))
    }
    if(itemType === 'Reminder') {
      setType(itemType)
      setIcon('bell')
      setModalTilte(t('translation:modal.titles.help'))
      setInfoTxt(t('translation:treatment.reminder.help'))
      setTitle(t('translation:treatment.intakes.title'))
      setSubtitle(t('translation:treatment.intakes.subtitle'))
      setAddTitle(t('translation:treatment.intakes.title'))
      dispatch(getMedicationsActions());
    }
  },[itemType, checklistData, savedData])

  useEffect(() => {
    if (initialData)
      dispatch(getEventsAction({eventId: initialData.id, callback: (res) => {
        setSavedData(res)
        setIsLoading(false)
      }}))
    else
      setIsLoading(false)
  }, [])

  const dispatchAction = (data, callback) => {
    setIsLoading(true)
    if(data.settings !== undefined && data.settings.type === 'reminder')
      dispatch(updateRemindersAction({data, navigation:navigation, msg:'Reminders saved successfully!!', callback: () => {
        setIsLoading(false)
      }}))
    else {
      if (initialData) {
        dispatch(updateEvent({
          old_date: initialData.date,
          data,
          eventId: initialData.id,
          patientId: patientData.id,
          callback: d => {
            callback?.(d);
            setIsLoading(false)
            navigation.goBack();
          }
        }));
      } else dispatch(addEventAction({
        data,
        callback: d => {
          if (d.type === 'seizure' && (!patientData.lists || !patientData.lists.neurologist_seizure_type?.find(item => item.id === d.seizure_type_id))) {
            dispatch(getMeAction())
          }
          callback?.(d);
          setIsLoading(false)
          navigation.goBack();
        }
      }));
    }
  }

  const deleteEventAction = () => {
    setDeleteModalVisible(false);
    dispatch(
      deleteEvent(
        { 
          eventId: initialData.id,
          patientId: patientData.id,
          callback: () => {
            navigation.goBack();
          }
        },
      )
    );
  };

  // Appointment checklist functions
  const onChecklistBtnClick = () => {
    navigation.navigate('AppointmentChecklist', {initialData})
  }

  const onEditChecklistClick = (data:AppointmentChecklistTypes) => {
    navigation.navigate('AppointmentChecklist', {checklistInitialData:data, initialData})
  }

  const onDeleteChecklistClick = () => {
    if(initialData?.id && initialData?.checklist && typeof(initialData.checklist) === 'object' && !isEmpty(initialData.checklist)){
      dispatch(deleteAppointmentChecklist({
        old_date: initialData.date,
        eventId: initialData.id,
        callback: () => {
          initialData.checklist = null
          setSavedData(initialData)
        }
      }))
    }
    else 
      setChecklistInitial(null)
  }

  if (isLoading)
    return <Loading netConnected={true}/>
  else
    return (
      <>
        { isDeleteModalVisible && <CommonDeleteEventModal onClose={() => { setDeleteModalVisible(false); }} onConfirm={deleteEventAction} event={eventRecord} /> }
        <CommonAddEventForm 
          type={type}
          icon={icon}
          modalTitle={modalTitle}
          infoTxt={infoTxt}
          title={title}
          addTitle={addTitle}
          subtitle={subtitle}
          timeTitle={timeTitle}
          navigation={navigation}
          getData={dispatchAction}
          getMeRes={GetMeResponse}
          getMedicationsRes={GetMedicationRes}
          initialData={savedData}
          checklistData={checklistInitial}
          onDeleteAction={onDeleteAction}
          onChecklistBtnClick={onChecklistBtnClick}
          onEditChecklistClick={onEditChecklistClick}
          onDeleteChecklistClick={onDeleteChecklistClick}
        />
      </>
    )
}

export default AddEvent;