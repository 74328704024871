import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import GlobalStyle from 'common/src/styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from 'common/src/env.json';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const Tab = createBottomTabNavigator();

const CommonTabNavigation = (props) => {
    return (
        <Tab.Navigator
            screenOptions={{
                headerShown: false,
                tabBarActiveTintColor: GlobalStyle[APP_SHORTCUT_NAME].darkColor,
                tabBarInactiveTintColor: GlobalStyle[APP_SHORTCUT_NAME].lightColor,
                tabBarStyle: {
                    backgroundColor: GlobalStyle.global.white,
                    borderTopColor: GlobalStyle[APP_SHORTCUT_NAME].darkColor,
                },
                tabBarIconStyle: {
                    marginBottom: PR(-5)
                },
                tabBarLabelStyle: {
                    marginBottom: PR(5)
                },
                tabBarLabelPosition: 'below-icon'
            }}
        >
            {props.children}
        </Tab.Navigator>
    )
}

export default CommonTabNavigation;