import { useDispatch, useSelector } from 'react-redux';
import { useNetInfo } from "@react-native-community/netinfo";
import { useDeviceHandler } from '../../services/deviceService';
import { updateTimezoneAction } from 'helpilepsy/src/store/timezones/changeTimezoneActions';
import { updateVersionAction } from 'helpilepsy/src/store/actions/authenticationActions';
import { timezones } from 'common/src/services/timezones.json';
import moment from 'moment';
import 'moment-timezone';
import { useEffect } from 'react';

type Props = {
	version: string;
}

export const useUpdateTimezoneAndVersion = ({ version }: Props) => {
  const dispatch = useDispatch();
  const netInfo = useNetInfo();
  const deviceHandler = useDeviceHandler();
  const GetMe = useSelector((state:any) => state.getMe.response);
  const GetMeSettings = useSelector((state:any) => state.getMe.settings);
  const deviceTz = timezones.find(tz => tz === moment.tz.guess());

  useEffect(() => {
    if (netInfo.isConnected && GetMe) {
		// update the O.S version of the user mobile phone
		deviceHandler();
		// if device timezone is different than the device timezone in db do request
		if (deviceTz && GetMeSettings && GetMeSettings?.device_timezone !== deviceTz) {
			const dataToUpdate = {
			dataToUpdate: {
				settings: {
				timezone: GetMeSettings.timezone,
				device_timezone: deviceTz || 'Timezone n/a'
				}
			}
			}
			dispatch(updateTimezoneAction(dataToUpdate))
		}
		// if app version is different than the db app version do the request
		if (GetMeSettings && GetMeSettings?.app_version !== version) {
			const sendVersion = {
			settings: {
				app_version: version,
			}
			}
			dispatch(updateVersionAction(sendVersion))
		}
    }
  }, [netInfo.isConnected]);
}