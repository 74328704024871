import React from 'react';
import { View, ScrollView, StyleSheet, SafeAreaView } from 'react-native';
import CommonBtn from 'common/src/components/CommonBtn';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonTitle from 'common/src/components/CommonTitle';
import CommonSubTitle from 'common/src/components/CommonSubtitle';
import {useTranslation} from 'react-i18next';
import { allSetAction } from '../store/actions/authenticationActions';
import { useDispatch } from 'react-redux';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonFooter from 'common/src/components/CommonFooter';

const OnboardingAddMedication = ({navigation}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const skip = () => {
  navigation.navigate('Main', { screen: 'Home' })
    navigation.reset({
      index: 0,
      routes: [{ name: 'Main' }],
    });
    dispatch(allSetAction(false))
  }

  const editMedication = () => {
    navigation.navigate('Main', { screen: 'Treatments' })
    navigation.reset({
      index: 0,
      routes: [{ 
        name: 'Main',
        state: {
          routes: [
            {name: 'Treatments'}
          ]
        } 
      }],
    });
    dispatch(allSetAction(false))
  }
  return(
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.wrapper}>
          <CommonTitle text={t('translation:onboarding.end.title')} size={PR(28)} color={GlobalStyles.global.black}/>
          <CommonSubTitle text={t('translation:onboarding.end.questionHelpilepsy')} txtAlign="center"/>
        </View>
      </ScrollView>
      <CommonFooter>
        <CommonBtn type="secondary" click={()=> {skip()}} title={t('translation:onboarding.end.buttonSkip')} testID="skip"/>
          <View style={{marginBottom:PR(15)}}></View>
        <CommonBtn type="primary" click={() => {editMedication()}} title={t('translation:onboarding.end.buttonMedications')} testID="editMyMedication"/>
      </CommonFooter>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: PR(50),
    display: "flex",
    flex: 1,
    backgroundColor: GlobalStyles.global.white,
  },
  wrapper: {
    width: '90%',
    alignSelf: 'center'
  }
})

export default OnboardingAddMedication;