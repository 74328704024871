import React, {useState} from 'react';
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import CommonRadioForm from '../CommonAddEvents/CommonRadioForm';
import CommonBtn from '../CommonBtn';
import CommonModal from 'common/src/components/CommonModal';
import Text from '../CommonCustomTxt';
import InputField from '../InputField';
import CommonFooter from '../CommonFooter';
import GlobalStyles from '../../styles/GlobalStyles';
import { useTranslation } from 'react-i18next';
import {translate} from 'common/src/services/translationList';
import {checkBottomScroll} from '../../services/utils';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';

const CommonEditMedicationInfo = ({navigation, data, getData, deleteData}) => {
  const {t} = useTranslation();
  const [warningModal, setWarningModal] = useState<boolean>(false)
  const [reasonTreat, setReasonTreat] = useState<string>(data?.reason || '')
  const [typeTreat,] = useState<string>(data?.treatment_type || '')
  const [typeIntake,] = useState<string>(data?.intake_type || '')
  const [nameOfTreat, setNameOfTreatment] = useState<string>(data?.name || '')
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);

  const goToDrugsScreen = () => {
    navigation.navigate('Drugs', {treatName:nameOfTreat, onReturn: (item) => {
      setNameOfTreatment(item)
    }})
  }

  const callbackVisible = () => {
    setWarningModal(false);
  }

  const sendBackAnswers = () => {
    if(typeTreat === 'drug')
      Object.assign(data, {reason: reasonTreat, treatment_type: typeTreat, intake_type:typeIntake, name: nameOfTreat})
    else 
      Object.assign(data, {reason: reasonTreat, treatment_type: typeTreat, name: nameOfTreat})
    getData(data)
  }

  const sendBackDelete = () => {
    setWarningModal(false);
    deleteData(data)
  }

  const confirmModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackVisible
    },
    {
    type: 'primary',
    title: t('translation:modal.btn.confirm'),
    click: sendBackDelete
    }
  ]
  return (
    <>
      { warningModal &&
        <CommonModal testID="warningModalID" type={'info'} title={t('translation:modal.titles.warning')} text={t('translation:modal.medication.delete')} buttons={confirmModalBtn} onClose={callbackVisible}/>
      }
      <ScrollView
        testID="EditMedicationInfo_scrollView"
        scrollEventThrottle={16}
        onScroll={(event) => {
          let calc = checkBottomScroll(event.nativeEvent, PR(15));
            if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
        }}
      >
        <View
          onLayout={(event: any) => {
            setContentHeight(event.nativeEvent.layout.height)
          }}
        >
          <View style={styles.horizontalDiv}></View>
          <CommonRadioForm
            value={reasonTreat}
            setValue={v => {
              setReasonTreat(v)
            }}
            cmsListLink='list.medication_reasons'
            patientList='reason_inputs'
            label={t('translation:treatment.add_medication.step_info.summary.reason')}
          />
          <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_info.summary.type')}</Text>
              <Text style={[styles.label, {fontSize:PR(10)}]}>{t('translation:general.form.not_editable')}</Text>
            </View>
            <View testID="typeOfTreatmentID" style={styles.summaryContainer}>
              <View style={styles.inputSummaryContainer}>
                <Text style={styles.inputSummaryValue}>{typeTreat.length === 0 ? '-' : translate(t, typeTreat, 'list.medication_types')}</Text>
              </View>
            </View>
          </View>
          { typeTreat === 'drug' &&
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
              <View style={{flexDirection:'row', alignItems:'center'}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_info.summary.intake')}</Text>
              <Text style={[styles.label, {fontSize:PR(10)}]}>{t('translation:general.form.not_editable')}</Text>
            </View>
              <View testID="intakeID" style={styles.summaryContainer}>
                <View style={styles.inputSummaryContainer}>
                  <Text style={styles.inputSummaryValue}>{typeIntake.length === 0 ? t('translation:treatment.add_medication.step_info.summary.name_placeholder') : translate(t, typeIntake, 'list.intake_types')}</Text>
                </View>
              </View>
            </View>
          }
          { typeIntake.length > 0 && typeTreat === 'drug' && 
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
              <Text style={styles.label}>{t('translation:treatment.add_medication.step_info.summary.name')}</Text>
              <TouchableOpacity testID="intakeID" style={styles.summaryContainer} onPress={() => goToDrugsScreen()}>
                <View style={styles.inputSummaryContainer}>
                  <Text style={styles.inputSummaryValue}>{nameOfTreat.length === 0 ? t('translation:treatment.add_medication.step_info.summary.name_placeholder') : nameOfTreat}</Text>
                </View>
              </TouchableOpacity>
            </View>
          }
          {(typeTreat !== 'drug' && !!typeTreat) &&
            <InputField 
              labelText={t('translation:treatment.add_medication.step_info.summary.name')}
              inputType="string" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setNameOfTreatment}
              placeholderTxt={t('translation:treatment.add_medication.step_info.summary.name_placeholder')} 
              testID="remarksID"
              value={nameOfTreat}
            />
          }
        </View>
      </ScrollView>
      <CommonFooter atBottom={contentHeight+PR(250)} reachBottom={scrollAtBottom}>
        <CommonBtn
          testID={"deleteBtnID"} 
          type={'tertiary'}
          title={t('translation:treatment.detail.delete_title')}
          color={GlobalStyles.global.redError}
          click={() => {setWarningModal(true)}}
        />
        <View style={{marginBottom:PR(15)}}></View>
        <CommonBtn
          testID={"updateBtnID"} 
          type={'primary'} 
          title={t('translation:treatment.general.update_medication')}
          click={() => {sendBackAnswers()}}
        />
      </CommonFooter>
      <View style={{marginBottom:PR(15)}}></View>
    </>
  )
}

const styles = StyleSheet.create({
  horizontalDiv: {
    borderTopWidth: PR(1), 
    borderTopColor: GlobalStyles.global.lightGrey,
    width: '90%',
    paddingBottom:PR(20),
    alignSelf:'center'
  },
  label: { 
    marginBottom: PR(10),
    marginLeft: PR(20),
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  summaryContainer: {
    backgroundColor: GlobalStyles.global.footerGrey,
    borderRadius: PR(20),
  },
  inputSummaryContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: PR(62),
    padding: PR(10),
    fontSize: PR(16),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputSummaryValue: {
    flex: 1,
    flexGrow: 1,
    color: GlobalStyles.global.black,
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  errorTxt: {
    color: GlobalStyles.global.redError,
    fontSize: PR(14)
  },
  btnContainer: {
    width: "100%",
    marginBottom: PR(20)
  }
})

export default CommonEditMedicationInfo;