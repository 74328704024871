import React from 'react';
import {Modal, View, StyleSheet, TouchableOpacity, TouchableWithoutFeedback,Platform, Dimensions, ScrollView} from 'react-native';
import CommonBtn from '../components/CommonBtn';
import GlobalStyle from '../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import CommonMoodAndSleep from './CommonMoodAndSleep';
import CommonAcceptanceCheckbox from './CommonAcceptanceCheckbox';
import isHtml from 'is-html';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const width = Dimensions.get('window').width;

type IModal = {
  type: string,
  title: string,
  text?: string,
  moodAndSleep?: boolean,
  moodAndSleepValue?: any,
  setValue?: number,
  route?: any,
  buttons: any,
  onClose: any,
  testID?: string
  testIDBtn?: string,
  modalCheckBoxText?: string
  modalCheckBox?: any,
  checkBoxValue?: boolean
}

const CommonModal = ({type, title, text, moodAndSleep, moodAndSleepValue, setValue, route, buttons, onClose, testID, testIDBtn, modalCheckBox, modalCheckBoxText, checkBoxValue}:IModal) => {
  
  const styles = generateStyleSheet(text)

  const sendBackMoodAndSleepValue = (data) => {
    moodAndSleepValue(data)
  }

  const iconName = type === 'error' || type === 'warning' ? 'alert-triangle' : '';

  const modalHeader = (
    <View style={styles.modalHeader}>
      {
        type === 'modal' ?
          <Text renderAsHtml={true} style={[styles.title,{textAlign:'left'}]}>{title}</Text>
        :
          <>
            {
              iconName !== '' ?  
                <Icon name={iconName} size={PR(25)}/>
                :
                <View style={{width: PR(25)}}></View>
            }
            <Text renderAsHtml={true} style={styles.title}>{title}</Text>
          </>
      }
      <TouchableOpacity style={styles.closeIcon} onPress={onClose}>
        <Icon name={"x"} size={PR(25)} color={GlobalStyle[APP_SHORTCUT_NAME].btnColor}/>
      </TouchableOpacity>
    </View>
  )

  const modalBody = (
    <>
      {
        moodAndSleep ?
          <View style={styles.modalBodyMoodSleep}>
            <CommonMoodAndSleep 
              receivedValue={setValue} 
              onValueChange={sendBackMoodAndSleepValue} 
              route={route}
              onClose={onClose}
            />
          </View>
        :
        <View style={styles.modalBody} onStartShouldSetResponder={() => true}>
          <ScrollView nestedScrollEnabled={true}
                      showsVerticalScrollIndicator={false}
                      showsHorizontalScrollIndicator={false}>
            <TouchableOpacity activeOpacity={1}>
              <Text renderAsHtml={true} style={styles.bodyText}>{text}</Text>
              {modalCheckBoxText &&
                <>
                <View style={{marginBottom:PR(15)}} />
                <CommonAcceptanceCheckbox testID="acceptanceCheckbox" text={modalCheckBoxText} updateValidation={modalCheckBox} checked={checkBoxValue}/>
                </>
              }
            </TouchableOpacity>
          </ScrollView>
        </View>
      }
    </>
    
  )

  const modalFooter = (
    <View style={{marginTop: PR(10)}}>
      {
        buttons && buttons.map((btn, index) => (
          <View key={index} style={{marginBottom:PR(5)}}>
            <CommonBtn type={btn.type} title={btn.title} click={btn.click} testID={testIDBtn}/>
          </View>
      ))}
    </View>
  )

  const modalContainer = (
      <TouchableWithoutFeedback>
        <View testID={testID} style={moodAndSleep ? styles.modalContainerMoodSleep : styles.modalContainer}>
          {modalHeader}
          {modalBody}
          {modalFooter}
        </View>
      </TouchableWithoutFeedback>
  )
  return (
    <Modal
      animationType={'fade'}
      transparent={true}>
      <TouchableOpacity onPress={onClose} style={styles.modal}>
          {modalContainer}
      </TouchableOpacity>
    </Modal>
  )
}

const generateStyleSheet = (text) => {
  return StyleSheet.create({
    closeIcon: {
      backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].closeBtn,
      borderRadius: PR(25),
      padding: PR(2),
      zIndex:999
    },
    modal: {
      backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].backgroundColorModal,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    },
    modalContainer: {
      width: Platform.OS === 'web' ? '80%' : width * 0.8,
      padding: PR(15),
      borderRadius: PR(15),
      backgroundColor: GlobalStyle.global.white,
      maxHeight: "80%"
    },
    modalContainerMoodSleep: {
      width: Platform.OS === 'web' ? '80%' : width * 0.88,
      padding: PR(15),
      borderRadius: PR(15),
      backgroundColor: GlobalStyle.global.white,
      maxHeight: "80%"
    },
    modalHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight:PR(10),
      paddingLeft:PR(10),
      paddingTop: PR(10)
    },
    title: {
      fontFamily: GlobalStyle.global.fontFamily.Bold,
      fontSize: PR(18),
      color: GlobalStyle.global.black,
      width: '75%',
      textAlign: 'center',
    },
    modalBody:{
      justifyContent: 'center',
      alignSelf: 'center',
      width:'90%',
      paddingVertical: PR(10),
      maxHeight: "80%"
    },
    modalBodyMoodSleep: {
      minHeight: PR(75),
      justifyContent: 'center',
      alignSelf: 'center',
      paddingTop: PR(10)
    },
    bodyText: {
      color: GlobalStyle.global.black,
      fontSize: PR(15),
      textAlign: isHtml(text) ? 'auto' : 'center',
      lineHeight: PR(25)
    }
  })
}

export default CommonModal;