import { RootState } from '.';
import * as types from '../actions';
import {UPDATE_TIMEZONE_SUCCESS} from '../timezones/changeTimezoneActions';
import moment from 'moment';

export default function(state:any = [] , action:any = '') {
  const response = action.response;
  switch(action.type) {
    case types.GET_ME_SUCCESS:
      const patient = response.patientData || response?.result?.patients
      const dataSharing = response?.result?.dataSharingRequests?.asPatient;
      const patientForms = patient.forms || []
      const settings = response.result?.settings || {};
      const accountTimezone = response.result?.settings?.timezone;
      const consent = response.result?.consent;
      const onBoarding = patient.onboarding;
      return { ...state, response, patient, settings, consent, onBoarding, accountTimezone, patientForms, dataSharing };
    case types.UPDATE_PATIENT_SUCCESS:
      state.response.result.patients = response.result
      return {...state, patient: response.result}
    case types.UPDATE_ONBOARDING_QUESTIONS_SUCCESS:
    case types.UPDATE_REMINDERS_SUCCESS:
      let copyMe = {...state.response}
      copyMe.result.patients = response.result
      return {...state, response: copyMe, patient: response.result, onBoarding: response.result.onboarding}
    case types.UPDATE_CONSENT_SUCCESS:
      const updateConsent = response.result.consent
      return {...state, consent: updateConsent, patient: state.response?.result?.patients}
    case types.UPDATE_MOOD_AND_SLEEP_NOTIFICATIONS_SUCCESS:
      return {...state, response, patient: response?.result?.patients}
    case types.ADD_EVENT_SUCCESS:
      let addedEvent = response.result;

      if (addedEvent.type === 'form') {
        let updatedPatientForms = JSON.parse(JSON.stringify(state.patientForms))
        let formFound = -1
        if (!addedEvent.studyParameters) {
          formFound = updatedPatientForms.findIndex(item =>
            item.form === addedEvent.form && (!item.expirationDate || moment(item.expirationDate).isSameOrBefore(moment()))
          )
        }
        else {
          formFound = updatedPatientForms.findIndex(item =>
            item.form === addedEvent.form && item.send_date === addedEvent.studyParameters.send_date
          )
        }
        if (formFound === -1)
          return state;
        else {
          updatedPatientForms.splice(formFound, 1)
          return {...state, patientForms: updatedPatientForms}
        }
      }
      else if (addedEvent.type === 'seizure') {
          const eventSeizureType = addedEvent.seizure_type;
          const seizure_custom_inputs = state.patient.lists?.seizure_type_inputs;
          if (seizure_custom_inputs && seizure_custom_inputs.indexOf(eventSeizureType) > -1) {
              const seizureTypeIndex = state.patient.lists?.neurologist_seizure_types?.findIndex(item => item.patientName === eventSeizureType)
              if (seizureTypeIndex === -1) {
                const patientDataCpy = {...state.patient}
                patientDataCpy.lists?.neurologist_seizure_types?.push({
                  patientName: eventSeizureType,
                  sameNomenclature: false,
                  showInApp: true,
                  seizureType: eventSeizureType
                })
                return {...state, patient: patientDataCpy}
              }
              return state
          }
          return state;
      }
      else
        return state
    case types.UPDATE_PATIENT_SITE_SUCCESS:
      const updatedSite = response.result
      const siteIndex = state.patientSites.findIndex(item => item._id === updatedSite.id)
      if (siteIndex === -1)
        return state
      const sitesCpy = JSON.parse(JSON.stringify(state.patientSites));
      const study = sitesCpy[siteIndex].study;
      sitesCpy[siteIndex] = {...updatedSite, study: study, _id: updatedSite.id,}
      return {...state, patientSites: sitesCpy}
    case types.UPDATE_SETTINGS_SUCCESS:
      const updatedSettings = response.result.settings;
      return { ...state, settings: updatedSettings };
    case UPDATE_TIMEZONE_SUCCESS:
      return { ...state, response:response, accountTimezone: response.dataSent.settings.timezone };
    default:
      return state;
    
    case types.GET_PATIENT_SITES_SUCCESS:
      return {...state, sites: response.result}
  }
}

export const userLanguage = (state: RootState) => state.getMe?.settings?.language;
