import React from "react";
import { EducationTrackStatus } from "../../types";
import Icon from "react-native-vector-icons/Feather";
import GlobalStyles from '../../styles/GlobalStyles';

type Status = EducationTrackStatus | string;

const CommonProgressIcon = ({color, status}: {color: string, status: Status}) => {
  if (status === 'in_progress') {
    return <Icon name="clock" color={GlobalStyles.global.blueInProgress} size={22}/>;
  } else if (status === 'completed') {
    return <Icon name="check-circle" color={GlobalStyles.global.greenSuccess} size={22} />;
  }

  return <Icon name="play-circle" color={color} size={22}/>;
}

export default CommonProgressIcon;
