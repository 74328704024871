import {StyleSheet} from 'react-native';
import {widthPercentageToDP as PR} from '../../../styles/PixelRatio';
import GlobalStyles from '../../../styles/GlobalStyles';

export const styles = StyleSheet.create({
    icon: {
      marginVertical: PR(8),
    },
    inputContainer: {
      flex: 1,
      flexDirection: 'column',
      minHeight: PR(62),
      padding: PR(10),
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: PR(17),
      backgroundColor: GlobalStyles.global.footerGrey,
      borderRadius: PR(20),
      width: '100%',
      paddingHorizontal: PR(20),
    },
    loadingProgressContainer: {
      position: "relative",
      alignSelf: "flex-start",
      width: "100%",
    },
    loadingProgressBackground: {
      backgroundColor: GlobalStyles.helpi.ultraLightColor,
      height: PR(10),
      borderRadius: PR(5),
      width: '100%',
      position: "absolute"
    },
    loadingProgress: {
      height: PR(10),
      borderRadius: PR(5),
      backgroundColor: GlobalStyles.helpi.color
    },
    inputText: {
      color: GlobalStyles.global.grey,
      marginTop: PR(20),
      marginBottom: PR(35),
      fontSize: PR(16),
    },
    videoItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: PR(20),
      marginBottom: PR(12),
      borderWidth: PR(2),
      borderRadius: PR(20),
      borderColor: GlobalStyles.helpi.color
    },
    itemIcon: {
      marginVertical: PR(18),
      marginRight: PR(15)
    },
    errorTxt: {
      paddingTop: PR(10),
      color: GlobalStyles.global.redError,
      textAlign: 'center'
    },
  })