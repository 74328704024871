import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import GlobalStyles from '../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../env.json';

interface CommonTimelineGraphsTypes {
  day: DayObjectTypes,
  max: number,
  seizureCount: number,
  timelineLabels?: string,
  impactLabels?: string
}

interface DayObjectTypes {
  date: string,
  seizures: Array<SeizuresArrayObjectTypes>
}

interface SeizuresArrayObjectTypes {
  color: string,
  count: number,
  date: string,
  name: string
}

const CommonTimelineGraphs = ({day, max, seizureCount,timelineLabels, impactLabels}:CommonTimelineGraphsTypes) => {
  const displaySeizuresPerPeriod = (seizure, size, count, sIndex) => {
    const extraStyles = {
        height: PR(125 / max * seizure.count),
        backgroundColor: seizure.color,
        borderTopRightRadius: sIndex === 0 && max === count ? PR(5) : 0, 
        borderTopLeftRadius: sIndex === 0 && max === count ? PR(5) : 0, 
        borderBottomLeftRadius: sIndex === size - 1 ? PR(5) : 0, 
        borderBottomRightRadius: sIndex === size - 1 ? PR(5) : 0
    }
    return (
        <View key={sIndex} style={[styles.timeLineBarFilled, extraStyles]}>
            { extraStyles.height > PR(15) &&
                <Text style={styles.timelineEventsCount}>{seizure.count}</Text>
            }
        </View>
    )
}
  // display the total seizure of the day or month
  const displayTotalSeizures = () => {
    return (
        <>
            {day.seizures.length > 0 &&
                <Text style={[styles.timelineLabels, {color:GlobalStyles[APP_SHORTCUT_NAME].color}]}>{seizureCount}</Text> 
            }
        </>
    )
}
  return (
    <>
      <View>
        { day.seizures.length === 0 ? // if there is not a seizure at all, display empty bars
          <View style={styles.timeLineBarEmpty}></View>
          :
          max > seizureCount && // if there is a seizure and the total seizure of the day is smaller than the maximum seizure total display a grey bar behind seizures
          <View style={[styles.timeLineBarEmpty, {height: PR(125 - (125 / max * seizureCount)), borderBottomLeftRadius: 0, borderBottomRightRadius: 0}]}></View>
        }
        { // display seizures if exist
          day.seizures.length > 0 && day.seizures.map((seizure, sIndex) => {
            return (displaySeizuresPerPeriod(seizure, day.seizures.length, seizureCount, sIndex))
          })
        }
      </View>
      {timelineLabels &&
        <Text style={styles.timelineLabels}>{timelineLabels}</Text>
      }
      {impactLabels &&
        <Text style={styles.impactLabels}>{impactLabels}</Text>
      }
      
      {displayTotalSeizures()}
    </>
  )
}

const styles = StyleSheet.create({
  timelineBar: {
    flex: 1,
    flexDirection: 'column'
  },
  timeLineBarEmpty: {
      height: PR(125), 
      width: '80%', 
      maxWidth: PR(50),
      backgroundColor: GlobalStyles.global.lightGrey, 
      alignSelf: 'center', 
      borderRadius: PR(5)
  },
  timeLineBarFilled: {
      width: '80%',
      maxWidth: PR(50),
      alignSelf: 'center',
      alignItems: 'center', 
      justifyContent: 'center',
  },
  timelineLabels: {
    alignSelf: 'center',
    marginTop: PR(10)
  },
  timelineEventsCount: {
    zIndex: 99,
    color: 'white'
  },
  impactLabels: {
    alignSelf: 'center',
    marginTop: PR(10),
    fontSize: PR(10),
    fontFamily: GlobalStyles.global.fontFamily.Regular,
    color: GlobalStyles.global.black
  }
})

export default CommonTimelineGraphs;