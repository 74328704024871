import React, { useEffect, useState } from 'react';
import { StyleSheet, SafeAreaView, ScrollView, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPrograms, getPatientPrograms, addPatientPrograms, updatePatientPrograms } from '../../store/programs/programsActions';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import CommonHeader from 'common/src/components/CommonHeader';
import Text from 'common/src/components/CommonCustomTxt';
import CommonProgramListItem from 'common/src/components/CommonProgramListItem';
import { useIsFocused } from "@react-navigation/native";
import { RootState } from '../../store/reducers';
import { ProgramsTypes } from '../../store/programs/types';
import ProgramOverview from './ProgramOverview';
import Loading from '../Loading';

const Programs = ({ navigation, route }) => {
    const isFocused = useIsFocused();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const programs = useSelector((state: RootState) => state.programsReducers.programs)
    const PatientPrograms = useSelector((state: RootState) => state.programsReducers.patientPrograms)
    const [programsLibrary, setProgramsLibrary] = useState<ProgramsTypes[] | null>(null);
    const [hasOnlyOneProgram, setHasOnlyOneProgram] = useState(false);
    const uniqueProgram = route?.params?.uniqueProgram
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!programs && isFocused)
            dispatch(getPrograms({}))
        else
            setProgramsLibrary(programs) // To sort library with already started programs
    }, [programs])

    useEffect(() => {
        dispatch(getPatientPrograms({}))
    }, [])

    const startProgram = (program) => {
        dispatch(addPatientPrograms({ program }))
    }

    const updateMyPatientProgram = (patientProgramData) => {
        let obj = patientProgramData
        dispatch(updatePatientPrograms({ obj }));
    }

    useEffect(() => {
        if (uniqueProgram)
            setHasOnlyOneProgram(true)
    }, [uniqueProgram])

    useEffect(() => {
        if (hasOnlyOneProgram)
            return
        if (programsLibrary && PatientPrograms) {
            if (programsLibrary.length === 0 && PatientPrograms.length === 1 && PatientPrograms[0].introductionDone) {
                if (!PatientPrograms[0].program.hasSubprograms || (PatientPrograms[0].program.hasSubprograms && PatientPrograms[0].subProgram)) {
                    if (PatientPrograms[0].subProgram)
                        PatientPrograms[0].subProgram.patientProgramId = PatientPrograms[0].id
                    PatientPrograms[0].program.patientProgramId = PatientPrograms[0].id
                    setHasOnlyOneProgram(true);
                }
            }
            setIsLoading(false)
        }
    }, [programsLibrary, PatientPrograms])

    if (isLoading)
        return <Loading netConnected={true} />

    else if (hasOnlyOneProgram)
        return (
            <>
                <ProgramOverview navigation={navigation} route={{
                    params: {
                        program: PatientPrograms![0].subProgram ? PatientPrograms![0].subProgram : PatientPrograms![0].program,
                        isTabScreen: true,
                        selectedTab: route.params?.selectedTab
                    }
                }} />
            </>
        )
    else
        return (
            <>
                <SafeAreaView style={styles.container}>
                    <CommonHeader
                        title={t('translation:general.page_title.programs')}
                        testIDTitle="Programs"
                        mainPage={true}
                    />
                    <ScrollView style={{ flex: 1 }}>
                        <View style={{ marginBottom: PR(25) }}>
                            <View style={styles.sectionHeader}>
                                <Text testID="myProgramsId" style={styles.sectionHeaderText}>
                                    {t('translation:general.programs.my_programs')}
                                </Text>
                                <View>
                                    {
                                        PatientPrograms && PatientPrograms.length > 0 ?
                                            PatientPrograms.map((program, index) => (
                                                <View key={index} testID={"list_of_patient_programs_" + index}>
                                                    <CommonProgramListItem
                                                        navigation={navigation}
                                                        program={{ ...program.program, patientProgramId: program.id, introductionDone: program.introductionDone, subProgram: program.subProgram }}
                                                        startProgram={startProgram}
                                                        updateMyPatientProgram={updateMyPatientProgram}
                                                    />
                                                </View>
                                            ))
                                            :
                                            <Text testID="no_myPrograms_id">{t('translation:general.programs.my_programs.empty')}</Text>
                                    }
                                </View>
                            </View>
                        </View>
                        <View style={{ marginBottom: PR(25) }}>
                            {
                                programsLibrary && programsLibrary.length > 0 &&
                                <View style={styles.sectionHeader}>
                                    <Text testID="libraryPrograms_title_id" style={styles.sectionHeaderText}>
                                        {t('translation:general.programs.all_programs')}
                                    </Text>
                                    <View>
                                        {programsLibrary.map((program, index) => {
                                            return (
                                                <View key={index} testID={'libraryPrograms_id_' + index}>
                                                    {!program?.isSubprogram &&
                                                        <CommonProgramListItem
                                                            navigation={navigation}
                                                            program={program}
                                                            startProgram={startProgram}
                                                            updateMyPatientProgram={updateMyPatientProgram}
                                                        />
                                                    }
                                                </View>
                                            )
                                        })
                                        }
                                    </View>
                                </View>
                            }
                        </View>
                    </ScrollView>
                    <View style={{ marginBottom: PR(30) }}></View>
                </SafeAreaView>
            </>
        )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: GlobalStyles.global.white,
    },
    sectionHeader: {
        width: '90%',
        alignSelf: 'center',
        paddingVertical: PR(6),
    },
    sectionHeaderText: {
        color: GlobalStyles.global.black,
        fontSize: PR(20),
        fontFamily: GlobalStyles.global.fontFamily.Bold,
        letterSpacing: 0,
        marginBottom: PR(12)
    }
})

export default Programs