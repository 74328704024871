import React, {useState, useEffect} from 'react';
import {StyleSheet, SafeAreaView, ScrollView, View, TouchableOpacity} from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonSubTitle from 'common/src/components/CommonSubtitle';
import {useTranslation} from 'react-i18next';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonImage from 'common/src/components/CommonImage';
import Text from 'common/src/components/CommonCustomTxt';
import CommonTabBar from 'common/src/components/CommonTabBar';
import CommonFuturePast from 'common/src/components/CommonMyAppointments/CommonFuturePast';
import { RootStackParams } from 'common/src/types';
import { StackNavigationProp } from '@react-navigation/stack';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { getPatientAllEvents } from '../../../store/epilepsyDetails/epilepsyDetailsReducers';
import { getPatientEvent } from '../../../store/epilepsyDetails/epilepsyDetailsActions';
import { useDispatch, useSelector } from 'react-redux';
import { seperateFuturePast } from './helpers';

type OwnProps = {
    navigation: StackNavigationProp<RootStackParams>
}

const MyAppointments = ({navigation}:OwnProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const getPatientEventReducer = useSelector(getPatientAllEvents);
    const [scrollState, setScrollState] = useState();
    const [selectedDisplayType, setSelectedDisplayType] = useState<string>();
    const [seperatedAppointments, setSeperatedAppointments] = useState<Record<string, Array<any>>>({future:[],past:[]})
    const [pastMore, setPastMore] = useState<number>(5);

    useEffect(() => {
        navigation.addListener('focus', () => {
            dispatch(getPatientEvent({eventType:'appointment'}));
        })
    },[navigation])

    useEffect(() => {
        if(getPatientEventReducer && getPatientEventReducer.length > 0)
            setSeperatedAppointments(seperateFuturePast(getPatientEventReducer))
        else
            setSeperatedAppointments({future:[],past:[]})
    }, [getPatientEventReducer])

    const goToEditAppointment = (event) => {
        navigation.navigate("AddEvent", {
            itemType: "Appointment",
            initialData: event,
          });
    }

    return (
        <SafeAreaView style={styles.container}>
            <CommonHeader
                title={t('translation:report.tables_page.appointment_table.title')} 
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {navigation.goBack()}}
                testIDTitle="epilepsy_my_appointments_title"
                scroll={scrollState}
                addActionBtnChildren={
                    <TouchableOpacity onPress={() => {navigation.navigate('AddEvent', {itemType: 'Appointment'})}} style={{width:'100%'}}>
                      <CommonImage name={'helpiAddIconFilled'} height={PR(30)} width={PR(30)}/>
                    </TouchableOpacity>
                  }
            />
            <ScrollView 
                testID="appointment_checklist_scrollView"
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                    setScrollState(event.nativeEvent.contentOffset.y);
                }}
            >
                <View style={{marginTop:PR(8), width:'90%', alignSelf:'center'}}>
                    <CommonSubTitle 
                        text={t('translation:settings.page_subtitle.epilepsy_my_appointments')}
                        txtAlign='center'
                    />
                </View>
                <View style={{marginTop:PR(21)}}></View>
                <CommonTabBar 
                    tabBarData={[
                        {label: t('translation:treatment.detail.future.label'), value: 'future'},
                        {label: t('translation:treatment.detail.past.label'), value: 'past'}
                    ]}
                    callback={setSelectedDisplayType}
                />
                {selectedDisplayType === 'future' &&
                    <>
                    {seperatedAppointments.future.length > 0 ?
                        seperatedAppointments.future.map((appItem, index) => {
                            return (
                                <View key={index}>
                                    <CommonFuturePast 
                                        appointment={appItem}
                                        callback={goToEditAppointment}
                                    />
                                </View>
                            )
                        })
                        :
                        <Text style={styles.noAppointmentText}>{t('translation:general.page.my_appointments.future_tab.no_data')}</Text>
                    }
                    </>
                }
                {selectedDisplayType === 'past' &&
                    <>
                    {seperatedAppointments.past.length > 0 ?
                        seperatedAppointments.past.slice(0, pastMore).map((appItem, index) => {
                            return (
                                <View key={index}>
                                    <CommonFuturePast 
                                        appointment={appItem}
                                        callback={goToEditAppointment}
                                    />
                                </View>
                            )
                        })
                        :
                         <Text style={styles.noAppointmentText}>{t('translation:general.page.my_appointments.past_tab.no_data')}</Text>
                    }
                    {seperatedAppointments.past && (seperatedAppointments.past.length > pastMore) &&
                        <TouchableOpacity style={{alignSelf:'center', marginBottom: PR(30)}} onPress={() => {setPastMore(pastMore+3)}}>
                            <Text style={{color: GlobalStyles.helpi.btnColor}}>{t('translation:treatment.moreDrugs')}</Text>
                        </TouchableOpacity>
                    }
                    </>
                }
            </ScrollView>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
      backgroundColor: GlobalStyles.global.white,
      flex: 1,
    },
    label: { 
        marginBottom: PR(10),
        color: GlobalStyles.global.black,
        fontSize: PR(12)
    },
    noAppointmentText: {
        color: GlobalStyles.global.black,
        fontFamily: GlobalStyles.global.fontFamily.Bold,
        fontSize: PR(16),
        alignSelf: 'center'
    }
});

export default MyAppointments;