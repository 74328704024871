import React, {useState, useEffect} from 'react';
import { SafeAreaView, StyleSheet, ScrollView, View } from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonImpactContainer from 'common/src/components/CommonImpact/CommonImpactContainer';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonSubTitle from 'common/src/components/CommonSubtitle';
import Text from 'common/src/components/CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector, useDispatch } from 'react-redux';
import { getImpactEvents, getTriggersEvents, getAurasEvents } from '../store/events/eventActions';
import { useTranslation } from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import moment from 'moment';

const MoreGraphs = ({navigation}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [scrollState, setScrollState] = useState();
    const Me = useSelector((state:any) => state.getMe.response);
    const impactSeizures = useSelector((state:any) => state.getEvents.impact_seizure_events);
    const impactTriggers = useSelector((state:any) => state.getEvents.impact_triggers_events);
    const impactAuras = useSelector((state: any) => state.getEvents.impact_auras_events);
    const impactMood = useSelector((state:any) => state.getEvents.impact_mood_events);
    const impactSleep = useSelector((state:any) => state.getEvents.impact_sleep_events);
    const impactAdherence = useSelector((state:any) => state.getEvents.impact_adherence_events);

    useEffect(() => {
        let obj = {
            date: {
                from: moment().subtract(5, 'week').startOf('isoWeek').format(),
                to: moment().endOf('isoWeek').format(),
            } 
        }
        // the first time length is zero for all of them
        if(impactSeizures.length === 0 || impactMood.length === 0 || impactSleep.length === 0 || impactAdherence.length === 0){
            Object.assign(obj, {event_type: 'seizure'})
            dispatch(getImpactEvents({
                obj,
                callback: () => {
                    Object.assign(obj, {event_type: 'mood'})
                    dispatch(getImpactEvents({
                        obj,
                        callback: () => {
                            Object.assign(obj, {event_type: 'sleep'})
                            dispatch(getImpactEvents({
                                obj,
                                callback: () => {
                                    Object.assign(obj, {event_type: 'reminder'})
                                    dispatch(getImpactEvents({obj}))
                                }
                            }));
                        }
                    }));
                }
            }));
        }
        if(impactTriggers.length === 0){
            dispatch(getTriggersEvents({
                field: ["triggers", "auras"],
                event_type: 'seizure',
                from: moment().subtract(6, 'months').startOf('day').format('YYYY-MM-DDTHH:mm'),
                to: moment().endOf('day').format('YYYY-MM-DDTHH:mm')
            }))
        }
        if(impactAuras.length === 0) {
            dispatch(getAurasEvents({
                field: 'auras',
                from: moment().subtract(6, 'months').startOf('day').format('YYYY-MM-DDTHH:mm'),
                to: moment().endOf('day').format('YYYY-MM-DDTHH:mm')
            }));
        }
    },[])

    const triggerArray = (triggerType: string) => {
        if(impactTriggers.length > 0)
            return impactTriggers.filter(item => {
                if(item[triggerType] !== undefined)
                    return item
            })
        else 
            return []
    }

    
    
    return(
        <SafeAreaView style={styles.container}>
            <CommonHeader
                title={t('translation:dashboard.more_graphs.title')}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {navigation.goBack()}}
                scroll={scrollState}
                testIDTitle="more_graphs_title"
            />
            <ScrollView
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                    setScrollState(event.nativeEvent.contentOffset.y);
                }}
            >
                <View style={{width:'90%', alignSelf:'center', marginBottom:PR(20)}} testID="more_graphs_subtitle">
                    <CommonSubTitle text={t('translation:dashboard.more_graphs.subtitle')}/>
                </View>
                <CommonImpactContainer
                    data={[
                        {itemType:'seizures', itemTitle: t('translation:list.events_types.seizure'), icon: 'zap', Me, Events: impactSeizures},
                        {itemType:'mood', itemTitle: t('translation:list.events_types.mood'), icon: 'sun', Events: impactMood},
                        {itemType:'sleep', itemTitle: t('translation:list.events_types.sleep'), icon: 'moon', Events: impactSleep},
                        {itemType:'triggers', itemTitle: t('translation:events.seizure.triggers'), icon: 'zap', Events: triggerArray('triggers')},
                        {itemType:'auras', itemTitle: t('translation:events.seizure.auras'), icon: 'zap', Events: impactAuras},
                        {itemType:'reminder', itemTitle: t('translation:reports.medication_adherence.general.title'), icon: 'plus-square', Events: impactAdherence},
                    ]}
                />
            </ScrollView>
            <View style={styles.disclaimerContainer} testID="disclaimer_id">
                <View style={{width:'10%', alignItems:'center'}}>
                    <Icon 
                        name={'info'} 
                        size={PR(25)} 
                        color={GlobalStyles.global.grey} 
                    />
                </View>
                <View style={{width:'90%', alignItems:'center'}}>
                    <Text style={styles.disclaimerTxt}>{t('translation:programs.impact.disclaimer')}</Text>
                </View>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: GlobalStyles.global.white,
    },
    disclaimerContainer: {
        flexDirection: 'row',
        width: '90%',
        alignItems: 'center',
        justifyContent:'space-between',
        alignSelf:'center',
        marginTop:PR(10),
        marginBottom: PR(10)
    },
    disclaimerTxt: {
        fontSize: PR(10),
        fontFamily: GlobalStyles.global.fontFamily.Regular,
        color: GlobalStyles.global.black,
        opacity: 0.8,
        textAlign:'left'
    },
});

export default MoreGraphs;