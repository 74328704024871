export enum PatientProgramActions {
    UPDATE_CHAPTER_LAST_PAGE = 'UPDATE_CHAPTER',
    UPDATE_CHAPTER_LAST_PAGE_SUCCESS = "UPDATE_CHAPTER_SUCCESS",
    COMPLETE_CHAPTER = "COMPLETE_CHAPTER",
    COMPLETE_CHAPTER_SUCCESS = "COMPLETE_CHAPTER_SUCCESS",
    SET_ACTIVE_PATIENT_PROGRAM = "SET_ACTIVE_PATIENT_PROGRAM",
    UPDATE_QUESTIONNAIRE = "UPDATE_QUESTIONNAIRE",
    UPDATE_QUESTIONNAIRE_SUCCESS = "UPDATE_QUESTIONNAIRE_SUCCESS",
    GET_PATIENT_PROGRAM_ACTION_EVENTS = "GET_PATIENT_PROGRAM_ACTION_EVENTS",
    GET_PATIENT_PROGRAM_ACTION_EVENTS_SUCCESS = "GET_PATIENT_PROGRAM_ACTION_EVENTS_SUCCESS",
    GET_PATIENT_PROGRAM_ACTION_SCHEDULE = "GET_PATIENT_PROGRAM_ACTION_SCHEDULE",
    GET_PATIENT_PROGRAM_ACTION_SCHEDULE_SUCCESS = "GET_PATIENT_PROGRAM_ACTION_SCHEDULE_SUCCESS"
}

export interface ChapterAction {
    patientProgramId: string;
    educationModuleId: string;
    chapterId: string;
}

export interface UpdateChapterAction extends ChapterAction {
    lastPage: number;
}

export interface UpdateQuestionnaireAction {
    patientProgramId: string;
    answers: object;
}

export interface GetPatientProgramActionEvents {
    patientProgramId: string;
    callback?: (e?: any) => void;
}

export interface GetPatientProgramActionSchedule {
    programActionId: string;
    callback?: (e?: any) => void;
}

export const updateChapter = (payload: UpdateChapterAction) => {
    return {
        type: PatientProgramActions.UPDATE_CHAPTER_LAST_PAGE,
        ...payload
    };
};

export const completeChapter = (payload: ChapterAction) => {
    return {
        type: PatientProgramActions.COMPLETE_CHAPTER,
        ...payload,
    }
};

export const setActivePatientProgram = (payload: {id: string}) => {
    return {
        type: PatientProgramActions.SET_ACTIVE_PATIENT_PROGRAM,
        ...payload,
    }
};

export const updateQuestionnaires = (payload: UpdateQuestionnaireAction) => {
    return {
        type: PatientProgramActions.UPDATE_QUESTIONNAIRE,
        ...payload,
    }
};

export const getPatientProgramActionEvents = (payload: GetPatientProgramActionEvents) => {
    return {
        type: PatientProgramActions.GET_PATIENT_PROGRAM_ACTION_EVENTS,
        ...payload
    }
}

export const getPatientProgramActionSchedule = (payload: GetPatientProgramActionSchedule) => {
    return {
        type: PatientProgramActions.GET_PATIENT_PROGRAM_ACTION_SCHEDULE,
        ...payload
    }
}
