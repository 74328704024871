import React, {useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import Text from "../../CommonCustomTxt";
import { widthPercentageToDP as PR } from "common/src/styles/PixelRatio";
import Icon from 'react-native-vector-icons/Feather';
import {APP_SHORTCUT_NAME} from '../../../env.json'
import GlobalStyles from "../../../styles/GlobalStyles";
import CommonStyle from "./CommonStyle";
import { useTranslation } from 'react-i18next';
import {
    ProgramAction,
    ActionsContentChapter
  } from "../../../types";
import { getCaptionInLanguage } from "../../../helpers/translations";
import CommonBtn from "../../CommonBtn";
import { getPatientProgramActionSchedule } from '../../../../../helpilepsy/src/store/programs/patientProgramsActions';
import { startProgramAction, addProgramAction } from '../../../../../helpilepsy/src/store/programs/programsActions';
import { activePatientProgram } from '../../../../../helpilepsy/src/store/programs/patientProgramsReducers';
import { RootState } from '../../../../../helpilepsy/src/store/reducers';
import {dateTimeEvents} from 'common/src/services/utils';
import moment from "moment";
import CommonProgressIcon from '../../CommonProgress/CommonProgressIcon';
interface OwnProps {
    content: ActionsContentChapter,
    handleChapterAction: (chapterAction: any) => void;
    navigation: any;
    updateChapterAction: (lastPage: number) => void;
    currentPageIndex: number;
}



const CommonActionContentComponent = ({content, handleChapterAction, navigation, updateChapterAction, currentPageIndex}: OwnProps) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const {language: userLanguage} = i18n;
    const patientProgramActionEvents = useSelector((state: any) => state.patientProgramsReducers.patientProgramActionEvents)
    const programs = useSelector((state: any) => state.programsReducers.programs)
    const PatientPrograms = useSelector((state: RootState) => state.programsReducers.patientPrograms)
    const [expandedAction, setExpandedAction] = useState(-1)
    const [displayFullExplanation, setDisplayFullExplanation] = useState(false)
    const [clickedActionSchedule, setClickedActionSchedule] = useState(null);
    const patientProgram = useSelector(activePatientProgram);
    const patientData = useSelector((state: RootState) => state.getMe.response);

    const expandAction = (action, index, status) => {
        if (index === expandedAction) {
            setExpandedAction(-1)
            return
        }

        if (status === 'in_progress') {
            index === expandedAction ? 
                setExpandedAction(-1) 
            : 
                dispatch(getPatientProgramActionSchedule({programActionId: action.id, callback: (res) => {
                    setClickedActionSchedule(res)
                    setExpandedAction(index)
                }}))
        }
        else if (status === 'not_selected') 
            dispatch(addProgramAction({actionId: action.id, action: action, patient_program_id: patientProgram!.id, callback: () => {
                index === expandedAction ? setExpandedAction(-1) : setExpandedAction(index)
            }}))
        else
            index === expandedAction ? setExpandedAction(-1) : setExpandedAction(index)
        setDisplayFullExplanation(false)
    }

    const handleActionSchedule = (action: ProgramAction, actionId:string, date?:string, time?:string, old_date?:string) => {
        let dateTime:string | undefined;
        if(date && time)
            dateTime = dateTimeEvents(date, time)

        dispatch(startProgramAction({
            actionId,
            scheduledDate: dateTime,
            old_date,
            callback: () => {
                dispatch(getPatientProgramActionSchedule({programActionId: action.id, callback: (res) => {
                    setClickedActionSchedule(res)
                }}))
            }
        }))
    }

    const removeAction = (action) => {
        dispatch(addProgramAction({actionId: action.id, action: action, patient_program_id: patientProgram!.id, callback: () => {
            setExpandedAction(-1)
        }}))
    }

    const onActionClick = (action, status) => {
        const actionToGo = patientProgramActionEvents.find(item => item.id === action.id)
        if (actionToGo)
            return navigation.navigate('ProgramAction', { programAction: actionToGo, handleActionSchedule, removeAction });
    }

    const getHabitScheduleTime = (action) => {
        if (action.actionBtnNavigation && (action.actionBtnNavigation.value === 'mood' || action.actionBtnNavigation.value === 'sleep') && patientData?.result?.settings?.notifications)
            return patientData.result.settings?.notifications[action.actionBtnNavigation.value + '_time']
        else if (clickedActionSchedule && clickedActionSchedule.next_action_at)
            return moment(clickedActionSchedule.next_action_at).format('HH:mm');
        else
            return moment(action.events[action.events.length - 1].date).format("HH:mm");
    }

    const getHabitScheduleFrequency = (action) => {
        if (action.frequency === 'daily')
            return t('translation:general.text.day')
        if (clickedActionSchedule && clickedActionSchedule.next_action_at)
            return moment(clickedActionSchedule.next_action_at).locale(userLanguage || 'en').format("dddd")
        else
            return moment(action.events[action.events.length - 1].date).locale(userLanguage || 'en').format("dddd")
    }

    const getText = (action, status) => {
        const actionWithEvents = patientProgramActionEvents.find(item => item.id === action.id)

        if (status === 'not_selected')
            return "";
        if (status === 'not_started')
            return t('translation:programs.action.not_scheduled');
        if (status === 'in_progress') {
            if (action.type === 'habit') {
                const frequency = getHabitScheduleFrequency(actionWithEvents)
                const time = getHabitScheduleTime(actionWithEvents)
                return t('translation:programs.action.habit.scheduled', {frequency, time})
            }
            else {
                const date =  moment(actionWithEvents.events[0].date).locale(userLanguage || 'en').format('Do MMM YYYY, HH:mm');
                return t('translation:programs.action.one_time.scheduled', {date})
            }
        }
        if (status === 'completed' && action.type === 'one_time') {
            const date =  moment(actionWithEvents.events[0].date).locale(userLanguage || 'en').fromNow();
            return t('translation:programs.action.one_time.completed', {date})
        }
        return "";
    }

    const nbLineUpdate = () => {
        setDisplayFullExplanation(!displayFullExplanation)
    }

    const renderActionText = (action) => {
        const status: any = checkActionCompletion(action);
        const explanationText = action.type === 'habit' ? t('translation:programs.action.habit.explanation') : t('translation:programs.action.one_time.explanation');
        const additionalText = getText(action, status);

        return (
            <View style={[styles.content, {padding: PR(10), paddingTop: 0, alignItems: 'center'}]}>
                <View style={{width: '90%', marginBottom: PR(20)}}>
                    <View style={{flexDirection: 'row'}}>
                        <Icon name='info' color='#3366BB'/>
                        <Text numberOfLines={displayFullExplanation ? 0 : 2} style={{fontSize: PR(13), fontStyle: 'italic', color: GlobalStyles.global.grey}}>{explanationText}</Text>
                    </View>
                    <TouchableOpacity onPress={nbLineUpdate} style={{alignSelf: 'flex-end'}}>
                        <Text style={{color: '#3366BB'}}>{displayFullExplanation ? t('translation:general.text.see_less') : t('translation:general.text.see_more')}</Text>
                    </TouchableOpacity>
                </View>
                <Text style={{fontSize: PR(14), marginBottom: PR(20)}}>{additionalText}</Text>
                <CommonBtn type="primary" title={ status === 'not_selected' || status === 'not_started' ? t('translation:programs.action.schedule_now') : t('translation:programs.action.go_to_action')} click={() => {onActionClick(action, status)}}/>
            </View>
        )
    }

    const renderStatusIcon = (status)  => {
        if (status === 'not_selected')
            return <Icon name="plus-circle" size={PR(22)} color={GlobalStyles[APP_SHORTCUT_NAME].color} />
        else if (status === 'not_started')
            return <Icon name="calendar" color={GlobalStyles[APP_SHORTCUT_NAME].color} size={22}/>;
        else {
            return <CommonProgressIcon status={status} color={GlobalStyles[APP_SHORTCUT_NAME].color}/>
        }
    }

    const checkActionCompletion = (action) => {
        if (!patientProgramActionEvents)
            return 'not_selected'
        const actionWithEvent = patientProgramActionEvents.find(item => item.id === action.id);
        if (!actionWithEvent)
            return 'not_selected'
        else {
            if (!actionWithEvent.events || actionWithEvent.events.length === 0)
                return 'not_started'
            else if (action.type === 'one_time')
                return actionWithEvent.events[0].done ? 'completed' : 'in_progress';
            else if (action.type === 'habit')
                return actionWithEvent.events.length === action.repetition ? 'completed' : 'in_progress'
        }
    }

    return (
        <View testID={`content-action-${content.index}`}>
            <View style={CommonStyle.actionDescContainer}>
                <Text style={CommonStyle.actionDesc}>{getCaptionInLanguage(userLanguage, content.actionCaption)}</Text>
            </View>
            {content.actions.map((itemAction, index) => {
                const actionTitle:Record<string, string> = itemAction?.title || {en:t('translation:general.empty_title')}
                const status = checkActionCompletion(itemAction)
                
                return(
                    <View style={styles.container}>
                        <TouchableOpacity 
                            onPress={() => {expandAction(itemAction, index, status)}}
                            key={index} 
                        >
                            <View style={[styles.wrapper, styles.header]}>
                                <View style={styles.checkBoxContainer}>
                                    {renderStatusIcon(status)}
                                </View>
                                <Text style={styles.txt}>{actionTitle[userLanguage] || actionTitle.en}</Text>
                            </View>
                           
                        </TouchableOpacity>
                        {
                            index === expandedAction && 
                                renderActionText(itemAction)
                        }
                    </View>
                )
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        borderRadius: PR(20),
        borderWidth: PR(2),
        borderColor: GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor,
        width:'90%',
        alignSelf:'center', 
        marginBottom:PR(8),
        backgroundColor: GlobalStyles.global.white,
        shadowColor: '#333333',
        shadowOffset: {
            width: PR(2),
            height: PR(10),
        },
        shadowOpacity: 0.10,
        shadowRadius: 13.97,
        elevation: 10,
    },
    header: {
        flexDirection: 'row',
        minHeight: PR(62),
        width: '90%'
    },
    content: {
        flexDirection: 'column',
        width: '100%'
    },
    wrapper: {
        padding: PR(10),
        paddingLeft:PR(20),
        alignItems:'center'
    },
    checkBoxContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: PR(2),
        marginRight: PR(10)
    },
    selectedcheckBox: {
        height: PR(24),
        width: PR(24),
        backgroundColor: GlobalStyles[APP_SHORTCUT_NAME].btnColor,
        borderRadius: PR(15)
    },
    txt: {
        fontSize: PR(16),
        color: GlobalStyles.global.black
    },
})

export default CommonActionContentComponent;