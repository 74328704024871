import React, {useState, useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import GlobalStyle from '../styles/GlobalStyles';
import {APP_SHORTCUT_NAME} from '../env.json';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

interface answerTypes {
  name:string,
  value:string
}

const CommonRadioBtn = (props:any) => {
  const [radioSelect, setRadioSelect] = useState<string>('')
  useEffect(() => {
    setRadioSelect(props.selected || '')
  },[props.selected])
  // send the obj to parent component
  const sendBackAnswer = (answer:string) => {
    props.updateAnswer(answer)
  }
  return (
    <View style={styles.wrapper}>
      {
        props.answers.map((item:answerTypes, val:number) => {
          return (
            <View style={styles.itemWrapper} key={val}>
              <Text renderAsHtml={true} style={props.textColor ? [styles.radioTxt, {color:props.textColor}] : styles.radioTxt}>{item.value}</Text>
              <TouchableOpacity testID={item.name} onPress={() => [setRadioSelect(item.name),sendBackAnswer(item.name)]}>
                <View style={styles.radioBtnContainer}>
                  {
                    item.name == radioSelect ?
                      <View style={styles.selectedRadioBtn} />
                      : null
                  }
                </View>
              </TouchableOpacity>
            </View>
          );
        })
      }
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems:'center',
    // padding: 25
  },
  itemWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems:'center',
  },
  radioBtnContainer: {
    height: PR(30),
    width: PR(30),
    borderRadius: PR(15),
    borderWidth: PR(2),
    borderColor: GlobalStyle.global.lightGrey,
    backgroundColor: GlobalStyle.global.lightGrey,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: PR(10),
    marginLeft: PR(10)
  },
  selectedRadioBtn: {
    height: PR(20),
    width: PR(20),
    borderRadius: PR(10),
    backgroundColor: GlobalStyle[APP_SHORTCUT_NAME].color
  },
  radioTxt: {
    color: GlobalStyle.global.grey,
    fontSize: PR(15)
  }
})
  
export default CommonRadioBtn;