import React, {useState, useEffect} from "react";
import {View, StyleSheet} from 'react-native';
import CommonCriteriaCheckMark from "./CommonCriteriaCheckMark";
import GlobalStyles from "../styles/GlobalStyles";
import {widthPercentageToDP as PR} from '../styles/PixelRatio';
import { useTranslation } from 'react-i18next';

interface CriteriaTypes {
  password: string,
  checkCriteria: any
}

const CommonPwdCriteria = ({password, checkCriteria}: CriteriaTypes) => {
  const {t} = useTranslation();
  const [minChar, setMinChar] = useState<boolean>(false)
  const [num, setNum] = useState<boolean>(false)
  const [upper, setUpper] = useState<boolean>(false)
  const [lower, setLower] = useState<boolean>(false)
  const [specialChar, setSpecialChar] = useState<boolean>(false)
  const [isNotSpace, setIsNotSpace] = useState<boolean>(true)

  const specialFormat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

  useEffect(() => {
      password.length >= 8 ? setMinChar(true) : setMinChar(false);
      /\d/.test(password) ? setNum(true) : setNum(false);
      /[A-Z]/.test(password) ? setUpper(true) : setUpper(false);
      /[a-z]/.test(password) ? setLower(true) : setLower(false);
      specialFormat.test(password) ? setSpecialChar(true) : setSpecialChar(false);
      /^\S*$/.test(password) ? setIsNotSpace(true) : setIsNotSpace(false);
  },[password])

  useEffect(() => {
    if(minChar && num && upper && lower && specialChar && isNotSpace)
      checkCriteria(true)
    else
      checkCriteria(false)
  }, [minChar, num, upper, lower, specialChar, isNotSpace])

  return(
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <CommonCriteriaCheckMark 
          icon='check-circle'
          value={minChar} 
          color={GlobalStyles.global.ligthGreen}
          text={t('translation:register.pwd.criteria.minChar')}/>
      </View>
      <View style={styles.container}>
        <CommonCriteriaCheckMark 
          icon='check-circle'
          value={num} 
          color={GlobalStyles.global.ligthGreen}
          text={t('translation:register.pwd.criteria.num')}/>
      </View>
      <View style={styles.container}>
        <CommonCriteriaCheckMark 
          icon='check-circle'
          value={upper} 
          color={GlobalStyles.global.ligthGreen}
          text={t('translation:register.pwd.criteria.upper')}/>
      </View>
      <View style={styles.container}>
        <CommonCriteriaCheckMark 
          icon='check-circle'
          value={lower} 
          color={GlobalStyles.global.ligthGreen}
          text={t('translation:register.pwd.criteria.lower')}/>
      </View>
      <View style={styles.container}>
        <CommonCriteriaCheckMark 
          icon='check-circle'
          value={specialChar} 
          color={GlobalStyles.global.ligthGreen}
          text={t('translation:register.pwd.criteria.specialChar')}/>
      </View>
      {
        !isNotSpace &&
        <View style={styles.container}>
          <CommonCriteriaCheckMark 
            icon='x-circle'
            value={!isNotSpace} 
            color={GlobalStyles.global.redError}
            text={t('translation:register.pwd.criteria.spaces')}/>
        </View>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    marginTop: PR(-20),
    marginBottom: PR(20),
    width: '90%',
    alignSelf: 'center'
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: PR(20)
  }
})

export default CommonPwdCriteria;