export const translate = (t, key, list) => {
    // @ts-ignore
    let index = t<string | TemplateStringsArray>('translation:'+list, {returnObjects: true}).findIndex((itemType)=>
    Object.keys(itemType).toString() === key)
    if (index > -1) 
      return t('translation:'+list+'.'+index+'.'+key)
    else 
      return key
  }

export const convertLangCode = (code) => {
  if(code === 'can-en')
    code = 'en'
  else if(code === 'can-fr')
    code = 'fr'

  return code
}