import React from 'react';
import {View, StyleSheet, Platform, ScrollView, SafeAreaView} from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';

const QualityOfLife = ({navigation}) => {
    return(
        <SafeAreaView style={styles.container}>
            <CommonHeader
                testIDTitle="quality_of_life_title"
                title={'Quality of life'}
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {
                    navigation.navigate('Main', {screen: 'Programs'});
                    navigation.reset({
                    index: 0,
                    routes: [{ name: 'Programs' }],
                    });
                }}
            />
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: GlobalStyles.global.white,
    },
})

export default QualityOfLife;