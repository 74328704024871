import React, {useEffect, useState} from 'react';
import {
  StyleSheet,
  Linking,
  ScrollView,
  View,
  SafeAreaView,
  Platform,
  LogBox
} from 'react-native';
import GlobalStyle from 'common/src/styles/GlobalStyles';
import CommonAboutFooter from "common/src/components/CommonAboutFooter";
import CommonHeader from "common/src/components/CommonHeader";
import CommonSettingsBtn from 'common/src/components/CommonSettingsBtn';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonActionModal from 'common/src/components/CommonActionModal';
import { version } from '../../package.json';
import Text from 'common/src/components/CommonCustomTxt';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch } from 'react-redux';
import { logOutAction, updateLanguageAction } from '../store/actions/authenticationActions';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {SettingsTypes, defaultLang} from 'common/src/services/settings_service';
import { revokeFbTokeAction } from '../store/FB-token/fb_tokenAction';

LogBox.ignoreAllLogs();

const Settings = ({ navigation, route }) => {
  const {i18n, t} = useTranslation();
  const lang = i18n.language;
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);
  const [scrollState, setScrollState] = useState();
  const [displayLanguageModal, setDisplayLanguageModal] = useState(false);
  const deviceType:string | undefined = route?.params?.deviceType;
  const FBToken:string | undefined = route?.params?.FBToken;

  const profileList = Platform.OS === 'web' ? 
    [
      {name:t('translation:settings.btn.profile'), value: SettingsTypes.Profile},
      {name:t('translation:settings.titles.physicians'), value: SettingsTypes.My_neurologists},
      {name:t('translation:settings.btn.language'), value: SettingsTypes.Language},
      {name:t('translation:treatment.form.timezone'), value: SettingsTypes.Timezone},
      {name:t('translation:settings.btn.change_password'), value: SettingsTypes.Change_my_password},
    ]
    :
    [
      {name:t('translation:settings.btn.profile'), value: SettingsTypes.Profile},
      {name:t('translation:settings.titles.physicians'), value: SettingsTypes.My_neurologists},
      {name:t('translation:settings.btn.language'), value: SettingsTypes.Language},
      {name:t('translation:treatment.form.timezone'), value: SettingsTypes.Timezone},
      {name:t('translation:settings.btn.change_password'), value: SettingsTypes.Change_my_password},
      {name:t('translation:settings.btn.enable_disable_biometrics'), value: SettingsTypes.Enable_Disable_biometrics},
    ]


  useEffect(() => {
    AsyncStorage.getItem('token')
      .then((value:any) => {
        if (value !== null && value !== "") {
          setLoggedIn(true)
        }
      });
  },[]);

  const setUpdateClick = (itemName:string) => {
    if (itemName === SettingsTypes.Help) {
      Linking.openURL("mailto:help@neuroventis.care");
    } 
    else if (itemName === SettingsTypes.Our_privacy_notice) {
      let hrefPrivacy = t('translation:settings.support.privacy_url')
      Platform.OS === 'web' ? window.open(hrefPrivacy, '_blank') : Linking.openURL(hrefPrivacy)
    }
    else if (itemName === SettingsTypes.Our_terms_of_use) {
      let hrefTerms = t('translation:settings.support.terms_url')
      Platform.OS === 'web' ? window.open(hrefTerms, '_blank') : Linking.openURL(hrefTerms)
    }
    else if (itemName === SettingsTypes.Treatment_reminders) {
      navigation.navigate(SettingsTypes.Treatment_reminders,{itemType:'Reminder'})
    }
    else if (itemName === SettingsTypes.Language) {
      setDisplayLanguageModal(true)
    }
    else {
      navigation.navigate(itemName)
    }
  }

  const logout = () => {
    
    let dataToSend = {
      data:{
        device_type: deviceType,
        device_token: FBToken
      }
    }
    
    if(Platform.OS !== 'web') {
      dispatch(revokeFbTokeAction({
        data: dataToSend.data,
        callback: () => {
          dispatch(logOutAction());
        }
      }))
    } else {
        dispatch(logOutAction());
    }
  }

  const updateLanguage = (values) => {
    const selectedLanguage = values.find(language => language.selected);
    
    if (!selectedLanguage) {
      return ;
    }

    i18n.changeLanguage(selectedLanguage.lngCode);
    if(loggedIn){
      dispatch(updateLanguageAction({ language: selectedLanguage.lngCode }));
    }
    setDisplayLanguageModal(false)
  }

  const changeModalVisibility = () => {
    setDisplayLanguageModal(!displayLanguageModal)
  }

  return (
    <>
      <SafeAreaView style={styles.wrapper}>
        {
            displayLanguageModal &&
              <CommonActionModal 
                testID={"LanguageModalID"} 
                title={t('translation:settings.select_language_title')}
                modalType={'select-radio'} 
                buttons={[{title: t('translation:general.header.btn_save'), 
                type: 'primary', click: updateLanguage, action: 'save'}]} 
                onClose={changeModalVisibility} 
                data={defaultLang(lang)}
              />
        }
        {loggedIn? 
          (<CommonHeader
            title={t('translation:general.page_title.settings')} 
            scroll={scrollState}
            testIDTitle="settings_title"
            mainPage={true}
          />
          ) : (
            <CommonHeader
              title={t('translation:general.page_title.settings')} 
              leftIconName="arrow-left"
              leftIconColor={GlobalStyle.helpi.color}
              leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
              testIDTitle="settings_title"
              scroll={scrollState}
              disableFormsBanner={true}
            />
          )
        }
        <ScrollView 
          testID="settings_scrollView"
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            setScrollState(event.nativeEvent.contentOffset.y);
          }}
        >
          {loggedIn? (
            <View>
              <View style={styles.sectionHeader}>
                <Text style={styles.sectionHeaderText}>
                  {t('translation:settings.titles.profile')}
                </Text>
              </View>
              <CommonSettingsBtn 
                listNames={profileList} 
                clicked={setUpdateClick}
              />
              <View style={styles.settingsSection}>
                <View style={styles.sectionHeader}>
                  <Text style={styles.sectionHeaderText}>
                    {t('translation:settings.titles.epilepsy_details')}
                  </Text>
                </View>
                <CommonSettingsBtn listNames={
                  [
                    {name:t('translation:settings.btn.my_seizures_description'), value: SettingsTypes.My_seizures_description},
                    {name:t('translation:settings.btn.seizure'), value: SettingsTypes.SeizureVideos},
                    {name:t('translation:settings.btn.my_triggers_model'), value: SettingsTypes.My_triggers_model},
                    {name:t('translation:settings.btn.my_seizure_antidote'), value: SettingsTypes.My_seizure_antidote},
                    {name:t('translation:report.tables_page.appointment_table.title'), value: SettingsTypes.My_appointments},
                    {name:t('translation:settings.btn.reports'), value: SettingsTypes.My_reports}
                  ]
                  } 
                  clicked={setUpdateClick}
                />
              </View>
            </View>
          ) : (
            <View>
              <View style={styles.sectionHeader}>
                <Text style={styles.sectionHeaderText}>
                  {t('translation:settings.titles.language')}
                </Text>
              </View>
              <CommonSettingsBtn listNames={
                [{name:t('translation:settings.btn.language'), value: SettingsTypes.Language},]
                } 
                clicked={setUpdateClick}
              />
            </View>
          )}

          {loggedIn && (
            <View>
              <View style={styles.settingsSection}>
                <View style={styles.sectionHeader}>
                  <Text style={styles.sectionHeaderText}>
                  {t('translation:settings.titles.devices')}
                  </Text>
                </View>
                <CommonSettingsBtn listNames={
                  [
                    {name:t('translation:settings.btn.nightwatch'), value: SettingsTypes.Nightwatch},
                  ]
                  } 
                  clicked={setUpdateClick}
                />
              </View>
              <View style={styles.settingsSection}>
                <View style={styles.sectionHeader}>
                  <Text style={styles.sectionHeaderText}>
                    {t('translation:settings.titles.treatment')}
                  </Text>
                </View>
                <CommonSettingsBtn listNames={
                  [
                    {name:t('translation:settings.btn.reminders'), value: SettingsTypes.Treatment_reminders},
                    {name:t('translation:settings.btn.mood_sleep'), value: SettingsTypes.Mood_and_sleep},
                  ]
                  } 
                  clicked={setUpdateClick}
                />
              </View>
            </View>
          )}

          <View style={styles.settingsSection}>
            <View style={styles.sectionHeader}>
              <Text style={styles.sectionHeaderText}>
                {t('translation:settings.titles.support')}
              </Text>
            </View>
            {
            loggedIn?
            (
              <CommonSettingsBtn listNames={
                [
                  {name:t('translation:settings.support.help'), value: SettingsTypes.Help},
                  {name:t('translation:settings.support.tutorial'), value: SettingsTypes.Mini_tutorial},
                  {name:t('translation:settings.support.privacy'), value: SettingsTypes.Our_privacy_notice},
                  {name:t('translation:settings.support.terms'), value: SettingsTypes.Our_terms_of_use},
                  {name:t('translation:settings.support.consent'), value: SettingsTypes.InformedConsent},
                  {name:t('translation:settings.about.title'), value: SettingsTypes.About},
                ]
                } 
                clicked={setUpdateClick}
              />
            )
            :
            <CommonSettingsBtn listNames={
              [
                {name:t('translation:settings.support.help'), value: SettingsTypes.Help},
                {name:t('translation:settings.support.tutorial'), value: SettingsTypes.Tutorial},
                {name:t('translation:settings.support.privacy'), value: SettingsTypes.Our_privacy_notice},
                {name:t('translation:settings.support.terms'), value: SettingsTypes.Our_terms_of_use},
                {name:t('translation:settings.about.title'), value: SettingsTypes.About},
              ]
              } 
              clicked={setUpdateClick}
            />
            }
          </View>
          {loggedIn && (
            <View style={{padding: PR(5)}}>
              <CommonBtn type="tertiary" txtColor="#F43535" click={logout} title={t('translation:settings.logout')} testID="logout_btn"/>
            </View>
          )}
          <View style={styles.infoSection}>
            <Text style={styles.versionText}>
            {t('translation:settings.about.app_version')} - {version}
            </Text>
          </View>
          <CommonAboutFooter />
          <View style={{marginBottom:PR(40)}}></View>
        </ScrollView>
      </SafeAreaView>
    </>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: GlobalStyle.global.white,
    flex: 1,
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
  },
  settingsSection: {
    marginTop: PR(30)
  },
  sectionHeader: {
    // paddingHorizontal: 20,
    width: '90%',
    alignSelf: 'center',
    paddingVertical: PR(6),
  },
  sectionHeaderText: {
    color: GlobalStyle.global.black,
    fontSize: PR(20),
    fontFamily: GlobalStyle.global.fontFamily.Bold,
    letterSpacing: 0,
  },
  item: {
    paddingVertical: PR(13),
    paddingHorizontal: PR(18),
    borderBottomWidth: PR(1),
    borderBottomColor: GlobalStyle.global.lightGrey,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  itemText: {
    fontSize: PR(16),
    letterSpacing: PR(1),
  },
  infoSection: {
    marginTop: PR(30),
    display: "flex",
    flexDirection: "column",
    alignContent: "center"
  },
  ceMarking: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  versionText: {
    color: "#bbb",
    fontSize: PR(13),
    textAlign: "center",
    marginBottom: PR(40)
  },
  selectElement: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  timezoneSelect: {
    paddingVertical: PR(16),
    display: "flex",
    flexDirection: "column",

  },
  timezoneTitle: {
    fontSize: PR(16),
  },
  timezoneDropdownIcon: {
    height: 0,
    marginTop: "auto",
    paddingBottom: PR(37),
  }

});

export default Settings;