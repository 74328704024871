import { PatientEducationTrack, PatientProgram } from 'common/src/types';
import { AnyAction } from 'redux';
import { UPDATE_MOOD_AND_SLEEP_SUCCESS } from '../actions';
import { RootState } from '../reducers';
import { PatientProgramActions } from './patientProgramsActions';
import { GET_PATIENT_PROGRAMS_SUCCESS, ADD_PROGRAM_ACTION_SUCCESS, START_PROGRAM_ACTION_SUCCESS, ADD_PATIENT_PROGRAMS_SUCCESS } from './programsActions';
import moment from 'moment';

interface PatientProgramsReducer {
    activePatientProgramId: string;
    patientPrograms: PatientProgram[];
    patientProgramQuestionnaires: Record<string, object> | null;
    patientProgramEducationTrack?: PatientEducationTrack;
    patientProgramActionEvents: Array<any>;
    currentProgramActionSchedule: Record<string, object> | null;
    activePatientProgram?: PatientProgram; 
}

const initialState: PatientProgramsReducer = {
    activePatientProgramId: '',
    patientPrograms: [],
    patientProgramQuestionnaires: null,
    patientProgramActionEvents: [],
    currentProgramActionSchedule: null,
};

const getUpdatedTracks = (trackId: string, currentTracks: PatientProgram[], updateData: Partial<PatientProgram>): PatientProgram[] => {
    const trackToUpdate =  currentTracks.find(({id}) => id === trackId);
    if (!trackToUpdate) {
        return currentTracks;
    }

    const updatedTracks = currentTracks.filter(({id}) => id !== trackId);
    updatedTracks.push({ ...trackToUpdate, ...updateData});
    return updatedTracks;
};

export default function (state = initialState, action: AnyAction): PatientProgramsReducer {
    if (action.type === PatientProgramActions.UPDATE_CHAPTER_LAST_PAGE_SUCCESS || action.type === PatientProgramActions.COMPLETE_CHAPTER_SUCCESS) {
        const { result } = action.response;
        const educationTrack = state.patientProgramEducationTrack ? Object.assign(state.patientProgramEducationTrack, result.education) : Object.assign({}, result.education);
        const updatedPatientPrograms = getUpdatedTracks(result.id, state.patientPrograms, { education: result.education });
        return { ...state, patientPrograms: [...updatedPatientPrograms], patientProgramEducationTrack: { ...educationTrack }};
    }
    if (action.type === PatientProgramActions.SET_ACTIVE_PATIENT_PROGRAM) {
        let educationTrack = state.patientProgramEducationTrack;
        let patientQuestionnaires = state.patientProgramQuestionnaires;

        const active = state.patientPrograms.length > 0 ? state.patientPrograms.find(({id}) => id === action.id) : undefined;
        if (active) {
            educationTrack = state.patientProgramEducationTrack ? Object.assign(state.patientProgramEducationTrack, active.education) : Object.assign({}, active.education);
            patientQuestionnaires = state.patientProgramQuestionnaires === null ? Object.assign({}, active.questionnaires) : Object.assign(state.patientProgramQuestionnaires, active.questionnaires);
        }
        return {
            ...state,
            activePatientProgramId: action.id,
            activePatientProgram: active,
            patientProgramEducationTrack: { ...educationTrack },
            patientProgramQuestionnaires: { ...patientQuestionnaires }
        };
    }
    if (action.type === PatientProgramActions.UPDATE_QUESTIONNAIRE_SUCCESS) {
        const { result } = action.response;
        const questionnaires = result.questionnaires ?? {};
        const updatedPatientPrograms = getUpdatedTracks(result.id, state.patientPrograms, { questionnaires: result.questionnaires });
        return { ...state, patientPrograms: [...updatedPatientPrograms],  patientProgramQuestionnaires: { ...questionnaires } };
    }
    if (action.type === GET_PATIENT_PROGRAMS_SUCCESS) {
        const { result } = action.response;
        return { ...state, patientPrograms: result };
    }
    if (action.type === PatientProgramActions.GET_PATIENT_PROGRAM_ACTION_EVENTS_SUCCESS) {
        const { result } = action.response;
        return { ...state, patientProgramActionEvents: result || []};
    }
    if (action.type === PatientProgramActions.GET_PATIENT_PROGRAM_ACTION_SCHEDULE_SUCCESS) {
        const { result } = action.response;
        return { ...state, currentProgramActionSchedule: result};
    }
    if (action.type === ADD_PROGRAM_ACTION_SUCCESS) {
        const { dataSent } = action.response;
        const programAction = dataSent.action;
        let programActionEvents = [...state.patientProgramActionEvents];
        let programActionSchedule = state.currentProgramActionSchedule
        const programActionIndex = programActionEvents.findIndex(item => item.id === programAction.id);
        if (programActionIndex > -1) {
            programActionEvents.splice(programActionIndex, 1)
            programActionSchedule = null;
        }
        else {
            programAction.events = []
            programActionEvents.push(programAction)
        }
        return {...state, patientProgramActionEvents: programActionEvents, currentProgramActionSchedule: programActionSchedule}
    }
    if (action.type === START_PROGRAM_ACTION_SUCCESS) {
        const { result } = action.response;
        const programActionId = result.action;
        let programActionEvents = [...state.patientProgramActionEvents];
        const programActionFound = programActionEvents.find(item => item.id === programActionId);
        if (programActionFound) {
            const eventFoundIndex = programActionFound.events.findIndex(item => item.id === result.id)
            if (eventFoundIndex > -1)
                programActionFound.events[eventFoundIndex] = result
            else
                programActionFound.events.push(result)
        }
        return {...state, patientProgramActionEvents: programActionEvents}
    }
    if (action.type === UPDATE_MOOD_AND_SLEEP_SUCCESS) {
        if (state.patientPrograms.length !== 1)
            return state
        const { result } = action.response;
        const actionsWithEvents = [...state.patientProgramActionEvents];
        actionsWithEvents && actionsWithEvents.forEach((_action) => {
            if (_action.actionBtnNavigation && _action.actionBtnNavigation.value === result.type && _action.events) {
                const eventFoundIndex = _action.events.findIndex(item => moment(item.date).format('YYYY-MM-DD') === moment(result.date).format('YYYY-MM-DD'));
                if (eventFoundIndex > -1)
                    _action.events[eventFoundIndex] = result;
            }
        })
        return {...state, patientProgramActionEvents: actionsWithEvents}
    }
    if (action.type === ADD_PATIENT_PROGRAMS_SUCCESS) {
        const response = action.response;
        const patientProgramsCpy = [...state.patientPrograms];

        if (response.result.program)
            patientProgramsCpy.push(response.result)

        return { 
            ...state, 
            patientPrograms: patientProgramsCpy,
        };
    }
    return state;
}

export const activePatientProgramId = (state: RootState) => state.patientProgramsReducers.activePatientProgramId;

export const activePatientProgram = (state: RootState) => state.patientProgramsReducers.activePatientProgram;

export const patientProgramEducationTrack = (state: RootState) => state.patientProgramsReducers.patientProgramEducationTrack;

export const patientPrograms = (state: RootState) => state.patientProgramsReducers.patientPrograms;

export const patientProgramQuestionnaires = (state: RootState) => state.patientProgramsReducers.patientProgramQuestionnaires;
