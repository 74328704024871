import React, {useState} from 'react';
import { View, Platform, ScrollView } from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonSubTitle from 'common/src/components/CommonSubtitle';
import InputField from 'common/src/components/InputField';
import CommonSelectForm from 'common/src/components/CommonAddEvents/CommonSelectForm';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonFooter from 'common/src/components/CommonFooter';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import {useTranslation} from 'react-i18next';
import { getListCMSData } from 'common/src/components/CommonDiary/utils';
import moment from 'moment';
import { AppointmentChecklistTypes, RootStackParams } from 'common/src/types';
import { StackNavigationProp } from '@react-navigation/stack';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

type OwnProps = {
    navigation: StackNavigationProp<RootStackParams, "AddEvent">,
    route: {
        params: {
            checklistInitialData?:AppointmentChecklistTypes,
            initialData?: Record<string, Record<string, object>>
        }
    }
}

const AppointmentChecklist = ({navigation, route}:OwnProps) => {
    const {checklistInitialData, initialData} = route.params || {};
    const {t} = useTranslation();
    const [scrollState, setScrollState] = useState();
    const [worryAndFears, setWorryAndFears] = useState<string>(checklistInitialData?.worryAndFears || '');
    const [positiveElements, setPositiveElements] = useState<string>(checklistInitialData?.positiveElements || '');
    const [sideEffectDropdown, setSideEffectDropdown] = useState<string>(checklistInitialData?. sideEffectDropdown || '');
    const [satisfactionTreatment, setSatisfactionTreatment] = useState<string>(checklistInitialData?.satisfactionTreatment || '');
    const [discussTreatmentChange, setDiscussTreatmentChange] = useState<string>(checklistInitialData?.discussTreatmentChange || '');
    const [sideEffectType, setSideEffectType] = useState<Array<string>>(checklistInitialData?.sideEffectType || []);
    const [reasonToChange, setReasonToChange] = useState<string>(checklistInitialData?.reasonToChange || '');
    const dropdownData = [
        {value: 'yes', label: t('translation:modal.btn.yes'),selected: false},
        {value: 'no', label: t('translation:modal.btn.no'),selected: false}
    ]

    const saveChecklist = () => {
        const checklistDate = moment().toISOString();
        navigation.navigate('AddEvent', {
            itemType: 'Appointment',
            checklistData: {
                checklist:{
                    checklistDate,
                    worryAndFears,
                    positiveElements,
                    sideEffectDropdown,
                    satisfactionTreatment,
                    discussTreatmentChange,
                    sideEffectType,
                    reasonToChange
                }
            },
            initialData
        })
    }

    const isDisabled = () => ( 
        worryAndFears.length === 0 && 
        positiveElements.length === 0 && 
        sideEffectDropdown.length === 0 && 
        satisfactionTreatment.length === 0 && 
        discussTreatmentChange.length === 0 && 
        sideEffectType.length === 0 &&
        reasonToChange.length === 0
    )

    return(
        <CommonKeyboardAvoidingView>
            <CommonHeader
                title={t('translation:general.page_title.epilepsy_appointment_checklist')} 
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {navigation.goBack()}}
                testIDTitle="epilepsy_apointment_checklist_title"
                scroll={scrollState}
            />
            <ScrollView 
                testID="appointmentChecklist_scrollView"
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                    setScrollState(event.nativeEvent.contentOffset.y);
                }}
            >
                <View style={{width:'90%', alignSelf:'center'}}>
                    <CommonSubTitle 
                        text={t('translation:general.page_subtitle.epilepsy_appointment_checklist')}
                        txtAlign='center'
                    />
                </View>
                <InputField 
                    labelText={t('translation:general.worryAndFears.label')}
                    inputType="string" 
                    customStyle={{marginTop:PR(30), marginBottom:PR(30)}}
                    changeText={setWorryAndFears}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="worry_and_fears"
                    multiline={true}
                    value={worryAndFears}
                />
                <InputField 
                    labelText={t('translation:general.positiveElements.label')}
                    inputType="string" 
                    customStyle={{marginBottom:PR(30)}}
                    changeText={setPositiveElements}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="positive_elements"
                    multiline={true}
                    value={positiveElements}
                />
                <InputField 
                    inputType="dropdown" 
                    customStyle={{marginBottom:PR(30)}}
                    placeholderTxt={t('translation:modal.select.title_one')} 
                    labelText={t('translation:events.side_effect.info_title')} 
                    value={sideEffectDropdown} 
                    testID="side_effect_dropdown"
                    changeText={setSideEffectDropdown} 
                    data={dropdownData}
                />
                {sideEffectDropdown === 'yes' && 
                    <View testID='side_effect_type'>
                        <CommonSelectForm
                            values={sideEffectType}
                            setValues={setSideEffectType}
                            cmsListLink="list.side_effect_types"
                            patientList="effect_type_inputs"
                            label={t('translation:general.whichSideEffects.label')}
                        />
                    </View>
                }
                <InputField 
                    inputType="dropdown" 
                    customStyle={{marginBottom:PR(30)}}
                    placeholderTxt={t('translation:modal.select.title_one')} 
                    labelText={t('translation:general.satisfactionTreatment.label')} 
                    testID="satisfaction_treatment"
                    value={getListCMSData(t, satisfactionTreatment, 'list.satisfaction')} 
                    changeText={(answer) => setSatisfactionTreatment(JSON.parse(answer.replace(/[\[\]']+/g,'')))} 
                    // @ts-ignore
                    data={t<string | TemplateStringsArray>('translation:list.satisfaction', {returnObjects: true}).map((name,i) => {
                        return {label:t('translation:list.satisfaction'+'.'+i+'.'+Object.keys(name)), value:JSON.stringify(Object.keys(name))}
                      })}
                />
                <InputField 
                    inputType="dropdown" 
                    customStyle={{marginBottom:PR(30)}}
                    placeholderTxt={t('translation:modal.select.title_one')} 
                    labelText={t('translation:general.discussTreatmentChange.label')} 
                    testID="discuss_treatment_changes_dropdown"
                    value={discussTreatmentChange} 
                    changeText={setDiscussTreatmentChange} 
                    data={dropdownData}
                />
                {discussTreatmentChange === 'yes' &&
                    <InputField 
                        labelText={t('translation:general.reasonToChange.label')}
                        inputType="string" 
                        customStyle={{marginBottom:PR(30)}}
                        changeText={setReasonToChange}
                        placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                        testID="reason_to_change"
                        multiline={true}
                        value={reasonToChange}
                    />
                }
                <CommonFooter>
                    <CommonBtn 
                        type="primary" 
                        click={() => saveChecklist()} 
                        title={t('translation:general.header.btn_save')} 
                        testID="save_appointment_checklist" 
                        disable={isDisabled()}
                    />
                </CommonFooter>
            </ScrollView>
        </CommonKeyboardAvoidingView>
    )
}

export default AppointmentChecklist;