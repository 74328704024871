import React, { useState, useEffect } from 'react';
import {View, ScrollView, SafeAreaView, StyleSheet, Platform} from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonSelect from 'common/src/components/CommonSelect';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonFooter from 'common/src/components/CommonFooter';
import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateTimezoneAction } from '../store/timezones/changeTimezoneActions';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import timezonesWithOffset from 'common/src/services/timezonesWithOffset.json'
import { isCloseToBottom } from '../components/DiaryFunctions';

type TimezoneListTypes = {
	label: string,
	value: string,
	selected: boolean,
}

const Timezone = ({navigation}) => {
    const {i18n, t} = useTranslation();
    const lang = i18n.language;
    const dispatch = useDispatch();
    const getMeRes = useSelector((state:any) => state.getMe.response);
    const [scrollState] = useState()
    const [contentHeight,] = useState<number>(5000);
    const [scrollAtBottom] = useState<boolean>(false);
    const [selectedTimezone, setSelectedTimeZone] = useState<string>(getMeRes?.result?.settings?.timezone || '')
    const [timezoneList, setTimezoneList] = useState<Array<TimezoneListTypes>>([]);
	const [limit, setLimit] = useState<number>(50);
    let foundTz = timezonesWithOffset.timezones.find(tz => tz.tzCode === selectedTimezone)

    useEffect(() => {
        if(timezoneList.length === 0)
            getTimeZonesFunc()
    },[])
    
   const getTimeZonesFunc = () => {
		let tempArrTimeZone: TimezoneListTypes[] = [];
		let tempItem: TimezoneListTypes = { label: '', value: '', selected: false};
		for (let i = 0; i <= timezonesWithOffset.timezones.length -1; i++ ){
			if (preSelected(timezonesWithOffset.timezones[i].name)) {
				tempItem = {
					label:timezonesWithOffset.timezones[i].label,
					value: timezonesWithOffset.timezones[i].tzCode,
					selected: true,
				};
			} else {
				tempArrTimeZone.push({
					label:timezonesWithOffset.timezones[i].label,
					value: timezonesWithOffset.timezones[i].tzCode,
					selected: false,
				});
			}
		}
		tempArrTimeZone.unshift(tempItem);
		setTimezoneList(tempArrTimeZone);
   };
   const preSelected = (value: string) => {
		if (value === foundTz?.name) {
			return true;
		}
   };
    
    const updateTimezone = (data: TimezoneListTypes[]) => {
        data.map((item) => {
            if(item.selected !== undefined && item.selected){
                setSelectedTimeZone(item.value)
            }
        })
    }

    const save = () => {
        const dataToUpdate = {
            dataToUpdate:{
                settings:{
                    language: lang,
                    timezone: selectedTimezone
                }
            }
        }
        dispatch(updateTimezoneAction(dataToUpdate))
    }

    return (
		<SafeAreaView style={styles.container}>
			<CommonHeader
				testIDTitle="timezone"
				title={t("translation:general.page_title.timezone")}
				leftIconName="arrow-left"
				leftIconColor={GlobalStyles.helpi.color}
				leftClick={() => {
					Platform.OS === "web"
						? window.history.back()
						: navigation.goBack();
				}}
				scroll={scrollState}
			/>
			<ScrollView
				onScroll={event => {
					if (isCloseToBottom(event.nativeEvent)) {
						setLimit(limit + 50);
					}
				}}
			>
				<View style={styles.wrapper}>
					<CommonSelect
						disable={false}
						style={{}}
						type={"radio"}
						data={timezoneList.slice(0, limit)}
						onSelect={updateTimezone}
					/>
				</View>
			</ScrollView>
			<CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
				<CommonBtn
					type="primary"
					title={t("translation:general.header.btn_save")}
					click={() => {
						save();
					}}
				/>
			</CommonFooter>
		</SafeAreaView>
	);
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: GlobalStyles.global.white,
        flex: 1,
    },
    wrapper: {
        width:'90%',
        alignSelf:'center',
        marginBottom: PR(20)
    }
})

export default Timezone;

