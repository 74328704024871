import React, {useState, useEffect} from 'react';
import { Platform } from 'react-native';
import CommonAddTreatmentTitleSection from 'common/src/components/CommonAddTreatments/CommonAddTreatmentTitleSection';
import CommonAddTreatmentPeriod from 'common/src/components/CommonAddTreatments/CommonAddTreatmentPeriod';
import CommonEditMedicationInfo from 'common/src/components/CommonEditTreatments/CommonEditMedicationInfo';
import CommonEditReview from 'common/src/components/CommonEditTreatments/CommonEditReview';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonModal from 'common/src/components/CommonModal';
import CommonAddTreatmentFrequency from 'common/src/components/CommonAddTreatments/CommonAddTreatmentFrequency';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDrugsAction } from '../../store/drugs/drugsActions'
import { updateTreatmentAction, deleteTreatmentAction, updateDoseAction, deleteDoseAction, addIntakeAction } from '../../store/treatments/treatmentsActions';
import { DateTime } from "luxon";
import {getMedicationsActions, getMedicationsHistoryActions, getEditMedicationsHistoryActions} from "../../store/actions/authenticationActions";
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const containsValidDoses = doses => doses.filter(dose => dose).length > 0;
const normalizeTreatmentType = currentType => ['device', 'diet', 'exercices', 'drug'].includes(currentType) ? currentType : 'custom';

const EditMedication = ({navigation, route}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const GetDrugs = useSelector((state:any) => state.getDrugs.drugs);
  const [warningModal, setWarningModal] = useState<boolean>(false)
  const [step, setStep] = useState<number>(0)
  const [periodAnswers,] = useState()
  const [editData, setEditData] = useState<any>({})
  const [oldWarningData, setOldWarningData] = useState<any>({})
  const data:any = route?.params?.data || {}
  let tempUnitArr:any = []
  let dos = route?.params?.newDose?.intake?.dosage ? route?.params?.newDose?.intake?.dosage : [route?.params?.newDose?.intake?.specific_dosage]
  // return the total dose
  const calculateDose = (u) => {
    let count = 0
    let unit = u
    dos?.forEach(element => {
      if(unit.length > 0 && element.unit === unit){
        count = count + element.dose
        unit = element.unit
      }
    });
    if(count > 0)
      return count + ' ' + unit
    else
      return ''
  }

  // return the total dose of every unit
  const unitFunc = () => {
    if(dos !== null && dos !== undefined){
      return (
        dos?.map(item => {
          const findUnit = tempUnitArr.find(itemUnit => itemUnit === item.unit);
          if(!findUnit && item?.unit)
          {
            tempUnitArr.push(item.unit)
            return(
              calculateDose(item.unit).toString()
            )
          }
        })
      )
    }
  }
  const getFreq = (daysArr) => {
    let tempArr:any = []
    daysArr?.forEach(day => {
      if(day === true)
        tempArr.push(day)
    })
    if(tempArr.length >= 7)
      return 'daily'
    else
      return 'weekly'
  }

  const getOtheFreq = (every) => {
    if(every === 1)
      return 'monthly'
    else if( every === 3)
      return 'quarterly'
    else
      return 'other'
  }

  const getWeekDays = (weekDaysArr) => {
    let week = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    let tempArr:any = []
    weekDaysArr?.forEach((day,i) => {
      if(day === true)
        tempArr.push({day:week[i], pos:i})
    })
    return tempArr
  }

  useEffect(() => {
    if(GetDrugs.length === 0)
      dispatch(getDrugsAction())
  },[])

  useEffect(() => {
    let obj = {}
    if(route?.params?.newDose !== undefined){
      if(route?.params?.newDose?.intake?.dosage){
        obj = {
          dosage: dos,
          typeTreat: route?.params?.newDose?.treatment_type,
          frequency: getFreq(route?.params?.newDose?.intake?.days),
          from: route?.params?.newDose?.intake?.from,
          to: route?.params?.newDose?.intake?.to,
          dose: route?.params?.newDose?.intake && containsValidDoses(dos) ? unitFunc() : '',
          week_frequency: getWeekDays(route?.params?.newDose?.intake?.days)
        }
      }
      else if(route?.params?.newDose?.intake?.specific_dosage){
        obj = {
          MQDose: route?.params?.newDose?.intake?.specific_dosage?.dose.toString(),
          MQUnit: route?.params?.newDose?.intake?.specific_dosage?.unit,
          every: route?.params?.newDose?.intake?.specific_dosage?.every.toString(),
          otherPeriod: route?.params?.newDose?.intake?.specific_dosage?.period,
          frequency: getOtheFreq(route?.params?.newDose?.intake?.specific_dosage?.every),
          typeTreat: route?.params?.newDose?.treatment_type,
          from: route?.params?.newDose?.intake?.from,
          to: route?.params?.newDose?.intake?.to,
          dose: route?.params?.newDose?.intake && containsValidDoses(dos) ? unitFunc() : '',
        }
      }
    }
    if(isEmpty(obj) && route?.params?.newDose)
      setEditData('custom intialize')
    else
      setEditData(obj)
    setOldWarningData(obj)
    
  },[])

  const goBackToMedicationHistory = () =>{
    if(Platform.OS === 'web')
      window.history.back() 
    else
      navigation.goBack()
    setWarningModal(false)
  }

  const callbackVisible = () => {
    setWarningModal(false);
  }

  const confirmModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackVisible
    },
    {
    type: 'primary',
    title: t('translation:modal.btn.confirm'),
    click: goBackToMedicationHistory
    }
  ]

  let range = [
    {name:'add_medication.steps.step_1', stepNum: '1', stepName: t('translation:treatment.add_medication.steps.step_2')}
  ]

  let newDoseRange = [
    {name:'add_medication.steps.step_1', stepNum: '1', stepName: t('translation:treatment.add_medication.steps.step_2')},
    {name:'add_medication.steps.step_2', stepNum: '2', stepName: t('translation:treatment.add_medication.steps.step_3')}
  ]

  const isEmpty = inputObject => {
    return Object.keys(inputObject).length === 0;
  };

  const submitData = (dataToSubmit) => {
    dispatch(updateTreatmentAction({
      t, dataToSubmit,
      callback: Platform.OS === 'web' ? window.history.back() : navigation.goBack()
    }))
  }

  const updateIntake = (dataUpdate) => {
    let start = DateTime.fromFormat(dataUpdate.intake.from, 'dd-MM-yyyy').toLocal().toFormat('yyyy-MM-dd')
    let end = dataUpdate.intake.to ? DateTime.fromFormat(dataUpdate.intake.to, 'dd-MM-yyyy').toLocal().toFormat('yyyy-MM-dd') : null
    dataUpdate.intake.from = start
    dataUpdate.intake.to = end
    const dataToUpdate = dataUpdate?.intake
    dispatch(updateDoseAction({
      t, dataToUpdate,
      callback: () => {
        dispatch(getMedicationsActions());
        dispatch(getMedicationsHistoryActions());
        dispatch(getEditMedicationsHistoryActions(data?.id));
        if (Platform.OS === 'web') window.history.back()
        else navigation.goBack()
      }
    }))
  }

  const submitIntake = (d) => {
    let start = DateTime.fromFormat(d.intake.from, 'dd-MM-yyyy').toLocal().toFormat('yyyy-MM-dd')
    let end = d.intake.to ? DateTime.fromFormat(d.intake.to, 'dd-MM-yyyy').toLocal().toFormat('yyyy-MM-dd') : null
    d.intake.from = start
    d.intake.to = end
    let msg = 'treatment.intakes.success.add'
    dispatch(addIntakeAction({
      t, d, obj:{data:d}, msg,
      callback: () => {
        navigation.navigate('Treatments')
        dispatch(getMedicationsActions());
        dispatch(getMedicationsHistoryActions());
      }
    }))
  }

  const deleteData = (dataToDelete) => {
    dispatch(deleteTreatmentAction({
      t, dataToDelete,
      callback: navigation.navigate('Treatments')
    }))
  }

  const deleteDose = (doseToDelete) => {
    dispatch(deleteDoseAction({
      t, doseToDelete,
      callback: () =>{ 
        navigation.navigate('Treatments');
        dispatch(getMedicationsActions());
        dispatch(getMedicationsHistoryActions());
        dispatch(getEditMedicationsHistoryActions(data?.id));
      }
    }))
  }

  const getdata = (d) => {
    setEditData(d)
    if(d.goback === 'back')
      setStep(1)
    else
      setStep(0)
  }

  const getFreqData = (d) => {
    if(d.step === undefined){
      if(d?.dosage && data?.intake){
        data.intake.dosage = d?.dosage
        data.intake.specific_dosage = null
      }
      else if (d?.dosage && !data?.intake){
        Object.assign(data, {
          intake:{
            dosage: d?.dosage,
            from: null,
            to: null,
            week_frequency: []
          }
        })
      }
      else if(!data?.intake?.specific_dosage && !d?.dosage && !d?.startDate && data?.intake) {
        Object.assign(data.intake, {
          specific_dosage: {
            period: d?.frequency === 'monthly' || d?.frequency === 'quarterly' ? 'month' : d.otherPeriod,
            every: d?.frequency === 'monthly' ? 1 : d?.frequency === 'quarterly' ? 3 : parseInt(d?.every),
            dose: parseInt(d?.MQDose),
            unit: d?.MQUnit
          }
        })
        data.intake.dosage = null
      }
      else if (!data?.intake?.specific_dosage && !d?.dosage && !d?.startDate && !data?.intake) {
        Object.assign(data, {
          intake:{
            specific_dosage: {
              period: d?.frequency === 'monthly' || d?.frequency === 'quarterly' ? 'month' : d.otherPeriod,
              every: d?.frequency === 'monthly' ? 1 : d?.frequency === 'quarterly' ? 3 : parseInt(d?.every),
              dose: parseInt(d?.MQDose),
              unit: d?.MQUnit
            },
            from: null,
            to: null
          }
        })
      }
      else if (data?.intake?.specific_dosage && !d?.dosage && !d?.startDate) {
        data.intake.specific_dosage.period = d?.frequency === 'monthly' || d?.frequency === 'quarterly' ? 'month' : d.otherPeriod
        data.intake.specific_dosage.every = d?.frequency === 'monthly' ? 1 : d?.frequency === 'quarterly' ? 3 : parseInt(d?.every)
        data.intake.specific_dosage.dose = parseInt(d?.MQDose)
        data.intake.specific_dosage.unit = d?.MQUnit
        data.intake.dosage = null
      }
      else if (route?.params?.newDose && d.startDate !== undefined) {
        data.intake.from = d?.startDate
        data.intake.to = d?.endDate
      }
      if(!data?.frequency)
        Object.assign(data, {week_frequency: d?.week_frequency, frequency: d?.frequency})
      if(!route?.params?.newDose && step === 0)
        setEditData({})
      else if(step === 1)
        setEditData({})
      else
        setStep(1)
    }
  }
  
  return(
    <CommonKeyboardAvoidingView>
      { warningModal &&
        <CommonModal testID="warningModalID" type={'info'} title={t('translation:modal.titles.warning')} text={t('translation:treatment.add_medication.confirm.quit_action')} buttons={confirmModalBtn} onClose={callbackVisible}/>
      }
      <CommonHeader 
        testIDTitle="EditMedicationInfoID"
        title={route?.params?.type === 'name' ? t('translation:general.page_title.medication') : route?.params?.newDose ? t('translation:treatment.intakes.add') : t('translation:treatment.intakes.edit')}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={() => {setWarningModal(true)}}
        rightBtnTxtFirst={(!isEmpty(editData) && !route?.params?.newDose) && t('translation:general.header.btn_review')}
        rightClickFirst={() => setEditData({})}
        // scroll={scrollState}
      />
      {route?.params?.type === 'name' ?
        <>
          <CommonAddTreatmentTitleSection step={step} imageName='treatmentsIcon' range={range}/>
          <CommonEditMedicationInfo navigation={navigation} data={data} getData={submitData} deleteData={deleteData}/>
        </>
        :
        <>
          {isEmpty(editData)?
            <CommonEditReview 
              data={data} 
              doRequest={route?.params?.newDose ? submitIntake : updateIntake} 
              back={getdata} 
              deleteData={deleteDose} 
              newDose={route?.params?.newDose ? true : false}
              oldWarningData={oldWarningData}
              medData={route?.params?.med}
            />
            :
            <>
              <CommonAddTreatmentTitleSection step={step} imageName='treatmentsIcon' range={route?.params?.newDose ? newDoseRange : range}/>
              {step === 0 ?
                <CommonAddTreatmentFrequency getAnswers={getFreqData} freqStep={normalizeTreatmentType(data?.treatment_type)} freqData={editData} back={() =>setWarningModal(true)} newDose={route?.params?.newDose ? true : false} editTreat={true}/>
                :
                <CommonAddTreatmentPeriod 
                  getAnswers={getFreqData} 
                  back={() => setStep(0)} 
                  periodData={periodAnswers}
                  newDose={route?.params?.newDose ? true : false}
                  oldWarningData={oldWarningData}
                />
              }
            </>
          }
          
        </>
      }
    </CommonKeyboardAvoidingView>
  )
}

export default EditMedication;