import React, { useEffect, useState } from 'react';
import CommonEducationModule from 'common/src/components/CommonEducationModules/CommonEducationModule';
import { StackNavigationProp } from '@react-navigation/stack';
import { EducationTrack, RootStackParams, EducationModule, ProgramAction } from 'common/src/types';
import { ProgressBarStyle } from './constants';
import { useSelector } from 'react-redux';
import { patientProgramEducationTrack } from '../../store/programs/patientProgramsReducers';

type OwnProps = {
    navigation: StackNavigationProp<RootStackParams, "EducationModule">,
    route: {
        params: {
            educationModule: EducationModule,
            programAction: Array<ProgramAction>
        }
    }
}

const ProgramEducation = (props: OwnProps) => {
    const { route: { params: { educationModule } } } = props;
    const patientEducationTracks = useSelector(patientProgramEducationTrack);
    const [educationTrack, setEducationTrack] = useState<EducationTrack | undefined>();

    useEffect(() => {
        if (patientEducationTracks && educationModule.id in patientEducationTracks) {
            setEducationTrack(patientEducationTracks[educationModule.id]);
        }
    }, [patientEducationTracks]);

    return (
        <CommonEducationModule
            educationModule={props.route.params.educationModule}
            navigation={props.navigation}
            style={ProgressBarStyle}
            patientEducationTrack={educationTrack}
        />
    );
};

export default ProgramEducation;
