import React, {useState, useEffect} from 'react';
import {StyleSheet, ScrollView, View} from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonSubTitle from 'common/src/components/CommonSubtitle';
import InputField from 'common/src/components/InputField';
import CommonFooter from 'common/src/components/CommonFooter';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonModal from 'common/src/components/CommonModal';
import Text from 'common/src/components/CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { useDispatch, useSelector } from 'react-redux';
import { triggersModelCard } from '../../../store/epilepsyDetails/epilepsyDetailsReducers';
import { getTriggersModelCard, addUpdateTriggersModelCard, deleteDescriptionCards } from '../../../store/epilepsyDetails/epilepsyDetailsActions';
import { isEmpty } from 'lodash';
import { RootStackParams } from 'common/src/types';
import { StackNavigationProp } from '@react-navigation/stack';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

type OwnProps = {
    navigation: StackNavigationProp<RootStackParams, "Settings">
}

const MyTriggersModel = ({navigation}:OwnProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [scrollState, setScrollState] = useState();
    const triggersModelCardReducer = useSelector(triggersModelCard);
    const [longTermExternal, setLongTermExternal] = useState<string>('');
    const [longTermInternal, setLongTermInternal] = useState<string>('');
    const [shortTermExternal, setShortTermExternal] = useState<string>('');
    const [shortTermInternal, setShortTermInternal] = useState<string>('');
    const [combinationOfStressfulConditions, setCombinationOfStressfulConditions] = useState<string>('');
    const [seizureInducingReactions, setSeizureInducingReactions] = useState<string>('');
    const [warningModal, setWarningModal] = useState<boolean>(false);

    useEffect(() => {
        if(isEmpty(triggersModelCardReducer))
            dispatch(getTriggersModelCard());
        else{
            setLongTermExternal(triggersModelCardReducer?.longTermExternal || '');
            setLongTermInternal(triggersModelCardReducer?.longTermInternal || '');
            setShortTermExternal(triggersModelCardReducer?.shortTermExternal || '');
            setShortTermInternal(triggersModelCardReducer?.shortTermInternal || '');
            setCombinationOfStressfulConditions(triggersModelCardReducer?.combinationOfStressfulConditions || '');
            setSeizureInducingReactions(triggersModelCardReducer?.seizureInducingReactions || '');
        }
    },[triggersModelCardReducer])


    const saveTriggersModel = () => {
        dispatch(addUpdateTriggersModelCard({
            longTermExternal,
            longTermInternal,
            shortTermExternal,
            shortTermInternal,
            combinationOfStressfulConditions,
            seizureInducingReactions,
            successMsg: t('translation:settings.seizuresDescription.success.message'),
            callback: () => {
                navigation.navigate('Settings')
            }
        }))
    }

    const deleteSeizureAntidote = () => {
        setWarningModal(false);
        dispatch(deleteDescriptionCards({
            cardAction: 'ADD_UPDATE_TRIGGERS_MODEL_CARD_SUCCESS',
            cardName: 'triggersModel',
            cardUrl: 'addupdatetriggersmodel',
            successMsg: t('translation:settings.epilepsy_details.deleted_successfully.message'),
            callback: () => {
                navigation.navigate('Settings');
            }
        }))
    }

    // keep tracking of the changes of the warningVisible
    const callbackWarningVisible = () => {
        setWarningModal(false);
    }

    const warningModalBtn = [
        {
          type: 'tertiary',
          title: t('translation:modal.btn.cancel'),
          click: callbackWarningVisible
        },
        {
          type: 'primary',
          title: t('translation:modal.btn.confirm'),
          click: deleteSeizureAntidote
        }
    ]

    const isDisabled = () => ( 
        longTermExternal.length === 0 && 
        longTermInternal.length === 0 && 
        shortTermExternal.length === 0 && 
        shortTermInternal.length === 0 && 
        combinationOfStressfulConditions.length === 0 && 
        seizureInducingReactions.length === 0
    )

    return(
        <CommonKeyboardAvoidingView>
            { warningModal &&
                <CommonModal 
                    testID="warningModalID" 
                    type={'info'} 
                    title={t('translation:modal.titles.warning')} 
                    text={t('translation:modal.epilepsy_details_delete_text')} 
                    buttons={warningModalBtn} 
                    onClose={callbackWarningVisible}
                />
            }
            <CommonHeader
                title={t('translation:general.page_title.epilepsy_triggers_model')} 
                leftIconName="arrow-left"
                leftIconColor={GlobalStyles.helpi.color}
                leftClick={() => {navigation.goBack()}}
                testIDTitle="epilepsy_triggers_model_title"
                scroll={scrollState}
            />
            <ScrollView 
                testID="triggers_model_scrollView"
                scrollEventThrottle={16}
                onScroll={(event: any) => {
                    setScrollState(event.nativeEvent.contentOffset.y);
                }}
            >
                <View style={{width:'90%', alignSelf:'center'}}>
                    <CommonSubTitle 
                        text={t('translation:general.page_subtitle.epilepsy_triggers_model')}
                        txtAlign='center'
                    />
                </View>
                <View testID='indroduction-section' style={{marginTop: PR(23), width: '90%', alignSelf:'center'}}>
                    <Text style={styles.label}>{t('translation:general.triggers_model_introduction_title')}</Text>
                    <Text style={styles.introductionTxt}>{t('translation:general.triggers_model_introduction_text')}</Text>
                </View>
                <InputField 
                    labelText={t('translation:general.long_term_external_label')}
                    labelDescription={t('translation:general.long_term_external_label_description')}
                    inputType="string" 
                    customStyle={{marginTop:PR(20), marginBottom:PR(30)}}
                    changeText={setLongTermExternal}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="long_term_external"
                    multiline={true}
                    value={longTermExternal}
                />
                <InputField 
                    labelText={t('translation:general.long_term_internal_label')}
                    labelDescription={t('translation:general.long_term_internal_label_description')}
                    inputType="string" 
                    customStyle={{marginBottom:PR(30)}}
                    changeText={setLongTermInternal}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="long_term_internal"
                    multiline={true}
                    value={longTermInternal}
                />
                <InputField 
                    labelText={t('translation:general.short_term_external_label')}
                    labelDescription={t('translation:general.short_term_external_label_description')}
                    inputType="string" 
                    customStyle={{marginBottom:PR(30)}}
                    changeText={setShortTermExternal}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="short_term_external"
                    multiline={true}
                    value={shortTermExternal}
                />
                <InputField 
                    labelText={t('translation:general.short_term_internal_label')}
                    labelDescription={t('translation:general.short_term_internal_label_description')}
                    inputType="string" 
                    customStyle={{marginBottom:PR(30)}}
                    changeText={setShortTermInternal}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="short_term_internal"
                    multiline={true}
                    value={shortTermInternal}
                />
                <InputField 
                    labelText={t('translation:general.combination_of_stressful_conditions_label')}
                    labelDescription={t('translation:general.combination_of_stressful_conditions_label_description')}
                    inputType="string" 
                    customStyle={{marginBottom:PR(30)}}
                    changeText={setCombinationOfStressfulConditions}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="combination_of_stressful_conditions"
                    multiline={true}
                    value={combinationOfStressfulConditions}
                />
                <InputField 
                    labelText={t('translation:general.seizure_inducing_reactions_label')}
                    labelDescription={t('translation:general.seizure_inducing_reactions_label_description')}
                    inputType="string" 
                    customStyle={{marginBottom:PR(30)}}
                    changeText={setSeizureInducingReactions}
                    placeholderTxt={t('translation:general.seizure_description_placeholder')} 
                    testID="seizure_inducing_reactions"
                    multiline={true}
                    value={seizureInducingReactions}
                />
                <CommonFooter>
                    {triggersModelCardReducer !== null &&
                        <>
                            <CommonBtn type="tertiary" txtColor="red" title={t("translation:settings.epilepsy_details.delete_description.btn")} testID="delete_triggers_model" click={()=> setWarningModal(true)} /> 
                            <View style={{marginBottom: PR(5)}}></View>
                        </>
                    }
                    <CommonBtn type="primary" click={() => saveTriggersModel()} title={t('translation:general.header.btn_save')} testID="save_triggers_model" disable={isDisabled()}/>
                </CommonFooter>
            </ScrollView>
        </CommonKeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    label: { 
        marginBottom: PR(10),
        marginLeft: PR(20),
        color: GlobalStyles.global.black,
        fontSize: PR(12)
    },
    introductionTxt: {
        color: GlobalStyles.global.black,
        fontSize: PR(12),
        lineHeight: PR(18),
        fontFamily: GlobalStyles.global.fontFamily.Regular,
        opacity: 0.8
    }
});

export default MyTriggersModel;
