import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import Text from 'common/src/components/CommonCustomTxt';
import {VideoTypes} from '../components/interfaces';
import { translate } from 'common/src/services/translationList';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const CommonSettingsBtn = ({listNames, clicked}:VideoTypes) => {
  const {t} = useTranslation();
  const clickedBtn = (allData:any) => {
    clicked(allData)
  }
  return (
    <>
      {listNames.map((item, key) => {
        return (
          <TouchableOpacity testID={item.id} key={key} style={styles.item} onPress={() => {
            // TODO redirect to account page
            clickedBtn(item.data)
          }}>
            <View style={{flexDirection:'column', width:'95%'}}>
              <Text style={styles.itemText}>
                {item.date}
              </Text>
              <Text style={styles.itemText}>
                {translate(t, item.title, 'list.seizureList')}
              </Text>
            </View>
            <Icon name={"chevron-right"} size={PR(25)} color={GlobalStyles.global.black} style={{opacity:0.5, width:'5%'}} />
          </TouchableOpacity>
        )
      })}
    </>
  );
}

const styles = StyleSheet.create({
  item: {
    paddingVertical: PR(10),
    width: '90%',
    alignSelf: 'center',
    alignItems: 'center',
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  itemText: {
    fontSize: PR(18),
    letterSpacing: 0,
  },
});

export default CommonSettingsBtn;