import React from 'react'
import { useSelector } from 'react-redux';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Home from './Home';
import Diary from './Diary';
import Treatments from './Treatments';
import Programs from './Programs/Programs';
import Studies from './Studies';
import Settings from './Settings';
import CommonTabNavigation from 'common/src/components/CommonTabNavigation';
import CommonImage from 'common/src/components/CommonImage';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const Tab = createBottomTabNavigator();

const TabNavigation = ({route}) => {
    const {t} = useTranslation();
    const deviceType:string | undefined = route?.params?.deviceType;
    const FBToken:string | undefined = route?.params?.FBToken
    const Me = useSelector((state: any) => state.getMe.response?.result)
    
    if (!Me)
        return (<></>)
    return (
        <CommonTabNavigation>
            <Tab.Screen
                name="Home" 
                component={Home}
                options={{
                    tabBarLabel:t('translation:general.page_title.dashboard'),
                    tabBarTestID:"home_tab",
                    tabBarIcon: ({ focused }) => (
                        <CommonImage
                            name={focused? 'homeIconFilled': 'homeIconLine'}
                            height={PR(23)}
                            width={PR(23)}
                        />
                    )
                }}
            />
            <Tab.Screen
                name="Diary"  
                component={Diary}
                options={{
                    tabBarLabel:t('translation:general.page_title.journal'),
                    tabBarTestID:"diary_tab",
                    tabBarIcon: ({ focused }) => (
                        <CommonImage
                            name={focused? 'diaryIconFilled': 'diaryIconLine'}
                            height={PR(23)}
                            width={PR(23)}
                        />
                    )
                }}
            />
            <Tab.Screen 
                name="Treatments" 
                component={Treatments} 
                options={{
                    tabBarLabel:t('translation:general.page_title.treatment'),
                    tabBarTestID:"treatments_tab",
                    tabBarIcon: ({ focused }) => (
                        <CommonImage
                            name={focused? 'treatmentIconFilled': 'treatmentIconLine'}
                            height={PR(23)}
                            width={PR(23)}
                        />
                    )
                }}
            />
            { Me.hasProgram &&
                <Tab.Screen 
                    name="Programs" 
                    component={Programs} 
                    options={{
                        tabBarLabel:t('translation:general.page_title.programs'),
                        tabBarTestID:"programs_tab",
                        tabBarIcon: ({ focused }) => (
                            <CommonImage
                                name={focused? 'programsIconFilled': 'programsIconLine'}
                                height={PR(23)}
                                width={PR(23)}
                            />
                        )
                    }}
                />
            }
            {
                Me.hasStudy &&
                <Tab.Screen 
                    name="Studies"
                    component={Studies} 
                    options={{
                        tabBarLabel:t('translation:general.page_title.studies'),
                        tabBarTestID:"studies_tab",
                        tabBarIcon: ({ focused }) => (
                            <CommonImage
                                name={focused? 'studyIconFilled': 'studyIconLine'}
                                height={PR(23)}
                                width={PR(23)}
                            />
                        )
                    }}
                />
            }
            <Tab.Screen 
                name="Settings"
                component={Settings} 
                initialParams={{deviceType, FBToken}}
                options={{
                    tabBarLabel:t('translation:general.page_title.settings'),
                    tabBarTestID:"settings_tab",
                    tabBarIcon: ({ focused }) => (
                        <CommonImage
                            name={focused? 'settingsIconFilled': 'settingsIconLine'}
                            height={PR(23)}
                            width={PR(23)}
                        />
                    )
                }}
            />
        </CommonTabNavigation>
    )
}

export default TabNavigation;