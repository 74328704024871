import { combineReducers } from 'redux';
import register from './registerReducer';
import getMe from './getMeReducer';
import getEvents from './getEventsReducer';
import getNotifications from './getNotificationsReducer';
import neurologists from './neurologistsReducer';
import loading from './loadingReducer'
import getSeizureVideos from './seizureVideosReducer';
import modal from './modalReducer';
import getMedications from './getMedicationsReducer';
import getDrugs from './getDrugsReducer';
import getListOfLang from './getListOfLangReducer';
import allSetReducer from './allSetReducer';
import programsReducers from '../programs/programsReducers';
import getReports from './getReportsReducer';
import patientProgramsReducers from '../programs/patientProgramsReducers';
import epilepsyDetailsReducers from '../epilepsyDetails/epilepsyDetailsReducers';
import timezoneBannerReducer from '../timezoneBanner/timezoneBannerReducer';
import biometricKeyExistsReducer from '../biometrics/biometricsReducer';
import nightwatchReducer from '../nightwatch/nightwatchReducer';
import profileDetailsReducer from '../profileDetails/profileDetailsReducer';

const appReducer = combineReducers({
    register, 
    getMe, 
    getEvents, 
    getNotifications, 
    neurologists, 
    loading, 
    getSeizureVideos, 
    modal, 
    getMedications,
    getDrugs,
    getListOfLang,
    allSetReducer,
    programsReducers,
    getReports,
    patientProgramsReducers,
    epilepsyDetailsReducers,
    timezoneBannerReducer,
    biometricKeyExistsReducer,
    nightwatchReducer,
    profileDetailsReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_USER') {
      state = undefined
    }
    return appReducer(state, action)
}
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
 