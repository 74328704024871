import React, {useState} from 'react';
import {StyleSheet, ScrollView, View, Platform} from 'react-native';
import { useDispatch } from 'react-redux';
import { registerUserAction } from '../store/actions/authenticationActions';
import CommonBtn from 'common/src/components/CommonBtn';
import InputField from 'common/src/components/InputField';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonAboutFooter from 'common/src/components/CommonAboutFooter';
import CommonPwdCriteria from 'common/src/components/CommonPwdCriteria';
import {isSamePassword} from 'common/src/services/utils';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {useTranslation} from 'react-i18next';
import { version } from '../../package.json';
import { getTimeZones } from "@vvo/tzdb";
import moment from 'moment';
import 'moment-timezone';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { biometricKeyExistsAction } from '../store/biometrics/biometricsActions';
import { removeBiometricKeysOnFirstLaunch } from 'common/src/services/biometricServices';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';
import { checkEmailExistanceAction } from "../store/emailExistanceOnRegistration/emailExistanceAction";

const Register = ({ navigation }: any) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [confirmPdw, setConfirmPassword] = useState<string>("");
	const [emailValidated, setEmailValidated] = useState(false);
	const [scrollState, setScrollState] = useState();
	const [criteria, setCriteria] = useState<boolean>(false);

	// keep tracking of the changes of the email input
	const callbackEmail = (text: string) => {
		setEmail(text);
	};
	// keep tracking of the changes of the password input
	const callbackPassword = (text: string) => {
		setPassword(text);
	};

	const checkEmailValidation = (validated: boolean) => {
		setEmailValidated(validated);
	};

	const getTimezone = () => {
		const timezones = getTimeZones();
		const guessedTz = moment.tz.guess(true);
		let foundTz = timezones.find(
			(tz) => tz.name === guessedTz || tz.group.includes(guessedTz)
		);
		if (foundTz) {
			return foundTz.name;
		} else {
			return "Europe/Brussels";
		}
	};
	// post request in order to register to the app
	const signUp = async () => {
		if (Platform.OS !== "web")
			removeBiometricKeysOnFirstLaunch({
				callback: dispatch(biometricKeyExistsAction({ key: false })),
			}); // remove biometrics keys form keychain before registration
		const { language } = i18n;
		const timezone = getTimezone();
		let obj = {
			email: email,
			password: password,
			first_name: firstName,
			last_name: lastName,
			add_me_as_patient: true,
			navigation,
			settings: {
				language,
				timezone,
				app_version: version,
			},
		};
		const setRegister = () => registerUserAction(obj);
		dispatch(
			checkEmailExistanceAction({
				email: email,
				language: language,
				callback: () => {
					dispatch(setRegister());
				},
			})
		);
	};
	// return a boolean value in order to turn the button to disable or enable
	const checkDisable = () =>
		!email.length ||
		!password.length ||
		!firstName.length ||
		!lastName.length ||
		!emailValidated ||
		!criteria ||
		!isSamePassword(password, confirmPdw);

	return (
		<CommonKeyboardAvoidingView>
			<CommonHeader
				title={t("translation:login.title_register")}
				rightIconNameSecond="settings"
				rightIconColorSecond={GlobalStyles.helpi.color}
				rightClickSecond={() => navigation.navigate("Settings")}
				scroll={scrollState}
				testIDTitle="registration"
				disableFormsBanner={true}
			/>
			<View style={styles.scrollViewWrapper}>
				<ScrollView
					testID="register_scroll"
					style={styles.scrollview}
					scrollEventThrottle={16}
					onScroll={(event: any) => {
						setScrollState(event.nativeEvent.contentOffset.y);
					}}
				>
					<InputField
						labelText={t(
							"translation:register.form.inp_first_name"
						)}
						labelTextSize={PR(14)}
						inputType="string"
						customStyle={{ marginBottom: PR(30) }}
						changeText={setFirstName}
						placeholderTxt={t(
							"translation:register.form.firstname_placeholder"
						)}
						value={firstName}
						testID="first_name"
					/>
					<InputField
						labelText={t("translation:register.form.inp_last_name")}
						labelTextSize={PR(14)}
						inputType="string"
						customStyle={{ marginBottom: PR(30) }}
						changeText={setLastName}
						placeholderTxt={t(
							"translation:register.form.lastname_placeholder"
						)}
						value={lastName}
						testID="last_name"
					/>
					<InputField
						labelText={t("translation:register.form.inp_email")}
						labelTextSize={PR(14)}
						inputType="email"
						customStyle={{ marginBottom: PR(30) }}
						changeText={callbackEmail}
						placeholderTxt={t(
							"translation:register.form.email_placeholder"
						)}
						value={email}
						testID="email"
						isValidationRequired={true}
						changeValidation={checkEmailValidation}
					/>
					<InputField
						labelText={t("translation:register.form.inp_password")}
						labelTextSize={PR(14)}
						inputType="password"
						customStyle={{ marginBottom: PR(30) }}
						changeText={callbackPassword}
						placeholderTxt={t(
							"translation:register.form.password_placeholder"
						)}
						value={password}
						validated={criteria}
						testID="password"
					/>
					<CommonPwdCriteria
						password={password || ""}
						checkCriteria={setCriteria}
					/>
					<InputField
						labelText={t(
							"translation:profile.change_password.confirm"
						)}
						inputType="password"
						customStyle={{ marginBottom: PR(30) }}
						changeText={setConfirmPassword}
						placeholderTxt={t(
							"translation:profile.change_password.confirm_placeholder"
						)}
						testID="confirm_password"
						value={confirmPdw}
						validated={isSamePassword(password, confirmPdw)}
						error={!isSamePassword(password, confirmPdw)}
						errorText={t(
							"translation:server.errors.passwords_error"
						)}
					/>
					<View style={{ marginBottom: PR(5) }}>
						<CommonBtn
							type="tertiary"
							click={() => navigation.navigate("Login")}
							title={t("translation:login.have_account")}
							testID="login_btn"
						/>
					</View>
					<CommonBtn
						type="primary"
						click={signUp}
						title={t("translation:login.form.btn_register")}
						disable={checkDisable()}
						testID="register"
					/>
					<CommonAboutFooter />
				</ScrollView>
			</View>
		</CommonKeyboardAvoidingView>
	);
};

const styles = StyleSheet.create({
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
  },
  registerHeader: {
    fontSize: PR(20),
    color: GlobalStyles.migraine.color,
    fontFamily: GlobalStyles.global.fontFamily.Bold,
    marginBottom: PR(40),
    textAlign: 'center'
  },
  nfo: {
    fontSize: PR(14),
    lineHeight: PR(20),
    color: GlobalStyles.global.grey,
    alignSelf: 'center',
    marginTop: PR(10),
    marginBottom: PR(10)
  }
});

export default Register;