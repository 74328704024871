import React, {useState, useEffect} from 'react';
import { SafeAreaView, StyleSheet, View, TouchableOpacity, ScrollView, ActivityIndicator } from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import { useTranslation } from 'react-i18next';
import Text from 'common/src/components/CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import CommonProgramActionsList from 'common/src/components/CommonProgramActions/CommonProgramActionsList';
import ImpactTab from './ImpactTab';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import { getEducationModules, getEducationModuleWithAllData, startProgramAction, addProgramAction } from '../../store/programs/programsActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import {dateTimeEvents} from 'common/src/services/utils';
import { isArray } from 'lodash';
import { setActivePatientProgram, getPatientProgramActionEvents, getPatientProgramActionSchedule } from '../../store/programs/patientProgramsActions';
import { ProgressBarStyle } from './constants';
import { activePatientProgram, patientProgramEducationTrack } from '../../store/programs/patientProgramsReducers';
import { PatientEducationTrack, ProgramAction, Program, EducationModule } from 'common/src/types';
import CommonEducationListByFeatured from 'common/src/components/CommonEducationModules/CommonEducationListByFeatured';

const ProgramOverview = ({navigation, route}) => {
    const { program, isTabScreen, selectedTab } = route.params;
    const {t, i18n} = useTranslation();
    const {language: userLanguage} = i18n;
    const dispatch = useDispatch();
    const educationModules = useSelector((state: RootState) => state.programsReducers.educationModules)
    const programs = useSelector((state: RootState) => state.programsReducers.allPrograms)
    const [selectedDisplayType, setSelectedDisplayType] = useState<string>(selectedTab || 'education');
    const [selectedProgram, setSelectedProgram] = useState<Program>(route.params.program);
    selectedProgram.goal = selectedProgram?.goal || {en:t('translation:general.empty_subtitle')}
    const patientEducationTracks = useSelector(patientProgramEducationTrack);
    const [educationTracks, setEducationTracks] = useState<PatientEducationTrack | undefined>();
    const patientProgramActionEvents = useSelector((state: RootState) => state.patientProgramsReducers.patientProgramActionEvents)
    const patientProgram = useSelector(activePatientProgram);

    useEffect(() => {
        if (selectedTab && selectedTab !== selectedDisplayType) {
            setSelectedDisplayType(selectedTab)
        }
    }, [selectedTab])

    const loadEducationChapters = (educationIds: string[]) => {
        const ids =
          isArray(educationIds) && educationIds.length > 0
            ? educationIds
            : undefined;
        dispatch(getEducationModules({ ids }));
    };

    useEffect(() => { // checking for the subProgram that has be picked in the form step
        let tempSelectedProgram;
        programs && programs.forEach(p => {
            if(route.params.program?.subProgram && p?.id === route.params.program?.subProgram)
                tempSelectedProgram = p
        })
        if(tempSelectedProgram) {
            setSelectedProgram(tempSelectedProgram);
            // Load subprogram education modules
            if (tempSelectedProgram.educationModules) {
                const ids = tempSelectedProgram.educationModules.map(({id}) => id);
                loadEducationChapters(ids);
            }
        } else {
            // Load program education modules.
            if (program.educationModules) {
                const ids = program.educationModules.map(({id}) => id);
                loadEducationChapters(ids);
            }
        }
        if (program.patientProgramId) {
            dispatch(setActivePatientProgram({ id: program.patientProgramId }));
            dispatch(getPatientProgramActionEvents({patientProgramId: program.patientProgramId}))
        }
    },[]);

    useEffect(() => {
        if (patientEducationTracks && Array.isArray(selectedProgram.educationModules)) {
            const tracks = {};
            const programEducationIds = selectedProgram.educationModules.map(({id}) => id);
            for (const [id, track] of Object.entries(patientEducationTracks)) {
                if (programEducationIds.includes(id)) {
                    tracks[id] = track;
                }
            }
            setEducationTracks(tracks);
        }
    }, [patientEducationTracks, selectedProgram]);

    const updateSelected = (type) => {
        route.params.selectedTab = type
        setSelectedDisplayType(type)
    }

    const handleActionSchedule = (action: ProgramAction, actionId:string, date?:string, time?:string, old_date?:string) => {
        let dateTime:string | undefined;
        if(date && time)
            dateTime = dateTimeEvents(date, time)

        dispatch(startProgramAction({
            actionId,
            scheduledDate: dateTime,
            old_date
        }))
    }

    const removeAction = (action) => {
        dispatch(addProgramAction({actionId: action.id, action: action, patient_program_id: program.patientProgramId}))
    }

    const onActionClick = (action) => {
        dispatch(getPatientProgramActionSchedule({programActionId: action.id}))
        navigation.navigate('ProgramAction', { programAction: action, handleActionSchedule, removeAction });
    }

    const getAllDataOfEducationModule = (eId:string) => {
        dispatch(getEducationModuleWithAllData(
            { 
                ids:[eId],
                callback: (education: EducationModule) => {
                    if (
                            Array.isArray(education[0].chapters) &&
                            education[0].chapters.length === 1
                        ) {
                            const chapter = education[0].chapters[0];
                            navigation.navigate("EducationChapter", {
                                chapter,
                                educationModuleId: education[0].id,
                            });
                        } else {
                            navigation.navigate("EducationModule", {
                                educationModule: education[0],
                            });
                        }
                }
            }
        ));
    }

    return(
        <SafeAreaView style={styles.container}>
            {
                isTabScreen ? 
                    <CommonHeader 
                        title={selectedProgram.title[userLanguage] || selectedProgram.title.en} 
                        testIDTitle="Program_overview"
                    />
                :
                    <CommonHeader
                        title={selectedProgram.title[userLanguage] || selectedProgram.title.en} 
                        testIDTitle="Program_overview"
                        leftIconName="arrow-left"
                        leftIconColor={GlobalStyles.helpi.color}
                        leftClick={() => {
                            navigation.navigate('Main', {screen: 'Programs'});
                        }}
                    />
            }
            <View style={styles.subtitleContainer}>
                <View style={styles.subtitleWrapper}>
                    <Text testID='goal_id' style={styles.goalTxt}>{t('translation:general.programs.goal')}</Text>
                    <View style={{marginBottom:PR(6)}}></View>
                    <Text testID='program_goal' style={styles.subtitleTxt}>{selectedProgram.goal[userLanguage] || selectedProgram.goal.en}</Text>

                </View>
            </View>
            <View testID='program_overview_tabBar' style={styles.selectContainer}>
                <TouchableOpacity testID='education_btn' style={[styles.selectBox, 'education' === selectedDisplayType.toLowerCase() ? {} : {backgroundColor: 'transparent'}]} onPress={() => {updateSelected('education')}}>
                    <Text style={[styles.selectBoxText, 'education' === selectedDisplayType.toLowerCase() ? {color: GlobalStyles.helpi.color} : {color: GlobalStyles.helpi.inactiveColor}]}>{t('translation:general.programs.education')}</Text>
                </TouchableOpacity> 
                <TouchableOpacity testID='actions_btn' style={[styles.selectBox, 'actions' === selectedDisplayType.toLowerCase() ? {} : {backgroundColor: 'transparent'}]} onPress={() => {updateSelected('actions')}}>
                    <Text style={[styles.selectBoxText, 'actions' === selectedDisplayType.toLowerCase() ? {color: GlobalStyles.helpi.color} : {color: GlobalStyles.helpi.inactiveColor}]}>{t('translation:general.programs.actions')}</Text>
                </TouchableOpacity> 
                <TouchableOpacity testID='impact_btn' style={[styles.selectBox, 'impact' === selectedDisplayType.toLowerCase() ? {} : {backgroundColor: 'transparent'}]} onPress={() => {updateSelected('impact')}}>
                    <Text style={[styles.selectBoxText, 'impact' === selectedDisplayType.toLowerCase() ? {color: GlobalStyles.helpi.color} : {color: GlobalStyles.helpi.inactiveColor}]}>{t('translation:general.programs.impact')}</Text>
                </TouchableOpacity> 
            </View>
            <ScrollView 
                style={{flex: 1, paddingBottom:PR(50)}}
            >
                {selectedDisplayType === 'education' &&
                    <>
                    { !educationModules && (
                        <ActivityIndicator size="small" color={GlobalStyles.helpi.color} />
                    )}
                    { patientProgram && educationModules && educationModules.length > 0 && (
                        <CommonEducationListByFeatured
                            educationModules={educationModules}
                            programEducationModules={selectedProgram.educationModules}
                            patientStartDate={patientProgram.startedDate || patientProgram.createdAt}
                            progressBarStyle={ProgressBarStyle}
                            educationTracks={educationTracks}
                            onClick={getAllDataOfEducationModule}
                        />
                    ) }
                    </>
                }
                {selectedDisplayType === 'actions' &&
                    <>
                        { patientProgramActionEvents && patientProgramActionEvents.length > 0 && patientProgramActionEvents.map((action, index) => (
                                <View testID={'program_action_'+index} key={index} style={{width:'90%', alignSelf:'center'}}>
                                    <CommonProgramActionsList
                                        programAction={action}
                                        handleClick={() => onActionClick(action)}
                                    />
                                </View>
                            ))
                        }
                        {(!patientProgramActionEvents || patientProgramActionEvents.length === 0) && 
                            <Text style={{alignSelf:'center'}}>
                                {t('translation:general.programs.empty_actions')}
                            </Text>
                        }
                    </>
                }
                {selectedDisplayType === 'impact' &&
                    <ImpactTab />
                }
            </ScrollView>
                {selectedDisplayType === 'impact' &&
                    <View style={styles.disclaimerContainer}>
                        <View style={{width:'10%', alignItems:'center'}}>
                            <Icon 
                                name={'info'} 
                                size={PR(25)} 
                                color={GlobalStyles.global.grey} 
                            />
                        </View>
                        <View style={{width:'90%', alignItems:'center'}}>
                            <Text style={styles.disclaimerTxt}>{t('translation:programs.impact.disclaimer')}</Text>
                        </View>
                    </View>
                }
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: GlobalStyles.global.white
    },
    subtitleContainer: {
        borderRadius: PR(20),
        width:'90%',
        alignSelf:'center', 
        marginBottom:PR(20),
        backgroundColor: GlobalStyles.global.footerGrey
    },
    subtitleWrapper: {
        flexDirection: 'column',
        minHeight: PR(62),
        padding: PR(10),
        paddingLeft:PR(20),
        width: '90%',
    },
    subtitleTxt: {
        color: GlobalStyles.global.black,
        fontSize: PR(16),
        fontFamily: GlobalStyles.global.fontFamily.Bold
    },
    goalTxt: {
        fontSize: PR(12),
        fontFamily: GlobalStyles.global.fontFamily.Regular,
        color: GlobalStyles.global.black
    },
    selectContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: PR(16),
        backgroundColor: GlobalStyles.helpi.ultraLightColor,
        borderRadius: PR(25),
        alignItems: 'center',
        alignSelf: 'center',
        width: '90%'
    },
    timelineContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: PR(10)
    },
    selectBox: {
        padding: PR(12),
        backgroundColor: GlobalStyles.global.white,
        borderRadius: PR(25),
        margin: PR(3),
        alignItems: 'center',
        flex: 1,
        flexGrow: 0.33
    },
    selectBoxText: {
        fontFamily: GlobalStyles.global.fontFamily.Bold
    },
    disclaimerContainer: {
        flexDirection: 'row',
        width: '90%',
        alignItems: 'center',
        justifyContent:'space-between',
        alignSelf:'center',
        marginTop:PR(10),
        marginBottom: PR(10)
    },
    disclaimerTxt: {
        fontSize: PR(10),
        fontFamily: GlobalStyles.global.fontFamily.Regular,
        color: GlobalStyles.global.black,
        opacity: 0.8,
        textAlign:'left'
    },
    skeletonContainer: {
        borderRadius: PR(20),
        width: "90%",
        alignSelf: "center",
        marginBottom: PR(8),
        backgroundColor: GlobalStyles.global.grey,
        shadowColor: "#333333",
        shadowOffset: {
          width: PR(2),
          height: PR(10),
        },
        shadowOpacity: 0.1,
        shadowRadius: 13.97,
        elevation: 10,
      },
      skeletonWrapper: {
        flexDirection: "row",
        minHeight: PR(100),
        padding: PR(10),
        paddingLeft: PR(20),
        width: "100%",
        alignItems: "center",
      },
})

export default ProgramOverview;