import React, {useState, useEffect} from 'react';
import { View, StyleSheet, Platform, TouchableOpacity, ScrollView } from 'react-native';
import Text from 'common/src/components/CommonCustomTxt';
import InputField from '../../../../common/src/components/InputField';
import CommonHeader from 'common/src/components/CommonHeader';
import GlobalStyles from '../../../../common/src/styles/GlobalStyles';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const Drugs = ({navigation, route}) => {
  const {t} = useTranslation();
  const GetDrugs = useSelector((state:any) => state.getDrugs.drugs);
  const [search, setSearch] = useState<string>('')
  const [list, setList] = useState<Array<string>>([])
  const [maxItems, setMaxItems] = useState<number>(10)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [showMore, setShowMore] = useState<boolean>(false)

  useEffect(() => {
    searchFunc(route.params?.treatName || '')
  },[])

  const searchFunc = (keyword) => {
    setSearch(keyword)
    let arr:Array<string> = []
    GetDrugs?.forEach(item => {
      if(item.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1){
        arr.push(item.name)
        setNotFound(false)
        setShowMore(true)
      }
    })
    if(keyword.length === 0) {
      arr = []
      setMaxItems(10)
      setNotFound(false)
      setShowMore(false)
    }
    else if(arr.length === 0 && search.length > 0){
      setShowMore(false)
      setNotFound(true)
    }
    setList(arr)
  }

  const selectDrug = (selectedDrug) => {
    setSearch(selectedDrug)
    goBack(selectedDrug)
  }

  const goBack = (selectedDrug) => {
    route.params.onReturn(selectedDrug)
    if(Platform.OS === 'web') {
      window.history.back()
    } else
        navigation.goBack()
  }
  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader 
        testIDTitle="treatment_name_ID"
        title={t('translation:treatment.drugs_list.header')}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
        // scroll={scrollState}
      />
      <View style={{height:PR(100)}}>
      <InputField 
        labelText={t('translation:treatment.drugs_list.placeholder_search')}
        inputType="string" 
        changeText={searchFunc}
        placeholderTxt={t('translation:treatment.drugs_list.placeholder_search')} 
        testID="search_ID"
        value={search}
      />
      </View>
      <ScrollView
        testID="drug_scroll_ID"
      >
        <View style={{width:'90%', alignSelf:'center'}}>
          {list.length > 0 && list.slice(0,maxItems).map((item, index) => {
            return(
              <TouchableOpacity key={index} style={styles.drugItem} onPress={() => {selectDrug(item)}}>
                <Text>{item}</Text>
              </TouchableOpacity>
            )
          })}
          { showMore &&
           <TouchableOpacity style={{alignSelf:'center', marginBottom: PR(30)}} onPress={() => {setMaxItems(maxItems+10)}}>
              <Text style={{color: GlobalStyles.helpi.btnColor}}>{t('translation:treatment.moreDrugs')}</Text>
            </TouchableOpacity>
          }
        </View>
      </ScrollView>
      {notFound && 
        <TouchableOpacity style={styles.notFoundContainer} onPress={() => {goBack(search)}}>
          <Text style={{color: GlobalStyles.helpi.btnColor, textAlign:'center'}}>{t('translation:treatment.drugs_list.not_found')}</Text>
        </TouchableOpacity>
      }
    </CommonKeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  drugItem: {
    justifyContent:'space-between', 
    paddingVertical:PR(10),
    borderBottomWidth: PR(1),
    borderBottomColor: GlobalStyles.global.lightGrey
  },
  notFoundContainer: {
    alignSelf: 'center',
    marginBottom: PR(30),
    width: '90%'
  }
})

export default Drugs;