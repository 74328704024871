import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CommonImpactMoodSleepAdhBars from './CommonImpactMoodSleepAdhBars';
import { useTranslation } from 'react-i18next';
import {StyleSheet, View, TouchableOpacity} from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import Text from 'common/src/components/CommonCustomTxt';
import {APP_SHORTCUT_NAME} from '../../../env.json';
import {convertLangCode} from 'common/src/services/translationList';
import moment from 'moment';
import {widthPercentageToDP as PR} from '../../../styles/PixelRatio';
import Loading from 'helpilepsy/src/modules/Loading';
import { getImpactEvents } from 'helpilepsy/src/store/events/eventActions';

type OwnProps = {
    Events: Record<string,any>[],
    eventType: string,
}

const CommonImpactMoodSleepAdhGraph = ({Events, eventType}:OwnProps) => {
    const {i18n, t} = useTranslation();
    const lang = convertLangCode(i18n.language);
    const dispatch = useDispatch();
    const [weekArray, setWeekArray] = useState<any[]>([]);
    const [currentWeek, setCurrentWeek] = useState(moment().week());
    const firstWeek = currentWeek - 5;
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let newArray: any = [];
        for(let i=0; i <= 5; i++){
            newArray.push({
                date: moment().week(firstWeek+i).startOf('isoWeek').format(),
                moodSleep: [],
                reminders: []
            })
        }
        setWeekArray(newArray)
        groupTheEvents(newArray)
    }, [currentWeek,Events])

    const groupTheEvents = (tempArray) => {
        let tempWeekArray:any = [...tempArray]
        if(Events.length > 0){
            Events.forEach(mNs => {
                let weekIndex = tempWeekArray.findIndex(item => moment(item.date).startOf('isoWeek') <= moment(mNs.date) && moment(item.date).endOf('isoWeek') >= moment(mNs.date))
                if(weekIndex > -1 && eventType !== 'reminder' && mNs.value !== null)
                    tempWeekArray[weekIndex].moodSleep.push(mNs.value)
                else if(weekIndex > -1 && eventType == 'reminder')
                    tempWeekArray[weekIndex].reminders.push(mNs)
            })
            setWeekArray(tempWeekArray)
        }
    }

    const loadPrev = () => {
        const from = moment().week(firstWeek-1).startOf('isoWeek').format();
        const to = moment().week(firstWeek-1).endOf('isoWeek').format()
        setIsLoading(true)
        const obj = {
            date: {
                from,
                to
            },
            event_type: eventType
        }
        dispatch(getImpactEvents({obj, callback: () => {
            setIsLoading(false)
        }}));
        setCurrentWeek(currentWeek-1)
    }

    const loadNext = () => {
        setCurrentWeek(currentWeek+1)
    }


    return(
        <>
            <View style={{alignItems:'center', marginBottom:PR(15)}}>
                <Text testID="impact_mood_sleep_adh_title" style={{textAlign: 'center'}}>{t(`translation:programs.impact.${eventType}.graph.title`)}</Text>
                {eventType === 'reminder' ? 
                    <Text testID="impact_adherence_subtitle" style={styles.subtitle}>{t('translation:programs.impact.reminder.graph.subtitle')}</Text>
                    :
                    <Text testID="impact_mood_sleep_subtitle" style={styles.subtitle}>{t('translation:programs.impact.not_exist_text', {itemType: eventType})}</Text>
                }
            </View>
            <View style={{flexDirection:'column', width: eventType === 'reminder' ? '75%' : '85%', alignItems:'center'}}>
                <View style={styles.moodSleepAdhImpactContainer}>
                    <TouchableOpacity testID="impact_mood_sleep_adh_arrow_left" onPress={loadPrev} style={{position:'absolute', top:PR(40), left:eventType === 'reminder' ? PR(-65) : PR(-50)}}>
                        <Icon 
                            name={'chevron-left'} 
                            size={PR(25)} 
                            color={GlobalStyles[APP_SHORTCUT_NAME].btnColor} 
                        />
                    </TouchableOpacity>
                    {
                        isLoading && 
                        <Loading netConnected={true}/>
                    }
                    { !isLoading && 
                        <>
                            {eventType === 'reminder' &&
                                <>
                                    <View style={{position:'absolute', top: PR(2), left:PR(-44)}}>
                                        <Text style={styles.percentageLabels}>100%</Text>
                                    </View>
                                    <View style={{position:'absolute', top: PR(48), left:PR(-41)}}>
                                        <Text style={styles.percentageLabels}>50%</Text>
                                    </View>
                                    <View style={{position:'absolute', top: PR(93), left:PR(-37)}}>
                                        <Text style={styles.percentageLabels}>0%</Text>
                                    </View>
                                </>
                            }
                            <View style={{position:'absolute', left:PR(-20)}}>
                                <Icon 
                                    name={"smile"} 
                                    size={PR(15)} 
                                    color={"#1de9b6"} 
                                />
                            </View>
                            <View style={{position:'absolute', top: PR(45), left:PR(-20)}}>
                                <Icon 
                                    name={"meh"} 
                                    size={PR(15)} 
                                    color={"#ffd039"} 
                                />
                            </View>
                            <View style={{position:'absolute', top: PR(90), left:PR(-20)}}>
                                <Icon 
                                    name={"frown"} 
                                    size={PR(15)} 
                                    color={"#fb667a"}
                                />
                            </View>
                        <View style={styles.verticleLine}></View>
                        {
                            weekArray.map((day, index) => {
                                return (
                                    <View key={index} style={styles.moodSleepAdhImpactBar}>
                                        <CommonImpactMoodSleepAdhBars 
                                            day={day}
                                            eventType={eventType}
                                            switchWeek={currentWeek}
                                        />
                                    </View>
                                )
                            })
                        }
                    </>
                    }
                    <TouchableOpacity 
                        testID="impact_mood_sleep_adh_arrow_right"
                        onPress={loadNext} 
                        style={{position:'absolute', top:PR(40), right:eventType === 'reminder' ? PR(-55) : PR(-40)}} 
                        disabled={currentWeek === moment().week()}
                    >
                        <Icon 
                            name={'chevron-right'} 
                            size={PR(25)} 
                            color={currentWeek !== moment().week() ? GlobalStyles[APP_SHORTCUT_NAME].btnColor : GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor} 
                        />
                    </TouchableOpacity>
                </View>
                <View style={styles.horizontalLine}></View>
                <View style={styles.moodSleepAdhImpactLabelsContainer}>
                {
                    weekArray.map((day, index) => {
                        return (
                            <View key={index} style={styles.moodSleepAdhImpactBar}>
                                <Text style={styles.impactLabels}>{moment(day.date).locale(lang || 'en').format('DD MMM') +'\n' +moment(day.date).endOf('isoWeek').locale(lang || 'en').format('DD MMM')}</Text>
                            </View>
                        )
                    })
                }
                </View>
            </View>
            {eventType === 'reminder' &&
                <View testID='reminder_bottom_text' style={{marginTop: PR(21), width:'95%', alignItems:'center'}}>
                    <Text style={styles.adherenceBottomText}>{t('translation:programs.impact.reminder.bottom.text')}</Text>
                </View>
            }
        </>
    )
}

const styles = StyleSheet.create({
    moodSleepAdhImpactContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: PR(10),
        width: '95%'
    },
    moodSleepAdhImpactLabelsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: PR(5),
        width: '90%'
    },
    moodSleepAdhImpactBar: {
        flex: 1,
        flexDirection: 'column',
    },
    subtitle: {
        marginTop:PR(12),
        fontSize: PR(12),
        fontFamily: GlobalStyles.global.fontFamily.Regular,
        color: GlobalStyles.global.grey,
        textAlign:'center'
    },
    verticleLine: {
        height: PR(102),
        width: PR(1),
        backgroundColor: GlobalStyles.global.lightGrey,
    },
    horizontalLine: {
        borderBottomColor: GlobalStyles.global.lightGrey,
        borderBottomWidth: PR(1),
        width: '90%',
    },
    impactLabels: {
        alignSelf: 'center',
        fontSize: PR(8),
        fontFamily: GlobalStyles.global.fontFamily.Regular,
        color: GlobalStyles.global.black
    },
    percentageLabels: {
        fontSize: PR(8),
        fontFamily: GlobalStyles.global.fontFamily.Regular,
        color: GlobalStyles.global.black,
    },
    adherenceBottomText: {
        fontSize: PR(10),
        fontFamily: GlobalStyles.global.fontFamily.RegularItalic,
        opacity: 0.7
    }
})

export default CommonImpactMoodSleepAdhGraph;