import React, {useRef, useState} from 'react';
import Icon from 'react-native-vector-icons/Feather';
import GlobalStyles from '../../../styles/GlobalStyles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Text from '../../CommonCustomTxt';
import {API_URL} from 'common/src/env.json';
import CommonSimpleModal from "../../CommonSimpleModal";
// @ts-ignore
import CommonVideo from "../../CommonVideo/CommonVideo";
import CommonModal from "../../CommonModal";
import VideoItem from './VideoItem';
import UploadVideoProgressBar from './UploadVideoProgressBar';
import {useTranslation} from "react-i18next";
import {widthPercentageToDP as PR} from '../../../styles/PixelRatio';
import {styles} from './styles';


const maxVideoSizeMB = 600;

const getToken = async () => {
  try {
    return await AsyncStorage.getItem('token');
  } catch (e) {
    return null;
  }
};

const UploadVideo = ({ updateValues, values, text, deleteVideo, progressValue }) => {
  const {t} = useTranslation();
  const inputFile:any = useRef(null)
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [showMaxVideosModal, setShowMaxVideosModal] = useState<boolean>(false);
  const [showMaxSizeModal, setShowMaxSizeModal] = useState<boolean>(false);
  const [errorVideo, setErrorVideo] = useState<boolean>(false);
  const [errorVideoText, setErrorVideoText] = useState<string>('');

  const handleErrorOnUpload = (errorType?) => {
    setUploadProgress(0);
    progressValue(0);
    setErrorVideo(true);
    if (errorType === 'wrong_file_format')
      setErrorVideoText(t('translation:events.seizure.error_video_uploading_format'))
  }

  const handleProgress = event => {
    setUploadProgress(Math.round((event.loaded * 100) / event.total));
    progressValue(1)
  }

  const handleClick = () => {
    inputFile.current?.click();
    setErrorVideo(false);
  };

  const handleResponse = (response?: string) => {
    if (response) {
      const res = JSON.parse(response);
      if (res.success) {
        updateValues([...values, res.result])
        setUploadProgress(0);
        progressValue(100)
      } else {
        handleErrorOnUpload(res.errors[0].type);
      }
    }
  }

  const onClickUpload = (res): Promise<string | undefined> => new Promise(async (resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const formData =new FormData();
    const token = await getToken();

    formData.append('file', res);

    xhr.upload.addEventListener('progress', handleProgress);
    xhr.addEventListener('load', () => {
      setUploadProgress(100);
      return resolve(xhr.response);
    });
    xhr.open('POST', `${API_URL}/video-event`);
    xhr.onerror = function (e) {
      handleErrorOnUpload();
      return reject(e);
    };

    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    xhr.send(formData)
  });



  const readFile = async (event?) => {
    setErrorVideo(false);

    if (values.length >= 3) {
      setShowMaxVideosModal(true);
      return;
    }
    try {
        const fileUploaded = event.target.files[0];
        if (fileUploaded.size > maxVideoSizeMB * 1000 * 1000) {
            setShowMaxSizeModal(true)
            return;
        }
        handleResponse(await onClickUpload(fileUploaded));
    } catch (err) {
        handleErrorOnUpload();
    }
  }
  return (
    <>
      {showMaxVideosModal && (
          <CommonModal
              type={'error'}
              title={t('translation:modal.titles.warning')}
              text={t('translation:events.seizure.modal_limit_video')}
              onClose={() => setShowMaxVideosModal(false)}
              buttons={[
                {
                  type: 'primary',
                  title: t('translation:modal.btn.ok'),
                  click: () => setShowMaxVideosModal(false)
                }
              ]}
          />
      )}
      {showMaxSizeModal && (
          <CommonModal
              type={'error'}
              title={t('translation:modal.titles.warning')}
              text={t('translation:errors.messages.max_upload', { size: `${maxVideoSizeMB}MB` })}
              onClose={() => setShowMaxSizeModal(false)}
              buttons={[
                {
                  type: 'primary',
                  title: t('translation:modal.btn.ok'),
                  click: () => setShowMaxSizeModal(false)
                }
              ]}
          />
      )}
      {!!videoUrl && (
          <CommonSimpleModal onClose={() =>
              setVideoUrl('')
          }>
            <CommonVideo
                srcUri={encodeURI(videoUrl)}
            />
          </CommonSimpleModal>
      )}
      {values.map(video => (
          <>
            <VideoItem 
                video={video}
                deleteVideo={deleteVideo}
                setVideoUrl={setVideoUrl}
                isDisabled={uploadProgress > 0 ? true : false}
            />
          </>

      ))}
      {uploadProgress ?
          <UploadVideoProgressBar uploadProgress={uploadProgress}/>
          :
            <div onClick={()=>handleClick()} style={{
                borderRadius: PR(20),
                minHeight: PR(62),
                display: 'flex',
                justifyContent:'center',
                alignItems:'center',
                padding: PR(10),
                cursor: "pointer",
                flexDirection: "column",
                paddingBottom: PR(17),
                backgroundColor: GlobalStyles.global.footerGrey,
            }}>
                <input ref={inputFile} accept="video/*" onChange={readFile} style={{display:'none'}} type="file"/>
                <Icon style={styles.icon} name="film" size={PR(25)} color={GlobalStyles.global.greyToBlack}/>
                <Text style={{color: GlobalStyles.global.greyToBlack}}>{text}</Text>
                <p></p>
                <Text style={{color: GlobalStyles.global.grey, flex:1, fontSize: PR(12), textAlign: 'center'}}>{t('translation:events.uploadVideo_disclaimer')}</Text>
            </div>
      }
      { errorVideo && !errorVideoText &&
        <Text style={styles.errorTxt}>{t('translation:events.seizure.error_video_uploading')}</Text>
      }
      { errorVideo && errorVideoText &&
        <Text style={styles.errorTxt}>{errorVideoText}</Text>
      }
    </>
  )
};

export default UploadVideo;