import React from "react";
import { View } from "react-native";
import Text from 'common/src/components/CommonCustomTxt';
import { styles } from "./styles";
import {useTranslation} from "react-i18next";

type OwnTypes =  {
    uploadProgress: number
}

const UploadVideoProgressBar = ({uploadProgress}:OwnTypes) => {
    const {t} = useTranslation();
    
    return(
        <View style={styles.inputContainer}>
            <Text style={styles.inputText}>{t('translation:events.seizure.uploading_progress')}</Text>
            <View style={styles.loadingProgressContainer}>
                <View style={styles.loadingProgressBackground} />
                <View style={[styles.loadingProgress, { width: `${uploadProgress}%` }]} />
            </View>
        </View>
    )
}

export default UploadVideoProgressBar;