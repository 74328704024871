export const UPDATE_EVENT = 'UPDATE_EVENT';
export const RESET_EVENTS = 'RESET_EVENTS';
export const RESET_EVENTS_SUCCESS = 'RESET_EVENTS_SUCCESS';
export const UPDATE_VIDEO_EVENTS = 'UPDATE_VIDEO_EVENTS';
export const DELETE_VIDEO_EVENT = 'DELETE_VIDEO_EVENT';
export const LOAD_EVENT = 'LOAD_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const UPDATE_NIGHTWATCH_EVENT = 'UPDATE_NIGHTWATCH_EVENT'; 
export const GET_IMPACT_EVENTS = 'GET_IMPACT_EVENTS';
export const GET_IMPACT_EVENTS_SUCCESS = 'GET_IMPACT_EVENTS_SUCCESS';
export const GET_TRIGGERS_EVENTS = 'GET_TRIGGERS_EVENTS';
export const GET_TRIGGERS_EVENTS_SUCCESS = 'GET_TRIGGERS_EVENTS_SUCCESS';
export const GET_AURAS_EVENTS = 'GET_AURAS_EVENTS';
export const GET_AURAS_EVENTS_SUCCESS ='GET_AURAS_EVENTS_SUCCESS';

export const updateEvent = (payload) => ({
        type: UPDATE_EVENT,
        ...payload
});

export const resetEvents = () => ({
    type: RESET_EVENTS
});

export const updateVideoEvents = (payload) => ({
    type: UPDATE_VIDEO_EVENTS,
    ...payload
});

export const deleteVideoEvent = (payload) => ({
    type: DELETE_VIDEO_EVENT,
    ...payload
});

export const loadEvent = (payload) => ({
    type: LOAD_EVENT,
    ...payload
});

export const deleteEvent = (payload) => ({ type: DELETE_EVENT, ...payload });

export const updateNightwatchEvent = (payload) => ({ type: UPDATE_NIGHTWATCH_EVENT, ...payload });

export const getImpactEvents = (payload) => ({
    type: GET_IMPACT_EVENTS,
    ...payload
})

export const getTriggersEvents = (payload) => ({
    type: GET_TRIGGERS_EVENTS,
    ...payload
})

export const getAurasEvents = (payload) => ({
    type: GET_AURAS_EVENTS,
    ...payload
})