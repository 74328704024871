import React from 'react';
import {View, Image, Platform} from 'react-native';
import Images from '../services/imageServices';

type IProps = {
  name: string,
  height?: any,
  width?: any,
  style?: any,
  uri?:string
}


const CommonImage = ({name, height, width, style, uri}:IProps) => {

  return (
    <View>
      {
        Platform.OS === 'web'?
            <img src={uri ? uri : Images[name].web} style={height=== undefined ? {width: width, objectFit:'contain', alignSelf:'center', ...style}:{height: height, width: width, objectFit:'contain', alignSelf:'center', ...style}}/>
            :
            <Image
              style={height=== undefined ? {width: width, ...style} : {height: height, width: width, ...style}}
              source={uri ? {uri: uri} : Images[name].mobile}
              resizeMode="contain"
            />
      }
    </View>
  );
}

export default CommonImage;