import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import {
  Image,
  ScrollView,
  SafeAreaView,
  Platform,
} from "react-native";
import { RootStackParams } from "../types";
import { widthPercentageToDP as PR } from "../styles/PixelRatio";
import { useTheme } from "@react-navigation/native";
import CommonHeader from "./CommonHeader";
import CommonImage from "./CommonImage";

type OwnProps = StackScreenProps<RootStackParams, "ImagePage">;

const getImageSize = (
  uri: string
): Promise<{ width: number; height: number } | null> =>
  new Promise((resolve, _) => {
    Image.getSize(
      uri,
      (width, height) => resolve({ width, height }),
      (_) => resolve(null)
    );
  });

const getImage = ({
  uri,
  height,
  width,
}: {
  uri: string;
  width: number;
  height: number;
}) =>
  Platform.OS === "web" ? (
    <div style={{ overflow: "scroll", marginLeft: '5%' }}>
      <img src={uri} />
    </div>
  ) : (
    <ScrollView contentContainerStyle={{ height, width, padding: PR(10), marginLeft: '5%' }}>
      <CommonImage name="" uri={uri} height={height} width={width} />
    </ScrollView>
  );

const CommonImagePage = (props: OwnProps) => {
  const [size, setSize] = useState<
    { width: number; height: number } | undefined
  >();
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      const gotSize = await getImageSize(props.route.params.uri);
      if (gotSize) {
        setSize({ height: gotSize.height, width: gotSize.width });
      }
    })();
  }, [props.route.params.uri]);
  return (
    <SafeAreaView style={{ flex: 1, padding: PR(10) }}>
      <CommonHeader
        title={props.route.params.title || ''}
        leftIconName="arrow-left"
        leftIconColor={theme.colors.primary}
        leftClick={() => {
          props.navigation.goBack();
        }}
      />
      {size &&
        getImage({
          uri: props.route.params.uri,
          height: size.height,
          width: size.width,
        })}
    </SafeAreaView>
  );
};

export default CommonImagePage;
