import { AnyAction } from 'redux';
import { RootState } from '../reducers';
import { BiometricsActions } from './biometricsActions';

interface BiometricReducerInterface {
    isBiometricKeyExists: boolean
}

const initialState:BiometricReducerInterface = {
    isBiometricKeyExists: false
};

export default function (state = initialState, action: AnyAction): BiometricReducerInterface {
    if (action.type === BiometricsActions.BIOMETRIC_KEY_EXISTS_SUCCESS){
        return {
            ...state,
            isBiometricKeyExists: action.response
        }
    } 
    else if (action.type === BiometricsActions.GET_PIN_STATUS_SUCCESS){
        return {
            ...state,
            isBiometricKeyExists: action.response?.result?.message
        }
    } 
    else if (action.type === BiometricsActions.ENABLE_PIN_CODE_SUCCESS){
        return {
            ...state,
            isBiometricKeyExists: true
        }
    } 
    else if (action.type === BiometricsActions.DISABLE_PIN_CODE_SUCCESS){
        return {
            ...state,
            isBiometricKeyExists: false
        }
    } 
    return state;
}

export const isBiometricKeyExists = (state: RootState) => state.biometricKeyExistsReducer.isBiometricKeyExists;