import React from 'react';
import {View, StyleSheet, Platform} from 'react-native';
import CommonImage from '../CommonImage';
import GlobalStyles from '../../styles/GlobalStyles';
import Text from '../CommonCustomTxt';
import {APP_SHORTCUT_NAME} from '../../env.json';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';


interface TitleTypes {
  imageName: string,
  step: number,
  range: Array<any>
}

const CommonAddTreatmentTitleSection = ({imageName, step, range}:TitleTypes) => {
  const steps = (currentStep) =>{
    return range.map((item, i) =>{
      return(
        <>
          <View style={{flexDirection:'column', alignItems:'center', height:PR(80)}}>
            <View key={i} style={[styles.stepItem, {backgroundColor: currentStep === i ? GlobalStyles[APP_SHORTCUT_NAME].color : GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor}]}>
              <Text style={styles.stepTxt}>{item.stepNum}</Text>
            </View>
            <View style={{position:'absolute', bottom:PR(10), width:PR(100), alignItems:'center'}}>
              <Text style={{color:GlobalStyles[APP_SHORTCUT_NAME].color}}>{item.stepName}</Text>
            </View>
          </View>
          { i < range.length-1 && 
            <View style={[styles.horizontalDivider, {marginTop: PR(-30)}]}></View>
          }
        </>
      )
    })
  }
  return(
    <View style={styles.container}>
      <View style={styles.titleIcon}>
        <CommonImage name={imageName} width={PR(40)} height={PR(40)}/>
      </View>
      <View style={styles.stepsWrapper}>
        {
          steps(step)
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingBottom:PR(20)
  },
  titleIcon: {
    alignSelf:'center', 
    borderWidth:PR(2), 
    borderRadius:PR(30), 
    borderColor:GlobalStyles.global.lightGrey, 
    backgroundColor: GlobalStyles.global.lightGrey,
    padding: PR(10),
    marginTop: PR(20)
  },
  stepsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf:'center', 
    justifyContent: 'center',
    paddingTop: PR(20)
  },
  stepItem: {
    width: PR(44),
    height: PR(44),
    borderRadius: PR(22), 
    borderColor:GlobalStyles[APP_SHORTCUT_NAME].color,
    justifyContent: 'center',
    alignItems: 'center'
  },
  horizontalDivider: {
    width: Platform.OS === 'web' ? '50%' : '10%',
    borderRadius: PR(1), 
    borderBottomWidth: PR(5), 
    borderBottomColor: GlobalStyles[APP_SHORTCUT_NAME].ultraLightColor,
    // flexGrow:1
  },
  stepTxt: {
    color: GlobalStyles.global.white,
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Bold
  }
})

export default CommonAddTreatmentTitleSection;