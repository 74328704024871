import React from "react";
import {View, TouchableOpacity, StyleSheet, Platform} from 'react-native';
import {useSelector} from 'react-redux';
import Text from '../CommonCustomTxt';
import Icon from 'react-native-vector-icons/Feather';
import {useTranslation} from 'react-i18next';
import {useNavigation} from "@react-navigation/native";
import { getColor, getContainerBackgroundColor, getListCMSData, defineFormTitle, defineActionTitle, getIcon } from './utils';
import {convertLangCode} from 'common/src/services/translationList';
import { DateTime } from "luxon";
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import GlobalStyles from '../../styles/GlobalStyles';
import MedicationEvent from './EventSubComponents/MedicationEvent';

interface Event {
    title: string,
    item: Array<any>,
    type: string,
    itemNames: Array<any>,
    callback?: (e:any) => void,
    moreActionCallback: Record<string, any>,
    saveForm?: () => void,
    modal?: (data:Record<string, any>) => void
}

// the purpose of this child component is to recognized what kind of event we have and show it in the diary
const Events = ({title, item, type, itemNames, callback, moreActionCallback, saveForm, modal}: Event) => {
    const navigation = useNavigation();
    const {i18n, t} = useTranslation();
    const lang = convertLangCode(i18n.language) 
    const styles: any = generateStyleSheet(type);
    const ActionWithEvents = useSelector((state: any) => state.patientProgramsReducers.patientProgramActionEvents)
    const userTimezone = useSelector((state: any) => state.getMe?.settings?.timezone);

    item.sort((a,b) => (a[itemNames[0]] > b[itemNames[0]]) ? 1 : ((b[itemNames[0]] > a[itemNames[0]]) ? -1 : 0))
  
    const handlePress = (itemClick:Record<string, Record<string, object>>) => {
      if (type === "seizure") {
        navigation.navigate("AddEvent", {
          itemType: "Seizure",
          initialData: itemClick,
        });
      } else if (type === "appointment") {
        navigation.navigate("AddEvent", {
          itemType: "Appointment",
          initialData: itemClick,
        });
      } else if (type === "sideEffect") {
        navigation.navigate("AddEvent", {
          itemType: "Side Effect",
          initialData: itemClick,
        });
      } else if (type === "notes") {
        navigation.navigate("AddEvent", {
          itemType: "Note / Event",
          initialData: itemClick,
        });
      } else if (type === "medication") { 
        callback && callback(itemClick); 
      } else if (type === "forms") {
        navigation.navigate("StudyForm", {
          questionnaire: {
            eventId: itemClick.id,
            formId: itemClick?.form?.id,
            expirationDate: itemClick?.studyParameters?.expiration,
            site: itemClick?.studyParameters?.site,
            sentDate: itemClick?.studyParameters?.send_date,
            targetDate: itemClick?.studyParameters?.targetDate
          },
          saveForm,
        });
      } else if (type === "nightwatch") {
        navigation.navigate("NightwatchEvent", {
          itemType: "Nightwatch",
          initialData: itemClick,
        });
      } else if (type === 'program_actions') {
        let done = itemClick.done
        if(!done && callback)
          callback({actionId: itemClick.action, done, actionEventId: itemClick.id || itemClick._id})
        else
          modal && modal({actionId: itemClick.action, done, actionEventId: itemClick.id || itemClick._id})
      }
    };

    const goToAction = (event) => {
      if (!ActionWithEvents || ActionWithEvents.length === 0){
        moreActionCallback.getEvents(event.action, (res) => {
          moreActionCallback.getSchedule(event.action, () => {
            const actionFound = res.find(action => (action.id || action._id) === event.action)
            if (actionFound)
              navigation.navigate('ProgramAction', { programAction: actionFound, handleActionSchedule: moreActionCallback.handleAction, removeAction: moreActionCallback.removeAction })
          });
        });
      }
      else {
        moreActionCallback.getSchedule(event.action, () => {
          const actionFound = ActionWithEvents.find(action => (action.id || action._id) === event.action)
          if (actionFound)
            navigation.navigate('ProgramAction', { programAction: actionFound, handleActionSchedule: moreActionCallback.handleAction, removeAction: moreActionCallback.removeAction })
        });
      }
    }

    const renderActionEventIcon = (isFromDeletedAction, status) => {
      if (isFromDeletedAction)
        return <Icon name={"x-circle"} size={PR(20)} color={GlobalStyles.global.actionGreen}/>
      else if (status)
        return <Icon name={"circle"} size={PR(20)} color={GlobalStyles.global.actionGreen}/>
      else
        return <Icon name={"check-circle"} size={PR(20)} style={{color:GlobalStyles.global.actionGreen}} color={GlobalStyles.global.white}/>
    }

    return (
        <View style={styles.eventContainer}>
            <View style={{flexDirection:'row', alignItems: 'center'}}>
                <Icon 
                    name={getIcon(type)}
                    size={PR(20)} 
                    color={getColor(type)} 
                />
                <Text renderAsHtml={true} style={[styles.eventTitle, {paddingLeft: 5}]}>{title}</Text>
            </View>
            {item.map((sItem,sIndex) =>{
                let keyValueCol1 = type === 'sideEffect' ? getListCMSData(t, sItem[itemNames[0]], 'list.side_effect_types') 
                : type === 'notes' ? getListCMSData(t, sItem[itemNames[0]], 'list.other_event_categories') : sItem[itemNames[0]]
                let keyValueCol2 = type === 'seizure' ? getListCMSData(t, sItem[itemNames[1]], 'list.seizureList') 
                : type === 'sideEffect' ? getListCMSData(t, sItem[itemNames[1]], 'list.side_effect_intensities') : sItem[itemNames[1]]
                const isMedication = type === 'medication';
                const isForms = type === 'forms';
                const isActions = type === 'program_actions';
                const isOther =  !isMedication && !isForms && !isActions;
                return (
                    <View key={sIndex}>
                        { isMedication && 
                            <MedicationEvent handlePress={handlePress} itemNames={itemNames} sItem={sItem} styles={styles} userTimezone={userTimezone} />
                        }
                    
                        { isForms &&
                            <TouchableOpacity style={{ width: "100%", marginVertical:PR(4)}} onPress={() => {handlePress(sItem)}}>
                                <View style={{flexDirection:"row", width: '100%'}}>
                                    <Text style={styles.eventSubtitle}>{DateTime.fromISO(sItem[itemNames[0]]).toFormat('HH:mm')} - </Text>
                                    <Text renderAsHtml={true} numberOfLines={1} style={[styles.eventSubtitle, {flex:1}]}>{defineFormTitle(sItem[itemNames[2]], lang, t)}</Text>
                                </View>
                                <Text renderAsHtml={true} style={[styles.eventSubtitle, {fontSize:PR(12), alignSelf:'flex-end'}]}>{t('translation:journal.events.lastEdit',{date: DateTime.fromISO(sItem[itemNames[1]]).toFormat('dd/MM/yyyy')})}</Text>
                            </TouchableOpacity>
                        }
        
                        { isActions &&
                            <View style={{ width: "100%", marginVertical:PR(4)}}>
                                <View style={{flexDirection:"row", width: '100%', alignItems:'center'}}>
                                    <TouchableOpacity style={{flex: 1, flexDirection:"row"}} onPress={() => goToAction(sItem)} disabled={sItem.isFromDeletedAction}>
                                      <View style={{maxWidth:Platform.OS === 'web'? '11%':'15%', flex:1}}>
                                          <Text style={styles.eventSubtitle}>{DateTime.fromISO(sItem[itemNames[0]]).toFormat('HH:mm')}</Text>
                                      </View>
                                      <View style={{maxWidth:'3%', flex:1}}>
                                          <Text style={styles.eventSubtitle}>-</Text>
                                      </View>
                                      <View style={{minWidth:'75%', maxWidth:'75%', flex:1}}>
                                          <Text renderAsHtml={true} numberOfLines={2} style={[styles.eventSubtitle, sItem.isFromDeletedAction ? {textDecorationLine: "line-through"} : {}]}>{defineActionTitle(sItem[itemNames[2]], lang, t)}</Text>
                                      </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{maxWidth:'7%', flex:1}} onPress={() => {handlePress(sItem)}} disabled={sItem.isFromDeletedAction}>
                                        {renderActionEventIcon(sItem.isFromDeletedAction, !sItem[itemNames[1]])}
                                    </TouchableOpacity>
                                </View>
                            </View>
                        }
                    
                        { isOther && 
                            <TouchableOpacity 
                                style={{ width: '100%', marginVertical:PR(4)}} 
                                onPress={() => {handlePress(sItem);}
                            }>
                                <View style={{flexDirection:itemNames[0]==="other_type"?"column":"row", width: '100%'}}>
                                    {type === 'notes'?
                                        <Text style={styles.eventSubtitle}>{!sItem.all_day && `${DateTime.fromISO(sItem.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('HH:mm')} - `}{keyValueCol1}</Text>
                                    :
                                        <Text style={styles.eventSubtitle}>{itemNames[0] === 'date'?DateTime.fromISO(sItem[itemNames[0]], { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }).toFormat('HH:mm'):keyValueCol1}</Text>
                                    }
                                    {itemNames[0]==="other_type"?
                                    <View style={{paddingBottom:3}}></View>:
                                    <Text style={styles.eventSubtitle}> - </Text>
                                    }
                                    <Text numberOfLines={1} style={[styles.eventSubtitle, {flex:1}]}>
                                    {itemNames[1] === "doctor_info" ? 
                                        sItem[itemNames[1]].name :
                                        itemNames[1] === undefined ? 
                                        t('translation:journal.events.nightwatch_recording') : 
                                        keyValueCol2}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                )
            })}
        </View>
    )
}

const generateStyleSheet = (type?) => {
    return StyleSheet.create({
        eventContainer: {
            backgroundColor: getContainerBackgroundColor(type),
            borderColor: getColor(type),
            borderWidth: type === 'notes' || type === 'medication' || type === 'program_actions' ? PR(1): 0,
            borderRadius: PR(20),
            padding: PR(10),
            marginBottom: PR(10)
        },
        eventTitle: {
            fontFamily: GlobalStyles.global.fontFamily.Medium,
            fontSize: PR(16),
            color: getColor(type)
        },
        eventSubtitle: {
            fontSize: PR(13),
            color: getColor(type),
        }
    })
  }

export default Events;