import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { EducationChapterContent, EducationModulePage } from "../../../types";
import {
  ImageContent,
  ParagraphContent,
  VideoContent,
} from "./CommonPageComponent";
import CommonQuestionnaireContentComponent from "./CommonQuestionnaireContentComponent";
import CommonActionContentComponent from "./CommonActionContentComponent";
import CommonQuestionContentComponent from "./CommonQuestionContentComponent";
import { isConditionSatisfied } from "../util";

interface OwnProps {
  page: EducationModulePage;
  language: string;
  updateQuestionnaire: (
    questionnaireId: string,
    questionIndex: string,
    answer: string
  ) => void;
  questionnaireAnswers: Record<string, object> | null;
  handleChapterAction: (chapterActionID: string) => void;
  updateSingleQuestion: (id: string, answer: any) => void;
  chapterId: string;
  navigation: any;
  updateChapterAction: (lastPage: number) => void;
  currentPageIndex: number;
}

interface ChapterContentProps {
  content: EducationChapterContent;
  language: string;
  chapterAnswers: object | null;
  index: string;
  updateQuestionnaire: (
    questionnaireId: string,
    questionIndex: string,
    answer: string
  ) => void;
  questionnaireAnswers: Record<string, object> | null;
  handleChapterAction: (chapterActionID: string) => void;
  updateSingleQuestion: (id: string, answer: any) => void;
  navigation: any;
  updateChapterAction: (lastPage: number) => void;
  currentPageIndex: number;
}

const ChapterContent = ({
  content,
  index,
  language,
  chapterAnswers,
  updateSingleQuestion,
  updateQuestionnaire,
  handleChapterAction,
  questionnaireAnswers,
  navigation,
  updateChapterAction,
  currentPageIndex
}: ChapterContentProps) => {
  const [shouldBeVisible, setShouldBeVisible] = useState(true);
  const { contentType } = content;

  useEffect(() => {
    if (content.hasCondition && content.conditions) {
      if (chapterAnswers) {
        const shouldDisplay = content.conditions.every((condition) =>
          isConditionSatisfied(condition, chapterAnswers)
        )
        if (!shouldDisplay && content.question)
          chapterAnswers[content.question.id] = ""

        setShouldBeVisible(shouldDisplay);
      } else {
        setShouldBeVisible(false);
      }
    }
  }, [chapterAnswers, content]);

  return (
    <>
      {!shouldBeVisible && <></>}
      {shouldBeVisible && contentType === "paragraph" && (
        <ParagraphContent
          content={content}
          key={`content-${index}`}
          language={language}
        />
      )}
      {shouldBeVisible && contentType === "image" && (
        <ImageContent
          content={content}
          key={`content-${index}`}
          language={language}
        />
      )}
      {shouldBeVisible && contentType === "video" && (
        <VideoContent
          content={content}
          key={`content-${index}`}
          language={language}
        />
      )}
      {shouldBeVisible && contentType === "questionnaire" && (
        <CommonQuestionnaireContentComponent
          content={content}
          key={`content-${index}`}
          language={language}
          handleAnsweringQuestion={(question, answer) => {
            updateQuestionnaire(
              content.questionnaireForm,
              question.index,
              answer
            );
          }}
          previousQuestionnaireAnswers={questionnaireAnswers}
        />
      )}
      {shouldBeVisible && contentType === "action" && (
        <CommonActionContentComponent
          content={content}
          key={`content-${index}`}
          handleChapterAction={handleChapterAction}
          navigation={navigation}
          updateChapterAction={updateChapterAction}
          currentPageIndex={currentPageIndex}
        />
      )}
      {shouldBeVisible && contentType === "question" && (
        <CommonQuestionContentComponent
          content={content}
          handleAnsweringQuestion={updateSingleQuestion}
          key={`content-${index}`}
          previousAnswer={
            chapterAnswers && content.question.id in chapterAnswers
              ? chapterAnswers[content.question.id]
              : null
          }
        />
      )}
    </>
  );
};

const CommonChapterPage = ({
  page,
  language,
  updateQuestionnaire,
  questionnaireAnswers,
  handleChapterAction,
  updateSingleQuestion,
  chapterId,
  navigation,
  updateChapterAction,
  currentPageIndex
}: OwnProps) => {
  const [chapterAnswers, setChapterAnswers] = useState<null | object>(null);

  useEffect(() => {
    setChapterAnswers(
      questionnaireAnswers && chapterId in questionnaireAnswers
        ? questionnaireAnswers[chapterId]
        : null
    );
  }, [questionnaireAnswers]);

  const sortedContent =
    page.content && page.content.length > 0
      ? [...page.content].sort((current, next) => current.index - next.index)
      : [];

  return (
    <>
      {sortedContent.length > 0 &&
        sortedContent.map((content) => (
          <View key={`${chapterId}-${page.index}-${content.index}`}>
            <ChapterContent
              content={content}
              index={`${chapterId}-${page.index}-${content.index}`}
              language={language}
              updateQuestionnaire={updateQuestionnaire}
              updateSingleQuestion={updateSingleQuestion}
              handleChapterAction={handleChapterAction}
              chapterAnswers={chapterAnswers}
              questionnaireAnswers={questionnaireAnswers}
              navigation={navigation}
              updateChapterAction={updateChapterAction}
              currentPageIndex={currentPageIndex}
            />
          </View>
        ))}
    </>
  );
};

export default CommonChapterPage;
