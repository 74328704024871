import { GET_PROGRAMS_SUCCESS, GET_PATIENT_PROGRAMS_SUCCESS, ADD_PATIENT_PROGRAMS_SUCCESS, UPDATE_PATIENT_PROGRAMS_SUCCESS, GET_EDUCATION_MODULES_SUCCESS, GET_PROGRAM_ACTIONS_SUCCESS, ADD_PROGRAM_ACTION_SUCCESS, GET_SUBPROGRAMS_SUCCESS } from "./programsActions";
import { AnyAction } from 'redux';
import { ProgramsReducer, ProgramsTypes, PatientPrograms } from './types';

const initialState: ProgramsReducer = {
    allPrograms: null,
    programs: null,
    patientPrograms: null,
    educationModules: null,
    programActions: null,
    currentSubprograms: null
};

export default function(state = initialState, action:AnyAction): ProgramsReducer {
    const response = action.response;
    switch(action.type) {
        case GET_PROGRAMS_SUCCESS:
            let allPrograms:ProgramsTypes[] = []
            let libraryPrograms:ProgramsTypes[] = response.result
            return { 
                ...state, 
                programs: libraryPrograms,
                allPrograms: allPrograms
            };
        case GET_EDUCATION_MODULES_SUCCESS:
            return { 
                ...state, 
                educationModules: response.result
            };
        case GET_PROGRAM_ACTIONS_SUCCESS:
            return { 
                ...state, 
                programActions: response.result
            };
        case GET_PATIENT_PROGRAMS_SUCCESS:
            let myPrograms:PatientPrograms[] = response.result
                return { 
                    ...state, 
                    patientPrograms: myPrograms
                };
        case ADD_PATIENT_PROGRAMS_SUCCESS:
            let registeredProgram:PatientPrograms[] = []
            if(state.patientPrograms)
                registeredProgram = [...state.patientPrograms]
                state.programs && state.programs.forEach(program => {
                    if(program.id === response.result.program){
                        program.status = 'active'
                        Object.assign(response.result, {program: program})
                        registeredProgram.push(response.result)
                    }
                })
            let newLibraryPrograms: ProgramsTypes[] = []
            if(state.programs)
                newLibraryPrograms = state.programs.filter(p => p.status !== 'active')
            return { 
                ...state, 
                patientPrograms: registeredProgram,
                programs: newLibraryPrograms
            };
        case UPDATE_PATIENT_PROGRAMS_SUCCESS:
            let updatedProgram:PatientPrograms[] = []
            if(state.patientPrograms)
                updatedProgram = [...state.patientPrograms]
            updatedProgram.forEach(patientProgram => {
                if(patientProgram.id === response.result.id){
                    if(response.dataSent?.subProgram) {
                        patientProgram.subProgram = response.dataSent?.subProgramData
                    }
                    else
                        patientProgram.introductionDone = true
                }
            })
            return { 
                ...state, 
                patientPrograms: updatedProgram
            };
        case ADD_PROGRAM_ACTION_SUCCESS:
            let patientProgramCpy:PatientPrograms[] = []
            if(state.patientPrograms)
                patientProgramCpy = [...state.patientPrograms]
            let findPatientProgram = patientProgramCpy.findIndex(program => program.id === response.result.id)
            if(findPatientProgram > -1)
                patientProgramCpy[findPatientProgram].actions = [...response.result.actions]
    
            return { ...state, patientPrograms: [...patientProgramCpy] };
        
        case GET_SUBPROGRAMS_SUCCESS:
            return {...state, currentSubprograms: response.result}
        default:
            return state
    }
};