import React from "react";
import Events from "../Events";
import { DiaryFilterTypes } from '../../../types/index';

interface OwnTypes {
    filterData:Array<DiaryFilterTypes>, 
    title:string, 
    item:Array<any>,
    type:string, 
    itemNames:Array<string>, 
    saveForm:() => void,
}

const FormEvents = ({filterData, title, item, type, itemNames, saveForm}: OwnTypes) => {
    return(
        <>
        {filterData.length === 0  &&
            <Events title={title} item={item} type={type} itemNames={itemNames} saveForm={saveForm}/>
        }
        {filterData.length > 0 && item.length > 0 && filterData.find(fd => fd.value === type) &&
            <Events title={title} item={item} type={type} itemNames={itemNames} saveForm={saveForm}/>
        }
        </>
    )
}

export default FormEvents;