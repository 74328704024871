import React, {useState} from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import CommonHeader from '../CommonHeader';
import CommonTitle from '../CommonTitle';
import CommonBtn from '../CommonBtn'
import CommonSubtitle from '../CommonSubtitle';
import CommonModal from '../CommonModal';
import CommonApointment from './CommonAppointment';
import CommonAddSeizure from './CommonAddSeizure';
import CommonAddSideEffect from './CommonAddSideEffect'
import CommonAddNote from './CommonAddNote';
import CommonReminder from './CommonReminder';
import Text from '../CommonCustomTxt';
import GlobalStyles from '../../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import {useTranslation} from 'react-i18next';
import { DateTime } from "luxon";
import CommonDateInput from "../CommonDateInput";
import CommonTimeInput from "../CommonTimeInput";
import CommonFooter from '../CommonFooter';
import {checkBottomScroll, dateTimeEvents} from '../../services/utils';
import {compareDatesForEvents} from '../../services/compare';
import { DeleteEventAction, AppointmentChecklistTypes } from '../../types';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import CommonKeyboardAvoidingView from '../CommonKeyboardAvoidingView';

interface AddEventTypes {
  navigation: any,
  type: string,
  icon: string,
  modalTitle: string,
  infoTxt: string,
  title: string,
  addTitle: string,
  subtitle: string,
  timeTitle: string,
  getData: any,
  getMeRes:any,
  getMedicationsRes:any,
  initialData: any,
  checklistData: any,
  onDeleteAction?: DeleteEventAction;
  onChecklistBtnClick: () => void;
  onEditChecklistClick: (data:AppointmentChecklistTypes) => void;
  onDeleteChecklistClick: () => void;
}

const CommonAddEventForm = (
  {
    navigation,
    type,
    icon,
    modalTitle,
    infoTxt, 
    title, 
    addTitle,
    subtitle, 
    timeTitle,
    getData,
    getMeRes,
    getMedicationsRes,
    initialData,
    checklistData,
    onDeleteAction,
    onChecklistBtnClick,
    onEditChecklistClick,
    onDeleteChecklistClick
  }: AddEventTypes
  ) => {
  const {t} = useTranslation();
  const [scrollState, setScrollState] = useState<any>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false)
  const [date, setDate] = useState((initialData?.date? DateTime.fromISO(initialData?.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }) : DateTime.now()).toFormat('dd-MM-yyyy'));
  const [time, setTime] = useState((initialData?.date? DateTime.fromISO(initialData?.date, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone }) : DateTime.now()).toFormat('HH:mm'));
  const [buttonValue, setButtonValue] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>();
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
  const [checkDate, setCheckDate] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isWarningModalEnabled, setIsWarningModalEnabled] = useState<boolean>(false);

  let currentTime = DateTime.fromJSDate(new Date()).toFormat('HH:mm')
  let currentDate = DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy')
  
  const changeDate = (inputDate) => {
    if(DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy') !== 'Invalid DateTime'){
      inputDate = DateTime.fromFormat(inputDate, 'yyyy-MM-dd').toFormat('dd-MM-yyyy')
      setDate(inputDate)
      enableWarningModal(true)
    }
    if(compareDatesForEvents(inputDate, currentDate, 'dd-MM-yyyy') && (type === 'Seizure' || type === 'Side Effect')){
      setCheckDate(false)
      enableWarningModal(true)
    }
    else{
      setCheckDate(true)
      setTime(DateTime.now().toFormat('HH:mm'))
      enableWarningModal(true)
    }
  }

  const enableWarningModal = (data) => {
    setIsWarningModalEnabled(data)
  }

  icon = icon || 'image'

  const sendDataBack = (data, callback) => {
    let obj
    let dateTime = dateTimeEvents(date, time)
    switch(data.type) {
      case 'appointment':
        obj = {
          date: dateTime,
          doctor_info: {
            name: data.physician,
            type: data.doctorType
          },
          consultation: data.appointmentType,
          place: data.location,
          remark: data.remarks,
          checklist: data.checklist,
          type: data.type,
          msg: data.msg
        }
        break;
      case 'seizure':
        obj = {
          date: dateTime,
          felt: data.answer,
          during_sleep: data.answerDuringSleep,
          duration: data.duration,
          emergency_treatments: data.emergencyType,
          post_seizure: data.postSeizuresType,
          seizure_type: data.seizureType,
          triggers: data.triggersType,
          auras: data.aurasType,
          place: data.whereType,
          remark: data.remarks,
          type: data.type,
          videoList: data.videoResponse ? [data.videoResponse] : undefined,
          msg: data.msg,
          seizure_type_id: data.seizureTypeId,
          same_nomenclature: data.sameNomenclature || false
        }
        break;
      case 'side_effect':
        obj = {
          date: dateTimeEvents(date, currentTime),
          effect_type: data.sideEffectType,
          intensity: data.intesityType,
          remark: data.remarks,
          type: data.type,
          msg: data.msg
        }
        break;
      case 'other':
        obj = {
          date: dateTimeEvents(date, data.time || currentTime),
          other_type: data.noteType,
          all_day: data.answer,
          remark: data.remarks,
          type: data.type,
          msg: data.msg
        }
        break;
      case 'reminder':
        setButtonValue(data.btnValue)
        obj = {
          settings: {
            type: data.type,
            early_morning: data.early_morning,
            morning: data.morning,
            afternoon: data.afternoon,
            evening: data.evening,
            noon: data.noon,
            notification: data.notification,
            time: data.time,
            when: data.when
          }
        }
        break;
      default:
        break;
    }
    getData(obj, callback)
  }

  const openModal = () => {
    setModalVisible(true);
  }

  // keep tracking of the changes of the modalVisible
  const callbackVisible = () => {
    setModalVisible(false);
  }

  // keep tracking of the changes of the warningVisible
  const callbackWarningVisible = () => {
    setWarningModal(false);
  }

  const confirmModalBtn = [
    {
      type: 'primary',
      title: t('translation:modal.btn.ok'),
      click: callbackVisible
    }
  ]

  const goBackToDiary = () =>{
    navigation.goBack()
    setWarningModal(false)
  }

  const warningModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackWarningVisible
    },
    {
      type: 'primary',
      title: t('translation:modal.btn.confirm'),
      click: goBackToDiary
    }
  ]
  return (
    <CommonKeyboardAvoidingView>
      { modalVisible &&
        <CommonModal type={'info'} title={modalTitle} text={infoTxt} buttons={confirmModalBtn} onClose={callbackVisible}/>
      }
      { warningModal &&
        <CommonModal 
          testID="warningModalID" 
          type={'info'} 
          title={t('translation:modal.titles.warning')} 
          text={type === 'Seizure' ? t('translation:events.seizure.data_not_saved_confirm') : t('translation:events.appointment.data_not_saved_confirm')} 
          buttons={warningModalBtn} 
          onClose={callbackWarningVisible}
        />
      }
      <CommonHeader
        testIDTitle="addEvent_title"
        title={addTitle}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={() => {(type === 'Seizure' || type === 'Appointment') && isWarningModalEnabled ? setWarningModal(true) : navigation.goBack()}}
        scroll={scrollState}
      />
      <View style={styles.scrollViewWrapper}>
        <ScrollView 
          testID="AddEvent_scrollView"
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            let calc = checkBottomScroll(event.nativeEvent, 15);
            if (!scrollAtBottom && calc)
              setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
              setScrollAtBottom(false)
            setScrollState(event.nativeEvent.contentOffset.y);
          }}
        >
          <View
            onLayout={(event: any) => {
              setContentHeight(event.nativeEvent.layout.height)
            }}
          >
            <View style={styles.IconRow}>
              <View style={styles.Icon}>
                <View style={styles.titleIcon}>
                  <Icon name={icon} size={PR(40)} color={GlobalStyles.helpi.color}/>
                </View>
                <TouchableOpacity onPress={openModal} style={styles.titleInfo}>
                  <Icon name={"info"} size={PR(25)} color={GlobalStyles.helpi.color} />
                </TouchableOpacity>
              </View>

            </View>
            <View style={styles.titleWrapper}>
              <CommonTitle text={title} color={GlobalStyles.helpi.color}/>
              <CommonSubtitle text={subtitle}/>
            </View>
            {
              type === 'Reminder' ? null :
                  <View style={styles.dateInput}>
                    <View style={styles.dateInputLabel}>
                      <Text style={styles.dateInputLabelText}>{t('translation:general.events.date')}</Text>
                    </View>
                    <CommonDateInput
                      onChange={d => changeDate(d)}
                      value={date}
                      fieldStyle={styles.dateInputField}
                      textStyle={styles.dateInputFieldText}
                      maxDate={type === 'Seizure' || type === 'Side Effect' ? new Date() : undefined}
                      webInputStyle={{
                        backgroundColor: "#F2F4F5", 
                        border: "none", 
                        width: "100%", 
                        color: GlobalStyles.global.black,
                        fontSize: PR(16),
                        fontFamily: GlobalStyles.global.fontFamily.Medium
                      }}
                    />
                    {!checkDate &&
                      <Text style={styles.errorTxt}>{t('translation:events.future_date_error')}</Text>
                    }  
                  </View>
            }
            {timeTitle !== '' && (
              <View style={styles.dateInput}>
                <View style={styles.dateInputLabel}>
                  <Text style={styles.dateInputLabelText}>{timeTitle}</Text>
                </View>
                <CommonTimeInput
                    onChange={timeData => {enableWarningModal(true); (timeData > currentTime) && (type === 'Seizure' || type === 'Side Effect') && !checkDate? setTime(currentTime) : setTime(timeData)}}
                    value={time}
                    fieldStyle={styles.dateInputField}
                    textStyle={styles.dateInputFieldText}
                    maxTime={(type === 'Seizure' || type === 'Side Effect') && date === DateTime.now().toFormat('dd-MM-yyyy') ? new Date() : undefined}
                    inputDate={type === 'Seizure' || type === 'Side Effect' ? date : undefined}
                    webInputStyle={{
                      backgroundColor: "#F2F4F5", 
                      border: "none", 
                      width: "100%", 
                      color: GlobalStyles.global.black,
                      fontSize: PR(16),
                      fontFamily: GlobalStyles.global.fontFamily.Medium
                    }}
                />
              </View>
            )}
            {type === 'Seizure' &&
              <CommonAddSeizure 
                AddSeizureData={sendDataBack} 
                isSubmitButtonActive={buttonValue} 
                initialData={initialData} 
                checkDate={checkDate} 
                isFormUpdated={enableWarningModal}
                disabled={setIsDisabled}
              />
            }
            {type === 'Appointment' && 
              <CommonApointment 
                AppointmentData={sendDataBack} 
                isSubmitButtonActive={buttonValue}
                initialData={initialData} 
                checklistData={checklistData}
                onChecklistBtnClick={onChecklistBtnClick} 
                onEditChecklistClick={onEditChecklistClick}
                onDeleteChecklistClick={onDeleteChecklistClick}
                disabled={setIsDisabled}
                isFormUpdated={enableWarningModal}
              />
            }
            {type === 'Side Effect' && 
              <CommonAddSideEffect SideEffectData={sendDataBack} isSubmitButtonActive={buttonValue} initialData={initialData} checkDate={checkDate} disabled={setIsDisabled}/>
            }
            {type === 'Note / Event' && 
              <CommonAddNote NoteData={sendDataBack} isSubmitButtonActive={buttonValue} initialData={initialData} disabled={setIsDisabled}/>
            }
            {type === 'Reminder' && 
              <CommonReminder 
                isSubmitButtonActive={buttonValue} 
                getMeRes={getMeRes} 
                getMedicationsRes={getMedicationsRes} 
                ReminderData={sendDataBack}
                navigation={navigation}
              />
            }
          </View>
        </ScrollView>
        {type === 'Reminder' && 
          <>
            <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
              <CommonBtn type="primary" click={()=>setButtonValue(true)} title={t('translation:general.header.btn_save')} testID="save_reminders" />
            </CommonFooter>
          </>
        }
        {type !== 'Reminder' &&
          <>
            <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
              { 
                onDeleteAction &&
                  <>
                    <CommonBtn type="tertiary" txtColor="red" title={t(`translation:events.${initialData?.type}.delete`)} click={onDeleteAction} /> 
                    <View style={{marginBottom: PR(5)}}></View>
                  </>
              }
              <CommonBtn type="primary" click={()=>setButtonValue(true)} title={t('translation:general.header.btn_save')} testID="save_event" disable={isDisabled}/>
            </CommonFooter>
          </>
        }
      </View>
    </CommonKeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1,
  },
  IconRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleWrapper: {
    margin: PR(30)
  },
  titleIcon: {
    alignSelf:'center', 
    borderWidth:PR(2), 
    borderRadius:PR(30), 
    borderColor:GlobalStyles.global.lightGrey, 
    backgroundColor: GlobalStyles.global.lightGrey,
    padding: PR(7)
  },
  titleInfo: {
    position: 'absolute',
    top: '35%',
    right: PR(-35)
  },
  Icon: {
    position: 'relative',
  },
  label: { 
    marginBottom: PR(10),
    marginLeft: PR(20),
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  container: {
    backgroundColor: GlobalStyles.global.lightGrey,
    borderRadius: PR(20),
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: PR(62),
    padding: PR(10),
    fontSize: PR(16),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputValue: {
    flex: 1,
    flexGrow: 1,
    color: GlobalStyles.global.black,
    marginLeft: PR(10),
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  dateInput: {
    width:'90%',
    alignSelf:'center',
    marginBottom: PR(30)
  },
  dateInputLabel: {
    marginBottom: PR(10),
    marginLeft: PR(20),
  },
  dateInputLabelText: {
    color: GlobalStyles.global.black,
    fontSize: PR(12)
  },
  dateInputField: {
    backgroundColor: '#F2F4F5',
    borderRadius: PR(20),
    borderColor: '#F2F4F5',
    height: PR(62),
    paddingHorizontal: PR(20),
    paddingVertical: PR(20),
  },
  dateInputFieldText: {
    fontSize: PR(16),
    fontFamily: GlobalStyles.global.fontFamily.Medium
  },
  errorTxt: {
    color: GlobalStyles.global.redError,
    textAlign: 'center'
  },
})

export default CommonAddEventForm;