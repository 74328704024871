import React, {useReducer, useState} from 'react';
import { ScrollView, StyleSheet, Platform, View} from 'react-native';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import InputField from 'common/src/components/InputField';
import CommonFooter from 'common/src/components/CommonFooter';
import { useDispatch } from 'react-redux';
import { updateAddressAction} from '../store/actions/authenticationActions';
import {AddressTypes} from '../components/interfaces';
import {useTranslation} from 'react-i18next';
import {checkBottomScroll} from 'common/src/services/utils';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const Address = (props:any) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [scrollState, setScrollState] = useState();
  const [contentHeight, setContentHeight] = useState<number>();
  const [scrollAtBottom, setScrollAtBottom] = useState<boolean>(false);
  const [addressDetails, setAddressDetails] = useReducer(
    (state:AddressTypes, newState:AddressTypes) => ({...state, ...newState}),
    {
    streetAndNumber: props.route.params?.address?.street || '',
    zipcode: props.route.params?.address?.postcode || '',
    city: props.route.params?.address?.city || '',
    country: props.route.params?.address?.country || ''
  })
  const profile = props.route.params.profile

  /**
 * update the local state according to text input change
 */
  const setStreetAndNumber = (text:string) => (
    setAddressDetails({
      streetAndNumber: text,
    })
  )
  const setZipcode = (text:string) => (
    setAddressDetails({
      zipcode: text,
    })
  )
  const setCity = (text:string) => (
    setAddressDetails({
      city: text,
    })
  )
  
  const saveAddress = () => {
    const obj = {
      address: {
        city: addressDetails.city,
        postcode: addressDetails.zipcode,
        street: addressDetails.streetAndNumber,
        country: addressDetails.country
      }
    }
    dispatch(updateAddressAction(obj));
    profile.address = {
      city: addressDetails.city,
      country: addressDetails.country,
      postcode: addressDetails.zipcode,
      street: addressDetails.streetAndNumber
    }
    props.navigation.goBack();
    props.route.params.onGoBack(profile)
  }
  
  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader
        title={t('translation:modal.address_picker.address')}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={() => {Platform.OS === 'web' ? window.history.back() : props.navigation.goBack()}}
        scroll={scrollState}
        testIDTitle="address_title"
      />
      <View style={styles.scrollViewWrapper}>
        <ScrollView 
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            let calc = checkBottomScroll(event.nativeEvent, 0);
            if (!scrollAtBottom && calc)
              setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
              setScrollAtBottom(false)
            setScrollState(event.nativeEvent.contentOffset.y);
          }}
        >
          <View
            onLayout={(event: any) => {
              setContentHeight(event.nativeEvent.layout.height)
            }}
          >
            <InputField 
              labelText={t('translation:modal.address_picker.street')}
              inputType="string" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setStreetAndNumber}
              placeholderTxt="-" 
              testID="street_and_number"
              value={addressDetails.streetAndNumber}
            />
            <InputField 
              labelText={t('translation:modal.address_picker.zip')}
              inputType="string" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setZipcode}
              placeholderTxt="-" 
              testID="zipcode"
              value={addressDetails.zipcode}
            />
            <InputField 
              labelText={t('translation:modal.address_picker.city')}
              inputType="string" 
              customStyle={{marginBottom:PR(30)}}
              changeText={setCity}
              placeholderTxt="-" 
              testID="city"
              value={addressDetails.city}
            />
          </View>
        </ScrollView>
        
      </View>
      <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
        <CommonBtn type="primary" click={saveAddress} title={t('translation:modal.address_picker.confirm')} testID="save_address"/>
      </CommonFooter>
      <View style={{marginBottom:Platform.OS === 'ios' ? PR(15) : 0}}></View>
    </CommonKeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
   },
})

export default Address