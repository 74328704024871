import React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  RootStackParams,
  EducationModuleChapter,
  EducationModule,
  ProgressBarStyleProps,
  EducationTrack,
  ChapterTrack,
} from "../../types";
import { SafeAreaView, ScrollView, View } from "react-native";
import CommonStyle from "./CommonStyle";
import { useTranslation } from "react-i18next";
import CommonHeader from "../CommonHeader";
import GlobalStyles from "../../styles/GlobalStyles";
import { widthPercentageToDP as PR } from "common/src/styles/PixelRatio";
import CommonEducationChapterItem from "./components/CommonEducationChapterItem";
import CommonStatusLabel from "../CommonProgress/CommonStatusLabel";

interface OwnProps {
  navigation: StackNavigationProp<RootStackParams, "EducationModule">;
  educationModule: EducationModule;
  style: ProgressBarStyleProps;
  patientEducationTrack?: EducationTrack;
}

const CommonEducationModule = ({
  educationModule,
  navigation,
  style,
  patientEducationTrack
}: OwnProps) => {
  const { i18n } = useTranslation();
  const { language: userLanguage } = i18n;

  const handleChapterNavigation = (chapter: EducationModuleChapter) => {
    navigation.navigate("EducationChapter", {
      chapter,
      educationModuleId: educationModule.id
    });
  };

  const getChapterTrack = (chapterId: string): ChapterTrack | undefined => {
    if (patientEducationTrack && patientEducationTrack.chapters) {
      const { chapters } = patientEducationTrack;
      return chapterId in chapters ? chapters[chapterId] : undefined;
    }

    return undefined;
  };

  const getChapterStatusElement = (
    chapter: EducationModuleChapter
  ): JSX.Element => {
    const chapterTrack = getChapterTrack(chapter.id);

    if (!chapterTrack) {
      return (
        <CommonStatusLabel status="not_started" color={style.progressBarColor} testID={`status-notstarted-${chapter.id}`}/>
      );
    }

    return (
      <CommonStatusLabel status={chapterTrack.status} color={style.progressBarColor} testID={`status-${chapterTrack.status}-${chapter.id}`}/>
    );
  };

  return (
    <SafeAreaView style={CommonStyle.screenContainer}>
      <CommonHeader
        title={educationModule.title[userLanguage] || educationModule.title.en}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={() => {
          navigation.goBack();
        }}
      />
      {educationModule.chapters && educationModule.chapters.length > 0 && (
        <ScrollView 
        style={{ marginTop: PR(20) }}>
          {[...educationModule.chapters]
            .sort((current, next) => current.index - next.index)
            .map((chapter) => (
              <View style={{ paddingHorizontal: PR(20), alignSelf: 'center', width: '96%' }} key={chapter.index}>
                <CommonEducationChapterItem
                  chapter={chapter}
                  language={userLanguage}
                  handleClick={() => {
                    handleChapterNavigation(chapter);
                  }}
                  StatusElement={getChapterStatusElement(chapter)}
                />
              </View>
            ))}
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default CommonEducationModule;
