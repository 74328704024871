import React, {useState} from "react";
import { Platform, Image, View, Dimensions, TouchableOpacity } from "react-native";
import {
  ImageContentChapter,
  ParagraphContentChapter,
  VideoContentChapter,
} from "../../../types";
import CommonCustomTxt from "../../CommonCustomTxt";
import CommonImage from "../../CommonImage";
import ImageZoom from 'react-native-image-pan-zoom';
//@ts-ignore
import CommonVideo from "../../CommonVideo/CommonVideo"; // Typescript does not resolve platform based.
import { useTheme } from "@react-navigation/native";
import { getCaptionInLanguage } from "../../../helpers/translations";
import Style from './CommonStyle';
import { widthPercentageToDP as PR } from '../../../styles/PixelRatio';
import CommonImageZoomModal from "../../CommonImageZoomModal";

export const ImageContent = ({
  content,
  language,
}: {
  content: ImageContentChapter;
  language: string;
}) => {
  const title = getCaptionInLanguage(language, content.imageCaption);
  const [imageOriginalHeight, setImageOriginalHeight] = useState<number>(1);
  const [imageOriginalWidth, setImageOriginalWidth] = useState<number>(1);
  const [isImageClicked, setImageClicked] = useState<boolean>(false);
  const [clickedImageUri, setClickedImageUri] = useState<string>('');
  // get the real size of the app
  Image.getSize(content.image, (width, height) => {setImageOriginalWidth(width); setImageOriginalHeight(height)})
  const contentWidth = content?.width ? content.width : '50';

  const getImageWidth = () => {
    let width = imageOriginalWidth

    // If image width is bigger than screen => zoom ratio will be image width
    if (width > Dimensions.get('window').width) {
      const widthPixel = Dimensions.get('window').width / width
      width *= widthPixel
    }

    return width;
  }

  const getImageHeight = () => {
    let height = imageOriginalHeight
    if (height > Dimensions.get('window').height) {
      const HeightPixel = Dimensions.get('window').height / height
      height *= HeightPixel
    }
    return height;
  }


  return (
    <>
      {isImageClicked && 
        <CommonImageZoomModal 
          onClose={() => setImageClicked(false)}
          title={title}
          children={
            // @ts-ignore
            <ImageZoom
              cropWidth={Dimensions.get('window').width -PR (20)}
              cropHeight={PR(400)}
              imageWidth={getImageWidth()}
              imageHeight={getImageHeight() > PR(400) ? PR(400) : getImageHeight()}
              style={{alignSelf:'center'}}
            >
              <CommonImage
                name=""
                uri={clickedImageUri}
                width={Platform.OS === 'web'? PR(500) :getImageWidth()}
                height={getImageHeight() > PR(400) ? PR(400) : getImageHeight()}
                style={{ alignSelf: "center"}}
              />
            </ImageZoom>
          } />
      }
      <View testID={`content-image-${content.index}`}>
        {title !== "" && (
          <CommonCustomTxt style={Style.caption}>{title}</CommonCustomTxt>
        )}
        {Platform.OS === 'web' ? 
          <TouchableOpacity onPress={() => {setImageClicked(true); setClickedImageUri(content.image)}}>
            <CommonImage
              name=""
              uri={content.image}
              width={contentWidth+"%"}
              style={{ alignSelf: "center", height: 'auto'}}
            />
          </TouchableOpacity>
          : imageOriginalWidth > imageOriginalHeight ? // landscape orientation
            <TouchableOpacity onPress={() => {setImageClicked(true); setClickedImageUri(content.image)}}>
              <CommonImage
                name=""
                uri={content.image}
                width={contentWidth+"%"}
                style={{ alignSelf: "center", aspectRatio: +contentWidth === 100 ? 1.5 : +contentWidth < 100 && +contentWidth > 70 ? 1.3 : +contentWidth <= 70 && +contentWidth > 45 ? 1.1 : 1}}
              />
            </TouchableOpacity>
          : // portrait orientation
            <TouchableOpacity onPress={() => {setImageClicked(true); setClickedImageUri(content.image)}}>
              <CommonImage
                name=""
                uri={content.image}
                width={contentWidth+"%"}
                style={{ alignSelf: "center", aspectRatio: +contentWidth === 100 ? 0.5 : +contentWidth < 100 && +contentWidth > 70 ? 0.6 : +contentWidth <= 70 && +contentWidth > 45 ? 0.8 : 1}}
              />
            </TouchableOpacity>
        }
      </View>
    </>
  );
};

export const ParagraphContent = ({
  content,
  language,
}: {
  content: ParagraphContentChapter;
  language: string;
}) => {
  const theme = useTheme();

  const tagsStyles = {
    h1: {
      color: theme.colors.primary,
      fontSize: PR(24),
      lineHeight: PR(30),
      marginBottom: PR(0),
    },
    h2: {
      color: theme.colors.primary,
      fontSize: PR(20),
      lineHeight: PR(30),
      marginTop: PR(10),
      marginBottom: PR(0),
    },
    h3: {
      color: theme.colors.primary,
      fontSize: PR(16),
      fontWeight: 'bold',
      lineHeight: PR(20),
      marginTop: PR(10),
      marginBottom: PR(0),
    },
    p: {
      fontSize: PR(16),
      lineHeight: PR(20),
      marginVertical: PR(1),
    },
    blockquote: {
      fontSize: PR(16),
      fontStyle: 'italic',
      color: theme.colors.primary,
      marginVertical: PR(24),
    },
    img: {
      padding: PR(10),
    },
    ul: {
      fontSize: PR(16),
    },
  };

  const paragraphContent = language in content.paragraph ? content.paragraph[language]: content.paragraph["en"];
  const htmlContent = paragraphContent.replace(/&nbsp;/g, `<span style="color: transparent">-</span>`);

  return (
    <View testID={`content-paragraph-${content.index}`}>
      <CommonCustomTxt renderAsHtml={true} tagsStyles={tagsStyles} imageWidth={PR(80)}>
        { htmlContent }
      </CommonCustomTxt>
    </View>
  );
};

export const VideoContent = ({
  content,
  language,
}: {
  content: VideoContentChapter;
  language: string;
}) => (
  <View testID={`content-video-${content.index}`}>
    <CommonCustomTxt style={Style.caption}>
      {getCaptionInLanguage(language, content.videoCaption)}
    </CommonCustomTxt>
    <CommonVideo srcUri={encodeURI(content.video)} />
  </View>
);
