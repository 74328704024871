import {setJSExceptionHandler} from 'react-native-exception-handler';
import { Platform, Alert } from 'react-native';
import DeviceInfo, {useDeviceName} from 'react-native-device-info';
import { useSelector } from 'react-redux';
import { errorRequest } from './callService';
import { useTranslation } from 'react-i18next';
import {ERROR_LOG_URL} from '../env.json';

export const errorHandler = (route) => {  
  const {t} = useTranslation();
  const screen = route?.current;

    const GetMe = useSelector((state:any) => state.getMe.response)
    if(Platform.OS !=='web'){

      const { result } = useDeviceName();
      const exceptionhandler = (error, isFatal) => {
          // your error handler function
          let deviceDetails = {
              user_id: GetMe?.result?.id,
              appVersion: DeviceInfo.getVersion(),
              buildNumber: DeviceInfo.getBuildNumber(),
              deviceInfo: {
                  systemName: DeviceInfo.getSystemName(),
                  systemVersion: DeviceInfo.getSystemVersion(),
                  deviceName: result
              },
              error_screen: screen,
              error_name: error.name,
              error_message:error.message,
              error
          }
          if(isFatal)
          {
            Alert.alert(
                'Unexpected error occurred',
                `Error: ${error.name} \n ${error.message} \n\n ${t('translation:errors.crash_msg')}`,
              [{
                text: t('translation:modal.btn.ok'),
              }]
            );
            errorRequest(ERROR_LOG_URL,deviceDetails)
          }
        };
        setJSExceptionHandler(exceptionhandler, true);
        // - exceptionhandler is the exception handler function
        // - allowInDevMode is an optional parameter is a boolean.
        //   If set to true the handler to be called in place of RED screen
        //   in development mode also.

    }
}