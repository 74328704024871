import React, {useState} from 'react';
import {StyleSheet, ScrollView, View, TouchableOpacity} from 'react-native';
import CommonBtn from 'common/src/components/CommonBtn';
import InputField from 'common/src/components/InputField';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonAboutFooter from 'common/src/components/CommonAboutFooter';
import GlobalStyle from 'common/src/styles/GlobalStyles';
import { useDispatch } from 'react-redux';
import { passwordForgottenAction } from '../store/actions/authenticationActions';
import Text from 'common/src/components/CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const PasswordForgotten = ({navigation}:any) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const [scrollState, setScrollState] = useState();
  const [emailValidated, setEmailValidated] = useState(false);

  // keep tracking of the changes of the email input
  const callbackEmail = (text:string) => {
    setEmail(text);
  }

  const checkEmailValidation = (validated: boolean) => {
    setEmailValidated(validated);
  }

  // post request in order to retrieve the password to the app
  const forgotPass = async () => {
    let obj = {
      email:email,
      msg: t('translation:login.form.email_password_sent')
    }
    const setPassForgot = () => (passwordForgottenAction(obj));
    dispatch(setPassForgot());
  }

  const signIn = () => {
    navigation.navigate('Login')
  }

  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => {return (email.length === 0 || !emailValidated )}

  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader 
        title={t('translation:login.title_reset')}
        rightIconNameSecond="settings"
        rightIconColorSecond={GlobalStyle.helpi.color}
        rightClickSecond={() => navigation.navigate("Settings")}
        scroll={scrollState}
        testIDTitle="Retrieve password"
      />
      <View style={styles.scrollViewWrapper}>
        <ScrollView 
          style={styles.scrollview}
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            setScrollState(event.nativeEvent.contentOffset.y);
          }}
        >
          <InputField 
            labelText={t('translation:login.form.email')}
            inputType="email" 
            customStyle={{marginBottom:PR(30)}}
            changeText={callbackEmail}
            placeholderTxt="-" 
            testID="Email"
            isValidationRequired={true}
            changeValidation={checkEmailValidation}
            value={email}
          />
          <CommonBtn type="secondary" click={forgotPass} title={t('translation:login.form.reinit_password')} disable={checkDisable()} testID="reinit_pass"/>
          <TouchableOpacity onPress={signIn} testID="remember_pass">
            <Text style={styles.nfo}>{t('translation:login.form.have_password')}</Text>
          </TouchableOpacity>
          <CommonAboutFooter />
          </ScrollView>
      </View>
    </CommonKeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  scrollViewWrapper: {
    marginTop: PR(5),
    flex: 1,
  },
  scrollview: {
    paddingTop: PR(20),
    flex:1
   },
  nfo: {
    fontSize: PR(14),
    lineHeight: PR(20),
    color: GlobalStyle.global.grey,
    alignSelf: 'center',
    marginTop: PR(10),
    marginBottom: PR(10)
  }
});

export default PasswordForgotten;