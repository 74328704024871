import {call, takeLatest} from "redux-saga/effects";
import {makeRequest} from "../../services/requestService";
import { GET_REPORTS, GET_REPORTS_SUCCESS, POST_REPORTS, POST_REPORTS_SUCCESS } from "./reportsActions";

export function* getReportsSaga() {
    yield call(makeRequest, {
        path: '/reports',
        method: 'GET',
        responseAction: GET_REPORTS_SUCCESS
      });
}

export function* postReportsSaga(payload) {
    const obj = {
        startDate: payload.startDate,
        endDate: payload.endDate
    }
    const response = yield call(makeRequest, {
        data: obj,
        path: '/reports',
        method: 'POST',
        responseAction: POST_REPORTS_SUCCESS
    });
    if(response) {
        payload.navigation.navigate('ReportGenerated');
    }
}

export default function* watchUserAuthentication() {
    yield takeLatest(GET_REPORTS, getReportsSaga);
    yield takeLatest(POST_REPORTS, postReportsSaga);
}