import React from 'react';
import { useTranslation } from "react-i18next";
import { View, TouchableOpacity } from 'react-native';
import Text from '../CommonCustomTxt';
import CommonBtn from '../CommonBtn';
import CommonStyle from './CommonStyle';
import GlobalStyles from '../../styles/GlobalStyles';
import { widthPercentageToDP as PR } from 'common/src/styles/PixelRatio';
import { APP_SHORTCUT_NAME } from '../../env.json';
import { ProgramAction } from "../../types";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParams } from "../../types";

type OwnProps = {
    programAction: ProgramAction,
    scheduledDateTime: string | undefined,
    updateModalVisibility: (visable: boolean) => void,
    navigation: StackNavigationProp<RootStackParams>
}


const CommonProgramActionOneTime = ({programAction, scheduledDateTime, updateModalVisibility, navigation}: OwnProps) => {
    const { t, i18n } = useTranslation();
    const { language: userLanguage } = i18n;
    const actionBtn:Record<string, string> = programAction?.actionBtn || {en:t('translation:general.empty_action_btn')}
    const actionNavigation:keyof RootStackParams = programAction?.actionBtnNavigation?.Nav || 'AddEvent'
    const actionNavigationParams:string = programAction?.actionBtnNavigation?.params || ''

    const showModal = () => {
        updateModalVisibility(true)
    }

    const defineOnClickAction = () => {
        if (programAction.isInternalTraining) {
            const params = actionNavigationParams ?  {itemType: actionNavigationParams} : {}
            return navigation.navigate(actionNavigation, params)
        }
        else
            updateModalVisibility(true)
    }

    return (
        <View>
            {
                !programAction.isInternalTraining && scheduledDateTime &&
                    <TouchableOpacity onPress={showModal} style={{width:'90%', alignSelf:'center', marginTop: PR(7)}}>
                        <View style={{flexDirection:'row', alignItems:'center'}}>
                            <Text style={CommonStyle.label}>{t('translation:list.journal_filters_raw.0.label')}</Text>
                        </View>
                        <View testID="Email" style={CommonStyle.summaryContainer}>
                            <View style={CommonStyle.inputSummaryContainer}>
                                <Text style={CommonStyle.inputSummaryValue}>{scheduledDateTime}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
            }
            {
                (!scheduledDateTime || programAction.isInternalTraining) && 
                    <View style={CommonStyle.scheduleBtn}>
                        <CommonBtn
                            type='tertiary'
                            title={ programAction.isInternalTraining ? actionBtn[userLanguage] || actionBtn.en : t('translation:general.programs.schedule_for_later_btn')}
                            color={GlobalStyles[APP_SHORTCUT_NAME].btnColor}
                            click={defineOnClickAction}
                            testID='schedule'
                        />
                    </View>
            }
        </View>
    )
}

export default CommonProgramActionOneTime;