import ReactNativeBiometrics from 'react-native-biometrics';

const rnBiometrics = new ReactNativeBiometrics();

interface OwnType {
    callback: any;
}

export const removeBiometricKeysOnFirstLaunch = ({callback}: OwnType) => {
    rnBiometrics.biometricKeysExist()
    .then((resultObject) => {
        const { keysExist } = resultObject

        if (keysExist) {
            rnBiometrics.deleteKeys().then((resultObject) => {
                const { keysDeleted } = resultObject
            
                if (keysDeleted) {
                    callback();
                }
            })
        }
    })
}