import React, {useEffect, useState} from 'react';
import {StyleSheet, Platform, ScrollView, View, TouchableOpacity} from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonTitle from 'common/src/components/CommonTitle';
import CommonSubtitle from 'common/src/components/CommonSubtitle';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonImage from 'common/src/components/CommonImage';
import InputField from 'common/src/components/InputField';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonModal from 'common/src/components/CommonModal';
import Icon from 'react-native-vector-icons/Feather';
import { useDispatch, useSelector } from 'react-redux';
import { addNeurologistAction, getMeAction, getNeurologistAction } from '../store/actions/authenticationActions';
import {useTranslation} from 'react-i18next';
import CommonAcceptanceCheckbox from 'common/src/components/CommonAcceptanceCheckbox';
import CommonFooter from 'common/src/components/CommonFooter';
import {checkBottomScroll} from 'common/src/services/utils';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';
import CommonKeyboardAvoidingView from 'common/src/components/CommonKeyboardAvoidingView';

const AddNeurologist = ({navigation}:any) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const GetMeResponse = useSelector((state:any) => state.getMe.response);
  const NeuroResponse = useSelector((state:any) => state.neurologists.success);
  const [contentHeight, setContentHeight] = useState<number>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [checkBoxAnswer, setCheckBoxAnswer] = useState<boolean>(false);
  const [scrollState, setScrollState] = useState();
  const [scrollAtBottom, setScrollAtBottom] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);
  const subtitle = t('translation:neurologist.info_subtitle')

  // Checking if the getMe state is empty
  useEffect(() => {
    if (GetMeResponse === undefined) {
      dispatch(getMeAction());
    }
    else if(NeuroResponse.length === 0) {
      dispatch(getNeurologistAction());
    }
  },[GetMeResponse]);

  const checkEmailValidation = (validated: boolean) => {
    setEmailValidated(validated);
  }
  // keep tracking of the changes of the modalVisible
  const callbackVisible = () => {
    setModalVisible(false);
  }
  const openModal = () => {
    setModalVisible(true);
  }
  // keep tracking of the changes of the email input
  const callbackEmail = (text:string) => {
    setEmail(text)
  }
  // triggering the request in order to save the neurologist
  const saveNeuro = () => {
    let obj = {neuro:{email:email, type:'doctor', permissions:{"*":true}, optin:true}, navigation}
    dispatch(addNeurologistAction(obj));
  }
  // return a boolean value in order to turn the button to disable or enable
  const checkDisable = () => (!emailValidated || !checkBoxAnswer) 

  const confirmModalBtn = [
    {
      type: 'primary',
      title: t('translation:modal.btn.ok'),
      click: callbackVisible
    }
  ]
  const infoTxt = t('translation:neurologist.info_help')
  return (
    <CommonKeyboardAvoidingView>
      <CommonHeader
        testIDTitle="add_neuro_title"
        title={t('translation:neurologist.add')}
        leftIconName="arrow-left"
        leftIconColor={GlobalStyles.helpi.color}
        leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
        scroll={scrollState}
      />
      <ScrollView
        scrollEventThrottle={16}
        onScroll={(event: any) => {
          let calc = checkBottomScroll(event.nativeEvent, 0);
          if (!scrollAtBottom && calc)
              setScrollAtBottom(true)
          else if (scrollAtBottom && !calc)
              setScrollAtBottom(false)
          setScrollState(event.nativeEvent.contentOffset.y);
        }}
      >
        <View
          onLayout={(event: any) => {
            setContentHeight(event.nativeEvent.layout.height)
          }}
        >
          <View style={styles.IconRow}>
            <CommonImage name="doctorIconHelpi" width={PR(80)} height={PR(80)}/>
            <TouchableOpacity onPress={openModal} style={{marginLeft: PR(20)}}>
              <Icon name={"info"} size={PR(25)} color={GlobalStyles.helpi.color} />
            </TouchableOpacity>
          </View>
          <View style={styles.titleWrapper}>
            <CommonTitle text={t('translation:neurologist.info_title')} color={GlobalStyles.helpi.color}/>
            <CommonSubtitle text={subtitle}/>
          </View>
          <InputField 
            labelText={t('translation:neurologist.form.label_email')}
            inputType="email" 
            customStyle={{marginBottom:PR(30)}}
            changeText={callbackEmail}
            placeholderTxt="-" 
            testID="EmailNeurologist"
            isValidationRequired={true}
            changeValidation={checkEmailValidation}
            value={email}
          />
          <View style={styles.checkboxContainer}>
            <CommonAcceptanceCheckbox testID="acceptanceCheckbox" text={t('translation:neurologist.optin')} updateValidation={setCheckBoxAnswer} checked={checkBoxAnswer}/>
          </View>
          { modalVisible &&
            <CommonModal type={'info'} title={t('translation:modal.titles.help')} text={infoTxt} buttons={confirmModalBtn} onClose={callbackVisible}/>
          }
        </View>
      </ScrollView>
      <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
        <CommonBtn type="primary" title={t('translation:neurologist.save')} click={saveNeuro} disable={checkDisable()} testID="save_neuro_btn"/>
      </CommonFooter>
      <View style={{marginBottom:Platform.OS === 'ios' ? PR(15) : 0}}></View>
    </CommonKeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  titleWrapper: {
    margin: PR(30)
  },
  IconRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: PR(100)
  },
  checkboxContainer: {
    width:'90%',
    alignSelf:'center',
    justifyContent: 'center',
    padding: PR(20)
  }
});

export default AddNeurologist;