import React, { useState, useEffect } from 'react';
import {StyleSheet, View} from 'react-native';
import Text from 'common/src/components/CommonCustomTxt';
import GlobalStyles from '../../styles/GlobalStyles';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import { useTranslation } from 'react-i18next';
import {getListCMSData} from "../CommonDiary/utils";
import CommonProgressBar from '../CommonProgress/CommonProgressBar';
import {APP_SHORTCUT_NAME} from '../../env.json';

type OwnProps = {
    Events: Array<EventsTypes>,
    triggersAurasType: string
}

interface EventsTypes {
    count: number,
    totalEvents: number,
    triggers?: Array<TriggersAurasTypes>,
    auras?: Array<TriggersAurasTypes>
}

interface TriggersAurasTypes {
    name: string,
    count: number
}

const CommonImpactTriggersAurasGraph = ({Events, triggersAurasType}:OwnProps) => {
    const {t} = useTranslation();
    const [triggersAuras, setTriggerAuras] = useState<Array<TriggersAurasTypes>>([]);
    const findField = Events.findIndex(item => Object.keys(item).includes(triggersAurasType))
    
    useEffect(() => {
        if(findField > -1){
            let tempArray:Array<TriggersAurasTypes> = [...Events[findField][triggersAurasType]]
            tempArray.sort((a,b) => (a.count > b.count) ? -1 : ((b.count > a.count) ? 1 : 0))
            setTriggerAuras(tempArray)
        }
    },[])

    
    return(
        <View style={{flexDirection:'column', width:'100%'}}>
            <Text testID="triggers_auras_title" style={{textAlign: 'center'}}>{t('translation:programs.impact.triggers_auras.title',{fieldType: triggersAurasType})}</Text>
            <View>
                {triggersAuras.slice(0, triggersAurasType === 'triggers' ? 5 : 3).map((tA:TriggersAurasTypes, index:number) => {
                    return(
                        <View key={index} style={styles.triggerAuraContainer}>
                            <Text testID={`triggers_auras_name_${index}`} style={{textAlign: 'left'}}>{getListCMSData(t, tA.name, `list.seizure_${triggersAurasType}`)}</Text>
                            <View style={{flexDirection:'row', flex:1, justifyContent:'space-between', alignItems:'center'}}>
                                <View style={{flexGrow: 1}}>
                                    <CommonProgressBar current={tA.count} total={Events[findField].count} fillColor={GlobalStyles[APP_SHORTCUT_NAME].color} backgroundColor={GlobalStyles[APP_SHORTCUT_NAME].progressBarBackgroundColor}/>
                                </View>
                                <Text testID={`triggers_auras_percentage_${index}`}>{((tA.count/Events[findField].count)*100).toFixed(1)}%</Text>
                            </View>
                        </View>
                    )
                })}
            </View>
            <Text 
                testID='triggers_auras_bottom_text' 
                style={styles.triggersAurasBottomTxt}
            >
                {t('translation:programs.impact.triggers_auras.bottom_text',{TAtype: triggersAurasType, percentage: ((Events[findField].count/Events[findField].totalEvents)*100).toFixed(1)})}
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    triggerAuraContainer: {
        flex:1,
        marginTop: PR(20)
    },
    triggersAurasBottomTxt: {
        marginTop: PR(20),
        textAlign: 'left',
        fontSize: PR(12),
        fontFamily: GlobalStyles.global.fontFamily.RegularItalic,
        opacity: 0.8
    }
})

export default CommonImpactTriggersAurasGraph;