import React from 'react';
import { StyleSheet, View } from 'react-native';
import { widthPercentageToDP as PR } from "common/src/styles/PixelRatio";
import CommonImage from '../../CommonImage';

const CommonFeaturedRibbon = () => 
  <View style={Style.container}>
      <CommonImage name={'ribbonIcon'} height={PR(36)} width={PR(30)}/>      
  </View>;


const Style = StyleSheet.create(
    {
        container: {
            elevation: 100,
            right: PR(8),
            position: 'absolute',
            top: PR(-2),
            zIndex: 100,
        },
    }
)

export default CommonFeaturedRibbon;
