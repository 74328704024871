import React, {useEffect, useState} from 'react';
import {StyleSheet, Platform, SafeAreaView, View, TouchableOpacity, ScrollView} from 'react-native';
import CommonHeader from 'common/src/components/CommonHeader';
import CommonBtn from 'common/src/components/CommonBtn';
import CommonFooter from 'common/src/components/CommonFooter';
import GlobalStyles from 'common/src/styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import { useDispatch, useSelector } from 'react-redux';
import { getNeurologistAction, getMeAction, deleteNeurologistAction } from '../store/actions/authenticationActions';
import Text from 'common/src/components/CommonCustomTxt';
import CommonModal from 'common/src/components/CommonModal';
import {useTranslation} from 'react-i18next';
import {checkBottomScroll} from 'common/src/services/utils';
import {widthPercentageToDP as PR} from 'common/src/styles/PixelRatio';

const My_neurologists = ({navigation}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const GetMeResponse = useSelector((state:any) => state.getMe.response);
  const NeuroResponse = useSelector((state:any) => state.neurologists.success);
  const [scrollState, setScrollState] = useState();
  const [contentHeight, setContentHeight] = useState<number>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [neuroId, setNeuroId] = useState<string>('');
  const [scrollAtBottom, setScrollAtBottom] = useState(false);
  // checking if the getMe state is empty else if getNeurologists state is empty triggering the request to get them
  useEffect(() => {
    if (GetMeResponse === undefined) {
      dispatch(getMeAction());
    }
    else if(NeuroResponse.length === 0) {
      dispatch(getNeurologistAction());
    }
  },[GetMeResponse]);
  // keep tracking of the changes of the modalVisible
  const callbackVisible = () => {
    setModalVisible(false);
  }
  // go to Add Neurologist page
  const addNeuro = () => {
    navigation.navigate('AddNeurologist');
  }
  const openModal = (id:string) => {
    setModalVisible(true);
    setNeuroId(id);
  }
  const removeNeuro = () => {
    dispatch(deleteNeurologistAction(neuroId));
    setModalVisible(false);
  }

  const confirmModalBtn = [
    {
      type: 'tertiary',
      title: t('translation:modal.btn.cancel'),
      click: callbackVisible
    },
    {
    type: 'primary',
    title: t('translation:modal.btn.confirm'),
    click: removeNeuro
    }
  ]

  const confirmModalTxt = t('translation:neurologist.delete_text')
  return (
    <>
      <SafeAreaView style={styles.container}>
        <CommonHeader
          testIDTitle="my_neurologists"
          title={t('translation:neurologist.list_title')}
          leftIconName="arrow-left"
          leftIconColor={GlobalStyles.helpi.color}
          leftClick={() => {Platform.OS === 'web' ? window.history.back() : navigation.goBack()}}
          scroll={scrollState}
        />
        <ScrollView
          scrollEventThrottle={16}
          onScroll={(event: any) => {
            let calc = checkBottomScroll(event.nativeEvent, 0);
            if (!scrollAtBottom && calc)
                setScrollAtBottom(true)
            else if (scrollAtBottom && !calc)
                setScrollAtBottom(false)
            setScrollState(event.nativeEvent.contentOffset.y);
          }}
        >
          <View style={styles.wrapper}
            onLayout={(event: any) => {
              setContentHeight(event.nativeEvent.layout.height)
            }}
          >
            {/* checking if the neurologist page is not empty and print all neurologists */}
            {NeuroResponse.length ? NeuroResponse.map(item => {
                return (
                  <View key={item.id} style={styles.item}>
                    <Text style={styles.itemText}>
                      {item.email}
                    </Text>
                    <TouchableOpacity style={{width:'15%', flex:1, alignItems:'flex-end'}} onPress={() => openModal(item.id)} testID="delete">
                      <Icon name={"trash-2"} size={PR(18)} color={'red'} />
                    </TouchableOpacity>
                  </View>
                )
              }) :
              <View>
              </View>
            }
          </View>
          { modalVisible &&
            <CommonModal testID="deleteNeuroModal" testIDBtn="BtnID" type={'warning'} title={t('translation:neurologist.delete_title')} text={confirmModalTxt} buttons={confirmModalBtn} onClose={callbackVisible}/>
          }
        </ScrollView>
        <CommonFooter atBottom={contentHeight} reachBottom={scrollAtBottom}>
          <CommonBtn type="primary" title={t('translation:neurologist.add')} click={addNeuro} testID="add_neurologist"/>
        </CommonFooter>
      </SafeAreaView>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: GlobalStyles.global.white,
    flex: 1,
  },
  wrapper: {
    marginTop: PR(20),
    marginBottom: PR(20)
  },
  item: {
    paddingVertical: PR(13),
    paddingHorizontal: PR(18),
    borderBottomWidth: PR(1),
    borderBottomColor: GlobalStyles.global.lightGrey,
    width:'90%',
    justifyContent: "space-between",
    alignSelf:'center',
    flexDirection: "row"
  },
  itemText: {
    color: GlobalStyles.global.grey,
    fontSize: PR(16),
    letterSpacing: PR(1),
    width: '85%'
  },
});

export default My_neurologists;