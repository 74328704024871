import React, {useState, useEffect} from 'react';
import { StyleSheet, View } from 'react-native';
import CommonTitle from '../CommonTitle';
import Text from '../CommonCustomTxt';
import InputField from '../InputField';
import GlobalStyles from '../../styles/GlobalStyles';
import {useTranslation} from 'react-i18next';
// @ts-ignore
import UploadVideo from './UploadVideo/UploadVideo';
import CommonRadioForm from "./CommonRadioForm";
import CommonSelectForm from "./CommonSelectForm";
import {getDurationString} from "../../helpers/event";
import {deleteVideoEvent, resetEvents, updateVideoEvents} from "helpilepsy/src/store/events/eventActions";
import { resetSeizureVideoAction } from 'helpilepsy/src/store/actions/authenticationActions';
import {useDispatch,useSelector} from "react-redux";
import {getListCMSData} from "../../helpers/translations";
import CommonRadioBtn from '../CommonRadioBtn';
import {widthPercentageToDP as PR} from '../../styles/PixelRatio';
import CommonLabelForm from './CommonLabelForm';
import CommonModal from '../CommonModal';

interface OwnProps {
    isSubmitButtonActive: boolean;
    AddSeizureData: any;
    initialData: any;
    checkDate: boolean;
    isFormUpdated: (data: boolean) => void;
    disabled: (data: boolean) => void;
}

const CommonAddSeizure = ({AddSeizureData, isSubmitButtonActive, initialData, checkDate, isFormUpdated, disabled}: OwnProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [duration, setDuration] = useState<string>(getDurationString(initialData?.duration));
    const [seizureType, setSeizureType] = useState<string>(initialData?.seizure_type || '');
    const [seizureTypeId, setSeizureTypeId] = useState<string | null>(initialData?.seizure_type_id);
    const [triggersType, setTriggersType] = useState<Array<string>>(initialData?.triggers || []);
    const [aurasType, setAurasType] = useState<Array<string>>(initialData?.auras || []);
    const [postSeizuresType, setPostSeizuresType] = useState<Array<string>>(initialData?.post_seizure || []);
    const [emergencyType, setEmergencyType] = useState<Array<string>>(initialData?.emergency_treatments || []);
    const [whereType, setWhereType] = useState<string>(initialData?.place);
    const [answer, setAnswer] = useState<string>(initialData?.felt || '');
    const [answerDuringSleep, setAnswerDuringSleep] = useState<string>(initialData?.during_sleep || undefined)
    const [remarks, setRemarks] = useState<string>(initialData?.remark);
    const patientData = useSelector((state:any) => state.getMe.patient);
    const [videoResponse, setVideoResponse] = useState<Array<any>>(initialData?.video_events || []);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const Me = useSelector((state:any) => state.getMe.response)
    const [incorrectDataModal, setIncorrectDataModal] = useState(false)

    useEffect(() => {
        if(isSubmitButtonActive)
          sendDataBack()
      },[isSubmitButtonActive])

    useEffect(() => {
        if(!checkDisable())
            disabled(false)
        else    
            disabled(true)
    }, [seizureType, answer, checkDate, uploadProgress])

    useEffect(() => {
        if(answer === 'no') {
            setAurasType([])
        }
    }, [ answer])
    
    const sendDataBack = () => {
        let durationNumber: number | undefined
        if(duration !== undefined) {
            let [minutes, seconds] = duration.split(":");
            // TODO: Remove this workaround when the duration component is replaced.
            minutes = minutes === '' || minutes === 'undefined' ? '00' : minutes;
            seconds = seconds === '' || seconds === 'undefined' ? '00' : seconds;
            durationNumber = (parseInt(minutes, 10) * 60) + (parseInt(seconds, 10));
        }

        let sameNomenclature = false
        patientData?.lists?.neurologist_seizure_types?.map((seizType) => {
            if (seizType.seizureType === seizureType)
                sameNomenclature = seizType.sameNomenclature
        })
        if (!sameNomenclature) {
            const isValidatedByHCP = checkNeuroSeizureTypes();
            if (!isValidatedByHCP &&  getListCMSData(t, "list.seizurePatientList").find(type => type.value === seizureType))
                sameNomenclature = true
        }
        let obj = {
            type:'seizure',
            duration: durationNumber,
            seizureType,
            triggersType,
            aurasType,
            postSeizuresType,
            emergencyType,
            whereType,
            answerDuringSleep,
            answer,
            remarks,
            videoResponse,
            seizureTypeId,
            sameNomenclature,
            msg: t('translation:events.seizure.success')
        }
        AddSeizureData(obj, (res) => {
            dispatch(updateVideoEvents({
                data: {
                    event_id: res.id,
                    video_events: videoResponse.map(v => v.id)
                },
                callback: () => {
                    dispatch(resetSeizureVideoAction());
                }
            }));
        })
    }


    // return a boolean value in order to turn the button to disable or enable
    const checkDisable = () => {return (seizureType?.length === 0 || answer?.length === 0 || !checkDate || (uploadProgress > 0 && uploadProgress < 100) )}

    const isConnectedToHCP = () => {
        return !!Me?.result?.permissions?.find(item => item.type === 'doctor')
    }

    const checkNeuroSeizureTypes = () => {
        if (!isConnectedToHCP())
            return false
        let validated = false
        patientData?.lists?.neurologist_seizure_types?.map((sType) => {
            if (!sType.patientName || (sType.patientName !== sType?.seizureType))
                validated = true
        })
        return validated
    }

    const defineSeizureTypeToShow = () => {
        const isValidatedByHCP = checkNeuroSeizureTypes();
        let seizureTypeList: any = [];
        const cmsSeizureTypes =  isValidatedByHCP ? getListCMSData(t, "list.seizureList") : getListCMSData(t, "list.seizurePatientList");
        const filteredData = isValidatedByHCP ? patientData?.lists?.neurologist_seizure_types?.filter(i => i.showInApp) : patientData?.lists?.neurologist_seizure_types?.filter(i => i.showInApp && cmsSeizureTypes.findIndex(item => item.value === i.seizureType) === -1)

        if (!isValidatedByHCP)
            seizureTypeList = [...cmsSeizureTypes];

        if (filteredData)
            filteredData.map(i => {
                const value = i.sameNomenclature ? i.seizureType : i.patientName;
                seizureTypeList.push({
                    label: cmsSeizureTypes?.find(type => type.value === value)?.label || value,
                    value,
                    id: i.id
                })
            })

        return seizureTypeList
    }

    const checkSeizureTypeDeletable = () => {
        const deletableTypes: any = [];
        patientData?.lists?.seizure_type_inputs?.map((seizureInput) => {
            if (!patientData.lists?.neurologist_seizure_types?.find((item) => item.patientName === seizureInput))
                deletableTypes.push(seizureInput);
        })
        return deletableTypes
    }

    const getSeizureTypdId = (type) => {
        if (!patientData.lists)
            return null;
        const seizureTypeFound = patientData.lists?.neurologist_seizure_types?.find((item) => {
            return (item.sameNomenclature && item.seizureType === type) || (!item.sameNomenclature && item.patientName === type)
        })
        return seizureTypeFound ? seizureTypeFound.id : null
    }

    const updateSeizureDuration = (e) => {
        const min = e.split(':')[0];
        if (min && parseInt(min) >= 60)
            setIncorrectDataModal(true);
        setDuration(e);
        isFormUpdated(true)
    }

    const closeIncorrectDataModal = () => {
        setIncorrectDataModal(false)
    }

    return (
        <>
            { incorrectDataModal &&
                <CommonModal 
                    testID="warningModalID" 
                    type={'info'} 
                    title={t('translation:modal.titles.warning')} 
                    text={t('translation:modal.incorrect_data.seizure_duration')}
                    buttons={[
                        {
                          type: 'primary',
                          title: t('translation:modal.btn.ok'),
                          click: closeIncorrectDataModal
                        }
                      ]} 
                    onClose={closeIncorrectDataModal}
                />
            }
            <CommonRadioForm
                value={seizureType}
                setValue={(type) => {
                    setSeizureTypeId(getSeizureTypdId(type))
                    setSeizureType(type)
                    isFormUpdated(true)
                }}
                dataFieldToDisplay={"label"}
                cmsListLink={"list.seizurePatientList"}
                displayCmsListLink={"list.seizureList"}
                patientList="seizure_type_inputs"
                patientListData={checkSeizureTypeDeletable()}
                label={t('translation:general.events.type')}
                dataList={defineSeizureTypeToShow()}
                isOptional={false}
            />
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
                <CommonLabelForm label={t('translation:events.seizure.felt')} isOptional={false}/>
                <View style={styles.container}>
                    <View style={styles.inputContainer}>
                        <CommonRadioBtn 
                            answers={[
                            {name:'Yes',value:t('translation:modal.btn.yes')},
                            {name:'No',value:t('translation:modal.btn.no')}
                            ]} 
                            updateAnswer={(e) => {setAnswer(e.toLowerCase()); isFormUpdated(true)}}
                            textColor={GlobalStyles.global.black}
                            selected={initialData?.felt ? initialData?.felt.charAt(0).toUpperCase() + initialData?.felt.slice(1) : ''}
                        />
                    </View>
                </View>
            </View>
            {
                answer === 'yes' &&
                <CommonSelectForm
                    values={aurasType}
                    setValues={(d) => {setAurasType(d); isFormUpdated(true)}}
                    cmsListLink="list.seizure_auras"
                    patientList="auras_inputs"
                    label={t('translation:events.seizure.auras')}
                    isOptional={true}
                 />
            }
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
                <CommonLabelForm label={t('translation:events.appointment.videoMode')} isOptional={true}/>
                <UploadVideo
                    text={t('translation:events.uploadVideo_text')}
                    values={videoResponse}
                    updateValues={(v) => {setVideoResponse(v); isFormUpdated(true); dispatch(resetSeizureVideoAction());}}
                    progressValue={(p) => {setUploadProgress(p); isFormUpdated(true)}}
                    deleteVideo={(video) => {
                        dispatch(deleteVideoEvent({
                            videoId: video.id,
                            callback: () => {
                                setVideoResponse([...videoResponse.filter(v => v.id !== video.id)]);
                                isFormUpdated(true);
                                dispatch(resetEvents());
                                dispatch(resetSeizureVideoAction());
                            }
                        }))
                    }}
                />
            </View>
            <View style={{width:'90%',alignSelf:'center', marginBottom:PR(30)}}>
            <CommonLabelForm label={t('translation:events.seizure.during_sleep')} isOptional={true}/>
                <View style={styles.container}>
                    <View style={styles.inputContainer}>
                        <CommonRadioBtn 
                            answers={[
                            {name:'Yes',value:t('translation:modal.btn.yes')},
                            {name:'No',value:t('translation:modal.btn.no')}
                            ]} 
                            updateAnswer={(e) => {setAnswerDuringSleep(e.toLowerCase()); isFormUpdated(true)}}
                            textColor={GlobalStyles.global.black}
                            selected={initialData?.during_sleep ? initialData?.during_sleep.charAt(0).toUpperCase() + initialData?.during_sleep.slice(1) : undefined}
                        />
                    </View>
                </View>
            </View>
            <InputField
                labelText={t('translation:events.seizure.duration') + ' ' + '('+t('translation:modal.duration.minutes')+ ':' + t('translation:modal.duration.seconds')+')'}
                inputType="minutes-and-seconds"
                customStyle={{marginBottom:PR(30)}}
                changeText={updateSeizureDuration}
                testID="time"
                value={duration}
                isOptional={true}
            />
            <CommonSelectForm
                values={triggersType}
                setValues={(d) => {setTriggersType(d); isFormUpdated(true)}}
                cmsListLink="list.seizure_triggers"
                patientList="triggers_inputs"
                label={t('translation:events.seizure.triggers')}
                isOptional={true}
            />
           
            <CommonSelectForm
                values={postSeizuresType}
                setValues={(d) => {setPostSeizuresType(d); isFormUpdated(true)}}
                cmsListLink="list.post_seizure_effects"
                patientList="post_seizure_inputs"
                label={t('translation:events.seizure.post_seizure')}
                isOptional={true}
            />
            <CommonSelectForm
                values={emergencyType}
                setValues={(d) => {setEmergencyType(d); isFormUpdated(true)}}
                cmsListLink="list.emergency_treatments"
                patientList="emergency_treatments_inputs"
                label={t('translation:events.seizure.emergencies')}
                isOptional={true}
            />
            <CommonRadioForm
                value={whereType}
                setValue={(d) => {setWhereType(d); isFormUpdated(true)}}
                cmsListLink="list.seizure_locations"
                patientList="place_inputs"
                label={t('translation:events.seizure.location')}
                isOptional={true}
            />
            <InputField
                labelText={t('translation:general.events.remark')}
                inputType="string"
                customStyle={{marginBottom:PR(30)}}
                changeText={(d) => {setRemarks(d); isFormUpdated(true)}}
                placeholderTxt={t('translation:general.events.remark_placeholder')}
                testID="remarks"
                multiline={true}
                value={remarks}
                isOptional={true}
            />
        </>
    )
};

const styles = StyleSheet.create({
    seperationTitleContainer: {
        width:'90%',
        alignSelf:'center'
    },
    label: {
        marginBottom: PR(10),
        marginLeft: PR(20),
        color: GlobalStyles.global.black,
        fontSize: PR(12)
    },
    container: {
        backgroundColor: '#F2F4F5',
        borderRadius: PR(20),
        borderColor: '#F2F4F5',
        height: PR(62)
      },
    inputContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingLeft: PR(20),
        fontSize: PR(16),
        alignItems:'center',
    },
    footer: {
        position: 'absolute',
        flex:1,
        left: 0,
        right: 0,
        bottom: PR(10),
        flexDirection:'column',
        height:80,
        alignItems:'center',
      },
})

export default CommonAddSeizure;