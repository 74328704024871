import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import GlobalStyles from '../styles/GlobalStyles';
import Icon from 'react-native-vector-icons/Feather';
import Text from './CommonCustomTxt';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonSettingsBtn = (props:any) => {
  const clickedBtn = (itemName) => {
    
    props.clicked(itemName)
  }
  return (
    <>
      {props.listNames.map((item, key) => {
        return (
          <TouchableOpacity testID={`setting-item-${item.value}`} key={key} style={styles.item} onPress={() => {
            // TODO redirect to account page
            clickedBtn(item.value)
          }}>
            <Text numberOfLines={1} style={styles.itemText}>
              {item.name}
            </Text>
            <Icon name={"chevron-right"} size={PR(25)} color={GlobalStyles.global.black} style={{opacity:0.5}} />
          </TouchableOpacity>
        )
      })}
    </>
  );
}

const styles = StyleSheet.create({
  item: {
    paddingVertical: PR(10),
    width: '90%',
    alignSelf: 'center',
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  itemText: {
    fontSize: PR(18),
    letterSpacing: 0,
    width: '90%'
  },
});

export default CommonSettingsBtn;