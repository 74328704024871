import { ContentCondition, ContentConditionTypes, FormSelectAnswer, EducationModule, ProgramEducationModule } from "../../types";
import { DateTime } from "luxon";

export const getEducationModulesByFeatured = (programEducationModules: ProgramEducationModule[], trackStartDate: string, educationModules: EducationModule[]): { featured: EducationModule[], notFeatured: EducationModule[] } => {
    const currentDaysInProgram = DateTime.now().diff(DateTime.fromISO(trackStartDate), ['days', 'hours']).days;
    const currentlyFeaturedAndActive = programEducationModules
        .filter(module => module.isFeatured)
        .filter(module => module.featuredAfterDays <= currentDaysInProgram && currentDaysInProgram < (module.featuredAfterDays + module.featuredDuringDays))
        .map(module => module.id);
    
    return {
        featured: educationModules.filter(module => currentlyFeaturedAndActive.includes(module.id)),
        notFeatured: educationModules.filter(module => !currentlyFeaturedAndActive.includes(module.id))
    };
}

const isSelectionType = (answer: any) => {
    if (!Array.isArray(answer)) {
        return false;
    }
    const sample = answer[0];
    return 'value' in sample && 'selected' in sample;
};

const isNumericComparison = (answer: any, expectedAnswer: any): boolean => !isNaN(answer) && !isNaN(expectedAnswer);

const getSelectionValues = (answer: FormSelectAnswer[]): string[] => answer.filter(a => a.selected).map(({value}) => value);

const compare = (answer: string | string[], expectedAnswer: string, conditionType: ContentConditionTypes): boolean => {
    switch (conditionType) {
        case ContentConditionTypes.Contains:
            if (!Array.isArray(answer)) {
                return answer.toLowerCase().includes(expectedAnswer.toLowerCase());
            }
            return answer.includes(expectedAnswer);
        case ContentConditionTypes.Equals:
            return answer === expectedAnswer;
        case ContentConditionTypes.GreaterThanEquals:
            return answer >= expectedAnswer;
        case ContentConditionTypes.GreaterThan:
            return answer > expectedAnswer;
        case ContentConditionTypes.LessThan:
            return answer < expectedAnswer;
        case ContentConditionTypes.LessThanEquals:
            return answer <= expectedAnswer;
        default: return false;
    }
}

const compareNumeric = (answer: number, expectedAnswer: number, conditionType: ContentConditionTypes): boolean => {
    switch (conditionType) {
        case ContentConditionTypes.Equals:
            return answer === expectedAnswer;
        case ContentConditionTypes.GreaterThanEquals:
            return answer >= expectedAnswer;
        case ContentConditionTypes.GreaterThan:
            return answer > expectedAnswer;
        case ContentConditionTypes.LessThan:
            return answer < expectedAnswer;
        case ContentConditionTypes.LessThanEquals:
            return answer <= expectedAnswer;
        default: return false;
    }
}

export const isConditionSatisfied = (condition: ContentCondition, questionnaireAnswers: object): boolean => {
    const { questionId } = condition;

    if (!(questionId in questionnaireAnswers)) {
        return false;
    }

    let answer = questionnaireAnswers[questionId];

    if (isNumericComparison(answer, condition.answerValue)) {
        const numericAnswer = parseInt(answer);
        const numericExpected = parseInt(condition.answerValue);
        return compareNumeric(numericAnswer, numericExpected, condition.conditionType);
    }

    if (isSelectionType(questionnaireAnswers[questionId])) {
        answer = getSelectionValues(questionnaireAnswers[questionId]);
    }
    
    return compare(answer, condition.answerValue, condition.conditionType);
}