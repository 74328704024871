export enum TimezoneBannerActions {
    UPDATE_TIMEZONE_BANNER = 'UPDATE_TIMEZONE_BANNER',
    UPDATE_TIMEZONE_BANNER_SUCCESS = 'UPDATE_TIMEZONE_BANNER_SUCCESS'
}

export interface UpdateTimezoneBannerTypes{
    bannerExist: boolean;
}

export const updateTimezoneBannerAction = (payload: UpdateTimezoneBannerTypes) => {
    return {
        type: TimezoneBannerActions.UPDATE_TIMEZONE_BANNER,
        ...payload
    };
};